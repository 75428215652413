export const STATUS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
];

export const STATUS_ALL = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "NA", value: "NA" }
];

export const MODE = [
  { label: "CFS", value: "CFS" },
  { label: "CY", value: "CY" }
];