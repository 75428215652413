import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DialogContentText from "@material-ui/core/DialogContentText";

import MenuBar from "./MenuBar";
import Quote from "./Quote";
import Task from "./Task";
import Products from "./Products";
import SupplierFactory from "./SupplierFactory";
import Invoice from "./Invoice";

import {
  atnCancelTasks,
  atnGetLabAdminSubmissionDetails,
  atnGetTasksForCancellation,
} from "actions/submissionActions";
import Tabs from "components/navigation/Tabs";
import { USER_TYPE } from "shared/constants";

import SelectTasksTable from "components/table/SelectTasksTable";
import labData from "./data/labData.json";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  dialogTableTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 5,
  },
}));

const SubmissionDetail = ({ match, isAdmin = false }) => {
  const submissionId = match.params.submissionId;
  const type = USER_TYPE.lab;

  const classes = useStyles();

  const submissionData = labData;
  // const { tabs = [] } = submissionData;

  const [tabs, setTabs] = useState([]);
  const [labStatus, setLabStatus] = useState("");
  const [details, setDetails] = useState({ id: submissionId });
  const [curTaskType, setCurTaskType] = useState(null);
  const [openCancelTaskDialog, setOpenCancelTaskDialog] = useState(false);
  const [tasksForCancellation, setTasksForCancellation] = useState([]);
  const [selectedCancelTaskIds, setSelectedCancelTaskIds] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelTaskDialogOpen = () => {
    atnGetTasksForCancellation({
      submissionId: submissionId,
    }).then(({ data, message }) => {
      if (data) {
        setTasksForCancellation(data);
        setOpenCancelTaskDialog(true);
      }
    });
  };

  const handleCancelTaskDialogClose = () => {
    setOpenCancelTaskDialog(false);
    setSelectedCancelTaskIds([]);
  };

  const handleCancelTaskSelect = (task) => {
    if (selectedCancelTaskIds.indexOf(task.id) >= 0) {
      setSelectedCancelTaskIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      setSelectedCancelTaskIds((prevState) => {
        return prevState.concat(task.id);
      });
    }
  };

  const handleTaskCancel = () => {
    let tasks = [];

    for (let i = 0; i < selectedCancelTaskIds.length; i++) {
      tasks.push({
        taskId: selectedCancelTaskIds[i],
      });
    }

    atnCancelTasks({
      submissionId: submissionId,
      data: tasks,
    }).then(({ data, message }) => {
      if (data) {
        setOpenConfirmDialog(false);
        setOpenCancelTaskDialog(false);
        window.location.reload();
      }
    });
  };

  const getSubmissionDetails = (id) => {
    atnGetLabAdminSubmissionDetails({ data: { id }, type }).then(({ data, message }) => {
      const detail = { id, ...data };

      if (data.labStatus === "rejected") {
        setLabStatus(data.labStatus);
        setTabs(submissionData.rejectedTabs);
      } else {
        if (data.taskType === "SS") {
          setTabs(submissionData.sampleSealingTabs);
        } else {
          if (data.isSkipQuote === 1) {
            setTabs(submissionData.skipQuoteTabs);
          } else {
            setTabs(submissionData.tabs);
          }
        }
      }

      setDetails(detail);

      // TODO Set current task type: testing/inspection/sample sealing
      setCurTaskType(_get(detail, "taskType"));
    });
  };

  useEffect(() => {
    getSubmissionDetails(submissionId);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabsData = tabs.map((tab) => {
    const { title } = tab;

    let component = null;
    if (title === "Quote")
      component = (
        <Quote data={details} userType={type} taskType={curTaskType} labStatus={labStatus} />
      );
    else if (title === "Task") component = <Task data={details} userType={type} />;
    else if (title === "Products") component = <Products data={details} userType={type} />;
    else if (title === "Supplier & Factory")
      component = <SupplierFactory data={details} userType={type} />;
    else if (title === "Invoice")
      component = <Invoice data={details} userType={type} taskType={curTaskType} />;

    return { ...tab, component };
  });

  return (
    <>
      <MenuBar
        data={details}
        submissionId={submissionId}
        userType={type}
        isAdmin={isAdmin}
        labStatus={labStatus}
        handleCancelTaskDialogOpen={handleCancelTaskDialogOpen}
      />
      <Tabs data={tabsData} gridDisplay />

      <Dialog
        className={classes.dialog}
        onClose={handleCancelTaskDialogClose}
        open={openCancelTaskDialog}
      >
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>Cancel Task</Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.dialogMessage}>
            The selected task below will be cancelled, and the action cannot be undone.
          </Box>
          <Box className={classes.dialogTableTitle}>Select task for cancellation</Box>
          <SelectTasksTable
            tasks={tasksForCancellation}
            selectedTaskIds={selectedCancelTaskIds}
            handleTaskSelect={handleCancelTaskSelect}
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCancelTaskDialogClose} color="primary">
            BACK
          </Button>

          <Button
            onClick={handleConfirmDialogOpen}
            color="primary"
            variant="contained"
            disabled={selectedCancelTaskIds.length === 0}
          >
            CONFIRM CANCEL
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Cancel Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All selected task will be cancelled, and this action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            BACK
          </Button>
          <Button onClick={handleTaskCancel} color="secondary" variant="contained">
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmissionDetail;
