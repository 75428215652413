import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  table: {
    marginTop: 15,
  },
  tableCell: {
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  labCell: {
    width: 200,
  },
  labLocationCell: {
    width: 1000,
    position: "relative",
  },
  labLocationsContainer: {
    width: 400,
    paddingTop: 10,
  },
  labLocation: {
    paddingBottom: 10,
  },
  labLocationName: {
    paddingTop: 5,
    cursor: "pointer",
  },
  viewContactDetails: {
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer",
    textDecoration: "underline",
    color: "#005EFF",
  },
  checkBoxContainer: {
    paddingRight: 8,
    cursor: "pointer",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxIconGrey: {
    color: "#AAAAAA",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  radioGroup: {
    paddingTop: 10,
  },
}));

const CustomiseRadio = withStyles({
  root: {
    "&$checked": {
      color: "#B0D400",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const SelectLab = ({
  isRetest = false,
  openTaskData = {},
  labs = [],
  handleOpenTaskDataChange,
  handleNextPage,
}) => {
  const classes = useStyles();

  const [openSkipQuoteDialog, setOpenSkipQuoteDialog] = useState(false);
  const [skipQuoteResult, setSkipQuoteResult] = useState("");

  useEffect(() => {
    if (openTaskData.isSkipQuote) {
      setSkipQuoteResult("skip");
    }
  }, [openTaskData]);

  const handleLabSelect = (labId) => {
    if (openTaskData.labIds.indexOf(labId) >= 0) {
      handleOpenTaskDataChange({
        target: {
          name: "labIds",
          value: openTaskData.labIds.filter((item) => item !== labId),
        },
      });
    } else {
      handleOpenTaskDataChange({
        target: {
          name: "labIds",
          value: openTaskData.labIds.concat(labId),
        },
      });
    }
  };

  const handleSkipQuoteChange = (e) => {
    setSkipQuoteResult(e.target.value);
  };

  const handleSkipQuoteSelect = () => {
    handleOpenTaskDataChange({
      target: {
        name: "isSkipQuote",
        value: skipQuoteResult === "skip",
      },
    });

    handleNextPage();
  };

  const handleCloseSkipQuoteDialog = () => {
    setOpenSkipQuoteDialog(false);
  };

  const handleConfirm = () => {
    if (!isRetest && openTaskData.labIds.length === 1 && !openTaskData.isSkipQuote) {
      setSkipQuoteResult("");
      return setOpenSkipQuoteDialog(true);
    }

    handleOpenTaskDataChange({
      target: {
        name: "isSkipQuote",
        value: isRetest || skipQuoteResult === "skip",
      },
    });

    handleNextPage();
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Currently Selected Lab</Box>

        {skipQuoteResult === "skip" && <Box>(Can select one lab only)</Box>}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classnames(classes.tableCell, classes.labCell)}>Lab</TableCell>
              <TableCell className={classnames(classes.tableCell, classes.labLocationCell)}>
                Lab Location
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labs.map((lab) => (
              <TableRow key={lab.labName}>
                <TableCell className={classnames(classes.tableCell, classes.labCell)}>
                  {lab.labName}
                </TableCell>
                <TableCell className={classnames(classes.tableCell, classes.labLocationCell)}>
                  <Grid container className={classes.labLocationsContainer}>
                    {lab.branches.map((branch, index) => (
                      <Grid item xs={6} key={branch.id} className={classes.labLocation}>
                        {skipQuoteResult === "skip" ? (
                          openTaskData.labIds.length === 0 ? (
                            <Box display="flex" onClick={() => handleLabSelect(branch.id)}>
                              <Box className={classes.checkBoxContainer}>
                                <CheckBoxOutlineBlankIcon
                                  className={classes.checkBoxOutlineBlankIcon}
                                />
                              </Box>
                              <Box className={classes.labLocationName}>{branch.labBranchName}</Box>
                            </Box>
                          ) : (
                            <>
                              {openTaskData.labIds.indexOf(branch.id) >= 0 ? (
                                <Box display="flex" onClick={() => handleLabSelect(branch.id)}>
                                  <Box className={classes.checkBoxContainer}>
                                    <CheckBoxIcon className={classes.checkBoxIcon} />
                                  </Box>
                                  <Box className={classes.labLocationName}>
                                    {branch.labBranchName}
                                  </Box>
                                </Box>
                              ) : (
                                <Box display="flex">
                                  <Box className={classes.checkBoxContainer}>
                                    <CheckBoxOutlineBlankIcon
                                      className={classes.checkBoxOutlineBlankIcon}
                                    />
                                  </Box>
                                  <Box className={classes.labLocationName}>
                                    {branch.labBranchName}
                                  </Box>
                                </Box>
                              )}
                            </>
                          )
                        ) : isRetest ? (
                          <Box display="flex">
                            <Box className={classes.checkBoxContainer}>
                              {openTaskData.labIds.indexOf(branch.id) >= 0 ? (
                                <CheckBoxIcon className={classes.checkBoxIconGrey} />
                              ) : (
                                <CheckBoxOutlineBlankIcon className={classes.checkBoxIconGrey} />
                              )}
                            </Box>
                            <Box className={classes.labLocationName}>{branch.labBranchName}</Box>
                          </Box>
                        ) : (
                          <Box display="flex" onClick={() => handleLabSelect(branch.id)}>
                            <Box className={classes.checkBoxContainer}>
                              {openTaskData.labIds.indexOf(branch.id) >= 0 ? (
                                <CheckBoxIcon className={classes.checkBoxIcon} />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  className={classes.checkBoxOutlineBlankIcon}
                                />
                              )}
                            </Box>
                            <Box className={classes.labLocationName}>{branch.labBranchName}</Box>
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  {/* <Box className={classes.viewContactDetails}>
                    View Contact Details
                  </Box> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleConfirm}
        disabled={openTaskData.labIds.length === 0}
      >
        CONTINUE
      </Button>

      <Dialog
        className={classes.dialog}
        onClose={handleCloseSkipQuoteDialog}
        open={openSkipQuoteDialog}
      >
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>
            Do You Wish to Skip Quote? (Please Select)
          </Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.dialogMessage}>
            Since you have only chosen 1 lab, did you intend to skip quote?
          </Box>
          <RadioGroup
            name="skipQuote"
            value={skipQuoteResult}
            onChange={handleSkipQuoteChange}
            className={classes.radioGroup}
          >
            <FormControlLabel value="skip" control={<CustomiseRadio />} label="Skip Quote" />
            <FormControlLabel
              value="needQuote"
              control={<CustomiseRadio />}
              label="No, I need a quotation"
            />
          </RadioGroup>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseSkipQuoteDialog} color="primary">
            BACK
          </Button>

          <Button
            onClick={handleSkipQuoteSelect}
            color="primary"
            variant="contained"
            disabled={!skipQuoteResult}
          >
            CONTINUE
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default SelectLab;
