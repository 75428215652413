import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { styled, Box, Grid } from "@material-ui/core";

import BasicModal from "components/modal/BasicModal";
import Select from "components/input/Select";
import MultipleSelect from "components/input/MultipleSelect";

import {
  atngetTenderingExemptionLabList,
  atnputTenderingLabExemption,
} from "actions/tenderingActions";
import { EXCEPTION_TASK_TYPE_MAPPING } from "shared/constants/tendering";
import { getLabExemptionTasks } from "services/tenderingService";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const InputTitle = styled(Box)({
  fontWeight: 500,
  color: "#000",
});

const ErrorBox = styled(Box)({
  fontWeight: 500,
  color: "#D42600",
});

const validationSchema = Yup.object().shape({
  taskType: Yup.string().required("Please select a test type."),
  labIds: Yup.array(Yup.number())
    .min(1, "Please select at least 1 lab")
    .required("Please select labs for exception."),
});

export default function ExemptionTenderingModal({
  tasks = [],
  masterWicId,
  open = false,
  onClose,
  onSubmit,
}) {
  const [labListOptions, setLabListOptions] = useState([]);
  const [isAllLabAssigned, setIsAllLabAssigned] = useState(false);
  const [selectedTaskRequirementId, setSelectedTaskRequirementId] = useState(null);

  const { labExemptionTasks } = getLabExemptionTasks(tasks);

  // seasonal only for eraly stage
  const taskTypeOptions = labExemptionTasks?.map((task) => ({
    label: EXCEPTION_TASK_TYPE_MAPPING[task?.taskType],
    value: task?.taskType,
  }));

  const getSelectedTaskRequirementId = (taskType) =>
    labExemptionTasks.filter((task) => task.taskType === taskType)[0]?.taskRequirementId || "";

  const formik = useFormik({
    initialValues: {
      taskType: "",
      labIds: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        taskType: values?.taskType,
        labIds: values?.labIds?.map((id) => parseInt(id, 10)) || [],
      };

      await atnputTenderingLabExemption(masterWicId, payload).then((res) => {
        if (res?.code !== 200) return;

        onClose();
        onSubmit();
        setSelectedTaskRequirementId(null);
      });
    },
  });

  useEffect(() => {
    if (!masterWicId || !selectedTaskRequirementId || !open) return;

    atngetTenderingExemptionLabList(masterWicId, selectedTaskRequirementId).then((res) => {
      const labData = res?.data || [];

      // convert api data to lit option format
      const labOptions = labData?.map((lab) => ({
        label: `${lab?.name}${lab?.assigned ? " (Assigned)" : ""}`,
        value: lab?.id,
        disabled: lab?.assigned,
      }));

      // set lab options
      setLabListOptions(labOptions || []);

      // set if is assigned all labs
      setIsAllLabAssigned(!!(labData?.length && labData?.every((lab) => lab?.assigned)) ?? false);
    });
  }, [masterWicId, selectedTaskRequirementId, open]);

  const handleSelectTaskTypeChange = (event) => {
    const { name, value } = event.target;

    setSelectedTaskRequirementId(getSelectedTaskRequirementId(value));

    formik.setFieldValue(name, value);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    formik.setFieldValue(name, value);
  };

  const getSelectOptionValue = () => {
    if (!formik.values.labIds) return [];

    return labListOptions?.filter((option) => formik.values.labIds?.includes(option.value));
  };

  return (
    <BasicModal
      divider
      open={open}
      onClose={onClose}
      actionsDisabled
      submitDisabled={isAllLabAssigned}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ fontSize: "10px" }}>Please select task type & lab(s) for exemption.</Box>
        </Grid>

        <Grid item xs={12}>
          <InputTitle>1. Select a task type</InputTitle>
        </Grid>

        <Grid item xs={12}>
          <Select
            fullWidth
            label="Task Type"
            name="taskType"
            value={formik.values.taskType}
            options={taskTypeOptions}
            handleChange={handleSelectTaskTypeChange}
            error={!!(formik.errors.taskType && formik.touched.taskType)}
            helperText={formik.touched.taskType && formik.errors.taskType}
            disabled={isAllLabAssigned}
          />
        </Grid>

        <Grid item xs={12}>
          <InputTitle>2. Select lab(s)</InputTitle>
        </Grid>

        <Grid item xs={12}>
          <MultipleSelect
            fullWidth
            label="Lab(s)"
            name="labIds"
            value={getSelectOptionValue()}
            options={labListOptions}
            handleChange={handleSelectChange}
            error={!!(formik.errors.labIds && !formik.touched.labIds?.length)}
            helperText={
              !!(formik.errors.labIds && !formik.touched.labIds?.length) && formik.errors.labIds
            }
            disabled={isAllLabAssigned}
          />
        </Grid>

        {isAllLabAssigned && (
          <Grid item xs={12}>
            <ErrorBox>All labs assigned already, lab exception is not allowed.</ErrorBox>
          </Grid>
        )}
      </Grid>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button
          autoFocus
          onClick={onClose}
          color={"primary"}
          disabled={isAllLabAssigned}
          // color={errorButton ? "error" : "primary"}
        >
          close
        </Button>

        <Button
          type="submit"
          variant="contained"
          color={"primary"}
          disabled={isAllLabAssigned}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </BasicModal>
  );
}
