import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Header from "./Header";
import TestingQuotationDetails from "./TestingQuotationDetails";
import InspectionQuotationDetails from "./InspectionQuotationDetails";
import { formatFloatingNumber } from "utils/string";

import { atnGetSubmissionDetails, atnSubmitQuotation } from "actions/submissionActions";
import useIsUL from "../../../hooks/useIsUL";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    marginTop: 30,
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 30,
    paddingBottom: 5,
  },
  selectField: {
    width: 200,
  },
  largeInputField: {
    width: 340,
  },
  inputField: {
    width: 130,
  },
  smallInputField: {
    width: 60,
  },
  deleteBtn: {
    padding: 5,
    marginLeft: 25,
  },
  subTotalTitle: {
    textAlign: "right",
    paddingRight: 10,
  },
  subTotal: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  addButtonContainer: {
    paddingTop: 20,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  priceSection: {
    paddingRight: 10,
    textAlign: "right",
  },
  vat: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 5,
  },
  totalPriceSection: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 30,
    textAlign: "right",
  },
  additionalInputTitle: {
    width: 300,
    paddingTop: 10,
  },
  displayInline: {
    display: "inline-block",
  },
  inlineTitle: {
    paddingRight: 40,
  },
}));

const Quotation = ({ match, history }) => {
  const submissionId = match.params.submissionId;

  const classes = useStyles();

  const isUl = useIsUL();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);
  const [submissionType, setSubmissionType] = useState("");
  const [quotationData, setQuotationData] = useState({
    currency: "USD",
    isPrepayment: false,
    expectedCompletionDate: "",
    cancellationFee: "",
    subTotal: 0,
    totalPrice: 0,
    packagePrice: "",
    expressServicePercentage: "",
    vat: "",
    remarks: "",
    packageRemark: "",
    tat: "",
    sampleSize: "",
    isPackagePrice: false,
  });

  const [testItems, setTestItems] = useState([
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
  ]);

  const [quotationInspectionTaskDetail, setQuotationInspectionTaskDetail] = useState({
    currency: "USD",
    aqlCritical: "0",
    aqlMajor: "2.5",
    aqlMinor: "4.0",
    expectedInspectionDate: "",
    expectedSamplePickUpDate: "",
    inspectionDateFrom: "",
    inspectionDateTo: "",
    inspectionLevel: "II",
    totalQuantity: "",
    isPrepayment: false,
    isPackagePrice: false,
    remarks: "",
    vat: 0,

    noOfMandays: 0,
    mandayUnitCost: 0,
    travelExpenses: 0,
    hotelExpenses: 0,
    otherExpenses: 0,
    lateBookingFee: 0,
    cancellationFee: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    atnGetSubmissionDetails({ data: { id: submissionId }, type: 3 }).then(({ data, message }) => {
      if (data && data.taskType) {
        if (data.taskType === "QT") {
          setSubmissionType("testing");
        } else if (data.taskType === "PST") {
          setSubmissionType("testing");
        } else if (data.taskType === "SS") {
          setSubmissionType("inspection");
        } else if (data.taskType === "SPU" || data.taskType === "SPUD") {
          setQuotationInspectionTaskDetail((prevState) => ({
            ...prevState,
            inspectionDateFrom: data.expectedSamplePickUpDate,
          }));
          setSubmissionType("inspection");
        } else if (data.taskType === "ST" || data.taskType === "STD") {
          setSubmissionType("testing");
        } else if (data.taskType === "DPI") {
          setQuotationInspectionTaskDetail((prevState) => ({
            ...prevState,
            inspectionDateFrom: data.expectedInspectionDate,
          }));
          setSubmissionType("inspection");
        } else if (data.taskType === "FRI" || data.taskType === "FRID") {
          setQuotationInspectionTaskDetail((prevState) => ({
            ...prevState,
            inspectionDateFrom: data.expectedInspectionDate,
          }));
          setSubmissionType("inspection");
        } else if (data.taskType === "PAC") {
          setSubmissionType("testing");
        }
      }
    });
  }, []);

  const handleQuotationDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "isPackagePrice") {
      if (value) {
        let newTotalPrice = quotationData.packagePrice || 0;

        if (quotationData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(quotationData.vat) / 100)).toFixed(2);
        }

        setQuotationData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      } else {
        let newTotalPrice = quotationData.subTotal;

        if (quotationData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(quotationData.vat) / 100)).toFixed(2);
        }

        setQuotationData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      }
    } else if (name === "packagePrice") {
      let newTotalPrice = value || 0;

      if (quotationData.vat) {
        newTotalPrice = (newTotalPrice * (1 + parseFloat(quotationData.vat) / 100)).toFixed(2);
      }

      setQuotationData((prevState) => ({
        ...prevState,
        [name]: value,
        totalPrice: newTotalPrice,
      }));
    } else if (name === "vat") {
      if (quotationData.isPackagePrice) {
        setQuotationData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? ((quotationData.packagePrice || 0) * (1 + parseFloat(value) / 100)).toFixed(2)
            : quotationData.packagePrice || 0,
        }));
      } else {
        setQuotationData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (quotationData.subTotal * (1 + parseFloat(value) / 100)).toFixed(2)
            : quotationData.subTotal,
        }));
      }
    } else {
      setQuotationData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleQuotationInspectionTaskDetailChange = (e) => {
    const { name, value } = e.target;
    setQuotationInspectionTaskDetail((prevState) => {
      let tempTotal = 0;
      let tempSubTotal = 0;

      if (
        name === "noOfMandays" ||
        name === "mandayUnitCost" ||
        name === "travelExpenses" ||
        name === "hotelExpenses" ||
        name === "otherExpenses" ||
        name === "lateBookingFee" ||
        name === "vat" ||
        name === "cancellationFee"
      ) {
        prevState[name] = parseFloat(value);
        tempTotal += (prevState.noOfMandays || 0) * (prevState.mandayUnitCost || 0);
        tempTotal += prevState.travelExpenses || 0;
        tempTotal += prevState.hotelExpenses || 0;
        tempTotal += prevState.otherExpenses || 0;
        tempTotal += prevState.lateBookingFee || 0;
        tempTotal += prevState.cancellationFee || 0;

        tempSubTotal = tempTotal;
        if (prevState.vat > 0) {
          tempTotal = tempTotal * (1 + parseFloat(prevState.vat) / 100);
        }

        return {
          ...prevState,
          [name]: parseFloat(value),
          totalPrice: tempTotal.toFixed(2),
          subTotal: tempSubTotal.toFixed(2),
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "isPackagePrice") {
      handleQuotationDataChange({
        target: {
          name: name,
          value: checked,
        },
      });
    } else {
      setQuotationData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleInspectionPrepaymentChange = (e) => {
    const { name, checked } = e.target;

    setQuotationInspectionTaskDetail((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleTestItemDataChange = (e, index) => {
    const { name, value } = e.target;

    setTestItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;

          if (name === "quantity") {
            if (value && item.unitPrice) {
              item.subTotal = parseInt(value) * parseFloat(item.unitPrice);
            } else {
              item.subTotal = 0;
            }
          }

          if (name === "unitPrice") {
            if (value && item.quantity) {
              item.subTotal = parseInt(item.quantity) * parseFloat(value);
            } else {
              item.subTotal = 0;
            }
          }
        }

        return item;
      });
    });

    setTimeout(() => {
      let subTotal = 0;

      for (let i = 0; i < testItems.length; i++) {
        if (testItems[i].quantity && testItems[i].unitPrice) {
          subTotal = formatFloatingNumber(
            subTotal + parseInt(testItems[i].quantity) * parseFloat(testItems[i].unitPrice),
          );
        }
      }

      let totalPrice = subTotal;

      if (quotationData.isPackagePrice) {
        totalPrice = quotationData.packagePrice || 0;
      }

      if (quotationData.vat) {
        totalPrice = (totalPrice * (1 + parseFloat(quotationData.vat) / 100)).toFixed(2);
      }

      setQuotationData((prevState) => ({
        ...prevState,
        subTotal: subTotal,
        totalPrice: totalPrice,
      }));
    }, 200);
  };

  const handleAddNewTestItem = () => {
    setTestItems((prevState) => {
      return prevState.concat({
        itemName: "",
        quantity: "",
        unitPrice: "",
        subTotal: 0,
      });
    });
  };

  const handleRemoveTestItem = (index) => {
    let subTotal = 0;

    for (let i = 0; i < testItems.length; i++) {
      if (i !== index && testItems[i].quantity && testItems[i].unitPrice) {
        subTotal = formatFloatingNumber(
          subTotal + (testItems[i].quantity || 0) * (testItems[i].unitPrice || 0),
        );
      }
    }

    let totalPrice = subTotal;

    if (quotationData.isPackagePrice) {
      totalPrice = quotationData.packagePrice || 0;
    }

    if (quotationData.vat) {
      totalPrice = (totalPrice * (1 + parseFloat(quotationData.vat || 0) / 100)).toFixed(2);
    }

    setQuotationData((prevState) => ({
      ...prevState,
      subTotal: subTotal,
      totalPrice: totalPrice,
    }));

    setTestItems((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleTestingSubmit = () => {
    setIsDisabledConformButton(true);
    let tempTestItems = [];

    for (let i = 0; i < testItems.length; i++) {
      if (
        testItems[i].quantity !== null &&
        testItems[i].quantity !== "" &&
        testItems[i].unitPrice !== null &&
        testItems[i].unitPrice !== ""
      ) {
        tempTestItems.push({
          itemName: testItems[i].itemName,
          quantity: parseInt(testItems[i].quantity),
          unitPrice: parseFloat(testItems[i].unitPrice),
        });
      }
    }

    let tempQuotationData = quotationData;
    tempQuotationData.vat = quotationData.vat || 0;

    if (tempQuotationData.isPackagePrice && !tempQuotationData.packagePrice) {
      tempQuotationData.packagePrice = 0;
    }

    let submitData = {
      submissionId: submissionId,
      quotation: tempQuotationData,
      quotationTestItems: tempTestItems,
    };

    atnSubmitQuotation(submitData).then(({ data, message }) => {
      setIsDisabledConformButton(false);
      setOpenConfirmDialog(false);

      if (data) {
        history.push(`/lab/submissions/${submissionId}`);
      }
    });
  };

  const handleInspectionSubmit = () => {
    setIsDisabledConformButton(true);
    let tempQuotationData = Object.keys(quotationInspectionTaskDetail)
      .filter(
        (key) =>
          key === "currency" ||
          key === "isPrepayment" ||
          key === "expectedCompletionDate" ||
          key === "cancellationFee" ||
          key === "totalPrice" ||
          key === "expressServicePercentage" ||
          key === "vat" ||
          key === "remarks" ||
          key === "tat" ||
          key === "sampleSize" ||
          key === "isPackagePrice" ||
          key === "packagePrice",
      )
      .reduce((obj, key) => {
        obj[key] = quotationInspectionTaskDetail[key];
        return obj;
      }, {});

    let tempQuotationInspectionTaskDetail = Object.keys(quotationInspectionTaskDetail)
      .filter(
        (key) =>
          key === "aqlCritical" ||
          key === "aqlMajor" ||
          key === "aqlMinor" ||
          key === "expectedInspectionDate" ||
          key === "inspectionDateFrom" ||
          key === "inspectionDateTo" ||
          key === "inspectionLevel" ||
          key === "totalQuantity",
      )
      .reduce((obj, key) => {
        obj[key] = quotationInspectionTaskDetail[key];
        return obj;
      }, {});

    tempQuotationData.expectedCompletionDate = tempQuotationData.expectedCompletionDate
      ? moment(tempQuotationData.expectedCompletionDate).format("YYYY-MM-DD")
      : null;
    tempQuotationInspectionTaskDetail.expectedInspectionDate =
      tempQuotationInspectionTaskDetail.expectedInspectionDate
        ? moment(tempQuotationInspectionTaskDetail.expectedInspectionDate).format("YYYY-MM-DD")
        : null;
    tempQuotationInspectionTaskDetail.inspectionDateFrom =
      tempQuotationInspectionTaskDetail.inspectionDateFrom
        ? moment(tempQuotationInspectionTaskDetail.inspectionDateFrom).format("YYYY-MM-DD")
        : null;
    tempQuotationInspectionTaskDetail.inspectionDateTo =
      tempQuotationInspectionTaskDetail.inspectionDateTo
        ? moment(tempQuotationInspectionTaskDetail.inspectionDateTo).format("YYYY-MM-DD")
        : null;

    const newTempTestItems = [
      {
        itemName: "Manday",
        quantity: quotationInspectionTaskDetail.noOfMandays,
        unitPrice: quotationInspectionTaskDetail.mandayUnitCost || 0,
      },
      {
        itemName: "Travel Expenses",
        quantity: 1,
        unitPrice: quotationInspectionTaskDetail.travelExpenses || 0,
      },
      {
        itemName: "Hotel Expenses",
        quantity: 1,
        unitPrice: quotationInspectionTaskDetail.hotelExpenses || 0,
      },
      {
        itemName: "Other Expenses",
        quantity: 1,
        unitPrice: quotationInspectionTaskDetail.otherExpenses || 0,
      },
      {
        itemName: "Late Booking Fee",
        quantity: 1,
        unitPrice: quotationInspectionTaskDetail.lateBookingFee || 0,
      },
      {
        itemName: "Cancellation Fee",
        quantity: 1,
        unitPrice: quotationInspectionTaskDetail.cancellationFee || 0,
      },
    ];

    if (
      !tempQuotationInspectionTaskDetail.inspectionDateFrom ||
      !tempQuotationInspectionTaskDetail.inspectionDateTo ||
      !newTempTestItems[0].quantity
    ) {
      setIsDisabledConformButton(false);
      setOpenConfirmDialog(false);
      return alert("missing data");
    }

    let submitData = {
      submissionId: submissionId,
      quotation: tempQuotationData,
      quotationTestItems: newTempTestItems,
      quotationInspectionTaskDetail: tempQuotationInspectionTaskDetail,
    };

    atnSubmitQuotation(submitData).then(({ data, message }) => {
      setIsDisabledConformButton(false);
      setOpenConfirmDialog(false);

      if (data) {
        history.push(`/lab/submissions/${submissionId}`);
      }
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>
          {submissionType === "testing" && <Box>Design Your Test Plan</Box>}

          {submissionType === "inspection" && <Box>Design Your Inspection Plan</Box>}
        </Box>

        {submissionType === "testing" && (
          <TestingQuotationDetails
            quotationData={quotationData}
            testItems={testItems}
            handleQuotationDataChange={handleQuotationDataChange}
            handleTestItemDataChange={handleTestItemDataChange}
            handleAddNewTestItem={handleAddNewTestItem}
            handleRemoveTestItem={handleRemoveTestItem}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}

        {submissionType === "inspection" && (
          <InspectionQuotationDetails
            quotationInspectionTaskDetail={quotationInspectionTaskDetail}
            handleQuotationInspectionTaskDetailChange={handleQuotationInspectionTaskDetailChange}
            handleInspectionPrepaymentChange={handleInspectionPrepaymentChange}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={handleOpenConfirmDialog}
          disabled={
            (submissionType === "testing" &&
              (testItems.length === 0 ||
                !testItems[0].itemName ||
                (!testItems[0].quantity && testItems[0].quantity !== 0) ||
                (!testItems[0].unitPrice && testItems[0].unitPrice !== 0))) ||
            (submissionType === "inspection" &&
              (!quotationInspectionTaskDetail.inspectionDateFrom ||
                !quotationInspectionTaskDetail.inspectionDateTo ||
                !quotationInspectionTaskDetail.noOfMandays ||
                quotationInspectionTaskDetail.noOfMandays <= 0))
          }
        >
          SUBMIT
        </Button>
      </Box>

      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm to submit?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isUl
              ? 'Ensure that all tasks conducted in China are set to use currency "RMB"'
              : "Do you confirm to submit?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Close
          </Button>
          <Button
            onClick={submissionType === "testing" ? handleTestingSubmit : handleInspectionSubmit}
            color="primary"
            disabled={isDisabledConformButton}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(null, {
  atnSubmitQuotation,
})(Quotation);
