import { USER_TYPE } from "shared/constants";
import api from "./api";

class submissionApi {
  static getSubmissionListHeader({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get("/supplier/submissions/header", {});
    } else if (type === USER_TYPE.lab) {
      return api.get("/lab/submissions/header", {});
    }
  }

  static getSubmissionListData({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.post("/supplier/submissions", data);
    } else if (type === USER_TYPE.lab) {
      return api.post("/lab/submissions", data);
    }
  }

  static getSubmissionDetails({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}`, {});
    }
  }

  static getSubmissionDetailsQuotations({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/quotations`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/quotations`, {});
    }
  }

  static getSubmissionDetailsTaskList({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/tasks`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/tasks`, {});
    }
  }

  static getSubmissionDetailsTaskDetail({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/tasks/${data.taskId}`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/tasks/${data.taskId}`, {});
    }
  }

  static getSubmissionDetailsProducts({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/products`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/products`, {});
    }
  }

  static getSubmissionDetailsSupplierFactory({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/supplier_and_factory`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/supplier_and_factory`, {});
    }
  }

  static updateSubmissionDetailsSupplierFactory({
    data: { submissionId, data },
    type = USER_TYPE.supplier,
  }) {
    return api.put(`/supplier/submissions/${submissionId}/suppliers_and_factory`, data);
  }

  static getSubmissionDetailsInvoice({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/invoice`, {});
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/invoice`, {});
    }
  }

  static getSubmissionResumeReportTasks({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/supplier/submissions/${data.id}/resume/tasks`);
    } else if (type === USER_TYPE.lab) {
      return api.get(`/lab/submissions/${data.id}/resume/tasks`);
    }
  }

  static putSubmissionResumeReport({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.put(`/supplier/submissions/${data.id}/resume_reports`, data.data);
    } else if (type === USER_TYPE.lab) {
      return api.put(`/lab/submissions/${data.id}/resume_reports`, data.data);
    }
  }

  static putSubmissionCompleteResumeReport({ data, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.put(`/supplier/submissions/${data.id}/complete_resume_reports`);
    } else if (type === USER_TYPE.lab) {
      return api.put(`/lab/submissions/${data.id}/complete_resume_reports`);
    }
  }

  //  ******************** lab admin start ********************

  static getLabAdminSubmissionListHeader(data) {
    return api.get("/lab_admin/submissions/header", {});
  }

  static getLabAdminSubmissionListData({ data }) {
    return api.post("/lab_admin/submissions", data);
  }

  static getLabAdminSubmissionDetails({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}`, {});
  }

  static getLabAdminSubmissionDetailsQuotations({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/quotations`, {});
  }

  static getLabAdminSubmissionDetailsTaskList({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/tasks`, {});
  }

  static getLabAdminSubmissionDetailsTaskDetail({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/tasks/${data.taskId}`, {});
  }

  static getLabAdminSubmissionDetailsProducts({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/products`, {});
  }

  static getLabAdminSubmissionDetailsSupplierFactory({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/supplier_and_factory`, {});
  }

  static getLabAdminSubmissionDetailsInvoice({ data }) {
    return api.get(`/lab_admin/submissions/${data.id}/invoice`, {});
  }

  static getLabAdminLabBranches(data) {
    return api.get("/lab_admin/submissions/lab_branches", {});
  }

  static assignLabBranchToSubmissions(data) {
    return api.post("/lab_admin/submissions/assign_lab", data);
  }

  // ******************** lab admin end ********************

  static submitQuotation(data) {
    return api.post(`/lab/submissions/${data.submissionId}/quotations`, data);
  }

  static confirmQuotation(data) {
    return api.post(
      `/supplier/submissions/${data.submissionId}/quotations/${data.quotationId}`,
      data,
    );
  }

  static getRelatedTasks(data) {
    return api.get(
      `/lab/status/submissions/${data.submissionId}?taskStatus=${data.updateType}`,
      data,
    );
  }

  static updateTaskStatus(data) {
    return api.put(`/lab/status`, data);
  }

  static getTestItems(data) {
    return api.get(`/lab/status/submissions/${data.submissionId}/invoice/test_items`, data);
  }

  static getCompleteData(data) {
    return api.get(`/lab/status/submissions/${data.submissionId}/complete`, data);
  }

  static updateCompleteStatus(data) {
    return api.put(`/lab/status/submissions/${data.submissionId}/complete`, data);
  }

  static getTasksForCancellation(data) {
    return api.get(`/supplier/submissions/${data.submissionId}/tasks_for_cancellation`, data);
  }

  static cancelTasks(data) {
    return api.put(`/supplier/submissions/${data.submissionId}/tasks/cancel`, data.data);
  }

  static getSubmissionTasks(data) {
    return api.get(
      `/supplier/submissions/${data.submissionId}/tasks/submission_tasks_for_update/${data.taskType}`,
      data,
    );
  }

  static editSubmission(data) {
    return api.put(`/supplier/submissions/${data.submissionId}`, data);
  }

  static updateSampleSealingResult(data) {
    if (data.type === USER_TYPE.buyer) {
      return api.post(
        `/buyer/items/master_wic/${data.masterWicId}/tasks/${data.taskId}/sample_sealing_result`,
        data.data,
      );
    } else if (data.type === USER_TYPE.lab) {
      return api.post(
        `/lab/submissions/${data.submissionId}/tasks/${data.taskId}/sample_sealing_result`,
        data.data,
      );
    }
  }

  static deleteSampleSealingResult(data) {
    if (data.type === USER_TYPE.buyer) {
      return api.post(
        `/buyer/items/master_wic/${data.masterWicId}/tasks/${data.taskId}/sample_sealing_result/delete`,
        data.data,
      );
    } else if (data.type === USER_TYPE.lab) {
      return api.post(
        `/lab/submissions/${data.submissionId}/tasks/${data.taskId}/sample_sealing_result/delete`,
        data.data,
      );
    }
  }

  static completeSampleSealing(data) {
    if (data.type === USER_TYPE.buyer) {
      return api.put(
        `/buyer/items/master_wic/${data.masterWicId}/tasks/${data.taskId}/sample_sealing_result/complete`,
        data.data,
      );
    } else if (data.type === USER_TYPE.lab) {
      return api.put(
        `/lab/submissions/${data.submissionId}/tasks/${data.taskId}/sample_sealing_result/complete`,
        data.data,
      );
    }
  }

  static getSubmissionTrfDetails(data) {
    return api.get(`/supplier/submissions/${data.submissionId}/trf_details`, data);
  }

  static createTrfForm(data) {
    return api.post("/supplier/trf", data);
  }

  static updateQuotationCost(data) {
    return api.put(
      `/lab/status/submissions/${data.submissionId}/quotations/${data.quotation.id}`,
      data,
    );
  }

  static updateInvoiceCost(data) {
    return api.put(
      `/lab/status/submissions/${data.submissionId}/invoices/${data.invoice.id}`,
      data,
    );
  }

  static resumeInvoice(data) {
    return api.put(`/lab/submissions/${data.submissionId}/invoice/${data.invoice.id}/resume`, data);
  }

  static updateReport(data) {
    return api.put(`/lab/status/submissions/${data.submissionId}/reports`, data.data);
  }

  static createReviseReport(data) {
    return api.post(
      `/lab/status/submissions/${data.submissionId}/tasks/${data.taskId}/reports`,
      data.data,
    );
  }

  static deleteReport(data) {
    return api.delete(`/lab/status/submissions/${data.submissionId}/reports`, data.data);
  }

  static getMasterWicTrfFormData(data) {
    return api.get(`/supplier/items/${data.masterWicId}/trf_details`, data);
  }

  static downloadAllFiles(data) {
    if (data.type === USER_TYPE.supplier) {
      return api.download(`/supplier/submissions/${data.submissionId}/download_reports`, {});
    } else if (data.type === USER_TYPE.lab) {
      return api.download(`/lab/submissions/${data.submissionId}/download_all_files`, {});
    }
  }

  static createPACCert(data) {
    return api.post(`/lab/status/tasks/${data.taskId}/pac_cert`, data);
  }

  static createNewInvoice(data) {
    return api.post(`/lab/invoice/submissions/${data.submissionId}`, data.data);
  }

  static updateCAPStatus(data) {
    return api.put(`/lab/status/submissions/${data.submissionId}/cap`, data.data);
  }

  static uploadCAPReport(data) {
    return api.post(
      `/supplier/submissions/${data.submissionId}/tasks/${data.taskId}/cap`,
      data.data,
    );
  }

  static notifyCannotComplete(data) {
    return api.post(`/lab/status/tasks/${data.taskId}/notify_cannot_complete`, {});
  }

  static getSubmissionsPayer(data) {
    return api.get(`/supplier/submissions/payers`, {});
  }

  static updatePayerSubmission(data) {
    return api.put(`/supplier/submissions/${data.submissionId}/payer`, data.data);
  }
}

export default submissionApi;
