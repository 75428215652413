import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(() => ({
  container: {
    height: 36,
  },
  searchFieldContainer: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #AAAAAA",
    borderRadius: "2px 0 0 2px",
    width: 400,
    height: 36,
  },
  searchIcon: {
    position: "absolute",
    top: 7,
    left: 6,
    height: 22,
  },
  clearIcon: {
    position: "absolute",
    top: 6,
    right: 6,
    height: 22,
    cursor: "pointer",
  },
  input: {
    marginLeft: 33,
    width: 600,
    "& .MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 4px",
    },
  },
  searchButton: {
    height: 36,
    padding: "0px 16px",
    fontSize: 14,
    borderRadius: "0 2px 2px 0",
    backgroundColor: "#005EFF",
    boxShadow: "none",
  },
}));

const SearchTableButton = ({
  value = "",
  cbHandleSearch,
  placeHolder = "Search ....",
  handleFilteredList,
}) => {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState(value);
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      cbHandleSearch(searchQuery);
    }
  };

  const handleSearch = (e) => {
    cbHandleSearch(searchQuery);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const cancelSearchQuery = () => {
    setSearchQuery("");
    if (handleFilteredList) handleFilteredList("");
  };

  useEffect(() => {
    if (value === "") {
      setSearchQuery("");
    }
  }, [value]);

  return (
    <Box display="flex" className={classes.container}>
      <Box display="flex" className={classes.searchFieldContainer}>
        <SearchIcon className={classes.searchIcon} />
        <InputBase
          className={classes.input}
          placeholder={placeHolder}
          name="keyword"
          value={searchQuery}
          onChange={handleChange}
          onKeyUp={handleEnter}
        />

        {searchQuery && searchQuery !== "" && (
          <ClearIcon className={classes.clearIcon} onClick={() => cancelSearchQuery()} />
        )}
      </Box>

      <Box display="flex">
        <Button
          variant="contained"
          color="primary"
          className={classes.searchButton}
          onClick={handleSearch}
        >
          SEARCH
        </Button>
      </Box>
    </Box>
  );
};

export default SearchTableButton;
