import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import GifLoading from "components/display/GifLoading";
import Select from "components/input/Select";
import SearchSection from "components/searchBar/SearchSection";
import SubmissionListTab from "./SubmissionListTab";
import SubmissionListTable from "./SubmissionListTable/index";
import { formatHeader } from "utils/formatSubmissionTableData";
import {
  atnAssignLabBranchToSubmissions,
  atnGetLabAdminLabBranches,
  atnGetLabAdminSubmissionListData,
  atnGetLabAdminSubmissionListHeader,
} from "actions/submissionActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  topContainer: {
    height: 80,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #AAAAAA",
  },
  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  tableInfo: {
    fontWeight: "normal",
    fontSize: 10,
    paddingLeft: 10,
  },
  tableContainer: {
    paddingTop: 10,
    width: "fit-content",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
      padding: "4px 6px",
    },
  },
  dialogContent: {
    padding: "10px 30px",
    borderBottom: "none",
  },
  dialogInfo: {
    fontSize: 10,
  },
  dialogSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 30,
  },
  dialogSelectText: {
    fontSize: 12,
    fontWeight: "normal",
    paddingTop: 5,
  },
  dialogSelect: {
    paddingTop: 6,
    fontSize: 12,
    fontWeight: "normal",
    width: 312,
  },
  dialogEditContainer: {
    borderTop: "1px solid #AAAAAA",
    marginTop: 110,
    minHeight: 450,
  },
  dialogEditSection: {
    width: 400,
  },
  dialogConfirmButton: {
    borderRadius: 2,
    height: 36,
  },
  datePicker: {
    paddingTop: 5,
    "& .MuiInputBase-root": {
      "& input": {
        padding: 11,
      },
    },
  },
  imageGalleryContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    backgroundColor: "rgb(0, 0, 0, .5)",
    zIndex: 999,
  },
  imageGallery: {
    top: 0,
    bottom: 0,
  },
  imageGalleryImage: {
    height: 500,
    objectFit: "contain",
  },
  manageColumnContainer: {
    paddingTop: 30,
  },
  manageColumnBoxTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
  },
  manageColumnBox: {
    width: 320,
    border: "1px solid #000000",
    borderRadius: 4,
  },
  addTaskTypeButton: {
    marginTop: 8,
  },
}));

const LabAdminSubmissionList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingTable, setLoadingTable] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [labBranches, setLabBranches] = useState([]);
  const [selectedLabBranch, setSelectedLabBranch] = useState("");
  const [selectedSubmission, setSelectedSubmission] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [submissionList, setSubmissionList] = useState([]);
  const [filterName, setFilterName] = useState("");

  const actionMenuItems = [
    // {
    //   name: "export",
    //   type: "export",
    //   label: `Export (${selectedSubmission.length === 0 ? "All" : selectedSubmission.length}) Submission`
    // },
    {
      name: "assignLab",
      type: "assignLab",
      label: `Assign (${selectedSubmission.length}) to Different Lab`,
    },
  ];

  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [progressNumber, setProgressNumber] = useState({
    all: 0,
    completed: 0,
    selectedItem: 0,
    onGoing: 0,
  });

  const [selectedFilter, setSelectedFilter] = useState({
    labName: [],
    taskType: [],
    taskStatus: [],
  });
  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });
  const [searchSectionData, setSearchSectionData] = useState({
    keyword: "",
  });

  const [tab, setTab] = useState(0);

  useEffect(() => {
    setLoadingTable(true);

    atnGetLabAdminSubmissionListHeader({
      type: 3,
    }).then(({ data, message }) => {
      if (data && data[0]) {
        setHeaderData(formatHeader(data[0].header, t));
      }
    });

    atnGetLabAdminSubmissionListData({
      type: 3,
      data: {},
    }).then(({ data, message }) => {
      if (data) {
        setSubmissionList(data.list);
        setProgressNumber(data.progressNumber);

        let totalRecords = 0;

        if (tab === 0) {
          totalRecords = data.progressNumber.all || 0;
        } else if (tab === 1) {
          totalRecords = data.progressNumber.waitingForLabQuote || 0;
        } else if (tab === 2) {
          totalRecords = data.progressNumber.onGoing || 0;
        } else if (tab === 3) {
          totalRecords = data.progressNumber.completed || 0;
        }

        let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setInitLoading(false);
        setLoadingTable(false);
      }
    });

    atnGetLabAdminLabBranches().then(({ data, message }) => {
      if (data) {
        let tempLabBranches = [];

        for (let i = 0; i < data.length; i++) {
          tempLabBranches.push({
            value: data[i].id,
            label: data[i].labName,
          });
        }

        setLabBranches(tempLabBranches);
      }
    });
  }, []);

  const searchSubmissions = (page) => {
    setLoadingTable(true);
    let filters = [];

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["waiting_for_lab_quote"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: pageData.rowsPerPage,
      filters: filters,
      search: searchSectionData.keyword,
    };

    atnGetLabAdminSubmissionListData({
      type: 3,
      data: conditions,
    }).then(({ data, message }) => {
      if (data) {
        setSubmissionList(data.list);
        setProgressNumber(data.progressNumber);

        let totalRecords = 0;

        if (tab === 0) {
          totalRecords = data.progressNumber.all || 0;
        } else if (tab === 1) {
          totalRecords = data.progressNumber.waitingForLabQuote || 0;
        } else if (tab === 2) {
          totalRecords = data.progressNumber.onGoing || 0;
        } else if (tab === 3) {
          totalRecords = data.progressNumber.completed || 0;
        }

        let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    if (initLoading) {
      return;
    }

    searchSubmissions();
  }, [
    pageData.currentPage,
    pageData.rowsPerPage,
    tab,
    searchConditions.orderBy,
    searchConditions.order,
    selectedFilter.labName.length,
    selectedFilter.taskType.length,
    selectedFilter.taskStatus.length,
  ]);

  const handleSearchDataChange = (e) => {
    const { name, value } = e.target;

    setSearchSectionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    searchSubmissions(1);
  };

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));
  };

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleSubmissionSelect = (e, submissionId, submissionIndex) => {
    if (e.target.checked) {
      if (selectedSubmission.indexOf(submissionId) === -1) {
        setSelectedSubmission((prevState) => {
          return prevState.concat(submissionId);
        });
      }
    } else {
      if (selectedSubmission.indexOf(submissionId) >= 0) {
        setSelectedSubmission((prevState) => {
          return prevState.filter((item) => {
            return item !== submissionId;
          });
        });
      }
    }
  };

  const [openAssignLabDialog, setOpenAssignLabDialog] = useState(false);

  const handleOpenDialog = (type) => {
    if (type === "export") {
      // setOpenEditDialog(true);
    } else if (type === "assignLab") {
      setSelectedLabBranch("");
      setOpenAssignLabDialog(true);
    }
  };

  const handleCloseAssignLabDialog = () => {
    setOpenAssignLabDialog(false);
    setSelectedLabBranch("");
  };

  const handleLabBranchChange = (e) => {
    const { value } = e.target;

    setSelectedLabBranch(value);
  };

  const handleConfirmAssignLab = () => {
    if (selectedSubmission.length > 0 && selectedLabBranch) {
      let tempData = [];

      for (let i = 0; i < selectedSubmission.length; i++) {
        tempData.push({
          labBranchId: selectedLabBranch,
          submissionId: selectedSubmission[i],
        });
      }

      atnAssignLabBranchToSubmissions(tempData).then(({ data, message }) => {
        setOpenAssignLabDialog(false);

        if (data) {
          window.location.reload();
        }
      });
    } else {
      setOpenAssignLabDialog(false);
    }
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (filterName === "taskType") {
      if (filterItemValue === "ST") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["ST", "STD"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "ST" && item !== "STD"),
          }));
        }
      } else if (filterItemValue === "SPU") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["SPU", "SPUD"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "SPU" && item !== "SPUD"),
          }));
        }
      } else if (filterItemValue === "FRI") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["FRI", "FRID"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "FRI" && item !== "FRID"),
          }));
        }
      } else {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(filterItemValue),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
          }));
        }
      }
    } else {
      if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
        setSelectedFilter((prevState) => ({
          ...prevState,
          [filterName]: prevState[filterName].concat(filterItemValue),
        }));
      } else {
        setSelectedFilter((prevState) => ({
          ...prevState,
          [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
        }));
      }
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleOpenSubmission = (id) => {
    window.open(`/lab_admin/submissions/${id}`);
  };

  return (
    <Box className={classes.root}>
      {loadingTable && <GifLoading />}

      <Box className={classes.topContainer}>
        <SearchSection
          title="Task Submission List"
          searchSectionData={searchSectionData}
          handleSearchDataChange={handleSearchDataChange}
          handleSearch={handleSearch}
          hasActionMenu={true}
          actionMenuItems={actionMenuItems}
          handleOpenDialog={handleOpenDialog}
          selectedItemNumber={selectedSubmission.length}
        />
      </Box>

      <Box>
        <SubmissionListTab
          tab={tab}
          progressNumber={progressNumber}
          handleTabChange={handleTabChange}
        />
      </Box>

      <Box className={classes.contentContainer}>
        <Typography className={classes.tableTitle} variant="body1">
          {tab === 0 && <>Viewing All Submissions</>}
          {tab === 1 && <>Viewing Waiting For Lab Quote Submissions</>}
          {tab === 2 && <>Viewing Ongoing Submissions</>}
          {tab === 3 && <>Viewing Completed Submissions</>}
        </Typography>
        <Typography className={classes.tableInfo} variant="body1">
          <>
            Showing{" "}
            {pageData.totalRecords > 0 ? (pageData.currentPage - 1) * pageData.rowsPerPage + 1 : 0}{" "}
            -{" "}
            {pageData.currentPage * pageData.rowsPerPage > pageData.totalRecords
              ? pageData.totalRecords
              : pageData.currentPage * pageData.rowsPerPage}{" "}
            of {pageData.totalRecords}
          </>
        </Typography>

        {(!initLoading || !loadingTable) && (
          <Box className={classes.tableContainer}>
            <SubmissionListTable
              selectedSubmission={selectedSubmission}
              selectedFilter={selectedFilter}
              filterName={filterName}
              handleSubmissionSelect={handleSubmissionSelect}
              headerData={headerData}
              rowData={submissionList}
              handleRowClick={handleOpenSubmission}
              handleSorting={handleSorting}
              handleFilterListToggle={handleFilterListToggle}
              handleFilterItemSelect={handleFilterItemSelect}
              rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
              currentPage={pageData.currentPage}
              rowsPerPage={pageData.rowsPerPage}
              totalPages={pageData.totalPages}
              totalRecords={pageData.totalRecords}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>

      <Dialog
        onClose={handleCloseAssignLabDialog}
        open={openAssignLabDialog}
        className={classes.dialog}
      >
        <MuiDialogTitle onClose={handleCloseAssignLabDialog} className={classes.dialogTitle}>
          Assign Submission to Different Lab
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Assigning {selectedSubmission.length} Submission(s) to a different lab location
          </Box>

          <Box className={classes.dialogSubtitle}>Choose Lab Location</Box>

          <Box className={classes.dialogSelectText}>Lab Location</Box>

          <Box>
            <Select
              label=""
              name="selectedLabBranch"
              value={selectedLabBranch}
              options={labBranches}
              handleChange={handleLabBranchChange}
              additionalClass={classes.dialogSelect}
            />
          </Box>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseAssignLabDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleConfirmAssignLab}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!selectedLabBranch}
          >
            CONFIRM EDIT
          </Button>
        </MuiDialogActions>
      </Dialog>
    </Box>
  );
};

export default LabAdminSubmissionList;
