import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import VideoDownload from "components/input/VideoDownload";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  container: {
    padding: 30,
  },
  videoContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const VideoPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 1,
                fileName: "Acknowledge.mov",
                fileUrl: "acknowledge.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 2,
                fileName: "Quality_Task.mov",
                fileUrl: "quality_task.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 3,
                fileName: "Sample_Pick_Up.mov",
                fileUrl: "sample_pick_up.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 4,
                fileName: "Inspection_Task.mov",
                fileUrl: "inspection_task.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 5,
                fileName: "Revise_Testing_Submission.mov",
                fileUrl: "revise_testing_submission.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 6,
                fileName: "Revise_Sample_Pick_Up_Submission.mov",
                fileUrl: "revise_sample_pick_up_submission.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 7,
                fileName: "Revise_Inspection_Submission.mov",
                fileUrl: "revise_inspection_submission.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 8,
                fileName: "Remove_Task_from_Submission.mov",
                fileUrl: "remove_task_from_submission.mov",
              },
            ]}
            editable={false}
          />
        </Box>
        <Box className={classes.videoContainer}>
          <VideoDownload
            data={[
              {
                id: 9,
                fileName: "Add_Task_to_Submission.mov",
                fileUrl: "add_task_to_submission.mov",
              },
            ]}
            editable={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPage;
