import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#2A77F6",
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const Status = ({ list = [], cbSetFilter }) => {
  const classes = useStyles();

  const [listCheck, setListCheck] = useState(list);

  const handleChange = (event) => {
    setListCheck(
      listCheck.map((item, index) => {
        return {
          ...item,
          checked: item.value === event.target.name ? event.target.checked : item.checked,
        };
      }),
    );
  };

  useEffect(() => {
    cbSetFilter(listCheck);
  }, [listCheck]);

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {listCheck.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox checked={item.checked} onChange={handleChange} name={item.value} />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default Status;
