import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const content = [
  "Before you confirm, please ensure the invoice amount on the system is correct.",
  "After this task is marked as completed,",
  "- You will not be able to change or update this task.",
  "- The applicant will receive an invoice via email / ICW.",
];

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 430,
        width: "100%",
        minHeight: 180,
      },
    },
  },
}));

const ConfirmModal = ({
  submitVariant = "text",
  submitText = "SUBMIT",
  closeText = "CLOSE",
  title = "Confirm to submit?",
  contentText = "",
  contentTextClass,
  contentList = content,
  hasContentList = false,
  isDisabledSubmit,
  open,
  onSubmit,
  onClose,
  className,
  ...props
}) => {
  const classes = useStyles();
  const [isUl, setIsUl] = useState(false);
  useEffect(() => {
    const companyName = localStorage.getItem("companyName");
    if (companyName.includes("UL")) {
      setIsUl(true);
      // contentList = ulContent;
    }
  }, []);

  return (
    <Dialog
      data-cy="confirm-modal"
      open={open}
      onClose={onClose}
      className={classnames(classes.dialog, className)}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {contentText && (
          <DialogContentText className={contentTextClass}>{contentText}</DialogContentText>
        )}

        {hasContentList &&
          (isUl ? (
            <>
              <Box>
                Before you confirm, please ensure the invoice amount on the system is correct.
              </Box>
              <Box sx={{ color: "red" }}>
                Ensure that all tasks conducted in China are set to use currency "RMB"
              </Box>
              <Box>After this task is marked as completed,</Box>
              <Box>- You will not be able to change or update this task.</Box>
              <Box>- The applicant will receive an invoice via email / ICW.</Box>
            </>
          ) : (
            contentList.length > 0 && contentList.map((text, idx) => <Box key={idx}>{text}</Box>)
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {closeText}
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant={submitVariant}
          disabled={isDisabledSubmit}
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ConfirmModal);
