import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Program777Chip from "components/display/Program777Chip";

import { isShowProgram777 } from "services/pbService";

import { atnGetRelatedTasks } from "actions/submissionActions";
import { TASK_TRIGGERED_BY } from "shared/constants";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  error: {
    color: "#D42600",
  },
  fontSmall: {
    fontSize: 12,
  },
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const SelectTasks = ({
  submissionId,
  reviseReportData = {},
  handleReviseReportDataChange,
  handleNextPage,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState(reviseReportData.selectedTasks);

  const hasProgram777Task = relatedTasks?.some(
    (item) => item?.triggeredBy === TASK_TRIGGERED_BY.PB,
  );

  useEffect(() => {
    setSelectedTasks(reviseReportData.selectedTasks);
    let tempSelectedTaskIds = [];

    for (let i = 0; i < reviseReportData.selectedTasks.length; i++) {
      tempSelectedTaskIds.push(reviseReportData.selectedTasks[i].id);
    }

    setSelectedTaskIds(tempSelectedTaskIds);
  }, [reviseReportData.selectedTasks]);

  useEffect(() => {
    setLoading(true);
    setRelatedTasks([]);

    atnGetRelatedTasks({
      submissionId: submissionId,
      updateType: reviseReportData.updateType,
    }).then(({ data, message }) => {
      if (data) {
        setRelatedTasks(data);
        setLoading(false);
      }
    });
  }, [submissionId, reviseReportData.updateType]);

  // const getRelatedTasks = type => {
  //   setLoading(true);
  //   setRelatedTasks([]);

  //   atnGetRelatedTasks({
  //     submissionId: submissionId,
  //     updateType: type
  //   })
  //   .then(({data, message}) => {
  //     if (data) {
  //       setRelatedTasks(data);
  //       setLoading(false);
  //     }
  //   });
  // };

  // const handleUpdateTypeChange = e => {
  //   const { value } = e.target;

  //   handleReviseReportDataChange(e);
  //   getRelatedTasks(value);
  // };

  const handleTaskSelect = (task) => {
    if (hasProgram777Task) {
      //set only 1 task
      setSelectedTasks([task]);
      setSelectedTaskIds([task.id]);

      return;
    }

    const hasTaskId = selectedTaskIds?.indexOf(task.id) >= 0;

    if (hasTaskId) {
      setSelectedTasks((prevState) => {
        return prevState?.length > 0 ? prevState?.filter((item) => item.id !== task.id) : prevState;
      });

      setSelectedTaskIds((prevState) => {
        return prevState?.length > 0 ? prevState?.filter((item) => item !== task.id) : prevState;
      });
    } else {
      setSelectedTasks((prevState) => {
        return prevState?.length > 0 ? [...prevState, task] : [task];
      });

      setSelectedTaskIds((prevState) => {
        return prevState?.length > 0 ? [...prevState, task.id] : [task.id];
      });
    }
  };

  const handleConfirm = () => {
    // check empty
    handleReviseReportDataChange({
      target: {
        name: "selectedTasks",
        value: selectedTasks,
      },
    });
    handleNextPage();
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Please Select Tasks</Box>

      <Paper className={classes.container}>
        {reviseReportData.updateType && (
          <Box>
            {!loading && relatedTasks.length === 0 && <Box>No Task</Box>}

            {!loading && relatedTasks.length > 0 && (
              <TableContainer className={classes.tableContainer}>
                {hasProgram777Task && (
                  <Box className={classnames(classes.fontSmall, classes.error)}>
                    You can only select 1 task
                  </Box>
                )}

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderCell} />
                      <TableCell className={classes.tableHeaderCell}>Product Name</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Program</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Task Type</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Factory</TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        {SIMPLE_BUYER_NAME} Deadline
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relatedTasks?.map((task) => {
                      const showProgram777 = isShowProgram777(task.triggeredBy);

                      return (
                        <TableRow
                          key={task.id}
                          className={classes.tableRow}
                          onClick={() => handleTaskSelect(task)}
                        >
                          <TableCell scope="row" align="center" className={classes.tableCell}>
                            {selectedTaskIds.indexOf(task.id) >= 0 ? (
                              <CheckBoxIcon className={classes.checkBoxIcon} />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkBoxOutlineBlankIcon}
                              />
                            )}
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box display="flex" className={classes.tableText}>
                              {showProgram777 && <Program777Chip />}
                              {task.wicNumber}
                            </Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              {task.productName}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{task.programName}</Box>
                            <Box className={classnames(classes.buyer, classes.tableText)}>
                              {task.buyerCompanyName}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{t(`task.${task.taskType}`)}</Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              {task.taskNumber}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{task.factoryName}</Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              {task.factoryAddress}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>
                              {moment(task.deadLine).format("DD/MMM/YYYY")}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleConfirm}
        disabled={selectedTasks.length === 0}
      >
        CONTINUE
      </Button>
    </Box>
  );
};

export default SelectTasks;
