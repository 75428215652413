import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import { Box } from "@material-ui/core";

import PayerDetail, { defaultBillingAddress } from "pages/supplier/Workflow/components/PayerDetail";

import Header from "./Header";

import { atnGetTaskPayer, atnUpdateTaskPayer } from "actions/taskActions";

import { ratnNavigateToInvoiceTab } from "state/submissionDetail/action";

const payerErrorMessage = "Cannot update payer, please try again.";

export default function EditPayerInfo({ match, history }) {
  const dispatch = useDispatch();

  const { submissionId, taskId } = match.params;

  // for payer info
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [isPayerInfoLoading, setIsPayerInfoLoading] = useState(false);
  const [payerInfo, setPayerInfo] = useState({
    billingAddress: defaultBillingAddress,
  });
  const [payerError, setPayerError] = useState("");

  useEffect(() => {
    getSampleSealingTaskPayerInfo(taskId);
  }, [taskId]);

  useEffect(() => {
    if (payerInfo?.billingAddress?.taxInformationFiles?.length > 0) {
      const convertedAttachmentFiles = payerInfo?.billingAddress?.taxInformationFiles?.map(
        (item) => {
          return {
            id: item?.id,
            name: item?.fileName,
          };
        },
      );

      setPayerInfo((state) => ({
        ...state,
        billingAddress: {
          ...state.billingAddress,
          attachments: convertedAttachmentFiles,
        },
      }));
    }
  }, [payerInfo?.billingAddress?.taxInformationFiles]);

  useEffect(() => {
    handleNavigateToInvoiceTab(true);
  }, []);

  const handleNavigateToInvoiceTab = (show = false) => {
    dispatch(ratnNavigateToInvoiceTab(show));
  };

  //* payer info for SS task with lab flow
  const getSampleSealingTaskPayerInfo = (id) => {
    setIsPayerInfoLoading(true);

    atnGetTaskPayer({ taskId: id })
      .then((res) => {
        if (res.code === 404 || !res?.data) return;

        setPayerInfo((state) => ({ ...state, billingAddress: res?.data }));
      })
      .finally(() => {
        setIsPayerInfoLoading(false);
      });
  };

  const handlePayerInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setPayerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setPayerError("");
    setIsDisabledConfirmButton(true);

    let tempBillingAddress = payerInfo?.billingAddress;

    if (isNewBillingAddress) {
      delete payerInfo?.billingAddress.id;
    }

    if (payerInfo?.billingAddress.invoiceType === "icw_company_invoice") {
      delete tempBillingAddress.attachments;
      delete tempBillingAddress.taxInformationFiles;
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerInfo?.billingAddress.invoiceType === "plain_vat_invoice") {
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerInfo?.billingAddress.invoiceType !== "icw_company_invoice") {
      let taxInformationFiles = [];

      for (let i = 0; i < payerInfo?.billingAddress?.attachments?.length; i++) {
        taxInformationFiles.push({
          id: payerInfo?.billingAddress?.attachments[i]?.id,
          type: "tax_information",
        });
      }

      tempBillingAddress.taxInformationFiles = taxInformationFiles;

      delete tempBillingAddress.attachments;
    }

    atnUpdateTaskPayer({ taskId, data: payerInfo?.billingAddress })
      .then((res) => {
        if (!res?.data) return setPayerError(payerErrorMessage);

        history.push(`/supplier/submissions/${submissionId}`);
      })
      .finally(() => {
        setIsDisabledConfirmButton(false);
      });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <EditPayerWrapper>
      <Header
        title="Payer Detail for Invoice"
        subtitle="Enter Payer Detail for Invoice"
        onClose={handleClose}
      />

      <Subheading>Enter Payer Detail for Invoice</Subheading>

      <PayerDetail
        continueButtonText="CONFIRM"
        isDisabledConfirmButton={isPayerInfoLoading || isDisabledConfirmButton}
        errors={[payerError]}
        data={payerInfo}
        handleDataChange={handlePayerInfoChange}
        handleSubmit={handleSubmit}
      />
    </EditPayerWrapper>
  );
}

const EditPayerWrapper = withStyles({
  root: {
    margin: "24px 32px",
    padding: "80px 0 ",
  },
})((props) => <Box {...props} />);

const Subheading = withStyles({
  root: {
    fontWeight: 500,
    fontSize: "18px",
    textAlign: "center",
    padding: "24px 0 ",
  },
})((props) => <Box {...props} />);
