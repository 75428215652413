// export const LOADING_SUBMISSION = "LOADING_SUBMISSION";
export const ERROR_SUBMISSION = "ERROR_SUBMISSION";

export const TOGGLE_PB_ERROR_MODAL = "TOGGLE_PB_ERROR_MODAL";

//supplier types for pb revision
export const TOGGLE_SUBMISSION_PB_REVISION_MODAL = "TOGGLE_SUBMISSION_PB_REVISION_MODAL";
export const TOGGLE_SUBMISSION_PB_REVISION_MODAL_FAILURE =
  "TOGGLE_SUBMISSION_PB_REVISION_MODAL_FAILURE";

export const GET_SUBMISSION_TASKS_DATA = "GET_SUBMISSION_TASKS_DATA";
export const GET_SUBMISSION_TASKS_DATA_FAILURE = "GET_SUBMISSION_TASKS_DATA_FAILURE";

export const CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES = "CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES";
export const CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES_FAILURE =
  "CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES_FAILURE";

export const UPDATE_SUBMISSION_PB_WIC_DETAIL = "UPDATE_SUBMISSION_PB_WIC_DETAIL";
export const UPDATE_SUBMISSION_PB_WIC_DETAIL_FAILURE = "UPDATE_SUBMISSION_PB_WIC_DETAIL_FAILURE";

export const EXPAND_SUBMISSION_PB_WIC_DETAIL = "EXPAND_SUBMISSION_PB_WIC_DETAIL";
export const EXPAND_SUBMISSION_PB_WIC_DETAIL_FAILURE = "EXPAND_SUBMISSION_PB_WIC_DETAIL_FAILURE";

export const EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL = "EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL";
export const EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL_FAILURE =
  "EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL_FAILURE";

//lab types for pb revision
export const TOGGLE_SUBMISSION_LAB_REPORT_MODAL = "TOGGLE_SUBMISSION_LAB_REPORT_MODAL";
export const TOGGLE_SUBMISSION_LAB_REPORT_MODAL_FAILURE =
  "TOGGLE_SUBMISSION_LAB_REPORT_MODAL_FAILURE";

export const GET_SUBMISSION_CURRENT_TASK_DETAIL = "GET_SUBMISSION_CURRENT_TASK_DETAIL";
export const GET_SUBMISSION_CURRENT_TASK_DETAIL_FAILURE =
  "GET_SUBMISSION_CURRENT_TASK_DETAIL_FAILURE";

export const UPDATE_SUBMISSION_CURRENT_TASK_REPORT = "UPDATE_SUBMISSION_CURRENT_TASK_REPORT";
export const UPDATE_SUBMISSION_CURRENT_TASK_REPORT_FAILURE =
  "UPDATE_SUBMISSION_CURRENT_TASK_REPORT_FAILURE";

export const GET_SUBMISSION_TASK_STATUS = "GET_SUBMISSION_TASK_STATUS";
export const GET_SUBMISSION_TASK_STATUS_FAILURE = "GET_SUBMISSION_TASK_STATUS_FAILURE";

export const NAVIGATE_SUBMISSION_INVOICE_TAB = "NAVIGATE_SUBMISSION_INVOICE_TAB";
export const NAVIGATE_SUBMISSION_INVOICE_TAB_FAILURE = "NAVIGATE_SUBMISSION_INVOICE_TAB_FAILURE";
