import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Button from "components/input/Button";
import Checkbox from "components/input/Checkbox";
import Modal from "components/modal/Modal";
import Table from "components/table/Table";

import data from "./data";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles((theme) => ({
  checkboxColumn: {
    textAlign: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  blueText: {
    color: "#003DA5",
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
  },
  content: {
    paddingTop: 0,
  },
}));

const UpdateStatus = ({ history }) => {
  const classes = useStyles();

  const taskListTableColumn = [
    {
      columnId: "selected",
      classes: { cell: classes.checkboxColumn },
      label: (
        <Checkbox
          noLabel
          list={[
            {
              value: false,
            },
          ]}
        />
      ),
      render: (data) => {
        return (
          <Checkbox
            noLabel
            list={[
              {
                value: false,
              },
            ]}
          />
        );
      },
    },
    {
      columnId: "productName",
      label: "Product Name",
      render: (data) => {
        return (
          <Box className={classes.item}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              {data.productCode}
            </Typography>
            <Typography className={classnames(classes.text, classes.grayText)}>
              {data.productName}
            </Typography>
          </Box>
        );
      },
    },
    {
      columnId: "program",
      label: "Program",
      render: (data) => {
        return (
          <Box className={classes.item}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              {data.programName}
            </Typography>
            <Typography className={classnames(classes.text, classes.blueText)}>
              {data.programCategory}
            </Typography>
          </Box>
        );
      },
    },
    {
      columnId: "taskType",
      label: "Task Type",
      render: (data) => {
        return (
          <Box className={classes.item}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              {data.taskTypeName}
            </Typography>
            <Typography className={classnames(classes.text, classes.grayText)}>
              {data.taskTypeCode}
            </Typography>
          </Box>
        );
      },
    },
    {
      columnId: "factory",
      label: "Factory",
      render: (data) => {
        return (
          <Typography className={classnames(classes.text, classes.blackText)}>
            {data.factory}
          </Typography>
        );
      },
    },
    {
      columnId: "wbaDeadline",
      label: `${SIMPLE_BUYER_NAME} Deadline`,
      render: (data) => {
        return (
          <Typography className={classnames(classes.text, classes.blackText)}>
            {data.wbaDeadline}
          </Typography>
        );
      },
    },
  ];

  const taskListTableData = data.taskList;

  const handleClose = () => {
    history.push("/lab/submissions/1");
  };

  const ActionButtons = () => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button variant="text" onClick={() => history.push("/lab/submissions/1")}>
          Back
        </Button>
        <Button onClick={() => history.push("/lab/submissions/1/update/status/steps?type=1")}>
          Continue
        </Button>
      </Box>
    );
  };

  return (
    <div>
      <Modal
        className={{ classContent: classes.content }}
        title="Update Status"
        open
        handleClose={handleClose}
        footer={<ActionButtons />}
        hideCloseIcon
      >
        <Box>
          <Typography className={classnames(classes.text, classes.blackText)}>
            Please select task(s) for Status Update.
          </Typography>
        </Box>
        <Box pt={1} pb={2}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            Select Task
          </Typography>
        </Box>
        <Table data={taskListTableData} columnData={taskListTableColumn} noPagination />
      </Modal>
    </div>
  );
};

export default withRouter(UpdateStatus);
