import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";
import Textarea from "components/input/Textarea";

import Button from "components/input/Button";
import Divider from "components/display/Divider";
import FileUpload from "components/input/FileUpload";
import Modal from "components/modal/Modal";
import Checkbox from "components/input/Checkbox";
import { TEST_RESULTS } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  label: {
    fontSize: 12,
    paddingBottom: theme.spacing(1),
  },
  blackText: {
    color: "#000000",
  },
  lightDarkText: {
    color: "#232B3A",
  },
  remark: {
    resize: "none",
    outline: "none",
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
}));

const UpdateNewReport = ({ open, setOpen }) => {
  const classes = useStyles();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const UploadReportFile = () => {
    return (
      <>
        <Typography className={classnames(classes.title, classes.blackText)}>
          1. Upload Report File
        </Typography>
        <FileUpload
          data={[]}
          config={{ type: "reportFiles", typeId: null, fileType: "report_file" }}
          multiple
          buttonText="Upload Supporting Doc"
        />
      </>
    );
  };

  const IssueDate = () => {
    return (
      <>
        <Typography className={classnames(classes.title, classes.blackText)}>
          2. Issue Date
        </Typography>
        <Box className={classes.item}>
          <Typography className={classnames(classes.label, classes.lightDarkText)}>
            Issue Date
          </Typography>
          <DatePicker
            name="issueDate"
            // value={}
            // handleChange={() => {}}
            // additionalClass={}
          />
        </Box>
      </>
    );
  };

  const TestResults = () => {
    const results = [
      { id: 1, test: "ASTM F963 - H&M", selected: true, result: "pass" },
      { id: 2, test: "ASTM F963 - Flam", selected: false, result: "" },
      { id: 3, test: "ASTM F963 - P&M", selected: false, result: "" },
    ];

    const Item = ({ data: { test, selected = false, result } }) => (
      <Box display="flex" alignItems="center" py={1}>
        <Checkbox list={[{ label: test, value: selected }]} />
        <Select
          name="testResults"
          value={result}
          options={TEST_RESULTS}
          // handleChange={}
          // additionalClass={}
        />
      </Box>
    );
    return (
      <>
        <Typography className={classnames(classes.title, classes.blackText)}>
          3. Test Results
        </Typography>
        <Box className={classes.item}>
          {results.map((item) => (
            <Item key={item.id} data={item} />
          ))}
        </Box>
      </>
    );
  };

  const Remark = () => {
    return (
      <>
        <Typography className={classnames(classes.title, classes.blackText)}>4. Remark</Typography>
        <Box className={classes.item}>
          <Typography className={classnames(classes.label, classes.lightDarkText)}>
            Remark
          </Typography>
          <Textarea
            name="remark"
            rowsMin={5}
            rowsMax={5}
            // placeholder='Remark'
            // value={}
            // onChange={() => {}}
            additionalClass={classes.remark}
          />
        </Box>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button variant="outlined" className={classes.btnLeft} onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Upload</Button>
      </Box>
    );
  };

  return (
    <div>
      <Modal title="Update New Report" open={open} footer={<ActionButtons />} hideCloseIcon>
        <Box pb={2}>
          <UploadReportFile />
        </Box>
        <Divider />
        <Box py={2}>
          <IssueDate />
        </Box>
        <Divider />
        <Box py={2}>
          <TestResults />
        </Box>
        <Divider />
        <Box pt={2}>
          <Remark />
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateNewReport;
