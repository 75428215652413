import React from "react";
import _get from "lodash/get";
import _find from "lodash/find";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Check as SubmitIcon, Close as CancelIcon, Edit as EditIcon } from "@material-ui/icons";
import Select from "components/input/Select";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  rootEditing: {
    flexWrap: "wrap",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: "#777777",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  text: {
    fontSize: 10,
    color: "#777777",
  },
}));

const SelectItem = ({ label, value, name, options, isEditable = false, onSubmit }) => {
  const classes = useStyles();

  const [editing, setEditing] = React.useState(false);
  const [valueState, setValueState] = React.useState(value);

  const handleChange = (e) => {
    const { value: newValue } = e.target;
    setValueState(newValue);
  };

  const handleCancel = () => {
    setValueState(value);
    setEditing(false);
  };

  const handleSubmit = () => {
    setValueState(value);
    if (onSubmit) onSubmit(valueState);
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  return isEditable && value ? (
    <Box
      className={classnames(classes.root, { [classes.rootEditing]: editing })}
      onClick={(e) => e.stopPropagation()}
    >
      {editing ? (
        <>
          {label && <Typography className={classes.text}>{label}</Typography>}
          <Box display="flex" alignItems="flex-start">
            <Select
              label={label}
              name={name}
              value={valueState}
              options={options}
              handleChange={handleChange}
            />
            <Box display="flex" mt={0.5}>
              <SubmitIcon className={classes.iconButton} onClick={handleSubmit} />
              <CancelIcon className={classes.iconButton} onClick={handleCancel} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.text}>
            {`${label ? `${label}: ` : ""}${textOrUnknown(
              _get(_find(options, { value: valueState }), "label"),
            )}`}
          </Typography>
          <EditIcon className={classes.iconButton} onClick={handleEdit} />
        </>
      )}
    </Box>
  ) : (
    <Typography className={classes.text}>
      {label}: {textOrUnknown(_get(_find(options, { value: valueState }), "label"))}
    </Typography>
  );
};

export default SelectItem;
