import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function StepHeader({
  step = 0,
  header = "Status Update",
  title = "",
  handlePrevStep,
  handleClose,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {step > 0 && (
          <IconButton className={classes.backBtn} onClick={() => handlePrevStep()}>
            <ArrowBackIcon className={classes.arrow} />
          </IconButton>
        )}
        <Typography className={classes.title}>{header}</Typography>
        <Typography className={classes.title}>|</Typography>
        <Typography className={classes.stepText}>{title}</Typography>
        <IconButton className={classes.closeBtn} onClick={() => handleClose()}>
          <CloseIcon className={classes.arrow} />
        </IconButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 40,
    left: 0,
    width: "100%",
    height: 76,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  header: {
    position: "relative",
    width: "100%",
    height: 76,
    fontSize: 14,
    paddingTop: 29,
    textAlign: "center",
  },
  title: {
    color: "#000000",
    paddingRight: 10,
  },
  stepText: {
    color: "#777777",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    top: 15,
  },
  backBtn: {
    position: "absolute",
    left: 30,
    top: 15,
  },
}));
