import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import StarIcon from "@material-ui/icons/Star";

import CsvLink from "./CSVLink";
import ReactTable from "components/table/ReactTable";
import classTable from "./style.module.scss";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  item: {
    display: "flex",
    alignItems: "flex-end",

    "& svg": {
      marginLeft: "10px",
    },
  },
}));

const SpendingTable = ({ tableData = [] }) => {
  let arrQTTotal = tableData.map((item, index) => item.qtTotal);
  let arrPstUSD = tableData.map((item, index) => item.pstTotal);
  let arrSsUSD = tableData.map((item, index) => item.ssTotal);
  let arrSpuUSD = tableData.map((item, index) => item.spuTotal);
  let arrStUSD = tableData.map((item, index) => item.stTotal);
  let arrDpiUSD = tableData.map((item, index) => item.dpiTotal);
  let arrFriUSD = tableData.map((item, index) => item.friTotal);
  let arrTotalUSD = tableData.map((item, index) => item.total);

  let maxQtUSD = Math.max(...arrQTTotal) !== Math.min(...arrQTTotal) ? Math.max(...arrQTTotal) : -1;
  let maxPstUSD = Math.max(...arrPstUSD) !== Math.min(...arrPstUSD) ? Math.max(...arrPstUSD) : -1;
  let maxSsUSD = Math.max(...arrSsUSD) !== Math.min(...arrSsUSD) ? Math.max(...arrSsUSD) : -1;
  let maxSpuUSD = Math.max(...arrSpuUSD) !== Math.min(...arrSpuUSD) ? Math.max(...arrSpuUSD) : -1;
  let maxStUSD = Math.max(...arrStUSD) !== Math.min(...arrStUSD) ? Math.max(...arrStUSD) : -1;
  let maxDpiUSD = Math.max(...arrDpiUSD) !== Math.min(...arrDpiUSD) ? Math.max(...arrDpiUSD) : -1;
  let maxFriUSD = Math.max(...arrFriUSD) !== Math.min(...arrFriUSD) ? Math.max(...arrFriUSD) : -1;
  let maxTotalUSD =
    Math.max(...arrTotalUSD) !== Math.min(...arrTotalUSD) ? Math.max(...arrTotalUSD) : -1;

  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const noSortFilter = { canSort: false, canFilter: false };

  const tableColumn = [
    {
      id: "lab_location",
      value: "labName",
      label: "Lab Location",
      ...noSortFilter,
      render: ({ cell: { value: labName } }) => {
        return <Box className={classes.item}>{labName}</Box>;
      },
    },
    {
      id: "qt_usd",
      value: "qtTotal",
      label: "QT(USD)",
      ...sortOnly,
      render: ({ cell: { value: qtTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((qtTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {qtTotal === maxQtUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "pst_usd",
      value: "pstTotal",
      label: "PST(USD)",
      ...sortOnly,
      render: ({ cell: { value: pstTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((pstTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {pstTotal === maxPstUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "ss_usd",
      value: "ssTotal",
      label: "SS(USD)",
      ...sortOnly,
      render: ({ cell: { value: ssTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((ssTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {ssTotal === maxSsUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "spu_usd",
      value: "spuTotal",
      label: "SPU(USD)",
      ...sortOnly,
      render: ({ cell: { value: spuTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((spuTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {spuTotal === maxSpuUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "st_usd",
      value: "stTotal",
      label: "ST(USD)",
      ...sortOnly,
      render: ({ cell: { value: stTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((stTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {stTotal === maxStUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "dpi_usd",
      value: "dpiTotal",
      label: "DPI(USD)",
      ...sortOnly,
      render: ({ cell: { value: dpiTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((dpiTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {dpiTotal === maxDpiUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "fri_usd",
      value: "friTotal",
      label: "FRI(USD)",
      ...sortOnly,
      render: ({ cell: { value: friTotal } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((friTotal + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {friTotal === maxFriUSD && <StarIcon />}
          </Box>
        );
      },
    },
    {
      id: "total_usd",
      value: "total",
      label: "Toal(USD)",
      ...sortOnly,
      render: ({ cell: { value: total } }) => {
        return (
          <Box className={classes.item}>
            <span>{(Math.round((total + Number.EPSILON) * 100) / 100).toLocaleString()}</span>
            {total === maxTotalUSD && <StarIcon />}
          </Box>
        );
      },
    },
  ];

  const headerExport = [
    { label: "Lab Location", key: "labName" },
    { label: "QT(USD)", key: "qtTotalShow" },
    { label: "PST(USD)", key: "pstTotalShow" },
    { label: "SS(USD)", key: "ssTotalShow" },
    { label: "SPU(USD)", key: "spuTotalShow" },
    { label: "ST(USD)", key: "stTotalShow" },
    { label: "DPI(USD)", key: "dpiTotalShow" },
    { label: "FRI(USD)", key: "friTotalShow" },
    { label: "Toal(USD)", key: "totalShow" },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  const dataExport = tableData.map((item, index) => {
    return {
      ...item,
      qtTotalShow: (Math.round((item.qtTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      pstTotalShow: (Math.round((item.pstTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      ssTotalShow: (Math.round((item.ssTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      spuTotalShow: (Math.round((item.spuTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      stTotalShow: (Math.round((item.stTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      dpiTotalShow: (Math.round((item.dpiTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      friTotalShow: (Math.round((item.friTotal + Number.EPSILON) * 100) / 100).toLocaleString(),
      totalShow: (Math.round((item.total + Number.EPSILON) * 100) / 100).toLocaleString(),
    };
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idExport = open ? "simple-popover" : undefined;

  return (
    <Box className={classes.wrapperBasic}>
      <Box display="flex" alignItems="center" padding="18px 0">
        <Box display="block" className={classes.allWicText}>
          Viewing Lab Location
        </Box>
        <Box display="block" className={classes.allShowing}>{`Showing ${newPage * pageSize + 1} - ${
          newPage * pageSize + pageSize
        } of ${tableData.length}`}</Box>
        <Box display="block" className={classes.actionExport}>
          <Button
            aria-describedby={idExport}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Actions <ArrowDropDownIcon />
          </Button>
          <Popover
            id={idExport}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography className={classes.typography}>
              <CsvLink
                header={headerExport}
                data={dataExport}
                fileName="spending_table.csv"
                text="Export to Excel"
              />
            </Typography>
          </Popover>
        </Box>
      </Box>

      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          columns={tableColumn}
          hasPagingTop={true}
          data={tableData}
          rowsPerPageOptions={[10, 20, 50, { label: "All" }]}
          classes={{
            root: classTable.analyticsTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default SpendingTable;
