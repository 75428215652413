import fileAPI from "../api/fileApi";

export function atnDownloadFile(data) {
  return fileAPI.downloadFile(data);
}

export function atnUploadFile(data) {
  return fileAPI.uploadFile(data);
}

export function atnUploadFileWithType(data) {
  return fileAPI.uploadFileWithType(data);
}

export function atnDeleteFile(data) {
  return fileAPI.deleteFileWithType(data);
}

export function atnDownloadTrfFile(data) {
  return fileAPI.downloadTrfFile(data);
}

export function atnDownloadTUVTrfFile(data) {
  return fileAPI.downloadTrfFile(data);
}

export function atnDownloadULTrfFile(data) {
  return fileAPI.downloadTrfFile(data);
}

export function atnDownloadTrfBulk(data) {
  return fileAPI.downloadTrfBulk(data);
}
