import React, { PureComponent } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const styles = () => ({
  input: {
    width: "100%",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    height: 36,
    "& .MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 8px",
    },
  },
});

class TextInput extends PureComponent {
  render() {
    const {
      classes,
      placeholder,
      name,
      value,
      handleChange,
      onKeyUp,
      additionalClass,
      cy,
      ...otherProps
    } = this.props;

    return (
      <InputBase
        className={classnames(classes.input, additionalClass)}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        inputProps={{
          "data-cy": cy,
        }}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(TextInput);
