import React from "react";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  csvLink: {
    color: "inherit",
    textDecoration: "none",
  },
}));

const CsvLink = ({ header, data, text, fileName = "file.csv" }) => {
  const classes = useStyles();

  return (
    <CSVLink headers={header} data={data} filename={fileName} className={classes.csvLink}>
      {text}
    </CSVLink>
  );
};

export default CsvLink;
