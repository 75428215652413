import React, { useCallback } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import OutlinedButton from "components/input/OutlinedButton";
import { atnUploadFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 5,
  },
  fileItemContainer: {
    paddingBottom: 5,
  },
  fileName: {
    paddingTop: 5,
    paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  deleteFileBtn: {
    cursor: "pointer",
    paddingTop: 5,
  },
  deleteFileIcon: {
    color: "#777777",
    width: 14,
    height: 14,
  },
}));

const UploadSingleFile = ({ buttonLabel = "UPLOAD", file, handleAddFile, className }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      atnUploadFile(formData).then(({ data, message }) => {
        if (data) {
          handleAddFile({
            id: data.id,
            name: acceptedFiles[0].name,
          });
        }
      });
    },
    [handleAddFile],
  );

  return (
    <Box className={classes.container}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <OutlinedButton label={buttonLabel} color="primary" className={className} />
            </div>
          </section>
        )}
      </Dropzone>

      {file && (
        <Box display="flex" className={classes.fileItemContainer}>
          <Box className={classes.fileName}>{file.name}</Box>
        </Box>
      )}
    </Box>
  );
};

export default connect(null, { atnUploadFile })(UploadSingleFile);
