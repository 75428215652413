/* eslint-disable no-use-before-define */
import React from "react";
import _find from "lodash/find";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";

import SelectUpdateType from "./SelectUpdateType";
import UpdateInformation from "./UpdateInformation";
import InvoiceUpdate from "./InvoiceUpdate";
import InvoiceUpdateConfirmation from "./InvoiceUpdate/Confirmation";
import InvoiceUpdateInspection from "./InvoiceUpdate/Inspection";
import ReportUpload from "./ReportUpload";
import MenuBar from "./MenuBar";

import { LAB_TYPE, LAB_TYPE_NUMS } from "shared/constants";

const PRE_LABELS = ["Status Update", "Cost Update"];

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 24,
    fontWeight: 500,
    color: "#000000",
    padding: `${theme.spacing(4)}px 0`,
  },
}));

const UpdateSteps = ({ history }) => {
  const classes = useStyles();

  const getStepObject = (type, id) => {
    return _find(stepListStatus[type], { id });
  };

  const stepListStatusActions = {
    [LAB_TYPE.type01]: {
      step01: {
        prev: () => {
          history.push("/lab/submissions/1/update/status");
        },
        next: (updateType) => {
          if (updateType === "inProgress") {
            setStep(getStepObject(LAB_TYPE.type01, 2));
          } else if (updateType === "updateResultReports") {
            setStep(getStepObject(LAB_TYPE.type01, 5));
          } else if (updateType === "completed") {
            setStep(getStepObject(LAB_TYPE.type01, 3));
          }
        },
      },
      step02UpdateInformation: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type01, 1));
        },
        next: () => {
          console.info("Status Changed to [Test In Progress]");
        },
      },
      step02InvoiceUpdate: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type01, 1));
        },
        next: () => {
          setStep(stepListStatus[LAB_TYPE.type01][3]);
        },
      },
      step03InvoiceUpdate: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type01, 3));
        },
        next: () => {
          console.info("Status Changed to [Completed]");
        },
      },
      step02ReportUpload: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type01, 1));
        },
        next: () => {
          setStep("Report Upload confirmed");
        },
      },
    },
    [LAB_TYPE.type02]: {
      step01: {
        prev: () => {
          history.push("/lab/submissions/1/update/status");
        },
        next: (updateType) => {
          if (updateType === "bookingReserved") {
            setStep(getStepObject(LAB_TYPE.type02, 2));
          } else if (updateType === "updateReportResult") {
            setStep(getStepObject(LAB_TYPE.type02, 5));
          } else if (updateType === "completed") {
            setStep(getStepObject(LAB_TYPE.type02, 3));
          }
        },
      },
      step02BookingReserved: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type02, 1));
        },
        next: () => {
          console.info("Status Changed to [Booking Reserved]");
        },
      },
      step02InvoiceUpdate: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type02, 1));
        },
        next: (updateType) => {
          setStep(getStepObject(LAB_TYPE.type02, 4));
        },
      },
      step03InvoiceUpdate: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type02, 3));
        },
        next: () => {
          console.info("Status Changed to [Inspection Completed]");
        },
      },
      step02ReportUpload: {
        prev: () => {
          setStep(getStepObject(LAB_TYPE.type02, 1));
        },
        next: () => {
          setStep("Report Upload 2 confirmed");
        },
      },
    },
  };

  const stepListStatus = {
    [LAB_TYPE.type01]: [
      {
        id: 1,
        preLabel: PRE_LABELS[0],
        label: "Step 1: Select Update Type",
        title: "Please Select Status Update Type",
        content: (
          <SelectUpdateType
            data={{
              labType: LAB_TYPE.type01,
              nextAction: stepListStatusActions[LAB_TYPE.type01].step01.next,
            }}
          />
        ),
        process: 30,
        backAction: stepListStatusActions[LAB_TYPE.type01].step01.prev,
      },
      {
        id: 2,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Update Information",
        title: "Update Expected Completion Date",
        content: (
          <UpdateInformation
            data={{
              labType: LAB_TYPE.type01,
              nextAction: stepListStatusActions[LAB_TYPE.type01].step02UpdateInformation.next,
            }}
          />
        ),
        process: 60,
        backAction: stepListStatusActions[LAB_TYPE.type01].step02UpdateInformation.prev,
      },
      {
        id: 3,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Invoice Update",
        title: "Update and Confirm the Invoice Detail",
        process: 60,
        content: (
          <InvoiceUpdate
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type01].step02InvoiceUpdate.next }}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type01].step02InvoiceUpdate.prev,
      },
      {
        id: 4,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Invoice Update",
        title: "Are You Confirming This Task as Completed?",
        process: 90,
        content: (
          <InvoiceUpdateConfirmation
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type01].step03InvoiceUpdate.next }}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type01].step03InvoiceUpdate.prev,
      },
      {
        id: 5,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Update Report and Result",
        title: "Upload Report",
        process: 60,
        content: (
          <ReportUpload
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type01].step02ReportUpload.next }}
            type={LAB_TYPE.type01}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type01].step02ReportUpload.prev,
      },
    ],
    [LAB_TYPE.type02]: [
      {
        id: 1,
        preLabel: PRE_LABELS[0],
        label: "Step 1: Select Update Type",
        title: "What kind of update are you making?",
        content: (
          <SelectUpdateType
            data={{
              labType: LAB_TYPE.type02,
              nextAction: stepListStatusActions[LAB_TYPE.type02].step01.next,
            }}
          />
        ),
        process: 30,
        backAction: stepListStatusActions[LAB_TYPE.type02].step01.prev,
      },
      {
        id: 2,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Update Information",
        title: "Confirm Inspection Booking",
        content: (
          <UpdateInformation
            data={{
              labType: LAB_TYPE.type02,
              nextAction: stepListStatusActions[LAB_TYPE.type02].step02BookingReserved.next,
            }}
          />
        ),
        process: 60,
        backAction: stepListStatusActions[LAB_TYPE.type02].step02BookingReserved.prev,
      },
      {
        id: 3,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Invoice Update",
        title: "Update and Confirm the Invoice Detail",
        process: 60,
        content: (
          <InvoiceUpdateInspection
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type02].step02InvoiceUpdate.next }}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type02].step02InvoiceUpdate.prev,
      },
      {
        id: 4,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Invoice Update",
        title: "Are You Confirming This Task as Completed?",
        process: 90,
        content: (
          <InvoiceUpdateConfirmation
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type02].step03InvoiceUpdate.next }}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type02].step03InvoiceUpdate.prev,
      },
      {
        id: 5,
        preLabel: PRE_LABELS[0],
        label: "Step 2: Update Report and Result",
        title: "Upload Report 2",
        process: 60,
        content: (
          <ReportUpload
            data={{ nextAction: stepListStatusActions[LAB_TYPE.type02].step02ReportUpload.next }}
            type={LAB_TYPE.type02}
          />
        ),
        backAction: stepListStatusActions[LAB_TYPE.type02].step02ReportUpload.prev,
      },
    ],
  };

  const handleUpdateCost = () => {
    console.info("Cost Changed");
  };

  const stepListCost = [
    {
      id: 1,
      preLabel: PRE_LABELS[1],
      label: "Step 1: Invoice Update",
      title: "Update and Confirm the Invoice Detail",
      process: 60,
      content: <InvoiceUpdate data={{ nextAction: handleUpdateCost, type: "cost" }} />,
      backAction: () => {
        history.push("/lab/submissions/1");
      },
    },
  ];

  const handleExitAction = () => {
    const path = window.location.pathname;

    if (path.includes("status")) {
      history.push("/lab/submissions/1/update/status");
    } else if (path.includes("cost")) {
      history.push("/lab/submissions/1");
    }
  };

  const getStepListByQS = () => {
    const path = window.location.pathname;

    if (path.includes("status")) {
      const qs = queryString.parse(window.location.search);
      if (qs.type === LAB_TYPE_NUMS.type01.toString()) {
        return getStepObject(LAB_TYPE.type01, 1);
      } else if (qs.type === LAB_TYPE_NUMS.type02.toString()) {
        return getStepObject(LAB_TYPE.type02, 1);
      }
    } else if (path.includes("cost")) {
      return stepListCost[0];
    }

    return stepListStatus[LAB_TYPE.type01][0];
  };

  const [step, setStep] = React.useState(getStepListByQS());

  return (
    <>
      <MenuBar data={step} exitAction={handleExitAction} />
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" pb={4}>
          <Typography className={classes.text}>{step.title}</Typography>
          {step.content}
        </Box>
      </Container>
    </>
  );
};

export default withRouter(UpdateSteps);
