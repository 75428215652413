import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { textOrUnknown } from "utils/string";

import Card from "components/display/Card";
import Divider from "components/display/Divider";
import Status from "components/display/Status";
import OtherDetails from "pages/common/OtherDetails";
import TestPlanTable from "pages/common/TestPlanTable";
import { TASK_INSPECTION_TYPE, TASK_TYPE_ENUM } from "shared/constants";
import { getDateFormat } from "utils/date";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    border: "1px solid #777777",
    boxShadow: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    color: "#777777",
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "0 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

const Lab = ({ data, taskType }) => {
  const classes = useStyles();

  const cardLabStyle = { root: classes.cardRoot };

  const HeaderLabSection = () => {
    const {
      lab: label,
      totalPrice: amount,
      expectedCompletionDate,
      currency,
      status,
      isPrepayment,
      inspectionTaskDetail,
    } = data;

    return (
      <Box className={classes.header}>
        <Box className={classnames(classes.left, classes.grayText)}>
          <Box display="flex">
            <Box className={classnames(classes.title, classes.blackText)}>
              {textOrUnknown(label)}
            </Box>
            {isPrepayment && <Box className={classes.prepaymentContainer}>Prepayment</Box>}
          </Box>

          <Status value={status} className={{ text: classes.subTitle }} withText withIcon={false} />
        </Box>
        <Box className={classes.right}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            {textOrUnknown(`${amount} ${currency}`)}
          </Typography>
          <Typography className={classes.text}>
            {[TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID].includes(taskType) &&
              `Expected Inspection Date: ${
                inspectionTaskDetail
                  ? textOrUnknown(getDateFormat(inspectionTaskDetail.inspectionDateFrom))
                  : "N/A"
              }`}

            {[TASK_TYPE_ENUM.SPU, TASK_TYPE_ENUM.SPUD].includes(taskType) &&
              `Expected Sample Pick Up Date: ${
                inspectionTaskDetail
                  ? textOrUnknown(getDateFormat(inspectionTaskDetail.inspectionDateFrom))
                  : "N/A"
              }`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const ContentLabSection = () => {
    const { testPlans, lastUpdated, ...rest } = data;

    return (
      <>
        <Divider />

        <Typography className={classnames(classes.title, classes.blackText)}>
          {TASK_INSPECTION_TYPE.includes(taskType) ? "Inspection Plan" : "Test Plan"}
        </Typography>
        <TestPlanTable data={{ testPlans, ...rest }} taskType={taskType} />

        <Typography className={classnames(classes.title, classes.blackText)}>
          Other Details
        </Typography>
        <OtherDetails data={rest} taskType={taskType} />

        <Divider />

        <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.text}>
            Last Updated: <br />
            {getDateFormat(lastUpdated)}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Card
      classes={cardLabStyle}
      collapseComponent={<HeaderLabSection />}
      expandComponent={<ContentLabSection />}
    />
  );
};

export default Lab;
