import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import loading from "./loading.gif";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  loadingImg: {
    borderRadius: "50%",
  },
}));

export default function GifLoading() {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open>
        <img src={loading} alt="loading..." className={classes.loadingImg} />
      </Backdrop>
    </div>
  );
}
