import React, { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";

import InvoiceItem from "pages/SharedItemDetail/Task/TaskDetail/DetailBox/tabs/InvoiceItem";

import { atnGetLabAdminSubmissionDetailsInvoice } from "actions/submissionActions";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hideButton: {
    display: "none",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#777777",
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "2px 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
  },
  headerAccordion: {
    cursor: "pointer",
  },
  arrowIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
  invoiceName: {
    fontSize: 14,
    fontWeight: 500,
  },
  normalText: {
    fontSize: 10,
  },
  opText: {
    fontSize: 10,
    color: "#777777",
  },
  totalSmall: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const Invoice = ({ data, userType, taskType }) => {
  const classes = useStyles();

  const [invoiceData, setInvoiceData] = useState([]);

  const [prepaymentData, setPrepaymentData] = useState({
    hasPrepayment: false,
    prepaymentPrice: 0,
  });

  useEffect(() => {
    if (!data.id) return;

    getInvoice(data.id);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvoice = (id) => {
    atnGetLabAdminSubmissionDetailsInvoice({
      data: { id },
      type: userType,
    }).then(({ data, message }) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isPrepayment) {
            setPrepaymentData({
              hasPrepayment: true,
              prepaymentPrice: data[i].totalPrice,
            });
            break;
          }
        }

        setInvoiceData(data);
      }
    });
  };

  return invoiceData.length > 0 ? (
    <Box overflow="hidden">
      {invoiceData.map((invoice, index) => (
        <Paper key={invoice.id} className={classes.container}>
          {!_isEmpty(invoice) ? (
            <InvoiceItem
              item={invoice}
              prepaymentData={prepaymentData}
              taskType={taskType}
              index={index}
            />
          ) : (
            "No data"
          )}
        </Paper>
      ))}
    </Box>
  ) : (
    <Box>No data</Box>
  );
};

export default Invoice;
