import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import classnames from "classnames";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    zIndex: 990,
    paddingTop: 13,
    paddingBottom: 10,
    overflow: "hidden",

    "& > div": {
      // float: 'left',
      paddingLeft: 20,
    },
  },
  leftArrowContainer: {
    padding: 0,
    marginRight: 20,
  },
  arrowContainer: {
    padding: 0,
  },
  arrow: {
    opacity: 0.54,
    color: "#000000",
  },
  recordNumber: {
    fontSize: 12,
    opacity: 0.54,
    marginRight: 30,
    paddingTop: 4,
    color: "#000000",
  },
  selectText: {
    fontSize: 12,
    opacity: 0.54,
    marginRight: 15,
    paddingTop: 4,
    color: "#000000",
  },
  select: {
    fontSize: 12,
    opacity: 0.54,
    color: "#000000",
    marginRight: 30,
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
}));

const Pagination = ({
  currentPage = 1,
  rowsPerPage = 20,
  totalPages = 1,
  totalRecords = 1,
  rowsPerPageOption = [20, 50, 75, 100, 150, 200],
  handlePageChange,
  handleRowsPerPageChange,
  className,
}) => {
  const classes = useStyles();

  const [pageOption, setPageOption] = useState([]);

  useEffect(() => {
    let tempPageOption = [];

    for (let i = 1; i <= totalPages; i++) {
      tempPageOption.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }

    setPageOption(tempPageOption);
  }, [totalPages]);

  const renderRowsPerPageOption = [];

  for (let i = 0; i < rowsPerPageOption.length; i++) {
    renderRowsPerPageOption.push(
      <MenuItem key={rowsPerPageOption[i]} value={rowsPerPageOption[i]}>
        {rowsPerPageOption[i]}
      </MenuItem>,
    );
  }

  const onPageSelect = (e) => {
    handlePageChange(e.target.value);
  };

  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      handlePageChange(page);
    }
  };

  return (
    <Box className={classnames(className, classes.container)}>
      <Box display="flex" justifyContent="flex-start">
        <Box display="flex">
          <Typography className={classes.selectText}>Page:</Typography>
        </Box>
        <Box display="flex">
          <Select value={currentPage} onChange={onPageSelect} className={classes.select}>
            {pageOption}
          </Select>
        </Box>
        <Box display="flex">
          <Typography className={classes.selectText}>Rows per page:</Typography>
        </Box>
        <Box display="flex">
          <Select value={rowsPerPage} onChange={handleRowsPerPageChange} className={classes.select}>
            {renderRowsPerPageOption}
          </Select>
        </Box>
        <Box display="flex">
          <Typography className={classes.recordNumber}>
            {totalRecords > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} -{" "}
            {currentPage * rowsPerPage > totalRecords ? totalRecords : currentPage * rowsPerPage} of{" "}
            {totalRecords}
          </Typography>
        </Box>
        <Box display="flex">
          <IconButton
            className={classes.leftArrowContainer}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage <= 1}
          >
            <ChevronLeftIcon className={classes.arrow} />
          </IconButton>
        </Box>
        <Box display="flex">
          <IconButton
            className={classes.arrowContainer}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            <ChevronRightIcon className={classes.arrow} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Pagination);
