import homePageApi from "../api/homePageApi";

export function atnGetBuyerHomeStatistics(data) {
  return homePageApi.getBuyerHomeStatistics(data);
}

export function atnGetBuyerHomeMenu(data) {
  return homePageApi.getBuyerHomeMenu(data);
}

export function atnGetBuyerTaskResult(data) {
  return homePageApi.getBuyerTaskResult(data);
}

export function atnGetBuyerTaskResultHeader(data) {
  return homePageApi.getBuyerTaskResultHeader(data);
}

export const atnExportBuyerHomeTaskListCsv = (reqData = {}) => {
  return homePageApi.exportBuyerHomeTaskListCsv(reqData);
};

export function atnGetSupplierHomeFilterList() {
  return homePageApi.getSupplierHomeFilterList();
}

export function atnGetSupplierProfile() {
  return homePageApi.getSupplierProfile();
}

export function atnUpdateSupplierProfile(data) {
  return homePageApi.atnUpdateSupplierProfile(data);
}

export function atnGetSupplierHomeResultList(reqData) {
  return homePageApi.getSupplierHomeResultList(reqData);
}

export function atnGetLabHomeLabFilter(data) {
  return homePageApi.getLabHomeLabFilter(data);
}

export function atnGetLabHomeStatistics(data) {
  return homePageApi.getLabHomeStatistics(data);
}

export function atnGetLabSubmissionResult(data) {
  return homePageApi.getLabSubmissionResult(data);
}

export function atnGetLabSubmissionResultHeader(data) {
  return homePageApi.getLabSubmissionResultHeader(data);
}

export const atnExportLabHomeTaskListCsv = (reqData = {}) => {
  return homePageApi.exportLabHomeTaskListCsv(reqData);
};

export const atnExportLabAdminHomeTaskListCsv = (reqData = {}) => {
  return homePageApi.exportLabAdminHomeTaskListCsv(reqData);
};

export const atnExportAllLabAdminHomeTaskListCsv = (reqData = {}) => {
  return homePageApi.exportLabAdminHomeAllTaskListCsv(reqData);
};
