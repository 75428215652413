import React from "react";
import _get from "lodash/get";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import { atnGetSubmissionDetailsSupplierFactory } from "actions/submissionActions";
import Divider from "components/display/Divider";

import EditItem from "./EditItem";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
}));

const SupplierFactory = ({ userType, data }) => {
  const classes = useStyles();

  const [supplierFactoryData, setSupplierFactoryData] = React.useState({});
  const [submissionId, setSubmissionId] = React.useState(0);

  React.useEffect(() => {
    if (!data.id) return;

    getSupplierFactory(data.id);
    setSubmissionId(data.id);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupplierFactory = (id) => {
    atnGetSubmissionDetailsSupplierFactory({ data: { id }, type: userType }).then((result) => {
      setSupplierFactoryData(result.data);
    });
  };

  const callGetSupplierFactory = () => {
    getSupplierFactory(submissionId);
  };

  const Item = ({ label, value, hasEdit = false, typeEdit }) => (
    <Box className={classes.item} pb={3}>
      <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>

      <Box display="flex" alignItems="center" marginTop="5px">
        <EditItem
          hasEdit={hasEdit}
          value={value}
          submissionId={submissionId}
          typeEdit={typeEdit}
          userType={userType}
          cbSetData={callGetSupplierFactory}
        />
      </Box>
    </Box>
  );

  const SupplierDetails = ({ data: supplierData }) => {
    const {
      name,
      address,
      supplierCode: supplierId,
      contactPerson: contactName,
      contactNumber,
      contactEmail,
    } = supplierData;

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Supplier Name" value={name} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Supplier Address" value={address} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Supplier ID" value={supplierId} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Name"
            value={contactName}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "supplierCompany", name: "contactPerson" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Number"
            value={contactNumber}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "supplierCompany", name: "contactNumber" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Email"
            value={contactEmail}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "supplierCompany", name: "contactEmail" }}
          />
        </Grid>
      </Grid>
    );
  };

  const FactoryDetails = ({ data: factoryData, masterWicId }) => {
    const {
      name,
      address,
      address2,
      factoryCode: factoryId,
      contactName,
      contactNumber,
      contactEmail,
      // aisFactoryInformation = [],
    } = factoryData;

    // const files = aisFactoryInformation.map((item) => ({
    //   id: item.id,
    //   fileName: item.fileName,
    //   fileUrl: item.location,
    // }));

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory Name" value={name} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory Address" value={address} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory ID" value={factoryId} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Factory Address (in Local Language)"
            value={address2}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "factory", name: "address2" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Name"
            value={contactName}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "factory", name: "contactPerson" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Number"
            value={contactNumber}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "factory", name: "contactNumber" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Email"
            value={contactEmail}
            hasEdit={userType === 2 ? true : false}
            typeEdit={{ type: "factory", name: "contactEmail" }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classnames(classes.item, classes.text)} pb={3}>
            <Typography className={classnames(classes.text, classes.grayText)}>
              AIS/Stibo - Factory Information
            </Typography>
            <Box pt={2}>
              <FileUpload
                data={files}
                config={{
                  type: 'masterWicFiles',
                  typeId: masterWicId,
                  fileType: 'ais_stibo_factory_information',
                }}
                editable={userType !== USER_TYPE.lab}
                multiple
              />
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Paper className={classes.container}>
      <Box py={2}>
        <Typography className={classes.title}>Supplier Details</Typography>
        <Divider />
        <SupplierDetails data={_get(supplierFactoryData, "supplier", {})} />
      </Box>
      <Box py={2}>
        <Typography className={classes.title}>Factory Details</Typography>
        <Divider />
        <FactoryDetails
          data={_get(supplierFactoryData, "factory", {})}
          masterWicId={supplierFactoryData.masterWicId}
        />
      </Box>
    </Paper>
  );
};

export default SupplierFactory;
