import React, { PureComponent } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 40,

      "& .MuiOutlinedInput-input": {
        padding: "12px 14px",
        fontSize: 12,
      },

      "& fieldset": {
        top: 0,
        "& legend": {
          display: "none",
        },
      },
    },
  },
});

class DatePicker extends PureComponent {
  render() {
    const {
      classes,
      label,
      name,
      value,
      defaultValue,
      handleChange,
      additionalClass,
      cy,
      ...otherProps
    } = this.props;

    return (
      <TextField
        variant="outlined"
        type="date"
        defaultValue={defaultValue}
        className={classnames(classes.textField, additionalClass)}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        data-cy={cy}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(DatePicker);
