const buyerName = process.env.REACT_APP_BUYERNAME;

export const STATUS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const STATUS_ALL = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "NA", value: "NA" },
];

export const MODE = [
  { label: "CFS", value: "CFS" },
  { label: "CY", value: "CY" },
];

export const WBA_FILTER = [
  { key: "Peter Ham 1", value: "peter_ham_1" },
  { key: "Peter Ham 2", value: "peter_ham_2" },
  { key: "Peter Ham 3", value: "peter_ham_3" },
  { key: "Peter Ham 4", value: "peter_ham_4" },
  { key: "Peter Ham 5", value: "peter_ham_5" },
  { key: "Peter Ham 6", value: "peter_ham_6" },
  { key: "Peter Ham 7", value: "peter_ham_7" },
];

export const PROGRAM_FILTER = [
  { key: "Toy 1", value: "toy_1" },
  { key: "Toy 2", value: "toy_2" },
  { key: "Toy 3", value: "toy_3" },
  { key: "Toy 4", value: "toy_4" },
  { key: "Toy 5", value: "toy_5" },
  { key: "Toy 6", value: "toy_6" },
  { key: "Toy 7", value: "toy_7" },
];

export const CATEGORY_FILTER = [
  {
    key: buyerName !== "Buyer" ? "Walgreens Seasonal" : "Seasonal",
    value: buyerName !== "Buyer" ? "Walgreens Seasonal" : "Seasonal",
  },
  {
    key: buyerName !== "Everyday" ? "Walgreens Everyday" : "Seasonal",
    value: buyerName !== "Everyday" ? "Walgreens Every Day" : "Every Day",
  },
];

export const SUPPLIER_NAME_FILTER = [
  { key: "Innovative Design Group 1", value: "innovative_design_group_1" },
  { key: "Innovative Design Group 2", value: "innovative_design_group_2" },
  { key: "Innovative Design Group 3", value: "innovative_design_group_3" },
  { key: "Innovative Design Group 4", value: "innovative_design_group_4" },
  { key: "Innovative Design Group 5", value: "innovative_design_group_5" },
  { key: "Innovative Design Group 6", value: "innovative_design_group_6" },
  { key: "Innovative Design Group 7", value: "innovative_design_group_7" },
];

export const FACTORY_NAME_FILTER = [
  { key: "Factory 1", value: "factory_1" },
  { key: "Factory 2", value: "factory_2" },
  { key: "Factory 3", value: "factory_3" },
  { key: "Factory 4", value: "factory_4" },
  { key: "Factory 5", value: "factory_5" },
  { key: "Factory 6", value: "factory_6" },
  { key: "Factory 7", value: "factory_7" },
];
