import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 40,
    left: 0,
    width: "100%",
    height: 76,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  header: {
    position: "relative",
    width: "100%",
    height: 76,
    fontSize: 14,
    paddingTop: 29,
    textAlign: "center",
  },
  title: {
    color: "#000000",
    paddingRight: 10,
  },
  stepText: {
    color: "#777777",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    top: 15,
  },
}));

const Header = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Create New Invoice</Typography>
        <Typography className={classes.title}>|</Typography>
        <Typography className={classes.stepText}>Invoice Information</Typography>
        <IconButton className={classes.closeBtn} onClick={() => handleClose()}>
          <CloseIcon className={classes.arrow} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
