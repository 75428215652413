import find from "lodash/find";
import get from "lodash/get";

export const getFormValueAsObject = (attr, options, values, isArr) => {
  if (isArr) {
    if (values[attr] && values[attr].length > 0) {
      return values[attr].map((v) => {
        if (typeof v !== "object") {
          return find(options, { value: v }) || {};
        }
        return v;
      });
    }
    return [];
  }
  if (typeof values[attr] !== "object") {
    return find(options, { value: values[attr] }) || "";
  }
  return values[attr];
};

export const getOptionsFormatted = (options) => {
  let result = [];

  if (options && options.length) {
    if (options[0].label === undefined || options[0].value === undefined)
      // IMPORTANT: options format should be an array contains
      // if not (array contains only values) => force format to pattern below
      // the object like: { label: ..., value: ... }
      result = options.map((item) => ({ label: item, value: item }));
    else result = options;
  }

  return result;
};

export const getValueFromObject = (obj, key) => {
  const value = get(obj, key);
  if (value) {
    return typeof value === "object" ? value.value : value;
  }

  return null;
};
