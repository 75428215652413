import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import {
  atnGetSubmissionDetailsTaskDetail,
  atnGetSubmissionDetailsTaskList,
} from "actions/submissionActions";

import {
  ratnGetCurrentTaskDetail,
  ratnGetCurrentTaskStatus,
  ratnToggleLabReportModal,
  ratnTogglePbRevisionModal,
} from "state/submissionDetail/action";

import TaskList from "./TaskList";
import TaskDetail from "./TaskDetail";
import TaskAlert from "components/display/TaskAlert";

import PbNotExistedModal from "components/modal/PbNotExistedModal";

import { PB_STATUS, TASK_STATUS, TASK_STATUS_ENUM } from "shared/constants";

import { isShowProgram777 } from "services/pbService";
import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  itemRight: {
    paddingLeft: theme.spacing(4),
  },
  alert: {
    marginBottom: 16,
    alignItems: "center",
  },
}));

const pbTaskAlertMessage = {
  supplier: {
    text: "There are some PB which did not exited anymore. Please change the PB number.",
    cta: "Revise PB Now",
  },
  lab: {
    text: "Supplier updated PB number , please revise the report first and completed the submission",
    cta: "Update Report’s PB",
  },
};

const pbNotExistedModalMessage = {
  supplier: "Please update the PB on this submission.",
  lab: "Please update lab report's PB on this submission.",
};

const Task = ({ hasPayerInfo = false, userType, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { NOT_EXISTED } = PB_STATUS;

  const { isSupplier, isLab } = useUserType(userType);

  const { pb_not_existed, pb_updated } = TASK_STATUS;

  const openLabReportUpdateModal = useSelector(
    (state) => state.submissionDetail.openLabReportUpdateModal,
  );
  const submissionTasks = useSelector((state) => state.submissionDetail.tasks);
  const submissionCurrentTaskDetail = useSelector(
    (state) => state.submissionDetail.currentTaskDetail,
  );
  const currentTaskStatus = useSelector((state) => state.submissionDetail.currentTaskStatus);

  const [taskListData, setTaskListData] = useState([]);
  const [taskDetailData, setTaskDetailData] = useState({});
  const [openPbNotExistedModal, setOpenPbNotExistedModal] = useState(false);
  const [pbNotExistedNumbers, setPbNotExistedNumbers] = useState([]);

  const [isTaskPbNotExisted, setIsTaskPbNotExisted] = useState(false);
  const [isTaskPbUpdated, setIsTaskPbUpdated] = useState(false);

  const [isOpenPbAlert, setIsOpenPbAlert] = useState(false);
  const [pbAlertContent, setPbAlertContent] = useState({
    message: "",
    cta: "",
  });

  const isTaskStatusPbNotExisted = currentTaskStatus === TASK_STATUS_ENUM.PB_NOT_EXISTED;

  const isTaskStatusPbUpdated = currentTaskStatus === TASK_STATUS_ENUM.PB_UPDATED;

  const isSupplierPbRevisionLoading = useMemo(
    () => isSupplier && isTaskStatusPbNotExisted && !submissionTasks?.length > 0,
    [isSupplier, isTaskStatusPbNotExisted, submissionTasks],
  );

  const isLabPbRevisionLoading = useMemo(
    () => isLab && isTaskStatusPbUpdated && !Object.keys(submissionCurrentTaskDetail).length > 0,
    [isLab, isTaskStatusPbUpdated, submissionCurrentTaskDetail],
  );

  const showProgram777 = isShowProgram777(taskDetailData?.triggeredBy);

  const hasReport = taskDetailData?.reports?.length > 0;

  useEffect(() => {
    if (!data.id) return;

    getTaskList(data.id);
  }, []);

  useEffect(() => {
    const isFulfilledToOpen =
      !openLabReportUpdateModal && showProgram777 && pbNotExistedNumbers?.length > 0;

    if (isSupplier) {
      isTaskPbNotExisted && isFulfilledToOpen && onOpenPbNotExistedModal();
    } else if (isLab) {
      isTaskPbUpdated && hasReport && isFulfilledToOpen && onOpenPbNotExistedModal();
    }
  }, [showProgram777, pbNotExistedNumbers, isTaskPbNotExisted, openLabReportUpdateModal]);

  useEffect(() => {
    setIsOpenPbAlert(
      (isSupplier && isTaskPbNotExisted) ||
        (isLab && isTaskPbUpdated && pbNotExistedNumbers?.length > 0),
    );

    const { supplier, lab } = pbTaskAlertMessage;

    const pbAlertMessage =
      isSupplier && isTaskPbNotExisted ? supplier.text : isLab && isTaskPbUpdated ? lab.text : "";

    const pbAlertCta =
      isSupplier && isTaskPbNotExisted ? supplier.cta : isLab && isTaskPbUpdated ? lab.cta : "";

    setPbAlertContent((state) => ({
      ...state,
      message: pbAlertMessage,
      cta: pbAlertCta,
    }));
  }, [isTaskPbNotExisted, isTaskPbUpdated, taskDetailData, pbNotExistedNumbers]);

  const pbDisplayName = (item) => `${item.pbNumber} [${item.pbLineId}]`;

  const getNotExistedPbNumbers = ({ pb }) => {
    const notExistedPbs = [];

    pb &&
      pb.map((item) => {
        if (item.pbStatus === NOT_EXISTED) {
          notExistedPbs.push(pbDisplayName(item));
        }
      });

    return notExistedPbs;
  };

  const getReportNotExistedPbNumbers = ({ reports }) => {
    const notExistedPbs = [];

    if (reports) {
      for (const report of reports) {
        report?.pb &&
          report?.pb.map((item) => {
            if (item.pbStatus === NOT_EXISTED) {
              notExistedPbs.push(pbDisplayName(item));
            }
          });
      }
    }

    return notExistedPbs.filter((item, pos) => notExistedPbs.indexOf(item) === pos);
  };

  const checkTaskStatus = ({ status }, taskStatus) => {
    return status === taskStatus;
  };

  const getTaskList = (id) => {
    atnGetSubmissionDetailsTaskList({ data: { id }, type: userType }).then((result) => {
      setTaskListData(result?.data);

      if (result?.data?.length) {
        const firstItemId = _get(result, "data.0.id");
        getTaskDetail(firstItemId);
      }
    });
  };

  const getTaskDetail = (id) => {
    if (id) {
      atnGetSubmissionDetailsTaskDetail({
        data: { id: data.id, taskId: id },
        type: userType,
      }).then((result) => {
        const detail = result.data;
        setTaskDetailData(detail);

        setIsTaskPbNotExisted(checkTaskStatus(detail, pb_not_existed.value));
        setIsTaskPbUpdated(checkTaskStatus(detail, pb_updated.value));

        setPbNotExistedNumbers(
          isSupplier ? getNotExistedPbNumbers(detail) : getReportNotExistedPbNumbers(detail),
        );

        dispatch(ratnGetCurrentTaskStatus(detail.status));
        dispatch(ratnGetCurrentTaskDetail(detail));
      });
    }
  };

  const handleChangeTask = (id) => {
    getTaskDetail(id);
  };

  const onClickPbAlertSupplier = () => {
    dispatch(ratnTogglePbRevisionModal(true));
  };

  const onClickPbAlertLab = () => {
    dispatch(ratnToggleLabReportModal(true));
  };

  const onOpenPbNotExistedModal = () => {
    setOpenPbNotExistedModal(true);
  };

  const onClosePbNotExistedModal = () => {
    setOpenPbNotExistedModal(false);
  };

  const onClickPbAlert = () => {
    return isSupplier ? onClickPbAlertSupplier() : isLab ? onClickPbAlertLab() : null;
  };

  return (
    <>
      <PbNotExistedModal
        pbNumbers={pbNotExistedNumbers}
        open={openPbNotExistedModal}
        onClose={onClosePbNotExistedModal}
        content={isSupplier ? pbNotExistedModalMessage.supplier : pbNotExistedModalMessage.lab}
      />

      <Grid container justify="space-between" spacing={2} className={classes.container}>
        <Grid item xs={3}>
          <TaskList
            userType={userType}
            data={{ list: taskListData }}
            func={{ onChange: handleChangeTask }}
          />
        </Grid>

        <Grid
          item
          xs={9}
          // className={classes.itemRight}
        >
          <TaskAlert
            className={classes.alert}
            open={isOpenPbAlert}
            message={pbAlertContent.message}
            buttonText={pbAlertContent.cta}
            onClick={onClickPbAlert}
            disabled={isSupplierPbRevisionLoading || isLabPbRevisionLoading}
          />

          <TaskDetail
            hasPayerInfo={hasPayerInfo}
            userType={userType}
            submissionId={data.id}
            data={taskDetailData || {}}
            assignTo={data.assignTo || ""}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Task;
