import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import SearchWithButton from "../input/SearchWithButton";
import SelectAutocomplete from "../input/SelectAutocomplete";
import Select from "../input/Select";

import auth from "auth/authenticator";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 22,
    position: "relative",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    padding: "8px 30px",
  },
  formControl: {
    position: "relative",
  },
  programSelect: {
    height: 36,
    paddingRight: 10,
  },
  yearSelect: {
    height: 36,
    paddingRight: 30,
  },
  actionContainer: {
    position: "absolute",
    right: 30,
  },
  actionButton: {
    borderRadius: 2,
    backgroundColor: "#003DA5",
    height: 36,
    boxShadow: "none",
    padding: "6px 10px 6px 16px",
  },
  actionMenu: {
    zIndex: 999,
  },
  actionMenuItem: {
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
  },
  exportItem: {
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #AAAAAA",
  },
  category: {
    width: 180,
  },
  program: {
    width: 250,
  },
  programYear: {
    width: 130,
  },
}));

const SearchSection = ({
  title,
  categoryList = [],
  programList = [],
  programYearList = [],
  searchSectionData,
  handleSearchDataChange,
  handleSearch,
  hasActionMenu,
  selectedItemNumber,
  actionMenuItems = [],
  handleOpenDialog,
}) => {
  const classes = useStyles();

  const [openActionMenu, setOpenActionMenu] = useState(false);
  const anchorRef = useRef(null);

  const handleActionMenuToggle = () => {
    setOpenActionMenu((prevOpen) => !prevOpen);
  };

  const handleActionMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenActionMenu(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openActionMenu);
  useEffect(() => {
    if (prevOpen.current === true && openActionMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openActionMenu;
  }, [openActionMenu]);

  const handleProgramSelect = (value) => {
    handleSearchDataChange({
      target: {
        name: "programName",
        value: value ? value.value : "",
      },
    });
  };

  const handleActionItemClick = (type) => {
    setOpenActionMenu(false);
    handleOpenDialog(type);
  };

  const handleExportClick = () => {
    setOpenActionMenu(false);
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box display="flex" className={classes.container}>
      {title && (
        <Box display="flex">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}

      {categoryList && categoryList.length > 0 && auth.loginBuyerTeam() === "all" && (
        <Box display="flex" className={classes.programSelect}>
          <Select
            label="Category"
            name="category"
            value={searchSectionData.category}
            options={categoryList}
            handleChange={handleSearchDataChange}
            additionalClass={classes.category}
          />
        </Box>
      )}

      {programList && programList.length > 0 && (
        <Box display="flex" className={classes.programSelect}>
          <SelectAutocomplete
            label="Program"
            name="programName"
            value={{
              label: searchSectionData.programName,
              value: searchSectionData.programName,
            }}
            options={programList}
            style={{ width: 200 }}
            onChange={(e, value) => handleProgramSelect(value)}
          />
          {/* <Select
            label="Program"
            name="programName"
            value={searchSectionData.programName}
            options={programList}
            handleChange={handleSearchDataChange}
            additionalClass={classes.program}
          /> */}
        </Box>
      )}

      {programYearList && programYearList.length > 0 && (
        <Box display="flex" className={classes.yearSelect}>
          {/* <SelectAutocomplete
            label="Year"
            options={yearList}
            style={{ width: 110 }}
            onChange={handleYearChange}
          /> */}
          <Select
            label="Program Year"
            name="programYear"
            value={searchSectionData.programYear}
            options={programYearList}
            handleChange={handleSearchDataChange}
            additionalClass={classes.programYear}
          />
        </Box>
      )}

      <SearchWithButton
        keyword={searchSectionData.keyword}
        handleChange={handleSearchDataChange}
        handleSearch={handleSearch}
        onKeyUp={handleEnterSearch}
        cy="itemlist"
      />

      {hasActionMenu && (
        <Box className={classes.actionContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            ref={anchorRef}
            aria-controls={openActionMenu ? "action-menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleActionMenuToggle}
            data-cy="action-btn"
          >
            ACTION <ArrowDropDownIcon />
          </Button>
          <Popper
            className={classes.actionMenu}
            open={openActionMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleActionMenuClose}>
                    <MenuList
                      autoFocusItem={openActionMenu}
                      id="action-menu-list-grow"
                      data-cy={`action-menu`}
                    >
                      {actionMenuItems.map((item) =>
                        item.name === "export" ? (
                          <MenuItem
                            key={item.name}
                            className={
                              actionMenuItems.length === 1
                                ? classes.actionMenuItem
                                : classes.exportItem
                            }
                            onClick={handleExportClick}
                            disabled={!selectedItemNumber}
                            data-cy={`action-${item.name}`}
                          >
                            {item.label}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            key={item.name}
                            className={classes.actionMenuItem}
                            onClick={() => handleActionItemClick(item.type)}
                            disabled={!selectedItemNumber}
                            data-cy={`action-${item.name}`}
                          >
                            {item.label}
                          </MenuItem>
                        ),
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default SearchSection;
