import React from "react";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@material-ui/core";

import TableCell from "./TableCell";
import ExpandButton from "components/input/ExpandButton";

const useStyles = makeStyles((theme) => ({
  tableAdditionalCell: {
    border: "1px solid #777777",
  },
  tableRowExpanded: {
    backgroundColor: "#FAFAFA",
  },
}));

const TableBody = (props) => {
  const {
    data: { columnData, displayData, emptyRows, tableData },
    func: { handleClick },
  } = props;
  const classes = useStyles();

  const emptyRowStyle = { height: 49 * emptyRows };

  const [additionalRow, setAdditionalRow] = React.useState({});

  return (
    <MuiTableBody>
      {tableData.length > 0 ? (
        <>
          {displayData.length > 0 ? (
            displayData.map((n, index) => (
              <React.Fragment key={n.id || uuidv4()}>
                <MuiTableRow
                  hover={false}
                  onClick={handleClick(n.onClick)}
                  tabIndex={-1}
                  className={classnames({ [classes.tableRowExpanded]: additionalRow[index] })}
                >
                  {columnData.map((colData, colIndex) => {
                    const { columnId, render } = colData;
                    return (
                      <TableCell key={columnId} type="body" data={n} {...colData}>
                        {n.additionalRow && colIndex === columnData.length - 1 ? (
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            {render ? render(n) : n[columnId]}
                            <ExpandButton
                              expanded={additionalRow[index]}
                              size="small"
                              onClick={() =>
                                setAdditionalRow({
                                  ...additionalRow,
                                  [index]: additionalRow[index] ? !additionalRow[index] : true,
                                })
                              }
                            />
                          </Box>
                        ) : (
                          <Box>{render ? render(n) : n[columnId]}</Box>
                        )}
                      </TableCell>
                    );
                  })}
                </MuiTableRow>
                {n.additionalRow && n.additionalRow.content && additionalRow[index] && (
                  <MuiTableRow>
                    <MuiTableCell
                      colSpan={columnData.length}
                      className={classes.tableAdditionalCell}
                    >
                      {n.additionalRow.content}
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <MuiTableRow>
              {[...Array(Math.floor(columnData.length)).keys()].map((c) => (
                <MuiTableCell key={c} />
              ))}
            </MuiTableRow>
          )}
          {tableData.length > 0 && emptyRows > 0 && (
            <MuiTableRow style={emptyRowStyle}>
              <MuiTableCell colSpan={columnData.length} />
            </MuiTableRow>
          )}
        </>
      ) : (
        <MuiTableRow>
          <MuiTableCell colSpan={columnData.length} align="center">
            No Data
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

export default TableBody;
