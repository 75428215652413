import React from "react";
import _get from "lodash/get";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { TASK_STATUS } from "shared/constants";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  text: {},
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginLeft: theme.spacing(0.5),
    backgroundColor: "#CCCCCC",
  },
  grey: {
    backgroundColor: "#CCCCCC",
  },
  greyText: {
    color: "#CCCCCC",
  },
  blue: {
    backgroundColor: "#196EFF",
  },
  blueText: {
    color: "#196EFF",
  },
  yellow: {
    backgroundColor: "#FFD12E",
  },
  yellowText: {
    color: "#FFD12E",
  },
  green: {
    backgroundColor: "#1BB135",
  },
  greenText: {
    color: "#1BB135",
  },
  red: {
    backgroundColor: "#D42600",
  },
  redText: {
    color: "#D42600",
  },
}));

const Status = ({ value, className = {}, withText = false, withIcon = true }) => {
  const classes = useStyles();

  const { text: classText } = className;

  return (
    <>
      {withText && (
        <Typography
          className={classnames(classes.text, classText, {
            [classes[`${_get(TASK_STATUS[value], "color", "grey")}Text`]]: !withIcon,
          })}
        >
          {textOrUnknown(_get(TASK_STATUS[value], "label"))}
        </Typography>
      )}
      {withIcon && (
        <div
          className={classnames(classes.circle, classes[_get(TASK_STATUS[value], "color", "grey")])}
        />
      )}
    </>
  );
};

export default Status;
