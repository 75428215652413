import React from "react";
import {
  TableFooter as MuiTableFooter,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
} from "@material-ui/core";

const TablePagination = (props) => {
  const {
    data: { columnData, page, rowsPerPage, rowsPerPageOptions, tableData },
    func: { onChangePage, onChangeRowsPerPage },
    classes,
  } = props;

  return (
    <MuiTableFooter>
      <MuiTableRow>
        <MuiTablePagination
          classes={classes}
          rowsPerPageOptions={rowsPerPageOptions} // { label: "All", value: -1 }
          colSpan={columnData.length}
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </MuiTableRow>
    </MuiTableFooter>
  );
};

export default TablePagination;
