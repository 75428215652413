import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import SmallTabPanelContent from "./SmallTabPanelContent";
import Statistics from "./Statistics/index";
import Submissions from "./Submissions/index";
import { formatHeaderLab } from "utils/formatSubmissionTableData";
import { downloadFile } from "utils/files";
import auth from "auth/authenticator";

import {
  atnExportAllLabAdminHomeTaskListCsv,
  atnExportLabAdminHomeTaskListCsv,
  atnExportLabHomeTaskListCsv,
  atnGetLabHomeLabFilter,
  atnGetLabHomeStatistics,
  atnGetLabSubmissionResult,
  atnGetLabSubmissionResultHeader,
} from "actions/homePageActions";
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tabSideMenuContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    width: 230,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 999,
  },
  contentContainer: {
    marginLeft: 230,
  },
  taskDataContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#F7F9FF",
  },
  tasksContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#FAFAFA",
  },
  panelWithSearch: {
    position: "absolute",
    top: 70,
    bottom: 0,
    overflowY: "auto",
  },
  panel: {
    position: "absolute",
    top: 40,
    bottom: 0,
    overflowY: "auto",
  },
  panelContainer: {
    minWidth: 230,
    width: 230,
  },
  show: {
    display: "normal",
  },
  hide: {
    display: "none",
  },
  searchField: {
    position: "fixed",
    top: 40,
    width: 230,
    fontSize: 10,
    "& .MuiInputBase-input": {
      height: 20,
      padding: "5px 0 5px 10px",
    },
  },
}));

const LabHome = ({ hasSearchFunction }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [sideMenuSearchKeyword, setSideMenuSearchKeyword] = useState("");
  const [showSubmissionList, setShowSubmissionList] = useState(false);
  const [panelItems, setPanelItems] = useState([]);
  const [exportButtonLoading, setExportButtonLoading] = useState(false);

  const [selectedPanelItem, setSelectedPanelItem] = useState({
    item: "",
  });

  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [tableNumberType, setTableNumberType] = useState({});

  const [selectedFilter, setSelectedFilter] = useState({
    labName: [],
    supplierName: [],
    factoryName: [],
    taskType: [],
    taskStatus: [],
  });

  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });

  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState([]);

  const [headerData, setHeaderData] = useState([]);

  const [submissionList, setSubmissionList] = useState([]);

  const [taskCompletionRateData, setTaskCompletionRateData] = useState([]);
  const [outstandingSubmissionData, setOutstandingSubmissionData] = useState([]);

  const getStatisticsData = (labName) => {
    atnGetLabHomeStatistics({
      type: "lab",
      value: labName || "",
    }).then(({ data, message }) => {
      if (data) {
        setTaskCompletionRateData(data.mainStatistic);

        let outstandingTask = [
          {
            name: "notQuoteSubmission",
            data: [
              {
                name: "notQuoteSubmission",
                label: "Not Quoted Submission",
                hasNumber: true,
                number: data.outstandingTask.notQuoteSubmission || 0,
                searchConditions: {
                  table: "outstandingTasks",
                  dataType: "notQuoteSubmission",
                  taskType: "",
                },
              },
            ],
          },
          {
            name: "inProgressSubmission",
            data: [
              {
                name: "inProgressSubmission",
                label: "In Progress Submission",
                hasNumber: true,
                number: data.outstandingTask.inProgressSubmission || 0,
                searchConditions: {
                  table: "outstandingTasks",
                  dataType: "inProgressSubmission",
                  taskType: "",
                },
              },
            ],
          },
        ];

        setShowSubmissionList(false);
        setOutstandingSubmissionData(outstandingTask);
      }
    });
  };

  const getSubmissionData = (page) => {
    let filters = [];
    let filterTaskStatus = [];

    if (selectedPanelItem.item !== "all") {
      filters.push({
        name: "labName",
        value: [selectedPanelItem.item],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        if (key === "taskStatus") {
          filterTaskStatus = selectedFilter[key];
        } else {
          filters.push({
            name: key,
            value: selectedFilter[key],
          });
        }
      }
    });

    if (tableNumberType.table === "taskCompletionRateTable") {
      if (tableNumberType.dataType === "all") {
      } else if (tableNumberType.dataType === "waiting_for_lab_quote") {
        filterTaskStatus = filterTaskStatus.concat(["waiting_for_lab_quote"]);
      } else if (tableNumberType.dataType === "quotation_not_confirm") {
        filterTaskStatus = filterTaskStatus.concat(["quote_available"]);
      } else if (tableNumberType.dataType === "booking_reserved") {
        filterTaskStatus = filterTaskStatus.concat(["booking_reserved"]);
      } else if (tableNumberType.dataType === "in_progress") {
        filterTaskStatus = filterTaskStatus.concat([
          "quote_confirmed_and_waiting_for_sample",
          "sample_received",
          "in_progress",
          "in_progress_revise_update",
          "pending",
          "retest_in_progress",
          "quote_confirmed",
          "in_progress_result_report_updated",
          "arranging_sample",
          "under_review",
          "booking_reserved",
          "waiting_for_wba_approval",
          "in_progress_upload_pac_cert",
        ]);
      } else if (tableNumberType.dataType === "completed") {
        filterTaskStatus = filterTaskStatus.concat([
          "completed",
          "completed_reject",
          "completed_accept",
          "completed_concession",
          "fail",
        ]);
      }

      if (tableNumberType.taskType === "all") {
      } else {
        filters.push({
          name: "taskType",
          value: [tableNumberType.taskType],
        });
      }
    } else if (tableNumberType.table === "outstandingTasks") {
      filters.push({
        name: tableNumberType.dataType,
        value: [tableNumberType.dataType],
      });
      // if (tableNumberType.dataType === "inProgressSubmission") {

      // } else if (tableNumberType.dataType === "notQuoteSubmission") {

      // }
    }

    if (filterTaskStatus.length > 0) {
      let tempFilterTaskStatus = [...new Set(filterTaskStatus)];

      filters.push({
        name: "taskStatus",
        value: tempFilterTaskStatus,
      });
    }

    let formattedFilters = JSON.parse(JSON.stringify(filters));

    for (let i = 0; i < formattedFilters.length; i++) {
      if (formattedFilters[i].name === "taskType") {
        if (formattedFilters[i].value.indexOf("ST") >= 0) {
          formattedFilters[i].value.push("STD");
        }
        if (formattedFilters[i].value.indexOf("SPU") >= 0) {
          formattedFilters[i].value.push("SPUD");
        }
        if (formattedFilters[i].value.indexOf("FRI") >= 0) {
          formattedFilters[i].value.push("FRID");
        }
      }
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: pageData.rowsPerPage,
      filters: formattedFilters,
      search: searchConditions.search,
    };

    atnGetLabSubmissionResult(conditions).then(({ data, message }) => {
      if (data) {
        atnGetLabSubmissionResultHeader({
          search: searchConditions.search,
          filters: formattedFilters,
        }).then(({ data: headerResult, message }) => {
          if (headerResult) {
            setHeaderData(formatHeaderLab(headerResult, t));
          }
        });

        setSubmissionList(data.list);
        // setProgressNumber(data.progressNumber);
        let totalRecords = data.progressNumber.total;
        let totalPages = Math.ceil(data.progressNumber.total / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });
      }
    });
  };

  useEffect(() => {
    atnGetLabHomeLabFilter({}).then(({ data, message }) => {
      if (data) {
        let tempData = [];

        for (let i = 0; i < data.length; i++) {
          tempData.push({
            itemName: data[i].name,
            title: data[i].name,
            info: "",
            message: "Click here to view",
            number: data[i].totalSubmission || 0,
          });
        }

        setSelectedPanelItem({ item: data[0].name });
        setPanelItems(tempData);
        getStatisticsData(data[0].name);
      }
    });

    atnGetLabSubmissionResultHeader({}).then(({ data, message }) => {
      if (data) {
        setHeaderData(formatHeaderLab(data, t));
      }
    });
  }, []);

  useEffect(() => {
    getStatisticsData(selectedPanelItem.item === "all" ? "" : selectedPanelItem.item);
  }, [selectedPanelItem.item]);

  useEffect(() => {
    getSubmissionData();
  }, [
    pageData.currentPage,
    pageData.rowsPerPage,
    searchConditions.orderBy,
    searchConditions.order,
    selectedFilter.labName.length,
    selectedFilter.supplierName.length,
    selectedFilter.factoryName.length,
    selectedFilter.taskType.length,
    selectedFilter.taskStatus.length,
    tableNumberType.table,
    tableNumberType.dataType,
    tableNumberType.taskType,
  ]);

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));
  };

  const handlePanelItemChange = (item) => {
    setSelectedPanelItem((prevState) => ({
      ...prevState,
      item: item,
    }));
  };

  const handleSideMenuSearchKeywordChange = (event) => {
    const { value } = event.target;

    setSideMenuSearchKeyword(value);
  };

  const handleTableDataClick = (data) => {
    setTableNumberType(data);
    setSearchConditions({
      orderBy: "",
      order: "",
      search: "",
    });
    setSelectedFilter({
      labName: [],
      supplierName: [],
      factoryName: [],
      taskType: [],
      taskStatus: [],
    });
    setShowSubmissionList(true);
  };

  const handleSearchSubmissionKeywordChange = (event) => {
    const { value } = event.target;

    setSearchConditions((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const handleSubmissionSearch = () => {
    getSubmissionData(1);
  };

  const handleExportButtonClick = () => {
    let filters = [];
    let filterTaskStatus = [];

    if (selectedPanelItem.item !== "all") {
      filters.push({
        name: "labName",
        value: [selectedPanelItem.item],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        if (key === "taskStatus") {
          filterTaskStatus = selectedFilter[key];
        } else {
          filters.push({
            name: key,
            value: selectedFilter[key],
          });
        }
      }
    });

    if (tableNumberType.table === "taskCompletionRateTable") {
      if (tableNumberType.dataType === "all") {
      } else if (tableNumberType.dataType === "waiting_for_lab_quote") {
        filterTaskStatus = filterTaskStatus.concat(["waiting_for_lab_quote"]);
      } else if (tableNumberType.dataType === "quotation_not_confirm") {
        filterTaskStatus = filterTaskStatus.concat(["quote_available"]);
      } else if (tableNumberType.dataType === "booking_reserved") {
        filterTaskStatus = filterTaskStatus.concat(["booking_reserved"]);
      } else if (tableNumberType.dataType === "not_started") {
        filterTaskStatus = filterTaskStatus.concat([
          "not_open_for_quote",
          "not_open",
          "waiting_for_lab_quote",
          "quote_available",
        ]);
      } else if (tableNumberType.dataType === "in_progress") {
        filterTaskStatus = filterTaskStatus.concat([
          "quote_confirmed_and_waiting_for_sample",
          "sample_received",
          "in_progress",
          "in_progress_revise_update",
          "pending",
          "retest_in_progress",
          "quote_confirmed",
          "in_progress_result_report_updated",
          "arranging_sample",
          "under_review",
          "booking_reserved",
          "waiting_for_wba_approval",
          "in_progress_upload_pac_cert",
        ]);
      } else if (tableNumberType.dataType === "completed") {
        filterTaskStatus = filterTaskStatus.concat([
          "completed",
          "completed_reject",
          "completed_accept",
          "completed_concession",
          "fail",
        ]);
      }

      if (tableNumberType.taskType === "all") {
      } else {
        filters.push({
          name: "taskType",
          value: [tableNumberType.taskType],
        });
      }
    } else if (tableNumberType.table === "outstandingTasks") {
      filters.push({
        name: tableNumberType.dataType,
        value: [tableNumberType.dataType],
      });
    }

    if (filterTaskStatus.length > 0) {
      let tempFilterTaskStatus = [...new Set(filterTaskStatus)];

      filters.push({
        name: "taskStatus",
        value: tempFilterTaskStatus,
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: 1,
      rowsPerPage: 99999,
      filters: filters,
      search: searchConditions.search,
    };

    if (auth.loginRole() === "lab") {
      atnExportLabHomeTaskListCsv(conditions).then(async (data) => {
        downloadFile(data, "Submission_List.xlsx");
      });
    } else {
      atnExportLabAdminHomeTaskListCsv(conditions).then(async (data) => {
        downloadFile(data, "Submission_List_Admin.xlsx");
      });
    }
  };

  const handleExportAll = async () => {
    await setExportButtonLoading(true);
    await atnExportAllLabAdminHomeTaskListCsv().then(async (data) => {
      downloadFile(data, "Submission_List_Admin.xlsx");
      setExportButtonLoading(false);
    });
    setExportButtonLoading(false);
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].concat(filterItemValue),
      }));
    } else {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
      }));
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleSubmissionSelect = (e, submissionId) => {
    if (e.target.checked) {
      if (selectedSubmission.indexOf(submissionId) === -1) {
        setSelectedSubmission((prevState) => {
          return prevState.concat(submissionId);
        });
      }

      let newProjectIds = [];

      for (let i = 0; i < submissionList.length; i++) {
        if (submissionList[i].id === submissionId) {
          for (let j = 0; j < submissionList[i].itemData.length; j++) {
            if (selectedItem.indexOf(submissionList[i].itemData[j].id) === -1) {
              newProjectIds.push(submissionList[i].itemData[j].id);
            }
          }

          setSelectedItem((prevState) => {
            return prevState.concat(newProjectIds);
          });

          break;
        }
      }
    } else {
      if (selectedSubmission.indexOf(submissionId) >= 0) {
        setSelectedSubmission((prevState) => {
          return prevState.filter((item) => {
            return item !== submissionId;
          });
        });
      }

      let projectIds = selectedItem;

      for (let i = 0; i < submissionList.length; i++) {
        if (submissionList[i].id === submissionId) {
          for (let j = 0; j < submissionList[i].itemData.length; j++) {
            if (selectedItem.indexOf(submissionList[i].itemData[j].id) >= 0) {
              projectIds.splice(selectedItem.indexOf(submissionList[i].itemData[j].id), 1);
            }
          }

          setSelectedItem(projectIds);

          break;
        }
      }
    }
  };

  const handleItemSelect = (e, projectId) => {
    if (e.target.checked) {
      if (selectedItem.indexOf(projectId) === -1) {
        setSelectedItem((prevState) => {
          return prevState.concat(projectId);
        });
      }
    } else {
      if (selectedItem.indexOf(projectId) >= 0) {
        setSelectedItem((prevState) => {
          return prevState.filter((item) => {
            return item !== projectId;
          });
        });
      }
    }
  };

  return (
    <Box>
      <Box className={classes.tabSideMenuContainer}>
        <Box className={hasSearchFunction ? classes.show : classes.hide}>
          <Input
            className={classes.searchField}
            placeholder="Search Lab Location..."
            value={sideMenuSearchKeyword}
            onChange={(e) => handleSideMenuSearchKeywordChange(e)}
          />
        </Box>
        <Box className={classes.panel}>
          <SmallTabPanelContent
            panelItems={panelItems}
            selectedPanelItem={selectedPanelItem}
            handlePanelItemChange={handlePanelItemChange}
          />
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.taskDataContainer}>
          {auth.loginRole() === "lab_admin" && (
            <Button color="primary" onClick={handleExportAll} disabled={exportButtonLoading}>
              {exportButtonLoading ? (
                <>
                  <CircularProgress disableShrink size={20} />
                  <span style={{ marginLeft: "20px" }}>Exporting ...</span>
                </>
              ) : (
                "Export All"
              )}
            </Button>
          )}

          <Statistics
            taskCompletionRateData={taskCompletionRateData}
            outstandingSubmissionData={outstandingSubmissionData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>
        {showSubmissionList && (
          <Box className={classes.tasksContainer}>
            <Submissions
              selectedSubmission={selectedSubmission}
              selectedItem={selectedItem}
              selectedFilter={selectedFilter}
              handleSubmissionSelect={handleSubmissionSelect}
              handleItemSelect={handleItemSelect}
              headerData={headerData}
              rowData={submissionList}
              handleSorting={handleSorting}
              handleFilterListToggle={handleFilterListToggle}
              handleFilterItemSelect={handleFilterItemSelect}
              searchSubmissionKeyword={searchConditions.search}
              handleSearchSubmissionKeywordChange={handleSearchSubmissionKeywordChange}
              handleSubmissionSearch={handleSubmissionSearch}
              handleExportButtonClick={handleExportButtonClick}
              selectedPanelItem={selectedPanelItem}
              tableNumberType={tableNumberType}
              rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
              currentPage={pageData.currentPage}
              rowsPerPage={pageData.rowsPerPage}
              totalPages={pageData.totalPages}
              totalRecords={pageData.totalRecords}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LabHome;
