import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import Divider from "components/display/Divider";
import TaskStatus from "components/task/TaskStatus";
import DateItem from "pages/common/DateItem";
import { TASK_DATE_TYPE, TASK_STATUS_ENUM, USER_TYPE } from "shared/constants";
import TenderingChip from "../../../components/display/TenderingChip";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    // transition: 'all .5s ease-in-out',

    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      // transform: 'scale(1.05)',
    },
  },
  disabledItemWrapper: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    transition: "all .5s ease-in-out",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  itemActivated: {
    borderLeft: "4px solid #196EFF",
  },
  itemTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemStatus: {
    minWidth: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginLeft: theme.spacing(0.5),
    backgroundColor: "#CCCCCC",
  },
  circleCompleted: {
    backgroundColor: "#1BB135",
  },
  circleCancelled: {
    backgroundColor: "#D42600",
  },
  circleInProgress: {
    backgroundColor: "#FFD12E",
  },
  mainText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
  disabledMainText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#AAAAAA",
  },
  subText: {
    fontSize: 10,
    color: "#777777",
  },
  disabledSubText: {
    fontSize: 10,
    color: "#AAAAAA",
  },
  divider: {
    margin: `${theme.spacing(1)}px -${theme.spacing(1)}px`,
  },
}));

const TaskList = ({ userType, data, func }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { list } = data;
  const { onChange } = func;

  const [activeItem, setActiveItem] = React.useState(0);

  const handleChange = (id, index) => {
    onChange(id);
    setActiveItem(index);
  };

  const Item = ({
    id,
    taskRequirementId,
    taskType,
    taskStatus: status,
    wicNo,
    wbaDeadline,
    plannedDate,
    labStatus,
    index,
    assignTo,
    completedStatus,
    tenderingProgram,
  }) => {
    const isRejected = labStatus === TASK_STATUS_ENUM.REJECTED;

    return (
      <Paper
        className={classnames(classes.itemWrapper, {
          [classes.itemActivated]: activeItem === index,
        })}
        onClick={() => handleChange(id, index)}
      >
        <Box className={classes.itemTitle}>
          <Typography className={isRejected ? classes.disabledMainText : classes.mainText}>
            {t(`task.${taskType}`)} {`${isRejected ? "(Closed)" : ""}`}
            {tenderingProgram && tenderingProgram.assignedLab !== "" && (
              <TenderingChip detail={tenderingProgram} />
            )}
          </Typography>

          <Box className={classes.itemStatus}>
            <TaskStatus
              value={completedStatus || status}
              className={{
                root: classes.itemStatus,
                text: isRejected ? classes.disabledSubText : classes.subText,
              }}
              withText
              withIcon
            />
          </Box>
        </Box>

        {!isRejected && (
          <>
            <Divider className={classes.divider} />
            <Box className={classes.itemDescription}>
              <Typography className={classes.subText}>{wicNo}</Typography>
              <DateItem value={wbaDeadline} type={TASK_DATE_TYPE.deadLine} />
              <DateItem
                value={plannedDate}
                isEditable={userType !== USER_TYPE.lab}
                type={TASK_DATE_TYPE.plannedDate}
                taskRequirementId={taskRequirementId}
                userType={userType}
              />
            </Box>
          </>
        )}

        {assignTo && (
          <Typography className={classes.subText}>
            Assign To: {assignTo ? t(`user.${assignTo}`) : "TBC"}
          </Typography>
        )}
      </Paper>
    );
  };

  return (
    <Box>
      {list?.map((item, index) => (
        <Item key={index} {...item} index={index} />
      ))}
    </Box>
  );
};

export default TaskList;
