import React from "react";
import _get from "lodash/get";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { SUPPORT_FILE_TYPES } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    borderRadius: 2,
    fontSize: 14,
  },
  buttonMaxWidth: {
    width: "100%",
  },
  contained: {
    background: "#003DA5",
  },
  outlined: {
    color: "#003DA5",
    borderColor: "#003DA5",
  },
}));

const FileUploadButton = ({
  children,
  id,
  color = "primary",
  variant = "contained",
  multiple = false,
  maxWidth = false,
  supportFileTypes = SUPPORT_FILE_TYPES,
  isIconButton = false,
  onChange,
}) => {
  const classes = useStyles();

  const inputEl = React.useRef(null);

  const handleChange = (e) => {
    const files = _get(e, "target.files") || [];

    if (files.length) {
      const fileList = [];
      for (let file of files) {
        // if (isValidFile(file)) {
        fileList.push(file);
        // }
      }
      if (onChange) onChange(fileList);
      inputEl.current.value = "";
    }
  };

  return (
    <div className={classes.root}>
      <input
        ref={inputEl}
        // accept={supportFileTypes.toString()}
        className={classes.input}
        id={`btn-upload-file_${id}`}
        name="contained-button-file"
        multiple={multiple}
        type="file"
        onChange={handleChange}
      />
      <label htmlFor={`btn-upload-file_${id}`}>
        {isIconButton ? (
          <IconButton color="default" aria-label="input-upload-button" component="span">
            <AttachFileIcon />
          </IconButton>
        ) : (
          <Button
            className={classnames(classes.button, classes[variant], {
              [classes.buttonMaxWidth]: maxWidth,
            })}
            variant={variant}
            color={color}
            component="span"
          >
            {children || "Upload More"}
          </Button>
        )}
      </label>
    </div>
  );
};

export default FileUploadButton;
