import commonApi from '../api/commonApi';
// import {
//   FETCH_BRANDS,
//   FETCH_COUNTRIES,
//   FETCH_STATES,
//   FETCH_CITIES,
// } from './types';

// export const atnGetBrands = reqData => dispatch => {
//   commonApi.getBrands(reqData)
//   .then(data => {
//     if (data && data.status === 200) {
//       dispatch({
//         type: FETCH_BRANDS,
//         payload: data.data
//       });
//     } else {
//       // handle error
//     }
//   })
//   .catch(error => {
//     // throw (error);
//   });
// }

export const atnGetBrands = (reqData = {}) => {
  return commonApi.getBrands(reqData);
}

export const atnGetBuyerCompanyTeamsAndPrograms = (reqData = {}) => {
  return commonApi.getBuyerCompanyTeamsAndPrograms(reqData);
}

export const atnGetCountries = (reqData = {}) => {
  return commonApi.getCountries(reqData);
}

export const atnGetStates = (reqData = {}) => {
  return commonApi.getStates(reqData);
}

export const atnGetCities = (reqData = {}) => {
  return commonApi.getCities(reqData);
}

export const atnGetPrograms = (reqData = {}) => {
  return commonApi.getPrograms(reqData);
}
