import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { ArrowDropDown as DropDownIcon } from "@material-ui/icons";

import Button from "components/input/Button";

import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    fontSize: 12,
    color: "#232B3A",
  },
}));

const ActionButton = ({
  history,
  itemId,
  actions,
  userType,
  taskType,
  isTenderingLabExemption = false,
}) => {
  const classes = useStyles();

  const { isBuyer, isSupplier } = useUserType(userType);

  const addTaskOption = {
    text: "Add Task",
    action: () => {
      actions.setAddTaskModalParams((prev) => ({ ...prev, open: true }));
    },
  };

  const editItemDetailOption = {
    text: "Edit Item Detail",
    action: () => history.push(`/buyer/editItem/${itemId}`),
  };

  const downloadAllReportsOption = {
    text: "Download All Reports",
    action: () => {
      actions.handleBuyerAllReportsDownload();
    },
  };

  const tenderingExemptionOption = {
    text: "Exemption of Tendering Program",
    action: () => {
      actions.toggleExceptionTendering();
    },
  };

  const optionsSupplier = [addTaskOption];

  const optionsBuyer = [editItemDetailOption, addTaskOption, downloadAllReportsOption];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const getOptionsByType = () => {
    if (isSupplier) return optionsSupplier;

    if (isBuyer)
      return isTenderingLabExemption ? [...optionsBuyer, tenderingExemptionOption] : optionsBuyer;

    return [];
  };

  const handleMenuItemClick = (_, action) => {
    if (action) action();

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;

    setOpen(false);
  };

  return (
    <>
      <Button cy="action-btn" myRef={anchorRef} endIcon={<DropDownIcon />} onClick={handleToggle}>
        Actions
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-end" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" data-cy="action-menu">
                  {getOptionsByType().map(({ text, action }, index) => (
                    <MenuItem
                      data-cy={`action-item-${index}`}
                      key={index}
                      className={classes.menuItem}
                      onClick={(event) => handleMenuItemClick(event, action)}
                    >
                      {text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default withRouter(ActionButton);
