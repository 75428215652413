import analyticsApi from '../api/analyticsApi';

export function atnGetFilterList(data) {
  return analyticsApi.getFilterList(data);
}

export function atnGetAnalyticsTotal(data) {
  return analyticsApi.getAnalyticsTotal(data);
}

export function atnGetAnalyticsChart(data) {
  return analyticsApi.getAnalyticsChart(data);
}

export function atnGetAnalyticsBasicTable(data) {
  return analyticsApi.getAnalyticsBasicTable(data);
}

export function atnGetAnalyticsSpendingTable(data) {
  return analyticsApi.getAnalyticsSpendingTable(data);
}

export function atnGetFinancialList(data) {
  return analyticsApi.getFinancialList(data);
}

export function atnUpdateFinancialYear(data) {
  return analyticsApi.updateFinancialYear(data);
}

// ============== 1506 API ==============
export function atnGetAvgUnitValue(data) {
  return analyticsApi.getAvgUnitValue(data);
}
export function atnGetAvgSellThroughPerSupplier(data) {
  return analyticsApi.getAvgSellThroughPerSupplier(data);
}
export function atnGetTotalItemQuanityClaim(data) {
  return analyticsApi.getTotalItemQuanityClaim(data);
}
export function atnGetTopTenAvgPerSupplier(data) {
  return analyticsApi.getTopTenAvgPerSupplier(data);
}
export function atnGetTop10SpendingSupplier(data) {
  return analyticsApi.getTop10SpendingSupplier(data);
}
export function atnGetTable1506WithType(data) {
  return analyticsApi.getTable1506WithType(data);
}
export function atnImport1506ExcelFile(data) {
  return analyticsApi.import1506ExcelFile(data);
}
export function atnGet1506FileUploadHistory(data) {
  return analyticsApi.get1506FileUploadHistory(data);
}
