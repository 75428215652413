import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TaskTable from "./TaskTable";
import SearchWithButton from "components/input/SearchWithButton";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  tableContainer: {
    marginTop: 10,
    width: "fit-content",
    paddingRight: 30,
  },
  topPaginationContainer: {
    position: "absolute",
    top: -5,
    right: 0,
  },
}));

const Tasks = ({
  headerData = [],
  rowData = [],
  // tasksTableData = {},
  searchTaskKeyword = "",
  handleSearchTaskKeywordChange,
  handleTaskSearch,
  handleExportButtonClick,
  handleSorting,
  handleFilterListToggle,
  handleFilterItemSelect,
  selectedFilter,

  selectedPanelItem,
  tableNumberType,

  rowsPerPageOption = [20, 50, 75, 100, 150, 200],
  currentPage,
  rowsPerPage,
  totalPages,
  totalRecords,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpenTask = (id) => {
    window.open(`/buyer/items/${id}`);
  };

  const [tableTitle, setTableTitle] = useState("");

  useEffect(() => {
    let title = `Viewing ${totalRecords || 0} Result(s) from`;

    if (selectedPanelItem.item === "all") {
      if (selectedPanelItem.tab === "supplier") {
        title = title + " All Suppliers";
      } else if (selectedPanelItem.tab === "taskType") {
        title = title + " All Task Types";
      } else if (selectedPanelItem.tab === "program") {
        title = title + " All Programs";
      }
    } else {
      title = title + ` ${selectedPanelItem.item}`;
    }

    if (tableNumberType.taskType && tableNumberType.taskType !== "all") {
      title = title + ` > ${tableNumberType.taskType}`;
    }

    if (tableNumberType.dataType !== "all") {
      title = title + " > " + t(`taskStatus.${tableNumberType.dataType}`);
    }

    setTableTitle(title);
  }, [
    totalRecords,
    selectedPanelItem.tab,
    selectedPanelItem.item,
    tableNumberType.taskType,
    tableNumberType.table,
    tableNumberType.dataType,
  ]);

  return (
    <Box className={classes.container}>
      <SearchWithButton
        keyword={searchTaskKeyword}
        handleChange={handleSearchTaskKeywordChange}
        handleSearch={handleTaskSearch}
      />

      {/* <Box className={classes.topPaginationContainer}>
        <TopPagination
          currentPage={1}
          rowsPerPage={50}
          totalPages={1}
          rowsPerPageOption={[]}
          handlePageChange={() => {}}
          handleRowsPerPageChange={() => {}}
        />
      </Box> */}

      <Box display="flex" className={classes.tableContainer}>
        <TaskTable
          tableTitle={tableTitle}
          headerData={headerData}
          rowData={rowData}
          handleRowClick={handleOpenTask}
          hasTopButton={false}
          topButtonLabel="EXPORT"
          handleTopButtonClick={handleExportButtonClick}
          handleSorting={handleSorting}
          handleFilterListToggle={handleFilterListToggle}
          handleFilterItemSelect={handleFilterItemSelect}
          selectedFilter={selectedFilter}
          rowsPerPageOption={rowsPerPageOption}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default Tasks;
