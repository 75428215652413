import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import classes from "./Formik.module.scss";

const Form = (props) => {
  const { initialValues, validateValues, onSubmit, onReset, render } = props;

  const yupValidate = Yup.object().shape({
    ...validateValues,
  });

  const formEl = useRef(null);

  return (
    <div className={classes.wrapper}>
      <Formik
        // Each form always have initialValues, it will help us define first time value of form
        initialValues={initialValues}
        // connect with yup validation defined
        validationSchema={yupValidate}
        // enableReinitialize will help form re-render if initialValues changed
        enableReinitialize
        // If end user click submit button of the form, handleSubmit will trigger, if the form is valid (it means errors object is null)
        // onSubmit will be triggered, we can get values object on this function and do some actions with it
        onSubmit={(values, actions) => onSubmit(values, actions)}
        onReset={(values, actions) => onReset(values, actions)}
        // set ref for the form, we can use formEl ref to get form information (values, errors, everything related to the form) outside Formik wrapper
        ref={formEl}
      >
        {/* map renderForm function to the form */}
        {(fmProps) => render(fmProps)}
      </Formik>
    </div>
  );
};

Form.defaultProps = {
  initialValues: {},
  validateValues: {},
  onSubmit: () => {},
  onReset: () => {},
  render: () => {},
};

Form.propTypes = {
  initialValues: PropTypes.object,
  validateValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  render: PropTypes.func,
};

export default Form;
