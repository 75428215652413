import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ReactTable from "components/table/ReactTable";
import classTable from "./css/style.module.scss";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "20px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",

    "& .wrapper-tooltip": {
      position: "absolute",
      width: "max-content",
      background: "white",
      padding: "10px 10px 15px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
      zIndex: 99999,
      height: "max-content",
      left: 100,
      bottom: 10,
    },

    "& .tui-chart-tooltip": {
      border: "1px solid #C4C8D0",
      color: "black",
      fontFamily: "Roboto",
    },

    "& .tui-chart-default-tooltip": {
      backgroundColor: "white",
    },

    "& .tui-chart-tooltip-head": {
      display: "none",
    },

    "& .tui-chart-tooltip-body": {},

    "& .tui-chart-legend-rect": {
      borderRadius: "50%",
      width: "15px",
      height: "15px",
      marginTop: "0",
      display: "inline-block",
      marginRight: 15,
    },

    "& .tooltip-title": {
      fontSize: "14px",
      fontWeight: "500",
    },

    "& .tooltip-title-sub": {
      fontSize: "14px",
      fontWeight: "500",
      marginTop: "10px",
      marginBottom: "10px",
      color: "#777777",

      "& .sub-item": {
        display: "inline-block",
        marginLeft: 10,
      },
    },

    "& .tooltip-title-percent": {
      display: "flex",
      alignItems: "center",

      "& .percent": {
        fontSize: 24,
        fontWeight: 500,
        color: "#000000",
      },

      "& .sub-item": {
        display: "inline-block",
        marginLeft: 10,
        fontSize: 14,
        color: "#777777",
      },
    },

    "& .tooltip-item-wrapper": {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
    },

    "& .tui-chart-tooltip-value": {
      fontWeight: "500",
      fontSize: "12px",
      textAlign: "left",
      color: "#777777",
      marginRight: 10,
    },

    "& .tui-chart-name": {
      fontWeight: "500",
      fontSize: "12px",
      marginRight: 10,
    },
  },

  title: {
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: 50,
  },
  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",
    marginTop: 40,
  },

  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },
  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
}));

const TopSpending = ({ handleTabChange, tenSpendingSupplier = [] }) => {
  const classes = useStyles();

  const [listTopObj, setListTopObj] = useState({});

  useEffect(() => {
    if (tenSpendingSupplier && tenSpendingSupplier.length) {
      let obj = {};
      let totalAllSpend = tenSpendingSupplier.reduce(
        (total, item, index) => total + parseFloat(item.spending),
        0,
      );

      for (let i = 0; i < tenSpendingSupplier.length; i++) {
        let itemSpending = tenSpendingSupplier[i];
        let name = itemSpending.supplierName.replaceAll(" ", "");
        obj[name] = itemSpending;
        obj[name]["wicTotal"] = itemSpending.tasks.reduce(
          (total, item, index) => total + item.noOfWic,
          0,
        );
        obj[name]["percentMain"] = (parseFloat(itemSpending.spending) / totalAllSpend) * 100;

        let objTask = {};
        for (let j = 0; j < itemSpending.tasks.length; j++) {
          itemSpending.tasks[j]["spending"] =
            (parseFloat(itemSpending.tasks[j].percent) * parseFloat(itemSpending.spending)) / 100;
          objTask[itemSpending.tasks[j].taskType] = itemSpending.tasks[j];
        }
        obj[name]["objTask"] = objTask;
      }

      setListTopObj(obj);
    }
  }, [tenSpendingSupplier]);

  const mouseOverSupplier = (e) => {
    let supplierName = e.currentTarget.innerText;
    let hasToolTip = e.currentTarget.firstElementChild.classList.contains("hasTooltip");
    if (!hasToolTip) return false;

    let name = supplierName.replaceAll(" ", "");

    let qualityTest = listTopObj[name]["objTask"]["QT"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      preSafeTest = listTopObj[name]["objTask"]["PST"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      samSealing = listTopObj[name]["objTask"]["SS"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      samPickUp = listTopObj[name]["objTask"]["SPU"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      safeTest = listTopObj[name]["objTask"]["ST"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      duProInspect = listTopObj[name]["objTask"]["DPI"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      },
      finalRandInspect = listTopObj[name]["objTask"]["FRI"] || {
        percent: 0,
        spending: 0,
        noOfWic: 0,
        noOfProgram: 0,
      };

    const includingWic = parseFloat(listTopObj[name].noOfWic).toLocaleString();

    e.currentTarget.insertAdjacentHTML(
      "beforeend",
      `<div id="wrapper-tooltip-spending" class="wrapper-tooltip">
          <div class="tui-chart-default-tooltip">
            <div class="tui-chart-tooltip-body">
              <div class="tooltip-title">${supplierName}</div>
              <div class="tooltip-title-sub">
                <span>Total Spend : (USD $${(
                  Math.round((parseFloat(listTopObj[name].spending) + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="sub-item">｜Including Tested WIC items: ${includingWic}</span>
              </div>

              <div class="tooltip-title-percent">
                <span class="percent">${parseFloat(
                  parseFloat(listTopObj[name].percentMain).toFixed(2),
                )}%</span>
                <span class="sub-item">in Total Spending for all Suppliers</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #0097A7"></span>
                <span class="tui-chart-name">Quality Test</span>
                <span class="tui-chart-name">${qualityTest.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((qualityTest.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>

                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((qualityTest.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>

                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((qualityTest.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>
              
              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #196EFF"></span>
                <span class="tui-chart-name">Pre-Safety Test</span>
                <span class="tui-chart-name">${preSafeTest.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((preSafeTest.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((preSafeTest.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((preSafeTest.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #B0D400"></span>
                <span class="tui-chart-name">Sample Sealing</span>
                <span class="tui-chart-name">${samSealing.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((samSealing.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((samSealing.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((samSealing.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #FBC02D"></span>
                <span class="tui-chart-name">Sample Pick Up</span>
                <span class="tui-chart-name">${samPickUp.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((samPickUp.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((samPickUp.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((samPickUp.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #F57C00"></span>
                <span class="tui-chart-name">Safety Test</span>
                <span class="tui-chart-name">${safeTest.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((safeTest.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((safeTest.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((safeTest.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #D32F2F"></span>
                <span class="tui-chart-name">During Production Inspection</span>
                <span class="tui-chart-name">${duProInspect.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((duProInspect.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((duProInspect.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((duProInspect.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>

              <div class="tooltip-item-wrapper">
                <span class="tui-chart-legend-rect pie" style="background: #7B1FA2"></span>
                <span class="tui-chart-name">Final Random Inspection</span>
                <span class="tui-chart-name">${finalRandInspect.percent}%</span>
                <span class="tui-chart-tooltip-value">(USD$${(
                  Math.round((finalRandInspect.spending + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</span>
                <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                  Math.round((finalRandInspect.noOfWic + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
                <span class="tui-chart-tooltip-value">｜Program：${(
                  Math.round((finalRandInspect.noOfProgram + Number.EPSILON) * 100) / 100
                ).toLocaleString()}</span>
              </div>
              
            </div>
          </div>
        </div>`,
    );
  };

  const mouseOutSupplier = (e) => {
    document.getElementById("wrapper-tooltip-spending") &&
      document.getElementById("wrapper-tooltip-spending").remove();
  };

  const noSortFilter = { canSort: false, canFilter: false };

  const tableColumn = [
    {
      id: "ranking",
      value: "ranking",
      label: "Ranking",
      ...noSortFilter,
      render: ({ cell: { value: ranking } }) => {
        return <Box>{ranking}</Box>;
      },
    },
    {
      id: "supplier",
      value: "supplierName",
      label: "Supplier",
      ...noSortFilter,
      render: ({ cell: { value: supplierName } }) => {
        return <Box className={classnames(classes.showDetail, "hasTooltip")}>{supplierName}</Box>;
      },
    },
    {
      id: "noProgram",
      value: "noOfProgram",
      label: "No. of Program",
      ...noSortFilter,
      render: ({ cell: { value: noOfProgram } }) => {
        return (
          <Box>{(Math.round((noOfProgram + Number.EPSILON) * 100) / 100).toLocaleString()}</Box>
        );
      },
    },
    {
      id: "wicItems",
      value: "noOfWic",
      label: "Including Tested WIC items",
      ...noSortFilter,
      render: ({ cell: { value: noOfWic } }) => {
        return <Box>{(Math.round((noOfWic + Number.EPSILON) * 100) / 100).toLocaleString()}</Box>;
      },
    },
    {
      id: "spending",
      value: "spending",
      label: "Spending",
      ...noSortFilter,
      render: ({ cell: { value: spending } }) => {
        return (
          <Box>
            USD $
            {(Math.round((parseFloat(spending) + Number.EPSILON) * 100) / 100).toLocaleString()}
          </Box>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box className={classes.title}>Top 10 Spending Suppliers</Box>

      <Box className={show ? classes.show : classes.hide}>
        <Box className={classes.boxTable} id="box-top-spending">
          <ReactTable
            columns={tableColumn}
            data={tenSpendingSupplier}
            classes={{
              root: classTable.topSpendingTable,
              viewColumnIcon: classTable.viewColumnIcon,
            }}
            indexColHover={1}
            onMouseOver={mouseOverSupplier}
            onMouseOut={mouseOutSupplier}
            noPagingBottom={true}
          />
        </Box>

        <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 2)}>
          VIEW ON LIST
        </Box>
      </Box>
    </Box>
  );
};

export default TopSpending;
