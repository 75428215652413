import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from "./translations/cn.json";
import hk from "./translations/hk.json";
import en from "./translations/en.json";

const resources = {
  "zh-CN": {
    translation: cn,
  },
  "zh-HK": {
    translation: hk,
  },
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", //預設語言
  fallbackLng: "en", //如果當前切換的語言沒有對應的翻譯則使用這個語言，
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
