import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",
  },

  title: {
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: 50,
  },
  title1: {
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "25px",
  },
  numRed: {
    fontSize: "36px",
    marginTop: "10px",
    color: "#D42600",
    fontWeight: "500",
  },
  numBlue: {
    fontSize: "36px",
    marginTop: "10px",
    color: "#003DA5",
    fontWeight: "500",
  },
  subTitle: {
    fontSize: "12px",
    paddingTop: "10px",
    borderTop: "1px solid #DADADA",
  },
  title2: {
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "20px",
  },

  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },

  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
}));

const AverageSell = ({
  handleTabChange,
  data = {
    avgPerSupplier: 0,
    highestAvg: 0,
    highestAvgSupplierName: "COOL",
    lowestAvg: 0,
    lowestAvgSupplierName: "COOL 2",
    totalSupplier: 0,
  },
}) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box className={classes.title}>Average Sell Through% Per supplier</Box>

      {show && (
        <Box>
          <Box className={classes.title1}>The highest average Sell Through% Per supplier</Box>
          <Box className={classes.numBlue}>
            {parseFloat(parseFloat(data.highestAvg).toFixed(3))}%
          </Box>

          <Box className={classes.subTitle}>{data.highestAvgSupplierName}</Box>
          <Box className={classes.title2}>The lowest average Sell Through% Per supplier</Box>
          <Box className={classes.numRed}>{parseFloat(parseFloat(data.lowestAvg).toFixed(3))}%</Box>

          <Box className={classes.subTitle}>{data.lowestAvgSupplierName}</Box>
          <Box className={classes.title2}>The Average Sell Through% Per supplier</Box>
          <Box className={classes.numBlue}>
            {parseFloat(parseFloat(data.avgPerSupplier).toFixed(3))}%
          </Box>

          <Box className={classes.subTitle}>{data.totalSupplier} Suppliers</Box>

          <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 0)}>
            VIEW ON LIST
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AverageSell;
