import api from "./api";
import auth from "auth/authenticator";

class homePageApi {
  static getBuyerHomeStatistics(data) {
    let value = encodeURIComponent(data.value);
    return api.get(
      `/buyer/home/get_home_statistics?type=${data.type}&value=${value}&programYear=${data.programYear}&category=${data.category}`,
      data,
    );
  }

  static getBuyerHomeMenu(data) {
    return api.get(`/buyer/home/${data.type}`, data);
  }

  static getBuyerTaskResult(data) {
    return api.post("/buyer/home/statistics/result", data);
  }

  static getBuyerTaskResultHeader(data) {
    return api.post("/buyer/home/statistics/result/headers", data);
  }

  static exportBuyerHomeTaskListCsv(data) {
    return api.downloadWithData("/buyer/home/tasks/csv", data);
  }

  static getSupplierHomeFilterList() {
    return api.get("/supplier/home/filter_list");
  }

  static getSupplierProfile() {
    return api.get("/supplier/profile");
  }

  static atnUpdateSupplierProfile(data) {
    return api.put("/supplier/profile", data);
  }

  static getSupplierHomeResultList(qsData) {
    return api.get(`/supplier/home/result?${qsData}`);
  }

  static getLabHomeLabFilter(data) {
    return api.get(`/${auth.loginRole()}/home/lab_filter`, data);
  }

  static getLabHomeStatistics(data) {
    let labName = encodeURIComponent(data.value);
    return api.get(
      `/${auth.loginRole()}/home/get_home_statistics?type=${data.type}&value=${labName}`,
      data,
    );
  }

  static getLabSubmissionResult(data) {
    return api.post(`/${auth.loginRole()}/home/statistics/result`, data);
  }

  static getLabSubmissionResultHeader(data) {
    return api.post(`/${auth.loginRole()}/home/statistics/result/headers`, data);
  }

  static exportLabHomeTaskListCsv(data) {
    return api.downloadWithData("/lab/home/excel", data);
  }

  static exportLabAdminHomeTaskListCsv(data) {
    return api.downloadWithData("/lab_admin/home/excel", data);
  }

  static exportLabAdminHomeAllTaskListCsv(data) {
    return api.downloadWithData("/lab_admin/home/excel/all", data);
  }
}

export default homePageApi;
