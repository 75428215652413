import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { ArrowDropDown as DropDownIcon } from "@material-ui/icons";
import classnames from "classnames";
import Button from "components/input/Button";

import { ratnToggleLabReportModal, ratnTogglePbRevisionModal } from "state/submissionDetail/action";

import { TASK_STATUS_ENUM } from "shared/constants";
import { SUBMISSION_ACTION_TYPE } from "shared/constants/submission";
import auth from "auth/authenticator";
import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    fontSize: 12,
    color: "#232B3A",
  },
  disabled: {
    // cursor: "not-allowed",
    pointerEvents: "none",
    // backgroundColor: "rgba(169, 169, 169, 0.1)",
    color: "rgb(169, 169, 169)",
    "& > *": {
      color: "rgb(169, 169, 169)",
    },
  },
}));

const ActionButton = ({
  status,
  taskDetail,
  history,
  isNb = false,
  // userType,
  submissionId,
  showResumeReport = false,
  handleCancelTaskDialogOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isSupplier, isLab } = useUserType(auth.userType());

  const submissionTasks = useSelector((state) => state.submissionDetail.tasks);
  const submissionCurrentTaskDetail = useSelector(
    (state) => state.submissionDetail.currentTaskDetail,
  );
  const currentTaskStatus = useSelector((state) => state.submissionDetail.currentTaskStatus);

  const [open, setOpen] = useState(false);
  const [actionMenuItems, setActionMenuItems] = useState([]);
  const [hasReportInCurrentTask, setHasReportInCurrentTask] = useState(false);

  useEffect(() => {
    setHasReportInCurrentTask(taskDetail.reports.length > 0);
  }, [taskDetail.reports]);

  const anchorRef = useRef(null);

  const isCompletedStatus = status === TASK_STATUS_ENUM.COMPLETED;

  const isPbNotExistedStatus = (val) => val === TASK_STATUS_ENUM.PB_NOT_EXISTED;
  const isPbUpdatedStatus = (val) => val === TASK_STATUS_ENUM.PB_UPDATED;

  const isStatusPbNotExisted = isPbNotExistedStatus(status);
  const isStatusPbUpdated = isPbUpdatedStatus(status);

  const isTaskStatusPbNotExisted = isPbNotExistedStatus(currentTaskStatus);
  const isTaskStatusPbUpdated = isPbUpdatedStatus(currentTaskStatus);

  //pb options
  const optionRevisePb = { value: SUBMISSION_ACTION_TYPE.REVISE_PB, label: "Revise PB" };
  const optionUpdateReportPb = {
    value: SUBMISSION_ACTION_TYPE.UPDATE_REPORT_PB,
    label: "Update Report's PB",
  };

  //resume report option
  const optionResumeReport = {
    value: SUBMISSION_ACTION_TYPE.RESUME_REPORT,
    label: "Resume Report",
  };

  //supplier edit submission
  const optionEditSubmission = {
    value: SUBMISSION_ACTION_TYPE.EDIT_SUBMISSION,
    label: "Edit Submission",
  };

  //supplier initial options
  const initSupplierOptions = [
    optionEditSubmission,
    { value: SUBMISSION_ACTION_TYPE.CANCEL_TASK, label: "Cancel Task" },
  ];

  //supplier options for actions
  const supplierOptions = isStatusPbNotExisted
    ? [...initSupplierOptions, optionRevisePb]
    : showResumeReport
    ? [optionEditSubmission, optionResumeReport]
    : initSupplierOptions;

  //lab initial completed status option
  const optionCreateNewInvoice = {
    value: SUBMISSION_ACTION_TYPE.CREATE_NEW_INVOICE,
    label: "Create New Invoice",
  };

  const initLabCompletedOptions = [
    { value: SUBMISSION_ACTION_TYPE.UPLOAD_REVISE_REPORT, label: "Upload Revised Report" },
    optionCreateNewInvoice,
  ];

  //lab completed status options for actions
  const labCompletedOptions = showResumeReport
    ? [optionCreateNewInvoice, optionResumeReport]
    : initLabCompletedOptions;

  //lab initial options
  const initLabOptions = useMemo(() => {
    const tempLabOptions = [
      { value: SUBMISSION_ACTION_TYPE.UPDATE_STATUS, label: "Update Status" },
    ];

    if (!isNb) {
      tempLabOptions.push({ value: SUBMISSION_ACTION_TYPE.UPDATE_COST, label: "Update Cost" });
    }

    return tempLabOptions;
  }, [isNb]);

  //lab options for actions
  const labOptions =
    isStatusPbUpdated && hasReportInCurrentTask
      ? [...initLabOptions, optionUpdateReportPb]
      : showResumeReport
      ? [...initLabOptions, optionResumeReport]
      : initLabOptions;

  useEffect(() => {
    if (isSupplier) {
      setActionMenuItems(supplierOptions);
    } else if (isLab) {
      setActionMenuItems(isCompletedStatus ? labCompletedOptions : labOptions);
    }
  }, [status, isStatusPbNotExisted, isStatusPbUpdated, hasReportInCurrentTask]);

  const handleMenuItemClick = (event, value) => {
    //lab user
    if (isLab) {
      //completed status
      if (isCompletedStatus) {
        switch (value) {
          case SUBMISSION_ACTION_TYPE.UPLOAD_REVISE_REPORT:
            return history.push(`/lab/submissions/${submissionId}/revisedReport`);
            break;
          case SUBMISSION_ACTION_TYPE.CREATE_NEW_INVOICE:
            return history.push(`/lab/submissions/${submissionId}/newInvoice`);
            break;
          case SUBMISSION_ACTION_TYPE.RESUME_REPORT:
            return (
              showResumeReport && history.push(`/lab/submissions/${submissionId}/resume/report`)
            );
            break;
          default:
            break;
        }
      } else {
        //status except completed
        switch (value) {
          case SUBMISSION_ACTION_TYPE.UPDATE_COST:
            history.push(`/lab/submissions/${submissionId}/update/cost`);
            break;
          case SUBMISSION_ACTION_TYPE.UPDATE_STATUS:
            history.push(`/lab/submissions/${submissionId}/update/status`);
            break;
          case SUBMISSION_ACTION_TYPE.UPDATE_REPORT_PB:
            isStatusPbUpdated && dispatch(ratnToggleLabReportModal(true));
            break;
          case SUBMISSION_ACTION_TYPE.RESUME_REPORT:
            showResumeReport && history.push(`/lab/submissions/${submissionId}/resume/report`);
            break;
          default:
            break;
        }
      }
    } else if (isSupplier) {
      //supplier user
      switch (value) {
        case SUBMISSION_ACTION_TYPE.EDIT_SUBMISSION:
          return history.push(`/supplier/submissions/${submissionId}/edit`);
          break;
        case SUBMISSION_ACTION_TYPE.CANCEL_TASK:
          return handleCancelTaskDialogOpen();
          break;
        case SUBMISSION_ACTION_TYPE.REVISE_PB:
          return isStatusPbNotExisted && dispatch(ratnTogglePbRevisionModal(true));
          break;
        case SUBMISSION_ACTION_TYPE.RESUME_REPORT:
          return (
            showResumeReport && history.push(`/supplier/submissions/${submissionId}/resume/report`)
          );
          break;
        default:
          break;
      }
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const isSupplierPbRevisionLoading = useMemo(
    () => isSupplier && isTaskStatusPbNotExisted && !submissionTasks?.length > 0,
    [isSupplier, isTaskStatusPbNotExisted, submissionTasks],
  );

  const isLabPbRevisionLoading = useMemo(
    () => isLab && isTaskStatusPbUpdated && !Object.keys(submissionCurrentTaskDetail).length > 0,
    [isLab, isTaskStatusPbUpdated, submissionCurrentTaskDetail],
  );

  return (
    <>
      <Button myRef={anchorRef} endIcon={<DropDownIcon />} onClick={handleToggle}>
        {isLab ? "Make an update" : "Actions"}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-end" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actionMenuItems.map((option, index) => {
                    const isPbRevision = option?.value === SUBMISSION_ACTION_TYPE.REVISE_PB;

                    return (
                      <MenuItem
                        key={index}
                        className={classnames(classes.menuItem, {
                          [classes.disabled]:
                            isPbRevision && (isSupplierPbRevisionLoading || isLabPbRevisionLoading),
                        })}
                        onClick={(event) => handleMenuItemClick(event, option?.value)}
                      >
                        {option?.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default withRouter(ActionButton);
