import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import SearchPOButton from "components/input/SearchPOButton";

import { downloadFile } from "utils/files";

import POTable from "./POTable";
import POSeriesTable from "./POSeriesTable";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GifLoading from "components/display/GifLoading";

import { atnGetDailyPOList, atnGetDailyPOSeriesList, atnUploadPOChecker } from "actions/poActions";

import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  title: {
    fontWeight: 500,
    fontSize: "16px",
    marginRight: "30px",
  },

  txtText: {
    display: "block",
    fontSize: "12px",
    color: "#232B3A",
    padding: "10px 17px",
    cursor: "pointer",

    "&:hover": {
      background: "#AAAAAA",
    },
  },

  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  boxViewing: {
    borderTop: "1px solid #AAAAAA",
    borderBottom: "1px solid #AAAAAA",
  },

  txtView: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#000000",
    paddingLeft: "30px",
    marginRight: "30px",
  },

  boxLast: {
    fontSize: "10px",
    color: "#000000",
    marginRight: "10px",
  },

  first: {
    color: "#777777",
  },
  wrapperTab: {
    width: "100%",
    background: "white",
    boxShadow: "inset 0px -1px 0px #aaaaaa",
    borderTop: "1px solid #aaaaaa",
  },
  parentTab: {
    width: "100%",
    boxShadow: "inset 0px -1px 0px #aaaaaa",
    borderRadius: 0,

    "& .MuiTabs-root": {
      minHeight: 40,
    },

    "& button": {
      minHeight: 40,
      fontSize: 10,
    },
  },
  uploadPOBtn: {
    "& button": {
      background: "transparent",
      color: "#232B3A",
      fontWeight: "normal",
      padding: "12px 17px",

      "&:hover": {
        background: "transparent",
      },
    },
  },
}));

const PO = () => {
  const classes = useStyles();

  // ==================== GET LIST PO ====================
  const [listPODaily, setListPODaily] = useState([]);
  const [isGetPODone, setIsGetPODone] = useState(false);

  const [listPODailySeries, setListPODailySeries] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    getListDailyPOSeries({ rowsPerPage: 999999999 });
  }, []);

  const getListDailyPO = (data) => {
    setLoadingTable(true);

    atnGetDailyPOList(data).then(({ data, message }) => {
      setListPODaily(data ? data.list : []);
      setLoadingTable(false);
      setIsGetPODone(true);
    });
  };

  const getListDailyPOSeries = (data) => {
    setLoadingTable(true);

    atnGetDailyPOSeriesList(data).then(({ data, message }) => {
      setListPODailySeries(data ? data.list : []);
      setLoadingTable(false);
    });
  };
  // ==================== END GET LIST PO ====================

  // ==================== HANDLE ACTIONS BUTTON ====================
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const idExportAction = openAction ? "simple-popover" : undefined;

  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
  };
  // ==================== END HANDLE ACTIONS BUTTON ====================

  // ==================== HANDLE SEARCH BUTTON ====================
  const [searchQueryData, setSearchQueryData] = useState("");
  const cbHandleSearch = (data) => {
    setSearchQueryData(data);
  };

  const [searchQuerySeriesData, setSearchQuerySeriesData] = useState("");
  const cbHandleSearchPOSeries = (data) => {
    setSearchQuerySeriesData(data);
  };
  // ==================== END HANDLE SEARCH BUTTON ====================

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    atnUploadPOChecker(formData).then(async (data) => {
      downloadFile(data, "Not_Imported_PO_Tracker.csv");
    });
  }, []);

  // ==================== HANDLE SELECT TAB ====================
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !isGetPODone) {
      getListDailyPO({ rowsPerPage: 999999999 });
    }
    setTab(newValue);
  };
  // ==================== END HANDLE SELECT TAB ====================

  return (
    <Box className={classes.root} width="fit-content">
      {loadingTable && <GifLoading />}

      <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 30px">
        <Box display={tab === 0 ? "flex" : "none"} alignItems="center">
          <Box className={classes.title}>Outstanding PO List</Box>
          <SearchPOButton cbHandleSearch={cbHandleSearchPOSeries} placeHolder="Search PO Series" />
        </Box>

        <Box display={tab === 1 ? "flex" : "none"} alignItems="center">
          <Box className={classes.title}>Outstanding PO List</Box>
          <SearchPOButton
            cbHandleSearch={cbHandleSearch}
            placeHolder="Search PO No., WIC No., WIC Description"
          />
        </Box>

        <Box display="block">
          <Button
            aria-describedby={idExportAction}
            variant="contained"
            color="primary"
            onClick={handleClickAction}
          >
            Actions <ArrowDropDownIcon />
          </Button>
          <Popover
            id={idExportAction}
            open={openAction}
            anchorEl={anchorEl}
            onClose={handleCloseAction}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Box className={classes.uploadPOBtn}>
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className={classes.uploadBtnContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} multiple={false} />
                      <Button variant="contained" color="primary" size="small">
                        UPLOAD PO TRACKER
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Box>
          </Popover>
        </Box>
      </Box>

      <Box display="flex" paddingLeft="30px" className={classes.wrapperTab}>
        <Paper className={classes.parentTab}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabHeader}
          >
            <Tab label="PO Series" className={classes.tab} />
            <Tab label="PO" className={classes.tab} />
          </Tabs>
        </Paper>
      </Box>

      <Box display="flex" className={classes.contentContainer}>
        <Box display={tab === 0 ? "block" : "none"}>
          <POSeriesTable listSearch={searchQuerySeriesData} listPODailySeries={listPODailySeries} />
        </Box>
        <Box display={tab === 1 ? "block" : "none"}>
          <POTable listSearch={searchQueryData} listPODaily={listPODaily} />
        </Box>
      </Box>
    </Box>
  );
};

export default PO;
