import api from './api';

class analyticsApi {
  static getFilterList(data) {
    return api.get('/buyer/analysis/filters', data);
  }

  static getAnalyticsTotal(data) {
    return api.post('/buyer/analysis/total', data);
  }

  static getAnalyticsChart(data) {
    return api.post('/buyer/analysis/chart', data);
  }

  static getAnalyticsBasicTable(data) {
    return api.post('/buyer/analysis/basic_table', data);
  }

  static getAnalyticsSpendingTable(data) {
    return api.post('/buyer/analysis/spend_table', data);
  }

  static getFinancialList(data) {
    return api.get('/buyer/analysis/financial_years', data);
  }

  static updateFinancialYear(data) {
    return api.put('/buyer/analysis/financial_years', data);
  }

  // ============== 1506 API ==============
  static getAvgUnitValue(data) {
    return api.post('/buyer/analysis/average_1506_rate', data);
  }
  static getAvgSellThroughPerSupplier(data) {
    return api.post('/buyer/analysis/average_sell_through_rate_per_supplier', data);
  }
  static getTotalItemQuanityClaim(data) {
    return api.post(`/buyer/analysis/quantity_claim`, data);
  }
  static getTopTenAvgPerSupplier(data) {
    return api.post(`/buyer/analysis/top_10_avg_1506_rate_in_unit_per_supplier`, data);
  }
  static getTop10SpendingSupplier(data) {
    return api.post(`/buyer/analysis/top_10_spending_suppliers`, data);
  }
  static getTable1506WithType(data) {
    return api.post(`/buyer/analysis/average_1506_rate_list_view/${data.type}`, data.object);
  }
  static import1506ExcelFile(data) {
    return api.post(`/buyer/analysis/1506_rate/import`, data);
  }
  static get1506FileUploadHistory(data) {
    return api.post(`/buyer/analysis/1506_rate/file/upload_history`, data);
  }

  // ============== End 1506 API ==============

}

export default analyticsApi;
