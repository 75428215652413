import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Card as MuiCard, CardContent, Collapse } from "@material-ui/core";
import ExpandButton from "components/input/ExpandButton";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",

    "&:last-child": {
      marginBottom: 0,
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Card = ({
  classes: myClasses = {},
  collapseComponent,
  expandComponent,
  expanded: isExpanded = false,
}) => {
  const classes = useStyles();

  const { root: rootStyle = "", content: contentStyle = "" } = myClasses;

  const [expanded, setExpanded] = React.useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiCard className={classnames(classes.root, rootStyle)}>
      <CardContent className={classes.content} onClick={handleExpandClick}>
        {collapseComponent || "Empty data"}
        <ExpandButton expanded={expanded} />
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
        <CardContent className={contentStyle}>{expandComponent || "Empty data"}</CardContent>
      </Collapse>
    </MuiCard>
  );
};

export default Card;
