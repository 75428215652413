import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CsvLink from "./CSVLink";
import ReactTable from "components/table/ReactTable";
import classTable from "./style.module.scss";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  normalCell: {},

  warningCell: {
    color: "#D32F2F",
    fontWeight: "bold",
  },

  showDetail: {
    cursor: "pointer",
    paddingBottom: "10px",

    "&:hover": {
      color: "#287577",
    },
  },
}));

const BasicInformationTable = ({ tableData = [] }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const noSortFilter = { canSort: false, canFilter: false };

  const tableColumn = [
    {
      id: "wic_item",
      // value: 'wicNumber',
      value: ({ wicNumber, masterWicId }) => {
        return { wicNumber, masterWicId };
      },
      label: "WIC Item",
      ...noSortFilter,
      render: ({
        cell: {
          value: { wicNumber, masterWicId },
        },
      }) => {
        // return <div onClick={() => window.open(`/buyer/items/${masterWicId}`)}>{wicNumber}</div>;
        // return <Box onClick={() => window.open(`/buyer/items/${masterWicId}`)} className={classes.showDetail}>{wicNumber}</Box>
        return <Box className={classes.showDetail}>{wicNumber}</Box>;
      },
    },
    {
      id: "supplier",
      value: "supplierName",
      label: "Supplier",
      ...noSortFilter,
      render: ({ cell: { value: supplierName } }) => {
        return <Box className={classes.normalCell}>{supplierName}</Box>;
      },
    },
    {
      id: "factory",
      value: "factoryName",
      label: "Factory",
      ...noSortFilter,
      render: ({ cell: { value: factoryName } }) => {
        return <Box className={classes.normalCell}>{factoryName}</Box>;
      },
    },
    {
      id: "program",
      value: "programName",
      label: "Program",
      ...noSortFilter,
      render: ({ cell: { value: programName } }) => {
        return <Box className={classes.normalCell}>{programName}</Box>;
      },
    },
    {
      id: "programYear",
      value: "programYear",
      label: "Program Year",
      ...noSortFilter,
      render: ({ cell: { value: programYear } }) => {
        return <Box className={classes.normalCell}>{programYear}</Box>;
      },
    },
    {
      id: "brand_type",
      value: "brandName",
      label: "Brand Type",
      ...noSortFilter,
      render: ({ cell: { value: brandName } }) => {
        return <Box className={classes.normalCell}>{brandName}</Box>;
      },
    },
    {
      id: "average_ss_time",
      value: "ssTimesShow",
      label: "Average SS time",
      ...sortOnly,
      render: ({ cell: { value: ssTimesShow } }) => {
        return <Box className={classes.normalCell}>{ssTimesShow}</Box>;
      },
    },
    {
      id: "st_time_pass",
      value: "noOfSTShow",
      label: "ST - 1st time pass",
      ...sortOnly,
      render: ({ cell: { value: noOfSTShow } }) => {
        return (
          <Box className={noOfSTShow === "No" ? classes.warningCell : classes.normalCell}>
            {noOfSTShow}
          </Box>
        );
      },
    },
    {
      id: "fri_time_pass",
      value: "noOfFRIShow",
      label: "FRI - 1st time pass",
      ...sortOnly,
      render: ({ cell: { value: noOfFRIShow } }) => {
        return (
          <Box className={noOfFRIShow === "No" ? classes.warningCell : classes.normalCell}>
            {noOfFRIShow}
          </Box>
        );
      },
    },
  ];

  const headerExport = [
    { label: "WIC Item", key: "wicNumber" },
    { label: "Supplier", key: "supplierName" },
    { label: "Factory", key: "factoryName" },
    { label: "Program", key: "programName" },
    { label: "Program Year", key: "programYear" },
    { label: "Brand Type", key: "brandName" },
    { label: "Average SS time", key: "ssTimesShow" },
    { label: "ST - 1st time pass", key: "noOfSTShow" },
    { label: "FRI - 1st time pass", key: "noOfFRIShow" },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  const dataExport = tableData.map((item, index) => {
    return {
      ...item,
      ssTimesShow: item.ssTimes === 0 ? "NA" : item.ssTimes,
      noOfSTShow:
        item.noOfST === 0 ? "NA" : item.noOfSTFail === 0 && item.noOfST > 0 ? "Yes" : "No",
      noOfFRIShow:
        item.noOfFRI === 0 ? "NA" : item.noOfFRIFail === 0 && item.noOfFRI > 0 ? "Yes" : "No",
    };
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idExport = open ? "simple-popover" : undefined;

  return (
    <Box className={classes.wrapperBasic}>
      <Box display="flex" alignItems="center" padding="18px 0">
        <Box display="block" className={classes.allWicText}>
          Viewing All WIC Item
        </Box>
        <Box display="block" className={classes.allShowing}>{`Showing ${newPage * pageSize + 1} - ${
          newPage * pageSize + pageSize
        } of ${tableData.length}`}</Box>
        <Box display="block" className={classes.actionExport}>
          <Button
            aria-describedby={idExport}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Actions <ArrowDropDownIcon />
          </Button>
          <Popover
            id={idExport}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography className={classes.typography}>
              <CsvLink
                header={headerExport}
                data={dataExport}
                fileName="basic_table.csv"
                text="Export to Excel"
              />
            </Typography>
          </Popover>
        </Box>
      </Box>

      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          goToItemDetail={true}
          hasPagingTop={true}
          columns={tableColumn}
          data={dataExport}
          classes={{
            root: classTable.analyticsTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default BasicInformationTable;
