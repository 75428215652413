import React from "react";
import { withRouter } from "react-router-dom";
import _isArray from "lodash/isArray";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Button from "components/input/Button";
import Card from "components/display/Card";
import Link from "components/display/Link";
import Table from "components/table/Table";
import Chip from "components/display/Chip";
import FileUpload from "components/input/FileUpload";

import { LAB, NA, PB_STATUS, TASK_STATUS_ENUM, TASK_TYPE_ENUM } from "shared/constants";

import { textOrUnknown } from "utils/string";

import auth from "auth/authenticator";
import Lab from "../components/Lab";

import { isShowProgram777 } from "services/pbService";
import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(3)}px`,
  },
  labSection: {
    paddingBottom: theme.spacing(4),
  },
  quoteRequestSection: {
    marginTop: "16px",
  },
  cardRoot: {
    border: "1px solid #777777",
    boxShadow: "none",
  },
  cardQuoteRequestContent: {
    padding: 0,
    paddingBottom: "0 !important",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  extraTitle: {
    fontSize: 24,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    color: "#777777",
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellFirst: {
    borderLeft: "none",
  },
  tableCellLast: {
    borderRight: "none",
  },
  tableCellNoBorderBottom: {
    borderBottom: "none",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  tableCellNoPaddingRight: {
    paddingRight: 0,
  },
  tableTitle: {
    color: "#000000",
    fontSize: 10,
  },
  notOpenQuoteBox: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginTop: 40,
    border: "1px solid #777777",
  },
  quotationContainer: {
    paddingTop: 20,
  },
  error: {
    color: "#D42600",
  },
}));

const Quote = ({
  history,
  data,
  category,
  taskId,
  taskStatus,
  taskType,
  inspectionTaskDetails,
  firstShipmentTotalQuantity,
  fullPoTotalQuantity,
  po = [],
  pb = [],
  e2open = [],
  selectedLabs = [],
  assignTo,
  triggeredBy = "",
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { isSupplier } = useUserType(auth.userType());

  const showProgram777 = isShowProgram777(triggeredBy);

  const notOpenForQuote = taskStatus === TASK_STATUS_ENUM.NOT_OPEN_FOR_QUOTE;

  const isAssignToLab = assignTo === LAB;

  const isEverydaySPUAndFRI = [TASK_TYPE_ENUM.SPUD, TASK_TYPE_ENUM.FRID].includes(taskType);

  const cardQuoteRequestStyle = {
    root: classnames(classes.cardRoot),
    content: classes.cardQuoteRequestContent,
  };

  const HeaderQuoteRequestSection = () => {
    return (
      <Box className={classes.header}>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Quote Request
        </Typography>
      </Box>
    );
  };

  const ContentQuoteRequestSection = () => {
    const tableColumn = [
      {
        columnId: "title",
        width: "30%",
        classes: {
          cell: classnames(classes.tableCellFirst, classes.tableCellNoBorderBottom),
        },
        render: (data) => {
          return data.title;
        },
      },
      {
        columnId: "value",
        classes: {
          cell: classnames(classes.tableCellLast, classes.tableCellNoBorderBottom),
        },
        render: (data) => {
          if (data.title === "Test Plan")
            return data.value.url ? (
              <Link path={data.value.url} text={data.value.text} />
            ) : (
              textOrUnknown(data.value.text)
            );
          else return data.value;
        },
      },
    ];

    const tableData = [];

    const getTableDataId = () => tableData.length + 1;

    if (selectedLabs && selectedLabs.length > 0) {
      tableData.push({
        id: getTableDataId(),
        title: "Designated Labs",
        value: selectedLabs.map((item) => <Chip key={item.id} label={item.labName} />),
      });
    }

    if (data.expectedCompletionDate) {
      tableData.push({
        id: getTableDataId(),
        title: "Expected Completion Date",
        value: data.expectedCompletionDate,
      });
    }

    if (inspectionTaskDetails && inspectionTaskDetails.expectedInspectionDate) {
      tableData.push({
        id: getTableDataId(),
        title: "Expected Inspection Date",
        value: inspectionTaskDetails.expectedInspectionDate,
      });
    }

    if (inspectionTaskDetails && inspectionTaskDetails.expectedSamplePickUpDate) {
      tableData.push({
        id: getTableDataId(),
        title: "Expected Sample Pick Up Date",
        value: inspectionTaskDetails.expectedSamplePickUpDate,
      });
    }

    if (
      (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") &&
      inspectionTaskDetails &&
      inspectionTaskDetails.inspectionLevel
    ) {
      tableData.push({
        id: getTableDataId(),
        title: "Inspection Level",
        value: t(`inspectionLevel.${inspectionTaskDetails.inspectionLevel}`),
      });

      tableData.push({
        id: getTableDataId(),
        title: "AQL Level",
        value: (
          <>
            <div>AQL Level(Critical) - {inspectionTaskDetails.aqlCritical}</div>
            <div>AQL Level(Major) - {inspectionTaskDetails.aqlMajor}</div>
            <div>AQL Level(Minor) - {inspectionTaskDetails.aqlMinor}</div>
          </>
        ),
      });
    }

    if (
      (po.length > 0 || pb.length > 0) &&
      (taskType === "DPI" ||
        taskType === "FRI" ||
        taskType === "FRID" ||
        taskType === "SPU" ||
        taskType === "SPUD")
    ) {
      let purchaseOrder = [];
      let totalQuantityAvailableForInspection = 0;
      let inspectionPercentage = 0;

      po.map((item) => {
        purchaseOrder.push(item);
        totalQuantityAvailableForInspection += parseInt(item.poQuantity || "0");
      });

      if (totalQuantityAvailableForInspection && fullPoTotalQuantity) {
        inspectionPercentage = (
          (totalQuantityAvailableForInspection / fullPoTotalQuantity) *
          100
        ).toFixed(2);
      }

      if (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") {
        tableData.push({
          id: getTableDataId(),
          title: "1st Shipment Total PO Quantity",
          value: firstShipmentTotalQuantity,
        });

        tableData.push({
          id: getTableDataId(),
          title: "Total P.O. Quantity",
          value: fullPoTotalQuantity,
        });
      }

      po?.length > 0 &&
        tableData.push({
          id: getTableDataId(),
          title: "Purchase Order",
          value: (
            <>
              <Grid container>
                <Grid item xs={3}>
                  PO Number
                </Grid>
                {category === "everyday" ? (
                  <>
                    <Grid item xs={3}>
                      Po Quantity
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={3}>
                      Po Quantity in Units
                    </Grid>
                    <Grid item xs={3}>
                      Actual Quantity in Pieces
                    </Grid>
                  </>
                )}
                <Grid item xs={3}>
                  {taskType === "SPU" || taskType === "SPUD"
                    ? "Qty Ready for Inspection"
                    : "Case Pack"}
                </Grid>
              </Grid>
              {purchaseOrder.map((item) => (
                <Grid key={item.id} container>
                  <Grid item xs={3}>
                    {item.poNumber}
                  </Grid>
                  {category !== "everyday" && (
                    <Grid item xs={3}>
                      {item.poQuantityInUnits}
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    {item.poQuantity}
                  </Grid>
                  <Grid item xs={3}>
                    {taskType === "SPU" || taskType === "SPUD"
                      ? item.quantityReadyForInspection
                      : item.casePack}
                  </Grid>
                </Grid>
              ))}
            </>
          ),
        });

      if (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") {
        tableData.push({
          id: getTableDataId(),
          title: "Total Quantity Available for Inspection",
          value: totalQuantityAvailableForInspection,
        });

        tableData.push({
          id: getTableDataId(),
          title: "Inspection Percentage",
          value: `${inspectionPercentage} %`,
        });
      }
    }

    if (showProgram777 && isEverydaySPUAndFRI) {
      //e2open
      isAssignToLab &&
        e2open?.length > 0 &&
        tableData.push({
          id: getTableDataId(),
          title: "Upload E2open - PB detail",
          value: (
            <div className={classes.gridRoot}>
              {e2open.map(({ id, fileName, location }) => {
                if (!id || !fileName || !location) {
                  return <Box display="flex">{NA}</Box>;
                } else {
                  return (
                    <Box display="flex">
                      <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
                    </Box>
                  );
                }
              })}
            </div>
          ),
        });

      //pb
      isAssignToLab &&
        pb?.length > 0 &&
        tableData.push({
          id: getTableDataId(),
          title: "PB Information",
          value: (
            <div className={classes.gridRoot}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Box className={classes.tableTitle}>PB Number [Production batch line ID]</Box>
                </Grid>

                <Grid item xs>
                  <Box className={classes.tableTitle}>PB Quantity</Box>
                </Grid>

                <Grid item xs>
                  <Box className={classes.tableTitle}>Qty Ready for Inspection</Box>
                </Grid>
              </Grid>

              {pb.map((item) => {
                const isNotExisted = item.pbStatus === PB_STATUS.NOT_EXISTED;

                const pbIdentity = item?.pbNumber
                  ? `${item?.pbNumber} [ ${item?.pbLineId || NA} ]`
                  : NA;

                return (
                  <Grid container spacing={3}>
                    <Grid item xs={5} className={classes.tableCell}>
                      {pbIdentity}{" "}
                      {isNotExisted && <span className={classes.error}>{`(Cancelled)`}</span>}
                    </Grid>

                    <Grid item xs className={classes.tableCell}>
                      {item?.pbQuantity || NA}
                    </Grid>

                    <Grid item xs className={classes.tableCell}>
                      {item?.casePack || NA}
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          ),
        });
    }

    return <Table data={tableData} columnData={tableColumn} noHeader noPagination />;
  };

  const NotOpenForQuoteComponent = () => {
    return (
      isSupplier && (
        <Box className={classes.notOpenQuoteBox}>
          <Typography className={classnames(classes.extraTitle, classes.blackText)}>
            Not Opened for Quotations
          </Typography>
          <Box py={4}>
            <Typography className={classnames(classes.subTitle, classes.blackText)}>
              You currently do not have any quotes from any labs because you have not opened this
              task for quotes yet. Once you open this task for quotes you will be able to review and
              add information so they may provide accurate quotations for this task.
            </Typography>
          </Box>
          <Box maxWidth="50%">
            <Button onClick={() => history.push(`/supplier/openTask/${taskId}`)}>
              START (You Are opening this task for quotes)
            </Button>
          </Box>
        </Box>
      )
    );
  };

  const QuoteComponent = () => {
    return (
      <>
        {_isArray(data) && !!data.length && (
          <Box className={classes.quotationContainer}>
            {data.map((item) => (
              <Box key={item.id} className={classes.labSection}>
                <Lab data={item} taskType={taskType} />
              </Box>
            ))}
          </Box>
        )}

        <Box className={classes.quoteRequestSection}>
          <Card
            classes={cardQuoteRequestStyle}
            collapseComponent={<HeaderQuoteRequestSection />}
            expandComponent={<ContentQuoteRequestSection />}
          />
        </Box>
      </>
    );
  };

  return notOpenForQuote ? <NotOpenForQuoteComponent /> : <QuoteComponent />;
};

export default withRouter(Quote);
