import React from "react";
import _find from "lodash/find";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";

import Button from "components/input/Button";
import Card from "components/display/Card";
import Select from "components/input/Select";

import { LAB_TYPE } from "shared/constants";
import { getLSItem, keysLS, setLSItem } from "utils/localStorage";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  cardRoot: {
    boxShadow: "none",

    "& > div:first-child": {
      borderBottom: "1px solid #777777",
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  cardContent: {
    padding: `${theme.spacing(2)}px 0`,
    paddingBottom: "0 !important",

    "& > div:first-child": {
      width: "100%",
      paddingBottom: theme.spacing(4),
    },
  },
  selectField: {
    width: "100%",
  },
}));

const SelectUpdateType = ({ data }) => {
  const classes = useStyles();

  const cardStyle = { root: classes.cardRoot, content: classes.cardContent };

  const types = {
    [LAB_TYPE.type01]: [
      { id: 1, label: "Sample Received", value: "sampleReceived" },
      { id: 2, label: "Test In Progress", value: "inProgress" },
      { id: 3, label: "Pending", value: "pending" },
      { id: 4, label: "Test In Progress - Update Result & Reports", value: "updateResultReports" },
      { id: 5, label: "Completed", value: "completed" },
    ],
    [LAB_TYPE.type02]: [
      { id: 1, label: "Booking Reserved", value: "bookingReserved" },
      { id: 2, label: "Upload Report & Result", value: "updateReportResult" },
      { id: 3, label: "Completed", value: "completed" },
    ],
  };

  const getLabUpdateTypeFromLS = () => {
    return _find(types[labType], { value: getLSItem(keysLS.labUpdateType) });
  };

  const { labType, nextAction } = data;

  const [type, setType] = React.useState(getLabUpdateTypeFromLS() || types[labType][0]);

  const handleChangeType = (value) => {
    if (value.target) {
      setType(value.target);
    }
  };

  const handleNext = () => {
    if (nextAction) {
      nextAction(type.value);
      setLSItem(keysLS.labUpdateType, type.value);
    }
  };

  const HeaderSection = ({ text }) => {
    return <Typography className={classes.cardTitle}>{text}</Typography>;
  };

  const SelectedTasksContentSection = ({ text }) => {
    return "Content here";
  };

  const UpdateTypeContentSection = ({ text }) => {
    return (
      <Select
        name="updateType"
        value={type.value}
        options={types[labType]}
        handleChange={handleChangeType}
        additionalClass={classes.selectField}
      />
    );
  };

  return (
    <>
      <Paper className={classes.container}>
        <Card
          classes={cardStyle}
          collapseComponent={<HeaderSection text="Select Task(s)" />}
          expandComponent={<SelectedTasksContentSection />}
        />
        <Card
          classes={cardStyle}
          collapseComponent={<HeaderSection text="Select Update Type" />}
          expandComponent={<UpdateTypeContentSection />}
          expanded
        />
      </Paper>
      <Button className={classes.bottomButton} onClick={handleNext}>
        Continue
      </Button>
    </>
  );
};

export default SelectUpdateType;
