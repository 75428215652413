import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(() => ({
  container: {
    height: 36,
  },
  searchFieldContainer: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    // width: 262,
    height: 36,
  },
  searchIcon: {
    position: "absolute",
    top: 7,
    left: 6,
    height: 22,
    color: "#4E5A71",
  },
  input: {
    marginLeft: 33,
    width: 229,
    "& .MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 4px",
    },
  },
}));

const Search = ({ name, value, additionalClass, handleChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.searchFieldContainer}>
      <SearchIcon className={classes.searchIcon} />
      <InputBase
        className={classnames(classes.input, additionalClass)}
        placeholder="Search Keywords"
        name={name || "keyword"}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default Search;
