import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Dialog, IconButton } from "@material-ui/core";

import OutlinedButton from "components/input/OutlinedButton";
import UploadFiles from "components/input/UploadFiles";
import SelectSearchPBTable from "components/table/SelectSearchPBTable";
import SelectedPBTable from "components/table/SelectedPBTable";
import Program777Chip from "components/display/Program777Chip";

import { atnGetPbEveryday } from "actions/taskActions";

import {
  ratnConvertWicFiles,
  ratnExpandPbWicDetail,
  ratnUpdatePbWicDetail,
} from "state/submissionDetail/action";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  grayText: {
    color: "#777",
  },
  small: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 300,
  },
  smallInputField: {
    width: 200,
  },
  largeInputField: {
    width: 450,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  smallFieldPadding: {
    paddingTop: 20,
  },
  blockPadding: {
    paddingRight: 10,
  },
  inputPadding: {
    paddingTop: 29,
  },
  WicContainer: {
    border: "1px solid #777777",
    padding: 20,
    position: "relative",
    marginBottom: 20,
  },
  WicTitleContainer: {
    position: "relative",
  },
  WicTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  itemName: {
    fontSize: 12,
    color: "#777777",
    marginTop: 4,

    "& span": {
      color: "#003DA5",
      textTransform: "capitalize",
    },
  },
  expandBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  tasksContainer: {
    position: "absolute",
    top: 32,
    right: 70,
  },
  task: {
    background: "#EBEDEF",
    borderRadius: 2,
    fontSize: 12,
    color: "#232B3A",
    padding: "3px 6px",
    marginRight: 10,
  },
  poDataContainer: {
    paddingBottom: 20,
  },
  deleteBtnContainer: {
    paddingTop: 10,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  btnEdit: {
    display: "block",
    marginTop: 10,
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  blockFull: {
    width: "100%",
    paddingRight: 10,
    marginTop: 10,

    "&:last-child": {
      paddingRight: 0,
    },
  },
  inputFieldPick: {
    width: 400,
  },
}));

const PbWicDetails = ({ masterWic = {}, masterWicIndex }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const buyerCompanyName = masterWic.buyerCompanyName;

  const triggeredBy = useSelector((s) => s.submissionDetail.submission.triggeredBy);

  const masterWics = useSelector((s) => s.submissionDetail.tasks);

  const taskType = useSelector((s) => s.submissionDetail.tasks[masterWicIndex].taskType);
  const selfInspectionValidityDate = useSelector(
    (s) => s.submissionDetail.tasks[masterWicIndex].selfInspectionValidityDate,
  );
  const assignTo = useSelector((s) => s.submissionDetail.tasks[masterWicIndex].assignTo);

  const [openSelectPBDialog, setOpenSelectPBDialog] = useState(false);
  const [selectedPBsShow, setSelectedPBsShow] = useState([]);
  const [selectedPBs, setSelectedPBs] = useState([]);
  const [selectedPBIds, setSelectedPBIds] = useState([]);
  const [filteredRelatedPBs, setFilteredRelatedPBs] = useState([]);
  const [listRelatedPB, setListRelatedPB] = useState([]);
  const [searchKeywordPB, setSearchKeywordPB] = useState("");

  const showProgram777 = isShowProgram777(triggeredBy);

  useEffect(() => {
    fetchPB();
  }, []);

  useEffect(() => {
    handleInitMasterWicFiles();
  }, [masterWic.masterWicFiles]);

  const fetchPB = () => {
    setSelectedPBsShow(masterWic.pb);

    if (masterWic.masterWicId) {
      atnGetPbEveryday({
        id: masterWic.masterWicId,
      }).then(({ data }) => {
        if (data) {
          setListRelatedPB(data);
        }
      });
    }
  };

  const handleInitMasterWicFiles = () => {
    const productSpecification = [];
    const artworks = [];
    const aisUPCInformation = [];
    const aisOuterInner = [];
    const aisFactoryInformation = [];
    const e2open = [];

    for (const file of masterWic.masterWicFiles) {
      const fileObj = {
        id: file.id,
        name: file.fileName,
      };

      switch (file.type) {
        case "product_specification":
          productSpecification.push(fileObj);

          break;
        case "artworks":
          artworks.push(fileObj);

          break;
        case "ais_stibo_upc_information":
          aisUPCInformation.push(fileObj);

          break;
        case "ais_stibo_outer_inner":
          aisOuterInner.push(fileObj);

          break;
        case "ais_stibo_factory_information":
          aisFactoryInformation.push(fileObj);

          break;
        case "e2open":
          e2open.push(fileObj);

          break;
        default:
          break;
      }
    }

    dispatch(
      ratnConvertWicFiles({
        productSpecification,
        artworks,
        aisUPCInformation,
        aisOuterInner,
        aisFactoryInformation,
        e2open,
      }),
    );
  };

  const handleTaskDataChange = (tasks) => {
    dispatch(ratnUpdatePbWicDetail(tasks));
  };

  // ========== FILE HANDLERS START ==========
  const handleAddProductSpecificationFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].productSpecification =
      masterWicTasks[masterWicIndex].productSpecification.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemoveProductSpecificationFile = (index) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].productSpecification = masterWics[
      masterWicIndex
    ].productSpecification.filter((item, i) => i !== index);

    handleTaskDataChange(masterWicTasks);
  };

  const handleAddArtworksFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].artworks =
      masterWicTasks[masterWicIndex].artworks.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemoveArtworksFile = (index) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].artworks = masterWics[masterWicIndex].artworks.filter(
      (item, i) => i !== index,
    );

    handleTaskDataChange(masterWicTasks);
  };

  const handleAddAisUPCInformationFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].aisUPCInformation =
      masterWicTasks[masterWicIndex].aisUPCInformation.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemoveAisUPCInformationFile = (index) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].aisUPCInformation = masterWics[
      masterWicIndex
    ].aisUPCInformation.filter((item, i) => i !== index);

    handleTaskDataChange(masterWicTasks);
  };

  const handleAddAisOuterInnerFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].aisOuterInner =
      masterWicTasks[masterWicIndex].aisOuterInner.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemoveAisOuterInnerFile = (index) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].aisOuterInner = masterWics[masterWicIndex].aisOuterInner.filter(
      (item, i) => i !== index,
    );

    handleTaskDataChange(masterWicTasks);
  };

  const handleAisFactoryinformationFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].aisFactoryInformation =
      masterWicTasks[masterWicIndex].aisFactoryInformation.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleUploadE2openFiles = (newFiles) => {
    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].e2open = masterWicTasks[masterWicIndex]?.e2open.concat(newFiles);

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemoveE2openFiles = (index) => {
    let masterWicTasks = masterWics;

    const newE2open = masterWics[masterWicIndex].e2open.filter((item, idx) => idx !== index);

    masterWicTasks[masterWicIndex].e2open = newE2open;

    handleTaskDataChange(masterWicTasks);
  };
  // ========== FILE HANDLERS END ==========

  const handleSearchKeywordChangePB = (e) => {
    const { value } = e.target;

    setSearchKeywordPB(value);

    if (value) {
      let tempTasks = listRelatedPB.filter((item, i) => item.pbNumber.indexOf(value) >= 0);
      setFilteredRelatedPBs(tempTasks);
    } else {
      setFilteredRelatedPBs(listRelatedPB);
    }
  };

  const handleConfirmEditPB = (e, index) => {
    const { name, value } = e.target;

    setSelectedPBsShow((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      });
    });

    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].pb.map((item, idx) => {
      if (idx === index) item.casePack = value;
      return item;
    });

    handleTaskDataChange(masterWicTasks);
  };

  const handleRemovePB = (pbId) => {
    let masterWicTasks = masterWics;

    const newPBs = masterWicTasks[masterWicIndex].pb.filter((item) => item.id !== pbId);

    setSelectedPBsShow(newPBs);

    masterWicTasks[masterWicIndex].pb = newPBs;

    handleTaskDataChange(masterWicTasks);
  };

  const handlePBSelect = (task) => {
    if (selectedPBIds.indexOf(task.id) >= 0) {
      setSelectedPBs((state) => state.filter((item) => item.id !== task.id));
      setSelectedPBIds((state) => state.filter((item) => item !== task.id));
      return;
    }

    setSelectedPBs((state) => state.concat(task));
    setSelectedPBIds((state) => state.concat(task.id));
  };

  const handleConfirmPBSelect = () => {
    setOpenSelectPBDialog(false);
    setSelectedPBsShow(selectedPBs);

    let masterWicTasks = masterWics;

    masterWicTasks[masterWicIndex].pb = selectedPBs;

    handleTaskDataChange(masterWicTasks);
  };

  const handleOpenSelectPBDialog = () => {
    const pbIds = [];

    setSelectedPBs(selectedPBsShow);

    for (let i = 0; i < selectedPBsShow.length; i++) {
      pbIds.push(selectedPBsShow[i].id);
    }

    setSelectedPBIds(pbIds);
    setOpenSelectPBDialog(true);
  };

  const handleCloseSelectPBDialog = () => {
    setOpenSelectPBDialog(false);
  };

  const onExpandWicDetail = () => {
    dispatch(ratnExpandPbWicDetail(masterWicIndex));
  };

  const isFRI = ["FRI", "FRID"].indexOf(taskType) !== -1;

  return (
    <Box className={classes.WicContainer}>
      <Box className={classes.WicTitleContainer}>
        <Box display="flex" className={classes.WicTitle}>
          {showProgram777 && <Program777Chip />}
          Wic Number: {masterWic.wicNumber}
        </Box>
        <Box className={classes.itemName}>Wic Description: {masterWic.productName}</Box>
        <Box className={classes.itemName}>
          Category: <span>{buyerCompanyName}</span>
        </Box>
      </Box>

      <Box display="flex" className={classes.tasksContainer}>
        {masterWic.tasks &&
          masterWic.tasks.map((task, taskIndex) => (
            <Box key={taskIndex} className={classes.task}>
              {t(`task.${task.taskType}`)}
            </Box>
          ))}
      </Box>

      <IconButton className={classes.expandBtn} onClick={onExpandWicDetail}>
        {masterWic.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      {masterWic.expanded && (
        <>
          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            1. Upload AIS/Stibo - UPC information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisUPCInformation}
              handleAddFiles={handleAddAisUPCInformationFiles}
              handleRemoveFile={handleRemoveAisUPCInformationFile}
            />
          </Box>

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            2. Upload AIS/Stibo - Outer/Inner
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisOuterInner}
              handleAddFiles={handleAddAisOuterInnerFiles}
              handleRemoveFile={handleRemoveAisOuterInnerFile}
            />
          </Box>

          {/* <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            5. Upload AIS/Stibo - Factory information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisFactoryInformation}
              handleAddFiles={handleAisFactoryinformationFiles}
              handleRemoveFile={handleRemoveAisFactoryinformationFile}
            />
          </Box> */}

          {isFRI && (
            <>
              <Box className={classnames(classes.title, classes.smallFieldPadding)}>
                3. Product Specification
              </Box>
              <Box className={classes.uploadContainer}>
                <UploadFiles
                  files={masterWic.productSpecification}
                  handleAddFiles={handleAddProductSpecificationFiles}
                  handleRemoveFile={handleRemoveProductSpecificationFile}
                />
              </Box>

              <Box className={classnames(classes.title, classes.smallFieldPadding)}>
                4. Artworks
              </Box>
              <Box className={classes.uploadContainer}>
                <UploadFiles
                  files={masterWic.artworks}
                  handleAddFiles={handleAddArtworksFiles}
                  handleRemoveFile={handleRemoveArtworksFile}
                />
              </Box>
            </>
          )}

          <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
            <Box>
              <Box>{isFRI ? "5" : "3"}. Upload E2Open - PB Detail</Box>

              <Box className={classnames(classes.grayText, classes.small)}>
                Please upload a file (screencap)
              </Box>
            </Box>
          </Box>

          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.e2open || []}
              handleAddFiles={handleUploadE2openFiles}
              handleRemoveFile={handleRemoveE2openFiles}
            />
          </Box>

          <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
            <Box>
              <Box>{isFRI ? "6" : "4"}. PB Information</Box>
              <Box className={classnames(classes.grayText, classes.small)}>
                Please add at least one PB information
              </Box>
            </Box>
          </Box>

          <SelectedPBTable
            selected={selectedPBsShow}
            onRemove={handleRemovePB}
            onClickConfirm={handleConfirmEditPB}
          />

          <Dialog
            className={classes.dialog}
            open={openSelectPBDialog}
            onClose={handleCloseSelectPBDialog}
          >
            <SelectSearchPBTable
              assignTo={assignTo}
              selfInspectionValidityDate={selfInspectionValidityDate}
              taskType={taskType}
              options={searchKeywordPB ? filteredRelatedPBs : listRelatedPB}
              selected={selectedPBIds}
              searchKeyword={searchKeywordPB}
              isDiasbledConfirm={!selectedPBIds}
              onSelect={handlePBSelect}
              onSearchKeywordChange={handleSearchKeywordChangePB}
              onCloseSelectDialog={handleCloseSelectPBDialog}
              onClickConfirm={handleConfirmPBSelect}
            />
          </Dialog>

          <Box className={classes.addButtonContainer}>
            <OutlinedButton
              label="SELECT PB"
              handleClick={() => handleOpenSelectPBDialog()}
              color="primary"
              className={classes.btnEdit}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(PbWicDetails);
