import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { textOrUnknown } from "utils/string";

import Link from "components/display/Link";

import TaskHeaderStatus from "components/submission/TaskHeaderStatus";

const useStyles = makeStyles(() => ({
  header: {
    // display: 'flex',
    // flexDirection: 'column',
    // color: '#777777',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  subTitle: {
    fontSize: 12,
  },
  title2: {
    fontSize: 12,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    color: "#777777",
  },
  right: {
    textAlign: "right",
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellNoBorderLeftRight: {
    borderLeft: "none",
    borderRight: "none",
  },
  tableCellNoBorderTop: {
    borderTop: "none",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  tableCellNoPaddingRight: {
    paddingRight: 0,
  },
  leftButton: {
    paddingRight: 20,
  },
}));

const TaskDetailHeader = ({
  wicNumber,
  wicNoUrl,
  labStatus,
  taskType,
  programName,
  programYear,
  category,
  status,
  remark,
  inspectionTaskDetails,
  expectedCompletionDate,
  confirmedInspectionDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const WICNoLink = () =>
    wicNoUrl ? <Link path={wicNoUrl} text={wicNumber} /> : textOrUnknown(wicNumber);

  return (
    <Box className={classes.header}>
      <Box className={classes.left}>
        <Typography className={classes.title}>
          <WICNoLink /> • {t(`task.${taskType}`)} {remark && remark !== "" && `(${remark})`}
        </Typography>
        <Typography className={classes.subTitle}>
          Program: {programName} {category === "everyday" ? "" : programYear}
        </Typography>

        <TaskHeaderStatus labStatus={labStatus} status={status} />
      </Box>
      <Box className={classes.right}>
        {(taskType === "DPI" || taskType === "FRI" || taskType === "FRID") && (
          <>
            <Box>Confirmed Inspection Date</Box>
            <Box>
              {confirmedInspectionDate
                ? moment(confirmedInspectionDate).format("DD/MMM/YYYY")
                : "TBC"}
            </Box>
          </>
        )}
        {(taskType === "SPU" || taskType === "SPUD") && (
          <>
            <Box>Confirmed Sample Pick Up Date</Box>
            <Box>
              {confirmedInspectionDate
                ? moment(confirmedInspectionDate).format("DD/MMM/YYYY")
                : "TBC"}
            </Box>
          </>
        )}
        {(taskType === "QT" ||
          taskType === "PST" ||
          taskType === "ST" ||
          taskType === "STD" ||
          taskType === "PAC") && (
          <>
            <Box>Confirmed Completion Date</Box>
            <Box>
              {expectedCompletionDate
                ? moment(expectedCompletionDate).format("DD/MMM/YYYY")
                : "TBC"}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TaskDetailHeader;
