import React from 'react';
import ClickableNumericTable from 'components/table/ClickableNumericTable';

const TaskCompletionRate = ({
  tableTitle = "",
  headerData = [],
  rowData = [],
  handleTableDataClick,
}) => {
  return (
    <ClickableNumericTable
      tableTitle={tableTitle}
      infoMessage="(number is task based)"
      headerData={headerData}
      rowData={rowData}
      handleClick={handleTableDataClick}
    />
  );
}

export default TaskCompletionRate;