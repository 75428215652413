import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import FactoryInformation from "./FactoryInformation";
import ProductInformation from "./ProductInformation";

import { atnGetCities, atnGetCountries, atnGetStates } from "actions/commonActions";
import { atnCreateTrfForm, atnGetMasterWicTrfFormData } from "actions/submissionActions";

import { atnGetTrfFactoryData } from "actions/taskActions";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  // title: {
  //   fontSize: 14,
  //   fontWeight: 500,
  // },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const TrfForm = ({ factoryId, tasks, masterWicId, productName, wicNumber, handleSubmit }) => {
  const classes = useStyles();

  const [trfFormData, setTrfFormData] = useState({
    factory: {},
    masterWics: [],
  });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hideState, setHideState] = useState(false);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);
  const [manufactureStateList, setManufactureStateList] = useState([]);
  const [manufactureCityList, setManufactureCityList] = useState([]);
  const [hideManufactureState, setHideManufactureState] = useState(false);

  const getCountries = () => {
    atnGetCountries().then(({ data, message }) => {
      let countries = [];

      for (let i = 0; i < data.length; i++) {
        countries.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCountryList(countries);
    });
  };

  const getStates = (countryId, type) => {
    atnGetStates({
      countryId: countryId,
    }).then(({ data, message }) => {
      if (data && data.length === 1 && !data[0].name) {
        getCities(data[0].id, type);

        if (type === "manufacture") {
          setTrfFormData((prevState) => ({
            ...prevState,
            factory: {
              ...prevState.factory,
              placeOfManufactureStateId: data[0].id,
            },
          }));

          setHideManufactureState(true);
        } else {
          setTrfFormData((prevState) => ({
            ...prevState,
            factory: {
              ...prevState.factory,
              countryOfOriginStateId: data[0].id,
            },
          }));

          setHideState(true);
        }
      } else {
        if (type === "manufacture") {
          setHideManufactureState(false);
        } else {
          setHideState(false);
        }
      }

      let states = [];

      for (let i = 0; i < data.length; i++) {
        states.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      if (type === "manufacture") {
        setManufactureStateList(states);
      } else {
        setStateList(states);
      }
    });
  };

  const getCities = (stateId, type) => {
    atnGetCities({
      stateId: stateId,
    }).then(({ data, message }) => {
      let cities = [];

      for (let i = 0; i < data.length; i++) {
        cities.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      if (type === "manufacture") {
        setManufactureCityList(cities);
      } else {
        setCityList(cities);
      }
    });
  };

  useEffect(() => {
    getCountries();

    atnGetTrfFactoryData({
      factoryId: factoryId,
    }).then(({ data, message }) => {
      if (data) {
        const getTrfFormData = (masterWicId) => {
          return new Promise((resolve, reject) => {
            atnGetMasterWicTrfFormData({
              masterWicId: masterWicId,
            }).then(({ data, message }) => {
              if (data) {
                resolve(data);
              } else {
                reject();
              }
            });
          });
        };

        let masterWics = [];
        let getTrfFormFunctionArray = [];

        const filteredTasks = [];
        const masterWicIds = [];

        for (let i = 0; i < tasks.length; i++) {
          if (masterWicIds.indexOf(tasks[i].masterWicId) === -1) {
            masterWicIds.push(tasks[i].masterWicId);
            filteredTasks.push(tasks[i]);
          }
        }

        for (let i = 0; i < filteredTasks.length; i++) {
          getTrfFormFunctionArray.push(getTrfFormData(filteredTasks[i].masterWicId));

          masterWics.push({
            showMore: false,
            masterWicId: filteredTasks[i].masterWicId,
            productName: filteredTasks[i].productName,
            wicNumber: filteredTasks[i].wicNumber,
            additionalWicNumber: "",
            styleNumber: "",
            contentSizeCount: "",
            dateOfManufacture: "",
            isGoodNotForResale: false,
            goodNotForResaleReason: "",
            isSourcedByWBA: false,
            isDuaneReadeTestSubmission: false,
            isChildResistancePackage: false,
            isFollowingCpsiaTrackingLabel: false,
            trackingLabelRemark: "",
            reasonNotBeingMarkedWithTrackingLabel: [],
            sampleSubmission: "",
            returnSample: "No",
          });
        }

        Promise.all(getTrfFormFunctionArray).then((result) => {
          for (let i = 0; i < result.length; i++) {
            if (result[i].id) {
              masterWics[i].id = result[i].id;
              masterWics[i].additionalWicNumber = result[i].additionalWicNumber;
              masterWics[i].styleNumber = result[i].styleNumber;
              masterWics[i].contentSizeCount = result[i].contentSizeCount;
              masterWics[i].dateOfManufacture = result[i].dateOfManufacture;
              masterWics[i].isGoodNotForResale = result[i].isGoodNotForResale;
              masterWics[i].goodNotForResaleReason = result[i].goodNotForResaleReason;
              masterWics[i].isSourcedByWBA = result[i].isSourcedByWBA;
              masterWics[i].isDuaneReadeTestSubmission = result[i].isDuaneReadeTestSubmission;
              masterWics[i].isChildResistancePackage = result[i].isChildResistancePackage;
              masterWics[i].isFollowingCpsiaTrackingLabel = result[i].isFollowingCpsiaTrackingLabel;
              masterWics[i].trackingLabelRemark = result[i].trackingLabelRemark;
              masterWics[i].reasonNotBeingMarkedWithTrackingLabel = result[i]
                .reasonNotBeingMarkedWithTrackingLabel
                ? result[i].reasonNotBeingMarkedWithTrackingLabel.split(",")
                : [];
              masterWics[i].sampleSubmission = result[i].sampleSubmission;
              masterWics[i].returnSample = result[i].returnSample;
            }
          }

          let factory = {
            factoryAddress: data.address1,
            factoryContactEmail: data.contactEmail,
            factoryContactNumber: data.contactNumber,
            factoryContactPerson: data.contactPerson,
            factoryName: data.name,
            factoryId: data.id,
            countryOfOriginCityId: data.countryOfOriginCityId,
            countryOfOriginCityName: data.countryOfOriginCityName,
            countryOfOriginCountryId: data.countryOfOriginCountryId,
            countryOfOriginCountryName: data.countryOfOriginCountryName,
            countryOfOriginStateId: data.countryOfOriginStateId,
            countryOfOriginStateName: data.countryOfOriginStateName,
            placeOfManufactureCityId: data.placeOfManufactureCityId,
            placeOfManufactureCityName: data.placeOfManufactureCityName,
            placeOfManufactureCountryId: data.placeOfManufactureCountryId,
            placeOfManufactureCountryName: data.placeOfManufactureCountryName,
            placeOfManufactureStateId: data.placeOfManufactureStateId,
            placeOfManufactureStateName: data.placeOfManufactureStateName,
          };

          if (data.countryOfOriginCountryId) {
            getStates(data.countryOfOriginCountryId, "origin");
          }

          if (data.countryOfOriginStateId) {
            getCities(data.countryOfOriginStateId, "origin");
          }

          if (data.placeOfManufactureCountryId) {
            getStates(data.placeOfManufactureCountryId, "manufacture");
          }

          if (data.placeOfManufactureStateId) {
            getCities(data.placeOfManufactureStateId, "manufacture");
          }

          setTrfFormData({
            factory: factory,
            masterWics: masterWics,
          });
        });
      }
    });
  }, []);

  const handleFactoryDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "countryOfOriginCountryId") {
      getStates(value, "origin");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          countryOfOriginStateId: "",
          countryOfOriginStateName: "",
          countryOfOriginCityId: "",
          countryOfOriginCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "countryOfOriginStateId") {
      getCities(value, "origin");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          countryOfOriginCityId: "",
          countryOfOriginCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "placeOfManufactureCountryId") {
      getStates(value, "manufacture");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          placeOfManufactureStateId: "",
          placeOfManufactureStateName: "",
          placeOfManufactureCityId: "",
          placeOfManufactureCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "placeOfManufactureStateId") {
      getCities(value, "manufacture");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          placeOfManufactureCityId: "",
          placeOfManufactureCityName: "",
          [name]: value,
        },
      }));
    } else {
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          [name]: value,
        },
      }));
    }
  };

  const handleMasterWicDataChange = (e, index) => {
    const { name, value } = e.target;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleMasterWicDataRadioChange = (e, index) => {
    const { name, value } = e.target;

    let newValue = value === "yes" ? true : false;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item[name] = newValue;
        }

        return item;
      }),
    }));
  };

  const handleMasterWicDataCheckBoxChange = (e, index) => {
    const { value } = e.target;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          if (item.reasonNotBeingMarkedWithTrackingLabel.indexOf(value) >= 0) {
            item.reasonNotBeingMarkedWithTrackingLabel =
              item.reasonNotBeingMarkedWithTrackingLabel.filter((label) => label !== value);
          } else {
            item.reasonNotBeingMarkedWithTrackingLabel.push(value);
          }
        }

        return item;
      }),
    }));
  };

  const handleExpandClick = (index) => {
    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item.showMore = !item.showMore;
        }

        return item;
      }),
    }));
  };

  const handleExpandAll = () => {
    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item) => {
        item.showMore = true;

        return item;
      }),
    }));
  };

  const handleSubmitWithTrfForm = () => {
    if (
      !trfFormData.factory.placeOfManufactureCityId ||
      !trfFormData.factory.countryOfOriginCityId
    ) {
      return alert("missing data");
    }

    let tempTrfFormData = JSON.parse(JSON.stringify(trfFormData));

    let hasMissingData = false;

    for (let i = 0; i < tempTrfFormData.masterWics.length; i++) {
      if (
        !tempTrfFormData.masterWics[i].additionalWicNumber ||
        !tempTrfFormData.masterWics[i].styleNumber ||
        !tempTrfFormData.masterWics[i].contentSizeCount ||
        !tempTrfFormData.masterWics[i].dateOfManufacture ||
        !tempTrfFormData.masterWics[i].sampleSubmission ||
        (tempTrfFormData.masterWics[i].isGoodNotForResale &&
          !tempTrfFormData.masterWics[i].goodNotForResaleReason) ||
        (tempTrfFormData.masterWics[i].isFollowingCpsiaTrackingLabel &&
          !tempTrfFormData.masterWics[i].trackingLabelRemark) ||
        (!tempTrfFormData.masterWics[i].isFollowingCpsiaTrackingLabel &&
          tempTrfFormData.masterWics[i].reasonNotBeingMarkedWithTrackingLabel.length === 0)
      ) {
        hasMissingData = true;
        break;
      }

      if (tempTrfFormData.masterWics[i].dateOfManufacture) {
        tempTrfFormData.masterWics[i].dateOfManufacture = moment(
          tempTrfFormData.masterWics[i].dateOfManufacture,
        ).format("YYYY-MM-DD");
      }
    }

    if (hasMissingData) {
      return alert("missing data");
    }

    setIsDisabledConformButton(true);

    atnCreateTrfForm({
      factory: tempTrfFormData.factory,
      trf: tempTrfFormData.masterWics,
    }).then(() => {
      handleSubmit();
    });
  };

  return (
    <>
      <Paper className={classes.container}>
        {/* <Box className={classes.title}>
          Fill in TRF Information to Open Task
        </Box> */}

        <FactoryInformation
          data={trfFormData.factory || {}}
          countryList={countryList}
          stateList={stateList}
          cityList={cityList}
          hideState={hideState}
          manufactureStateList={manufactureStateList}
          manufactureCityList={manufactureCityList}
          hideManufactureState={hideManufactureState}
          handleFactoryDataChange={handleFactoryDataChange}
        />
        <Box pt={4}>
          <ProductInformation
            data={trfFormData.masterWics || []}
            handleMasterWicDataChange={handleMasterWicDataChange}
            handleMasterWicDataRadioChange={handleMasterWicDataRadioChange}
            handleMasterWicDataCheckBoxChange={handleMasterWicDataCheckBoxChange}
            handleExpandClick={handleExpandClick}
            handleExpandAll={handleExpandAll}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleSubmitWithTrfForm}
        disabled={isDisabledConformButton}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default TrfForm;
