import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import FileUpload from "components/input/FileUpload";
import Chip from "components/display/Chip";
import Button from "components/input/Button";
import Table from "components/table/Table";

import ErrorModal from "components/modal/ErrorModal";

import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";
import { downloadFile, getTRFDocumentName } from "utils/files";

import { NA, PB_STATUS, TASK_STATUS_ENUM, TASK_TYPE_ENUM } from "shared/constants";

import SampleSealingResultTable from "./SampleSealingResultTable";

import {
  atnDownloadTrfFile,
  atnDownloadTUVTrfFile,
  atnDownloadULTrfFile,
} from "actions/fileActions";
import ReportDetails from "pages/common/ReportDetails";

import { isShowProgram777 } from "services/pbService";
import { getCategory } from "services/categoryService";
import useUserType from "hooks/useUserType";

const TaskDetailContent = ({
  hasPayerInfo = false,
  history,
  taskId,
  submissionId,
  userType,
  assignTo,
  createdAt,
  sampleReceivedDate,
  category,
  taskType,
  taskNumber,
  masterWicId,
  pendingReason,
  taskFiles = [],
  capStatus,
  lab,
  icl,
  lastUpdated,
  labStatus,
  reports = [],
  pacCert = [],
  firstShipmentTotalQuantity,
  fullPoTotalQuantity,
  po = [],
  pb = [],
  e2open = [],
  isResumeReport = false,
  triggeredBy = "",
  spuForEveryDayTaskType,
  selectedLabs = [],
  expectedCompletionDate,
  inspectionTaskDetails,
  sampleSealingResultData,
  editSampleSealingResultData,
  handleResultDataChange,
  handleAddResultData,
  handleRemoveResultData,
  handleCostDialogOpen,
  status,
  completedStatus,
  isUpdateResult,
  isSampleSealingResultEditing,
  handleCancelUpdateResult,
  handleConfirmUpdateResult,
  handleUpdateResultClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openPayerInfoErrorModal, setOpenPayerInfoErrorModal] = useState(false);

  const showProgram777 = isShowProgram777(triggeredBy);

  const { isEveryday } = getCategory(category);

  const { isLab, isSupplier } = useUserType(userType);

  const isEverydaySPUAndFRI = [TASK_TYPE_ENUM.SPUD, TASK_TYPE_ENUM.FRID].includes(taskType);

  const isSpuTaskTypes = [TASK_TYPE_ENUM.SPU, TASK_TYPE_ENUM.SPUD].includes(taskType);

  function PbInfoColumn() {
    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            PB Number [ Production batch line ID]
          </Grid>
          <Grid item xs={3}>
            PB Quantity
          </Grid>
          <Grid item xs={3}>
            {isSpuTaskTypes ? "Qty Ready for Inspection" : "Case Pack"}
          </Grid>
        </Grid>
        {pb.map((item) => {
          const isNotExisted = item.pbStatus === PB_STATUS.NOT_EXISTED;

          const pbIdentity = item?.pbNumber ? `${item?.pbNumber} [ ${item?.pbLineId || NA} ]` : NA;

          return (
            <Grid key={item.id} container>
              <Grid item xs={6}>
                {pbIdentity}{" "}
                {isNotExisted && <span className={classes.error}>{`(Cancelled)`}</span>}
              </Grid>
              <Grid item xs={3}>
                {item.pbQuantity}
              </Grid>
              <Grid item xs={3}>
                {item.casePack}
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }

  function E2openColumn() {
    return (
      <div className={classes.gridRoot}>
        {e2open.map(({ id, fileName, location }, index) => {
          return (
            <Box display="flex" key={index}>
              <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
            </Box>
          );
        })}
      </div>
    );
  }

  const handleDownloadTrfFile = (taskId, fileName) => {
    if (fileName === "F-Admin-Booking_Form-WBA-210104-4.xlsx") {
      atnDownloadTUVTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    } else if (fileName === "Generic Inspection Request Form Rev15 Protect.xlsm") {
      atnDownloadULTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    } else {
      atnDownloadTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    }
  };

  const handleSPUTaskType = (value) => {
    let result = "";

    if (value) {
      let typeArray = value.split(",");

      for (let i = 0; i < typeArray.length; i++) {
        result += (i === 0 ? "" : ", ") + t(`task.${typeArray[i]}`);
      }
    }

    return result;
  };

  const detailsTableColumn = [
    {
      columnId: "title",
      width: "30%",
      render: (data) => {
        return data.title;
      },
    },
    {
      columnId: "value",
      render: ({ title, value }) => {
        if (title === "TRF Document") {
          return value.trfDocument && value.taskId ? (
            <Box
              className={classes.trf}
              onClick={() => handleDownloadTrfFile(value.taskId, value.trfDocument)}
            >
              {value.trfDocument}
            </Box>
          ) : (
            textOrUnknown(value.trfDocument)
          );
        } else if (title === "Supporting Documents") {
          return value.supportingDocuments ? (
            <FileUpload data={value.supportingDocuments} editable={false} multiple />
          ) : (
            textOrUnknown(value.supportingDocuments)
          );
        } else if (title === "CAP") {
          return value.capDocuments ? (
            <Box display="flex">
              <Box>
                <FileUpload data={value.capDocuments} editable={false} multiple />
              </Box>
              {(capStatus === "rejected" || capStatus === "uploaded") && isSupplier && (
                <Box>
                  <Edit
                    className={classes.editCAPButton}
                    onClick={() =>
                      history.push(`/supplier/submissions/${submissionId}/task/${taskId}/uploadCAP`)
                    }
                  />
                </Box>
              )}
            </Box>
          ) : (
            textOrUnknown(value.capDocuments)
          );
        }

        return value;
      },
    },
  ];

  let detailsTableData = [];

  if (selectedLabs && selectedLabs.length > 0) {
    detailsTableData.push({
      id: uuid(),
      title: "Designated Labs",
      value: selectedLabs.map((item) => <Chip key={item.id} label={item.labName} />),
    });
  }

  if (taskType === TASK_TYPE_ENUM.SS) {
    detailsTableData.push({
      id: uuid(),
      title: "Responsible Party",
      value: assignTo ? t(`user.${assignTo}`) : "N/A",
    });
  } else {
    detailsTableData.push({
      id: uuid(),
      title: "Date",
      value: textOrUnknown(getDateFormat(createdAt)),
    });

    detailsTableData.push({
      id: uuid(),
      title: "Task ID",
      value: taskNumber,
    });
  }

  let trfDocument = getTRFDocumentName(lab, taskType);

  detailsTableData.push({
    id: uuid(),
    title: "TRF Document",
    value: { trfDocument, masterWicId, taskId },
  });

  if (pendingReason) {
    detailsTableData.push({
      id: uuid(),
      title: "Pending Reason",
      value: pendingReason,
    });
  }

  if (taskFiles && taskFiles.length > 0) {
    let supportingDocuments = [];
    let capDocuments = [];

    for (let i = 0; i < taskFiles.length; i++) {
      if (taskFiles[i].type === "supporting_document") {
        supportingDocuments.push({
          id: taskFiles[i].id,
          fileName: taskFiles[i].fileName,
          fileUrl: taskFiles[i].location,
        });
      } else if (taskFiles[i].type === "cap") {
        capDocuments.push({
          id: taskFiles[i].id,
          fileName: taskFiles[i].fileName,
          fileUrl: taskFiles[i].location,
        });
      }
    }

    detailsTableData.push({
      id: uuid(),
      title: "Supporting Documents",
      value: { supportingDocuments },
    });

    if (capDocuments.length > 0) {
      detailsTableData.push({
        id: uuid(),
        title: "CAP",
        value: { capDocuments },
      });
    }
  }

  if (expectedCompletionDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Completion Date",
      value: expectedCompletionDate,
    });
  }

  if (sampleReceivedDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Sample Received Date",
      value: sampleReceivedDate,
    });
  }

  if (inspectionTaskDetails && inspectionTaskDetails.expectedInspectionDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Inspection Date",
      value: inspectionTaskDetails.expectedInspectionDate,
    });
  }

  if (inspectionTaskDetails && inspectionTaskDetails.expectedSamplePickUpDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Sample Pick Up Date",
      value: inspectionTaskDetails.expectedSamplePickUpDate,
    });
  }

  if (
    [TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID].includes(taskType) &&
    inspectionTaskDetails &&
    inspectionTaskDetails.inspectionLevel
  ) {
    detailsTableData.push({
      id: uuid(),
      title: "Inspection Level",
      value: t(`inspectionLevel.${inspectionTaskDetails.inspectionLevel}`),
    });

    detailsTableData.push({
      id: uuid(),
      title: "AQL Level",
      value: (
        <>
          <div>AQL Level(Critical) - {inspectionTaskDetails.aqlCritical}</div>
          <div>AQL Level(Major) - {inspectionTaskDetails.aqlMajor}</div>
          <div>AQL Level(Minor) - {inspectionTaskDetails.aqlMinor}</div>
        </>
      ),
    });
  }

  if (
    po.length > 0 &&
    [
      TASK_TYPE_ENUM.DPI,
      TASK_TYPE_ENUM.FRI,
      TASK_TYPE_ENUM.FRID,
      TASK_TYPE_ENUM.SPU,
      TASK_TYPE_ENUM.SPUD,
    ].includes(taskType)
  ) {
    let purchaseOrder = [];
    // let firstTotalPoQuantity = 0;
    // let totalPoQuantity = 0;
    let totalQuantityAvailableForInspection = 0;
    let inspectionPercentage = 0;

    po.map((item) => {
      purchaseOrder.push(item);
      // firstTotalPoQuantity = parseInt(item.firstShipmentTotalQuantity || '0');
      // totalPoQuantity = parseInt(item.fullPoTotalQuantity || '0');
      totalQuantityAvailableForInspection += parseInt(item.poQuantity || "0");
    });

    if (totalQuantityAvailableForInspection && fullPoTotalQuantity) {
      inspectionPercentage = (
        (totalQuantityAvailableForInspection / fullPoTotalQuantity) *
        100
      ).toFixed(2);
    }

    if ([TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID].includes(taskType)) {
      detailsTableData.push({
        id: uuid(),
        title: "1st Shipment Total PO Quantity",
        value: firstShipmentTotalQuantity,
      });

      detailsTableData.push({
        id: uuid(),
        title: "Total P.O. Quantity",
        value: fullPoTotalQuantity,
      });
    }

    detailsTableData.push({
      id: uuid(),
      title: "Purchase Order",
      value: (
        <>
          <Grid container>
            <Grid item xs={3}>
              PO Number
            </Grid>
            {isEveryday ? (
              <>
                <Grid item xs={3}>
                  Po Quantity
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  Po Quantity in Units
                </Grid>
                <Grid item xs={3}>
                  Actual Quantity in Pieces
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              {isSpuTaskTypes ? "Qty Ready for Inspection" : "Case Pack"}
            </Grid>
          </Grid>
          {purchaseOrder.map((item) => (
            <Grid key={item.id} container>
              <Grid item xs={3}>
                {item.poNumber} {item.status === TASK_STATUS_ENUM.CANCELLED && " (cancelled)"}
              </Grid>

              {!isEveryday && (
                <Grid item xs={3}>
                  {item.poQuantityInUnits}
                </Grid>
              )}
              <Grid item xs={3}>
                {item.poQuantity}
              </Grid>
              <Grid item xs={3}>
                {isSpuTaskTypes ? item.quantityReadyForInspection : item.casePack}
              </Grid>
            </Grid>
          ))}
        </>
      ),
    });

    if ([TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID].includes(taskType)) {
      detailsTableData.push({
        id: uuid(),
        title: "Total Quantity Available for Inspection",
        value: totalQuantityAvailableForInspection,
      });

      detailsTableData.push({
        id: uuid(),
        title: "Inspection Percentage",
        value: `${inspectionPercentage} %`,
      });
    }
  }

  if (showProgram777 && isEverydaySPUAndFRI) {
    e2open.length > 0 &&
      detailsTableData.push({
        id: uuid(),
        title: "Upload E2open - PB detail",
        value: <E2openColumn />,
      });

    pb.length > 0 &&
      detailsTableData.push({
        id: uuid(),
        title: "PB Information",
        value: <PbInfoColumn />,
      });
  }

  // if (taskType === "SS") {
  //   // setSampleSealingResultData(sampleSealingResults);
  // }

  if (taskType === TASK_TYPE_ENUM.SPUD) {
    detailsTableData.push({
      id: uuid(),
      title: "Task Type for Sample Pickup",
      value: handleSPUTaskType(spuForEveryDayTaskType),
    });
  }

  const handleOpenPayerInfoErrorModal = () => {
    setOpenPayerInfoErrorModal(true);
  };

  const handleClosePayerInfoErrorModal = () => {
    setOpenPayerInfoErrorModal(false);
  };

  const latestSSResult =
    sampleSealingResultData && sampleSealingResultData[sampleSealingResultData?.length - 1]?.result;

  const disabledSSTaskComplete =
    isSampleSealingResultEditing ||
    !latestSSResult ||
    ["reject", "pending"].includes(latestSSResult);

  const showSSCompleteButton =
    isLab &&
    ![TASK_STATUS_ENUM.COMPLETED, TASK_STATUS_ENUM.CLOSED, TASK_STATUS_ENUM.CANCELLED].includes(
      status,
    ) &&
    sampleSealingResultData &&
    sampleSealingResultData?.length > 0;

  return (
    <>
      <Typography className={classes.title2}>Task Details</Typography>
      <Box mt={2} mb={6}>
        <Table
          classes={{ containerStyle: classes.tableStyle }}
          data={detailsTableData}
          columnData={detailsTableColumn}
          noHeader
          noPagination
        />
      </Box>

      {isEveryday && (
        <Box py={2}>
          <Typography className={classes.title2}>ICL Number</Typography>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={3} className={classes.gridItem}>
              ICL Number
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem}>
              {icl || "N/A"}
            </Grid>
          </Grid>
        </Box>
      )}

      {labStatus !== TASK_STATUS_ENUM.REJECTED && taskType !== TASK_TYPE_ENUM.SS && (
        <>
          <Typography className={classes.title2}>Report Details</Typography>
          <Box mt={2} className={classes.reportsTable}>
            <ReportDetails
              data={{ reports, certifications: pacCert }}
              status={status}
              completedStatus={completedStatus}
              submissionId={submissionId}
              taskType={taskType}
              triggeredBy={triggeredBy}
              pb={pb}
              isResumeReport={isResumeReport}
              assignTo={assignTo}
            />
            <Box pt={4} display="flex" justifyContent="space-between">
              <Typography className={classes.text}>
                Last Updated: <br />
                {moment(lastUpdated).format("DD/MMM/YYYY HH:mm:ss")}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {taskType === TASK_TYPE_ENUM.SS && (
        <>
          <Box display="flex">
            <Box display="flex" flexGrow={1} className={classes.title2}>
              Result
            </Box>
            <Box>
              {isLab &&
                ![
                  TASK_STATUS_ENUM.COMPLETED,
                  TASK_STATUS_ENUM.CLOSED,
                  TASK_STATUS_ENUM.CANCELLED,
                ].includes(status) && (
                  <>
                    {isUpdateResult ? (
                      <Box display="flex">
                        <Box className={classes.leftButton}>
                          <Button variant="outlined" onClick={() => handleCancelUpdateResult()}>
                            CANCEL
                          </Button>
                        </Box>
                        <Box>
                          <Button variant="contained" onClick={() => handleConfirmUpdateResult()}>
                            SAVE
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Button variant="contained" onClick={() => handleUpdateResultClick()}>
                        UPDATE RESULT
                      </Button>
                    )}
                  </>
                )}
            </Box>
          </Box>

          <Box mt={2} className={classes.reportsTable}>
            <SampleSealingResultTable
              isUpdateResult={isUpdateResult}
              sampleSealingResultData={sampleSealingResultData}
              editSampleSealingResultData={editSampleSealingResultData}
              handleResultDataChange={handleResultDataChange}
              handleAddResultData={handleAddResultData}
              handleRemoveResultData={handleRemoveResultData}
            />
            <Box pt={4} display="flex" justifyContent="space-between">
              <Typography className={classes.text}>
                Last Updated: <br />
                {moment(lastUpdated).format("DD/MMM/YYYY HH:mm:ss")}
              </Typography>

              {showSSCompleteButton && (
                <Button
                  variant="contained"
                  onClick={
                    !hasPayerInfo ? handleOpenPayerInfoErrorModal : () => handleCostDialogOpen()
                  }
                  disabled={disabledSSTaskComplete}
                >
                  MARK TASK AS COMPLETE
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}

      <ErrorModal
        open={openPayerInfoErrorModal}
        onClose={handleClosePayerInfoErrorModal}
        title="Supplier not yet completed Payer Infomation"
        content="Please remind Supplier to complete payer information on this submission."
      />
    </>
  );
};

export default withRouter(TaskDetailContent);

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  title2: {
    fontSize: 12,
    fontWeight: 500,
  },
  tableTitle: {
    color: "#AAAAAA",
    fontSize: 10,
  },
  tableCell: {
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
  },
  pbContainer: {
    paddingTop: "4px",
    paddingBottom: "8px",
  },
  pbRow: {
    display: "flex",
    width: "100%",
  },
  pbCell: {
    padding: "0 12px",
    paddingTop: "4px !important",
    paddingBottom: "0px !important",
  },
  text: {
    fontSize: 10,
  },
  leftButton: {
    paddingRight: 20,
  },
  trf: {
    cursor: "pointer",
    color: "#005EFF",
  },
  editCAPButton: {
    marginLeft: 10,
    color: "#777777",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  gridRoot: {
    flexGrow: 1,
  },
  gridContainer: {
    padding: "16px 0",
  },
  gridItem: {
    fontSize: 10,
    border: "1px solid #777777",
    padding: 10,
    "&:first-child": {
      borderRight: "none",
    },
  },
  tableStyle: {
    overflowX: "unset",
  },
  error: {
    color: "#D42600",
  },
}));
