import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from "components/modal/Modal";

import { atnGetFinancialList, atnUpdateFinancialYear } from "actions/analyticsActions";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  chooseTitle: {
    fontSize: 10,
  },

  chooseFinanceYear: {
    fontSize: 12,
    color: "#232B3A",
    fontWeight: 500,
    marginTop: 20,
  },

  selectYearGroup: {
    marginTop: 30,
    width: 280,
  },

  selectGroupFirst: {
    marginRight: 20,
  },

  selectGroup: {
    marginTop: 30,
  },

  dateCustom: {
    marginRight: 20,
    marginTop: 20,
  },

  fourBox: {
    textAlign: "right",
    marginTop: 50,
    marginBottom: 20,
  },

  applyBtn: {
    marginLeft: 15,
  },

  textEditYear: {
    color: "#005EFF",
    textDecorationLine: "underline",
    borderLeft: "1px solid black",
    paddingLeft: 30,
    cursor: "pointer",
  },

  firstGroupEdit: {
    marginRight: 30,
  },

  radioYear: {
    marginTop: 0,
  },

  selectYearEditGroup: {
    marginTop: 30,
    height: 242,
    border: "1px solid #D8D8D8",
    padding: "10px 20px",
    overflowY: "auto",
  },

  dateEditCustom: {
    marginTop: 30,
    marginRight: 15,
  },

  groupClearBtn: {
    float: "right",
    marginTop: 50,
  },

  textClear: {
    lineHeight: "25px",
    "& span": {
      color: "red",
    },
  },
}));

const FinancialYearPopup = ({
  currentFinancialYear,
  listFinancialYear = [],
  handleClose,
  cbSetData = function () {},
  cbSetDataListFinancialYear = function () {},
}) => {
  const classes = useStyles();

  // ============== LIST FINANCIAL YEAR STATE ===============
  const [listFinancialYearState, setListFinancialYearState] = useState(listFinancialYear);

  // ============== CHECK OPTION YEAR OR CUSTOM ===============
  const [optionYear, setOptionYear] = useState("financial_year");
  const handleOptionYear = (e) => {
    if (e.target.value === "financial_year") {
      setCustomYearFrom("");
      setCustomYearTo("");
    } else {
      setFinancialYearID("");
    }
    setOptionYear(e.target.value);
  };

  // ============== Call back to set Financial Year ===============
  const [selectedFinancialYear, setSelectedFinancialYear] = useState({
    id: currentFinancialYear.id,
    value: currentFinancialYear.value,
    startAt: currentFinancialYear.startAt,
    endAt: currentFinancialYear.endAt,
  });

  useEffect(() => {
    cbSetData(selectedFinancialYear);
  }, [selectedFinancialYear]);

  useEffect(() => {
    cbSetDataListFinancialYear(listFinancialYearState);
  }, [listFinancialYearState]);

  const [financialYearID, setFinancialYearID] = React.useState("");
  const handleChangeFinancialYear = (e) => {
    setFinancialYearID(e.target.value);
  };

  const [customYearFrom, setCustomYearFrom] = React.useState("");
  const handleYearFrom = (e) => {
    setCustomYearFrom(e.target.value);
  };

  const [customYearTo, setCustomYearTo] = React.useState("");
  const handleYearTo = (e) => {
    setCustomYearTo(e.target.value);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    // console.log('financialYearID : ', financialYearID);

    if (financialYearID !== "") {
      let item = listFinancialYearState.filter((item, index) => {
        return item.id === financialYearID;
      });

      setSelectedFinancialYear(item[0]);
    } else {
      setSelectedFinancialYear({ id: -1, value: -1, startAt: customYearFrom, endAt: customYearTo });
    }

    handleClose();
  };

  // ================ EDIT FINANCIAL YEAR GROUP POPUP ================

  const [openEditFinanYearPop, setOpenEditFinanYearPop] = useState(false);
  const [optionYearEdit, setOptionYearEdit] = useState(currentFinancialYear.value);
  const [optionYearEditID, setOptionYearEditID] = useState(currentFinancialYear.id);

  // ================ POPUP CONFIRM EDIT FINANCIAL YEAR  ================
  const [openConfirmMessage, setOpenConfirmMessage] = useState(false);
  const handleOpenConfirmMessage = () => {
    setOpenConfirmMessage(!openConfirmMessage);
  };

  const confirmEditFinal = () => {
    handleOpenConfirmMessage();

    atnUpdateFinancialYear({
      id: optionYearEditID,
      startAt: customYearFromEdit,
      endAt: customYearToEdit,
      value: optionYearEdit,
    }).then(({ data, message }) => {
      getListFinancialYear();
      handlePopEditFinaYear();
    });
  };

  const cancelEditFinal = () => {
    handleOpenConfirmMessage();
  };

  // ================== Call API - HANDLE get LIST FINANCIAL YEAR ==================
  const getListFinancialYear = (data) => {
    atnGetFinancialList(data).then(({ data, message }) => {
      setListFinancialYearState(data);
    });
  };

  // ================== Call API FIRST TIME - ==================

  // ================ END POPUP CONFIRM EDIT FINANCIAL YEAR  ================

  const [customYearFromEdit, setCustomYearFromEdit] = useState("");
  const handleYearFromEdit = (e) => {
    setCustomYearFromEdit(e.target.value);
  };

  const [customYearToEdit, setCustomYearToEdit] = useState("");
  const handleYearToEdit = (e) => {
    setCustomYearToEdit(e.target.value);
  };

  const handlePopEditFinaYear = () => {
    setOpenEditFinanYearPop(!openEditFinanYearPop);
  };

  const confirmEditYear = () => {
    handleOpenConfirmMessage();
  };

  const cancelEditYear = () => {
    handlePopEditFinaYear();
  };

  const handleOptionYearEdit = (e) => {
    // console.log('e.target.value : ', e.target.value);
    // console.log('listFinancialYearState : ', listFinancialYearState);

    setOptionYearEditID(parseInt(e.target.value));

    let selected = listFinancialYearState.filter((item, index) => {
      return item.id === parseInt(e.target.value);
    });

    // console.log('selected is : ', selected);
    setOptionYearEdit(selected[0].value);
  };

  // useEffect(() => {
  //   console.log('optionYearEditID : ', optionYearEditID);
  //   console.log('optionYearEdit : ', optionYearEdit);
  //   console.log('customYearFromEdit : ', customYearFromEdit);
  //   console.log('customYearToEdit : ', customYearToEdit);

  // }, [optionYearEditID, optionYearEdit, customYearFromEdit, customYearToEdit]);

  // ================ END EDIT FINANCIAL YEAR GROUP POPUP ================

  return (
    <Box display="block">
      <Box display="block" className={classes.chooseTitle}>
        Choose Financial Year or Custom Period
      </Box>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="position"
          value={optionYear}
          onChange={handleOptionYear}
          name="select_year"
        >
          <Box display="flex" alignItems="center">
            <FormControlLabel
              value="financial_year"
              control={<Radio color="primary" />}
              label="Financial Year"
              className={classes.selectGroupFirst}
            />
            <Box className={classes.textEditYear} onClick={() => handlePopEditFinaYear()}>
              Edit Financial Year
            </Box>
          </Box>

          {optionYear === "financial_year" && (
            <>
              <Box display="block" className={classes.chooseFinanceYear}>
                1.Choose the Financial Year{" "}
              </Box>
              <FormControl className={classes.selectYearGroup}>
                <Select
                  value={financialYearID}
                  onChange={handleChangeFinancialYear}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Year
                  </MenuItem>

                  {listFinancialYearState.map((item, key) => (
                    <MenuItem key={key} value={item.id}>
                      {item.value} | {item.startAt} - {item.endAt}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={2020}>2020 | 2020-09-01 - 2021-09-01</MenuItem>
                  <MenuItem value={2019}>2019 | 2019-09-01 - 2020-09-01</MenuItem>
                  <MenuItem value={2018}>2018 | 2018-09-01 - 2019-09-01</MenuItem> */}
                </Select>
              </FormControl>
            </>
          )}

          <FormControlLabel
            value="custom_year"
            control={<Radio color="primary" />}
            label="Custom Period"
            className={classes.selectGroup}
          />
          {optionYear === "custom_year" && (
            <>
              <Box display="block" className={classes.chooseFinanceYear}>
                1. Select Custom Period
              </Box>
              <form className={classes.container} noValidate>
                <TextField
                  id="dateFrom"
                  type="date"
                  value={customYearFrom}
                  onChange={handleYearFrom}
                  className={classes.dateCustom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="dateTo"
                  type="date"
                  value={customYearTo}
                  onChange={handleYearTo}
                  className={classes.dateCustom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </>
          )}
        </RadioGroup>
      </FormControl>

      <Box display="block" className={classes.fourBox}>
        <Button color="primary" onClick={() => handleCancel()}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.applyBtn}
          onClick={() => handleConfirm()}
          disabled={!financialYearID && !(new Date(customYearFrom) < new Date(customYearTo))}
        >
          CONFIRM
        </Button>
      </Box>

      {/* ------------ MODAL EDIT FINANCIAL YEAR ------------ */}
      <Modal
        title="Edit Financial Year"
        open={openEditFinanYearPop}
        handleClose={handlePopEditFinaYear}
      >
        <Box display="flex">
          <Box className={classes.firstGroupEdit}>
            <Box display="block" className={classes.chooseFinanceYear}>
              1.Choose the Financial Year{" "}
            </Box>

            <FormControl className={classes.selectYearEditGroup}>
              <RadioGroup
                aria-label="position"
                value={optionYearEditID}
                onChange={handleOptionYearEdit}
                name="select_year_edit"
              >
                {listFinancialYearState.map((item, key) => (
                  <FormControlLabel
                    key={key}
                    value={item.id}
                    control={<Radio color="primary" />}
                    label={`${item.value} | ${item.startAt} - ${item.endAt}`}
                    className={classes.radioYear}
                  />
                ))}
                {/* <FormControlLabel value="2020" control={<Radio color="primary" />} label="2020 | 2020-09-01 - 2021-09-01" className={classes.radioYear} />
                <FormControlLabel value="2019" control={<Radio color="primary" />} label="2019 | 2019-09-01 - 2020-09-01" className={classes.radioYear} />
                <FormControlLabel value="2018" control={<Radio color="primary" />} label="2018 | 2018-09-01 - 2019-09-01" className={classes.radioYear} />
                <FormControlLabel value="2017" control={<Radio color="primary" />} label="2017 | 2017-09-01 - 2018-09-01" className={classes.radioYear} />
                <FormControlLabel value="2016" control={<Radio color="primary" />} label="2016 | 2016-09-01 - 2017-09-01" className={classes.radioYear} /> */}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box>
            <Box display="block" className={classes.chooseFinanceYear}>
              2. Custom Period
            </Box>
            <form className={classes.container} noValidate>
              <TextField
                id="dateEditFrom"
                type="date"
                value={customYearFromEdit}
                onChange={handleYearFromEdit}
                className={classes.dateEditCustom}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="dateEditTo"
                type="date"
                value={customYearToEdit}
                onChange={handleYearToEdit}
                className={classes.dateEditCustom}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Box>
        </Box>

        <Box display="block" className={classes.fourBox}>
          <Button color="primary" onClick={() => cancelEditYear()}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={() => confirmEditYear()}
            disabled={!(new Date(customYearToEdit) > new Date(customYearFromEdit))}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
      {/* ------------ END MODAL EDIT FINANCIAL YEAR ------------ */}

      {/* ------------ MODAL CONFIRM CHANGE FINANCIAL YEAR ------------ */}
      <Modal
        title="Change Financial Year"
        open={openConfirmMessage}
        handleClose={handleOpenConfirmMessage}
        size="small"
      >
        <Box display="block" className={classes.textClear}>
          Are you sure you want to change <span>{optionYearEdit} Finacial Year Period</span> to "
          {customYearFromEdit} - {customYearToEdit}" ?
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button color="primary" onClick={() => cancelEditFinal()}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={() => confirmEditFinal()}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>

      {/* ------------ END MODAL CONFIRM CHANGE FINANCIAL YEAR ------------ */}
    </Box>
  );
};

export default FinancialYearPopup;
