import { USER_ROLE } from "shared/constants";

const useUserRole = (role = "") => {
  const isBuyerRole = role === USER_ROLE.BUYER;
  const isBuyerSourcingRole = role === USER_ROLE.BUYER_SOURCING;
  const isSupplierRole = role === USER_ROLE.SUPPLIER;
  const isLabRole = role === USER_ROLE.LAB;
  const isLabAdminRole = role === USER_ROLE.LAB_ADMIN;

  return { isBuyerRole, isBuyerSourcingRole, isSupplierRole, isLabRole, isLabAdminRole };
};

export default useUserRole;
