import React, { useEffect, useState } from "react";

import MenuBar from "./MenuBar";
import Task from "./Task";
import Products from "./Products";
import PO from "./PO";
import SupplierFactory from "./SupplierFactory";
import Comment from "./Comments";
import ActivityLog from "./ActivityLog";

import { atnDownloadAllReports, atnGetItemDetails } from "actions/itemActions";
import Tabs from "components/navigation/Tabs";
import { ITEM_CATEGORY, USER_TYPE } from "shared/constants";

import AddTaskModal from "./AddTask";
import ExemptionTenderingModal from "./ExemptionTenderingModal";

import buyerData from "./data/buyerData.json";
import supplierData from "./data/supplierData.json";

import { downloadFile } from "utils/files";
import useToggle from "hooks/useToggle";

import { canTenderingLabExemption } from "services/tenderingService";

const initialDetails = {
  id: "",
  brand: "",
  buyerCompanyName: "",
  category: "",
  hasTasks: 0,
  isProgram777: false,
  isTenderingProgram: false,
  productImages: [],
  productName: "",
  programName: "",
  programYear: "",
  shipByDate: "",
  status: "",
  supplierName: "",
  wicNumber: "",
};

const ItemDetail = ({ type, itemId }) => {
  const data = type === USER_TYPE.buyer ? buyerData : supplierData;

  const [openExceptionTendering, toggleExceptionTendering] = useToggle(false);

  const [tabs, setTabs] = useState([]);

  const [details, setDetails] = useState(initialDetails);

  const [taskType, setTaskType] = useState(null);

  const [addTaskModalParams, setAddTaskModalParams] = useState({
    open: false,
    refresh: false,
  });

  const [isUpdateLabel, setIsUpdateLabel] = useState(false);

  // for tendering exemption modal
  const [tasks, setTasks] = useState([]);

  const handleGetTasks = (allTasks) => {
    setTasks(allTasks);
  };

  const handleUpdateLabel = (bool) => {
    setIsUpdateLabel(bool);
  };

  const getItemDetails = (id) => {
    atnGetItemDetails({ type, data: { id } }).then((result) => {
      if (result?.data?.category !== ITEM_CATEGORY.everyday.value) {
        if (result?.data?.hasTasks) {
          setTabs(data.tabs.filter((item) => item.value !== "poPB"));
        } else {
          setTabs(data.tabs.filter((item) => item.value !== "poPB" && item.value !== "task"));
        }
      } else {
        if (result.data.hasTasks) {
          setTabs(data.tabs);
        } else {
          setTabs(data.tabs.filter((item) => item.value !== "task"));
        }
      }

      setDetails({ id, ...result.data });

      handleUpdateLabel(false);
    });
  };

  const handleBuyerAllReportsDownload = () => {
    atnDownloadAllReports({
      masterWicId: itemId,
    }).then(async (data) => {
      downloadFile(data, "all_reports.zip");
    });
  };

  useEffect(() => {
    getItemDetails(itemId);
  }, []);

  useEffect(() => {
    isUpdateLabel && getItemDetails(details.id);
  }, [isUpdateLabel]);

  useEffect(() => {
    if (addTaskModalParams.refresh) {
      window.location.reload();
    }
  }, [addTaskModalParams.refresh]);

  const tabsData = tabs.map((tab) => {
    const { title, content } = tab;

    let component = null;
    if (title === "Task")
      component = (
        <Task
          data={details}
          taskTypeState={{ taskType, setTaskType }}
          handleGetTasks={handleGetTasks}
        />
      );
    else if (title === "Products")
      component = (
        <Products
          userType={type}
          data={details}
          masterWicId={itemId}
          handleUpdateLabel={handleUpdateLabel}
        />
      );
    else if (title === "PO/PB")
      component = <PO userType={type} isProgram777={details.isProgram777} masterWicId={itemId} />;
    else if (title === "Supplier & Factory")
      component = <SupplierFactory userType={type} data={details} />;
    else if (title === "Comments") component = <Comment data={details} />;
    else if (title === "Activity Log") component = <ActivityLog data={content} />;

    return { ...tab, component };
  });

  const handleTenderingExceptionSubmit = () => {
    getItemDetails(itemId);
  };

  const isTenderingLabExemption = canTenderingLabExemption(
    details?.category,
    details?.brand,
    details?.isTenderingProgram,
  );

  return (
    <>
      <MenuBar
        data={details}
        actions={{ setAddTaskModalParams, handleBuyerAllReportsDownload, toggleExceptionTendering }}
        userType={type}
        taskType={taskType}
        isTenderingLabExemption={isTenderingLabExemption}
      />

      <Tabs data={tabsData} gridDisplay />

      {addTaskModalParams.open && (
        <AddTaskModal
          data={{ masterWicId: itemId }}
          category={details.category}
          userType={type}
          open={addTaskModalParams.open}
          setParams={setAddTaskModalParams}
        />
      )}

      {openExceptionTendering && (
        <ExemptionTenderingModal
          masterWicId={itemId}
          tasks={tasks}
          open={openExceptionTendering}
          onClose={toggleExceptionTendering}
          onSubmit={handleTenderingExceptionSubmit} // refetch detail
        />
      )}
    </>
  );
};

export default ItemDetail;
