import { TASK_TYPE_ENUM } from "shared/constants";

export function getTaskFlow(taskType) {
  const spuTasks = [TASK_TYPE_ENUM.SPU, TASK_TYPE_ENUM.SPUD];

  const pacTasks = [TASK_TYPE_ENUM.PAC];

  const inspectionTasks = [
    TASK_TYPE_ENUM.SS,
    TASK_TYPE_ENUM.DPI,
    TASK_TYPE_ENUM.FRI,
    TASK_TYPE_ENUM.FRID,
  ];

  const testingTasks = [
    TASK_TYPE_ENUM.ST,
    TASK_TYPE_ENUM.STD,
    TASK_TYPE_ENUM.PAC,
    TASK_TYPE_ENUM.PST,
    TASK_TYPE_ENUM.QT,
  ];

  let isSamplePickUp = false;
  let isInspection = false;
  let isTesting = false;
  let isPac = false;

  if (taskType) {
    isSamplePickUp = spuTasks.includes(taskType);
    isInspection = inspectionTasks.includes(taskType);
    isTesting = testingTasks.includes(taskType);
    isPac = pacTasks.includes(taskType);
  }

  return { isSamplePickUp, isInspection, isTesting, isPac };
}
