import React from "react";
import { makeStyles } from "@material-ui/styles";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

export default function DeleteReportModal({ report, open, onClose, onSubmit }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <MuiDialogTitle>
        <Typography className={classes.dialogTitle}>Delete Report</Typography>

        <Divider />
      </MuiDialogTitle>

      <MuiDialogContent>
        <Typography>Confirm to delete this report?</Typography>

        <Box className={classes.reportNameContainer}>{report?.fileName}</Box>
      </MuiDialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>

        <Button onClick={onSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  actionBtn: {
    cursor: "pointer",
    color: "#999999",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      maxHeight: "calc(100% - 64px - 40px) !important",
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: "2px",
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
  reportNameContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  errorSpacing: {
    marginTop: "4px",
  },
  datePickerMaxWidth: {
    maxWidth: "300px",
  },
}));
