import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import _isEqual from "lodash/isEqual";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Table from "components/table/Table";
import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";
import { replaceItemInArrayByIndex } from "utils/array";
import auth from "auth/authenticator";
import FileUpload from "components/input/FileUpload";

import EditReportModal from "./EditReportModal";
import EditInspectionReportModal from "./EditInspectionReportModal";
import DeleteReportModal from "./DeleteReportModal";

import { atnDeleteReport, atnGetTestItems, atnUpdateReport } from "actions/submissionActions";

import { LAB, PB_STATUS, SUPPLIER, TASK_TYPE_ENUM } from "shared/constants";

import { getPbLabel, isPbNotExisted, isShowProgram777 } from "services/pbService";

import useLoading from "hooks/useLoading";
import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  actionBtn: {
    cursor: "pointer",
    color: "#999999",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      maxHeight: "calc(100% - 64px - 40px) !important",
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: "2px",
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
  reportNameContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  errorSpacing: {
    marginTop: "4px",
  },
  datePickerMaxWidth: {
    maxWidth: "300px",
  },
}));

const ReportDetails = ({
  history,
  data: { reports: reportData = [], certifications: certificationData = [] },
  status,
  completedStatus,
  submissionId,
  taskType,
  isResumeReport = false,
  triggeredBy = "",
  pb = null,
  assignTo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { NOT_EXISTED } = PB_STATUS;

  const initialInspectionErrors = {
    issueDate: false,
    fileId: false,
    result: false,
    pb: false,
  };

  const initialReportErrors = {
    issueDate: false,
    testItemCount: false,
    testItemResult: false,
  };

  const {
    isLoading: isTestItemLoading,
    loading: testItemLoading,
    done: testItemDone,
  } = useLoading();

  const [reports, setReports] = useState([]);

  const [certifications, setCertifications] = useState([]);

  const [editReportItem, setEditReportItem] = useState({});
  const [editInspectionReportItem, setEditInspectionReportItem] = useState({});

  const [deleteReportItem, setDeleteReportItem] = useState({});

  const [openDeleteReportDialog, setOpenDeleteReportDialog] = useState(false);
  const [openEditReportDialog, setOpenEditReportDialog] = useState(false);
  const [openEditInspectionReportDialog, setOpenEditInspectionReportDialog] = useState(false);

  const [pbOptions, setPbOptions] = useState([]);
  const [selectedPb, setSelectedPb] = useState([]);

  const [reportEditErrors, setReportEditErrors] = useState(initialReportErrors);
  const [inspectionEditErrors, setInspectionEditErrors] = useState(initialInspectionErrors);

  const showProgram777 = isShowProgram777(triggeredBy);

  const { isLab, isSupplier } = useUserType(auth.userType());

  const isSelectedTaskType = [
    TASK_TYPE_ENUM.QT,
    TASK_TYPE_ENUM.PST,
    TASK_TYPE_ENUM.ST,
    TASK_TYPE_ENUM.STD,
    TASK_TYPE_ENUM.PAC,
  ].includes(taskType);

  const isLabFlow = assignTo === LAB;
  const isSupplierFlow = assignTo === SUPPLIER;

  useEffect(() => {
    let tempPb = [];

    if (pb && pb.length) {
      for (let i = 0; i < pb.length; i++) {
        tempPb.push({
          ...pb[i],
          value: pb[i].id,
          label: getPbLabel(pb[i]),
          disabled: isPbNotExisted(pb[i]),
        });
      }
    }

    setPbOptions(tempPb);
  }, [pb, selectedPb]);

  useEffect(() => {
    let selected = [];

    if (editInspectionReportItem?.pb?.length > 0) {
      for (let i = 0; i < editInspectionReportItem.pb.length; i++) {
        selected.push({
          ...editInspectionReportItem.pb[i],
          value: editInspectionReportItem.pb[i].id,
          label: getPbLabel(editInspectionReportItem.pb[i]),
        });
      }
    }

    setSelectedPb(selected);
  }, [editInspectionReportItem.pb]);

  useEffect(() => {
    if (_isEqual(reports, reportData)) return;
    setReports(reportData);

    fetchGetItems();
  }, [reportData]);

  useEffect(() => {
    if (_isEqual(certifications, certificationData)) return;
    setCertifications(certificationData);
  }, [certificationData]);

  const fetchGetItems = () => {
    testItemLoading();

    atnGetTestItems({
      submissionId: submissionId,
    })
      .then(({ data }) => {
        testItemDone();

        if (data) {
          let tempReportData = JSON.parse(JSON.stringify(reportData));

          if (isSelectedTaskType) {
            let tempTestItems = [];
            let isSelected = false;
            let newTestItems = [];

            for (let i = 0; i < tempReportData.length; i++) {
              tempTestItems = JSON.parse(JSON.stringify(data));
              newTestItems = [];

              for (let j = 0; j < tempTestItems.length; j++) {
                isSelected = false;

                if (tempReportData[i].reportTestItem) {
                  for (let k = 0; k < tempReportData[i].reportTestItem.length; k++) {
                    if (
                      tempReportData[i].reportTestItem[k].itemName === tempTestItems[j].itemName
                    ) {
                      newTestItems.push({
                        id: tempReportData[i].reportTestItem[k].id,
                        invoiceTestItemId: tempTestItems[j].testItemId,
                        itemName: tempReportData[i].reportTestItem[k].itemName,
                        result: tempReportData[i].reportTestItem[k].result,
                        isSelected: true,
                      });

                      isSelected = true;

                      break;
                    }
                  }
                }

                if (!isSelected) {
                  newTestItems.push({
                    invoiceTestItemId: tempTestItems[j].testItemId,
                    itemName: tempTestItems[j].itemName,
                    result: "",
                    isSelected: false,
                  });
                }
              }

              tempReportData[i].reportTestItem = newTestItems;
            }
          }

          setReports(tempReportData);
        }
      })
      .catch((err) => {
        testItemDone();
      });
  };

  const handleEditReportClick = (report) => {
    if (isSelectedTaskType) {
      setEditReportItem(report);
      setOpenEditReportDialog(true);
    } else {
      if (showProgram777) {
        setEditInspectionReportItem({
          ...report,
          pb: report.pb,
        });
      } else {
        setEditInspectionReportItem(report);
      }
      setOpenEditInspectionReportDialog(true);
    }
  };

  const handleDeleteReportClick = (report) => {
    setDeleteReportItem(report);
    setOpenDeleteReportDialog(true);
  };

  const handleEditReportClose = () => {
    setOpenEditReportDialog(false);
    setEditReportItem({});
  };

  const handleEditInspectionReportClose = () => {
    setOpenEditInspectionReportDialog(false);
    setEditInspectionReportItem({});
    resetErrors();
  };

  const handleDeleteReportClose = () => {
    setOpenDeleteReportDialog(false);
    setDeleteReportItem({});
  };

  const handleUploadReport = (newFile) => {
    setEditReportItem((prevState) => ({
      ...prevState,
      fileId: newFile.id,
      fileName: newFile.name,
    }));
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;

    setEditReportItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUploadInspectionReport = (newFile) => {
    setEditInspectionReportItem((prevState) => ({
      ...prevState,
      fileId: newFile.id,
      fileName: newFile.name,
    }));
  };

  const handleInspectionDataChange = (e) => {
    const { name, value } = e.target;

    setEditInspectionReportItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTestItemSelect = (index) => {
    setEditReportItem((prevState) => ({
      ...prevState,
      reportTestItem: prevState.reportTestItem.map((item, i) => {
        if (i === index) {
          item.isSelected = !item.isSelected;
        }

        return item;
      }),
    }));
  };

  const handleTestItemResultChange = (e, index) => {
    const { name, value } = e.target;

    setEditReportItem((prevState) => ({
      ...prevState,
      reportTestItem: prevState.reportTestItem.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleEditReport = () => {
    let submitData = [];
    let testItems = [];
    let result = "";

    for (let i = 0; i < editReportItem.reportTestItem.length; i++) {
      if (editReportItem.reportTestItem[i].isSelected) {
        if (!editReportItem.reportTestItem[i].result) {
          return setReportEditErrors((s) => ({ ...s, testItemResult: true }));

          break;
        }

        if (editReportItem.reportTestItem[i].result === "fail") {
          result = "fail";
        } else if (editReportItem.reportTestItem[i].result === "reportAsIs") {
          if (result !== "fail") {
            result = "reportAsIs";
          }
        } else if (editReportItem.reportTestItem[i].result === "pass") {
          if (result !== "reportAsIs" && result !== "fail") {
            result = "pass";
          }
        }

        if (editReportItem.reportTestItem[i].id) {
          testItems.push({
            id: editReportItem.reportTestItem[i].id,
            result: editReportItem.reportTestItem[i].result,
            invoiceTestItemId: editReportItem.reportTestItem[i].invoiceTestItemId,
          });
        } else {
          testItems.push({
            result: editReportItem.reportTestItem[i].result,
            invoiceTestItemId: editReportItem.reportTestItem[i].invoiceTestItemId,
          });
        }
      }
    }

    if (testItems.length === 0) return setReportEditErrors((s) => ({ ...s, testItemCount: true }));
    if (!editReportItem.issueDate) return setReportEditErrors((s) => ({ ...s, issueDate: true }));

    submitData.push({
      id: editReportItem.id,
      fileId: editReportItem.fileId,
      result: result,
      remark: editReportItem.remark,
      issueDate: editReportItem.issueDate,
      testItems: testItems,
    });

    atnUpdateReport({
      submissionId: submissionId,
      data: submitData,
    }).then(({ data }) => {
      if (!data) return;

      setOpenEditReportDialog(false);
      setEditReportItem({});
      window.location.reload();
    });
  };

  const handleEditInspectionReport = async () => {
    if (
      !editInspectionReportItem?.fileId ||
      !editInspectionReportItem?.issueDate ||
      !editInspectionReportItem?.result ||
      (showProgram777 && !editInspectionReportItem?.pb?.length > 0)
    ) {
      if (!editInspectionReportItem?.fileId)
        setInspectionEditErrors((s) => ({ ...s, fileId: true }));
      if (!editInspectionReportItem?.issueDate)
        setInspectionEditErrors((s) => ({ ...s, issueDate: true }));
      if (!editInspectionReportItem?.result)
        setInspectionEditErrors((s) => ({ ...s, result: true }));
      if (showProgram777 && !editInspectionReportItem?.pb?.length > 0)
        setInspectionEditErrors((s) => ({ ...s, pb: true }));

      return;
    }

    await atnUpdateReport({
      submissionId: submissionId,
      data: [editInspectionReportItem],
    }).then(({ data }) => {
      if (!data) return;

      setOpenEditInspectionReportDialog(false);
      setEditInspectionReportItem({});
      resetErrors();

      window.location.reload();
    });
  };

  const resetErrors = () => {
    setInspectionEditErrors(initialInspectionErrors);
    setReportEditErrors(initialReportErrors);
  };

  const handleDeleteReport = () => {
    let submitData = [
      {
        id: deleteReportItem.id,
      },
    ];

    atnDeleteReport({
      submissionId: submissionId,
      data: submitData,
    }).then(({ data, message }) => {
      if (!data) return;

      setOpenDeleteReportDialog(false);
      setDeleteReportItem({});
      window.location.reload();
    });
  };

  const ReportDescriptionBlock = ({ data: descriptionData }) => {
    const { reportTestItem = [], remark } = descriptionData;
    const Text = ({ value }) => (
      <Typography className={classnames(classes.text, classes.blackText)}>{value}</Typography>
    );

    const Item = ({ label, value, content }) => (
      <Box className={classes.item} xs={3}>
        <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>
        {value && <Text value={value} />}
        {content}
      </Box>
    );

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item
            label="Test Results"
            content={
              reportTestItem.length ? (
                reportTestItem.map(({ itemName, result }, index) => (
                  <Grid key={index} container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Text value={textOrUnknown(itemName)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Text value={result ? textOrUnknown(t(`reportResult.${result}`)) : ""} />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Text value={textOrUnknown()} />
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Remarks" value={textOrUnknown(remark)} />
        </Grid>
      </Grid>
    );
  };

  const reportsTableData = (reports || []).map((item) => {
    if (item.reportTestItem) {
      return {
        ...item,
        additionalRow: {
          content: <ReportDescriptionBlock data={item} />,
        },
      };
    } else {
      return item;
    }
  });

  let reportsTableColumn = [];

  if (showProgram777) {
    reportsTableColumn = [
      {
        columnId: "report",
        label: "Report",
        width: "30%",
        render: ({ id, fileName, location }) => {
          return location ? (
            <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
          ) : (
            textOrUnknown(fileName)
          );
        },
      },
      {
        columnId: "pb",
        label: "PB Number [Production batch line ID]",
        width: "30%",
        render: (data) => {
          return data.pb && data.pb.length > 0 ? (
            <div>
              {data.pb.map((item) => {
                const pbIdentity = `${item.pbNumber} [ ${item.pbLineId || "N/A"} ]`;

                const isNotExisted = item.pbStatus === NOT_EXISTED;

                return (
                  <div key={item.id}>
                    <div>
                      {pbIdentity}{" "}
                      {isNotExisted && <span className={classes.error}>{`(Cancelled)`}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            "N/A"
          );
        },
      },
      {
        columnId: "result",
        label: "Result",
        width: "20%",
        render: (data) => {
          return data.result ? textOrUnknown(t(`reportResult.${data.result}`)) : "";
        },
      },
      {
        columnId: "issueDate",
        label: "Issue Date",
        width: "10%",
        render: (data) => {
          return textOrUnknown(getDateFormat(data.issueDate));
        },
      },
    ];
  } else {
    reportsTableColumn = [
      {
        columnId: "report",
        label: "Report",
        width: "40%",
        render: ({ id, fileName, location }) => {
          return location ? (
            <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
          ) : (
            textOrUnknown(fileName)
          );
        },
      },
      {
        columnId: "result",
        label: isSelectedTaskType ? "Overall Result" : "Result",
        width: "20%",
        render: (data) => {
          return data.result ? textOrUnknown(t(`reportResult.${data.result}`)) : "";
        },
      },
      {
        columnId: "issueDate",
        label: "Issue Date",
        width: "10%",
        render: (data) => {
          return textOrUnknown(getDateFormat(data.issueDate));
        },
      },
      // {
      //   columnId: 'remark',
      //   label: 'Remark',
      //   width: '40%',
      //   render: (data) => {
      //     return (
      //       <Box display='flex' justifyContent='space-between' alignItems='center'>
      //         {textOrUnknown(data.remark)}
      //       </Box>
      //     );
      //   },
      // },
    ];
  }

  const remarkColumn = {
    columnId: "remark",
    label: "Remark",
    width: "30%",
    render: (data) => {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {textOrUnknown(data.remark)}
        </Box>
      );
    },
  };

  const actionColumn = {
    columnId: "action",
    label: "Action",
    width: "10%",
    render: (data) => {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <EditIcon className={classes.actionBtn} onClick={() => handleEditReportClick(data)} />
          </Box>
          <Box>
            <DeleteIcon
              className={classes.actionBtn}
              onClick={() => handleDeleteReportClick(data)}
            />
          </Box>
        </Box>
      );
    },
  };

  if (isLab && !["closed", "cancelled", "fail", "completed"].includes(status)) {
    if (isSelectedTaskType) {
      reportsTableColumn.push(remarkColumn);
    }

    reportsTableColumn.push(actionColumn);
  } else {
    reportsTableColumn.push({
      ...remarkColumn,
      width: "40%",
    });
  }

  //for show edit button if resume report = TRUE & supplier or lab user
  if (
    ((isLab && isLabFlow) || (isSupplier && isSupplierFlow)) &&
    status === "completed" &&
    isResumeReport
  ) {
    reportsTableColumn.push(actionColumn);
  }

  const revisedReportsTableData = reportsTableData.filter((r) => r.isReviseReport);
  const revisedReportsTableColumn = replaceItemInArrayByIndex(
    reportsTableColumn,
    {
      columnId: "report",
      label: "Revised Report",
      width: "40%",
      render: ({ id, fileName, location }) => {
        return location ? (
          <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
        ) : (
          textOrUnknown(fileName)
        );
      },
    },
    "columnId",
  );

  const certificationsTableData = certifications || [];

  const certificationsTableColumn = [
    {
      columnId: "certification",
      label: "Certification",
      render: ({ id, pacCertId, fileName, location }) => {
        let tempId = id || pacCertId;

        return location ? (
          <FileUpload data={[{ id: tempId, fileName, fileUrl: location }]} editable={false} />
        ) : (
          textOrUnknown(fileName)
        );
      },
    },
    {
      columnId: "pacValidityDate",
      label: "PAC Validity Date",
      width: "20%",
      render: (data) => {
        return textOrUnknown(getDateFormat(data.pacValidityDate));
      },
    },
    {
      columnId: "remark",
      label: "Remark",
      width: "40%",
      render: (data) => {
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {textOrUnknown(data.remark)}
          </Box>
        );
      },
    },
  ];

  const handlePACCertUpload = () => {
    // history.push(`/lab/submissions/${submissionId}/update/status?type=pac_cert`);
    history.push({
      pathname: `/lab/submissions/${submissionId}/update/status`,
      search: "",
      state: { type: "pac_cert" },
    });
  };

  const handlePbsChange = (e, index) => {
    const { value } = e.target;

    const pbData = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < pb.length; j++) {
        if (value[i] === pb[j].id) {
          const isSelectedNotExistedPb =
            value[i] === pb[j].id &&
            isPbNotExisted(pb[j]) &&
            selectedPb.findIndex((item) => item.id === value[i]) === -1;

          if (isSelectedNotExistedPb) return;

          pbData.push({
            ...pb[j],
            value: pb[j].id,
            label: pb[j].pbNumber,
            disabled: isPbNotExisted(pb[j]),
          });
        }
      }
    }

    handleInspectionDataChange({
      target: {
        name: "pb",
        value: pbData,
      },
    });
  };

  const hasSelectedNotExistedPb = () => {
    if (!selectedPb.length) return false;

    const hasNotExistedPb = selectedPb.findIndex((item) => isPbNotExisted(item)) !== -1;

    const notExistedPb = selectedPb.reduce((acc, cur) => {
      if (isPbNotExisted(cur)) {
        const pbIdentity = `${cur.pbNumber} [ ${cur.pbLineId || "N/A"} ]`;
        acc.push(pbIdentity);
      }

      return acc;
    }, []);

    return { hasNotExistedPb, notExistedPb };
  };

  return (
    <div>
      <Box>
        <Table
          data={reportsTableData.filter((r) => !r.isReviseReport)}
          columnData={reportsTableColumn}
          noPagination
        />
      </Box>

      {taskType === "PAC" && (
        <Box pt={2}>
          {isLab &&
          completedStatus === "completed_concession" &&
          certificationsTableData.length === 0 ? (
            <Button onClick={handlePACCertUpload} color="primary" variant="outlined">
              Upload PAC Cert
            </Button>
          ) : (
            <Table
              data={certificationsTableData}
              columnData={certificationsTableColumn}
              noPagination
            />
          )}
        </Box>
      )}

      <Box pt={2}>
        <Table data={revisedReportsTableData} columnData={revisedReportsTableColumn} noPagination />
      </Box>

      <EditInspectionReportModal
        showProgram777={showProgram777}
        loading={isTestItemLoading}
        report={editInspectionReportItem}
        open={openEditInspectionReportDialog}
        errors={inspectionEditErrors}
        hasSelectedNotExistedPb={hasSelectedNotExistedPb}
        pbOptions={pbOptions}
        selectedPb={selectedPb}
        onChangePb={handlePbsChange}
        onUploadReport={handleUploadInspectionReport}
        onChange={handleInspectionDataChange}
        onSubmit={handleEditInspectionReport}
        onClose={handleEditInspectionReportClose}
      />

      <EditReportModal
        report={editReportItem}
        loading={isTestItemLoading}
        open={openEditReportDialog}
        errors={reportEditErrors}
        onUploadReport={handleUploadReport}
        onSelectTestItem={handleTestItemSelect}
        onChangeTestItem={handleTestItemResultChange}
        onChange={handleDataChange}
        onSubmit={handleEditReport}
        onClose={handleEditReportClose}
      />

      <DeleteReportModal
        report={deleteReportItem}
        open={openDeleteReportDialog}
        onClose={handleDeleteReportClose}
        onSubmit={handleDeleteReport}
      />
    </div>
  );
};

export default withRouter(ReportDetails);
