import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import Button from "components/input/Button";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 12,
  },
  grayText: {
    color: "#232B3A",
  },
  blackText: {
    color: "#000000",
  },
}));

const InvoiceUpdateConfirmation = ({ data }) => {
  const classes = useStyles();

  const { nextAction } = data;

  const handleNext = () => {
    if (nextAction) nextAction();
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box pt={4} pb={16}>
          <Typography className={classnames(classes.text, classes.grayText)}>
            Before you confirm, please ensure the invoice amount on the system is correct.
            <br />
            After this task is marked as completed,
            <br />
            - You will not be able to change or update this task.
            <br />- The applicant will receive an invoice via email / ICW.
          </Typography>
        </Box>
      </Paper>
      <Button className={classes.bottomButton} onClick={handleNext}>
        By clicking, I confirm this task is completed
      </Button>
    </>
  );
};

export default InvoiceUpdateConfirmation;
