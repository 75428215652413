import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import TextInput from "components/input/TextInput";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 25,
  },
  table: {
    marginTop: 15,
  },
  tableHeaderCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  closeIcon: {
    cursor: "pointer",
  },
  wrapperInput: {
    marginTop: 10,
    marginBottom: 10,
  },
  smallInputField: {
    width: 162,
  },
}));

const SelectedPoSpuTable = ({ selectedPOs = [], handleRemovePO, handleConfirmEditPO }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>PO Number</TableCell>
            <TableCell className={classes.tableHeaderCell}>PO Quantity</TableCell>
            <TableCell className={classes.tableHeaderCell}>Qty Ready for Inspection</TableCell>
            <TableCell className={classes.tableCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPOs.map((task, index) => (
            <TableRow key={task.id}>
              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{task.poNumber}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{task.poQuantity}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.wrapperInput}>
                  <TextInput
                    type="number"
                    placeholder="No. of Quantity"
                    name="quantityReadyForInspection"
                    value={task.quantityReadyForInspection}
                    additionalClass={classes.smallInputField}
                    onChange={(e) => handleConfirmEditPO(e, index)}
                  />
                </Box>
              </TableCell>

              <TableCell className={classes.tableCell}>
                {/* {index !== 0 && */}
                <CloseIcon className={classes.closeIcon} onClick={() => handleRemovePO(task.id)} />
                {/* } */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SelectedPoSpuTable;
