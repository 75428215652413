/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import "@toast-ui/chart/dist/toastui-chart.min.css";
import Chart from "@toast-ui/chart";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
    padding: "20px",
    backgroundColor: "white",
    position: "relative",

    "& .toastui-chart-tooltip-container": {
      zIndex: 99999,
    },
    "& .wrapper-tooltip": {
      width: "max-content",
      background: "white",
      padding: "10px 10px 15px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
    },

    "& .tui-chart-tooltip": {
      border: "1px solid #C4C8D0",
      color: "black",
      fontFamily: "Roboto",
    },

    "& .tui-chart-default-tooltip": {
      backgroundColor: "white",
    },

    "& .tui-chart-tooltip-head": {
      display: "none",
    },

    "& .tui-chart-tooltip-body": {},

    "& .tui-chart-legend-rect": {
      borderRadius: "50%",
      width: "15px",
      height: "15px",
      marginTop: "0",
      display: "inline-block",
      marginRight: 15,
    },

    "& .tooltip-title": {
      fontSize: "14px",
      fontWeight: "500",
    },

    "& .tooltip-title-sub": {
      fontSize: "14px",
      fontWeight: "500",
      marginTop: "10px",
      marginBottom: "10px",
      color: "#777777",

      "& .sub-item": {
        display: "inline-block",
        marginLeft: 10,
      },
    },

    "& .tooltip-title-percent": {
      display: "flex",
      alignItems: "center",

      "& .percent": {
        fontSize: 24,
        fontWeight: 500,
        color: "#000000",
      },

      "& .sub-item": {
        display: "inline-block",
        marginLeft: 10,
        fontSize: 14,
        color: "#777777",
      },
    },

    "& .tooltip-item-wrapper": {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
    },

    "& .tui-chart-tooltip-value": {
      fontWeight: "500",
      fontSize: "12px",
      textAlign: "left",
      color: "#777777",
      marginRight: 10,
    },

    "& .tui-chart-name": {
      fontWeight: "500",
      fontSize: "12px",
      marginRight: 10,
    },
  },
  mainTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 50,
  },
  backTitle: {
    fontWeight: 500,
    marginLeft: 50,

    "& span": {
      color: "#000000",
    },
  },

  toSpending: {
    cursor: "pointer",
    display: "inline-block",
    color: "#003DA5 !important",
  },

  arrow: {
    marginLeft: 15,
    marginRight: 15,
  },
  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },
}));

const TotalSpendingLab = ({
  overAllTotalData,
  totalSpendingPerLab,
  totalSpendingPerLabOrigin,
  handleTabChange,
}) => {
  const classes = useStyles();

  const [totalMainCategories, setTotalMainCategories] = useState([]);
  const [mainTotalCategories, setMainTotalCategories] = useState(null);
  const [mainTotalSeries, setMainTotalSeries] = useState(null);
  const [mainTotalMaxNumber, setMainTotalMaxNumber] = useState(null);

  useEffect(() => {
    let groupMainCategories = [];
    let { categories } = totalSpendingPerLab;

    // ============ HANDLE GROUP DATA ============
    let totalCategories = [...new Set(categories.map((item, index) => item.split(" ")[0]))];

    let seriesOrigin = ["DPI", "FRI", "PST", "QT", "SPU", "SS", "ST"];
    let totalSpendingPerLabGroupName = totalSpendingPerLabOrigin.map((item, index) => {
      return { ...item, groupName: item.name.split(" ")[0] };
    });

    // Handle step 1 :

    groupMainCategories = totalCategories.map((name, index) => {
      let totalSpendingPerName = totalSpendingPerLabGroupName.filter((item, index) => {
        return item.groupName === name;
      });

      let seriesPerName = seriesOrigin.map((itemSeries, index) => {
        let data = [];
        let dataIncludeWIC = [];
        let dataSupplier = [];
        let dataFactories = [];

        for (let i = 0; i < totalSpendingPerName.length; i++) {
          let temp = totalSpendingPerName[i].chartItems.filter((itemLab, index) => {
            return itemLab.taskType === itemSeries;
          });

          temp.length ? data.push(temp[0].total) : data.push(0);
          temp.length ? dataIncludeWIC.push(temp[0].includeWic) : dataIncludeWIC.push(0);
          temp.length ? dataSupplier.push(temp[0].suppliers) : dataSupplier.push(0);
          temp.length ? dataFactories.push(temp[0].factories) : dataFactories.push(0);
        }

        let total = data.reduce((item, current) => item + current, 0);
        let totalIncludeWIC = dataIncludeWIC.reduce((item, current) => item + current, 0);
        let totalSupplier = dataSupplier.reduce((item, current) => item + current, 0);
        let totalFactories = dataFactories.reduce((item, current) => item + current, 0);

        return {
          name: itemSeries,
          data: data,
          total: total,
          dataIncludeWIC: dataIncludeWIC,
          totalIncludeWIC: totalIncludeWIC,
          dataSupplier: dataSupplier,
          totalSupplier: totalSupplier,
          dataFactories: dataFactories,
          totalFactories: totalFactories,
        };
      });

      let listSumTotalItems = [];
      for (let i = 0; i < totalSpendingPerName.length; i++) {
        let tempTotal = totalSpendingPerName[i].chartItems.reduce((total, item, index) => {
          return (total += item.total);
        }, 0);
        listSumTotalItems.push(tempTotal);
      }

      let maxNumber = parseInt(Math.max(...listSumTotalItems) * 1.1);

      return {
        name: name,
        data: totalSpendingPerName,
        categories: totalSpendingPerName.map((item) => item.name),
        series: seriesPerName,
        maxNumber: maxNumber,
      };
    });

    setTotalMainCategories(groupMainCategories);
    // Handle step 2 :
    let totalSeries = seriesOrigin.map((itemSeries, index) => {
      let data = [],
        dataIncludeWIC = [],
        dataSupplier = [],
        dataFactories = [];

      for (let i = 0; i < groupMainCategories.length; i++) {
        data.push(groupMainCategories[i].series[index].total);
        dataIncludeWIC.push(groupMainCategories[i].series[index].totalIncludeWIC);
        dataSupplier.push(groupMainCategories[i].series[index].totalSupplier);
        dataFactories.push(groupMainCategories[i].series[index].totalFactories);
      }

      return {
        name: itemSeries,
        data: data,
        dataIncludeWIC: dataIncludeWIC,
        dataSupplier: dataSupplier,
        dataFactories: dataFactories,
      };
    });

    let listMaxNumber = [];
    for (let i = 0; i < groupMainCategories.length; i++) {
      let tempTotal = groupMainCategories[i].series.reduce((total, item, index) => {
        return (total += item.total);
      }, 0);
      listMaxNumber.push(tempTotal);
    }
    let totalMaxNumber = parseInt(Math.max(...listMaxNumber) * 1.1);

    // ============ END HANDLE GROUP DATA ============
    setMainTotalCategories(totalCategories);
    setMainTotalSeries(totalSeries);
    setMainTotalMaxNumber(totalMaxNumber);
    handleShowChart(totalCategories, totalSeries, totalMaxNumber, groupMainCategories);
  }, [totalSpendingPerLab]);

  const [titleChild, setTitleChild] = useState(null);

  const handleShowChart = (categories, series, maxNumber, groupMainCategories) => {
    let colors = ["#D32F2F", "#7B1FA2", "#196EFF", "#0097A7", "#FBC02D", "#B0D400", "#F57C00"];
    let fullName = {
      DPI: "During Production Inspection",
      FRI: "Final Random Inspection",
      PST: "Pre-Safety Test",
      QT: "Quality Test",
      SPU: "Sample Pick Up",
      SS: "Sample Sealing",
      ST: "Safety Test",
    };
    let objTotal = {};
    let totalAllChart = 0;

    for (let i = 0; i < categories.length; i++) {
      let total = 0,
        totalIncludeWIC = 0,
        totalSupplier = 0,
        totalFactories = 0;
      let items = [];

      for (let j = 0; j < series.length; j++) {
        let smallObj = {};

        total += series[j].data[i];
        totalIncludeWIC += series[j].dataIncludeWIC[i];
        totalSupplier += series[j].dataSupplier[i];
        totalFactories += series[j].dataFactories[i];

        smallObj["name"] = series[j].name;
        smallObj["fullName"] = fullName[series[j].name];
        smallObj["total"] = series[j].data[i];
        smallObj["totalIncludeWIC"] = series[j].dataIncludeWIC[i];
        smallObj["totalSupplier"] = series[j].dataSupplier[i];
        smallObj["totalFactories"] = series[j].dataFactories[i];
        smallObj["cssText"] = `background-color: ${colors[j]}`;
        items.push(smallObj);
      }

      objTotal[categories[i]] = {
        total: total,
        totalIncludeWIC: totalIncludeWIC,
        totalSupplier: totalSupplier,
        totalFactories: totalFactories,
        list: items,
      };
      totalAllChart += total;
    }

    const el = document.getElementById("main-bar-chart");
    el.innerHTML = "";

    var data = {
      categories: categories,
      series: series,
    };
    var options = {
      chart: {
        width: 1080,
        height: 90 + 60 * categories.length,
        title: "",
      },
      tooltip: {
        template: (model) => {
          const itemName = model.category;
          return `
              <div class="wrapper-tooltip">
                <div class="tui-chart-default-tooltip">
                  <div class="tui-chart-tooltip-body">
                    <div class="tooltip-title">${itemName}</div>
                    <div class="tooltip-title-sub">
                      <span>Total Spend: (USD$ ${(
                        Math.round((overAllTotalData[itemName].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="sub-item">｜Including WIC : ${(
                        Math.round((overAllTotalData[itemName].includeWic + Number.EPSILON) * 100) /
                        100
                      ).toLocaleString()}</span>
                      <span class="sub-item">｜Suppliers : ${(
                        Math.round((overAllTotalData[itemName].suppliers + Number.EPSILON) * 100) /
                        100
                      ).toLocaleString()}</span>
                      <span class="sub-item">｜Factories : ${(
                        Math.round((overAllTotalData[itemName].factories + Number.EPSILON) * 100) /
                        100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-title-percent">
                      <span class="percent">${
                        ((objTotal[itemName].total / totalAllChart) * 100).toFixed(2) + "%"
                      }</span>
                      <span class="sub-item">in Total Spending in All Lab</span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[0].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[0].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[0].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[0].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>
                    
                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[1].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[1].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[1].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[1].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[2].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[2].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[2].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[2].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[3].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[3].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[3].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[3].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[4].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[4].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[4].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[4].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[5].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[5].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[5].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[5].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[6].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[6].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[6].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[6].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>
                    
                  </div>
                </div>
              </div>
            `;
        },
      },
      yAxis: {
        title: "",
        height: 330,
      },
      xAxis: {
        title: "",
        scale: {
          max: maxNumber,
        },
      },
      series: {
        stack: "normal",
        selectable: true,
        eventDetectType: "grouped",
      },
      legend: {
        showCheckbox: false,
      },
      theme: {
        xAxis: {
          label: {
            fontSize: 13,
            color: "#000000",
            fontWeight: 500,
          },
        },
        yAxis: {
          label: {
            fontSize: 13,
            color: "#000000",
            fontWeight: 500,
          },
        },
        legend: {
          label: {
            fontSize: 12,
            fontWeight: 500,
            color: "#000000",
          },
        },
        series: {
          colors: ["#D32F2F", "#7B1FA2", "#196EFF", "#0097A7", "#FBC02D", "#B0D400", "#F57C00"],
        },
      },
    };

    const chart = Chart.barChart({ el, data, options });

    chart.on("selectSeries", (ev) => {
      const index = ev.bar[0].index || 0;
      document.getElementById("main-bar-chart").style.display = "none";
      setTitleChild(groupMainCategories[index].name);

      handleShowChildChart(
        groupMainCategories[index].categories,
        groupMainCategories[index].series,
        groupMainCategories[index].maxNumber,
        groupMainCategories[index].data,
      );
    });
  };

  const handleShowChildChart = (categories, series, maxNumber, dataOrigin) => {
    let colors = ["#D32F2F", "#7B1FA2", "#196EFF", "#0097A7", "#FBC02D", "#B0D400", "#F57C00"];
    let fullName = {
      DPI: "During Production Inspection",
      FRI: "Final Random Inspection",
      PST: "Pre-Safety Test",
      QT: "Quality Test",
      SPU: "Sample Pick Up",
      SS: "Sample Sealing",
      ST: "Safety Test",
    };
    let objTotal = {};
    let totalAllChart = 0;

    for (let i = 0; i < categories.length; i++) {
      let total = 0,
        totalIncludeWIC = 0,
        totalSupplier = 0,
        totalFactories = 0;
      let items = [];

      total = dataOrigin[i].subTotalData.total;
      totalIncludeWIC = dataOrigin[i].subTotalData.includeWic;
      totalSupplier = dataOrigin[i].subTotalData.suppliers;
      totalFactories = dataOrigin[i].subTotalData.factories;

      for (let j = 0; j < series.length; j++) {
        let smallObj = {};
        smallObj["name"] = series[j].name;
        smallObj["fullName"] = fullName[series[j].name];
        smallObj["total"] = series[j].data[i];
        smallObj["totalIncludeWIC"] = series[j].dataIncludeWIC[i];
        smallObj["totalSupplier"] = series[j].dataSupplier[i];
        smallObj["totalFactories"] = series[j].dataFactories[i];
        smallObj["cssText"] = `background-color: ${colors[j]}`;
        items.push(smallObj);
      }

      objTotal[categories[i]] = {
        total: total,
        totalIncludeWIC: totalIncludeWIC,
        totalSupplier: totalSupplier,
        totalFactories: totalFactories,
        list: items,
      };
      totalAllChart += total;
    }

    const el = document.getElementById("child-bar-chart");
    el.innerHTML = "";

    const data = {
      categories: categories,
      series: series,
    };
    const options = {
      chart: {
        width: 1080,
        height: 80 + 60 * categories.length,
      },
      tooltip: {
        template: (model) => {
          const itemName = model.category;
          return `
              <div class="wrapper-tooltip">
                <div class="tui-chart-default-tooltip">
                  <div class="tui-chart-tooltip-body">
                    <div class="tooltip-title">${itemName}</div>
                    <div class="tooltip-title-sub">
                      <span>Total Spend : (USD$ ${(
                        Math.round((objTotal[itemName].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="sub-item">｜Including WIC : ${(
                        Math.round((objTotal[itemName].totalIncludeWIC + Number.EPSILON) * 100) /
                        100
                      ).toLocaleString()}</span>
                      <span class="sub-item">｜Suppliers : ${(
                        Math.round((objTotal[itemName].totalSupplier + Number.EPSILON) * 100) / 100
                      ).toLocaleString()}</span>
                      <span class="sub-item">｜Factories : ${(
                        Math.round((objTotal[itemName].totalFactories + Number.EPSILON) * 100) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-title-percent">
                      <span class="percent">${
                        ((objTotal[itemName].total / totalAllChart) * 100).toFixed(2) + "%"
                      }</span>
                      <span class="sub-item">in Total Spending in All Lab</span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[0].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[0].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[0].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[0].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[0].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>
                    
                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[1].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[1].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[1].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[1].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[1].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[2].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[2].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[2].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[2].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[2].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[3].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[3].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[3].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[3].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[3].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[4].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[4].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[4].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[4].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[4].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[5].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[5].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[5].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[5].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[5].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>

                    <div class="tooltip-item-wrapper">
                      <span class="tui-chart-legend-rect pie" style="${
                        objTotal[itemName].list[6].cssText
                      }"></span>
                      <span class="tui-chart-name">${objTotal[itemName].list[6].fullName}</span>
                      <span class="tui-chart-name">${
                        (
                          (objTotal[itemName].list[6].total / objTotal[itemName].total) *
                          100
                        ).toFixed(2) + "%"
                      }</span>
                      <span class="tui-chart-tooltip-value">(USD$ ${(
                        Math.round((objTotal[itemName].list[6].total + Number.EPSILON) * 100) / 100
                      ).toLocaleString()})</span>
                      <span class="tui-chart-tooltip-value">｜Including WIC : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalIncludeWIC + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()}</span>
                      <span class="tui-chart-tooltip-value">｜Suppliers : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalSupplier + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                      <span class="tui-chart-tooltip-value">｜Factories : ${(
                        Math.round(
                          (objTotal[itemName].list[6].totalFactories + Number.EPSILON) * 100,
                        ) / 100
                      ).toLocaleString()} </span>
                    </div>
                    
                  </div>
                </div>
              </div>
            `;
        },
      },
      yAxis: {
        title: "",
      },
      xAxis: {
        title: "",
        scale: {
          max: maxNumber,
        },
      },
      legend: {
        showCheckbox: false,
      },
      series: {
        stack: {
          type: "normal",
        },
        eventDetectType: "grouped",
      },
      theme: {
        xAxis: {
          label: {
            fontSize: 13,
            color: "#000000",
            fontWeight: 500,
          },
        },
        yAxis: {
          label: {
            fontSize: 13,
            color: "#000000",
            fontWeight: 500,
          },
        },
        legend: {
          label: {
            fontSize: 12,
            fontWeight: 500,
            color: "#000000",
          },
        },
        series: {
          colors: ["#D32F2F", "#7B1FA2", "#196EFF", "#0097A7", "#FBC02D", "#B0D400", "#F57C00"],
        },
      },
    };

    // new BarChart({el, data, options});
    Chart.barChart({ el, data, options });
  };

  const handleBackChart = () => {
    document.getElementById("main-bar-chart").style.display = "block";
    document.getElementById("child-bar-chart").innerHTML = "";
    handleShowChart(mainTotalCategories, mainTotalSeries, mainTotalMaxNumber, totalMainCategories);
    setTitleChild(null);
  };

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      {!titleChild && <Box className={classes.mainTitle}>Total Spending per Lab</Box>}
      {titleChild && (
        <Box className={classes.backTitle}>
          <span className={classes.toSpending} onClick={handleBackChart}>
            Total Spending per Lab
          </span>
          <span className={classes.arrow}>{">"}</span>
          <span>{titleChild}</span>
        </Box>
      )}
      <Box className={show ? classes.show : classes.hide}>
        <Box id="main-bar-chart"></Box>
        <Box id="child-bar-chart"></Box>

        <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 2)}>
          VIEW ON LIST
        </Box>
      </Box>
    </Box>
  );
};

export default TotalSpendingLab;
