import axios from "axios";

const API_URL = process.env.REACT_APP_API_DOMAIN;
// const API_URL = "http://54.179.30.61:8080/wba-server";

class api {
  static tempGet(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };

    return axios
      .get(apiPath, config)
      .then((result) => {
        if (result && result.data && result.status === 200) {
          return {
            code: result.data.code || result.status,
            data: result.data.data,
            message: result?.data?.message || result?.message,
          };
        }

        return {
          code: result.status,
          data: null,
          message: result?.data?.message || result?.message,
        };
      })
      .catch((error) => {
        // handle network error
        return {
          code: "",
          data: null,
          message: error?.response?.message,
        };
      });
  }

  static get(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };

    return axios
      .get(apiPath, config)
      .then((result) => {
        if (result && result.data && result.status === 200) {
          if (result.data.code === 200) {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          } else {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          }
        } else {
          // server return status != 200
          return {
            code: result.data.code || result.status,
            data: null,
            message: "",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
        return {
          code: null,
          data: null,
          message: "",
        };
      });
  }

  static post(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
    };

    return axios
      .post(apiPath, data, config)
      .then((result) => {
        if (result && result.data && result.status === 200) {
          if (result.data.code === 200) {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          } else {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          }
        } else {
          // server return status != 200
          return {
            code: null,
            data: null,
            message: "",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
        return {
          code: null,
          data: null,
          message: "",
        };
      });
  }

  static put(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
    };

    return axios
      .put(apiPath, data, config)
      .then((result) => {
        if (result && result.data && result.status === 200) {
          if (result.data.code === 200) {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          } else {
            return {
              code: result.data.code || result.status,
              data: result.data.data,
              message: result.data.message,
            };
          }
        } else {
          // server return status != 200
          return {
            data: null,
            message: "",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
        return {
          code: null,
          data: null,
          message: "",
        };
      });
  }

  static delete(path, data = {}) {
    const apiPath = API_URL + path;

    return (
      axios
        // .delete(apiPath, data, config)
        .delete(apiPath, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: data,
        })
        .then((result) => {
          if (result && result.data && result.status === 200) {
            if (result.data.code === 200) {
              return {
                code: result.data.code || result.status,
                data: result.data.data,
                message: result.data.message,
              };
            } else {
              return {
                code: result.data.code || result.status,
                data: result.data.data,
                message: result.data.message,
              };
            }
          } else {
            // server return status != 200
            return {
              code: result.data.code || result.status,
              data: null,
              message: "",
            };
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("useremail");
            localStorage.removeItem("companyName");
            window.location.href = "/login";
            window.location.reload();
          }

          // handle network error
          return {
            code: null,
            data: null,
            message: "",
          };
        })
    );
  }

  static login(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          "Content-Type": "application/json",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
    };

    return axios
      .post(apiPath, data, config)
      .then((result) => {
        if (result && result.headers && result.status === 200) {
          return result.headers;
        }
      })
      .catch((error) => {
        // handle network error
      });
  }

  static download(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "blob",
    };

    return axios
      .get(apiPath, config)
      .then((result) => {
        // console.log(result)
        return result.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
      });
  }

  static downloadWithData(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/octet-stream",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
      // headers: {
      //   'Content-Type': 'application/octet-stream',
      //   Authorization: localStorage.getItem('token'),
      // },
      responseType: "blob",
    };

    return axios
      .post(apiPath, data, config)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
      });
  }

  static upload(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
    };

    return axios
      .post(apiPath, data, config)
      .then((result) => {
        if (result && result.data && result.status === 200) {
          if (result.data.code === 200) {
            return {
              data: result.data.data,
              message: result.data.message,
            };
          } else {
            return {
              data: result.data.data,
              message: result.data.message,
            };
          }
        } else {
          // server return status != 200
          return {
            data: null,
            message: "",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
        return {
          data: null,
          message: "",
        };
      });
  }

  static uploadAndDownload(path, data = {}) {
    const apiPath = API_URL + path;

    const config = {
      headers: {
        post: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
        },
        common: {
          Authorization: localStorage.getItem("token"),
        },
      },
      responseType: "blob",
    };

    return axios
      .post(apiPath, data, config)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("useremail");
          localStorage.removeItem("companyName");
          window.location.href = "/login";
          window.location.reload();
        }

        // handle network error
      });
  }
}

export default api;
