import React from "react";
import _find from "lodash/find";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";

import { VALIDITY_PERIODS, VALIDITY_TYPES } from "shared/constants";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  inputFieldLeft: {
    width: 384,
  },
  inputFieldRight: {
    width: 200,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  uploadInfo: {
    paddingLeft: 3,
    fontSize: 10,
    color: "#777777",
  },
  uploadSection: {
    padding: "20px 10px 10px 10px",
  },
}));

const ValidityDetails = ({
  data: { itemData = {}, openConfirmDialog = false },
  func: { onItemDataChange, handleSubmit },
}) => {
  const classes = useStyles();

  const handleValidityDetailsChange = (e) => {
    onItemDataChange(e);
  };

  const hasPACTask = () => {
    const pac = _find(itemData.tasks, { taskType: "PAC" });
    return pac && !!pac.isSelected;
  };

  return (
    <>
      <Paper className={classes.container}>
        {hasPACTask() && (
          <>
            <Box className={classes.title}>1. PAC Validity Date</Box>
            <Box className={classes.subtitle}>Validity Date</Box>
            <DatePicker
              name="pacValidityDate"
              value={itemData.masterWic.pacValidityDate}
              handleChange={handleValidityDetailsChange}
              additionalClass={classes.inputField}
              cy={"ci-pac-date"}
            />
          </>
        )}

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          {`${hasPACTask() ? 2 : 1}. GCC/QAA Validity Date & Period`}
        </Box>
        <Box className={classes.subtitle}>GCC/QAA</Box>
        <Select
          name="validityType"
          label="GCC - QAA (Select One)"
          value={itemData.masterWic.validityType}
          options={VALIDITY_TYPES}
          handleChange={handleValidityDetailsChange}
          additionalClass={classes.inputField}
          cy={"ci-gcc-select"}
        />
        <Box display="flex" alignItems="center">
          <Box>
            <Box className={classes.subtitle}>Validity Date</Box>
            <DatePicker
              name="validityDate"
              value={itemData.masterWic.validityDate}
              handleChange={handleValidityDetailsChange}
              additionalClass={classes.inputFieldLeft}
              cy={"ci-validity-date"}
            />
          </Box>
          <Box pl={2}>
            <Box className={classes.subtitle}>Validity Period</Box>
            <Select
              name="validityPeriod"
              label="Select period"
              value={itemData.masterWic.validityPeriod}
              options={VALIDITY_PERIODS}
              handleChange={handleValidityDetailsChange}
              additionalClass={classes.inputFieldRight}
              cy={"ci-validity-period"}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          {`${hasPACTask() ? 3 : 2}. FRI Self Inspection Validity Date`}
        </Box>
        <Box className={classes.subtitle}>Validity Date</Box>
        <DatePicker
          name="selfInspectionValidityDate"
          value={itemData.masterWic.selfInspectionValidityDate}
          handleChange={handleValidityDetailsChange}
          additionalClass={classes.inputField}
          cy={"ci-self-date"}
        />
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleSubmit}
        disabled={openConfirmDialog}
        data-cy={"ci-confirm"}
      >
        CONFIRM
      </Button>
    </>
  );
};

export default ValidityDetails;
