import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import auth from "auth/authenticator";
// import FilterTable from 'components/table/FilterTable';
import SearchWithButton from "components/input/SearchWithButton";
import SubmissionListTable from "./SubmissionListTable/index";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  tableContainer: {
    marginTop: 10,
    width: "fit-content",
    paddingRight: 30,
  },
  topPaginationContainer: {
    position: "absolute",
    top: -5,
    right: 0,
  },
}));

const Submissions = ({
  headerData = [],
  rowData = [],
  selectedSubmission,
  selectedItem,
  selectedFilter,
  searchSubmissionKeyword = "",
  handleSearchSubmissionKeywordChange,
  handleSubmissionSearch,
  handleSubmissionSelect,
  handleItemSelect,
  handleSorting,
  handleFilterListToggle,
  handleFilterItemSelect,
  handleExportButtonClick,

  selectedPanelItem = {},
  tableNumberType = {},

  rowsPerPageOption = [20, 50, 75, 100, 150, 200],
  currentPage,
  rowsPerPage,
  totalPages,
  totalRecords,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [tableTitle, setTableTitle] = useState("");

  useEffect(() => {
    let title = `Viewing ${totalRecords || 0} Submission Result(s) from`;

    if (selectedPanelItem.item === "all") {
      title = title + " All Labs";
    } else {
      title = title + ` ${selectedPanelItem.item}`;
    }

    if (tableNumberType.taskType !== "all") {
      title = title + ` > ${tableNumberType.taskType}`;
    }

    if (tableNumberType.dataType !== "all") {
      title = title + " > " + t(`taskStatus.${tableNumberType.dataType}`);
    }

    setTableTitle(title);
  }, [
    totalRecords,
    selectedPanelItem.item,
    tableNumberType.taskType,
    tableNumberType.table,
    tableNumberType.dataType,
  ]);

  const handleOpenSubmission = (id) => {
    window.open(`/${auth.loginRole()}/submissions/${id}`);
  };

  const handleOpenItem = (id) => {
    window.open(`/${auth.loginRole()}/items/${id}`);
  };

  return (
    <Box className={classes.container}>
      <SearchWithButton
        keyword={searchSubmissionKeyword}
        handleChange={handleSearchSubmissionKeywordChange}
        handleSearch={handleSubmissionSearch}
      />

      {/* <Box className={classes.topPaginationContainer}>
        <TopPagination
          currentPage={1}
          rowsPerPage={50}
          totalPages={1}
          rowsPerPageOption={[]}
          handlePageChange={() => {}}
          handleRowsPerPageChange={() => {}}
        />
      </Box> */}

      <Box display="flex" className={classes.tableContainer}>
        <SubmissionListTable
          tableTitle={tableTitle}
          hasTopButton={true}
          // hasTopButton={false}
          topButtonLabel="EXPORT"
          handleTopButtonClick={handleExportButtonClick}
          // hasSelectBox={true}
          selectedFilter={selectedFilter}
          selectedSubmission={selectedSubmission}
          selectedItem={selectedItem}
          handleSubmissionSelect={handleSubmissionSelect}
          handleItemSelect={handleItemSelect}
          // handleViewImage={handleViewImage}
          headerData={headerData}
          rowData={rowData}
          handleRowClick={handleOpenSubmission}
          handleSubRowClick={handleOpenItem}
          handleSorting={handleSorting}
          handleFilterListToggle={handleFilterListToggle}
          handleFilterItemSelect={handleFilterItemSelect}
          rowsPerPageOption={rowsPerPageOption}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default Submissions;
