import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import "@toast-ui/chart/dist/toastui-chart.min.css";
import { PieChart } from "@toast-ui/chart";
import Select from "../input/Select";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",
  },

  title: {
    fontSize: "14px",
    fontWeight: "500",
    minHeight: "30px",
  },
  title1: {
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "29px",
  },
  numRed: {
    fontSize: "36px",
    marginTop: "10px",
    color: "#D42600",
    fontWeight: "500",
  },
  numBlue: {
    fontSize: "36px",
    marginTop: "10px",
    color: "#003DA5",
    fontWeight: "500",
  },
  subTitle: {
    fontSize: "12px",
    paddingTop: "10px",
    borderTop: "1px solid #DADADA",
  },
  title2: {
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "20px",
  },
  chart: {
    marginTop: 15,

    "& .toastui-chart-wrapper": {
      margin: "0 auto",
      width: "fit-content",
    },
    "& .toastui-chart-tooltip-container": {
      zIndex: 99999,
    },
    "& .wrapper-tooltip": {
      width: "max-content",
      background: "white",
      padding: "20px 10px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
    },
    "& .title-tooltip": {
      fontWeight: "500",
      fontSize: 14,
      color: "#777777",
    },
    "& .body-tooltip": {
      marginTop: 15,
      display: "flex",
      alignItems: "center",
    },
    "& .point-tooltip": {
      display: "inline-block",
      width: 15,
      height: 15,
      borderRadius: "50%",
      marginRight: 20,
    },
    "& .item-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      marginRight: 20,
    },
    "& .including-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      color: "#777777",
    },
  },
  wrapChart: {
    position: "relative",
    overflow: "hidden",
    marginTop: "10px",
  },
  title3: {
    position: "absolute",
    fontSize: "14px",
    fontWeight: "500",
    top: 20,
    zIndex: 9,
  },
  midContent: {
    position: "absolute",
    zIndex: 9999,
    left: "50%",
    transform: "translateX(-50%)",
    bottom: 20,
    textAlign: "center",
  },
  mid1: {
    fontSize: 14,
    color: "#AAAAAA",
  },
  mid2: {
    fontSize: 36,
    color: "#003DA5",
    fontWeight: "bold",
  },
  hint: {
    display: "flex",
    marginTop: 30,
  },
  below: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#003DA5",
      borderRadius: "50%",
      marginRight: 20,
    },
  },

  over: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,

    "& span": {
      width: 15,
      height: 15,
      background: "#D42600",
      borderRadius: "50%",
      marginRight: 20,
    },
  },

  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },
  programSelect: {
    height: 36,
    paddingRight: 10,
  },
  avargeSelect: {
    border: "none",
    borderBottom: "1px solid #777777",
    borderRadius: 0,

    "& .MuiSelect-selectMenu": {
      color: "#000000",
      paddingLeft: 0,
      fontSize: 14,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const AverageChart = ({ handleTabChange, avgUnitValue = { avgRage: {}, unit: [], value: [] } }) => {
  const classes = useStyles();

  const [unitShow, setUnitShow] = useState(null);
  const [valueShow, setValueShow] = useState(null);
  const [avgRageShow, setAvgRageShow] = useState(null);

  const [averValue, setAverValue] = useState("unit");
  const [totalSupplier, setTotalSupplier] = useState(0);

  const handleChangeAverage = (e) => {
    setAverValue(e.target.value);
    let seriesShow = e.target.value === "unit" ? unitShow : valueShow;

    // Handle show Pie Chart
    let selected = {};
    for (let i = 0; i < seriesShow.length; i++) {
      selected[seriesShow[i].name] = seriesShow[i];
    }
    handleShowPieChart(seriesShow, selected);
  };

  useEffect(() => {
    if (unitShow && valueShow) {
      averValue === "unit"
        ? setTotalSupplier(unitShow[0].suppliers + unitShow[1].suppliers)
        : setTotalSupplier(valueShow[0].suppliers + valueShow[1].suppliers);
    }
  }, [unitShow, valueShow, averValue]);

  useEffect(() => {
    const { unit, value = [], avgRage = [] } = avgUnitValue;

    let seriesShow =
      unit &&
      unit.length &&
      unit.map((item, index) => {
        return { ...item, data: item.suppliers };
      });
    setAvgRageShow(avgRage);
    setValueShow(
      value &&
        value.length &&
        value.map((item, index) => {
          return { ...item, data: item.suppliers };
        }),
    );
    setUnitShow(seriesShow);

    // Handle show Pie Chart
    if (seriesShow && seriesShow.length) {
      let selected = {};
      for (let i = 0; i < seriesShow.length; i++) {
        selected[seriesShow[i].name] = seriesShow[i];
      }
      handleShowPieChart(seriesShow, selected);
    }
  }, [avgUnitValue]);

  const handleShowPieChart = (seriesShow = [], selected) => {
    const el = document.getElementById("pie-chart-unit");
    el.innerHTML = "";

    const data = {
      categories: ["Average 1506% in unit"],
      series: seriesShow,
    };
    const options = {
      chart: { title: "", width: 360, height: 180 },
      series: {
        radiusRange: { inner: "85%", outer: "100%" },
        angleRange: { start: -90, end: 90 },
      },
      tooltip: {
        template: (model) => {
          let data = model.data[0];
          return `
            <div class="wrapper-tooltip">
              <div class="title-tooltip">Including Suppliers : ${data.value}</div>
              <div class="body-tooltip">
                <span class="point-tooltip" style="background:${data.color}"></span>
                <span class="item-tooltip">${data.label}</span>
                <span class="item-tooltip">${data.percentValue.toFixed(2) + "%"}</span>
                <span class="including-tooltip">Including WIC items : ${
                  selected && selected[data.label].wicItems
                }</span>
              </div>
            </div>
          `;
        },
      },
      legend: {
        showCheckbox: false,
        visible: false,
      },
      exportMenu: {
        visible: false,
      },
      theme: {
        series: {
          colors: ["#D32F2F", "#003DA5"],
        },
      },
    };

    new PieChart({ el, data, options });
  };

  return (
    <Box className={classes.root} display="flex">
      <Box display="flex" className={classes.programSelect}>
        <Select
          label="Category"
          name="category"
          value={averValue}
          options={[
            { value: "unit", label: "Average 1506% in unit" },
            { value: "value", label: "Average 1506% in value" },
          ]}
          handleChange={handleChangeAverage}
          additionalClass={classes.avargeSelect}
        />
      </Box>

      <Box className={classes.wrapChart}>
        <Box className={classes.title3}>The average 1506% in {averValue} among all suppliers</Box>
        <Box className={classes.midContent}>
          <Box className={classes.mid1}>{totalSupplier} Suppliers</Box>
          <Box className={classes.mid2}>
            {avgRageShow && avgRageShow[averValue === "unit" ? "unitAvgRate" : "valueAvgRate"]}%
          </Box>
          <Box className={classes.mid1}>Average 1506%</Box>
        </Box>

        <Box id="pie-chart-unit" className={classes.chart}></Box>
      </Box>

      <Box className={classes.hint}>
        <Box className={classes.below}>
          <span></span>Below 2 %
        </Box>
        <Box className={classes.over}>
          <span></span>Over 2 %
        </Box>
      </Box>

      <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 0)}>
        VIEW ON LIST
      </Box>
    </Box>
  );
};

export default AverageChart;
