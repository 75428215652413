import createNumberMask from "text-mask-addons/dist/createNumberMask";
import emailMask from "text-mask-addons/dist/emailMask";
import moment from "moment-timezone";

const digitRegExp = /\d/;

const patternArray = (pattern, number) => {
  const result = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < number; i++) result.push(pattern);

  return result;
};

const DATE_MASK = [
  ...patternArray(digitRegExp, 2),
  "/",
  ...patternArray(digitRegExp, 2),
  "/",
  ...patternArray(digitRegExp, 4),
];
const YEAR_MASK = [...patternArray(digitRegExp, 4)];
const TIME_MASK = [
  ...patternArray(digitRegExp, 2),
  ":",
  ...patternArray(digitRegExp, 2),
  " ",
  "(",
  "G",
  "M",
  "T",
  " ",
  ...patternArray(digitRegExp, 2),
  ")",
];
const POSTAL_CODE_MASK = [...patternArray(digitRegExp, 5)];
const EMAIL_MASK = emailMask;
const CURRENCY_MASK = createNumberMask({
  prefix: "$ ",
  suffix: "",
});

const PHONE_NUMBER_MASK = (userInput) => {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join("").length;
  }

  const prefixArray = ["[", "+", ...patternArray(digitRegExp, 3), "]"];
  const blockThreeNumbers = patternArray(digitRegExp, 3);
  const blockFourNumbers = patternArray(digitRegExp, 4);

  if (numberLength === 11)
    return [...prefixArray, " ", "[", ...blockFourNumbers, "-", ...blockFourNumbers, "]"];
  if (numberLength === 12)
    return [
      ...prefixArray,
      " ",
      "[",
      ...blockThreeNumbers,
      "-",
      ...blockThreeNumbers,
      "-",
      ...blockThreeNumbers,
      "]",
    ];

  return [
    ...prefixArray,
    " ",
    "[",
    ...blockThreeNumbers,
    "-",
    ...blockThreeNumbers,
    "-",
    ...blockFourNumbers,
    "]",
  ];
};

function formatPhoneCountryCode(value) {
  if (!value) return "";

  const str = value.split(/\+(.*?)\]/);

  let countryCode = str[1] ? str[1].trim() : "";
  if (countryCode.length === 1) countryCode = `00${countryCode}`;
  else if (countryCode.length === 2) countryCode = `0${countryCode}`;

  return `[+${countryCode}]${str[2]}`;
}

function formatDateDifferent(date, formatSource, formatTarget) {
  if (moment(date, formatSource).isValid()) {
    return moment(date, formatSource).tz(moment.tz.guess()).format(formatTarget);
  }

  return date;
}

export {
  DATE_MASK,
  YEAR_MASK,
  TIME_MASK,
  POSTAL_CODE_MASK,
  EMAIL_MASK,
  CURRENCY_MASK,
  PHONE_NUMBER_MASK,
  formatPhoneCountryCode,
  formatDateDifferent,
};
