import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";
import UploadFiles from "components/input/UploadFiles";
import ErrorModal from "components/modal/ErrorModal";

import ReactAutocomplete from "react-autocomplete";

import NewPayerConfirmModal from "./NewPayerConfirmModal";

import { CURRENCY, CURRENCY_OPTIONS, INVOICE_TYPE, INVOICE_TYPE_OPTIONS } from "shared/constants";

import { atnGetSubmissionPayer } from "actions/submissionActions";
import { formatPayerListDropdown } from "utils/formatSubmissionTableData";

import useLoading from "hooks/useLoading";

export const defaultBillingAddress = {
  id: null,
  invoiceType: "",
  currency: "",
  payerCompanyAddress: "",
  payerCompanyName: "",
  payerContactPerson: "",
  payerEmail: "",
  payerPhoneNumber: "",
  address: "",
  bankAddress: "",
  bankName: "",
  contactNumber: "",
  enterpriseName: "",
  taxInformationFiles: [],
  taxRegistrationCode: "",
  attachments: [],
};

export default function PayerDetail({
  continueButtonText = "CONTINUE",
  errors = [],
  actionsButtonEnabled = false,
  cancelButtonDisabled = false,
  isDisabledConfirmButton = false,
  isTestingTask = false,
  isRetestTask = false,
  testingData = {},
  data = {
    billingAddress: defaultBillingAddress,
  },
  handleDataChange,
  handleNextPage,
  handleSubmit,
  handleClose,
}) {
  const classes = useStyles();

  const { isLoading, loading, done } = useLoading();

  const Block = ({ title, content: { label, element } }) => {
    return (
      <Box className={classes.block}>
        <Box className={classes.element}>
          {label && (
            <Typography className={classnames(classes.titleInput, classes.blackText)}>
              {label}
            </Typography>
          )}
          {element}
        </Box>
      </Box>
    );
  };

  const [showOnlyRMB, setShowOnlyRMB] = useState(false);
  const [showExistPayer, setShowExistPayer] = useState(true);
  const [showSelectExistParties, setShowSelectExistParties] = useState(false);

  const [isDisabledCurrency, setDisabledCurrency] = useState(false);
  const [isIcwInvoiceRmb, setIcwInvoiceRmb] = useState(false);

  const [openRmbErrorModal, setOpenRmbErrorModal] = useState(false);

  const [currency, setCurrency] = useState(null);
  const [listPayer, setListPayer] = useState([]);

  const [listPayerCompany, setListPayerCompany] = useState([]);
  const [listPayerContactPerson, setListPayerContactPerson] = useState([]);
  const [listPayerAddress, setListPayerAddress] = useState([]);
  const [listContactEmail, setListContactEmail] = useState([]);
  const [listContactPhone, setListContactPhone] = useState([]);

  const [payerCompanyName, setPayerCompanyName] = useState(null);
  const [payerContactPerson, setPayerContactPerson] = useState(null);

  const [openChangePayer, setOpenChangePayer] = useState(false);

  // List all value to show autocomlete input
  const [listPayerContactPersonAll, setListPayerContactPersonAll] = useState([]);
  const [listPayerAddressAll, setListPayerAddressAll] = useState([]);
  const [listPayerEmailAll, setListPayerEmailAll] = useState([]);
  const [listPayerPhoneAll, setListPayerPhoneAll] = useState([]);

  const hasPayerInfoError = !!errors?.length;

  useEffect(() => {
    getSubmissionPayer();
    handleCurrencyConditionChange();
  }, []);

  useEffect(() => {
    handleCurrencyConditionChange();
    setCurrency(data?.billingAddress?.currency);
  }, [data?.billingAddress?.invoiceType, data?.billingAddress?.currency]);

  useEffect(() => {
    setPayerContactPerson(data?.billingAddress?.payerContactPerson);
  }, [data?.billingAddress?.payerContactPerson]);

  useEffect(() => {
    //condition to disable currency if not select invoice type
    setDisabledCurrency(data?.billingAddress?.invoiceType ? false : true);
  }, [data?.billingAddress?.invoiceType]);

  useEffect(() => {
    if (!listPayer?.length) return;

    //set edit payer company list
    setListPayerCompany(
      listPayer?.map((payer, index) => {
        return {
          id: index,
          value: payer.payerCompanyName,
          label: payer.payerCompanyName,
        };
      }),
    );

    setListPayerContactPerson(
      listPayer
        ?.filter((payer) => payer.payerCompanyName === data?.billingAddress?.payerCompanyName)[0]
        ?.contactPerson?.map((contact, index) => {
          return {
            id: index,
            value: contact.payerContactPerson,
            label: contact.payerContactPerson,
            payerCompanyAddress: contact.payerCompanyAddress,
            payerEmail: contact.payerEmail,
            payerPhoneNumber: contact.payerPhoneNumber,
            payerContactPerson: contact.payerContactPerson,
          };
        }) || [],
    );
  }, [listPayer, data?.billingAddress?.payerCompanyName]);

  useEffect(() => {
    if (!listPayerContactPerson.length) return;

    //set edit data contact person info list
    setListPayerAddress(
      listPayerContactPerson
        ?.filter(
          (payerContact) => payerContact.value === data?.billingAddress?.payerContactPerson,
        )[0]
        ?.payerCompanyAddress.map((payer, index) => {
          return { id: index, label: payer, value: payer };
        }) || [],
    );

    setListContactEmail(
      listPayerContactPerson
        ?.filter(
          (payerContact) => payerContact.value === data?.billingAddress?.payerContactPerson,
        )[0]
        ?.payerEmail.map((payer, index) => {
          return { id: index, label: payer, value: payer };
        }) || [],
    );

    setListContactPhone(
      listPayerContactPerson
        ?.filter(
          (payerContact) => payerContact.value === data?.billingAddress?.payerContactPerson,
        )[0]
        ?.payerPhoneNumber.map((payer, index) => {
          return { id: index, label: payer, value: payer };
        }) || [],
    );
  }, [listPayerContactPerson, data?.billingAddress]);

  const handleCurrencyConditionChange = () => {
    //condition to set isIcwInvoiceRmb (display RMB warning message & error modal)
    if (
      data?.billingAddress?.invoiceType === INVOICE_TYPE.ICW_COMPANY_INVOICE &&
      data?.billingAddress?.currency === CURRENCY.RMB
    ) {
      setIcwInvoiceRmb(true);
    } else {
      setIcwInvoiceRmb(false);
    }
  };

  const handleListPayerAuto = (data) => {
    let {
      listContactPersonAll = [],
      listAddressAll = [],
      listEmailALl = [],
      listPhoneAll = [],
    } = formatPayerListDropdown(data);

    // Set list data all
    setListPayerContactPersonAll(listContactPersonAll);
    setListPayerAddressAll(listAddressAll);
    setListPayerEmailAll(listEmailALl);
    setListPayerPhoneAll(listPhoneAll);
  };

  const getSubmissionPayer = () => {
    loading();

    atnGetSubmissionPayer({})
      .then((result) => {
        setListPayer(result.data);

        handleListPayerAuto(result.data);

        setPayerCompanyName(data?.billingAddress?.payerCompanyName);

        done();
      })
      .catch((err) => done());
  };

  const handleAddNewParty = () => {
    setShowSelectExistParties(true);
    setShowExistPayer(false);
  };

  const handleShowDropdownPayer = () => {
    setOpenChangePayer(true);
  };

  const handlePayerDetailChange = (e) => {
    const { name, value } = e.target;

    if (name === "invoiceType") {
      switch (value) {
        case INVOICE_TYPE.PLAIN_VAT_INVOICE:
        case INVOICE_TYPE.SPECIAL_VAT_INVOICE:
          setCurrency(CURRENCY.RMB);
          break;
        default:
          setCurrency(null);
          break;
      }
    }

    if (name === "currency") {
      setCurrency(value);
    }

    // Only get the list when option List Exist Payer
    if (showExistPayer) {
      if (name === "payerCompanyName") {
        setPayerCompanyName(
          listPayer.filter((payer) => payer.payerCompanyName === value)[0].payerCompanyName,
        );

        setListPayerContactPerson(
          listPayer
            .filter((payer) => payer.payerCompanyName === value)[0]
            .contactPerson.map((contactPerson, index) => {
              let item = {
                id: index,
                value: contactPerson.payerContactPerson,
                label: contactPerson.payerContactPerson,
                payerCompanyAddress: contactPerson.payerCompanyAddress,
                payerEmail: contactPerson.payerEmail,
                payerPhoneNumber: contactPerson.payerPhoneNumber,
                payerContactPerson: contactPerson.payerContactPerson,
              };
              return item;
            }),
        );

        let billingAddress = data.billingAddress;
        billingAddress["payerContactPerson"] = "";
        billingAddress["payerCompanyAddress"] = "";
        billingAddress["payerEmail"] = "";
        billingAddress["payerPhoneNumber"] = "";

        handleDataChange({
          target: {
            name: "billingAddress",
            value: billingAddress,
          },
        });
      }

      if (name === "payerContactPerson") {
        setPayerContactPerson(
          listPayerContactPerson.filter((payerContact, index) => payerContact.value === value)[0]
            .payerContactPerson,
        );

        setListPayerAddress(
          listPayerContactPerson
            .filter((payerContact, index) => payerContact.value === value)[0]
            .payerCompanyAddress.map((payer, index) => {
              let item = { id: index, value: payer, label: payer };
              return item;
            }),
        );

        setListContactEmail(
          listPayerContactPerson
            .filter((payerContact, index) => payerContact.value === value)[0]
            .payerEmail.map((payer, index) => {
              let item = { id: index, value: payer, label: payer };
              return item;
            }),
        );

        setListContactPhone(
          listPayerContactPerson
            .filter((payerContact, index) => payerContact.value === value)[0]
            .payerPhoneNumber.map((payer, index) => {
              let item = { id: index, value: payer, label: payer };
              return item;
            }),
        );
      }
    }

    let billingAddress = data.billingAddress;

    billingAddress[name] = value;

    if (
      billingAddress["invoiceType"] === INVOICE_TYPE.PLAIN_VAT_INVOICE ||
      billingAddress["invoiceType"] === INVOICE_TYPE.SPECIAL_VAT_INVOICE
    ) {
      billingAddress["currency"] = CURRENCY.RMB;
      setShowOnlyRMB(true);
    } else {
      setShowOnlyRMB(false);

      if (name === "invoiceType") {
        billingAddress["currency"] = null;
      }
    }

    handleDataChange({
      target: {
        name: "billingAddress",
        value: billingAddress,
      },
    });
  };

  const handleAddAttachmentFiles = (newFiles) => {
    const files = data?.billingAddress?.attachments?.concat(newFiles);

    let billingAddress = data?.billingAddress;

    billingAddress.attachments = files;

    handleDataChange({
      target: {
        name: "billingAddress",
        value: billingAddress,
      },
    });
  };

  const handleRemoveAttachmentFile = (index) => {
    const files = data?.billingAddress?.attachments?.filter((item, i) => i !== index);

    let billingAddress = data.billingAddress;

    billingAddress.attachments = files;

    handleDataChange({
      target: {
        name: "billingAddress",
        value: billingAddress,
      },
    });
  };

  const checkMissingData = () => {
    if (
      !data?.billingAddress?.invoiceType ||
      !data?.billingAddress?.currency ||
      !data?.billingAddress?.payerCompanyName ||
      !data?.billingAddress?.payerCompanyAddress ||
      !data?.billingAddress?.payerContactPerson ||
      !data?.billingAddress?.payerEmail ||
      !data?.billingAddress?.payerPhoneNumber
    ) {
      alert("missing data");

      return false;
    }

    return true;
  };

  const handlePopChangePayer = () => {
    setOpenChangePayer(!openChangePayer);
  };

  const keepChangePayer = () => {
    setOpenChangePayer(false);
  };

  const backToExistingPayer = () => {
    setOpenChangePayer(false);
    setShowSelectExistParties(false);
    setShowExistPayer(true);
  };

  const handleOpenRmbErrorModal = () => {
    if (!checkMissingData()) return;

    setOpenRmbErrorModal(true);
  };

  const handleCloseRmbErrorModal = () => {
    setOpenRmbErrorModal(false);
  };

  const handleSubmitClick = () => {
    if (!checkMissingData()) return;

    if (
      !isRetestTask &&
      isTestingTask &&
      (data.isSkipQuote ||
        !(
          testingData.taskStatus === "not_open_for_quote" ||
          testingData.taskStatus === "waiting_for_lab_quote" ||
          testingData.taskStatus === "quote_available"
        ))
    ) {
      handleNextPage();
    } else {
      handleSubmit();
    }

    handleCloseRmbErrorModal();
  };

  const messages = {
    invoiceType: "* With additional 6% value added tax (VAT) + USD$10 handling fee per invoice",
    rmbWarning: "If you choose RMB for this ICW invoice, make sure you do not need a VAT invoice.",
  };

  return (
    <React.Fragment>
      <Paper className={classes.container} data-cy="payer-detail">
        <Box className={classes.title}>1. Select Invoice Type</Box>

        <RadioGroup
          name="invoiceType"
          value={data?.billingAddress?.invoiceType || ""}
          onChange={handlePayerDetailChange}
          className={classes.radioGroup}
        >
          {INVOICE_TYPE_OPTIONS.map((item) => (
            <StyledFormControlLabel
              key={item.value}
              label={item.label}
              value={item.value}
              data-cy-value={item.value}
              data-cy-checked={data?.billingAddress?.invoiceType === item.value}
              control={<CustomiseRadio />}
            />
          ))}
        </RadioGroup>

        <Box className={classes.radioGroupMessage}>{messages.invoiceType}</Box>

        <Box className={classes.title2}>2. Currency</Box>

        <RadioGroup
          name="currency"
          value={currency}
          onChange={handlePayerDetailChange}
          className={classes.radioGroup}
        >
          {CURRENCY_OPTIONS.map((item) => {
            const isRMB = CURRENCY.RMB.toLowerCase() === item.value?.toLowerCase();

            return (
              ((showOnlyRMB && isRMB) || !showOnlyRMB) && (
                <StyledFormControlLabel
                  key={item.value}
                  label={item.label}
                  value={item.value}
                  data-cy-value={item.value}
                  data-cy-checked={currency === item.value}
                  control={
                    <CustomiseRadio
                      disabled={isDisabledCurrency || (!isRMB && showOnlyRMB)}
                      checked={(isRMB && showOnlyRMB) || currency === item.value}
                    />
                  }
                />
              )
            );
          })}
        </RadioGroup>

        {isIcwInvoiceRmb && (
          <Box className={classes.radioGroupMessage}>{`* ${messages.rmbWarning}`}</Box>
        )}

        <Box className={classnames(classes.title, classes.fieldPaddingSmall)}>
          3.Payer Information
        </Box>

        {showExistPayer ? (
          <React.Fragment>
            <Grid container spacing={2} data-cy="payer-section">
              <Grid item xs={12} sm={6}>
                <Block
                  content={{
                    label: "Payer Company Name",
                    element: (
                      <Select
                        cy="payerCompanyName"
                        additionalClass={classes.maxFullWidth}
                        disabled={isLoading || !currency}
                        label="Fill company name here"
                        name="payerCompanyName"
                        value={data?.billingAddress?.payerCompanyName || ""}
                        options={listPayerCompany}
                        handleChange={(e) => handlePayerDetailChange(e)}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Block
                  content={{
                    label: "Payer Contact Person Name",
                    element: (
                      <Select
                        cy="payerContactPerson"
                        additionalClass={classes.maxFullWidth}
                        disabled={isLoading || !payerCompanyName}
                        label="Fill payer contact person name"
                        name="payerContactPerson"
                        value={data?.billingAddress?.payerContactPerson || ""}
                        options={listPayerContactPerson}
                        handleChange={(e) => handlePayerDetailChange(e)}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Block
                  content={{
                    label: "Payer Address",
                    element: (
                      <Select
                        cy="payerCompanyAddress"
                        additionalClass={classes.maxFullWidth}
                        disabled={isLoading || !payerContactPerson}
                        label="Fill payer address"
                        name="payerCompanyAddress"
                        value={data?.billingAddress?.payerCompanyAddress || ""}
                        options={listPayerAddress}
                        handleChange={(e) => handlePayerDetailChange(e)}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Block
                  content={{
                    label: "Contact Person Email",
                    element: (
                      <Select
                        cy="payerEmail"
                        additionalClass={classes.maxFullWidth}
                        disabled={isLoading || !payerContactPerson}
                        label="Fill contact person email"
                        name="payerEmail"
                        value={data?.billingAddress?.payerEmail || ""}
                        options={listContactEmail}
                        handleChange={(e) => handlePayerDetailChange(e)}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Block
                  content={{
                    label: "Contact Person Phone Number",
                    element: (
                      <Select
                        cy="payerPhoneNumber"
                        additionalClass={classes.maxFullWidth}
                        disabled={isLoading || !payerContactPerson}
                        label="Fill contact person phone number"
                        name="payerPhoneNumber"
                        value={data?.billingAddress?.payerPhoneNumber || ""}
                        options={listContactPhone}
                        handleChange={(e) => handlePayerDetailChange(e)}
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Box className={classes.addButtonContainer}>
              <OutlinedButton
                data-cy="add-new-billing"
                label="Add New Billing Party"
                handleClick={handleAddNewParty}
                color="primary"
                disabled={isLoading}
              />
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container spacing={2} data-cy="new-payer-section">
              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitlePayer}>New Payer Company Name</Box>
                <Box className={classes.wrapperAuto} data-cy="newPayerCompanyName">
                  <ReactAutocomplete
                    getItemValue={(item) => item.label}
                    items={listPayerCompany || []}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{ background: isHighlighted ? "lightgray" : "white" }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={data?.billingAddress?.payerCompanyName || ""}
                    onChange={(e) =>
                      handlePayerDetailChange({
                        target: { name: "payerCompanyName", value: e.target.value },
                      })
                    }
                    onSelect={(val) =>
                      handlePayerDetailChange({ target: { name: "payerCompanyName", value: val } })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitlePayer}>New Payer Contact Person Name</Box>
                <Box className={classes.wrapperAuto} data-cy="newPayerContact">
                  <ReactAutocomplete
                    getItemValue={(item) => item.label}
                    items={listPayerContactPersonAll || []}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{ background: isHighlighted ? "lightgray" : "white" }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={data?.billingAddress?.payerContactPerson || ""}
                    onChange={(e) =>
                      handlePayerDetailChange({
                        target: { name: "payerContactPerson", value: e.target.value },
                      })
                    }
                    onSelect={(val) =>
                      handlePayerDetailChange({
                        target: { name: "payerContactPerson", value: val },
                      })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitlePayer}>New Payer Address</Box>
                <Box className={classes.wrapperAuto} data-cy="newPayerAddress">
                  <ReactAutocomplete
                    getItemValue={(item) => item.label}
                    items={listPayerAddressAll}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{ background: isHighlighted ? "lightgray" : "white" }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={data?.billingAddress?.payerCompanyAddress || ""}
                    onChange={(e) =>
                      handlePayerDetailChange({
                        target: { name: "payerCompanyAddress", value: e.target.value },
                      })
                    }
                    onSelect={(val) =>
                      handlePayerDetailChange({
                        target: { name: "payerCompanyAddress", value: val },
                      })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitlePayer}>New Contact Person Email</Box>
                <Box className={classes.wrapperAuto} data-cy="newPayerContactEmail">
                  <ReactAutocomplete
                    getItemValue={(item) => item.label}
                    items={listPayerEmailAll || []}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{ background: isHighlighted ? "lightgray" : "white" }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={data?.billingAddress?.payerEmail || ""}
                    onChange={(e) =>
                      handlePayerDetailChange({
                        target: { name: "payerEmail", value: e.target.value },
                      })
                    }
                    onSelect={(val) =>
                      handlePayerDetailChange({ target: { name: "payerEmail", value: val } })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitlePayer}>New Contact Person Phone Number</Box>
                <Box className={classes.wrapperAuto} data-cy="newPayerContactNumber">
                  <ReactAutocomplete
                    getItemValue={(item) => item.label}
                    items={listPayerPhoneAll || []}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        style={{ background: isHighlighted ? "lightgray" : "white" }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={data?.billingAddress?.payerPhoneNumber || ""}
                    onChange={(e) =>
                      handlePayerDetailChange({
                        target: { name: "payerPhoneNumber", value: e.target.value },
                      })
                    }
                    onSelect={(val) =>
                      handlePayerDetailChange({
                        target: { name: "payerPhoneNumber", value: val },
                      })
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        {showSelectExistParties && (
          <Box className={classes.textSelect} onClick={handleShowDropdownPayer}>
            *If you want to select existed billing Parties, please <span>click</span> here to
            select.
          </Box>
        )}

        {(data?.billingAddress?.invoiceType === INVOICE_TYPE.PLAIN_VAT_INVOICE ||
          data?.billingAddress?.invoiceType === INVOICE_TYPE.SPECIAL_VAT_INVOICE) && (
          <React.Fragment>
            <Box className={classnames(classes.title, classes.fieldPadding)}>
              4. Tax Information
            </Box>
            <Box className={classes.uploadContainer} data-cy="upload-tax">
              <UploadFiles
                buttonLabel="UPLOAD CHINA BR COPY AND OTHER ATTACHMENTS"
                files={data.billingAddress.attachments || []}
                handleAddFiles={handleAddAttachmentFiles}
                handleRemoveFile={handleRemoveAttachmentFile}
              />
            </Box>

            {data?.billingAddress?.invoiceType === "special_vat_invoice" && (
              <React.Fragment>
                <Grid container spacing={2} data-cy="tax-form">
                  <Grid item xs={12} sm={6}>
                    <Box className={classes.subtitle}>Tax Registration Code</Box>
                    <Box>
                      <TextInput
                        cy="taxRegistrationCode"
                        placeholder="Registration Code"
                        name="taxRegistrationCode"
                        value={data.billingAddress.taxRegistrationCode || ""}
                        // additionalClass={classes.inputField}
                        onChange={handlePayerDetailChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box className={classes.subtitle}>Enterprise Name</Box>
                    <Box>
                      <TextInput
                        cy="enterpriseName"
                        placeholder="Enterprise Name"
                        name="enterpriseName"
                        value={data.billingAddress.enterpriseName || ""}
                        // additionalClass={classes.inputField}
                        onChange={handlePayerDetailChange}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.subtitle}>
                  Address (shown on invoice)
                </Grid>
                <TextInput
                  cy="address"
                  placeholder="Address"
                  name="address"
                  value={data.billingAddress.address || ""}
                  // additionalClass={classes.inputField}
                  onChange={handlePayerDetailChange}
                />

                <Grid item xs={12} sm={6}>
                  <Box className={classes.subtitle}>Bank Name</Box>
                  <Box>
                    <TextInput
                      cy="bankName"
                      placeholder="Bank Name"
                      name="bankName"
                      value={data.billingAddress.bankName || ""}
                      // additionalClass={classes.inputField}
                      onChange={handlePayerDetailChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box className={classes.subtitle}>Contact No (shown on invoice)</Box>
                  <Box>
                    <TextInput
                      cy="contactNumber"
                      placeholder="Contact No"
                      name="contactNumber"
                      value={data.billingAddress.contactNumber || ""}
                      // additionalClass={classes.inputField}
                      onChange={handlePayerDetailChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className={classes.subtitle}>
                  Bank Address (shown on invoice)
                </Grid>
                <TextInput
                  cy="bankAddress"
                  placeholder="Bank Address"
                  name="bankAddress"
                  value={data.billingAddress.bankAddress || ""}
                  // additionalClass={classes.inputField}
                  onChange={handlePayerDetailChange}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {actionsButtonEnabled && (
          <Grid container spacing={2} className={classes.actionsButton}>
            {!cancelButtonDisabled && (
              <Grid item>
                <Button
                  data-cy="cancel"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  disabled={isDisabledConfirmButton}
                >
                  Cancel
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button
                data-cy="confirm"
                variant="contained"
                color="primary"
                onClick={isIcwInvoiceRmb ? handleOpenRmbErrorModal : handleSubmitClick}
                disabled={isDisabledConfirmButton}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        )}

        {hasPayerInfoError && (
          <ErrorBoxWrapper>
            {errors?.map((item, index) => (
              <ErrorBox data-cy={`error-${index}`} key={index}>
                {item}
              </ErrorBox>
            ))}
          </ErrorBoxWrapper>
        )}
      </Paper>

      {!actionsButtonEnabled && (
        <Button
          data-cy="confirm"
          variant="contained"
          color="primary"
          className={classes.continueBtn}
          onClick={isIcwInvoiceRmb ? handleOpenRmbErrorModal : handleSubmitClick}
          disabled={isDisabledConfirmButton}
        >
          {continueButtonText}
        </Button>
      )}

      {/* ------------ MODAL CONFIRM NEW PAYER ------------ */}
      <NewPayerConfirmModal
        hideCloseIcon
        open={openChangePayer}
        onClose={handlePopChangePayer}
        onCancel={keepChangePayer}
        onSubmit={backToExistingPayer}
      />

      {/* ------------ MODAL ALERT ICW INOVICE WITH RMB ------------ */}
      <ErrorModal
        title="ICW Invoice choosing RMB currency"
        content={messages.rmbWarning}
        open={openRmbErrorModal}
        onClose={handleCloseRmbErrorModal}
        onSubmit={handleSubmitClick}
      />
    </React.Fragment>
  );
}

const ErrorBoxWrapper = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "16px 0 8px 0",
  },
})((props) => <Box {...props} />);

const ErrorBox = withStyles({
  root: {
    color: "#D42600",
    margin: "4px 0",
  },
})((props) => <Box {...props} />);

const CustomiseRadio = withStyles({
  root: {
    "&$checked": {
      color: "#B0D400",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledFormControlLabel = withStyles({
  root: {
    marginRight: "auto",
  },
})((props) => <FormControlLabel {...props} />);

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  title2: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 30,
  },
  radioGroup: {
    paddingTop: 10,
  },
  radioGroupMessage: {
    paddingTop: 5,
    fontSize: 10,
    color: "#D42600",
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: "8px",
    marginBottom: "8px",
  },
  subtitlePayer: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#232B3A",
    display: "block",
    marginTop: 15,
    marginBottom: 5,
  },
  inputField: {
    width: 400,
  },
  fieldPaddingSmall: {
    paddingTop: 20,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  blockPadding: {
    paddingRight: 10,
    marginRight: 90,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  select400: {
    maxWidth: 400,
    width: 400,
  },
  select900: {
    maxWidth: 900,
    width: 900,
  },
  maxFullWidth: {
    maxWidth: "100%",
    width: "100%",
  },
  titleInput: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#232B3A",
    display: "block",
    marginTop: 15,
    marginBottom: 5,
  },
  addButtonContainer: {
    marginTop: 20,
    color: "#005EFF",
  },
  textSelect: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#777777",
    marginTop: 20,
    cursor: "pointer",

    "& span": {
      color: "#005EFF",
      textDecoration: "underline",
    },
  },
  fourBox: {
    float: "right",
  },
  discardPop: {
    "& hr": {
      background: "#003DA5",
    },
  },
  popPayerInform: {
    color: "#D42600",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "16px",
  },

  textContent: {
    color: "black",
    fontSize: 14,
    fontWeight: "initial",
  },
  applyBtn: {
    marginLeft: 25,
  },
  wrapperAuto: {
    borderRadius: 4,
    overflow: "hidden",
    border: "1px solid #AAAAAA",

    "& > div": {
      width: "100%",
    },

    "& input": {
      // width: 400,
      width: "100%",
      padding: "10px 8px",
      fontSize: "12px",
      border: 0,
    },

    "& input + div": {
      background: "white",

      zIndex: 999,

      "& > div": {
        padding: "5px 10px",
      },
    },
  },

  wrapperAuto900: {
    width: 904,
    borderRadius: 4,
    overflow: "hidden",
    border: "1px solid #AAAAAA",

    "& input": {
      width: 900,
      padding: "10px 8px",
      fontSize: "12px",
      border: 0,
    },

    "& input + div": {
      background: "white",
      zIndex: 999,

      "& > div": {
        padding: "5px 10px",
      },
    },
  },
  actionsButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "24px",
  },
}));
