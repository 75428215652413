import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import MenuBar from "./MenuBar";
import Quote from "./Quote";
import Task from "./Task";
import Products from "./Products";
import SupplierFactory from "./SupplierFactory";
import Invoice from "./Invoice";

import Tabs from "components/navigation/Tabs";

import SelectTasksTable from "components/table/SelectTasksTable";
import ErrorModal from "components/modal/ErrorModal";

import PbRevisionModal from "pages/common/PbRevisionModal";
import PbUploadReportModal from "pages/common/PbUploadReportModal";

import {
  atnCancelTasks,
  atnGetSubmissionDetails,
  atnGetTasksForCancellation,
  atnUpdateCAPStatus,
} from "actions/submissionActions";
import { atnGetTaskPayer, atnUpdateTaskLabReport } from "actions/taskActions";
import { atnReviseSupplierPb } from "actions/pbActions";

import Auth from "auth/authenticator";

import {
  ratnGetSubmissionTasksData,
  ratnToggleLabReportModal,
  ratnTogglePbErrorModal,
  ratnTogglePbRevisionModal,
} from "state/submissionDetail/action";

import { EVERYDAY, LAB, PB_STATUS, SUPPLIER, TASK_STATUS, USER_TYPE } from "shared/constants";

import supplierData from "./data/supplierData.json";
import labData from "./data/labData.json";

import { isShowProgram777 } from "services/pbService";
import SubmissionRemark from "../SharedItemDetail/SubmissionRemark";

const { pb_not_existed } = TASK_STATUS;
const { NOT_EXISTED } = PB_STATUS;

const pbRevisionErrors = {
  pbNotExisted: {
    title: "Not Existed PB in selected list",
    content: "Please unselect Not Existed PB & select a new PB for replacement.",
  },
  missingPb: {
    title: "Missing PB in list",
    content: "Please select at least 1 PB in list",
  },
  missingFile: {
    title: "Missing file(s) in list",
    content: "Please select at least 1 file for each file type in list",
  },
  missingSPU: {
    title: "Missing task type (everyday) for sample pick up",
    content: "Please select task type",
  },
};

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  dialogTableTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 5,
  },
}));

const SubmissionDetail = ({ type, submissionId, isAdmin = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isSupplier = Auth.loginRole() === SUPPLIER;

  const submissionData = type === USER_TYPE.lab ? labData : supplierData;
  // const { tabs = [] } = submissionData;

  const showInvoiceTab = useSelector((state) => state.submissionDetail.showInvoiceTab);

  const openPbRevisionModal = useSelector((state) => state.submissionDetail.openPbRevisionModal);
  const openPbErrorModal = useSelector((state) => state.submissionDetail.openErrorModal);

  const openLabReportModal = useSelector(
    (state) => state.submissionDetail.openLabReportUpdateModal,
  );

  const pbRevisionTasks = useSelector((state) => state.submissionDetail.tasks);

  const SUBMISSION_TASK_TYPE = useSelector((s) => s.submissionDetail?.submission?.taskType);
  const currentTaskStatus = useSelector((state) => state.submissionDetail.currentTaskStatus);

  const currentTaskDetail = useSelector((state) => state.submissionDetail.currentTaskDetail);

  const triggeredBy = useSelector((state) => state.submissionDetail.currentTaskDetail.triggeredBy);

  const isSPU = SUBMISSION_TASK_TYPE && ["SPU", "SPUD"].indexOf(SUBMISSION_TASK_TYPE) !== -1;
  const isFRI = SUBMISSION_TASK_TYPE && ["FRI", "FRID"].indexOf(SUBMISSION_TASK_TYPE) !== -1;

  const [pbRevisionError, setPbRevisionError] = useState({
    title: "Error",
    content: "Please fill in all inputs.",
  });

  const [tabs, setTabs] = useState([]);
  const [labStatus, setLabStatus] = useState("");

  const [details, setDetails] = useState({ id: submissionId });
  const [curTaskType, setCurTaskType] = useState(null);

  const [openCancelTaskDialog, setOpenCancelTaskDialog] = useState(false);
  const [tasksForCancellation, setTasksForCancellation] = useState([]);
  const [selectedCancelTaskIds, setSelectedCancelTaskIds] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [openRejectCAPDialog, setOpenRejectCAPDialog] = useState(false);
  const [openAcceptCAPDialog, setOpenAcceptCAPDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPbRevision, setIsLoadingPbRevision] = useState(false);

  //* <--- payer info for SS task with lab flow --->
  // determine to fetch task payer info api
  const [isSampleSealingLabFlow, setIsSampleSealingLabFlow] = useState(false);

  // task payer info for SS task with lab flow
  const [isPayerInfoLoading, setIsPayerInfoLoading] = useState(false);
  const [hasPayerInfo, setHasPayerInfo] = useState(false);
  const [payerInfo, setPayerInfo] = useState(null);

  const [isInvoiceDefaultTab, setIsInvoiceDefaultTab] = useState(false);

  // show payer info form for SS task to force user to fill info
  const isSupplierMissingPayerInfo =
    !isPayerInfoLoading && !hasPayerInfo && isSupplier && isSampleSealingLabFlow;

  const showPayerInfoButton = hasPayerInfo && isSupplier && isSampleSealingLabFlow;

  /* side effects */
  useEffect(() => {
    getSubmissionDetails(submissionId);
  }, []);

  useEffect(() => {
    setIsInvoiceDefaultTab(isSupplierMissingPayerInfo || showInvoiceTab);

    //reset default tab to null after navigated to invoice tab initially
    setTimeout(() => {
      setIsInvoiceDefaultTab(false);
    }, 1000);
  }, [isSupplierMissingPayerInfo, showInvoiceTab]);

  useEffect(() => {
    currentTaskStatus === pb_not_existed.value &&
      curTaskType &&
      details &&
      dispatch(ratnGetSubmissionTasksData(submissionId, curTaskType, details));
  }, [currentTaskStatus, curTaskType, details]);

  useEffect(() => {
    // set control thread to decide whether to fetch payer info
    if (!isSupplier || !details?.assignTo || !currentTaskDetail?.taskType) return;

    setIsSampleSealingLabFlow(details?.assignTo === LAB && currentTaskDetail?.taskType === "SS");
  }, [currentTaskDetail?.taskType, details?.assignTo]);

  useEffect(() => {
    // set payer info if supplier role & has task id
    getSampleSealingTaskPayerInfo(currentTaskDetail?.id);
  }, [currentTaskDetail?.id, isSampleSealingLabFlow]);

  const getSampleSealingTaskPayerInfo = (id) => {
    if (id) {
      setIsPayerInfoLoading(true);
      atnGetTaskPayer({ taskId: id })
        .then((res) => {
          if (res.code === 404 || !res?.data) return;

          setHasPayerInfo(true);
          setPayerInfo(res?.data);
        })
        .finally(() => {
          setIsPayerInfoLoading(false);
        });
    }
  };

  const getSubmissionDetails = (id) => {
    atnGetSubmissionDetails({ data: { id }, type }).then(({ data, message }) => {
      const detail = { id, ...data };

      setLabStatus(data?.labStatus);

      if (data?.labStatus === "rejected") {
        setTabs(submissionData.rejectedTabs);
      } else {
        if (data?.assignTo === SUPPLIER || data?.assignTo === "wba") {
          if (data?.taskType === "SS") {
            setTabs(submissionData.sampleSealingTabsNoInvoice);
          } else {
            if (data?.isSkipQuote === 1) {
              setTabs(submissionData.skipQuoteTabsNoInvoice);
            } else {
              setTabs(submissionData.tabsNoInvoice);
            }
          }
        } else {
          if (data?.taskType === "SS") {
            setTabs(submissionData.sampleSealingTabs);
          } else {
            if (data?.isSkipQuote === 1) {
              setTabs(submissionData.skipQuoteTabs);
            } else {
              setTabs(submissionData.tabs);
            }
          }
        }
      }

      setDetails(detail);
      setCurTaskType(detail.taskType);
    });
  };

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelTaskDialogOpen = () => {
    atnGetTasksForCancellation({
      submissionId: submissionId,
    }).then(({ data, message }) => {
      if (data) {
        setTasksForCancellation(data);
        setOpenCancelTaskDialog(true);
      }
    });
  };

  const handleCancelTaskDialogClose = () => {
    setOpenCancelTaskDialog(false);
    setSelectedCancelTaskIds([]);
  };

  const handleCancelTaskSelect = (task) => {
    if (selectedCancelTaskIds.indexOf(task.id) >= 0) {
      setSelectedCancelTaskIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      setSelectedCancelTaskIds((prevState) => {
        return prevState.concat(task.id);
      });
    }
  };

  const handleTaskCancel = () => {
    let tasks = [];

    for (let i = 0; i < selectedCancelTaskIds.length; i++) {
      tasks.push({
        taskId: selectedCancelTaskIds[i],
      });
    }

    atnCancelTasks({
      submissionId: submissionId,
      data: tasks,
    }).then(({ data, message }) => {
      if (data) {
        setOpenConfirmDialog(false);
        setOpenCancelTaskDialog(false);
        window.location.reload();
      }
    });
  };

  const handleCAPAccept = () => {
    atnUpdateCAPStatus({
      submissionId: submissionId,
      data: {
        status: "accepted",
      },
    }).then(({ data, message }) => {
      if (data) {
        window.location.reload();
      }
    });
  };

  const handleCAPReject = () => {
    atnUpdateCAPStatus({
      submissionId: submissionId,
      data: {
        status: "rejected",
      },
    }).then(({ data, message }) => {
      if (data) {
        window.location.reload();
      }
    });
  };

  const handleOpenRejectCAPDialog = () => {
    setOpenRejectCAPDialog(true);
  };

  const handleCloseRejectCAPDialog = () => {
    setOpenRejectCAPDialog(false);
  };

  const handleOpenAcceptCAPDialog = () => {
    setOpenAcceptCAPDialog(true);
  };

  const handleCloseAcceptCAPDialog = () => {
    setOpenAcceptCAPDialog(false);
  };

  const onClosePbRevisionModal = () => {
    dispatch(ratnTogglePbRevisionModal(false));
  };

  const onOpenPbErrorModal = () => {
    dispatch(ratnTogglePbErrorModal(true));
  };

  const onClosePbErrorModal = () => {
    dispatch(ratnTogglePbErrorModal(false));
  };

  const getConvertedPbFiles = (task) => {
    const masterWicFiles = [];

    for (const productSpec of task.productSpecification) {
      masterWicFiles.push({
        id: productSpec.id,
        type: "product_specification",
      });
    }

    for (const artworks of task.artworks) {
      masterWicFiles.push({
        id: artworks.id,
        type: "artworks",
      });
    }

    for (const upcInfo of task.aisUPCInformation) {
      masterWicFiles.push({
        id: upcInfo.id,
        type: "ais_stibo_upc_information",
      });
    }

    for (const outerInner of task.aisOuterInner) {
      masterWicFiles.push({
        id: outerInner.id,
        type: "ais_stibo_outer_inner",
      });
    }

    for (const factoryInfo of task.aisFactoryInformation) {
      masterWicFiles.push({
        id: factoryInfo.id,
        type: "ais_stibo_factory_information",
      });
    }

    for (const e2open of task.e2open) {
      masterWicFiles.push({
        id: e2open.id,
        type: "e2open",
      });
    }

    return masterWicFiles;
  };

  const getFilteredSubmitPb = (pbList) => {
    return pbList.filter((pb) => pb.pbStatus !== NOT_EXISTED);
  };

  const checkPbRevisionTasks = () => {
    let hasEmptyData = false;

    for (const task of pbRevisionTasks) {
      //check if task pb has not existed
      for (const pb of task.pb) {
        if (pb.pbStatus === NOT_EXISTED) {
          setPbRevisionError(pbRevisionErrors.pbNotExisted);
          hasEmptyData = true;
          break;
        }
      }

      //check if missing pb
      if (task.pb?.length === 0) {
        setPbRevisionError(pbRevisionErrors.missingPb);
        hasEmptyData = true;
        break;
      }

      //check if missing files
      if (
        task.aisUPCInformation?.length === 0 ||
        task.aisOuterInner?.length === 0 ||
        task.e2open?.length === 0
      ) {
        setPbRevisionError(pbRevisionErrors.missingFile);
        hasEmptyData = true;
        break;
      }

      if (isFRI) {
        if (task.productSpecification?.length === 0 || task.artworks?.length === 0) {
          setPbRevisionError(pbRevisionErrors.missingFile);
          hasEmptyData = true;
          break;
        }
      }

      //missing spu task type
      if (isSPU) {
        if (!task.spuForEverydayTaskType) {
          setPbRevisionError(pbRevisionErrors.missingSPU);
          hasEmptyData = true;
          break;
        }
      }
    }

    return hasEmptyData;
  };

  const onStartLoadingPbRevisionSubmit = () => setIsLoadingPbRevision(true);
  const onStopLoadingPbRevisionSubmit = () => setIsLoadingPbRevision(false);

  const onSubmitPbRevision = () => {
    const submitTasks = [];

    for (const task of pbRevisionTasks) {
      const showProgram777 = isShowProgram777(triggeredBy);

      if (task.category === EVERYDAY && showProgram777) {
        submitTasks.push({
          id: task.id,
          masterWicId: task.masterWicId,
          taskType: task.taskType,
          pb: getFilteredSubmitPb(task.pb),
          masterWicFiles: getConvertedPbFiles(task),
        });
      }
    }

    const submitData = {
      tasks: submitTasks,
    };

    //checking
    if (checkPbRevisionTasks()) {
      onOpenPbErrorModal();
      return;
    }

    onStartLoadingPbRevisionSubmit();

    atnReviseSupplierPb(submitData).then((res) => {
      onStopLoadingPbRevisionSubmit();

      if (res) {
        onClosePbRevisionModal();
        window.location.reload();
      }
    });

    onStopLoadingPbRevisionSubmit();
  };

  const onCloseLabReportModal = () => {
    dispatch(ratnToggleLabReportModal(false));
  };

  const onSubmitPbUploadReport = async () => {
    setIsLoading(true);

    const reportData = await currentTaskDetail.reports.map((report) => {
      return {
        id: report.id || "",
        fileId: report.fileId,
        result: report.result,
        issueDate: report.issueDate,
        pb: report.pb,
      };
    });
    const submitData = {
      taskId: currentTaskDetail.id,
      reports: reportData,
    };

    await atnUpdateTaskLabReport(submitData).then((res) => {
      if (res) {
        history.go(0);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleRefetchInInvoiceTab = () => {
    getSampleSealingTaskPayerInfo(currentTaskDetail?.id);
  };

  const tabsData = tabs.map((tab) => {
    const { title } = tab;

    let component = null;

    switch (title) {
      case "Quote":
        component = (
          <Quote data={details} userType={type} taskType={curTaskType} labStatus={labStatus} />
        );
        break;
      case "Task":
        component = (
          <Task
            hasPayerInfo={hasPayerInfo}
            data={details}
            userType={type}
            tenderingProgram={details?.tenderingProgram}
          />
        );
        break;
      case "Products":
        component = <Products data={details} userType={type} />;
        break;
      case "Supplier & Factory":
        component = <SupplierFactory data={details} userType={type} />;
        break;
      case "Invoice":
        component = (
          <Invoice
            refetch={handleRefetchInInvoiceTab}
            showPayerInfoForm={isSupplierMissingPayerInfo}
            payerInfo={payerInfo}
            taskId={currentTaskDetail?.id}
            data={details}
            userType={type}
            taskType={curTaskType}
            submissionId={submissionId}
          />
        );
        break;
      case "Remark":
        component = <SubmissionRemark data={details} userType={type} isSubmission />;
      default:
        break;
    }

    return { ...tab, component };
  });

  return (
    <>
      <MenuBar
        showPayerInfoButton={!isPayerInfoLoading && showPayerInfoButton}
        data={details}
        currentTaskId={currentTaskDetail?.id}
        submissionId={submissionId}
        userType={type}
        isAdmin={isAdmin}
        labStatus={labStatus}
        handleCancelTaskDialogOpen={handleCancelTaskDialogOpen}
        handleCAPAccept={handleOpenAcceptCAPDialog}
        handleCAPReject={handleOpenRejectCAPDialog}
      />

      <Tabs defaultTab={isInvoiceDefaultTab ? "invoice" : ""} data={tabsData} gridDisplay />

      <PbUploadReportModal
        open={openLabReportModal}
        onClose={onCloseLabReportModal}
        onSubmit={onSubmitPbUploadReport}
      />

      <PbRevisionModal
        open={openPbRevisionModal}
        onClose={onClosePbRevisionModal}
        onSubmit={onSubmitPbRevision}
        isLoading={isLoadingPbRevision}
      />

      <ErrorModal
        open={openPbErrorModal}
        onClose={onClosePbErrorModal}
        title={pbRevisionError.title}
        content={pbRevisionError.content}
      />

      <Dialog
        className={classes.dialog}
        onClose={handleCancelTaskDialogClose}
        open={openCancelTaskDialog}
      >
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>Cancel Task</Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.dialogMessage}>
            The selected task below will be cancelled, and the action cannot be undone.
          </Box>
          <Box className={classes.dialogTableTitle}>Select task for cancellation</Box>
          <SelectTasksTable
            tasks={tasksForCancellation}
            selectedTaskIds={selectedCancelTaskIds}
            handleTaskSelect={handleCancelTaskSelect}
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCancelTaskDialogClose} color="primary">
            BACK
          </Button>

          <Button
            onClick={handleConfirmDialogOpen}
            color="primary"
            variant="contained"
            disabled={selectedCancelTaskIds.length === 0}
          >
            CONFIRM CANCEL
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Cancel Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All selected task will be cancelled, and this action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            BACK
          </Button>
          <Button onClick={handleTaskCancel} color="secondary" variant="contained">
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAcceptCAPDialog} onClose={handleCloseAcceptCAPDialog}>
        <DialogTitle>Accept CAP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By accepting, you can then advise ECD & upload reports for retest.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAcceptCAPDialog} color="primary">
            BACK
          </Button>
          <Button onClick={handleCAPAccept} color="primary" variant="contained">
            ACCEPT
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRejectCAPDialog} onClose={handleCloseRejectCAPDialog}>
        <DialogTitle>Reject CAP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By rejecting, it will require supplier to submit CAP again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectCAPDialog} color="primary">
            BACK
          </Button>
          <Button onClick={handleCAPReject} color="secondary" variant="contained">
            REJECT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmissionDetail;
