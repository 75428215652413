import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import ConfirmModal from "components/modal/ConfirmModal";
import { atnConfirmQuotation, atnGetSubmissionDetailsQuotations } from "actions/submissionActions";
import Button from "components/input/Button";
import Card from "components/display/Card";
import Divider from "components/display/Divider";
import TestPlanTable from "pages/common/TestPlanTable";
import { textOrUnknown } from "utils/string";
import { getDateFormat } from "utils/date";
import OtherDetails from "pages/common/OtherDetails";
import { QUOTE_STATUS, TASK_STATUS_ENUM, TASK_TYPE_ENUM } from "shared/constants";
import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    // padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hideButton: {
    display: "none",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#777777",
  },
  noQuoteContainer: {
    width: "100%",
    textAlign: "center",
  },
  noQuoteContent: {
    paddingTop: 40,
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "2px 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
  },
}));

const Quote = ({ history, data, userType, taskType, labStatus }) => {
  const classes = useStyles();

  const { isLab, isSupplier } = useUserType(userType);

  const [openConfirmQuoteDialog, setOpenConfirmQuoteDialog] = useState(false);

  const [quotationsData, setQuotationsData] = React.useState([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState("");
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  useEffect(() => {
    if (!data.id) return;

    getQuotations(data.id);
  }, []);

  const handleConfirmQuoteDialogClose = () => {
    setOpenConfirmQuoteDialog(false);
  };

  const handleConfirmQuoteSubmit = () => {
    if (isDisabledConfirmButton) return;

    setIsDisabledConfirmButton(true);

    atnConfirmQuotation({
      submissionId: data.id,
      quotationId: selectedQuoteId,
    }).then(({ data }) => {
      setIsDisabledConfirmButton(false);

      if (data) {
        setOpenConfirmQuoteDialog(false);
        window.location.reload();
      }
    });
  };

  const handleConfirmQuote = (taskType, id) => {
    if (
      [
        TASK_TYPE_ENUM.QT,
        TASK_TYPE_ENUM.PST,
        TASK_TYPE_ENUM.ST,
        TASK_TYPE_ENUM.STD,
        TASK_TYPE_ENUM.PAC,
      ].includes(taskType)
    ) {
      history.push(`/supplier/submissions/${data.id}/confirmQuote/${id}`);
    } else if (
      [
        TASK_TYPE_ENUM.SS,
        TASK_TYPE_ENUM.SPU,
        TASK_TYPE_ENUM.SPUD,
        TASK_TYPE_ENUM.DPI,
        TASK_TYPE_ENUM.FRI,
        TASK_TYPE_ENUM.FRID,
      ].includes(taskType)
    ) {
      setSelectedQuoteId(id);
      setOpenConfirmQuoteDialog(true);
    }
  };

  const getQuotations = (id) => {
    atnGetSubmissionDetailsQuotations({ data: { id }, type: userType }).then((result) => {
      setQuotationsData(result?.data || []);
    });
  };

  const HeaderQuoteSection = ({ data: headerData = {} }) => {
    const {
      id,
      lab: label,
      status,
      totalPrice: amount,
      expectedCompletionDate,
      inspectionTaskDetail,
      taskType,
      currency,
      isPrepayment,
    } = headerData;

    return (
      <Box className={classes.header}>
        <Box className={classnames(classes.left, classes.grayText)}>
          <Box display="flex">
            <Box className={classnames(classes.title, classes.blackText)}>
              {textOrUnknown(label)}
            </Box>
            {isPrepayment && <Box className={classes.prepaymentContainer}>Prepayment</Box>}
          </Box>
          <Typography className={classnames(classes.subTitle, classes.greyText)}>
            {textOrUnknown(
              _get(QUOTE_STATUS[data?.isLabConfirm ? "agreeToProceed" : status], "label"),
            )}
          </Typography>
          {/* <Status value={status} className={{ text: classes.subTitle }} withText withIcon={false} /> */}
        </Box>
        <Box className={classes.right}>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography className={classnames(classes.title, classes.blackText)}>
              {textOrUnknown(`${amount} ${currency}`)}
            </Typography>

            <Typography className={classes.text}>
              {[
                TASK_TYPE_ENUM.FRI,
                TASK_TYPE_ENUM.FRID,
                TASK_TYPE_ENUM.DPI,
                TASK_TYPE_ENUM.SPU,
                TASK_TYPE_ENUM.SPUD,
              ].includes(taskType) &&
                `Expected Inspection Date: ${textOrUnknown(
                  getDateFormat(inspectionTaskDetail.inspectionDateFrom),
                )}`}
            </Typography>
          </Box>

          {isSupplier &&
            status === TASK_STATUS_ENUM.PENDING &&
            data.status !== TASK_STATUS_ENUM.CANCELLED && (
              <Box
                pl={4}
                className={classnames({
                  [classes.hideButton]: status === TASK_STATUS_ENUM.ACCEPTED,
                })}
              >
                <Button onClick={() => handleConfirmQuote(taskType, id)}>AGREE TO PROCEED</Button>
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  const ContentQuoteSection = ({ data: contentData }) => {
    const { testPlans, lastUpdated, ...rest } = contentData;

    return (
      <>
        <Divider />
        <Box className={classes.content}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            Test Plan
          </Typography>
          <TestPlanTable data={{ testPlans, ...rest }} taskType={taskType} />

          <Typography className={classnames(classes.title, classes.blackText)}>
            Other Details
          </Typography>
          <OtherDetails data={rest} taskType={taskType} />

          <Divider />

          <Box pt={2}>
            <Typography className={classes.text}>
              Last Updated: <br />
              {textOrUnknown(getDateFormat(lastUpdated))}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const AddQuoteButton = ({ onClick, ...props }) => (
    <Button onClick={onClick} {...props}>
      Make A Quote
    </Button>
  );

  return (
    <>
      {quotationsData.length === 0 ? (
        <Box className={classes.noQuoteContainer}>
          {isLab ? (
            <Box className={classes.noQuoteContent}>
              {labStatus === TASK_STATUS_ENUM.REJECTED ||
              data.status === TASK_STATUS_ENUM.CANCELLED ? (
                <h1>Closed</h1>
              ) : (
                <AddQuoteButton
                  onClick={() => history.push(`/lab/submissions/${data.id}/quotation`)}
                />
              )}
            </Box>
          ) : (
            <Box className={classes.noQuoteContent}>
              <h1>No Quotation</h1>
            </Box>
          )}
        </Box>
      ) : (
        quotationsData.map((item) => (
          <Card
            key={item.id}
            classes={{ root: classes.container }}
            collapseComponent={<HeaderQuoteSection data={item} />}
            expandComponent={<ContentQuoteSection data={item} />}
            expanded
          />
        ))
      )}

      <ConfirmModal
        title="Confirm Quote"
        contentText="Status will be changed to “Booking Reserved” once Lab confirms your booking; meanwhile,
        your booking is not successful yet."
        submitText="CONFIRM"
        closeText="BACK"
        open={openConfirmQuoteDialog}
        onClose={handleConfirmQuoteDialogClose}
        onSubmit={handleConfirmQuoteSubmit}
        isDisabledSubmit={isDisabledConfirmButton}
      />
    </>
  );
};

export default withRouter(Quote);
