import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import Modal from "@material-ui/core/Modal";
import MomentUtils from "@date-io/moment";
import { DatePicker as Picker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import GifLoading from "components/display/GifLoading";
import Select from "components/input/Select";
import SearchSection from "components/searchBar/SearchSection";
import ItemListTab from "./ItemListTab";
import ItemListTable from "components/table/ItemListTable/index";
import TextInput from "components/input/TextInput";
import DatePicker from "components/input/DatePicker";
import { formatHeader, formatRow, updateHeader } from "utils/formatItemTableData";
import { getUnselectedColumns } from "utils/tableHeader";
import { downloadFile, getFileSourceUrl } from "utils/files";

import {
  atnBulkAddItemsTask,
  atnBulkDeleteItemsTask,
  atnBulkEditItems,
  atnCreateCustomHeader,
  atnDeleteCustomHeader,
  atnDownloadProductSpec,
  atnExportItemListCsv,
  atnGetCategoryPrograms,
  atnGetItemListData,
  atnGetItemListHeader,
  atnUpdateCustomHeader,
} from "actions/itemActions";

import { atnGetPrograms } from "actions/commonActions";

import { atnDownloadFile } from "actions/fileActions";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  topContainer: {
    height: 80,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #AAAAAA",
  },
  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  tableInfo: {
    fontWeight: "normal",
    fontSize: 10,
    paddingLeft: 10,
  },
  exportAll: {
    fontWeight: 500,
    fontSize: 10,
    paddingLeft: 20,
    cursor: "pointer",
    color: "#005EFF",
  },
  tableContainer: {
    paddingTop: 10,
    width: "fit-content",
  },
  manageColumnDialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 850,
        width: 850,
        minHeight: 500,
      },
    },
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
      padding: "4px 6px",
    },
  },
  dialogMessage: {
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  },
  dialogContent: {
    padding: "10px 30px",
    borderBottom: "none",
  },
  dialogInfo: {
    fontSize: 10,
  },
  dialogSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 30,
  },
  dialogSelectText: {
    fontSize: 12,
    fontWeight: "normal",
    paddingTop: 5,
  },
  dialogSelect: {
    // paddingTop: 6,
    // fontSize: 12,
    // fontWeight: 'normal',
    width: 300,
  },
  dialogEditContainer: {
    borderTop: "1px solid #AAAAAA",
    marginTop: 110,
    minHeight: 450,
  },
  dialogEditSection: {
    width: 400,
  },
  dialogConfirmButton: {
    borderRadius: 2,
    height: 36,
  },
  datePicker: {
    paddingTop: 5,
    "& .MuiInputBase-root": {
      "& input": {
        padding: 11,
      },
    },
  },
  imageGalleryContainer: {
    margin: "100px 100px 0 100px",
  },
  manageColumnContainer: {
    paddingTop: 30,
  },
  manageColumnBoxTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
  },
  manageColumnBox: {
    width: 320,
    border: "1px solid #000000",
    borderRadius: 4,
  },
  addTaskTypeButton: {
    marginTop: 8,
  },
  manageColumnButton: {
    cursor: "pointer",
    fontSize: 12,
  },
  manageColumnMenu: {
    zIndex: 999,
  },
  manageColumnMenuList: {
    width: 280,
  },
  manageColumnMenuItemText: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  manageColumnMenuItem: {
    position: "relative",
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
  },
  manageColumnMenuItemWithBorder: {
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
  },
  manageColumnMenuItemEditButton: {
    position: "absolute",
    right: 30,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnMenuItemDeleteButton: {
    position: "absolute",
    right: 10,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnDialogButtonContainer: {
    paddingTop: 50,
  },
  handledByInput: {
    width: 300,
  },
  currentHeaderLabel: {
    paddingRight: 3,
    color: "#A5CE2D",
  },
}));

const WbaItemList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingTable, setLoadingTable] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [unselectedItems, setUnselectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItemDetailsData, setSelectedItemDetailsData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [fullItemList, setFullItemList] = useState([]);
  const [hasEverydayItem, setHasEverydayItem] = useState(false);
  const [hasSeasonalItem, setHasSeasonalItem] = useState(false);
  const [headerFilter, setHeaderFilter] = useState({});
  const [commonTaskTypeList, setCommonTaskTypeList] = useState([]);

  const [deleteHeaderData, setDeleteHeaderData] = useState({
    id: "",
    isCurrent: false,
    name: "",
    index: "",
  });

  const [openDeleteHeaderDialog, setOpenDeleteHeaderDialog] = useState(false);

  const [editHeaderData, setEditHeaderData] = useState({
    id: "",
  });

  const [openEditManageColumnDialog, setOpenEditManageColumnDialog] = useState(false);

  const categoryList = [
    {
      value: "",
      label: "Select Category",
    },
    {
      value: "seasonal",
      label: "Seasonal",
    },
    {
      value: "everyday",
      label: "Everyday",
    },
  ];

  const [addTaskList, setAddTaskList] = useState([
    {
      value: "QT",
      label: "Quality Test (Seasonal Only)",
    },
    {
      value: "PST",
      label: "Pre-Safety Test (Seasonal Only)",
    },
    {
      value: "SS",
      label: "Sample Sealing (Seasonal Only)",
    },
    {
      value: "SPU",
      label: "Sample Pick Up",
    },
    {
      value: "ST",
      label: "Safety Test",
    },
    {
      value: "DPI",
      label: "During Production Inspection (Seasonal Only)",
    },
    {
      value: "FRI",
      label: "Final Random Inspection",
    },
  ]);

  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [progressNumber, setProgressNumber] = useState({
    all: 0,
    completed: 0,
    notOpen: 0,
    onGoing: 0,
  });

  const [selectedFilter, setSelectedFilter] = useState({
    brand: [],
    supplier: [],
    programName: [],
    pacStatus: [],
    qtStatus: [],
    pstStatus: [],
    ssStatus: [],
    spuStatus: [],
    stStatus: [],
    dpiStatus: [],
    friStatus: [],
    labName: [],
    result: [],
    factory: [],
  });
  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });

  const [searchSectionData, setSearchSectionData] = useState({
    category: "",
    programName: "",
    programYear: "",
    keyword: "",
  });

  const [bulkEditTaskData, setBulkEditTaskData] = useState({
    property: "",
    taskType: "",
    newDate: "",
  });

  const [bulkAddTaskData, setBulkAddTaskData] = useState({
    taskType: "",
    remark: "",
    assignTo: "lab",
    deadLine: "",
    shipByDate: "",
  });
  const [bulkDeleteTaskType, setBulkDeleteTaskType] = useState("");

  const allHeaderColumns = [
    "productName",
    "brand",
    "programName",
    "supplier",
    "factory",
    "pacStatus",
    "pacValidityDate",
    "pacPlannedDate",
    "pacWbaDeadline",
    "pacEcd",
    "qtStatus",
    "qtPlannedDate",
    "qtWbaDeadline",
    "qtEcd",
    "pstStatus",
    "pstPlannedDate",
    "pstWbaDeadline",
    "pstEcd",
    "ssStatus",
    "ssPlannedDate",
    "ssWbaDeadline",
    "ssEcd",
    "spuStatus",
    "spuPlannedDate",
    "spuWbaDeadline",
    "spuEcd",
    "stStatus",
    "validityDate",
    "validityPeriod",
    "stPlannedDate",
    "stWbaDeadline",
    "stEcd",
    "dpiStatus",
    "dpiPlannedDate",
    "dpiWbaDeadline",
    "dpiEcd",
    "friStatus",
    "selfInspectionValidityDate",
    "friPlannedDate",
    "friWbaDeadline",
    "friEcd",
    "shipByDate",
    // "labName",
    // "result"
  ];
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([
    "productName",
    "brand",
    "programName",
    "supplier",
    "factory",
    "pacStatus",
    "qtStatus",
    "pstStatus",
    "ssStatus",
    "spuStatus",
    "stStatus",
    "dpiStatus",
    "friStatus",
    "shipByDate",
  ]);
  const [right, setRight] = useState(getUnselectedColumns(allHeaderColumns, left));
  const [manageColumnData, setManageColumnData] = useState({
    name: "",
    header: "",
  });

  const [galleryImages, setGalleryImages] = useState([]);
  const [tab, setTab] = useState(0);

  const [openImageGallery, setOpenImageGallery] = useState(false);

  const [categoryPrograms, setCategoryPrograms] = useState({});
  const [programList, setProgramList] = useState([
    {
      label: "Select Program",
      value: "",
    },
    {
      label: "Xmas",
      value: "Xmas",
    },
  ]);

  const [programYears, setProgramYears] = useState([]);

  const actionMenuItems = [
    {
      name: "download_product_spec",
      type: "download_product_spec",
      label: `Bulk Download ${
        isSelectedAll
          ? parseInt(pageData.totalRecords) - unselectedItems.length
          : selectedItem.length
      } Product Spec`,
    },
  ];

  const [manageColumnMenuItems, setManageColumnMenuItems] = useState([]);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [openManageColumnMenu, setOpenManageColumnMenu] = useState(false);
  const anchorRef = useRef(null);

  const handleManageColumnMenuToggle = () => {
    setOpenManageColumnMenu((prevOpen) => !prevOpen);
  };

  const handleManageColumnMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenManageColumnMenu(false);
  };

  const prevOpen = useRef(openManageColumnMenu);

  useEffect(() => {
    if (prevOpen.current === true && openManageColumnMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openManageColumnMenu;
  }, [openManageColumnMenu]);

  const handleActionItemClick = (index) => {
    setOpenManageColumnMenu(false);
    setHeaderData(formatHeader(manageColumnMenuItems[index].header, t, headerFilter));
    setManageColumnMenuItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item.isCurrent = true;
        } else {
          item.isCurrent = false;
        }

        return item;
      });
    });

    setItemList(formatRow(manageColumnMenuItems[index].header, fullItemList, t));

    atnUpdateCustomHeader({
      id: manageColumnMenuItems[index].id,
      isCurrent: true,
    });
  };

  useEffect(() => {
    setLoadingTable(true);

    atnGetCategoryPrograms().then(({ data, message }) => {
      if (data) {
        setCategoryPrograms(data);
      }
    });

    atnGetItemListHeader({
      type: 1,
    }).then(({ data, message }) => {
      // setHeaderDataCSV(data[data.length - 1].header);
      setCustomHeaders(data);

      let tempCustomHeaders = [];
      let currentHeader = null;

      for (let i = 0; i < data.length; i++) {
        if (data[i].isCurrent) {
          currentHeader = data[i];
        }

        tempCustomHeaders.push({
          id: data[i].id,
          isCurrent: data[i].isCurrent,
          name: data[i].name,
          type: "custom_col",
          label: data[i].name,
          header: data[i].header,
        });
      }

      setHeaderData(formatHeader(currentHeader ? currentHeader.header : data[0].header, t));

      setManageColumnMenuItems(tempCustomHeaders);

      let conditions = {
        ...searchConditions,
        currentPage: pageData.currentPage,
        rowsPerPage: pageData.rowsPerPage,
        filters: [],
        search: searchSectionData.keyword,
      };

      atnGetItemListData({
        type: 1,
        data: conditions,
      }).then(({ data: itemRow, message: itemMessage }) => {
        if (!itemRow) return;

        setFullItemList(
          itemRow.list.map((item) => {
            item.shipByDate = item.category === "everyday" ? null : item.shipByDate;
            return item;
          }),
        );

        setHeaderFilter(itemRow.filter);
        setHeaderData(
          formatHeader(currentHeader ? currentHeader.header : data[0].header, t, itemRow.filter),
        );
        setItemList(
          formatRow(currentHeader ? currentHeader.header : data[0].header, itemRow.list, t),
        );
        // setItemListCSV(itemRow.list);
        setProgressNumber(itemRow.progressNumber);
        let totalRecords = itemRow.progressNumber.all || 0;
        let totalPages = Math.ceil((itemRow.progressNumber.all || 0) / itemRow.page.rowsPerPage);

        setPageData({
          ...itemRow.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setInitLoading(false);
        setLoadingTable(false);
      });
    });

    atnGetPrograms({}).then(({ data, message }) => {
      if (!data) return;

      let programs = [
        {
          label: "Select Program",
          value: "",
        },
      ];

      for (let i = 0; i < data.length; i++) {
        programs.push({
          label: data[i].programName,
          value: data[i].programName,
        });
      }

      setProgramList(programs);
    });

    const year = moment().year();
    const startYear = 2019;

    const programYearData = [
      {
        label: "Select Year",
        value: "",
      },
    ];

    for (let i = startYear; i < year + 3; i++) {
      programYearData.push({
        label: i,
        value: i,
      });
    }

    setProgramYears(programYearData);
  }, []);

  const searchItems = (page, rowsPerPage, isKeepHeader) => {
    if (loadingTable) {
      return;
    }

    setLoadingTable(true);

    let filters = [];

    if (searchSectionData.category) {
      filters.push({
        name: "category",
        value: [searchSectionData.category],
      });
    }

    if (searchSectionData.programName) {
      filters.push({
        name: "programName",
        value: [searchSectionData.programName],
      });
    }

    if (searchSectionData.programYear) {
      filters.push({
        name: "programYear",
        value: [searchSectionData.programYear],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["not_acknowledge"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: rowsPerPage ? rowsPerPage : pageData.rowsPerPage,
      filters: filters,
      search: searchSectionData.keyword,
    };

    atnGetItemListData({
      type: 1,
      data: conditions,
    }).then(({ data, message }) => {
      if (data) {
        if (!isKeepHeader) {
          setHeaderFilter(data.filter);
          setHeaderData(updateHeader(headerData, data.filter, t));
        }

        setFullItemList(
          data.list.map((item) => {
            item.shipByDate = item.category === "everyday" ? null : item.shipByDate;
            return item;
          }),
        );

        setItemList(formatRow(headerData, data.list, t));
        // setItemListCSV(data.list);
        setProgressNumber(data.progressNumber);

        let totalRecords = 0;

        if (tab === 0) {
          totalRecords = data.progressNumber.all || 0;
        } else if (tab === 1) {
          totalRecords = data.progressNumber.notOpen || 0;
        } else if (tab === 2) {
          totalRecords = data.progressNumber.onGoing || 0;
        } else if (tab === 3) {
          totalRecords = data.progressNumber.completed || 0;
        }

        let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    if (initLoading) {
      return;
    }
    searchItems();
  }, [
    // pageData.currentPage,
    // pageData.rowsPerPage,
    tab,
    // searchConditions.orderBy,
    // searchConditions.order,
    selectedFilter.brand.length,
    selectedFilter.supplier.length,
    selectedFilter.programName.length,
    selectedFilter.qtStatus.length,
    selectedFilter.pstStatus.length,
    selectedFilter.ssStatus.length,
    selectedFilter.spuStatus.length,
    selectedFilter.stStatus.length,
    selectedFilter.dpiStatus.length,
    selectedFilter.friStatus.length,
    selectedFilter.labName.length,
    selectedFilter.result.length,
    selectedFilter.factory.length,
  ]);

  useEffect(() => {
    if (initLoading) {
      return;
    }
    searchItems(null, null, true);
  }, [
    // pageData.currentPage,
    // pageData.rowsPerPage,
    searchConditions.orderBy,
    searchConditions.order,
  ]);

  const handleSearchDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "category") {
      let tempPrograms = [];

      if (value === "seasonal") {
        tempPrograms = categoryPrograms.seasonalPrograms;
      } else if (value === "everyday") {
        tempPrograms = categoryPrograms.everydayPrograms;
      }

      let programs = [
        {
          label: "Select Program",
          value: "",
        },
      ];

      for (let i = 0; i < tempPrograms.length; i++) {
        programs.push({
          label: tempPrograms[i].programName,
          value: tempPrograms[i].programName,
        });
      }

      setProgramList(programs);

      setSearchSectionData((prevState) => ({
        ...prevState,
        [name]: value,
        programName: "",
      }));
    } else {
      setSearchSectionData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSearch = () => {
    searchItems(1);
  };

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));

    searchItems(newPage, pageData.rowsPerPage);
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));

    searchItems(pageData.currentPage, value);
  };

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].concat(filterItemValue),
      }));
    } else {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
      }));
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleSelectAll = (e) => {
    setIsSelectedAll(e.target.checked);
    setUnselectedItems([]);
    setSelectedItem([]);
  };

  const handleItemSelect = (e, itemId) => {
    if (e.target.checked) {
      if (isSelectedAll) {
        if (unselectedItems.indexOf(itemId) >= 0) {
          setUnselectedItems((prevState) => {
            return prevState.filter((item) => {
              return item !== itemId;
            });
          });
        }
      } else {
        if (selectedItem.indexOf(itemId) === -1) {
          setSelectedItem((prevState) => {
            return prevState.concat(itemId);
          });

          let itemDetails = {};

          for (let i = 0; i < fullItemList.length; i++) {
            if (fullItemList[i].id === itemId) {
              itemDetails = {
                id: fullItemList[i].id,
                shipByDate: fullItemList[i].shipByDate,
                item: fullItemList[i],
              };
            }
          }

          setSelectedItemDetailsData((prevState) => {
            return prevState.concat(itemDetails);
          });
        }
      }
    } else {
      if (isSelectedAll) {
        if (unselectedItems.indexOf(itemId) === -1) {
          if (unselectedItems.length === parseInt(pageData.totalRecords) - 1) {
            setIsSelectedAll(false);
            setUnselectedItems([]);
            setSelectedItem([]);
          } else {
            setUnselectedItems((prevState) => {
              return prevState.concat(itemId);
            });
          }
        }
      } else {
        if (selectedItem.indexOf(itemId) >= 0) {
          setSelectedItem((prevState) => {
            return prevState.filter((item) => {
              return item !== itemId;
            });
          });

          setSelectedItemDetailsData((prevState) => {
            return prevState.filter((item) => {
              return item.id !== itemId;
            });
          });
        }
      }
    }
  };

  const handleViewImage = (images) => {
    if (images.length === 0) {
      return;
    }

    const downloadImage = (location) => {
      return new Promise((resolve, reject) => {
        atnDownloadFile(location).then(async (res) => {
          if (res) {
            resolve(getFileSourceUrl(res));
          } else {
            reject();
          }
        });
      });
    };

    let downloadImageFunctionArray = [];

    for (let i = 0; i < images.length; i++) {
      downloadImageFunctionArray.push(downloadImage(images[i].location));
    }

    Promise.all(downloadImageFunctionArray).then((result) => {
      let formattedImages = [];

      for (let i = 0; i < result.length; i++) {
        formattedImages.push({
          original: result[i],
          thumbnail: result[i],
        });
      }

      setGalleryImages(formattedImages);
      setOpenImageGallery(true);
    });
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageColumnDialog, setOpenManageColumnDialog] = useState(false);
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [openDeleteTaskDialog, setOpenDeleteTaskDialog] = useState(false);

  const handleSelectedTaskCommonTaskType = () => {
    let taskTypes = ["PAC", "QT", "PST", "SS", "SPU", "ST", "DPI", "FRI"];
    const CANCELLED = "cancelled";

    for (let i = 0; i < selectedItemDetailsData.length; i++) {
      if (
        (!selectedItemDetailsData[i].item.pacStatus ||
          selectedItemDetailsData[i].item.pacStatus === CANCELLED) &&
        taskTypes.indexOf("PAC") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("PAC"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.qtStatus ||
          selectedItemDetailsData[i].item.qtStatus === CANCELLED) &&
        taskTypes.indexOf("QT") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("QT"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.pstStatus ||
          selectedItemDetailsData[i].item.pstStatus === CANCELLED) &&
        taskTypes.indexOf("PST") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("PST"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.ssStatus ||
          selectedItemDetailsData[i].item.ssStatus === CANCELLED) &&
        taskTypes.indexOf("SS") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("SS"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.spuStatus ||
          selectedItemDetailsData[i].item.spuStatus === CANCELLED) &&
        taskTypes.indexOf("SPU") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("SPU"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.stStatus ||
          selectedItemDetailsData[i].item.stStatus === CANCELLED) &&
        taskTypes.indexOf("ST") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("ST"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.dpiStatus ||
          selectedItemDetailsData[i].item.dpiStatus === CANCELLED) &&
        taskTypes.indexOf("DPI") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("DPI"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.friStatus ||
          selectedItemDetailsData[i].item.friStatus === CANCELLED) &&
        taskTypes.indexOf("FRI") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("FRI"), 1);
      }
    }

    let taskTypeList = [];

    for (let i = 0; i < taskTypes.length; i++) {
      if (taskTypes[i] === "PAC") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Everyday Only)",
        });
      } else if (taskTypes[i] === "QT") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "PST") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "SS") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "SPU") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      } else if (taskTypes[i] === "ST") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      } else if (taskTypes[i] === "DPI") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "FRI") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      }
    }

    setCommonTaskTypeList(taskTypeList);
  };

  const handleExportAll = () => {
    let filters = [];

    if (searchSectionData.category) {
      filters.push({
        name: "category",
        value: [searchSectionData.category],
      });
    }

    if (searchSectionData.programName) {
      filters.push({
        name: "programName",
        value: [searchSectionData.programName],
      });
    }

    if (searchSectionData.programYear) {
      filters.push({
        name: "programYear",
        value: [searchSectionData.programYear],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["not_acknowledge"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let formattedHeader = [];

    for (let i = 0; i < headerData.length; i++) {
      if (headerData[i].name === "productName") {
        formattedHeader.push("wicNumber");
        formattedHeader.push("productName");
      } else if (headerData[i].name === "factory") {
        formattedHeader.push("factoryName");
        formattedHeader.push("factoryCountry");
        formattedHeader.push("factoryState");
        formattedHeader.push("factoryCity");
      } else if (headerData[i].name === "result") {
        formattedHeader.push("masterWicResult");
      } else {
        formattedHeader.push(headerData[i].name);
      }
    }

    let conditions = {
      ...searchConditions,
      currentPage: 1,
      rowsPerPage: 99999,
      filters: filters,
      search: searchSectionData.keyword,
      header: formattedHeader.toString(),
    };

    atnExportItemListCsv(conditions).then(async (data) => {
      downloadFile(data, "Item_List.csv");
    });
  };

  const handleOpenDialog = (type) => {
    if (type === "edit") {
      // if has everyday remove ship by date
      handleSelectedTaskCommonTaskType();

      let tempHasEverydayItem = false;
      let tempHasSeasonalItem = false;

      for (let i = 0; i < selectedItemDetailsData.length; i++) {
        if (selectedItemDetailsData[i].item.category === "everyday") {
          tempHasEverydayItem = true;
        } else if (selectedItemDetailsData[i].item.category === "seasonal") {
          tempHasSeasonalItem = true;
        }
      }

      setHasEverydayItem(tempHasEverydayItem);
      setHasSeasonalItem(tempHasSeasonalItem);

      setOpenEditDialog(true);
    } else if (type === "add") {
      handleSelectedTaskCommonTaskType();
      let tempHasEverydayItem = false;
      let tempHasSeasonalItem = false;
      let shipByDate = "";

      for (let i = 0; i < selectedItemDetailsData.length; i++) {
        if (shipByDate) {
          if (moment(selectedItemDetailsData[i].shipByDate) < moment(shipByDate)) {
            shipByDate = selectedItemDetailsData[i].shipByDate;
          }
        } else {
          shipByDate = selectedItemDetailsData[i].shipByDate;
        }

        if (selectedItemDetailsData[i].item.category === "everyday") {
          tempHasEverydayItem = true;
        } else if (selectedItemDetailsData[i].item.category === "seasonal") {
          tempHasSeasonalItem = true;
        }
      }

      setHasEverydayItem(tempHasEverydayItem);
      setHasSeasonalItem(tempHasSeasonalItem);

      if (tempHasEverydayItem && tempHasSeasonalItem) {
        setAddTaskList([
          {
            value: "PAC",
            label: "PAC (Everyday Only)",
          },
          {
            value: "QT",
            label: "Quality Test (Seasonal Only)",
          },
          {
            value: "PST",
            label: "Pre-Safety Test (Seasonal Only)",
          },
          {
            value: "SS",
            label: "Sample Sealing (Seasonal Only)",
          },
          {
            value: "SPU",
            label: "Sample Pick Up",
          },
          {
            value: "ST",
            label: "Safety Test",
          },
          {
            value: "DPI",
            label: "During Production Inspection (Seasonal Only)",
          },
          {
            value: "FRI",
            label: "Final Random Inspection",
          },
        ]);
      } else if (tempHasEverydayItem && !tempHasSeasonalItem) {
        setAddTaskList([
          {
            value: "PAC",
            label: "PAC (Everyday Only)",
          },
          {
            value: "SPU",
            label: "Sample Pick Up",
          },
          {
            value: "ST",
            label: "Safety Test",
          },
          {
            value: "FRI",
            label: "Final Random Inspection",
          },
        ]);
      } else if (!tempHasEverydayItem && tempHasSeasonalItem) {
        setAddTaskList([
          {
            value: "QT",
            label: "Quality Test (Seasonal Only)",
          },
          {
            value: "PST",
            label: "Pre-Safety Test (Seasonal Only)",
          },
          {
            value: "SS",
            label: "Sample Sealing (Seasonal Only)",
          },
          {
            value: "SPU",
            label: "Sample Pick Up",
          },
          {
            value: "ST",
            label: "Safety Test",
          },
          {
            value: "DPI",
            label: "During Production Inspection (Seasonal Only)",
          },
          {
            value: "FRI",
            label: "Final Random Inspection",
          },
        ]);
      }

      setBulkAddTaskData({
        taskType: "",
        remark: "",
        assignTo: "lab",
        deadLine: "",
        shipByDate: shipByDate,
      });
      setOpenAddTaskDialog(true);
    } else if (type === "delete") {
      handleSelectedTaskCommonTaskType();
      setBulkDeleteTaskType("");
      setOpenDeleteTaskDialog(true);
    } else if (type === "download_product_spec") {
      setLoadingTable(true);

      let filters = [];

      if (searchSectionData.category) {
        filters.push({
          name: "category",
          value: [searchSectionData.category],
        });
      }

      if (searchSectionData.programName) {
        filters.push({
          name: "programName",
          value: [searchSectionData.programName],
        });
      }

      if (searchSectionData.programYear) {
        filters.push({
          name: "programYear",
          value: [searchSectionData.programYear],
        });
      }

      Object.keys(selectedFilter).forEach((key) => {
        if (selectedFilter[key].length > 0) {
          filters.push({
            name: key,
            value: selectedFilter[key],
          });
        }
      });

      if (tab === 0) {
        // filters.push({
        //   name: "progress",
        //   value: ["all"]
        // });
      } else if (tab === 1) {
        filters.push({
          name: "progress",
          value: ["not_acknowledge"],
        });
      } else if (tab === 2) {
        filters.push({
          name: "progress",
          value: ["in_progress"],
        });
      } else if (tab === 3) {
        filters.push({
          name: "progress",
          value: ["completed"],
        });
      }

      let submitData = {
        listParams: {
          filters: filters,
          search: searchSectionData.keyword,
        },
        selectCondition: {
          isSelectedAll: isSelectedAll && unselectedItems.length === 0 && selectedItem.length === 0,
          selectedIds: selectedItem,
          unselectedIds: unselectedItems,
        },
      };

      atnDownloadProductSpec(submitData).then(async (data) => {
        setLoadingTable(false);
        downloadFile(data, "product_spec.zip");
      });
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setBulkEditTaskData({
      property: "",
      taskType: "",
      newDate: "",
    });
  };

  const handleCloseManageColumnDialog = () => {
    setOpenManageColumnDialog(false);
  };

  const handleSubmitManageColumn = () => {
    let newHeader = [];

    for (let i = 0; i < allHeaderColumns.length; i++) {
      if (left.indexOf(allHeaderColumns[i]) >= 0) {
        newHeader.push(allHeaderColumns[i]);
      }
    }

    let submitData = {
      name: manageColumnData.name,
      header: newHeader.toString(),
      isCurrent: true,
    };

    atnCreateCustomHeader(submitData).then(({ data, message }) => {
      if (data) {
        setOpenManageColumnDialog(false);
        window.location.reload();
      }
    });
  };

  const handleCloseAddTaskDialog = () => {
    setOpenAddTaskDialog(false);
    setBulkAddTaskData({
      taskType: "",
      remark: "",
      assignTo: "lab",
      deadLine: "",
      shipByDate: "",
    });
  };

  const handleCloseDeleteTaskDialog = () => {
    setOpenDeleteTaskDialog(false);
    setBulkDeleteTaskType("");
  };

  const handleOpenManageColumnDialog = () => {
    setLeft([
      "productName",
      "brand",
      "supplier",
      "pacStatus",
      "qtStatus",
      "pstStatus",
      "ssStatus",
      "spuStatus",
      "stStatus",
      "dpiStatus",
      "friStatus",
      "shipByDate",
    ]);
    setRight(getUnselectedColumns(allHeaderColumns, left));
    setOpenManageColumnDialog(true);
  };

  const handleBulkEditDataChange = (e) => {
    const { name, value } = e.target;

    setBulkEditTaskData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBulkAddTaskDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "taskType") {
      setBulkAddTaskData((prevState) => ({
        ...prevState,
        [name]: value,
        assignTo: "lab",
      }));
    } else {
      setBulkAddTaskData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleBulkAddTaskDeadlineChange = (e) => {
    setBulkAddTaskData((prevState) => ({
      ...prevState,
      deadLine: e._d,
    }));
  };

  const handleBulkDeleteTaskTypeChange = (e, index) => {
    const { value } = e.target;

    setBulkDeleteTaskType(value);
  };

  const handleBulkEdit = () => {
    let change = {
      field:
        bulkEditTaskData.property === "deadLine"
          ? t(`task.${bulkEditTaskData.taskType}`) + ` ${SIMPLE_BUYER_NAME} Deadline`
          : "Ship By Date",
      action: "Update",
      before: "",
      after: bulkEditTaskData.newDate,
    };

    let everydayMasterWics = [];
    let seasonalMasterWics = [];

    for (let i = 0; i < selectedItemDetailsData.length; i++) {
      if (bulkEditTaskData.property === "deadLine") {
        change.field = t(`task.${bulkEditTaskData.taskType}`) + ` ${SIMPLE_BUYER_NAME} Deadline`;

        if (selectedItemDetailsData[i].item.category === "everyday") {
          if (
            bulkEditTaskData.taskType === "PAC" ||
            bulkEditTaskData.taskType === "ST" ||
            bulkEditTaskData.taskType === "SPU" ||
            bulkEditTaskData.taskType === "FRI"
          ) {
            if (bulkEditTaskData.taskType === "PAC") {
              change.before = selectedItemDetailsData[i].item.pacWbaDeadline;
            } else if (bulkEditTaskData.taskType === "SPU") {
              change.before = selectedItemDetailsData[i].item.spuWbaDeadline;
            } else if (bulkEditTaskData.taskType === "ST") {
              change.before = selectedItemDetailsData[i].item.stWbaDeadline;
            } else if (bulkEditTaskData.taskType === "FRI") {
              change.before = selectedItemDetailsData[i].item.friWbaDeadline;
            }
            everydayMasterWics.push({
              id: selectedItemDetailsData[i].item.id,
              changes: [JSON.parse(JSON.stringify(change))],
            });
          }
        } else if (selectedItemDetailsData[i].item.category === "seasonal") {
          if (bulkEditTaskData.taskType !== "PAC") {
            if (bulkEditTaskData.taskType === "QT") {
              change.before = selectedItemDetailsData[i].item.qtWbaDeadline;
            } else if (bulkEditTaskData.taskType === "PST") {
              change.before = selectedItemDetailsData[i].item.pstWbaDeadline;
            } else if (bulkEditTaskData.taskType === "SPU") {
              change.before = selectedItemDetailsData[i].item.spuWbaDeadline;
            } else if (bulkEditTaskData.taskType === "SS") {
              change.before = selectedItemDetailsData[i].item.ssWbaDeadline;
            } else if (bulkEditTaskData.taskType === "ST") {
              change.before = selectedItemDetailsData[i].item.stWbaDeadline;
            } else if (bulkEditTaskData.taskType === "DPI") {
              change.before = selectedItemDetailsData[i].item.dpiWbaDeadline;
            } else if (bulkEditTaskData.taskType === "FRI") {
              change.before = selectedItemDetailsData[i].item.friWbaDeadline;
            }
            seasonalMasterWics.push({
              id: selectedItemDetailsData[i].item.id,
              changes: [JSON.parse(JSON.stringify(change))],
            });
          }
        }
      } else if (bulkEditTaskData.property === "shipByDate") {
        change.field = "Ship By Date";

        if (selectedItemDetailsData[i].item.category === "seasonal") {
          if (bulkEditTaskData.taskType !== "PAC") {
            seasonalMasterWics.push({
              id: selectedItemDetailsData[i].item.id,
              changes: [JSON.parse(JSON.stringify(change))],
            });
          }
        }
      }
    }

    const handleBulkEditAction = (submitData) => {
      return new Promise((resolve, reject) => {
        atnBulkEditItems(submitData).then(({ data, message }) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        });
      });
    };

    let actionArray = [];

    if (everydayMasterWics.length > 0) {
      let everydayTaskType = bulkEditTaskData.taskType;

      if (bulkEditTaskData.taskType === "ST") {
        everydayTaskType = "STD";
      } else if (bulkEditTaskData.taskType === "SPU") {
        everydayTaskType = "SPUD";
      } else if (bulkEditTaskData.taskType === "FRI") {
        everydayTaskType = "FRID";
      }

      let submitData = {
        masterWics: everydayMasterWics,
        taskType: everydayTaskType,
      };

      if (bulkEditTaskData.property === "deadLine") {
        submitData.deadLine = bulkEditTaskData.newDate;
      } else if (bulkEditTaskData.property === "shipByDate") {
        submitData.shipByDate = bulkEditTaskData.newDate;
      }

      actionArray.push(handleBulkEditAction(submitData));
    }

    if (seasonalMasterWics.length > 0) {
      let submitData = {
        masterWics: seasonalMasterWics,
        taskType: bulkEditTaskData.taskType,
      };

      if (bulkEditTaskData.property === "deadLine") {
        submitData.deadLine = bulkEditTaskData.newDate;
      } else if (bulkEditTaskData.property === "shipByDate") {
        submitData.shipByDate = bulkEditTaskData.newDate;
      }

      actionArray.push(handleBulkEditAction(submitData));
    }

    Promise.all(actionArray).then((result) => {
      setOpenEditDialog(false);
      window.location.reload();
    });
  };

  const handleBulkAdd = () => {
    let change = {
      field: t(`task.${bulkAddTaskData.taskType}`),
      action: "Add Task",
      before: "",
      after: "New " + t(`task.${bulkAddTaskData.taskType}`),
    };

    let everydayMasterWics = [];
    let seasonalMasterWics = [];

    for (let i = 0; i < selectedItemDetailsData.length; i++) {
      if (selectedItemDetailsData[i].item.category === "everyday") {
        if (
          bulkAddTaskData.taskType === "PAC" ||
          bulkAddTaskData.taskType === "ST" ||
          bulkAddTaskData.taskType === "SPU" ||
          bulkAddTaskData.taskType === "FRI"
        ) {
          everydayMasterWics.push({
            id: selectedItemDetailsData[i].item.id,
            changes: [change],
          });
        }
      } else if (selectedItemDetailsData[i].item.category === "seasonal") {
        if (bulkAddTaskData.taskType !== "PAC") {
          seasonalMasterWics.push({
            id: selectedItemDetailsData[i].item.id,
            changes: [change],
          });
        }
      }
    }

    const handleBulkAddAction = (submitData) => {
      return new Promise((resolve, reject) => {
        atnBulkAddItemsTask(submitData).then(({ data, message }) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        });
      });
    };

    let actionArray = [];

    if (everydayMasterWics.length > 0) {
      let everydayTaskType = bulkAddTaskData.taskType;

      if (bulkAddTaskData.taskType === "ST") {
        everydayTaskType = "STD";
      } else if (bulkAddTaskData.taskType === "SPU") {
        everydayTaskType = "SPUD";
      } else if (bulkAddTaskData.taskType === "FRI") {
        everydayTaskType = "FRID";
      }

      let submitData = {
        masterWics: everydayMasterWics,
        taskType: everydayTaskType,
        remark: bulkAddTaskData.remark,
      };

      actionArray.push(handleBulkAddAction(submitData));
    }

    if (seasonalMasterWics.length > 0) {
      let submitData = {
        masterWics: seasonalMasterWics,
        taskType: bulkAddTaskData.taskType,
        remark: bulkAddTaskData.remark,
        assignTo: bulkAddTaskData.assignTo,
      };

      if (bulkAddTaskData.deadLine) {
        submitData.deadLine = moment(bulkAddTaskData.deadLine).format("YYYY-MM-DD");
      }

      actionArray.push(handleBulkAddAction(submitData));
    }

    Promise.all(actionArray).then((result) => {
      setOpenAddTaskDialog(false);
      window.location.reload();
    });
  };

  const handleBulkDelete = () => {
    let change = {
      field: t(`task.${bulkDeleteTaskType}`),
      action: "Delete Task",
      before: "",
      after: "Delete " + t(`task.${bulkDeleteTaskType}`),
    };

    let everydayMasterWics = [];
    let seasonalMasterWics = [];

    for (let i = 0; i < selectedItemDetailsData.length; i++) {
      if (selectedItemDetailsData[i].item.category === "everyday") {
        if (
          bulkDeleteTaskType === "PAC" ||
          bulkDeleteTaskType === "ST" ||
          bulkDeleteTaskType === "SPU" ||
          bulkDeleteTaskType === "FRI"
        ) {
          everydayMasterWics.push({
            id: selectedItemDetailsData[i].item.id,
            changes: [change],
          });
        }
      } else if (selectedItemDetailsData[i].item.category === "seasonal") {
        if (bulkDeleteTaskType !== "PAC") {
          seasonalMasterWics.push({
            id: selectedItemDetailsData[i].item.id,
            changes: [change],
          });
        }
      }
    }

    const handleBulkDeleteAction = (submitData) => {
      return new Promise((resolve, reject) => {
        atnBulkDeleteItemsTask(submitData).then(({ data, message }) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        });
      });
    };

    let actionArray = [];

    if (everydayMasterWics.length > 0) {
      let everydayTaskType = bulkDeleteTaskType;

      if (bulkDeleteTaskType === "ST") {
        everydayTaskType = "STD";
      } else if (bulkDeleteTaskType === "SPU") {
        everydayTaskType = "SPUD";
      } else if (bulkDeleteTaskType === "FRI") {
        everydayTaskType = "FRID";
      }

      let submitData = {
        masterWics: everydayMasterWics,
        taskType: everydayTaskType,
      };

      actionArray.push(handleBulkDeleteAction(submitData));
    }

    if (seasonalMasterWics.length > 0) {
      let submitData = {
        masterWics: seasonalMasterWics,
        taskType: bulkDeleteTaskType,
      };

      actionArray.push(handleBulkDeleteAction(submitData));
    }

    Promise.all(actionArray).then((result) => {
      setOpenDeleteTaskDialog(false);
      window.location.reload();
    });
  };

  // manage column start
  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleManageColumnDataChange = (e) => {
    const { name, value } = e.target;

    setManageColumnData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const leftList = (items) => (
    <>
      <Box className={classes.manageColumnBoxTitle}>Selected Properties</Box>
      <Paper className={classes.manageColumnBox}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
                disabled={value === "productName" || value === "brand" || value === "supplier"}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                <ListItemText id={labelId} primary={t(`tableHeader.${value}`)} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  const rightList = (items) => (
    <>
      <Box className={classes.manageColumnBoxTitle}>Unselected Properties</Box>
      <Paper className={classes.manageColumnBox}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                <ListItemText id={labelId} primary={t(`tableHeader.${value}`)} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  const handleEditHeader = (e, index, item) => {
    e.stopPropagation();

    let selectedItem = [];

    for (let i = 0; i < customHeaders[index].header.length; i++) {
      selectedItem.push(customHeaders[index].header[i].name);
    }

    setLeft(selectedItem);
    setRight(getUnselectedColumns(allHeaderColumns, selectedItem));

    setEditHeaderData({
      id: item.id,
    });

    setOpenEditManageColumnDialog(true);
  };

  const handleDeleteHeaderDialogOpen = (e, index, item) => {
    e.stopPropagation();

    setDeleteHeaderData({
      id: item.id,
      isCurrent: item.isCurrent,
      name: item.name,
      index: index,
    });

    setOpenDeleteHeaderDialog(true);
  };

  const handleCloseDeleteHeaderDialog = () => {
    setOpenDeleteHeaderDialog(false);
    setDeleteHeaderData({
      id: "",
      isCurrent: false,
      name: "",
      index: "",
    });
  };

  const handleDeleteHeader = () => {
    atnDeleteCustomHeader({
      headerId: deleteHeaderData.id,
    }).then(({ data, message }) => {
      if (data) {
        setOpenDeleteHeaderDialog(false);

        setDeleteHeaderData({
          id: "",
          isCurrent: false,
          name: "",
          index: "",
        });

        let tempCustomHeaders = JSON.parse(JSON.stringify(manageColumnMenuItems));
        let newCustomHeaders = [];

        for (let i = 0; i < tempCustomHeaders.length; i++) {
          if (tempCustomHeaders[i].id !== deleteHeaderData.id) {
            newCustomHeaders.push(tempCustomHeaders[i]);
          }
        }

        if (deleteHeaderData.isCurrent) {
          setHeaderData(formatHeader(newCustomHeaders[0].header, t, headerFilter));
          newCustomHeaders[0].isCurrent = true;

          setItemList(formatRow(newCustomHeaders[0].header, fullItemList, t));

          atnUpdateCustomHeader({
            id: newCustomHeaders[0].id,
            isCurrent: true,
          });
        }

        setManageColumnMenuItems(newCustomHeaders);
      } else {
        alert(message);
      }
    });
  };

  const handleCloseEditManageColumnDialog = () => {
    setOpenEditManageColumnDialog(false);
    setEditHeaderData({
      id: "",
    });
  };

  const handleEditManageColumn = () => {
    let newHeader = [];

    for (let i = 0; i < allHeaderColumns.length; i++) {
      if (left.indexOf(allHeaderColumns[i]) >= 0) {
        newHeader.push(allHeaderColumns[i]);
      }
    }

    atnUpdateCustomHeader({
      id: editHeaderData.id,
      header: newHeader.toString(),
    }).then(({ data, message }) => {
      if (data) {
        window.location.reload();
      } else {
        alert(message);
      }
    });
  };
  // manage column end

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleOpenItem = (id) => {
    window.open(`/buyer_sourcing/items/${id}`);
  };

  return (
    <Box className={classes.root}>
      {loadingTable && <GifLoading />}

      <Box className={classes.topContainer}>
        <SearchSection
          title="Item List"
          categoryList={categoryList}
          programList={programList}
          programYearList={programYears}
          searchSectionData={searchSectionData}
          handleSearchDataChange={handleSearchDataChange}
          handleSearch={handleSearch}
          hasActionMenu={true}
          selectedItemNumber={
            isSelectedAll
              ? parseInt(pageData.totalRecords) - unselectedItems.length
              : selectedItem.length
          }
          actionMenuItems={actionMenuItems}
          handleOpenDialog={handleOpenDialog}
        />
      </Box>

      <Box>
        <ItemListTab tab={tab} progressNumber={progressNumber} handleTabChange={handleTabChange} />
      </Box>

      <Box className={classes.contentContainer}>
        <Box display="flex">
          <Box display="flex" flexGrow={1}>
            <Typography className={classes.tableTitle} variant="body1">
              {tab === 0 && <>Viewing All Items</>}
              {tab === 1 && <>Viewing Not Open Items</>}
              {tab === 2 && <>Viewing Ongoing Items</>}
              {tab === 3 && <>Viewing Completed Items</>}
            </Typography>
            <Typography className={classes.tableInfo} variant="body1">
              Showing{" "}
              {pageData.totalRecords > 0
                ? (pageData.currentPage - 1) * pageData.rowsPerPage + 1
                : 0}{" "}
              -{" "}
              {pageData.currentPage * pageData.rowsPerPage > pageData.totalRecords
                ? pageData.totalRecords
                : pageData.currentPage * pageData.rowsPerPage}{" "}
              of {pageData.totalRecords}
            </Typography>
          </Box>

          <Box>
            <Box
              className={classes.manageColumnButton}
              ref={anchorRef}
              aria-controls={openManageColumnMenu ? "action-menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleManageColumnMenuToggle}
            >
              Manage Column
            </Box>
            <Popper
              className={classes.manageColumnMenu}
              open={openManageColumnMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="top-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleManageColumnMenuClose}>
                      <MenuList
                        // autoFocusItem={openManageColumnMenu}
                        id="action-menu-list-grow"
                        className={classes.manageColumnMenuList}
                      >
                        <MenuItem
                          className={classes.manageColumnMenuItemWithBorder}
                          onClick={() => handleOpenManageColumnDialog()}
                        >
                          Create a New View
                        </MenuItem>
                        <MenuItem className={classes.manageColumnMenuItem} disabled>
                          Saved Column Views
                        </MenuItem>
                        {manageColumnMenuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            className={classes.manageColumnMenuItem}
                            onClick={() => handleActionItemClick(index)}
                          >
                            <Box display="flex">
                              <Box className={classes.manageColumnMenuItemText}>
                                {item.isCurrent && (
                                  <span className={classes.currentHeaderLabel}>(Current)</span>
                                )}{" "}
                                {item.label}
                              </Box>
                              {index !== 0 && (
                                <Box display="flex">
                                  <Box>
                                    <EditIcon
                                      className={classes.manageColumnMenuItemEditButton}
                                      onClick={(e) => handleEditHeader(e, index, item)}
                                    />
                                  </Box>
                                  <Box>
                                    <CancelIcon
                                      className={classes.manageColumnMenuItemDeleteButton}
                                      onClick={(e) => handleDeleteHeaderDialogOpen(e, index, item)}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </MenuItem>
                        ))}
                        {/* <MenuItem
                          className={classes.manageColumnMenuItem}
                          onClick={handleOpenManageColumnDialog}
                        >
                          Manage Column
                        </MenuItem> */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>

        <Box className={classes.tableContainer}>
          <ItemListTable
            selectedItem={selectedItem}
            handleItemSelect={handleItemSelect}
            handleViewImage={handleViewImage}
            headerData={headerData}
            rowData={itemList}
            selectedFilter={selectedFilter}
            isSelectedAll={isSelectedAll}
            unselectedItems={unselectedItems}
            handleSelectAll={handleSelectAll}
            handleRowClick={handleOpenItem}
            handleSorting={handleSorting}
            handleFilterListToggle={handleFilterListToggle}
            handleFilterItemSelect={handleFilterItemSelect}
            rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
            currentPage={pageData.currentPage}
            rowsPerPage={pageData.rowsPerPage}
            totalPages={pageData.totalPages}
            totalRecords={pageData.totalRecords}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </Box>

      <Dialog onClose={handleCloseEditDialog} open={openEditDialog} className={classes.dialog}>
        <MuiDialogTitle onClose={handleCloseEditDialog} className={classes.dialogTitle}>
          Bulk Edit Items
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Editing {selectedItem.length} items with the following criteria:
          </Box>
          <Box className={classes.dialogInfo}>All Ongoing Items</Box>

          <Box className={classes.dialogSubtitle}>Edit Properties</Box>

          <Box className={classes.dialogSelectText}>Property</Box>

          <Box>
            <Select
              label="Please choose property"
              name="property"
              value={bulkEditTaskData.property}
              options={
                hasEverydayItem
                  ? [
                      {
                        value: "deadLine",
                        label: `${SIMPLE_BUYER_NAME} Deadline`,
                      },
                    ]
                  : [
                      {
                        value: "deadLine",
                        label: `${SIMPLE_BUYER_NAME} Deadline`,
                      },
                      {
                        value: "shipByDate",
                        label: "Ship By Date",
                      },
                    ]
              }
              handleChange={handleBulkEditDataChange}
              additionalClass={classes.dialogSelect}
            />
          </Box>

          {bulkEditTaskData.property === "deadLine" &&
            (commonTaskTypeList.length === 0 ? (
              <h2>No Common Task</h2>
            ) : (
              <Box display="flex" className={classes.dialogEditContainer}>
                <Box className={classes.dialogEditSection}>
                  <Box className={classes.dialogSubtitle}>Task Type</Box>
                  <Box className={classes.dialogSelectText}>Task Type</Box>
                  <Box>
                    <Select
                      label="Please choose task type"
                      name="taskType"
                      value={bulkEditTaskData.taskType}
                      options={commonTaskTypeList}
                      handleChange={handleBulkEditDataChange}
                      additionalClass={classes.dialogSelect}
                    />
                  </Box>
                </Box>

                <Box className={classes.dialogEditSection}>
                  <Box className={classes.dialogSubtitle}>New Value</Box>
                  <Box className={classes.dialogSelectText}>{SIMPLE_BUYER_NAME} Deadline</Box>
                  <Box>
                    <DatePicker
                      name="newDate"
                      value={bulkEditTaskData.newDate}
                      onChange={handleBulkEditDataChange}
                      type="date"
                      className={classes.datePicker}
                    />
                  </Box>
                </Box>
              </Box>
            ))}

          {bulkEditTaskData.property === "shipByDate" && (
            <Box className={classes.dialogEditContainer}>
              <Box className={classes.dialogSubtitle}>New Value</Box>
              <Box className={classes.dialogSelectText}>Ship By Date</Box>
              <Box>
                <DatePicker
                  name="newDate"
                  value={bulkEditTaskData.newDate}
                  onChange={handleBulkEditDataChange}
                  type="date"
                  className={classes.datePicker}
                />
              </Box>
            </Box>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleBulkEdit}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!bulkEditTaskData.property || !bulkEditTaskData.newDate}
          >
            CONFIRM EDIT
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseManageColumnDialog}
        open={openManageColumnDialog}
        className={classes.manageColumnDialog}
      >
        <MuiDialogTitle onClose={handleCloseManageColumnDialog} className={classes.dialogTitle}>
          Edit/Custom Column
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box>
            <Box className={classes.dialogSubtitle}>Name</Box>

            <TextInput
              name="name"
              value={manageColumnData.name}
              onChange={handleManageColumnDataChange}
            />
          </Box>
          {/* <Grid container spacing={2} justify="center" alignItems="center" className={classes.manageColumnContainer}> */}
          <Grid container spacing={2} justify="center" className={classes.manageColumnContainer}>
            <Grid item>{leftList(left)}</Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.manageColumnDialogButtonContainer}
              >
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label='move all right'>
                  ≫
                </Button> */}
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label='move all left'>
                  ≪
                </Button> */}
              </Grid>
            </Grid>
            <Grid item>{rightList(right)}</Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseManageColumnDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleSubmitManageColumn}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!manageColumnData.name}
          >
            SAVE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseAddTaskDialog}
        open={openAddTaskDialog}
        className={classes.dialog}
      >
        <MuiDialogTitle onClose={handleCloseAddTaskDialog} className={classes.dialogTitle}>
          Bulk Add Task
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Adding Task(s) to {selectedItem.length} selected item
          </Box>

          <Box className={classes.dialogSubtitle}>Choose Task Type</Box>

          <Box>
            <Box className={classes.dialogSelectText}>Task Type</Box>

            <Box>
              <Select
                label=""
                name="taskType"
                value={bulkAddTaskData.taskType}
                options={addTaskList}
                handleChange={handleBulkAddTaskDataChange}
                additionalClass={classes.dialogSelect}
              />
            </Box>
          </Box>

          {bulkAddTaskData.taskType && (
            <>
              <Box className={classes.dialogSubtitle}>Select SubWIC for Task</Box>
              <Box className={classes.dialogMessage}>Default bulk selected ALL SubWICs.</Box>
              {/* hasEverydayItem hasSeasonalItem */}

              {hasSeasonalItem && bulkAddTaskData.taskType !== "PAC" && (
                <>
                  <Box className={classes.dialogSubtitle}>
                    {SIMPLE_BUYER_NAME} Deadline (Seasonal Only)
                  </Box>
                  <Box className={classes.dialogSelectText}>Deadline</Box>

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Picker
                      disableToolbar
                      className={classes.datePicker}
                      format="DD/MMM/YYYY"
                      placeholder="dd/mm/yyyy"
                      name="deadLine"
                      value={bulkAddTaskData.deadLine || null}
                      onChange={handleBulkAddTaskDeadlineChange}
                      maxDate={moment(bulkAddTaskData.shipByDate)._d}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}

              {bulkAddTaskData.taskType === "SS" && (
                <>
                  <Box className={classes.dialogSubtitle}>Assign Task to (Seasonal Only)</Box>
                  <Box className={classes.dialogSelectText}>Assign to</Box>
                  <Select
                    label=""
                    name="assignTo"
                    value={bulkAddTaskData.assignTo}
                    options={[
                      {
                        value: "lab",
                        label: "Lab",
                      },
                      {
                        value: "wba",
                        label: SIMPLE_BUYER_NAME,
                      },
                    ]}
                    handleChange={handleBulkAddTaskDataChange}
                    additionalClass={classes.handledByInput}
                  />
                </>
              )}
              {hasSeasonalItem &&
                (bulkAddTaskData.taskType === "DPI" || bulkAddTaskData.taskType === "FRI") && (
                  <>
                    <Box className={classes.dialogSubtitle}>Assign Task to (Seasonal Only)</Box>
                    <Box className={classes.dialogSelectText}>Assign to</Box>
                    <Select
                      label=""
                      name="assignTo"
                      value={bulkAddTaskData.assignTo}
                      options={[
                        {
                          value: "lab",
                          label: "Lab",
                        },
                        {
                          value: "supplier",
                          label: "Supplier",
                        },
                      ]}
                      handleChange={handleBulkAddTaskDataChange}
                      additionalClass={classes.handledByInput}
                    />
                  </>
                )}

              <Box className={classes.dialogSubtitle}>Task Remark (Optional)</Box>
              <Box className={classes.dialogSelectText}>Remark</Box>
              <TextInput
                name="remark"
                value={bulkAddTaskData.remark}
                onChange={handleBulkAddTaskDataChange}
              />
            </>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseAddTaskDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleBulkAdd}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!bulkAddTaskData.taskType}
          >
            CONFIRM ADD
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseDeleteTaskDialog}
        open={openDeleteTaskDialog}
        className={classes.dialog}
      >
        <MuiDialogTitle onClose={handleCloseDeleteTaskDialog} className={classes.dialogTitle}>
          Bulk Delete Task
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Deleting tasks for {selectedItem.length} selected item(s). Please select the task type
            for bulk delete.
          </Box>

          <Box className={classes.dialogSubtitle}>Bulk Delete</Box>

          {commonTaskTypeList.length > 0 ? (
            <Box>
              <Box className={classes.dialogSelectText}>Task Type</Box>

              <Box>
                <Select
                  label=""
                  name="bulkDeleteTaskType"
                  value={bulkDeleteTaskType}
                  options={commonTaskTypeList}
                  handleChange={handleBulkDeleteTaskTypeChange}
                  additionalClass={classes.dialogSelect}
                />
              </Box>
            </Box>
          ) : (
            <h2>No Common Task</h2>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDeleteTaskDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleBulkDelete}
            color="secondary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!bulkDeleteTaskType}
          >
            CONFIRM DELETE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog onClose={handleCloseDeleteHeaderDialog} open={openDeleteHeaderDialog}>
        <MuiDialogTitle onClose={handleCloseDeleteHeaderDialog} className={classes.dialogTitle}>
          Remove a Saved Column View
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          "{deleteHeaderData.name}" will be removed, and this action cannot be undone.
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDeleteHeaderDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleDeleteHeader}
            color="secondary"
            variant="contained"
            className={classes.dialogConfirmButton}
          >
            CONFIRM DELETE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseEditManageColumnDialog}
        open={openEditManageColumnDialog}
        className={classes.manageColumnDialog}
      >
        <MuiDialogTitle onClose={handleCloseEditManageColumnDialog} className={classes.dialogTitle}>
          Edit/Custom Column
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Grid container spacing={2} justify="center" className={classes.manageColumnContainer}>
            <Grid item>{leftList(left)}</Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.manageColumnDialogButtonContainer}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label='move all left'>
                  ≪
                </Button> */}
              </Grid>
            </Grid>
            <Grid item>{rightList(right)}</Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseEditManageColumnDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleEditManageColumn}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
          >
            SAVE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Modal onClose={() => setOpenImageGallery(false)} open={openImageGallery}>
        <Box className={classes.imageGalleryContainer}>
          <ImageGallery
            items={galleryImages}
            showFullscreenButton={false}
            showPlayButton={false}
            showIndex={true}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default connect(null, {
  atnGetItemListHeader,
  atnGetItemListData,
})(WbaItemList);
