import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import UpdateTestingStatus from "./UpdateTestingStatus";
import UpdateInspectionStatus from "./UpdateInspectionStatus";
import UpdatePACStatus from "./UpdatePACStatus";

import { atnGetSubmissionDetails } from "actions/submissionActions";

import { getTaskFlow } from "services/taskFlowService";

import { SUBMISSION_TASK_TYPE } from "shared/constants";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
}));

const UpdateStatus = ({ match, location, history }) => {
  const classes = useStyles();

  const [submissionType, setSubmissionType] = useState("");

  const submissionId = match?.params?.submissionId;

  const isUploadPACCert = location?.state?.type === "pac_cert";

  const [TESTING, INSPECTION, PAC] = SUBMISSION_TASK_TYPE;

  const [isProgram777, setIsProgram777] = useState(false);

  useEffect(() => {
    atnGetSubmissionDetails({
      type: 3,
      data: {
        id: submissionId,
      },
    }).then(({ data }) => {
      if (data?.isProgram777) {
        setIsProgram777(data?.isProgram777);
      }

      if (data?.taskType) {
        const { isSamplePickUp, isInspection, isTesting, isPac } = getTaskFlow(data?.taskType);

        if (isPac) return setSubmissionType(PAC);
        if (isSamplePickUp || isInspection) return setSubmissionType(INSPECTION);
        if (isTesting) return setSubmissionType(TESTING);
      }
    });
  }, []);

  return (
    <Box className={classes.root}>
      {submissionType === TESTING && (
        <UpdateTestingStatus
          history={history}
          submissionType={submissionType}
          submissionId={submissionId}
        />
      )}

      {submissionType === INSPECTION && (
        <UpdateInspectionStatus
          history={history}
          submissionType={submissionType}
          submissionId={submissionId}
          isProgram777={isProgram777}
        />
      )}

      {submissionType === PAC && (
        <UpdatePACStatus
          history={history}
          submissionType={submissionType}
          submissionId={submissionId}
          isUploadPACCert={isUploadPACCert}
        />
      )}
    </Box>
  );
};

export default UpdateStatus;
