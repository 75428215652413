import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import { Box, Grid, Switch, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import Program777Chip from "components/display/Program777Chip";

const useStyles = makeStyles((theme) => ({
  switch: {
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#B0D400",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#B0D400",
    },
  },
  info: {
    paddingTop: theme.spacing(1),
  },
  infoIcon: {
    fontSize: 14,
    marginRight: 8,
    color: "#777777",
  },
  gridContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  gridItem: {
    padding: 10,
    border: "1px solid #777777",
  },
  text: {
    fontSize: 10,
  },
  textPadding: {
    paddingRight: 16,
  },
  blackText: {
    color: "#000000",
  },
  grayText: {
    color: "#777777",
  },
}));

const ItemType = ({
  data,
  checked = false,
  infoText = "If your you want to label regular 777 item , you can switch the toggle to turn on or off. Supplier will know it and take action for testing and inspection earlier.",
  onChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.info}>
        <InfoIcon className={classes.infoIcon} />
        <Typography className={classnames(classes.text, classes.grayText)}>{infoText}</Typography>
      </Box>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classnames(classes.gridItem, classes.text)}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Program777Chip />
              <Typography variant="subtitle2" className={classes.textPadding}>
                777 Item
              </Typography>
            </Box>

            <Switch
              className={classes.switch}
              checked={checked}
              onChange={onChange}
              name="label777"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ItemType);
