import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
    width: "100%",
    paddingTop: 150,
    fontSize: 48,
    fontWeight: 700,
    textAlign: "center",
  },
}));

const ActivityLog = () => {
  const classes = useStyles();

  return <Box className={classes.root}>COMING SOON ...</Box>;
};

export default ActivityLog;
