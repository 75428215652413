import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import auth from "auth/authenticator";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "center",
  },
  text: {
    paddingTop: "10%",
    fontSize: 36,
    fontWeight: 500,
  },
  button: {
    marginTop: 20,
  },
}));

const NotFoundPage = ({ history }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.text}>404 Not Found</Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => history.push(`/${auth.loginRole()}/home`)}
      >
        GO TO HOME PAGE
      </Button>
    </Box>
  );
};

export default NotFoundPage;
