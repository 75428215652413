import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import DatePicker from "components/input/DatePicker";
import Upload from "components/input/Upload";
import MultipleSelect from "components/input/MultipleSelect";

import { atnGetPbEveryday } from "actions/taskActions";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  content: {
    padding: 30,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 20,
    width: "100%",
  },
  uploadButton: {
    width: "100%",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const UploadReportAndResult = ({
  editSubmissionData = {},
  handleAddNewFilesChange,
  handleReportDataChange,
  handleReportPBChange,
  handleRemoveFile,
  handleSubmit,
  isDisabledConformButton,
  isProgram777 = false,
  triggeredBy = false,
  masterWicId,
}) => {
  const classes = useStyles();

  const [relatedPBOtpions, setRelatedPBOptions] = useState([]);
  const [showProgram777, setShowProgram777] = useState(false);

  useEffect(() => {
    const displayProgram777 = isShowProgram777(triggeredBy);

    setShowProgram777(displayProgram777);

    displayProgram777 && fetchPBOptions();
  }, [isProgram777, triggeredBy]);

  const fetchPBOptions = () => {
    if (masterWicId) {
      atnGetPbEveryday({ id: masterWicId }).then(({ data }) => {
        if (data) {
          setRelatedPBOptions(
            data.map((item) => ({
              ...item,
              //for multiselect options
              label: item.pbNumber,
              value: item.id,
            })),
          );
        }
      });
    }
  };

  const handleUploadReport = (newFiles) => {
    let files = [];

    for (let i = 0; i < newFiles.length; i++) {
      const initData = {
        fileId: newFiles[i].id,
        name: newFiles[i].name,
        result: "",
        issueDate: "",
      };

      files.push(initData);

      files.push(showProgram777 ? { ...initData, pb: [] } : initData);
    }

    handleAddNewFilesChange(files);
  };

  const handleSubmitClick = () => {
    let hasEmptyData = false;

    if (editSubmissionData.reports.length === 0) {
      hasEmptyData = true;
    } else {
      for (let i = 0; i < editSubmissionData.reports.length; i++) {
        if (!editSubmissionData.reports[i].result || !editSubmissionData.reports[i].issueDate) {
          hasEmptyData = true;
          break;
        }

        if (showProgram777 && !editSubmissionData.reports[i].pb.length) {
          hasEmptyData = true;
          break;
        }
      }
    }

    if (hasEmptyData) {
      alert("missing data");
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Reports & Results</Box>
        <Box className={classes.subtitleSection}>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              Report Documents
            </Grid>
            {showProgram777 && (
              <Grid item xs={3}>
                PB Information
              </Grid>
            )}
            <Grid item xs={3}>
              Result
            </Grid>
            <Grid item xs={3}>
              Issue Date
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>

        <Divider />

        {editSubmissionData.reports.map((item, index) => (
          <Grid key={item.fileId} container spacing={3} className={classes.rowContainer}>
            <Grid item xs={5}>
              {item.name}
            </Grid>

            {showProgram777 && (
              <Grid item xs={3}>
                {/* <Select
                label="Result"
                name="result"
                value={item.result}
                options={results}
                handleChange={e => handleReportDataChange(e, index)}
              /> */}

                <MultipleSelect
                  label="PB information"
                  name="pb"
                  value={item.pb || []}
                  options={relatedPBOtpions}
                  handleChange={(e) => handleReportPBChange(e, index, relatedPBOtpions)}
                />
              </Grid>
            )}

            <Grid item xs={3}>
              <DatePicker
                name="issueDate"
                value={item.issueDate}
                handleChange={(e) => handleReportDataChange(e, index)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleRemoveFile(index)} className={classes.deleteBtn}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Box className={classes.addButtonContainer}>
          <Upload
            buttonLabel="UPLOAD REPORT(S)"
            handleAddFiles={handleUploadReport}
            className={classes.uploadButton}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={isDisabledConformButton}
      >
        SUBMIT
      </Button>
    </>
  );
};

export default UploadReportAndResult;
