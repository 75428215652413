import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import AverageChart from "components/analytics/AverageChart";
import AverageSell from "components/analytics/AverageSell";
import TotalChart from "components/analytics/TotalChart";
import TotalSpendingTask from "components/analytics/TotalSpendingTask";
import TotalSpendingLab from "components/analytics/TotalSpendingLab";
import TopSpending from "components/analytics/TopSpending";
import TopAverage from "components/analytics/TopAverage";
import QuantityClaim from "components/analytics/QuantityClaim";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  titleSpending: {
    fontWeight: "500",
    fontSize: "24px",
    color: "#000000",
    marginTop: 20,
  },

  groupTotalMaster: {
    marginLeft: "30px",
  },

  blockTask: {
    paddingLeft: "30px",
  },

  blockLab: {
    marginTop: 0,
    marginLeft: "30px",
    width: 1100,
  },

  wrapperAllTask: {
    background: "white",
    padding: "20px 20px 10px 20px",
    marginTop: 25,
    boxShadow: "0 2px 4px rgba(35, 43, 58, 0.25)",
    maxWidth: 1100,
  },

  wrapperUSD: {
    display: "flex",
    marginTop: "25px",
    alignItems: "center",
  },

  titleAllTasks: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
  },

  usdTotal: {
    fontFamily: "Roboto",
    fontSize: "26px",
    fontWeight: "bold",
    color: "#003DA5",
    marginRight: "14px",
  },

  dateTotal: {
    fontFamily: "Roboto",
    fontSize: "16px",
    color: "#777777",
  },

  yearFinancial: {
    fontWeight: "500",
    paddingRight: "10px",
    marginRight: "10px",
    borderRight: "1px solid #777777",
  },
}));

const Dashboard = ({
  programYearPeriod,
  totalMasterWic,
  totalSupplier,
  totalFactory,
  totalProgram,
  totalBrand,
  avgSSTimes,
  firstTimePassRateFRI,
  firstTimePassRateST,
  avgUnitValue,
  avgSellPerSupplier,
  objQuantityClaim,
  tenAvgPerSupplier,
  tenSpendingSupplier,
  overAllTotalData,
  totalSpendingPerTask,
  totalSpendingPerLab = null,
  totalSpendingPerLabOrigin = null,
  financialYearTotal = { id: 1, value: "0", startAt: "0", endAt: "0" },
  handleTabChange,
}) => {
  const classes = useStyles();

  let totalAllTask = 0;

  for (let i = 0; i < totalSpendingPerTask.length; i++) {
    totalAllTask += totalSpendingPerTask[i].total;
  }

  return (
    <Box className={classes.root} display="block">
      <Box className={classes.groupTotalMaster} display="block">
        <Box className={classes.root} display="flex">
          <TotalChart
            title="Total Master WIC"
            bg="orange"
            totalNumber={totalMasterWic}
            subTitle="WIC"
            textView="View On List"
            fontSize="small"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Total Suppliers"
            bg="red"
            totalNumber={totalSupplier}
            subTitle="Suppliers"
            textView="View On List"
            fontSize="small"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Total Factories"
            bg="purple"
            totalNumber={totalFactory}
            subTitle="Factories"
            textView="View On List"
            fontSize="small"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Total Programs"
            bg="blue"
            totalNumber={totalProgram}
            subTitle="Programs"
            textView="View On List"
            fontSize="small"
            handleTabChange={handleTabChange}
          />
        </Box>

        <Box className={classes.root} display="flex">
          <TotalChart
            title="Brand Type"
            bg="cyan"
            totalNumber={totalBrand}
            subTitle="Brands"
            textView="View On List"
            fontSize="small"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Average Time of Sample Sealing time per WIC"
            bg="yellow"
            totalNumber={avgSSTimes}
            subTitle=""
            textView="View On List"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Final Random Inspection Pass Rate"
            bg="green"
            totalNumber={firstTimePassRateFRI}
            subTitle=""
            textView="View On List"
            handleTabChange={handleTabChange}
          />
          <TotalChart
            title="Safety Test Pass Rate"
            bg="dark"
            totalNumber={firstTimePassRateST}
            subTitle=" "
            textView="View On List"
            handleTabChange={handleTabChange}
          />
        </Box>
      </Box>

      <Box className={classes.blockTask} display="block">
        <Box className={classes.titleSpending}>1506%</Box>

        <AverageChart handleTabChange={handleTabChange} avgUnitValue={avgUnitValue} />
        <AverageSell handleTabChange={handleTabChange} data={avgSellPerSupplier} />
        <QuantityClaim
          handleTabChange={handleTabChange}
          financialYearTotal={financialYearTotal}
          objQuantityClaim={objQuantityClaim}
        />
        <TopAverage handleTabChange={handleTabChange} tenAvgPerSupplier={tenAvgPerSupplier} />
      </Box>

      <Box className={classes.blockTask} display="block">
        <Box className={classes.titleSpending}>Spending</Box>

        <Box className={classes.wrapperAllTask}>
          <Box className={classes.titleAllTasks}>Total Spending of All Tasks</Box>
          <Box className={classes.wrapperUSD}>
            <Box className={classes.usdTotal}>
              USD ${(Math.round((totalAllTask + Number.EPSILON) * 100) / 100).toLocaleString()}
            </Box>
            <Box className={classes.dateTotal}>
              Program Year(s): {programYearPeriod}
              {/* {financialYearTotal.value > -1 && (
                <span className={classes.yearFinancial}>{financialYearTotal.value}</span>
              )}
              <span className={classes.rangeYearFinanCial}>
                {financialYearTotal.startAt} - {financialYearTotal.endAt}
              </span> */}
            </Box>
          </Box>
        </Box>

        {totalSpendingPerTask && <TotalSpendingTask totalSpendingPerTask={totalSpendingPerTask} />}
      </Box>

      <Box className={classes.blockLab} display="block">
        {totalSpendingPerLab && (
          <TotalSpendingLab
            overAllTotalData={overAllTotalData}
            totalSpendingPerLab={totalSpendingPerLab}
            totalSpendingPerLabOrigin={totalSpendingPerLabOrigin}
            handleTabChange={handleTabChange}
          />
        )}
      </Box>

      <Box className={classes.blockLab} display="block">
        <TopSpending handleTabChange={handleTabChange} tenSpendingSupplier={tenSpendingSupplier} />
      </Box>
    </Box>
  );
};

export default Dashboard;
