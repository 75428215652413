const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

export const DD_MMM_YYYY = "DD/MMM/YYYY";
export const DATE_FORMAT_02 = "YYYY-MM-DD";
export const DATE_FORMAT_03 = "DD/MM/YYYY";
export const DATE_TIME_FORMAT_01 = "hh:mm DD/MM/YYYY";

export const PER_PAGE_OPTIONS = [10, 20, 50];
export const INITIAL_PER_PAGE = 10;

export const INITIAL_PAGINATION_STATE = {
  filter: {},
  pagination: { pageNo: 1, pageSize: INITIAL_PER_PAGE },
};

export const TASK_TYPE_ENUM = {
  SS: "SS",
  PAC: "PAC",
  QT: "QT",
  PST: "PST",
  SPU: "SPU",
  SPUD: "SPUD",
  ST: "ST",
  STD: "STD",
  DPI: "DPI",
  FRI: "FRI",
  FRID: "FRID",
};

export const TASK_TYPE_PICK_UP = [
  { value: "PAC", label: "PAC" },
  { value: "STD", label: "ST" },
  { value: "PAC,STD", label: "Both" },
];

export const NA = "N/A";

export const EVERYDAY = "everyday";
export const SEASONAL = "seasonal";

export const SUPPORT_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/vnd.ms-excel",
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/pdf",
];
export const SUPPORT_IMAGE_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

export const NO_DATA = "No Data";

export const MAX_FILE_SIZE_MB = 5;

export const ADD_CANCEL_LABEL_777 = "add_cancel_label_777";

export const BUYER = "buyer";
export const BUYER_SOURCING = "buyer_sourcing";
export const SUPPLIER = "supplier";
export const LAB = "lab";
export const WBA = "wba";

export const USER_TYPE = {
  buyer: 1,
  buyer_sourcing: 1,
  supplier: 2,
  lab: 3,
  lab_admin: 3,
};

export const USER_ROLE = {
  BUYER: "buyer",
  BUYER_SOURCING: "buyer_sourcing",
  SUPPLIER: "supplier",
  LAB: "lab",
  LAB_ADMIN: "lab_admin",
};

export const TASK_TRIGGERED_BY = {
  PO: "po",
  PB: "pb",
};

export const PB_STATUS = {
  NOT_EXISTED: "NOT_EXISTED",
  SHIPPED: "SHIPPED",
  WIP: "WIP",
  PARTIALLY_SHIPPED: "PARTIALLY_SHIPPED",
  INSPECTION_PASSED: "INSPECTION_PASSED",
};

export const PB_STATUS_TYPE = [
  {
    id: 1,
    label: "Cancelled",
    value: PB_STATUS.NOT_EXISTED,
  },
  {
    id: 2,
    label: "Shipped",
    value: PB_STATUS.SHIPPED,
  },
  {
    id: 3,
    label: "Work In Progress",
    value: PB_STATUS.WIP,
  },
  {
    id: 4,
    label: "Partially Shipped",
    value: PB_STATUS.PARTIALLY_SHIPPED,
  },
  {
    id: 5,
    label: "Inspection Passed",
    value: PB_STATUS.INSPECTION_PASSED,
  },
];

export const TASK_TYPE = [
  { id: 1, value: "QT", label: "Quality Test", type: "testing", days: 56 },
  { id: 2, value: "PST", label: "Pre-Safety Test", type: "testing", days: 49 },
  {
    id: 3,
    value: "SS",
    label: "Sample Sealing",
    type: "sampleSealing",
    days: 40,
  },
  { id: 4, value: "SPU", label: "Sample Pickup", type: "inspection", days: 35 },
  { id: 5, value: "ST", label: "Safety Test", type: "testing", days: 33 },
  {
    id: 6,
    value: "DPI",
    label: "During Production Inspection",
    type: "inspection",
    days: 35,
  },
  {
    id: 7,
    value: "FRI",
    label: "Final Random Inspection",
    type: "inspection",
    days: 28,
  },
];

export const TASK_TYPE_COMMON = [
  { id: 1, value: "PAC", label: "PAC" },
  { id: 2, value: "QT", label: "Quality Test" },
  { id: 3, value: "PST", label: "Pre-Safety Test" },
  { id: 4, value: "SS", label: "Sample Sealing" },
  { id: 5, value: "SPU", label: "Sample Pickup" },
  { id: 6, value: "SPUD", label: "Sample Pickup" },
  { id: 7, value: "ST", label: "Safety Test" },
  { id: 8, value: "STD", label: "Safety Test" },
  { id: 9, value: "DPI", label: "During Production Inspection" },
  { id: 10, value: "FRI", label: "Final Random Inspection" },
  { id: 11, value: "FRID", label: "Final Random Inspection" },
];

export const TASK_TYPE_EVERYDAY = [
  { id: 1, value: "PAC", label: "PAC", type: "testing", days: 56 },
  {
    id: 2,
    value: "SPUD",
    label: "Sample Pickup",
    type: "inspection",
    days: 35,
  },
  { id: 3, value: "STD", label: "Safety Test", type: "testing", days: 33 },
  {
    id: 4,
    value: "FRID",
    label: "Final Random Inspection",
    type: "inspection",
    days: 28,
  },
  { id: 5, value: "SS", label: "Sample Sealing" },
];

export const TASK_TESTING_TYPE = TASK_TYPE.filter((t) => t.type === "testing").map((t) => t.value);

export const TASK_INSPECTION_TYPE = TASK_TYPE.filter((t) => t.type === "inspection")
  .map((t) => t.value)
  .concat(TASK_TYPE_EVERYDAY.filter((t) => t.type === "inspection").map((t) => t.value));

export const TASK_SAMPLE_SEALING_TYPE = TASK_TYPE.filter((t) => t.type === "sampleSealing").map(
  (t) => t.value,
);

export const TASK_ASSIGN_LIST = [
  { id: 1, value: "lab", label: "Lab", group: [1, 2] },
  { id: 2, value: "supplier", label: "Supplier", group: [1] },
  { id: 3, value: "wba", label: "WBA", group: [2] },
];
export const TASK_ASSIGN_LIST_GROUP_01 = TASK_ASSIGN_LIST.filter((t) => t.group.includes(1));
export const TASK_ASSIGN_LIST_GROUP_02 = TASK_ASSIGN_LIST.filter((t) => t.group.includes(2));

export const LAB_TYPE = {
  type01: "QT/PST/ST",
  type02: "DPI/FRI/SPU",
};

export const LAB_TYPE_NUMS = {
  type01: 1,
  type02: 2,
};

export const TASK_COLOR = {
  grey: "grey",
  blue: "blue",
  yellow: "yellow",
  green: "green",
  red: "red",
};

export const TASK_STATUS_ENUM = {
  NOT_OPEN_FOR_QUOTE: "not_open_for_quote",
  CLOSED: "closed",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  WAITING_FOR_LAB_QUOTE: "waiting_for_lab_quote",
  QUOTE_AVAILABLE: "quote_available",
  QUOTE_SUBMITTED: "quote_submitted",
  QUOTE_CONFIRMED: "quote_confirmed",
  QUOTE_CONFIRMED_AND_WAITING_FOR_SAMPLE: "quote_confirmed_and_waiting_for_sample",
  PB_UPDATED: "pb_updated",
  SAMPLE_RECEIVED: "sample_received",
  IN_PROGRESS: "in_progress",
  IN_PROGRESS_REVISE_UPDATE: "in_progress_revise_update",
  PENDING: "pending",
  RETEST_IN_PROGRESS: "retest_in_progress",
  IN_PROGRESS_RESULT_REPORT_UPDATED: "in_progress_result_report_updated",
  IN_PROGRESS_UPLOAD_PAC_CERT: "in_progress_upload_pac_cert",
  ARRANGING_SAMPLE: "arranging_sample",
  UNDER_REVIEW: "under_review",
  BOOKING_RESERVED: "booking_reserved",
  WAITING_FOR_WBA_APPROVAL: "waiting_for_wba_approval",
  ACCEPTED: "accepted",
  COMPLETED: "completed",
  COMPLETED_ACCEPT: "completed_accept",
  COMPLETED_CONCESSION: "completed_concession",
  WITHIN_VALIDITY_PERIOD: "within_validity_period",
  COMPLETED_REJECT: "completed_reject",
  FAIL: "fail",
  PB_NOT_EXISTED: "pb_not_existed",
  NOT_ACKNOWLEDGE: "not_acknowledge",
  EMPTY_TASK: "empty_task",
};

export const TASK_STATUS = {
  // GREY
  not_open_for_quote: {
    label: "Not Open For Quote",
    value: "not_open_for_quote",
    color: TASK_COLOR.grey,
  },
  closed: {
    label: "Closed",
    value: "closed",
    color: TASK_COLOR.grey,
  },
  rejected: {
    label: "Rejected",
    value: "rejected",
    color: TASK_COLOR.grey,
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: TASK_COLOR.grey,
  },
  // BLUE
  waiting_for_lab_quote: {
    label: "Waiting For Lab Quote",
    value: "waiting_for_lab_quote",
    color: TASK_COLOR.blue,
  },
  quote_available: {
    label: "Quote Available",
    value: "quote_available",
    color: TASK_COLOR.blue,
  },
  quote_submitted: {
    label: "Quote Submitted",
    value: "quote_submitted",
    color: TASK_COLOR.blue,
  },
  quote_confirmed: {
    label: "Quote Confirmed",
    value: "quote_confirmed",
    color: TASK_COLOR.blue,
  },
  quote_confirmed_and_waiting_for_sample: {
    label: "Quote Confirmed And Waiting For Sample",
    value: "quote_confirmed_and_waiting_for_sample",
    color: TASK_COLOR.blue,
  },
  // YELLOW
  pb_updated: {
    label: "PB Updated",
    value: "pb_updated",
    color: TASK_COLOR.yellow,
  },
  sample_received: {
    label: "Sample Received",
    value: "sample_received",
    color: TASK_COLOR.yellow,
  },
  in_progress: {
    label: "In Progress",
    value: "in_progress",
    color: TASK_COLOR.yellow,
  },
  in_progress_revise_update: {
    label: "In Progress - Revise/Update",
    value: "in_progress_revise_update",
    color: TASK_COLOR.yellow,
  },
  pending: { label: "Pending", value: "pending", color: TASK_COLOR.yellow },
  retest_in_progress: {
    label: "Retest In Progress",
    value: "retest_in_progress",
    color: TASK_COLOR.yellow,
  },
  in_progress_result_report_updated: {
    label: "In Progress - Result/Report Updated",
    value: "in_progress_result_report_updated",
    color: TASK_COLOR.yellow,
  },
  in_progress_upload_pac_cert: {
    label: "In Progress - PAC Cert Updated",
    value: "in_progress_upload_pac_cert",
    color: TASK_COLOR.yellow,
  },
  arranging_sample: {
    label: "Arranging Sample",
    value: "arranging_sample",
    color: TASK_COLOR.yellow,
  },
  under_review: {
    label: "Under Review",
    value: "under_review",
    color: TASK_COLOR.yellow,
  },
  booking_reserved: {
    label: "Booking Reserved",
    value: "booking_reserved",
    color: TASK_COLOR.yellow,
  },
  waiting_for_wba_approval: {
    label: "Waiting For WBA Approval",
    value: "waiting_for_wba_approval",
    color: TASK_COLOR.yellow,
  },
  // GREEN
  accepted: {
    label: "Accepted",
    value: "accepted",
    color: TASK_COLOR.green,
  },
  completed: {
    label: "Completed",
    value: "completed",
    color: TASK_COLOR.green,
  },
  completed_accept: {
    label: "Completed-Accept",
    value: "completed_accept",
    color: TASK_COLOR.green,
  },
  completed_concession: {
    label: "Completed-concessed",
    value: "completed_concession",
    color: TASK_COLOR.green,
  },
  within_validity_period: {
    label: "Within Validity Period",
    value: "within_validity_period",
    color: TASK_COLOR.green,
  },
  // RED
  completed_reject: {
    label: "Completed-Reject",
    value: "completed_reject",
    color: TASK_COLOR.red,
  },
  fail: { label: "Fail Report", value: "fail", color: TASK_COLOR.red },
  pb_not_existed: {
    label: "PB Not Existed",
    value: "pb_not_existed",
    color: TASK_COLOR.red,
  },
  // UNKNOWN
  not_acknowledge: {
    label: "Acknowledge Project",
    value: "not_acknowledge",
    color: null,
  },
  // EMPTY TASK
  empty_task: { label: "No Active Task", value: "empty_task", color: null },
};

export const LAB_REPORT_UPLOAD_RESULTS = [
  {
    value: "pass",
    label: "Pass",
  },
  {
    value: "visitInVain",
    label: "Visit In Vain",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "fail",
    label: "Fail",
  },
];

export const TESTING_REPORT_RESULTS = [
  {
    value: "pass",
    label: "Pass",
  },
  {
    value: "reportAsIs",
    label: "Report As Is",
  },
  {
    value: "fail",
    label: "Fail",
  },
];

export const TASK_DATE_TYPE = {
  deadLine: { id: 1, label: `${SIMPLE_BUYER_NAME} Deadline`, value: "deadLine" },
  plannedDate: { id: 2, label: "Planned Date", value: "plannedDate" },
  pacValidityDate: {
    id: 3,
    label: "PAC Validity Date",
    value: "pacValidityDate",
  },
  selfInspectionValidityDate: {
    id: 4,
    label: "Self-Inspection Validity Date",
    value: "selfInspectionValidityDate",
  },
  gccValidityDate: {
    id: 5,
    label: "GCC Validity Date",
    value: "validityDate",
  },
  qaaValidityDate: {
    id: 6,
    label: "QAA Validity Date",
    value: "validityDate",
  },
};

export const TEST_RESULTS = [
  { id: 1, label: "Pass", value: "pass" },
  { id: 2, label: "Report As Is", value: "reportAsIs" },
  { id: 3, label: "Fail", value: "fail" },
];

export const QUOTE_STATUS = {
  accepted: { label: "Accepted", value: "accepted" },
  pending: { label: "Pending", value: "pending" },
  rejected: { label: "Rejected", value: "rejected" },
  agreeToProceed: { label: "Agree to Proceed", value: "agreeToProceed" },
};

export const ATTACHMENT_TYPES = {
  productSpecification: {
    id: 1,
    label: "Product Specification",
    value: "product_specification",
  },
  artwork: { id: 2, label: "Artwork", value: "artworks" },
  otherDocument: {
    id: 3,
    label: "Other Document e.g. BOM / Circuit Diagram",
    value: "other_information",
  },
  aisUPCInformation: {
    id: 4,
    label: "AIS/Stibo - UPC Information",
    value: "ais_stibo_upc_information",
  },
  // aisFactoryInformation: {
  //   id: 5,
  //   label: 'AIS/Stibo - Factory Information',
  //   value: 'ais_stibo_factory_information',
  // },
  aisOuterInner: {
    id: 6,
    label: "AIS/Stibo - Outer/Inner",
    value: "ais_stibo_outer_inner",
  },
  supplierInhouseCartonDropTestReport: {
    id: 7,
    label: "Supplier Inhouse Carton Drop Test Report",
    value: "supplier_inhouse_carton_drop_test_report",
  },
};

export const GUARANTEE_LETTER = {
  guaranteeLetter: {
    id: 1,
    label: "Guarantee letter ",
    value: "guarantee_letter",
  },
};

export const SUPPLIER_HOME_CONTENT_TYPE = {
  item: { id: 1, value: "item" },
  submission: { id: 2, value: "submission" },
  task: { id: 3, value: "task" },
};

export const SUPPLIER_HOME_FILTER_LIST = [
  {
    itemName: "allOutstandingProjectList",
    title: "All Outstanding Project List",
    value: "allOutstandingProjectList",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.item.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "acknowledgeProject",
    title: "Acknowledge Project",
    value: "acknowledgeProject",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.item.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "waitingForLabQuote",
    title: "Waiting for Lab Quote",
    value: "waitingForLabQuote",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.submission.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "confirmQuote",
    title: "Confirm Quote",
    value: "confirmQuote",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.submission.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "waitingLabToConfirmBooking",
    title: "Waiting Lab to Confirm Booking",
    value: "waitingLabToConfirmBooking",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.submission.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "uploadCAPForFailTask",
    title: "Upload CAP for Failed Task",
    value: "uploadCAPForFailTask",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.task.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "overdueTask",
    title: "Overdue Task",
    value: "overdueTask",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.task.value,
    message: "Click here to view",
    number: 0,
  },
  {
    itemName: "nonexistentPB",
    title: "Nonexistent PB",
    value: "nonexistentPB",
    contentType: SUPPLIER_HOME_CONTENT_TYPE.submission.value,
    message: "Click here to view",
    number: 0,
  },
];

export const ITEM_SAMPLE_DATA = {
  buyerTeam: "",
  program: "",
  programYear: "",
  shipByDates: [
    {
      shipByDate: "",
    },
  ],
  exportMarkets: [
    // {
    //   label: 'United States',
    //   value: 226,
    // },
  ],
  brand: "",
  supplier: {},
  supplierFactory: {
    isNewAdd: false,
    id: "",
    name: "",
    address: "",
    code: "",
    contactPersonName: "",
    contactNumber: "",
    contactEmail: "",
  },
  masterWic: {
    wicNumber: "",
    productName: "",
    // *** VALIDITY DETAILS ***
    pacValidityDate: "",
    validityType: "",
    validityDate: "",
    validityPeriod: "",
    selfInspectionValidityDate: "",
  },
  masterWicImages: [],
  subWics: [],
  // *** TASKS ***
  tasks: [
    // *** Seasonal Tasks ***
    {
      taskType: "QT",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: false,
      handledBy: "lab",
      handledByOptions: [],
      remarks: "",
      isSelected: false,
      days: 56,
    },
    {
      taskType: "PST",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: false,
      handledBy: "lab",
      handledByOptions: [],
      remarks: "",
      isSelected: false,
      days: 49,
    },
    {
      taskType: "SS",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: true,
      handledBy: "lab",
      handledByOptions: [
        {
          value: "lab",
          label: "Lab",
        },
        {
          value: "wba",
          label: SIMPLE_BUYER_NAME,
        },
      ],
      remarks: "",
      isSelected: true,
      days: 40,
    },
    {
      taskType: "SPU",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: false,
      handledBy: "lab",
      handledByOptions: [],
      remarks: "",
      isSelected: true,
      days: 35,
    },
    {
      taskType: "ST",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: false,
      handledBy: "lab",
      handledByOptions: [],
      remarks: "",
      isSelected: true,
      days: 33,
    },
    {
      taskType: "DPI",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: true,
      handledBy: "lab",
      handledByOptions: [
        {
          value: "lab",
          label: "Lab",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
      ],
      remarks: "",
      isSelected: false,
      days: 35,
    },
    {
      taskType: "FRI",
      category: "seasonal",
      wbaDeadline: "",
      subWics: [],
      hasHandledBy: true,
      handledBy: "lab",
      handledByOptions: [
        {
          value: "lab",
          label: "Lab",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
      ],
      remarks: "",
      isSelected: true,
      days: 28,
    },
    // *** Everyday tasks ***
    {
      taskType: "PAC",
      category: "everyday",
      wbaDeadline: "",
      subWics: [],
      remarks: "",
      isSelected: false,
      days: 56,
    },
    {
      taskType: "SPUD",
      category: "everyday",
      wbaDeadline: "",
      subWics: [],
      remarks: "",
      isSelected: true,
      days: 35,
    },
    {
      taskType: "STD",
      category: "everyday",
      wbaDeadline: "",
      subWics: [],
      remarks: "",
      isSelected: true,
      days: 33,
    },
    {
      taskType: "FRID",
      category: "everyday",
      wbaDeadline: "",
      subWics: [],
      remarks: "",
      isSelected: true,
      days: 28,
    },
  ],
  icl: "",
};

export const SUPPLIER_TABLE_HEADER = [
  {
    name: "supplierCompanyName",
    label: "Supplier Company Name",
  },
  {
    name: "contactPerson",
    label: "Contact Person",
  },
  {
    name: "supplierEmail",
    label: "Supplier Email",
  },
  {
    name: "supplierID",
    label: "Supplier ID",
  },
];

export const PROGRAM_TYPES = {
  walgreensSeasonal: { value: 1, label: " walgreens_seasonal" },
  bootsSeasonal: { value: 2, label: " boots_seasonal" },
  walgreensEveryday: { value: 3, label: " walgreens_seasonal" },
};

export const ITEM_CATEGORY = {
  everyday: { value: "everyday", label: "Everyday" },
  seasonal: { value: "seasonal", label: "Seasonal" },
};

export const VALIDITY_TYPES = [
  { label: "GCC", value: "GCC" },
  { label: "QAA", value: "QAA" },
];

export const VALIDITY_PERIODS = [
  { label: "6 Months", value: 6 },
  { label: "9 Months", value: 9 },
  { label: "12 Months", value: 12 },
];

export const CURRENCY = {
  USD: "USD",
  HKD: "HKD",
  RMB: "RMB",
};

export const CURRENCY_OPTIONS = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "HKD",
    value: "HKD",
  },
  {
    label: "RMB",
    value: "RMB",
  },
];

export const REPORT_RESULT_OPTIONS = [
  {
    value: "pass",
    label: "Pass",
  },
  {
    value: "reportAsIs",
    label: "Report As Is",
  },
  {
    value: "fail",
    label: "Fail",
  },
];

export const INVOICE_TYPE = {
  ICW_COMPANY_INVOICE: "icw_company_invoice",
  PLAIN_VAT_INVOICE: "plain_vat_invoice",
  SPECIAL_VAT_INVOICE: "special_vat_invoice",
};

export const INVOICE_TYPE_OPTIONS = [
  {
    label: "ICW Company Invoice",
    value: INVOICE_TYPE.ICW_COMPANY_INVOICE,
  },
  {
    label: "Plain VAT Invoice *",
    value: INVOICE_TYPE.PLAIN_VAT_INVOICE,
  },
  {
    label: "Special VAT Invoice *",
    value: INVOICE_TYPE.SPECIAL_VAT_INVOICE,
  },
];

export const RESUME_TYPE = {
  REPORT: "report",
  INVOICE: "invoice",
};
export const INVOICE_STATUS_EDIT = ["draft", "unpaid"];

export const TRF_DOCUMENT = {
  BOOKING_FORM_PLATFORM_USE_DOC: "WBA_SD001_WI_I_BOOKING_FORM_11_May_2020_FOR_PLATFORM_USE.doc",
  GENERAL_TESING_PLATFORM_USE_DOC: "WALG-GB-TRFS-GB-GENERAL-TESTING-V17.doc",
  SGS_WALGREENS_DOC: "SGS_Walgreens_TRF_Apr.2019.doc",
  GENERAL_INPECTION_REQUEST_FORM: "Generic Inspection Request Form Rev15 Protect.xlsm",
  PRODUCT_TESTING_DOWNLIST_DOC: "Walgreens_TRF.docx",
  ADMIN_BOOKING_FORM: "F-Admin-Booking_Form-WBA-210104-4.xlsx",
  SAMPLING_BOOKING_DOC: "WBA_Sampling_Booking.xlsx",
};

export const SUBMISSION_TASK_TYPE = ["testing", "inspection", "pac"];

export const SAMPLE_SEALING_RESULT_OPTIONS = [
  {
    value: "reject",
    label: "Reject",
  },
  {
    value: "accept",
    label: "Accept",
  },
  {
    value: "concession",
    label: "Concession",
  },
  // {
  //   value: "pending",
  //   label: "Pending",
  // },
  // {
  //   value: "awaitingResponse",
  //   label: "Awaiting response",
  // },
];
