import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import TextInput from "components/input/TextInput";
import UploadWithPreview from "components/input/UploadWithPreview";
import UploadFiles from "components/input/UploadFiles";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  titleMessage: {
    fontSize: 10,
  },
  infoMessage: {
    color: "#777777",
    fontSize: 10,
    paddingTop: 6,
    paddingBottom: 6,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  shortInputField: {
    width: 400,
  },
  blockPadding: {
    paddingRight: 10,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  alertMessage: {
    fontSize: 12,
    marginTop: 8,
    marginBottom: 16,
    width: "100%",
    maxWidth: "600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    "& .MuiAlert-icon": {
      fontSize: 18,
    },
  },
}));

const UploadDocuments = ({
  acknowledgeItemData = {},
  handleAcknowledgeItemDataChange,
  handleNextPage,
}) => {
  const classes = useStyles();

  const handleAddImages = (newImages) => {
    const images = acknowledgeItemData.images.concat(newImages);

    handleAcknowledgeItemDataChange("images", images);
  };

  const handleRemoveImage = (index) => {
    const images = acknowledgeItemData.images.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("images", images);
  };

  const handleAddProductSpecificationFiles = (newFiles) => {
    const files = acknowledgeItemData.productSpecification.concat(newFiles);

    handleAcknowledgeItemDataChange("productSpecification", files);
  };

  const handleRemoveProductSpecificationFile = (index) => {
    const files = acknowledgeItemData.productSpecification.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("productSpecification", files);
  };

  const handleAddArtworksFiles = (newFiles) => {
    const files = acknowledgeItemData.artworks.concat(newFiles);

    handleAcknowledgeItemDataChange("artworks", files);
  };

  const handleRemoveArtworksFile = (index) => {
    const files = acknowledgeItemData.artworks.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("artworks", files);
  };

  const handleAddOtherInformationFiles = (newFiles) => {
    const files = acknowledgeItemData.otherInformation.concat(newFiles);

    handleAcknowledgeItemDataChange("otherInformation", files);
  };

  const handleRemoveOtherInformationFile = (index) => {
    const files = acknowledgeItemData.otherInformation.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("otherInformation", files);
  };

  const handleAddAisUPCInformationFiles = (newFiles) => {
    const files = acknowledgeItemData.aisUPCInformation.concat(newFiles);

    handleAcknowledgeItemDataChange("aisUPCInformation", files);
  };

  const handleRemoveAisUPCInformationFile = (index) => {
    const files = acknowledgeItemData.aisUPCInformation.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("aisUPCInformation", files);
  };

  const handleAddAisOuterInnerFiles = (newFiles) => {
    const files = acknowledgeItemData.aisOuterInner.concat(newFiles);

    handleAcknowledgeItemDataChange("aisOuterInner", files);
  };

  const handleRemoveAisOuterInnerFile = (index) => {
    const files = acknowledgeItemData.aisOuterInner.filter((item, i) => i !== index);

    handleAcknowledgeItemDataChange("aisOuterInner", files);
  };

  const hasTwoProductImages = acknowledgeItemData?.images?.length >= 2;

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Master WIC Information from WBA</Box>
        <Box className={classes.subtitle}>Master WIC Number</Box>
        <TextInput
          placeholder="Master WIC Number"
          name="wicNumber"
          value={acknowledgeItemData.wicNumber}
          additionalClass={classes.inputField}
          disabled
        />

        <Box className={classes.subtitle}>Name & Description</Box>
        <TextInput
          placeholder="Name & Description"
          name="productName"
          value={acknowledgeItemData.productName}
          additionalClass={classes.inputField}
          disabled
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          1. Upload Product Image{" "}
          <span className={classes.titleMessage}>
            (upload at least 2 images, file size must be above 30 KB)
          </span>
        </Box>

        <Box className={classes.infoMessage}>
          All uploaded information is used for the purpose for the quotation process.
        </Box>

        {!hasTwoProductImages && (
          <Alert severity="error" className={classes.alertMessage}>
            Please upload at least 2 product images for reference.
          </Alert>
        )}

        <UploadWithPreview
          images={acknowledgeItemData.images}
          handleAddImages={handleAddImages}
          handleRemoveImage={handleRemoveImage}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          2. Upload Product Specification
        </Box>

        <Box className={classes.infoMessage}>
          Please upload product documents for lab quotation's reference. The more information is
          given, the more accurate quotation it is.
        </Box>

        <UploadFiles
          files={acknowledgeItemData.productSpecification}
          handleAddFiles={handleAddProductSpecificationFiles}
          handleRemoveFile={handleRemoveProductSpecificationFile}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>3. Upload Artworks</Box>

        <Box className={classes.infoMessage}>
          Please upload product documents for lab quotation's reference. The more information is
          given, the more accurate quotation it is.
        </Box>

        <UploadFiles
          files={acknowledgeItemData.artworks}
          handleAddFiles={handleAddArtworksFiles}
          handleRemoveFile={handleRemoveArtworksFile}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          4. Upload Other Information e.g. BOM/Circuit Diagram
        </Box>

        <Box className={classes.infoMessage}>
          Please upload product documents for lab quotation's reference. The more information is
          given, the more accurate quotation it is.
        </Box>

        <UploadFiles
          files={acknowledgeItemData.otherInformation}
          handleAddFiles={handleAddOtherInformationFiles}
          handleRemoveFile={handleRemoveOtherInformationFile}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          5. Upload AIS/Stibo - UPC information
        </Box>

        <UploadFiles
          files={acknowledgeItemData.aisUPCInformation}
          handleAddFiles={handleAddAisUPCInformationFiles}
          handleRemoveFile={handleRemoveAisUPCInformationFile}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          6. Upload AIS/Stibo - Outer/Inner
        </Box>

        <UploadFiles
          files={acknowledgeItemData.aisOuterInner}
          handleAddFiles={handleAddAisOuterInnerFiles}
          handleRemoveFile={handleRemoveAisOuterInnerFile}
        />
      </Paper>

      <Button
        data-cy="continue-acknow"
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={
          acknowledgeItemData.images.length < 2
          // || acknowledgeItemData.productSpecification.length === 0
          // || acknowledgeItemData.artworks.length === 0
          // || acknowledgeItemData.otherInformation.length === 0
          // || acknowledgeItemData.aisUPCInformation.length === 0
          // || acknowledgeItemData.aisOuterInner.length === 0
        }
      >
        CONTINUE
      </Button>
    </>
  );
};

export default UploadDocuments;
