import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";

import { SAMPLE_SEALING_RESULT_OPTIONS } from "shared/constants";

const useStyles = makeStyles(() => ({
  table: {
    fontSize: 12,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    "&:last-child": {
      borderLeft: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    "&:first-child": {
      borderRight: "none",
    },
    "&:last-child": {
      borderLeft: "none",
    },
    padding: 10,
  },
  remove: {
    color: "red",
    cursor: "pointer",
  },
}));

const SampleSealingResultTable = ({
  isUpdateResult,
  sampleSealingResultData = [],
  editSampleSealingResultData = [],
  handleResultDataChange,
  handleAddResultData,
  handleRemoveResultData,
}) => {
  const classes = useStyles();

  const resultOptions = SAMPLE_SEALING_RESULT_OPTIONS;

  return isUpdateResult ? (
    <Box className={classes.table}>
      <Grid container>
        <Grid item xs={4} className={classes.firstGrid} />
        <Grid item xs={3} className={classes.firstGrid}>
          Result
        </Grid>
        <Grid item xs={4} className={classes.firstGrid}>
          Remark
        </Grid>
        <Grid item xs={1} className={classes.firstGrid} />
      </Grid>
      {editSampleSealingResultData.map((item, index) => (
        <Grid key={index} container>
          <Grid item xs={4} className={classes.grid}>
            {`#${index + 1} sample sealing`}
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            <Select
              label="Result"
              name="result"
              value={item.result}
              options={resultOptions}
              handleChange={(e) => handleResultDataChange(e, index)}
              // additionalClass={classes.inputField}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <TextInput
              placeholder="Remark"
              name="remark"
              value={item.remark || ""}
              // additionalClass={classes.inputField}
              onChange={(e) => handleResultDataChange(e, index)}
            />
          </Grid>
          <Grid item xs={1} className={classes.grid}>
            <Box className={classes.remove} onClick={() => handleRemoveResultData(index)}>
              Remove
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <OutlinedButton
            label="ADD MORE RESULT"
            handleClick={handleAddResultData}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.table}>
      <Grid container>
        <Grid item xs={4} className={classes.firstGrid} />
        <Grid item xs={4} className={classes.firstGrid}>
          Result
        </Grid>
        <Grid item xs={4} className={classes.firstGrid}>
          Remark
        </Grid>
      </Grid>
      {sampleSealingResultData.map((item, index) => (
        <Grid key={index} container>
          <Grid item xs={4} className={classes.grid}>
            {/* {item.name} */}
            {`#${index + 1} sample sealing`}
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            {item.result}
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            {item.remark || "N/A"}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default SampleSealingResultTable;
