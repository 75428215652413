import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import StepHeader from "../../lab/components/StepHeader";

import SelectTasks from "./SelectTasks";
import InspectionReport from "./InspectionReport";
import TestingReport from "./TestingReport";

import { atnGetSubmissionDetails, atnPutSubmissionResumeReport } from "actions/submissionActions";

import auth from "auth/authenticator";

import { getTaskFlow } from "services/taskFlowService";

import useLoading from "hooks/useLoading";

export default function UploadResumeReport({ match, history }) {
  const submissionId = match.params.submissionId;
  const userType = auth.userType();

  const classes = useStyles();

  const pageTitle = ["Step 1: Select Tasks", "Step 2: Upload Report"];

  const { isLoading, loading, done } = useLoading();

  const [submissionType, setSubmissionType] = useState("");
  const [isProgram777, setIsProgram777] = useState(false);

  const [step, setStep] = useState(0);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);

  const [reportData, setReportData] = useState({
    selectedTasks: [],
    reports: [],
  });

  const [headerTitle, setHeaderTitle] = useState(pageTitle[0]);

  useEffect(() => {
    atnGetSubmissionDetails({
      type: userType,
      data: {
        id: submissionId,
      },
    })
      .then(({ data }) => {
        if (data?.taskType) {
          const { isSamplePickUp, isInspection, isTesting } = getTaskFlow(data?.taskType);

          setIsProgram777(data?.isProgram777);

          if (isSamplePickUp || isInspection) {
            setSubmissionType("inspection");
          } else if (isTesting) {
            setSubmissionType("testing");
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handleReportDataChange = (e) => {
    const { name, value } = e.target;

    setReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRemoveReport = (index) => {
    setReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewReport = (report) => {
    setReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.concat(report),
    }));
  };

  const handleEditTestingReport = (index, newReportData) => {
    setReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, reportIndex) => {
        if (reportIndex === index) {
          item = newReportData;
        }

        return item;
      }),
    }));
  };

  const handleEditReport = (e, index) => {
    const { name, value } = e.target;

    setReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handlePrevStep = () => {
    if (step > 0) {
      setHeaderTitle(pageTitle[step - 1]);

      setStep(step - 1);
    }
  };

  const handleNextStep = () => {
    if (step === 0) {
      setHeaderTitle(pageTitle[step + 1]);
      setStep(step + 1);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const checkIsMissingData = () => {
    const isMissingReportData =
      reportData?.reports?.some((report) => !report?.issueDate || !report?.result) || false;

    return isMissingReportData;
  };

  const handleSubmit = () => {
    if (checkIsMissingData()) return;

    loading();

    const selectedTaskIds = reportData?.selectedTasks?.map((task) => task.id);

    const selectedReports = reportData?.reports?.map(
      ({ fileId, fileName, result, issueDate, pb, remark, testItems }) => ({
        fileId,
        name: fileName,
        result,
        issueDate,
        pb,
        remark,
        testItems,
      }),
    );

    const submitData = {
      tasks: selectedTaskIds,
      reports: selectedReports,
    };

    atnPutSubmissionResumeReport({
      data: {
        id: submissionId,
        data: submitData,
      },
      type: userType,
    })
      .then(({ data, message }) => {
        done();

        if (!data) return;

        handleClose();
        setIsDisabledConformButton(true);
      })
      .catch((err) => {
        done();
      });
  };

  const isStep0 = step === 0;
  const isStep1 = step === 1;

  return (
    <Box className={classes.root}>
      <StepHeader
        step={step}
        header="Resume Report"
        title={headerTitle}
        handlePrevStep={handlePrevStep}
        handleClose={handleClose}
      />

      {isStep0 && (
        <SelectTasks
          isProgram777={isProgram777}
          step={step}
          userType={userType}
          submissionId={submissionId}
          reportData={reportData}
          onReportDataChange={handleReportDataChange}
          onNextPage={handleNextStep}
        />
      )}

      {isStep1 && submissionType === "testing" && (
        <TestingReport
          submissionId={submissionId}
          reportData={reportData}
          onAddNewFilesChange={handleAddNewReport}
          onReportDataChange={handleEditTestingReport}
          onRemoveFile={handleRemoveReport}
          onSubmit={handleSubmit}
          disabledSubmit={isLoading || isDisabledConformButton}
        />
      )}

      {isStep1 && submissionType === "inspection" && (
        <InspectionReport
          isProgram777={isProgram777}
          submissionId={submissionId}
          reportData={reportData}
          onAddNewFilesChange={handleAddNewReport}
          onReportDataChange={handleEditReport}
          onRemoveFile={handleRemoveReport}
          onSubmit={handleSubmit}
          disabledSubmit={isLoading || isDisabledConformButton}
        />
      )}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectFieldContainer: {
    paddingTop: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  selectField: {
    width: "100%",
  },
  selectTaskContainer: {
    paddingTop: 50,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
}));
