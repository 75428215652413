import React from "react";
import { Box, Grid, styled, Tooltip } from "@material-ui/core";

import { textOrUnknown } from "utils/string";
import { NA } from "shared/constants";

const StyledChip = styled("span")({
  background: "#003DA5",
  borderRadius: 2,
  fontSize: 10,
  color: "#fff",
  fontWeight: 500,
  marginRight: 6,
  marginTop: "4px",
  marginBottom: "4px",
  padding: "0 4px",
  cursor: "pointer",
  display: "inline-block",
});

export default function TenderingChip({ detail, label = "T" }) {
  return detail ? (
    <span>
      <Tooltip
        title={
          <Grid container>
            {detail?.assignedLab && detail?.assignedLab !== "" && (
              <Grid item xs={12}>
                Lab: {textOrUnknown(detail?.assignedLab)}
              </Grid>
            )}

            {detail?.taskType && detail?.taskType !== "" && (
              <Grid item xs={12}>
                Task Type: {textOrUnknown(detail?.taskType)}
              </Grid>
            )}

            {detail?.labExemptions?.length > 0 && (
              <Grid item xs={12}>
                <Box>Exemption: {!detail?.labExemptions?.length && NA}</Box>

                {detail?.labExemptions?.length
                  ? detail?.labExemptions?.map((exception, index) => {
                      const isPreSafetyTest = exception?.taskType === "PST";
                      const isSamplePickUp = exception?.taskType === "SPU";
                      const isSafetyTest = exception?.taskType === "ST";
                      const isQualityTest = exception?.taskType === "QT";
                      const isDPI = exception?.taskType === "DPI";
                      const isFRI = exception?.taskType === "FRI";

                      return (
                        <React.Fragment key={index}>
                          {isPreSafetyTest && (
                            <Box>Pre-Safety Test - To {textOrUnknown(exception?.labName)}</Box>
                          )}

                          {isSamplePickUp && (
                            <Box>Sample Pickup - To {textOrUnknown(exception?.labName)}</Box>
                          )}

                          {isSafetyTest && (
                            <Box>Safety Test - To {textOrUnknown(exception?.labName)}</Box>
                          )}

                          {isQualityTest && (
                            <Box>Quality Test - To {textOrUnknown(exception?.labName)}</Box>
                          )}

                          {isDPI && (
                            <Box>
                              During Production Inspection - To {textOrUnknown(exception?.labName)}
                            </Box>
                          )}

                          {isFRI && (
                            <Box>
                              Final Random Inspection - To {textOrUnknown(exception?.labName)}
                            </Box>
                          )}
                        </React.Fragment>
                      );
                    })
                  : null}
              </Grid>
            )}
          </Grid>
        }
      >
        <StyledChip>{label}</StyledChip>
      </Tooltip>
    </span>
  ) : (
    <StyledChip>{label}</StyledChip>
  );
}
