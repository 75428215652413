import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TabSideMenu from "components/tab/TabSideMenu/index";
import TaskData from "components/statistics/TaskData/index";
import Tasks from "./Tasks/index";
import { formatHeaderBuyer } from "utils/formatHomePageTaskTableData";
import { downloadFile } from "utils/files";

import {
  atnExportBuyerHomeTaskListCsv,
  atnGetBuyerHomeMenu,
  atnGetBuyerHomeStatistics,
  atnGetBuyerTaskResult,
  atnGetBuyerTaskResultHeader,
} from "actions/homePageActions";

const useStyles = makeStyles(() => ({
  tabSideMenuContainer: {
    position: "fixed",
    top: 40,
    bottom: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 999,
  },
  contentContainer: {
    marginLeft: 300,
  },
  taskDataContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#F7F9FF",
  },
  tasksContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#FAFAFA",
  },
}));

const WbaHomePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const sideMenuItems = [
    {
      name: "supplierName",
      label: "Supplier",
      hasSearchFunction: true,
      searchKeywordPlaceholder: "Search supplier name...",
    },
    {
      name: "taskType",
      label: "Task Type",
      hasSearchFunction: false,
      searchKeywordPlaceholder: "",
    },
    {
      name: "program",
      label: "Program",
      hasSearchFunction: true,
      searchKeywordPlaceholder: "Search program name...",
    },
  ];
  const [loadingTable, setLoadingTable] = useState(false);
  const [sideMenuItemValue, setSideMenuValue] = useState(0);
  const [sideMenuSearchKeyword, setSideMenuSearchKeyword] = useState("");
  const [showTaskList, setShowTaskList] = useState(false);
  const [programYear, setProgramYear] = useState("");
  const [category, setCategory] = useState("");
  const [tabItems, setTabItems] = useState({
    supplierName: [],
    taskType: [],
    program: [],
  });

  const [filteredTabItems, setFilteredTabItems] = useState({
    supplierName: [],
    taskType: [],
    program: [],
  });

  const [selectedPanelItem, setSelectedPanelItem] = useState({
    tab: "supplierName",
    item: "all",
  });

  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [progressNumber, setProgressNumber] = useState({
    total: 0,
  });

  const [tableNumberType, setTableNumberType] = useState({
    table: "",
    dataType: "",
    taskType: "",
  });

  const [selectedFilter, setSelectedFilter] = useState({
    supplierName: [],
    labName: [],
    taskType: [],
    taskStatus: [],
  });

  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });

  const [headerData, setHeaderData] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [taskCompletionRateData, setTaskCompletionRateData] = useState([]);
  const [outstandingTaskData, setOutstandingTaskData] = useState([]);

  const getStatisticsData = () => {
    let value = selectedPanelItem.item;

    if (selectedPanelItem.item === "all") {
      value = "";
    } else if (selectedPanelItem.tab === "program") {
      value = selectedPanelItem.item.split("(")[0];
    }

    atnGetBuyerHomeStatistics({
      type: selectedPanelItem.tab,
      value: value,
      programYear: programYear,
      category: category,
    }).then(({ data, message }) => {
      if (data) {
        setTaskCompletionRateData(data.mainStatistic);
        let outstandingTask = [
          {
            name: "overDueTasks",
            data: [
              {
                name: "overDueTasks",
                label: "Overdue – Today > Planned Date",
                hasNumber: true,
                number: data.outstandingTask[0].overDueTasks,
                searchConditions: {
                  table: "outstandingTasks",
                  dataType: "overDueTasks",
                  taskType: "",
                },
              },
            ],
          },
          {
            name: "failPendingTasks",
            data: [
              {
                name: "failPendingTasks",
                label: "Fail/Pending Tasks",
                hasNumber: true,
                number: data.outstandingTask[1].pendingRejectTasks,
                searchConditions: {
                  table: "outstandingTasks",
                  dataType: "pendingRejectTasks",
                  taskType: "",
                },
              },
            ],
          },
          {
            name: "plannedDateGTDeadlineTasks",
            data: [
              {
                name: "plannedDateGTDeadlineTasks",
                label: "Planned Date > WBA's Deadline",
                hasNumber: true,
                number: data.outstandingTask[2].plannedDateGTDeadlineTasks,
                searchConditions: {
                  table: "outstandingTasks",
                  dataType: "plannedDateGTDeadlineTasks",
                  taskType: "",
                },
              },
            ],
          },
        ];

        setOutstandingTaskData(outstandingTask);
      }
    });
  };

  useEffect(() => {
    let type = selectedPanelItem.tab;

    if (selectedPanelItem.tab === "supplierName") {
      type = "supplier";
    }

    atnGetBuyerHomeMenu({
      type: type,
    }).then(({ data, message }) => {
      if (data) {
        let tempData = [];
        let totalTasks = 0;

        for (let i = 0; i < data.length; i++) {
          totalTasks += data[i].totalTasks || 0;

          tempData.push({
            itemName: data[i].name,
            tab: "supplierName",
            title: data[i].name,
            programYear: data[i].programYear,
            category: data[i].category,
            info: data[i].programDesc,
            message: "Click here to view",
            number: data[i].totalTasks,
            isTenderingProgram: data[i]?.isTenderingProgram,
          });
        }

        let firstItem = [
          {
            itemName: "all",
            tab: "supplierName",
            title: t(`buyerHomePageTabFirstItem.${selectedPanelItem.tab}`),
            info: "",
            message: "Click here to view",
            number: totalTasks,
          },
        ];

        setTabItems((prevState) => ({
          ...prevState,
          [selectedPanelItem.tab]: firstItem.concat(tempData),
        }));
      }
    });

    atnGetBuyerTaskResultHeader({}).then(({ data, message }) => {
      if (data) {
        setHeaderData(formatHeaderBuyer(data, t));
      }
    });

    getStatisticsData();
  }, []);

  useEffect(() => {
    getStatisticsData();
  }, [selectedPanelItem.tab, selectedPanelItem.item]);

  const handleGetTasks = (page) => {
    if (loadingTable) {
      return;
    }

    setLoadingTable(true);

    let filters = [];
    let filterTaskStatus = [];

    if (selectedPanelItem.item !== "all") {
      let value = selectedPanelItem.item;

      if (selectedPanelItem.tab === "program") {
        value = selectedPanelItem.item.split("(")[0];
      }

      filters.push({
        name: selectedPanelItem.tab,
        value: [value],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        if (key === "taskStatus") {
          filterTaskStatus = selectedFilter[key];
        } else {
          filters.push({
            name: key,
            value: selectedFilter[key],
          });
        }
      }
    });

    if (tableNumberType.table === "taskCompletionRateTable") {
      if (tableNumberType.dataType === "all") {
      } else if (tableNumberType.dataType === "not_started") {
        filterTaskStatus = filterTaskStatus.concat([
          "not_open_for_quote",
          "not_open",
          "waiting_for_lab_quote",
          "quote_available",
        ]);
      } else if (tableNumberType.dataType === "in_progress") {
        filterTaskStatus = filterTaskStatus.concat([
          "quote_confirmed_and_waiting_for_sample",
          "sample_received",
          "in_progress",
          "in_progress_revise_update",
          "pending",
          "retest_in_progress",
          "quote_confirmed",
          "in_progress_result_report_updated",
          "arranging_sample",
          "under_review",
          "booking_reserved",
          "waiting_for_wba_approval",
          "in_progress_upload_pac_cert",
        ]);
      } else if (tableNumberType.dataType === "completed") {
        filterTaskStatus = filterTaskStatus.concat([
          "completed",
          "completed_reject",
          "completed_accept",
          "completed_concession",
          "fail",
        ]);
      }

      if (tableNumberType.taskType !== "all") {
        // console.log(tableNumberType.taskType, filters);

        if (filters.findIndex((item) => item.name === "taskType") === -1) {
          // push taskType if filters[] does not have taskType in filters object
          filters.push({
            name: "taskType",
            value: [tableNumberType.taskType],
          });
        } else {
          //for taskType tab, concat value if already has taskType in filters object
          filters.forEach((item) => {
            if (item.name === "taskType") {
              item.value = [...item.value, tableNumberType.taskType];
            }
          });
        }
      }
    } else if (tableNumberType.table === "outstandingTasks") {
      filters.push({
        name: tableNumberType.dataType,
        value: [tableNumberType.dataType],
      });

      // if (tableNumberType.dataType === "overDueTasks") {

      // } else if (tableNumberType.dataType === "pendingRejectTasks") {

      // }
    }

    if (filterTaskStatus.length > 0) {
      // let tempFilterTaskStatus = filterTaskStatus.filter((item, index) => filterTaskStatus.indexOf(item) !== index);
      let tempFilterTaskStatus = [...new Set(filterTaskStatus)];

      filters.push({
        name: "taskStatus",
        value: tempFilterTaskStatus,
      });
    }

    if (programYear) {
      filters.push({
        name: "programYear",
        value: [programYear],
      });
    }

    if (category) {
      filters.push({
        name: "category",
        value: [category],
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: pageData.rowsPerPage,
      filters: filters,
      search: searchConditions.search,
    };

    atnGetBuyerTaskResult(conditions).then(({ data, message }) => {
      if (data) {
        atnGetBuyerTaskResultHeader({
          search: searchConditions.search,
          filters: filters,
        }).then(({ data: headerResult, message }) => {
          if (headerResult) {
            setHeaderData(formatHeaderBuyer(headerResult, t));
          }
        });

        // setTaskList(formatRow(headerData, data.list, t));
        setTaskList(data.list);
        setProgressNumber(data.progressNumber);
        let totalRecords = data.progressNumber.total;
        let totalPages = Math.ceil(data.progressNumber.total / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    handleGetTasks();
  }, [
    pageData.currentPage,
    pageData.rowsPerPage,
    searchConditions.orderBy,
    searchConditions.order,
    selectedFilter.labName.length,
    selectedFilter.supplierName.length,
    selectedFilter.taskType.length,
    selectedFilter.taskStatus.length,
    tableNumberType.table,
    tableNumberType.dataType,
    tableNumberType.taskType,
  ]);

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));
  };

  const handleMenuChange = (event, newValue) => {
    if (newValue === sideMenuItemValue) {
      return;
    }

    setShowTaskList(false);
    setSearchConditions({
      orderBy: "",
      order: "",
      search: "",
    });
    setTableNumberType({
      table: "",
      dataType: "",
      taskType: "",
    });

    let type = sideMenuItems[newValue].name;

    if (sideMenuItems[newValue].name === "supplierName") {
      type = "supplier";
    }

    atnGetBuyerHomeMenu({
      type: type,
    }).then(({ data, message }) => {
      if (data) {
        let tempData = [];
        let totalTasks = 0;
        let title = "";

        for (let i = 0; i < data.length; i++) {
          totalTasks += data[i].totalTasks || 0;

          title = type === "taskType" ? t(`taskShort.${data[i].name}`) : data[i].name;

          if (data[i].programYear) {
            title += " " + data[i].programYear;
          }

          tempData.push({
            itemName: data[i].name,
            tab: sideMenuItems[newValue].name,
            title: title,
            programYear: data[i].programYear,
            category: data[i].category,
            info: data[i].programDesc,
            message: "Click here to view",
            number: data[i].totalTasks,
            isTenderingProgram: data[i]?.isTenderingProgram,
          });
        }

        let firstItem = [
          {
            itemName: "all",
            tab: sideMenuItems[newValue].name,
            title: t(`buyerHomePageTabFirstItem.${sideMenuItems[newValue].name}`),
            info: "",
            message: "Click here to view",
            number: totalTasks,
          },
        ];

        setTabItems((prevState) => ({
          ...prevState,
          [sideMenuItems[newValue].name]: firstItem.concat(tempData),
        }));
        setSideMenuValue(newValue);
        setSideMenuSearchKeyword("");
        setSelectedPanelItem({
          tab: sideMenuItems[newValue].name,
          item: "all",
        });
      }
    });
  };

  const handlePanelItemChange = (itemName, item) => {
    if (item.programYear) {
      setProgramYear(item.programYear);
    } else {
      setProgramYear("");
    }

    if (item.category) {
      setCategory(item.category);
    } else {
      setCategory("");
    }

    setShowTaskList(false);
    setSearchConditions({
      orderBy: "",
      order: "",
      search: "",
    });
    setTableNumberType({
      table: "",
      dataType: "",
      taskType: "",
    });
    setSelectedPanelItem((prevState) => ({
      ...prevState,
      item: itemName,
    }));
  };

  const handleSideMenuSearchKeywordChange = (event) => {
    const { value } = event.target;

    let newItems = tabItems[selectedPanelItem.tab].filter(
      (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) >= 0,
    );

    setSideMenuSearchKeyword(value);
    setFilteredTabItems((prevState) => ({
      ...prevState,
      [selectedPanelItem.tab]: newItems,
    }));
  };

  const handleTableDataClick = (data) => {
    setTableNumberType(data);
    setSearchConditions({
      orderBy: "",
      order: "",
      search: "",
    });
    setSelectedFilter({
      supplierName: [],
      labName: [],
      taskType: [],
      taskStatus: [],
    });
    setShowTaskList(true);
  };

  const handleSearchTaskKeywordChange = (event) => {
    const { value } = event.target;

    setSearchConditions((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const handleTaskSearch = () => {
    handleGetTasks(1);
  };

  const handleExportButtonClick = () => {
    let filters = [];
    let filterTaskStatus = [];

    if (selectedPanelItem.item !== "all") {
      let value = selectedPanelItem.item;

      if (selectedPanelItem.tab === "program") {
        value = selectedPanelItem.item.split("(")[0];
      }

      filters.push({
        name: selectedPanelItem.tab,
        value: [value],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        if (key === "taskStatus") {
          filterTaskStatus = selectedFilter[key];
        } else {
          filters.push({
            name: key,
            value: selectedFilter[key],
          });
        }
      }
    });

    if (tableNumberType.table === "taskCompletionRateTable") {
      if (tableNumberType.dataType === "all") {
      } else if (tableNumberType.dataType === "not_started") {
        filterTaskStatus = filterTaskStatus.concat([
          "not_open_for_quote",
          "not_open",
          "waiting_for_lab_quote",
          "quote_available",
        ]);
      } else if (tableNumberType.dataType === "in_progress") {
        filterTaskStatus = filterTaskStatus.concat([
          "quote_confirmed_and_waiting_for_sample",
          "sample_received",
          "in_progress",
          "in_progress_revise_update",
          "pending",
          "retest_in_progress",
          "quote_confirmed",
          "in_progress_result_report_updated",
          "arranging_sample",
          "under_review",
          "booking_reserved",
          "waiting_for_wba_approval",
          "in_progress_upload_pac_cert",
        ]);
      } else if (tableNumberType.dataType === "completed") {
        filterTaskStatus = filterTaskStatus.concat([
          "completed",
          "completed_reject",
          "completed_accept",
          "completed_concession",
          "fail",
        ]);
      }

      if (tableNumberType.taskType === "all") {
      } else {
        filters.push({
          name: "taskType",
          value: [tableNumberType.taskType],
        });
      }
    } else if (tableNumberType.table === "outstandingTasks") {
      filters.push({
        name: tableNumberType.dataType,
        value: [tableNumberType.dataType],
      });
      // if (tableNumberType.dataType === "overDueTasks") {

      // } else if (tableNumberType.dataType === "pendingRejectTasks") {

      // }
    }

    if (filterTaskStatus.length > 0) {
      let tempFilterTaskStatus = [...new Set(filterTaskStatus)];

      filters.push({
        name: "taskStatus",
        value: tempFilterTaskStatus,
      });
    }

    if (programYear) {
      filters.push({
        name: "programYear",
        value: [programYear],
      });
    }

    if (category) {
      filters.push({
        name: "category",
        value: [category],
      });
    }

    if (programYear) {
      filters.push({
        name: "programYear",
        value: [programYear],
      });
    }

    if (category) {
      filters.push({
        name: "category",
        value: [category],
      });
    }

    let formattedHeader = [];

    for (let i = 0; i < headerData.length; i++) {
      if (headerData[i].name === "product") {
        formattedHeader.push("wicNumber");
        formattedHeader.push("productName");
      } else if (headerData[i].name === "taskType") {
        formattedHeader.push("taskType");
        formattedHeader.push("taskNumber");
      } else if (headerData[i].name === "result") {
        formattedHeader.push("masterWicResult");
      } else {
        formattedHeader.push(headerData[i].name);
      }
    }

    let conditions = {
      ...searchConditions,
      currentPage: 1,
      rowsPerPage: 99999,
      filters: filters,
      search: searchConditions.search,
      header: formattedHeader.toString(),
    };

    atnExportBuyerHomeTaskListCsv(conditions).then(async (data) => {
      downloadFile(data, "Item_List.xlsx");
    });
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].concat(filterItemValue),
      }));
    } else {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
      }));
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  return (
    <Box>
      <Box className={classes.tabSideMenuContainer}>
        <TabSideMenu
          sideMenuItems={sideMenuItems}
          sideMenuItemValue={sideMenuItemValue}
          sideMenuSearchKeyword={sideMenuSearchKeyword}
          tabItems={tabItems}
          filteredTabItems={filteredTabItems}
          selectedPanelItem={selectedPanelItem}
          handleMenuChange={handleMenuChange}
          handlePanelItemChange={handlePanelItemChange}
          handleSideMenuSearchKeywordChange={handleSideMenuSearchKeywordChange}
        />
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.taskDataContainer}>
          <TaskData
            taskCompletionRateData={taskCompletionRateData}
            outstandingTaskData={outstandingTaskData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>
        {showTaskList && (
          <Box className={classes.tasksContainer}>
            <Tasks
              headerData={headerData}
              rowData={taskList}
              // tasksTableData={tasksTableData}
              searchTaskKeyword={searchConditions.search}
              handleSearchTaskKeywordChange={handleSearchTaskKeywordChange}
              handleTaskSearch={handleTaskSearch}
              handleExportButtonClick={handleExportButtonClick}
              handleSorting={handleSorting}
              handleFilterListToggle={handleFilterListToggle}
              handleFilterItemSelect={handleFilterItemSelect}
              selectedFilter={selectedFilter}
              selectedPanelItem={selectedPanelItem}
              tableNumberType={tableNumberType}
              rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
              currentPage={pageData.currentPage}
              rowsPerPage={pageData.rowsPerPage}
              totalPages={pageData.totalPages}
              totalRecords={pageData.totalRecords}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WbaHomePage;
