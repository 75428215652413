import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import Select from "components/input/Select";
import Program777Chip from "components/display/Program777Chip";

import { atnGetRelatedTasks } from "actions/submissionActions";

import { isShowProgram777 } from "services/pbService";

import { SUBMISSION_TASK_TYPE } from "shared/constants";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

export default function UpdateStatusSelectUpdateType({
  updateStatusData,
  submissionId,
  isProgram777 = false,
  triggeredBy = "", //inspection -> pb | po
  isUploadPACCert = false, //pac -> boolean
  submissionType, //TESTING | INSPECTION | PAC
  handleNextPage,
  handleUpdateStatusDataChange,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState(updateStatusData?.selectedTasks);

  const [selectOptions, setSelectOptions] = useState([]);

  const [TESTING, INSPECTION, PAC] = SUBMISSION_TASK_TYPE;

  const isInspection = submissionType === INSPECTION;
  const isTesting = submissionType === TESTING;
  const isPac = submissionType === PAC;

  const inspectionOptions = [
    {
      value: "booking_reserved",
      label: "Booking Reserved",
    },
    {
      value: "in_progress_result_report_updated",
      label: "Update Report & Result",
    },
    {
      value: "completed",
      label: "Completed",
    },
  ];

  const testingOptions = [
    {
      value: "sample_received",
      label: "Sample Received",
    },
    {
      value: "in_progress",
      label: "Test In Progress",
    },
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "in_progress_result_report_updated",
      label: "Test In Progress - Update Result & Reports",
    },
    {
      value: "completed",
      label: "Completed",
    },
  ];

  const pacOptions = isUploadPACCert
    ? [
        {
          value: "in_progress_upload_pac_cert",
          label: "Upload PAC Certification",
        },
      ]
    : [
        {
          value: "sample_received",
          label: "Sample Received",
        },
        {
          value: "in_progress",
          label: "Test In Progress",
        },
        {
          value: "pending",
          label: "Pending",
        },
        {
          value: "in_progress_result_report_updated",
          label: "Test In Progress - Update Result & Reports",
        },
        {
          value: "in_progress_upload_pac_cert",
          label: "Test In Progress - Upload PAC Certification",
        },
        {
          value: "completed",
          label: "Completed",
        },
      ];

  const showProgram777 = isShowProgram777(triggeredBy);

  const isAllSelected = selectedTaskIds?.length === relatedTasks?.length;
  const isSomeSelected = selectedTaskIds?.length > 0 && !isAllSelected;

  useEffect(() => {
    if (!submissionType) return;

    //* check PAC task type first for share cost
    if (isPac) return setSelectOptions(pacOptions);
    if (isInspection) return setSelectOptions(inspectionOptions);
    if (isTesting) return setSelectOptions(testingOptions);
  }, [submissionType]);

  useEffect(() => {
    if (!updateStatusData?.selectedTasks) return;

    setSelectedTasks(updateStatusData?.selectedTasks);
    let tempSelectedTaskIds = [];

    for (let i = 0; i < updateStatusData?.selectedTasks?.length; i++) {
      tempSelectedTaskIds.push(updateStatusData?.selectedTasks[i].id);
    }

    setSelectedTaskIds(tempSelectedTaskIds);
  }, [updateStatusData?.selectedTasks]);

  useEffect(() => {
    if (!updateStatusData?.updateType) return;

    getRelatedTasks(updateStatusData?.updateType);
  }, [submissionId, updateStatusData?.updateType]);

  const getRelatedTasks = (type) => {
    setLoading(true);
    setRelatedTasks([]);

    atnGetRelatedTasks({
      submissionId,
      updateType: type,
    }).then(({ data, message }) => {
      if (data) {
        setRelatedTasks(data);
        setLoading(false);
      }
    });
  };

  const handleUpdateTypeChange = (e) => {
    handleUpdateStatusDataChange(e);
  };

  const handleTaskSelect = (task) => {
    if (selectedTaskIds.indexOf(task.id) >= 0) {
      setSelectedTasks((prevState) => {
        return prevState.filter((item) => item.id !== task.id);
      });

      setSelectedTaskIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      if ((isInspection && showProgram777) || isProgram777) {
        setSelectedTasks([task]);

        setSelectedTaskIds([task.id]);
      } else {
        setSelectedTasks((prevState) => {
          return prevState.concat(task);
        });

        setSelectedTaskIds((prevState) => {
          return prevState.concat(task.id);
        });
      }
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedTasks([]);
      setSelectedTaskIds([]);
    } else {
      setSelectedTasks(relatedTasks);
      setSelectedTaskIds(relatedTasks.map((task) => task.id));
    }
  };

  const handleConfirm = () => {
    // check empty
    handleUpdateStatusDataChange({
      target: {
        name: "selectedTasks",
        value: selectedTasks,
      },
    });
    handleNextPage(selectedTasks);
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Please Select Status Update Type</Box>

      <Paper className={classes.container}>
        <Box className={classes.sectionTitle}>Select Update Type</Box>

        <Box className={classes.selectFieldContainer}>
          <Select
            label="Update Type (Select One)"
            name="updateType"
            value={updateStatusData?.updateType}
            options={selectOptions}
            handleChange={handleUpdateTypeChange}
            additionalClass={classes.selectField}
          />
        </Box>

        {updateStatusData?.updateType && (
          <Box className={classes.selectTaskContainer}>
            <Box className={classes.sectionTitle}>Select Tasks</Box>

            {(isInspection && showProgram777) ||
              (isProgram777 && (
                <Box className={classes.selectTaskMessage}>
                  *You can only select 1 product item in 777 submission.
                </Box>
              ))}

            {!loading && relatedTasks.length === 0 && <Box>No Task</Box>}

            {!loading && relatedTasks.length > 0 && (
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {/* checkbox: hide if program777 */}
                      {(isInspection && showProgram777) || isProgram777 ? (
                        <TableCell className={classes.tableHeaderCell} />
                      ) : (
                        <TableCell className={classes.tableHeaderCell} onClick={handleSelectAll}>
                          <Box className={classes.selectAllCell}>
                            {isSomeSelected ? (
                              <IndeterminateCheckBoxIcon />
                            ) : isAllSelected ? (
                              <CheckBoxIcon className={classes.checkBoxIcon} />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkBoxOutlineBlankIcon}
                              />
                            )}
                          </Box>
                        </TableCell>
                      )}

                      <TableCell className={classes.tableHeaderCell}>Product Name</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Program</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Task Type</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Factory</TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        {SIMPLE_BUYER_NAME} Deadline
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {relatedTasks.map((task) => {
                      const showProgram777LabelRelatedTasks =
                        isShowProgram777(task.triggeredBy) || isProgram777;

                      return (
                        <TableRow
                          key={task.id}
                          className={classes.tableRow}
                          onClick={() => handleTaskSelect(task)}
                        >
                          <TableCell scope="row" align="center" className={classes.tableCell}>
                            {selectedTaskIds.indexOf(task.id) >= 0 ? (
                              <CheckBoxIcon className={classes.checkBoxIcon} />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkBoxOutlineBlankIcon}
                              />
                            )}
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box display="flex" className={classes.tableText}>
                              {isInspection && showProgram777LabelRelatedTasks && (
                                <Program777Chip />
                              )}
                              {task.wicNumber}
                            </Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              {task.productName}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{task.programName}</Box>
                            <Box className={classnames(classes.buyer, classes.tableText)}>
                              {task.buyerCompanyName}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{t(`task.${task.taskType}`)}</Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              {task.taskNumber}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>{task.factoryName}</Box>
                            <Box className={classnames(classes.info, classes.tableText)}>
                              p{task.factoryAddress}
                            </Box>
                          </TableCell>

                          <TableCell scope="row" align="left" className={classes.tableCell}>
                            <Box className={classes.tableText}>
                              {moment(task.deadLine).format("DD/MMM/YYYY")}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleConfirm}
        disabled={selectedTasks.length === 0}
      >
        CONTINUE
      </Button>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  selectAllCell: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectFieldContainer: {
    paddingTop: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  selectField: {
    width: "100%",
  },
  selectTaskContainer: {
    paddingTop: 50,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  selectTaskMessage: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 3,
    color: "#D42600",
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));
