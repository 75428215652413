import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer";

export default function configureStore(preloadedState) {
  const middleWares = [thunkMiddleware];

  const enhancer =
    process.env.NODE_ENV === "development"
      ? composeWithDevTools(applyMiddleware(...middleWares))
      : applyMiddleware(...middleWares);

  return createStore(rootReducer, preloadedState, enhancer);
}
