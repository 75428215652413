import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import TextInput from "components/input/TextInput";
import Button from "components/input/Button";
import { DATE_TIME_FORMAT_01 } from "shared/constants";
import { getDateFormat } from "utils/date";
import {
  antCreateSubmissionRemark,
  antCreateSubmissionRemarkBySubmission,
  antGetSubmissionRemarks,
  antGetSubmissionRemarksBySubmission,
} from "actions/taskRemarkActions";
import useUserType from "../../../hooks/useUserType";
import auth from "../../../auth/authenticator";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  messageList: {
    maxHeight: 600,
    overflowY: "auto",
    marginBottom: theme.spacing(2),
  },
  messageItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  messageItemLeft: {
    display: "flex",
    flexDirection: "column",
  },
  messageInput: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 500,
  },
  blackText: {
    color: "#000000",
  },
  inputBox: {
    position: "relative",
    width: "100%",
  },
  sendBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#003DA5",
  },
}));

const SubmissionRemark = ({ data, userType, isSubmission = false }) => {
  const { isSupplier } = useUserType(auth.userType());
  const classes = useStyles();
  const [comments, setComments] = React.useState([]);
  const [message, setMessage] = React.useState("");

  const getComments = (id) => {
    antGetSubmissionRemarks(id).then((result) => {
      setComments(result.data);
    });
  };

  const getSubmissionComments = (id) => {
    antGetSubmissionRemarksBySubmission(id).then((result) => {
      setComments(result.data);
    });
  };

  React.useEffect(() => {
    if (!data.id) return;

    if (isSubmission) {
      getSubmissionComments(data.id);
    } else {
      getComments(data.id);
    }

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitComment = (id, value) => {
    if (isSubmission) {
      antCreateSubmissionRemarkBySubmission(id, { remarks: value }).then((result) => {
        setMessage("");
        getSubmissionComments(data.id);
      });
    } else {
      antCreateSubmissionRemark(id, { remarks: value }).then((result) => {
        setMessage("");
        getComments(data.id);
      });
    }
  };

  const Item = ({ data: { remarks, createdAt, createdBy } }) => (
    <Box className={classes.messageItem}>
      <Box className={classes.messageItemLeft}>
        <Typography className={classnames(classes.text, classes.bold, classes.blackText)}>
          {createdBy}
        </Typography>
        <Typography className={classnames(classes.text, classes.blackText)}>{remarks}</Typography>
      </Box>
      <Box className={classes.messageItemRight}>
        {getDateFormat(createdAt, DATE_TIME_FORMAT_01)}
      </Box>
    </Box>
  );

  return (
    <Paper className={classes.container}>
      <Box className={classes.messageList}>
        {comments.map((item) => (
          <Item key={item.id} data={item} />
        ))}
      </Box>
      {!isSupplier && (
        <Box className={classes.messageInput}>
          <form
            className={classes.inputBox}
            onSubmit={(e) => {
              e.preventDefault();
              if (message) {
                submitComment(data.id, message);
              }
            }}
          >
            <TextInput
              placeholder="Chat Message"
              name="input-message"
              value={message}
              additionalClass=""
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button type="submit" variant="text" className={classes.sendBtn}>
              Send
            </Button>
          </form>
        </Box>
      )}
    </Paper>
  );
};

export default SubmissionRemark;
