import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { getFileSourceUrl } from "utils/files";

import Header from "./Header";
import UploadDocuments from "./UploadDocuments";
import FactoryDetails from "./FactoryDetails";
import TaskPlannedDate from "./TaskPlannedDate";

import { atnAcknowledgeItem, atnGetAcknowledgeDetails } from "actions/itemActions";
import { atnGetCities, atnGetCountries, atnGetStates } from "actions/commonActions";
import { atnDownloadFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const AcknowledgeItem = ({ match, history }) => {
  const classes = useStyles();

  const itemId = match.params.itemId;

  const [step, setStep] = useState(0);

  const [acknowledgeItemData, setAcknowledgeItemData] = useState({
    id: itemId,
    factory: {
      address1: "",
      address2: "",
      city: {
        id: "",
        stateId: "",
        countryId: "",
      },
      contactEmail: "",
      contactNumber: "",
      contactPerson: "",
      factoryCode: "",
      id: "",
      name: "",
    },
    tasks: [],
    masterWicFiles: [],
    wicNumber: "",
    productName: "",
    images: [],
    productSpecification: [],
    artworks: [],
    otherInformation: [],
    aisUPCInformation: [],
    aisOuterInner: [],
    aisFactoryInformation: [],
  });

  const titleData = [
    "Upload Required Documents",
    "Factory Details",
    "Advise Planned Completion Date Per Task",
  ];

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hideState, setHideState] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [oldMasterWicFiles, setOldMasterWicFiles] = useState([]);

  const getCountries = () => {
    atnGetCountries().then(({ data, message }) => {
      let countries = [];

      for (let i = 0; i < data.length; i++) {
        countries.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCountryList(countries);
    });
  };

  const getStates = (countryId) => {
    atnGetStates({
      countryId: countryId,
    }).then(({ data, message }) => {
      if (data && data.length === 1 && !data[0].name) {
        getCities(data[0].id);

        setAcknowledgeItemData((prevState) => ({
          ...prevState,
          factory: {
            ...prevState.factory,
            city: {
              ...prevState.factory.city,
              stateId: data[0].id,
              id: "",
            },
          },
        }));

        setHideState(true);
      } else {
        setHideState(false);
      }

      let states = [];

      for (let i = 0; i < data.length; i++) {
        states.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setStateList(states);
    });
  };

  const getCities = (stateId) => {
    atnGetCities({
      stateId: stateId,
    }).then(({ data, message }) => {
      let cities = [];

      for (let i = 0; i < data.length; i++) {
        cities.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCityList(cities);
    });
  };

  const getAcknowledgeDetails = () => {
    atnGetAcknowledgeDetails({
      id: itemId,
    }).then(({ data, message }) => {
      if (data) {
        if (data.factory.city) {
          if (data.factory.city.countryId && data.factory.city.stateId) {
            getStates(data.factory.city.countryId);
          }

          if (data.factory.city.stateId && data.factory.city.id) {
            getCities(data.factory.city.stateId);
          }
        }

        setOldMasterWicFiles(data.masterWicFiles || []);

        if (data.masterWicFiles.length > 0) {
          const downloadImage = (location) => {
            return new Promise((resolve, reject) => {
              atnDownloadFile(location).then(async (res) => {
                if (res) {
                  resolve(getFileSourceUrl(res));
                } else {
                  reject();
                }
              });
            });
          };

          let images = data.masterWicFiles;
          let downloadImageFunctionArray = [];

          for (let i = 0; i < images.length; i++) {
            downloadImageFunctionArray.push(downloadImage(images[i].location));
          }

          Promise.all(downloadImageFunctionArray).then((result) => {
            for (let i = 0; i < result.length; i++) {
              images[i].preview = result[i];
            }

            setAcknowledgeItemData((prevState) => ({
              ...prevState,
              wicNumber: data.wicNumber,
              productName: data.productName || "",
              factory: data.factory,
              tasks: data.tasks,
              images: images,
            }));
          });
        } else {
          setAcknowledgeItemData((prevState) => ({
            ...prevState,
            wicNumber: data.wicNumber,
            productName: data.productName || "",
            factory: data.factory,
            tasks: data.tasks,
          }));
        }
      }
    });
  };

  useEffect(() => {
    getCountries();
    getAcknowledgeDetails();
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const handleAcknowledgeItemDataChange = (name, value) => {
    setAcknowledgeItemData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFactoryDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      getStates(value);

      setAcknowledgeItemData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          city: {
            countryId: value,
            stateId: "",
            id: "",
          },
        },
      }));
    } else if (name === "state") {
      getCities(value);

      setAcknowledgeItemData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          city: {
            ...prevState.factory.city,
            stateId: value,
            id: "",
          },
        },
      }));
    } else if (name === "city") {
      setAcknowledgeItemData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          city: {
            ...prevState.factory.city,
            id: value,
          },
        },
      }));
    } else {
      setAcknowledgeItemData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          [name]: value,
        },
      }));
    }
  };

  const handleTaskPlannedDateChange = (e, index) => {
    let hasEmptyPlannedDate = false;
    setAcknowledgeItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item, i) => {
        if (i === index) {
          item.plannedDate = e._d;
        }

        if (!item.plannedDate) {
          hasEmptyPlannedDate = true;
        }

        return item;
      }),
    }));

    setDisableSubmitButton(hasEmptyPlannedDate);
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);

    let masterWicFiles = [];
    let oldMasterWicFileIds = [];

    for (let i = 0; i < oldMasterWicFiles.length; i++) {
      oldMasterWicFileIds.push(oldMasterWicFiles[i].id);
    }

    for (let i = 0; i < acknowledgeItemData.images.length; i++) {
      if (oldMasterWicFileIds.indexOf(acknowledgeItemData.images[i].id) === -1) {
        masterWicFiles.push({
          id: acknowledgeItemData.images[i].id,
          type: "product_image",
        });
      }
    }

    for (let i = 0; i < acknowledgeItemData.productSpecification.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.productSpecification[i].id,
        type: "product_specification",
      });
    }

    for (let i = 0; i < acknowledgeItemData.artworks.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.artworks[i].id,
        type: "artworks",
      });
    }

    for (let i = 0; i < acknowledgeItemData.otherInformation.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.otherInformation[i].id,
        type: "other_information",
      });
    }

    for (let i = 0; i < acknowledgeItemData.aisUPCInformation.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.aisUPCInformation[i].id,
        type: "ais_stibo_upc_information",
      });
    }

    for (let i = 0; i < acknowledgeItemData.aisOuterInner.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.aisOuterInner[i].id,
        type: "ais_stibo_outer_inner",
      });
    }

    for (let i = 0; i < acknowledgeItemData.aisFactoryInformation.length; i++) {
      masterWicFiles.push({
        id: acknowledgeItemData.aisFactoryInformation[i].id,
        type: "ais_stibo_factory_information",
      });
    }

    const submitData = {
      id: itemId,
      wicNumber: acknowledgeItemData.wicNumber,
      factory: acknowledgeItemData.factory,
      masterWicFiles: masterWicFiles,
      tasks: acknowledgeItemData.tasks,
    };

    atnAcknowledgeItem(submitData).then(({ data, message }) => {
      setDisableSubmitButton(false);
      history.push(`/supplier/items/${data.id}`);
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header step={step} handlePrevPage={handlePrevPage} handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <UploadDocuments
            acknowledgeItemData={acknowledgeItemData}
            handleAcknowledgeItemDataChange={handleAcknowledgeItemDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 1 && (
          <FactoryDetails
            acknowledgeItemData={acknowledgeItemData}
            handleFactoryDataChange={handleFactoryDataChange}
            handleAcknowledgeItemDataChange={handleAcknowledgeItemDataChange}
            countryList={countryList}
            stateList={stateList}
            cityList={cityList}
            hideState={hideState}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 2 && (
          <TaskPlannedDate
            taskData={acknowledgeItemData.tasks}
            handleTaskPlannedDateChange={handleTaskPlannedDateChange}
            disableSubmitButton={disableSubmitButton}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(null, {
  atnGetAcknowledgeDetails,
  atnAcknowledgeItem,
  atnGetCountries,
  atnGetStates,
  atnGetCities,
})(AcknowledgeItem);
