import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TaskCompletionRate from "./TaskCompletionRate";
import OutstandingSubmissions from "./OutstandingSubmissions";

const useStyles = makeStyles((theme) => ({
  outstandingSubmissionsContainer: {
    marginLeft: 10,
    paddingRight: 30,
  },
}));

const Statistics = ({
  taskCompletionRateData = [],
  outstandingSubmissionData = [],
  handleTableDataClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [taskCompletionRateTableHeaderData, setTaskCompletionRateTableHeaderData] = useState([]);
  const [taskCompletionRateTableRowData, setTaskCompletionRateTableRowData] = useState([]);

  const [outstandingSubmissionsTableHeaderData, setOutstandingSubmissionsTableHeaderData] =
    useState([]);
  const [outstandingSubmissionsTableRowData, setOutstandingSubmissionsTableRowData] = useState([]);

  useEffect(() => {
    if (!taskCompletionRateData || taskCompletionRateData.length === 0) {
      return;
    }

    let headerData = [
      {
        name: "task",
        label: "Task",
        hasNumber: true,
        number: taskCompletionRateData[0].total || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "all",
          taskType: "all",
        },
      },
      {
        name: "waitingForLabQuote",
        label: "Waiting for Lab Quote",
        hasNumber: true,
        number: taskCompletionRateData[0].waiting_for_lab_quote || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "waiting_for_lab_quote",
          taskType: "all",
        },
      },
      {
        name: "quoteNotConfirmed",
        label: "Quote Not Confirmed",
        hasNumber: true,
        number: taskCompletionRateData[0].quotation_not_confirm || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "quotation_not_confirm",
          taskType: "all",
        },
      },
      {
        name: "bookingReserved",
        label: "Booking Reserved",
        hasNumber: true,
        number: taskCompletionRateData[0].booking_reserved || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "booking_reserved",
          taskType: "all",
        },
      },
      {
        name: "inProgress",
        label: "In Progress",
        hasNumber: true,
        number: taskCompletionRateData[0].in_progress || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "in_progress",
          taskType: "all",
        },
      },
      {
        name: "completed",
        label: "Completed",
        hasNumber: true,
        number: taskCompletionRateData[0].completed || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "completed",
          taskType: "all",
        },
      },
    ];

    let rowData = [];

    for (let i = 1; i < taskCompletionRateData.length; i++) {
      rowData.push({
        name: taskCompletionRateData[i].name,
        data: [
          {
            name: taskCompletionRateData[i].name,
            label: t(`task.${taskCompletionRateData[i].name}`),
            hasNumber: true,
            number: taskCompletionRateData[i].total || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "all",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "waitingForLabQuote",
            label: `${(taskCompletionRateData[i].waiting_for_lab_quote_percentage || 0).toFixed(
              2,
            )}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].waiting_for_lab_quote || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "waiting_for_lab_quote",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "quoteNotConfirmed",
            label: `${(taskCompletionRateData[i].quotation_not_confirm_percentage || 0).toFixed(
              2,
            )}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].quotation_not_confirm || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "quotation_not_confirm",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "bookingReserved",
            label: `${(taskCompletionRateData[i].booking_reserved_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].booking_reserved || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "booking_reserved",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "inProgress",
            label: `${(taskCompletionRateData[i].in_progress_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].in_progress || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "in_progress",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "completed",
            label: `${(taskCompletionRateData[i].completed_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].completed || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "completed",
              taskType: taskCompletionRateData[i].name,
            },
          },
        ],
      });
    }

    setTaskCompletionRateTableHeaderData(headerData);
    setTaskCompletionRateTableRowData(rowData);
  }, [taskCompletionRateData]);

  useEffect(() => {
    let headerData = [
      {
        name: "type",
        label: "Type",
        hasNumber: false,
      },
    ];

    setOutstandingSubmissionsTableHeaderData(headerData);
    setOutstandingSubmissionsTableRowData(outstandingSubmissionData);
  }, [outstandingSubmissionData]);

  return (
    <Box>
      <Box display="flex">
        <Box display="flex">
          <TaskCompletionRate
            tableTitle="Task Completion Rate %"
            headerData={taskCompletionRateTableHeaderData}
            rowData={taskCompletionRateTableRowData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>

        <Box display="flex" className={classes.outstandingSubmissionsContainer}>
          <OutstandingSubmissions
            tableTitle="Outstanding Submission"
            headerData={outstandingSubmissionsTableHeaderData}
            rowData={outstandingSubmissionsTableRowData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Statistics;
