import React from "react";
import { connect } from "react-redux";

import SharedSubmissionDetail from "pages/SharedSubmissionDetail";
import { USER_TYPE } from "shared/constants";

const LabSubmissionDetail = ({ match }) => {
  return <SharedSubmissionDetail type={USER_TYPE.lab} submissionId={match.params.id} />;
};

export default connect(null, null)(LabSubmissionDetail);
