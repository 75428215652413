import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";

import InvoiceItem from "pages/SharedItemDetail/Task/TaskDetail/DetailBox/tabs/InvoiceItem";
import PayerDetail, { defaultBillingAddress } from "pages/supplier/Workflow/components/PayerDetail";
import PayerInfo from "pages/common/PayerInfo";

import { atnGetSubmissionDetailsInvoice } from "actions/submissionActions";

import { atnUpdateTaskPayer } from "actions/taskActions";
import { INVOICE_TYPE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  payerContainer: {
    margin: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hideButton: {
    display: "none",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#777777",
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "2px 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
  },
  headerAccordion: {
    cursor: "pointer",
  },
  arrowIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
  invoiceName: {
    fontSize: 14,
    fontWeight: 500,
  },
  normalText: {
    fontSize: 10,
  },
  opText: {
    fontSize: 10,
    color: "#777777",
  },
  totalSmall: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const payerErrorMessage = "Cannot update payer, please try again.";

const Invoice = ({
  showPayerInfoForm = false,
  payerInfo,
  taskId, // SS task id
  data,
  userType,
  taskType,
  submissionId,
  refetch,
}) => {
  const classes = useStyles();

  const [invoiceData, setInvoiceData] = useState([]);

  const [prepaymentData, setPrepaymentData] = useState({
    hasPrepayment: false,
    prepaymentPrice: 0,
  });

  // for payer info
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [payerData, setPayerData] = useState({
    billingAddress: defaultBillingAddress,
  });
  const [payerError, setPayerError] = useState("");

  useEffect(() => {
    if (!data.id) return;

    getInvoice(data.id);
  }, []);

  const getInvoice = (id) => {
    atnGetSubmissionDetailsInvoice({ data: { id }, type: userType }).then(({ data, message }) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isPrepayment) {
            setPrepaymentData({
              hasPrepayment: true,
              prepaymentPrice: data[i].totalPrice,
            });
            break;
          }
        }

        setInvoiceData(data);
      }
    });
  };

  //* payer info for SS task with lab flow
  const handlePayerInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setPayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setPayerError("");
    setIsDisabledConfirmButton(true);

    let tempBillingAddress = payerData?.billingAddress;

    if (isNewBillingAddress) {
      delete payerData?.billingAddress.id;
    }

    if (payerData?.billingAddress.invoiceType === INVOICE_TYPE.ICW_COMPANY_INVOICE) {
      delete tempBillingAddress.attachments;
      delete tempBillingAddress.taxInformationFiles;
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType === INVOICE_TYPE.PLAIN_VAT_INVOICE) {
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType !== INVOICE_TYPE.ICW_COMPANY_INVOICE) {
      let taxInformationFiles = [];

      for (let i = 0; i < payerData?.billingAddress?.attachments?.length; i++) {
        taxInformationFiles.push({
          id: payerData?.billingAddress?.attachments[i]?.id,
          type: "tax_information",
        });
      }

      tempBillingAddress.taxInformationFiles = taxInformationFiles;

      if (!payerInfo) {
        delete tempBillingAddress.attachments;
      }
    }

    atnUpdateTaskPayer({ taskId, data: payerData?.billingAddress })
      .then((res) => {
        if (!res?.data) return setPayerError(payerErrorMessage);

        if (refetch) refetch();
      })
      .finally(() => {
        setIsDisabledConfirmButton(false);
      });
  };

  const NoDataFound = () => (
    <NoDataBox className={classes.payerContainer}>No invoice data</NoDataBox>
  );

  return (
    <React.Fragment>
      {showPayerInfoForm ? (
        <Paper className={classes.payerContainer} elevation={2}>
          <PayerDetail
            actionsButtonEnabled
            cancelButtonDisabled
            isDisabledConfirmButton={isDisabledConfirmButton}
            errors={[payerError]}
            data={payerData}
            handleDataChange={handlePayerInfoChange}
            handleSubmit={handleSubmit}
          />
        </Paper>
      ) : (
        <InvoiceWrapperBox>
          {invoiceData.length > 0 ? (
            invoiceData?.map((invoice, index) => (
              <Paper key={invoice.id} className={classes.container}>
                <InvoiceItem
                  item={invoice}
                  prepaymentData={prepaymentData}
                  taskType={taskType}
                  index={index}
                  submissionId={submissionId}
                />
              </Paper>
            ))
          ) : (
            <NoDataFound />
          )}

          {payerInfo && (
            <WrapperPaper className={classes.container}>
              <PayerInfo payerInfo={payerInfo} />
            </WrapperPaper>
          )}
        </InvoiceWrapperBox>
      )}
    </React.Fragment>
  );
};

export default Invoice;

const NoDataBox = withStyles({
  root: {
    marginBottom: "16px",
  },
})((props) => <Box {...props} />);

const InvoiceWrapperBox = withStyles({
  root: {
    margin: "24px",
  },
})((props) => <Box {...props} />);

const WrapperPaper = withStyles({
  root: {
    padding: "24px",
  },
})((props) => <Paper elevation={2} {...props} />);
