import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 430,
        width: "100%",
        minHeight: 180,
      },
    },
  },
  modalTitle: {
    borderBottom: "1.5px solid #003DA5",
  },
  textError: {
    color: "#d42600",
  },
  desc: {
    fontSize: 12,
    color: "#000",
    marginBottom: 24,
  },
}));

function WarningModal({ open, isDisabledSubmit = false, onClose, onSubmit, className }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classnames(classes.dialog, className)}>
      <DialogTitle className={classnames(classes.textError, classes.modalTitle)}>
        Incorrect Data on test item
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.desc}>
          Please fill in the right amount on test item.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {/* <Button onClick={onClose} color="primary">
          CANCEL
        </Button> */}

        <Button variant="contained" onClick={onSubmit} color="primary" disabled={isDisabledSubmit}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(WarningModal);
