// seasonal
export const EXCEPTION_TASK_TYPE_MAPPING = {
  QT: "Quality Test",
  DPI: "During Production Inspection",
  FRI: "Final Random Inspection",
  FRID: "Final Random Inspection",
  PST: "Pre-Safety Test",
  SPU: "Sample Pickup",
  SPUD: "Sample Pickup",
  ST: "Safety Test",
  STD: "Safety Test",
};

export const TENDERING_BRAND_TYPES = [
  "DTR",
  "Owned Brand",
  "Vendor Brand",
  "Vendor Brand - Exclusive",
  "National Brand - Exclusive",
  "License Brand",
  "Licensed Brand-Exclusive",
];

export const EXCEPTION_TASK_STATUS_MAPPING = [
  "not_open_for_quote",
  "quote_available",
  "waiting_for_lab_quote",
];
