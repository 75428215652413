import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import Search from "components/input/Search";
import SelectTasksTable from "components/table/SelectTasksTable";
import OutlinedButton from "components/input/OutlinedButton";
import Program777Chip from "components/display/Program777Chip";
import TenderingChip from "components/display/TenderingChip";

import ErrorModal from "components/modal/ErrorModal";

import { getCategory } from "services/categoryService";

import { canShareCostWithTendering } from "services/tenderingService";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  tableContainer: {
    marginTop: 15,
  },
  table: {
    marginTop: 15,
  },
  tableHeaderCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
    textTransform: "capitalize",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  closeIcon: {
    cursor: "pointer",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  dialogSearchContainer: {
    paddingTop: 10,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  inputPadding: {
    paddingTop: 10,
  },
  shortInputField: {
    width: 400,
  },
  blockPadding: {
    paddingRight: 10,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  addButtonContainer: {
    paddingTop: 20,
    textAlign: "center",
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const SelectTask = ({
  editSubmissionData = {},
  relatedTasks = [],
  searchKeyword,
  handleSearchKeywordChange,
  basicData = {},
  handleEditSubmissionDataChange,
  handleGetLabs,
  handleNextPage,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openSelectTaskDialog, setOpenSelectTaskDialog] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState(editSubmissionData.tasks);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);

  const [openTenderingShareCostErrorPopup, setOpenTenderingShareCostErrorPopup] = useState(false);

  const { isSeasonal } = getCategory(selectedTasks[0]?.category);

  const { canTenderingShareCost, matchedDesignatedLabs } = canShareCostWithTendering(selectedTasks);

  const isTenderingTaskShareCostError = isSeasonal && !canTenderingShareCost;

  const isTenderingShareCost = isSeasonal && canTenderingShareCost;

  const stringifyTenderingLabs = !!matchedDesignatedLabs?.length
    ? matchedDesignatedLabs?.toString()
    : "";

  useEffect(() => {
    setSelectedTasks(editSubmissionData.tasks);
  }, [editSubmissionData.tasks]);

  const handleOpenTenderingShareCostPopup = () => {
    setOpenTenderingShareCostErrorPopup(true);
  };

  const handleCloseTenderingShareCostPopup = () => {
    setOpenTenderingShareCostErrorPopup(false);
  };

  const handleContinue = () => {
    if (isTenderingTaskShareCostError) {
      handleOpenTenderingShareCostPopup();
    } else {
      if (isTenderingShareCost) handleConfirmTaskSelect();

      handleNextPage(false);
    }
  };

  const handleAddTask = () => {
    setSelectedTasks(editSubmissionData.tasks);

    const taskIds = [];

    for (let i = 0; i < editSubmissionData.tasks.length; i++) {
      taskIds.push(editSubmissionData.tasks[i].id);
    }

    setSelectedTaskIds(taskIds);

    setOpenSelectTaskDialog(true);
  };

  const handleTaskSelect = (task) => {
    if (selectedTaskIds.indexOf(task.id) >= 0) {
      setSelectedTasks((prevState) => {
        return prevState.filter((item) => item.id !== task.id);
      });

      setSelectedTaskIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      setSelectedTasks((prevState) => {
        return prevState.concat(task);
      });

      setSelectedTaskIds((prevState) => {
        return prevState.concat(task.id);
      });
    }
  };

  const handleRemoveTask = (taskId) => {
    const tasks = selectedTasks.filter((item) => item.id !== taskId);

    let taskTypes = [];

    for (let i = 0; i < tasks.length; i++) {
      if (taskTypes.indexOf(tasks[i].taskType) === -1) {
        taskTypes.push(tasks[i].taskType);
      }
    }

    handleGetLabs({
      taskType: taskTypes.toString(),
      buyerProgramId: tasks[0].buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });

    handleEditSubmissionDataChange({
      target: {
        name: "tasks",
        value: tasks,
      },
    });
  };

  const handleCloseSelectTaskDialog = () => {
    setOpenSelectTaskDialog(false);
  };

  const handleConfirmTaskSelect = () => {
    let taskTypes = [];

    for (let i = 0; i < selectedTasks.length; i++) {
      if (taskTypes.indexOf(selectedTasks[i].taskType) === -1) {
        taskTypes.push(selectedTasks[i].taskType);
      }
    }

    handleGetLabs({
      taskType: taskTypes.toString(),
      buyerProgramId: selectedTasks[0].buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });

    handleEditSubmissionDataChange({
      target: {
        name: "tasks",
        value: selectedTasks,
      },
    });
    setOpenSelectTaskDialog(false);
  };

  return (
    <>
      {openTenderingShareCostErrorPopup && (
        <ErrorModal
          title="Grouped items with different lab"
          content="Please re-select tasks for sharing cost."
          closeText="Confirm"
          open={openTenderingShareCostErrorPopup}
          onClose={handleCloseTenderingShareCostPopup}
        />
      )}

      <Paper className={classes.container}>
        <Box className={classes.title}>Currently Selected Task</Box>

        <Box className={classes.tableContainer}>
          {/* <TableWithDelete
            headerData={headerData}
            rowData={editSubmissionData.tasks}
            handleRemoveRow={handleRemoveTask}
          /> */}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Product Name</TableCell>
                <TableCell className={classes.tableHeaderCell}>Program</TableCell>
                <TableCell className={classes.tableHeaderCell}>Task Type</TableCell>
                <TableCell className={classes.tableHeaderCell}>Factory</TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {SIMPLE_BUYER_NAME} Deadline
                </TableCell>
                <TableCell className={classes.tableCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {editSubmissionData.tasks.map((task, index) => (
                <TableRow key={task.id}>
                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box display="flex" className={classes.tableText}>
                      {task.isProgram777 && <Program777Chip />} {task.wicNumber}
                    </Box>
                    <Box className={classnames(classes.info, classes.tableText)}>
                      {task.productName}
                    </Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box display="flex" className={classes.tableText}>
                      {task?.isTenderingProgram && (
                        <TenderingChip detail={task?.tenderingProgram} />
                      )}

                      {task.programName}
                    </Box>
                    <Box className={classnames(classes.buyer, classes.tableText)}>
                      {task.buyerCompanyName}
                    </Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>{t(`task.${task.taskType}`)}</Box>
                    <Box className={classnames(classes.info, classes.tableText)}>
                      {task.taskNumber}
                    </Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>{task.factoryName || "N/A"}</Box>
                    <Box className={classes.info}>{task.factoryAddress || "N/A"}</Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>
                      {moment(task.deadLine).format("DD/MMM/YYYY")}
                    </Box>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {index >= basicData.submissionTasksLength && (
                      <CloseIcon
                        className={classes.closeIcon}
                        onClick={() => handleRemoveTask(task.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box className={classes.addButtonContainer}>
          <OutlinedButton label="+ ADD TASK" handleClick={handleAddTask} color="primary" />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleContinue}
        disalbed={isTenderingTaskShareCostError}
      >
        CONTINUE
      </Button>

      <Dialog
        className={classes.dialog}
        onClose={handleCloseSelectTaskDialog}
        open={openSelectTaskDialog}
      >
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>Select Task(s) for Sharing Cost</Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.dialogMessage}>
            List shown below are unopened task(s) with same nature, and same factory.
          </Box>
          <Box className={classes.dialogSearchContainer}>
            <Search
              name="searchKeyword"
              value={searchKeyword}
              handleChange={handleSearchKeywordChange}
            />
          </Box>
          <SelectTasksTable
            tasks={relatedTasks}
            selectedTaskIds={selectedTaskIds}
            handleTaskSelect={handleTaskSelect}
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseSelectTaskDialog} color="primary">
            CANCEL
          </Button>

          <Button onClick={handleConfirmTaskSelect} color="primary" variant="contained">
            CONTINUE
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default SelectTask;
