import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";

import { currencyOptions } from "../data/index.json";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    marginTop: 30,
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 30,
    paddingBottom: 5,
  },
  selectField: {
    width: 200,
  },
  largeInputField: {
    width: 340,
  },
  inputField: {
    width: 130,
  },
  smallInputField: {
    width: 60,
  },
  deleteBtn: {
    padding: 5,
    marginLeft: 25,
  },
  subTotalTitle: {
    textAlign: "right",
    paddingRight: 10,
  },
  subTotal: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  addButtonContainer: {
    paddingTop: 20,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  priceSection: {
    paddingRight: 10,
    textAlign: "right",
  },
  vat: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 5,
  },
  totalPriceSection: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 30,
    textAlign: "right",
  },
  additionalInputTitle: {
    width: 300,
    paddingTop: 10,
  },
  displayInline: {
    display: "inline-block",
  },
  inlineTitle: {
    paddingRight: 40,
  },
}));

const Complete = ({
  completeData,
  testItems = [],
  prepaymentData,
  handleCompleteDataChange,
  handleTestItemDataChange,
  handleAddNewTestItem,
  handleRemoveTestItem,
}) => {
  const classes = useStyles();

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    handleCompleteDataChange({
      target: {
        name: name,
        value: checked,
      },
    });
  };

  return (
    <>
      <Box className={classes.sectionTitle}>Design Test Plan</Box>

      <Divider className={classes.divider} />

      <Box className={classes.inputTitle}>Currency</Box>
      <Select
        label="Currency (Select One)"
        name="currency"
        value={completeData.currency}
        options={currencyOptions}
        handleChange={handleCompleteDataChange}
        additionalClass={classes.selectField}
        disabled={!!completeData.prepaymentPrice}
      />

      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Box className={classes.inputTitle}>Test Item</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.inputTitle}>Quantity</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.inputTitle}>Cost</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classnames(classes.inputTitle, classes.subTotalTitle)}>Sub-Total</Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      {testItems.map((item, index) => (
        <Grid key={index} container spacing={3}>
          <Grid item xs={5}>
            <TextInput
              placeholder="Item name"
              name="itemName"
              value={item.itemName}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              placeholder="Quantity"
              type="number"
              name="quantity"
              value={item.quantity}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              placeholder="Unit price"
              name="unitPrice"
              value={item.unitPrice}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.subTotal}>{item.subTotal}</Box>
          </Grid>

          <Grid item xs={1}>
            {item.hasReport !== 1 && (
              <IconButton onClick={() => handleRemoveTestItem(index)} className={classes.deleteBtn}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      <Box className={classes.addButtonContainer}>
        <OutlinedButton
          label="+ ADD NEW TEST ITEM"
          handleClick={handleAddNewTestItem}
          color="primary"
        />
      </Box>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Box className={classes.priceSection}>
            <Box className={classes.inputTitle}>
              <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
                Sub-total:
              </Box>
              <Box className={classes.displayInline}>
                {completeData.isPackagePrice
                  ? completeData.packagePrice || 0
                  : completeData.subTotal}
              </Box>
            </Box>

            {prepaymentData.hasPrepayment && (
              <Box className={classes.inputTitle}>
                <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
                  Pre-Payment:
                </Box>
                <Box className={classes.displayInline}>- {prepaymentData.prepaymentPrice}</Box>
              </Box>
            )}

            <Box className={classes.vat}>
              <Box className={classnames(classes.displayInline, classes.inlineTitle)}>VAT:</Box>
              <Box className={classes.displayInline}>
                <TextInput
                  placeholder="0"
                  name="vat"
                  value={completeData.vat}
                  additionalClass={classes.inputField}
                  onChange={handleCompleteDataChange}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Box className={classnames(classes.inputTitle, classes.totalPriceSection)}>
            <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
              Total Price:
            </Box>
            <Box className={classes.displayInline}>
              {completeData.totalPrice} {completeData.currency}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Box className={classes.sectionTitle}>Additional Information</Box>

      <Divider className={classes.divider} />

      {/* <Box display="flex" className={classes.inputTitle}>
            <Box className={classes.additionalInputTitle}>
              Sample Size
            </Box>
            <Box>
              <TextInput
                placeholder="Sample size"
                name="sampleSize"
                value={completeData.sampleSize}
                additionalClass={classes.largeInputField}
                onChange={handleCompleteDataChange}
              />
            </Box>
          </Box> */}

      <Box display="flex" className={classes.inputTitle}>
        <Box className={classes.additionalInputTitle}>This is a Package Price</Box>
        <Box>
          <Checkbox
            name="isPackagePrice"
            checked={completeData.isPackagePrice}
            onChange={handleCheckboxChange}
            color="primary"
          />{" "}
          Yes
        </Box>
      </Box>

      {completeData.isPackagePrice && (
        <>
          <Box display="flex" className={classes.inputTitle}>
            <Box className={classes.additionalInputTitle}>Package Price</Box>
            <Box>
              <TextInput
                placeholder="Package Price"
                type="number"
                name="packagePrice"
                value={completeData.packagePrice || ""}
                additionalClass={classes.largeInputField}
                onChange={handleCompleteDataChange}
              />
            </Box>
          </Box>

          <Box display="flex" className={classes.inputTitle}>
            <Box className={classes.additionalInputTitle}>Package Remarks</Box>
            <Box>
              <TextInput
                placeholder="Package Remarks"
                name="packageRemark"
                value={completeData.packageRemark}
                additionalClass={classes.largeInputField}
                onChange={handleCompleteDataChange}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Complete;
