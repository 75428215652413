import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(() => ({
  labelBox: {
    marginRight: 8,
    marginLeft: 8,
  },
  label: {
    backgroundColor: "#668BC9",
    color: "#fff",
    width: 30,
    height: 20,
    fontSize: 10,
    fontWeight: "bold",
    border: "1px solid #003DA5",
    padding: 4,
  },
}));

export default function NBChip({ label = "SVRR", variant = "rounded" }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.labelBox}
      display="inline-block"
      justifyContent="center"
      alignItems="center"
    >
      <Avatar variant={variant} className={classes.label}>
        {label}
      </Avatar>
    </Box>
  );
}
