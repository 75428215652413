import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import omit from "lodash/omit";
import { Chip as MuiChip, FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import MuiChipInput from "material-ui-chip-input";

import classes from "./Chip.module.scss";

const getChipsFormatted = (chips) => {
  let result = [];

  if (chips && chips.length) {
    if (chips[0].label === undefined || chips[0].value === undefined)
      // IMPORTANT: chips format should be an array contains
      // if not (array contains only values) => force format to pattern below
      // the object like: { label: ..., value: ... }
      result = chips.map((item, index) => ({ label: item, value: index }));
    else result = chips;
  }

  return result;
};

const ChipInput = (props) => {
  const {
    name,
    label,
    value,
    errorText,
    touched,
    onAdd,
    onDelete,
    customChips,
    formProps,
    ...others
  } = props;

  const [chips, setChips] = useState(getChipsFormatted(value));

  const handleAddChip = (chipToAdd) => {
    if (!isEmpty(find(chips, { label: chipToAdd }))) return;
    const newChip = { label: chipToAdd, value: chips.length + 1 };
    const res = [...chips, newChip];

    setChips(res);
    if (formProps.setFieldValue) {
      formProps.setFieldValue(name, res);
    }
    if (onAdd) onAdd(newChip);
  };

  const handleDeleteChip = (chipToDelete, removedIndex) => {
    const res = chips.filter((item, index) => index !== removedIndex);

    setChips(res);
    if (formProps.setFieldValue) {
      formProps.setFieldValue(name, res);
    }

    if (onDelete) onDelete(chipToDelete);
  };

  const chipRendererProps = {
    chipRenderer: customChips
      ? ({ value: item, handleClick, handleDelete }, key) => {
          return (
            <MuiChip
              key={key}
              className={classes.chipItem}
              onClick={handleClick}
              onDelete={handleDelete}
              label={item.label}
              {...customChips}
            />
          );
        }
      : null,
  };

  const hasError = !!errorText;

  return (
    // Controlled Input
    <div className={classes.textInputWrapper}>
      <FormControl error={hasError}>
        {!!label && (
          <FormLabel component="legend" className={clsx(classes.label, hasError && classes.error)}>
            {label}
          </FormLabel>
        )}
        <MuiChipInput
          className={hasError && classes.error}
          value={chips}
          onAdd={(chip) => handleAddChip(chip)}
          onDelete={(chip, idx) => handleDeleteChip(chip, idx)}
          {...chipRendererProps}
          {...omit(others, ["formProps", "onBlur"])}
        />
        <FormHelperText
          className={clsx(classes.errorText, hasError ? classes.error : classes.errorTextHide)}
        >
          {errorText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

ChipInput.defaultProps = {
  value: [],
  onAdd: () => {},
  onDelete: () => {},
  customChips: {},
};

ChipInput.propTypes = {
  value: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  customChips: PropTypes.object,
};

export default ChipInput;
