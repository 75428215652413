import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import StepHeader from "../components/StepHeader";

import SelectTasks from "./SelectTasks";
import InspectionReport from "./InspectionReport";
import TestingReport from "./TestingReport";

import { atnCreateReviseReport, atnGetSubmissionDetails } from "actions/submissionActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectFieldContainer: {
    paddingTop: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  selectField: {
    width: "100%",
  },
  selectTaskContainer: {
    paddingTop: 50,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
}));

const UploadRevisedReport = ({ match, history }) => {
  const submissionId = match.params.submissionId;
  const classes = useStyles();

  const [submissionType, setSubmissionType] = useState("");
  const [isProgram777, setIsProgram777] = useState(false);
  const pageTitle = ["Step 1: Select Tasks", "Step 2: Upload Report"];

  const [step, setStep] = useState(0);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(pageTitle[0]);
  const [reviseReportData, setReviseReportData] = useState({
    updateType: "revise_report",
    selectedTasks: [],
    reports: [],
  });

  useEffect(() => {
    atnGetSubmissionDetails({
      type: 3,
      data: {
        id: submissionId,
      },
    }).then(({ data, message }) => {
      if (data && data.taskType) {
        setIsProgram777(data.isProgram777);

        if (data.taskType === "QT") {
          setSubmissionType("testing");
        } else if (data.taskType === "PST") {
          setSubmissionType("testing");
        } else if (data.taskType === "SS") {
          setSubmissionType("inspection");
        } else if (data.taskType === "SPU" || data.taskType === "SPUD") {
          setSubmissionType("inspection");
        } else if (data.taskType === "ST" || data.taskType === "STD") {
          setSubmissionType("testing");
        } else if (data.taskType === "DPI") {
          setSubmissionType("inspection");
        } else if (data.taskType === "FRI" || data.taskType === "FRID") {
          setSubmissionType("inspection");
        } else if (data.taskType === "PAC") {
          setSubmissionType("testing");
        }
      }
    });
  }, []);

  const handleReviseReportDataChange = (e) => {
    const { name, value } = e.target;

    setReviseReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRemoveReport = (index) => {
    setReviseReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewReport = (report) => {
    setReviseReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.concat(report),
    }));
  };

  // const handleConfirmEditReport = (index, newReportData) => {
  const handleConfirmEditReport = (e, index) => {
    const { name, value } = e.target;
    setReviseReportData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handlePrevPage = () => {
    if (step > 0) {
      setHeaderTitle(pageTitle[step - 1]);

      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step === 0) {
      setHeaderTitle(pageTitle[step + 1]);
      setStep(step + 1);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async (_) => {
    let submitData = [];
    let hasEmptyData = false;

    let { reports, selectedTasks } = reviseReportData;

    for (let i = 0; i < reports.length; i++) {
      let { issueDate, result } = reports[i];
      if (issueDate === "" || result === "") {
        hasEmptyData = true;
        break;
      }
    }

    if (hasEmptyData) {
      return alert("missing data");
    }

    for (let i = 0; i < reports.length; i++) {
      let editReportItem = reports[i];
      submitData.push({
        id: editReportItem.fileId,
        fileId: editReportItem.fileId,
        result: editReportItem.result,
        remark: "",
        issueDate: editReportItem.issueDate,
        pb: editReportItem.pb,
        testItems: [],
      });
    }

    for (let i = 0; i < selectedTasks.length; i++) {
      await atnCreateReviseReport({
        submissionId: submissionId,
        taskId: selectedTasks[i].id,
        data: submitData,
      }).then(({ data, message }) => {
        if (data) {
        }
      });
    }

    history.push(`/lab/submissions/${submissionId}`);
    setIsDisabledConformButton(true);
  };

  return (
    <Box className={classes.root}>
      <StepHeader
        step={step}
        header="Upload Revise Report"
        title={headerTitle}
        handlePrevStep={handlePrevPage}
        handleClose={handleClose}
      />

      {step === 0 && (
        <SelectTasks
          // completeData={completeData}
          // testItems={testItems}
          // prepaymentData={prepaymentData}
          // handleCompleteDataChange={handleCompleteDataChange}
          // handleTestItemDataChange={handleTestItemDataChange}
          // handleAddNewTestItem={handleAddNewTestItem}
          // handleRemoveTestItem={handleRemoveTestItem}
          // handleSubmit={handleSubmit}
          submissionId={submissionId}
          reviseReportData={reviseReportData}
          handleReviseReportDataChange={handleReviseReportDataChange}
          handleNextPage={handleNextPage}
          // isDisabledConformButton={isDisabledConformButton}
        />
      )}

      {step === 1 && submissionType === "testing" && (
        <TestingReport
          submissionId={submissionId}
          updateStatusData={reviseReportData}
          handleAddNewReport={handleAddNewReport}
          handleConfirmEditReport={handleConfirmEditReport}
          handleRemoveReport={handleRemoveReport}
          handleSubmit={handleSubmit}
          isDisabledConformButton={isDisabledConformButton}
        />
      )}

      {step === 1 && submissionType === "inspection" && (
        <InspectionReport
          submissionId={submissionId}
          updateStatusData={reviseReportData}
          isProgram777={isProgram777}
          handleAddNewFilesChange={handleAddNewReport}
          handleReportDataChange={handleConfirmEditReport}
          handleRemoveFile={handleRemoveReport}
          handleSubmit={handleSubmit}
          isDisabledConformButton={isDisabledConformButton}
        />
      )}
    </Box>
  );
};

export default UploadRevisedReport;
