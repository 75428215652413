import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import Button from "components/input/Button";
import Table from "components/table/Table";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  blackText: {
    color: "#000000",
  },
  removeButton: {
    color: "#D42600",
    borderColor: "#D42600",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  updateButton: {
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
}));

const Result = ({ data: resultTableData }) => {
  const classes = useStyles();

  const [editing, setEditing] = React.useState(false);

  const resultTableColumn = [
    {
      columnId: "title",
      render: (data) => {
        return textOrUnknown(data.title);
      },
    },
    {
      columnId: "result",
      label: "Result",
      render: (data) => {
        return textOrUnknown(data.result);
      },
    },
    {
      columnId: "remark",
      label: "Remark",
      width: "40%",
      render: (data) => {
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {textOrUnknown(data.remark)}
            {editing && (
              <Button variant="outlined" className={classes.removeButton}>
                Remove
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const handleUpdateResultButton = () => {
    setEditing(true);
  };

  const handleCancelButton = () => {
    setEditing(false);
  };

  const handleSaveButton = () => {
    setEditing(false);
  };

  const handleAddButton = () => {};

  return (
    <Box>
      <Box pb={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classnames(classes.title, classes.blackText)}>Result</Typography>
        {!editing ? (
          <Button className={classes.updateButton} onClick={handleUpdateResultButton}>
            Update Result
          </Button>
        ) : (
          <Box>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
            <Button onClick={handleSaveButton}>Save</Button>
          </Box>
        )}
      </Box>
      <Table data={resultTableData} columnData={resultTableColumn} noPagination />
      {editing && (
        <Box pt={1}>
          <Button variant="outlined" onClick={handleAddButton}>
            Add more result
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Result;
