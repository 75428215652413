import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar as Image, Box, Typography } from "@material-ui/core";

import NoImage from "assets/no_image.png";
import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";
import ImagePreview from "components/display/ImagePreview";
import { ITEM_CATEGORY } from "shared/constants";
import Program777Chip from "components/display/Program777Chip";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles((theme) => ({
  programSectionWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  programSection: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "2px",
  },
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: 50,
    height: 50,
  },
  infoColumn: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#777777",
  },
  defaultText: {
    color: "#777777",
  },
  defaultTitleText: {
    color: "#AAAAAA",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  subTitle: {
    fontSize: 10,
    color: "#000000",
  },
  spacingText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  branchText: {
    color: "#003DA5",
  },
  wicNumber: {
    paddingLeft: 5,
  },
}));

const InfoBox = ({ data }) => {
  const classes = useStyles();

  const {
    brand,
    supplierName,
    programName,
    shipByDate,
    wicNumber,
    buyerCompanyName,
    productName,
    productImages = [],
    category = "everyday",
    isProgram777,
    isTenderingProgram,
  } = data;

  const SubTitleItem = ({ label, value, isSpacing = false, labelClassName = "" }) => (
    <>
      <span
        className={classnames(classes.defaultText, {
          [classes.spacingText]: isSpacing,
        })}
      >
        {isSpacing ? "|" : label}
      </span>
      {!isSpacing && (
        <span data-cy={value} className={labelClassName}>
          {textOrUnknown(value)}
        </span>
      )}
    </>
  );

  return (
    <Box className={classes.root}>
      <Box>
        {productImages.length ? (
          <ImagePreview data={productImages} className={{ image: classes.image }} />
        ) : (
          <Image variant="square" alt="item-image" src={NoImage} className={classes.image} />
        )}
      </Box>
      <Box className={classes.infoColumn}>
        <Box display="flex">
          {isProgram777 && <Program777Chip />}
          <Typography className={classes.title}>
            <span className={classes.defaultTitleText}>Item /</span>
            <span className={classes.wicNumber}>{wicNumber}</span> • {textOrUnknown(productName)}
          </Typography>
        </Box>

        <Typography className={classes.subTitle}>
          <SubTitleItem label="Supplier Name: " value={supplierName} />
          <SubTitleItem isSpacing />
          <SubTitleItem label="Brand Type: " value={brand} />
        </Typography>
        <Typography className={classes.subTitle}>
          {category !== "everyday" && (
            <>
              <SubTitleItem label="Ship by Date: " value={getDateFormat(shipByDate)} />
              <SubTitleItem isSpacing />
            </>
          )}

          <div className={classes.programSectionWrapper}>
            <span className={classes.defaultText}>Program Name:</span>{" "}
            <span className={classes.programSection}>{programName}</span>
          </div>

          <SubTitleItem isSpacing />
          <SubTitleItem
            label={`${SIMPLE_BUYER_NAME} Branch: `}
            value={buyerCompanyName}
            labelClassName={classes.branchText}
          />
          <SubTitleItem isSpacing />
          <SubTitleItem label="Category: " value={ITEM_CATEGORY[category]?.label} />
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoBox;
