import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Container, IconButton, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  defaultText: {
    color: "#000000",
    flexGrow: 1,
    textAlign: "center",
  },
  menuButton: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  processRoot: {
    backgroundColor: "#E5ECF6",
    height: 7,
  },
  processBar: {
    backgroundColor: "#003DA5",
  },
}));

const MenuBar = ({ history }) => {
  const classes = useStyles();
  const handleQuit = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <span className={classes.defaultText}>
                <b>Confirm Quote |</b> Fill in TRF Information
              </span>
              <IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleQuit}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Container>
        </Toolbar>
        {/* <LinearProgress variant='determinate' value={20} classes={stepperClasses} /> */}
      </AppBar>
    </div>
  );
};

export default withRouter(MenuBar);
