import submissionRemarkApi from "../api/submissionRemarkApi";

export const antGetSubmissionRemarks = (taskId) => {
  return submissionRemarkApi.getSubmissionRemarkData(taskId);
};

export const antCreateSubmissionRemark = (taskId, reqData = {}) => {
  return submissionRemarkApi.createSubmissionRemark(taskId, reqData);
};

export const antGetSubmissionRemarksBySubmission = (submissionId) => {
  return submissionRemarkApi.getSubmissionRemarkDataBySubmission(submissionId);
};

export const antCreateSubmissionRemarkBySubmission = (submissionId, reqData = {}) => {
  return submissionRemarkApi.createSubmissionRemarkBySubmission(submissionId, reqData);
};
