import _get from 'lodash/get';
import queryString from 'query-string';

const getUrlParam = (match, path) => {
  return _get(match, `params.${path}`, '');
};

const getStringQueryValue = (history, key = '') => {
  const values = queryString.parse(history.location.search);
  return _get(values, key, '');
};

const removeAllSearchParam = (history) => {
  history.replace({ search: '' });
};

const nextPath = (history) => (path, state = null) => history.push(path, state);

export { getUrlParam, getStringQueryValue, removeAllSearchParam, nextPath };
