import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import ReactTable from "components/table/ReactTable";
import classTable from "./../style.module.scss";

import Status from "./GroupFilter/Status";
import { MODE, STATUS, STATUS_ALL } from "./GroupFilter/constants";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    padding: "10px 0",
  },

  searchContent: {
    marginBottom: "10px",
    fontWeight: "500",

    "& span:not(:last-child)": {
      marginRight: "10px",
    },
  },

  wrapperSearch: {
    marginBottom: "10px",
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  normalCell: {},

  warningCell: {
    color: "#D32F2F",
    fontWeight: "bold",
  },
  status: {
    textTransform: "capitalize",
  },
  noStatus: {
    textTransform: "capitalize",
    color: "#D42600",
  },
  blurDetail: {
    color: "#777777",
  },
  boldCell: {
    fontWeight: 500,
    color: "#000000",
    fontSize: 12,
  },
  iconSeven: {
    width: 25,
    height: 17,
    borderRadius: 2,
    background: "#668BC9",
    fontSize: 10,
    fontWeight: "bold",
    color: "#F4F6FA",
    textAlign: "center",
    lineHeight: "17px",
    marginRight: 7,
    border: "1px solid #003DA5",
  },
}));

const PO = ({ listSearch, listPODaily, cbSetDataShowTable = () => {} }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const filterOnly = { canSort: false, canFilter: true };
  const noSortFilter = { canSort: false, canFilter: false };

  const [listPAC, setListPAC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterPAC = (data) => {
    setListPAC(data);
  };

  const [listGCC, setListGCC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterGCC = (data) => {
    setListGCC(data);
  };

  const [listQAA, setListQAA] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterQAA = (data) => {
    setListQAA(data);
  };

  const [listFRI, setListFRI] = useState(
    STATUS.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterFRI = (data) => {
    setListFRI(data);
  };

  const [listMode, setListMode] = useState(
    MODE.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterMode = (data) => {
    setListMode(data);
  };

  const tableColumn = [
    {
      id: "poNumber1",
      value: "poNumber",
      label: "PO Number ",
      ...noSortFilter,
      render: ({ cell: { value: poNumber } }) => {
        return <Box className={classes.boldCell}>{poNumber}</Box>;
      },
    },
    {
      id: "relatedPBNumber",
      value: "relatedPBNumbers",
      label: "Related PB Number [Production batch line ID ]",
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: relatedPBNumbers } }) => {
        return (
          <Box className={classes.normalCell}>
            {!relatedPBNumbers
              ? "N/A"
              : relatedPBNumbers.map((item) => (
                  <div>
                    {item.pbNumber} [{item.pbLineId}]
                  </div>
                ))}
          </Box>
        );
      },
    },
    {
      id: "wicNumber",
      value: ({ wicNumber, productName, isProgram777 }) => {
        return { wicNumber, productName, isProgram777 };
      },
      label: "WIC No. / Description ",
      ...noSortFilter,
      width: 200,
      render: ({
        cell: {
          value: { wicNumber, productName, isProgram777 },
        },
      }) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display={isProgram777 ? "block" : "none"} className={classes.iconSeven}>
              777
            </Box>
            <div>
              <div>{wicNumber}</div>
              <div className={classes.blurDetail}>{productName}</div>
            </div>
          </Box>
        );
      },
    },
    {
      id: "poQuantity1",
      value: "poQuantity",
      label: "PO Quantity",
      ...sortOnly,
      render: ({ cell: { value: poQuantity } }) => {
        return <Box className={classes.normalCell}>{poQuantity}</Box>;
      },
    },
    {
      id: "shipByDate1",
      value: "shipByDate",
      label: "Ship By Date",
      ...sortOnly,
      render: ({ cell: { value: shipByDate } }) => {
        return <Box className={classes.normalCell}>{shipByDate}</Box>;
      },
    },
    {
      id: "pacStatus1",
      value: "pacStatus",
      label: "PAC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: pacStatus } }) => {
        return (
          <Box
            className={
              pacStatus && pacStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!pacStatus ? "NA" : pacStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterPAC} list={listPAC} />;
      },
      selectedCheckBox: listPAC.filter((child) => child.checked).length > 0,
    },
    {
      id: "gccStatus1",
      value: "gccStatus",
      label: "GCC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: gccStatus } }) => {
        return (
          <Box
            className={
              gccStatus && gccStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!gccStatus ? "NA" : gccStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterGCC} list={listGCC} />;
      },
      selectedCheckBox: listGCC.filter((child) => child.checked).length > 0,
    },
    {
      id: "qaaStatus1",
      value: "qaaStatus",
      label: "QAA Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: qaaStatus } }) => {
        return (
          <Box
            className={
              qaaStatus && qaaStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!qaaStatus ? "NA" : qaaStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterQAA} list={listQAA} />;
      },
      selectedCheckBox: listQAA.filter((child) => child.checked).length > 0,
    },
    {
      id: "friStatus1",
      value: "friStatus",
      label: "FRI Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: friStatus } }) => {
        return (
          <Box
            className={
              friStatus && friStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!friStatus ? "NA" : friStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterFRI} list={listFRI} />;
      },
      selectedCheckBox: listFRI.filter((child) => child.checked).length > 0,
    },
    {
      id: "loadingMode1",
      value: "loadingMode",
      label: "Loading Mode",
      ...filterOnly,
      render: ({ cell: { value: loadingMode } }) => {
        return <Box className={classes.normalCell}>{loadingMode}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterMode} list={listMode} />;
      },
      selectedCheckBox: listMode.filter((child) => child.checked).length > 0,
    },
  ];

  // ============= HANDLE SEARCH ANG FILTER LIST ====================

  const [listPODailyDone, setListPODailyDone] = useState([]);
  const [listKeySearch, setListKeySearch] = useState([]);

  useEffect(() => {
    setListPODailyDone(listPODaily);

    return () => {
      // cleanup
    };
  }, [listPODaily]);

  useEffect(() => {
    setListKeySearch(listSearch.split(new RegExp("[,]", "g")).map((item, index) => item.trim()));

    return () => {
      // cleanup
    };
  }, [listSearch]);

  useEffect(() => {
    handleFilteredList();

    return () => {
      // cleanup
    };
  }, [listKeySearch, listPAC, listGCC, listQAA, listFRI]);

  const handleFilteredList = () => {
    let result = listPODaily
      .filter((item) => {
        return listKeySearch.length === 1 && listKeySearch[0] === ""
          ? item
          : listKeySearch.filter((itemSearch) => item.poNumber.includes(itemSearch)).length > 0;
      })
      .filter((item) => {
        return listPAC.filter((child) => !child.checked).length === 3
          ? item
          : listPAC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.pacStatus ? "na" : item.pacStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listGCC.filter((child) => !child.checked).length === 3
          ? item
          : listGCC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.gccStatus ? "na" : item.gccStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listQAA.filter((child) => !child.checked).length === 3
          ? item
          : listQAA.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.qaaStatus ? "na" : item.qaaStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listFRI.filter((child) => !child.checked).length === 2
          ? item
          : listFRI.filter(
              (child) =>
                child.label.toLowerCase() === item.friStatus.toLowerCase() && child.checked,
            ).length > 0;
      });

    setListPODailyDone(result);
  };

  const [pageSize, setPageSize] = useState(50);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  useEffect(() => {
    cbSetDataShowTable({ pageSize, newPage, listDone: listPODailyDone });
  }, [listPODailyDone, pageSize, newPage]);

  return (
    <Box className={classes.wrapperBasic}>
      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          columns={tableColumn}
          data={listPODailyDone}
          hasPagingTop={true}
          rowsPerPageOptions={[10, 20, 50, { label: "All" }]}
          classes={{
            root: classTable.analyticsTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default PO;
