import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Box, Button, Grid } from "@material-ui/core";

import Select from "components/input/Select";
import Textarea from "components/input/Textarea";

const useStyles = makeStyles(() => ({
  selectField: {
    width: "100% !important",
    maxWidth: 600,
  },
  inputWidth: {
    width: "100%",
  },
  textarea: {
    maxHeight: "300px",
    width: "100% !important", //fixed width
  },
  modalTitle: {
    borderBottom: "1.5px solid #003DA5",
  },
  inputWrapper: {
    marginBottom: 24,
  },
  inputTitle: {
    fontWeight: 500,
    marginBottom: 10,
  },
  inputDesc: {
    marginBottom: 10,
  },
  inputAlert: {
    color: "#d42600",
  },
  desc: {
    fontSize: 12,
    color: "#000",
    marginBottom: 24,
  },
}));

function ReasonRemarkModal({
  data,
  open,
  isDisabledSubmit = false,
  options = [
    {
      value: "special offer",
      label: "Special Offer",
    },
    {
      value: "share cost",
      label: "Share Cost",
    },
  ],
  onClose,
  onSubmit,
  onReasonChange,
}) {
  const classes = useStyles();

  const isAllFilled = data?.reasonOption && data?.reasonRemarks;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.modalTitle}>State the reason for $0 cost</DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.desc}>
          Please select the reason for $0 cost
        </DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.inputWrapper}>
            <Box className={classes.inputTitle}>1. Reason for $0 Cost</Box>
            <Box className={classes.inputDesc}>
              Reason option <span className={classes.inputAlert}>(*Mandatory)</span>
            </Box>

            <Select
              label="Please select reason"
              name="reasonOption"
              value={data?.reasonOption || ""}
              options={options}
              handleChange={onReasonChange}
              formControlClass={classes.inputWidth}
              additionalClass={classes.selectField}
            />
          </Grid>

          <Grid item xs={12} className={classes.inputWrapper}>
            <Box className={classes.inputTitle}>2. Remark for $0 Cost</Box>
            <Box className={classes.inputDesc}>
              State your remark <span className={classes.inputAlert}>(*Mandatory)</span>
            </Box>

            <Textarea
              rowsMin={5}
              rowsMax={8}
              placeholder="Please fill in your remark"
              name="reasonRemarks"
              value={data?.reasonRemarks || ""}
              onChange={onReasonChange}
              additionalClass={classes.textarea}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          disabled={!isAllFilled || isDisabledSubmit}
        >
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ReasonRemarkModal);
