import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: 50,
    height: 50,
  },
  infoColumn: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#777777",
  },
  defaultText: {
    color: "#777777",
  },
  defaultTitleText: {
    color: "#AAAAAA",
  },
  title: {
    fontSize: 16,
    color: "#000000",
  },
  subTitle: {
    fontSize: 10,
    color: "#000000",
  },
  spacingText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  branchText: {
    color: "#003DA5",
  },
  retestLabel: {
    marginLeft: 10,
    padding: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    borderRadius: 3,
  },
}));

const InfoBox = ({ data }) => {
  const classes = useStyles();

  const SubTitleItem = ({ label, value }) => (
    <>
      <span className={classes.defaultText}>{label}</span>
      <span>{value}</span>
    </>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.infoColumn}>
        <Box display="flex">
          <Box className={classes.title}>{`Submission ID: ${data.name}`}</Box>
          {data.isRetest === 1 && <Box className={classes.retestLabel}>RETEST</Box>}
        </Box>
        {data.labName && (
          <Typography className={classes.subTitle}>
            <SubTitleItem value={`Lab Name: ${data.labName}`} />
          </Typography>
        )}
        {data.supplierName && (
          <Typography className={classes.subTitle}>
            <SubTitleItem value={`Supplier Name: ${data.supplierName}`} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InfoBox;
