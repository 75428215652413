/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import SearchIcon from "assets/icons/Search.svg";
import classes from "./Text.module.scss";

const TextInput = (props) => {
  const {
    errorText,
    touched,
    label,
    required,
    error,
    className,
    type,
    variant,
    ref,
    setRef,
    inputIcon,
    loading,
    formProps,
    isEyeVisibilityIcon,
    searchIcon,
    value,
    notError,
    multiline,
    ...other
  } = props;

  const [visibilityInput, setVisibilityInput] = useState(false);
  const requireRender = () => {
    return required && <span className="required-mark">*</span>;
  };
  const getError = () => {
    if (touched) {
      return !!errorText;
    }
    return false;
  };
  return (
    <div
      className={clsx(
        searchIcon ? classes.textInputWrapperWithIcon : classes.textInputWrapper,
        className,
        type === "hidden" && classes.inputHidden,
        variant === "outlined" && classes.variantOutlined,
        multiline && classes.multipleLine,
        !label && classes.noLabel,
        searchIcon && classes.hadSearchIcon,
      )}
    >
      <FormControl error={!!(touched && errorText)}>
        <TextField
          margin="dense"
          inputRef={(el) => {
            if (setRef) {
              setRef(el);
            }
          }}
          error={getError()}
          label={
            <span>
              {label}
              {requireRender()}
            </span>
          }
          type={visibilityInput ? "text" : type}
          variant={variant}
          value={value || ""}
          multiline={multiline}
          {...other}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {!!isEyeVisibilityIcon && (
          <div
            className={clsx(classes.inputIcon, other.disabled ? classes.inputIconDisabled : "")}
            onClick={() => {
              if (!other.disabled) {
                setVisibilityInput(!visibilityInput);
              }
            }}
          >
            {!visibilityInput ? <Visibility /> : <VisibilityOff />}
          </div>
        )}
        {searchIcon && (
          <div className={clsx(classes.searchIcon)}>
            <img src={SearchIcon} title="search" alt="search" />
          </div>
        )}
        {!!inputIcon && (
          <div className={clsx(classes.inputIcon, other.disabled ? classes.inputIconDisabled : "")}>
            {inputIcon}
          </div>
        )}
        {!notError && (
          <FormHelperText
            className={clsx(classes.errorText, touched && !!errorText ? "" : classes.errorTextHide)}
          >
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

TextInput.defaultProps = {
  required: false,
  error: {},
  className: "",
  type: "text",
  variant: "outlined",
  setRef: () => {},
  inputIcon: null,
  loading: false,
  ref: null,
  errorText: "",
  formProps: {},
  isEyeVisibilityIcon: false,
  searchIcon: false,
  label: "",
  touched: false,
  value: "",
  notError: false,
};

TextInput.propTypes = {
  inputIcon: PropTypes.object,
  ref: PropTypes.object,
  error: PropTypes.object,
  className: PropTypes.any,
  label: PropTypes.any,
  setRef: PropTypes.func,
  errorText: PropTypes.any,
  formProps: PropTypes.any,
  type: PropTypes.string,
  variant: PropTypes.string,
  touched: PropTypes.any,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  isEyeVisibilityIcon: PropTypes.bool,
  searchIcon: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notError: PropTypes.bool,
};

export default TextInput;
