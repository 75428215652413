import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    marginTop: 30,
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 30,
    paddingBottom: 5,
  },
  selectField: {
    width: 200,
  },
  largeInputField: {
    width: 340,
  },
  inputField: {
    width: 130,
  },
  smallInputField: {
    width: 60,
  },
  deleteBtn: {
    padding: 5,
    marginLeft: 25,
  },
  subTotalTitle: {
    textAlign: "right",
    paddingRight: 10,
  },
  subTotal: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  addButtonContainer: {
    paddingTop: 20,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  priceSection: {
    paddingRight: 10,
    textAlign: "right",
  },
  vat: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 5,
  },
  totalPriceSection: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 30,
    textAlign: "right",
  },
  additionalInputTitle: {
    width: 300,
    paddingTop: 10,
  },
  displayInline: {
    display: "inline-block",
  },
  inlineTitle: {
    paddingRight: 40,
  },
  errorText: {
    color: "#D42600",
    fontSize: "12px",
    marginTop: "16px",
  },
}));

const TestingCostDetails = ({
  isNewInvoice = false,
  subtitle,
  totalPriceError,
  detailData,
  testItems,
  testItemLength,
  prepaymentData,
  handleDetailDataChange,
  handleTestItemDataChange,
  handleAddNewTestItem,
  handleRemoveTestItem,
  handleCheckboxChange,
}) => {
  const classes = useStyles();

  const currencyOptions = [
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "HKD",
      label: "HKD",
    },
    {
      value: "RMB",
      label: "RMB",
    },
  ];

  return (
    <Paper className={classes.container}>
      <Box className={classes.sectionTitle}>Design Test Plan</Box>

      {subtitle && <Box className={classnames(classes.inputTitle)}>{subtitle}</Box>}

      <Divider className={classes.divider} />

      <Box className={classes.inputTitle}>Currency</Box>
      <Select
        label="Currency (Select One)"
        name="currency"
        value={detailData.currency}
        options={currencyOptions}
        handleChange={handleDetailDataChange}
        additionalClass={classes.selectField}
      />

      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Box className={classes.inputTitle}>Test Item</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.inputTitle}>Quantity</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.inputTitle}>Cost</Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classnames(classes.inputTitle, classes.subTotalTitle)}>Sub-Total</Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      {testItems.map((item, index) => (
        <Grid key={index} container spacing={3}>
          <Grid item xs={5}>
            <TextInput
              placeholder="Item name"
              name="itemName"
              value={item.itemName}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              placeholder="Quantity"
              type="number"
              name="quantity"
              value={item.quantity}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              placeholder="Unit price"
              name="unitPrice"
              value={item.unitPrice}
              onChange={(e) => handleTestItemDataChange(e, index)}
            />
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.subTotal}>{item.subTotal}</Box>
          </Grid>

          <Grid item xs={1}>
            {item.hasReport !== 1 && (
              <IconButton onClick={() => handleRemoveTestItem(index)} className={classes.deleteBtn}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      <Box className={classes.addButtonContainer}>
        <OutlinedButton
          label="+ ADD NEW TEST ITEM"
          handleClick={handleAddNewTestItem}
          color="primary"
        />
      </Box>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Box className={classes.priceSection}>
            <Box className={classes.inputTitle}>
              <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
                Sub-total:
              </Box>
              <Box className={classes.displayInline}>
                {detailData?.isPackagePrice ? detailData?.packagePrice || 0 : detailData?.subTotal}
              </Box>
            </Box>

            {!isNewInvoice &&
              !prepaymentData?.showPrepaymentOption &&
              prepaymentData?.hasPrepayment && (
                <Box className={classes.inputTitle}>
                  <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
                    Prepayment Price:
                  </Box>
                  <Box className={classes.displayInline}>- {prepaymentData?.prepaymentPrice}</Box>
                </Box>
              )}

            <Box className={classes.vat}>
              <Box className={classnames(classes.displayInline, classes.inlineTitle)}>VAT:</Box>
              <Box className={classes.displayInline}>
                <TextInput
                  placeholder="0"
                  name="vat"
                  value={detailData.vat}
                  additionalClass={classes.inputField}
                  onChange={handleDetailDataChange}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Box className={classnames(classes.inputTitle, classes.totalPriceSection)}>
            <Box className={classnames(classes.displayInline, classes.inlineTitle)}>
              Total Price:
            </Box>
            <Box className={classes.displayInline}>
              {detailData.totalPrice} {detailData.currency}
            </Box>

            {totalPriceError && <Box className={classes.errorText}>{totalPriceError}</Box>}
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Box className={classes.sectionTitle}>Additional Information</Box>

      <Divider className={classes.divider} />

      <Box display="flex" className={classes.inputTitle}>
        <Box className={classes.additionalInputTitle}>Sample Size</Box>
        <Box>
          <TextInput
            placeholder="Sample size"
            name="sampleSize"
            value={detailData.sampleSize}
            additionalClass={classes.largeInputField}
            onChange={handleDetailDataChange}
          />
        </Box>
      </Box>

      <Box display="flex" className={classes.inputTitle}>
        <Box className={classes.additionalInputTitle}>This is a Package Price</Box>
        <Box>
          <Checkbox
            name="isPackagePrice"
            checked={detailData.isPackagePrice}
            onChange={handleCheckboxChange}
            color="primary"
          />{" "}
          Yes
        </Box>
      </Box>

      {detailData.isPackagePrice && (
        <>
          <Box display="flex" className={classes.inputTitle}>
            <Box className={classes.additionalInputTitle}>Package Price</Box>
            <Box>
              <TextInput
                placeholder="Package Price"
                type="number"
                name="packagePrice"
                value={detailData.packagePrice}
                additionalClass={classes.largeInputField}
                onChange={handleDetailDataChange}
              />
            </Box>
          </Box>

          <Box display="flex" className={classes.inputTitle}>
            <Box className={classes.additionalInputTitle}>Package Remarks</Box>
            <Box>
              <TextInput
                placeholder="Package Remarks"
                name="packageRemark"
                value={detailData.packageRemark}
                additionalClass={classes.largeInputField}
                onChange={handleDetailDataChange}
              />
            </Box>
          </Box>
        </>
      )}

      <Box display="flex" className={classes.inputTitle}>
        <Box className={classes.additionalInputTitle}>Estimated Turnaround Time (TAT)</Box>
        <Box>
          <TextInput
            placeholder="Estimated Turnaround Time (TAT)"
            name="tat"
            value={detailData.tat}
            additionalClass={classes.largeInputField}
            onChange={handleDetailDataChange}
          />
        </Box>
      </Box>

      {prepaymentData?.showPrepaymentOption && (
        <Box display="flex" className={classes.inputTitle}>
          <Box className={classes.additionalInputTitle}>Request Pre-Payment</Box>
          <Box>
            <Checkbox
              name="isPrepayment"
              checked={detailData.isPrepayment}
              onChange={handleCheckboxChange}
              color="primary"
            />{" "}
            Yes
          </Box>
        </Box>
      )}

      <Box display="flex" className={classes.inputTitle}>
        <Box className={classes.additionalInputTitle}>Remarks</Box>
        <Box>
          <TextInput
            placeholder="Addtional Remarks (Optional)"
            name="remarks"
            value={detailData.remarks}
            additionalClass={classes.largeInputField}
            onChange={handleDetailDataChange}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default TestingCostDetails;
