import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import DownloadButton from "./DownloadButton";
import InfoBox from "./InfoBox";

import { atnGetLabAdminSubmissionDetailsTaskList } from "actions/submissionActions";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menuButton: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: theme.spacing(4),
  },
}));

const MenuBar = ({
  history,
  data,
  submissionId,
  userType,
  isAdmin,
  labStatus,
  handleCancelTaskDialogOpen,
}) => {
  const classes = useStyles();

  const [taskListData, setTaskListData] = useState([]);

  useEffect(() => {
    if (!data.id) return;
    getTaskList(data.id);
  }, []);

  const getTaskList = (id) => {
    atnGetLabAdminSubmissionDetailsTaskList({ data: { id }, type: userType }).then((result) => {
      setTaskListData(result.data);
    });
  };

  const handleBack = () => {
    history.push("/lab_admin/submissions");
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleBack}
          >
            <BackIcon />
          </IconButton>
          <InfoBox data={data} />

          {labStatus !== "rejected" && data.status !== "cancelled" && (
            <DownloadButton submissionId={submissionId} taskListData={taskListData} />
          )}
          {/* {!isAdmin && labStatus !== "rejected" && data.taskType !== "SS" && data.status !== "cancelled" && data.status !== "completed" &&
            <ActionButton
              status={data.status}
              userType={userType}
              submissionId={submissionId}
              handleCancelTaskDialogOpen={handleCancelTaskDialogOpen}
            />
          } */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(MenuBar);
