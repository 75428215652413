import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Multiselect } from "multiselect-react-dropdown";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "220px",
    border: "1px solid #003DA5",
    boxShadow: "0 2px 10px rgba(35, 43, 58, 0.25)",
    borderRadius: "4px",

    "> div": {
      overflowY: "hidden",
    },

    "& .search-wrapper": {
      borderRadius: 0,
      border: 0,
      borderBottom: "1px solid #cccccc",
      padding: "9px 10px",
    },

    "& .search-wrapper > span": {
      display: "none",
    },

    "& .multiselect-container": {
      height: "200px",
    },

    "& .optionListContainer": {
      display: "block",
      height: "160px",
      marginTop: 0,
    },

    "& .optionContainer": {
      maxHeight: "initial",
      borderRadius: 0,
      background: "white",
      height: "160px",
      overflowY: "auto",
    },

    "& .optionContainer li": {
      padding: "7px 10px",
      background: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      width: "max-content",
      display: "flex",
      alignItems: "center",
    },
    "& .optionContainer li input": {
      width: 16,
      height: 16,
    },
  },
}));

const SearchFilter = ({ placeholder = "Search", list = [], cbSetFilter, selectedValues = [] }) => {
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(selectedValues);

  useEffect(() => {
    setSelectedOption(selectedValues);
  }, [selectedValues]);

  const handleOnSelect = (selectedList, selectedItem) => {
    cbSetFilter(selectedList);
  };

  const handleOnRemove = (selectedList, removedItem) => {
    cbSetFilter(selectedList);
  };

  return (
    <Box className={classes.root}>
      <Multiselect
        options={list}
        displayValue="key"
        showCheckbox={true}
        onSelect={handleOnSelect}
        onRemove={handleOnRemove}
        selectedValues={selectedOption}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default SearchFilter;
