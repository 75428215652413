import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import ReactTable from "components/table/ReactTable";
import classTable from "./../style.module.scss";

import { NA, PB_STATUS_TYPE } from "shared/constants";
import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    padding: "10px 0",
  },

  searchContent: {
    marginBottom: "10px",
    fontWeight: "500",

    "& span:not(:last-child)": {
      marginRight: "10px",
    },
  },

  wrapperSearch: {
    marginBottom: "10px",
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  normalCell: {},

  warningCell: {
    color: "#D32F2F",
    fontWeight: "bold",
  },
  status: {
    textTransform: "capitalize",
  },
  noStatus: {
    textTransform: "capitalize",
    color: "#D42600",
  },
}));

const PBTable = ({ listSearch, listPBDaily, cbSetDataShowTable = () => {} }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const noSortFilter = { canSort: false, canFilter: false };

  const getPbStatus = (status) => {
    const result = PB_STATUS_TYPE.find((statusType) => statusType.value === status);

    return result ? result.label : NA;
  };

  const tableColumn = [
    {
      id: "pbNumber",
      value: ({ pbNumber, pbLineId }) => {
        return { pbNumber, pbLineId };
      },
      label: "PB Number",
      ...noSortFilter,
      render: ({
        cell: {
          value: { pbNumber, pbLineId },
        },
      }) => {
        return (
          <Box className={classes.normalCell}>
            {pbNumber} [{pbLineId}]
          </Box>
        );
      },
    },
    {
      id: "pbQuantity",
      value: "pbQuantity",
      label: "PB Quantity",
      ...sortOnly,
      render: ({ cell: { value: pbQuantity } }) => {
        return <Box className={classes.normalCell}>{pbQuantity}</Box>;
      },
    },
    {
      id: "lineItemState",
      value: "pbStatus",
      label: "PB Status",
      ...sortOnly,
      render: ({ cell: { value: pbStatus } }) => {
        return <Box className={classes.normalCell}>{getPbStatus(pbStatus)}</Box>;
      },
    },
    {
      id: "pbImportedDate",
      value: "pbImportedDate",
      label: "PB Import Date",
      ...sortOnly,
      render: ({ cell: { value: pbImportedDate } }) => {
        return (
          <Box className={classes.normalCell}>
            {!pbImportedDate ? "N/A" : textOrUnknown(getDateFormat(pbImportedDate))}
          </Box>
        );
      },
    },
    {
      id: "relatedPO",
      value: "relatedPONumbers",
      label: "Related PO",
      ...noSortFilter,
      render: ({ cell: { value: relatedPONumbers } }) => {
        return (
          <Box className={classes.normalCell}>{!relatedPONumbers ? "N/A" : relatedPONumbers}</Box>
        );
      },
    },
  ];

  // ============= HANDLE SEARCH AND FILTER LIST ====================

  const [listPBDailyDone, setListPBDailyDone] = useState([]);
  const [listKeySearch, setListKeySearch] = useState([]);

  useEffect(() => {
    setListPBDailyDone(listPBDaily);
  }, [listPBDaily]);

  useEffect(() => {
    setListKeySearch(listSearch.split(new RegExp("[,]", "g")).map((item, index) => item.trim()));
  }, [listSearch]);

  useEffect(() => {
    handleFilteredList();
  }, [listKeySearch]);

  const handleFilteredList = () => {
    let result = listPBDaily.filter((item) => {
      return listKeySearch.length === 1 && listKeySearch[0] === ""
        ? item
        : listKeySearch.filter((itemSearch) => item.pbNumber.includes(itemSearch)).length > 0;
    });

    setListPBDailyDone(result);
  };

  const [pageSize, setPageSize] = useState(50);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  useEffect(() => {
    cbSetDataShowTable({ pageSize, newPage, listDone: listPBDailyDone });
  }, [listPBDailyDone, pageSize, newPage]);

  return (
    <Box className={classes.wrapperBasic}>
      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          columns={tableColumn}
          data={listPBDailyDone}
          hasPagingTop={true}
          rowsPerPageOptions={[10, 20, 50, { label: "All" }]}
          classes={{
            root: classTable.pbTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default PBTable;
