import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import _find from "lodash/find";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import auth from "auth/authenticator";
import EmptyDetailBox from "./TaskDetail/EmptyDetailBox";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import PayerDetail, { defaultBillingAddress } from "pages/supplier/Workflow/components/PayerDetail";

import {
  atnGetItemDetailsTaskDetail,
  atnGetItemDetailsTaskDetailInvoice,
  atnGetItemDetailsTaskDetailQuotations,
  atnGetItemDetailsTaskDetailReportsDetails,
  atnGetItemDetailsTaskList,
} from "actions/itemActions";

import { atnGetTaskPayer, atnUpdateTaskPayer } from "actions/taskActions";

import TaskList from "./TaskList";
import TaskDetail from "./TaskDetail";
import { SEASONAL, USER_TYPE } from "shared/constants";

import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  itemRight: {
    paddingLeft: theme.spacing(4),
  },
  popupPayerModal: {
    "& > div > div": {
      height: 880,
    },
  },
}));

const DEFAULT_DETAIL_TABS = [
  {
    title: "Quote",
    content: "Quote content",
  },
  {
    title: "Report & Detail",
    content: "Report & Detail content",
  },
  { title: "Invoice", content: "Invoice content" },
  { title: "Remark", content: "Log content" },
];

const TaskMain = ({ data, taskTypeState: { taskType, setTaskType }, handleGetTasks }) => {
  const userType = auth.userType();

  const classes = useStyles();
  const { t } = useTranslation();

  const [showEmptyDetailBox, setShowEmptyDetailBox] = useState(false);
  const [showEmptyTaskBox, setShowEmptyTaskBox] = useState(false);
  const [taskListData, setTaskListData] = useState([]);
  const [selectedTask, setSelectedTask] = useState({});
  const [taskDetailData, setTaskDetailData] = useState([]);

  const [showPopupPayer, setShowPopupPayer] = useState(false);
  const [payerInfo, setPayerInfo] = useState(null);
  const [taskSSId, setTaskSSId] = useState(0);
  const [indexTaskSelected, setIndexTaskSeleted] = useState(0);

  const { isSupplier } = useUserType(userType);

  useEffect(() => {
    if (!data.id) return;
    getTaskList(data.id);
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkSSTaskNotFillPayer = (data) => {
    if (!data) return;
    if (!showEmptyDetailBox) {
      for (let i = 0; i < data.length; i++) {
        let task = data[i];
        if (
          task.taskType === "SS" &&
          task.assignTo === "lab" &&
          task.taskStatus !== "cancelled" &&
          task.taskStatusatus !== "completed"
        ) {
          handleGetTaskPayer(task.taskId, data.indexOf(task));
          return;
        }
      }
    }
  };

  const handleGetTaskPayer = (taskId, indexSSTask) => {
    atnGetTaskPayer({ taskId: taskId }).then((res) => {
      if (res.code === 404 || !res?.data) {
        if (isSupplier) {
          setShowPopupPayer(true);
          setTaskSSId(taskId);
          setIndexTaskSeleted(indexSSTask);
        }
        return;
      }

      setPayerInfo(res?.data);
      setShowPopupPayer(false);
    });
  };

  const getTaskList = (id, currentTaskId) => {
    atnGetItemDetailsTaskList({ data: { id }, type: userType }).then((result) => {
      let tempData = isSupplier
        ? result.data.filter((item) => item.taskStatus !== "empty_task")
        : result.data;

      setTaskListData(tempData);

      handleGetTasks(tempData);

      if (!currentTaskId && (!tempData || !tempData[0])) {
        getTaskDetail({});
      } else {
        const taskId = currentTaskId || tempData[0].taskRequirementId;
        const taskData = currentTaskId ? { taskRequirementId: currentTaskId } : tempData[0];

        getTaskDetail(taskData, tempData);

        // set selected item
        setSelectedTask(_find(tempData, { taskRequirementId: taskId }));
      }
    });
  };

  const getTaskDetail = (taskData, listTask) => {
    if (taskData.taskRequirementId) {
      // get item detail
      atnGetItemDetailsTaskDetail({
        data: { id: data.id, taskRequirementId: taskData.taskRequirementId },
        type: userType,
      }).then(({ data: listData, message }) => {
        if (taskData.taskStatus && taskData.taskStatus === "within_validity_period") {
          setShowEmptyDetailBox(false);
          setShowEmptyTaskBox(true);
        } else {
          setShowEmptyTaskBox(false);

          if (
            (data.status === "not_acknowledge" && data.category === SEASONAL && isSupplier) ||
            (data.status === "in_progress" && (!listData || listData.length === 0))
          ) {
            setShowEmptyDetailBox(true);
          } else {
            // setShowEmptyDetailBox(!listData || listData.length === 0);
            setShowEmptyDetailBox(false);
          }
        }

        checkSSTaskNotFillPayer(listTask);

        if (listData && listData.length) {
          // let list = result.data.map((item) => ({ ...item, detailTabs: DEFAULT_DETAIL_TABS }));

          let list = listData;

          // setTaskDetailData(list);

          // list.forEach((detail) => {
          //   getTaskDetailQuotations(list, detail);
          //   getTaskDetailReportsDetails(list, detail);
          //   getTaskDetailInvoice(list, detail);
          // });

          let getDataFunctionArray = [];

          for (let i = 0; i < list.length; i++) {
            getDataFunctionArray.push(getTaskDetailQuotations(list[i]));
            getDataFunctionArray.push(getTaskDetailReportsDetails(list[i]));
            getDataFunctionArray.push(getTaskDetailInvoice(list[i]));
          }

          Promise.all(getDataFunctionArray).then((result) => {
            // for (let i = 0; i < result.length; i++) {
            //   list[Math.floor(i/3)].detailTabs[i % 3].content = result[i];
            // }

            for (let i = 0; i < list.length; i++) {
              let detailTabs = JSON.parse(JSON.stringify(DEFAULT_DETAIL_TABS));

              detailTabs[0].content = result[i * 3];
              detailTabs[1].content = result[i * 3 + 1];
              detailTabs[1].content.lab = list[i].lab;
              detailTabs[2].content = result[i * 3 + 2];

              list[i].detailTabs = detailTabs;
            }

            setTaskDetailData(list);
          });

          // TODO Set current task type: testing/inspection/sample sealing
          setTaskType(_get(list[0], "taskType"));
        }
      });
    } else {
      setShowEmptyTaskBox(true);
    }
  };

  const getTaskDetailQuotations = (item) => {
    return new Promise((resolve, reject) => {
      atnGetItemDetailsTaskDetailQuotations({
        data: { id: data.id, taskId: item.id },
        type: userType,
      }).then(({ data, message }) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      });
    });
  };

  const getTaskDetailReportsDetails = (item) => {
    return new Promise((resolve, reject) => {
      atnGetItemDetailsTaskDetailReportsDetails({
        data: { id: data.id, taskId: item.id },
        type: userType,
      }).then(({ data, message }) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      });
    });
  };

  const getTaskDetailInvoice = (item) => {
    return new Promise((resolve, reject) => {
      atnGetItemDetailsTaskDetailInvoice({
        data: { id: data.id, taskId: item.id },
        type: userType,
      }).then(({ data, message }) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      });
    });
  };

  //   const emptyTaskSupplierCondition =
  //     auth.loginRole() === 'supplier' && data.status === 'not_acknowledge';
  //   const emptyTaskBuyerCondition = _get(data, 'status') === TASK_STATUS.empty_task.value; //&& !taskDetailData.length

  // ================== For Payer Popup handle ==================
  const payerErrorMessage = "Cannot update payer, please try again.";

  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [payerData, setPayerData] = useState({
    billingAddress: defaultBillingAddress,
  });

  const [payerError, setPayerError] = useState("");

  //* payer info for SS task with lab flow
  const handlePayerInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setPayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (taskId) => {
    setPayerError("");
    setIsDisabledConfirmButton(true);

    let tempBillingAddress = payerData?.billingAddress;

    if (isNewBillingAddress) {
      delete payerData?.billingAddress.id;
    }

    if (payerData?.billingAddress.invoiceType === "icw_company_invoice") {
      delete tempBillingAddress.attachments;
      delete tempBillingAddress.taxInformationFiles;
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType === "plain_vat_invoice") {
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType !== "icw_company_invoice") {
      let taxInformationFiles = [];

      for (let i = 0; i < payerData?.billingAddress?.attachments?.length; i++) {
        taxInformationFiles.push({
          id: payerData?.billingAddress?.attachments[i]?.id,
          type: "tax_information",
        });
      }

      tempBillingAddress.taxInformationFiles = taxInformationFiles;

      if (!payerInfo) {
        delete tempBillingAddress.attachments;
      }
    }

    atnUpdateTaskPayer({ taskId, data: payerData?.billingAddress })
      .then((res) => {
        if (!res?.data) return setPayerError(payerErrorMessage);

        handleGetTaskPayer(taskId);
        setShowPopupPayer(false);
      })
      .finally(() => {
        setIsDisabledConfirmButton(false);
      });
  };
  // ================== End For Payer Popup handle ==================

  return (
    <>
      <Grid
        data-cy="task"
        container
        justify="space-between"
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={3} sm={3} md={3} lg={3} data-cy="task-list">
          <TaskList
            data={{
              list: taskListData,
              userType,
              taskType,
              category: data.category,
              statusProject: data.status,
            }}
            func={{
              onChange: getTaskDetail,
              onUpdate: () => getTaskList(data.id, _get(selectedTask, "taskRequirementId")),
            }}
            indexTaskSelected={indexTaskSelected}
            payerInfo={payerInfo}
          />
        </Grid>
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={9}
          // className={classes.itemRight}
          data-cy="task-detail"
        >
          {showEmptyDetailBox ? (
            <EmptyDetailBox
              itemId={data.id}
              userType={isSupplier ? USER_TYPE.supplier : USER_TYPE.buyer}
            />
          ) : showEmptyTaskBox ? (
            <EmptyDetailBox itemId={data.id} userType={USER_TYPE.buyer} />
          ) : (
            <TaskDetail
              data={{
                brand: data.brand,
                allowSvrr: data.allowSvrr,
                isProgram777: data.isProgram777,
                list: taskDetailData,
                masterWicId: data.id,
                itemStatus: data.status,
                category: data.category,
                taskType,
              }}
              func={{
                onUpdate: () => getTaskDetail(selectedTask),
              }}
              hasPayerInfo={payerInfo}
            />
          )}
        </Grid>

        {/* =================== MODAL POPUP PAYER =================== */}
        <Modal
          title={t(`task.${taskType}`)}
          subTitle="* Enter Payer Detail for Invoice"
          open={showPopupPayer && !showEmptyDetailBox}
          handleClose={() => setShowPopupPayer(false)}
          size="large"
          className={{ classRoot: classes.popupPayerModal }}
        >
          <PayerDetail
            actionsButtonEnabled
            cancelButtonDisabled={false}
            isDisabledConfirmButton={isDisabledConfirmButton}
            data={payerData}
            handleDataChange={handlePayerInfoChange}
            handleSubmit={() => handleSubmit(taskSSId)}
            handleClose={() => setShowPopupPayer(false)}
          />
        </Modal>
        {/* ================== END MODAL POPUP PAYER ===================== */}
      </Grid>
    </>
  );
};

export default TaskMain;
