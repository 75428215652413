import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  container: {
    padding: "10px 30px",
  },
  taskContainer: {
    borderBottom: "1px solid #cccccc",
    padding: "30px 0px 20px 0px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  taskType: {
    fontSize: 16,
  },
  inputTitle: {
    fontSize: 12,
    paddingRight: 15,
  },
  inputText: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      fontSize: 12,
    },
  },
  errorMessage: {
    fontSize: 8,
    color: "#fd3a37",
    padding: "3px 0px 3px 90px",
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const TaskPlannedDate = ({
  taskData = [],
  handleTaskPlannedDateChange,
  disableSubmitButton,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.container}>
        {taskData.map((task, index) => (
          <Grid container spacing={3} key={index} className={classes.taskContainer}>
            <Grid item xs={4} className={classes.taskType}>
              {t(`task.${task.taskType}`)}
            </Grid>
            <Grid item xs={4}>
              <span className={classes.inputTitle}>{SIMPLE_BUYER_NAME} Deadline:</span>
              <span className={classes.inputText}>
                {moment(task.deadLine).format("DD/MMM/YYYY")}
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className={classes.inputTitle}>Planned Date:</span>
              <span className={classes.inputText}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    data-cy="select-date"
                    variant="inline"
                    className={classes.datePicker}
                    format="DD/MMM/YYYY"
                    placeholder="dd/mm/yyyy"
                    value={task.plannedDate || null}
                    name="plannedDate"
                    onChange={(e) => handleTaskPlannedDateChange(e, index)}
                    maxDate={moment(task.shipByDate)._d}
                    error={
                      task.plannedDate &&
                      moment(task.plannedDate).startOf("day") > moment(task.deadLine).startOf("day")
                    }
                  />
                </MuiPickersUtilsProvider>
                {task.plannedDate &&
                  moment(task.plannedDate).startOf("day") >
                    moment(task.deadLine).startOf("day") && (
                    <Box className={classes.errorMessage}>
                      Later than {SIMPLE_BUYER_NAME} deadline (
                      {moment(task.deadLine).format("DD/MMM/YYYY")})
                    </Box>
                  )}
              </span>
            </Grid>
          </Grid>
        ))}
      </Paper>

      <Button
        data-cy="continue-taskdate"
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleSubmit}
        disabled={disableSubmitButton}
      >
        CONFIRM
      </Button>
    </>
  );
};

export default TaskPlannedDate;
