import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";

import GifLoading from "components/display/GifLoading";
import SearchSection from "components/searchBar/SearchSection";
import SubmissionListTab from "./SubmissionListTab";
import SubmissionListTable from "./SubmissionListTable/index";
import { formatHeader } from "utils/formatSubmissionTableData";
import { atnGetSubmissionListData, atnGetSubmissionListHeader } from "actions/submissionActions";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  topContainer: {
    height: 80,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #AAAAAA",
  },
  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  tableInfo: {
    fontWeight: "normal",
    fontSize: 10,
    paddingLeft: 10,
  },
  tableContainer: {
    paddingTop: 10,
    width: "fit-content",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
      padding: "4px 6px",
    },
  },
  dialogContent: {
    padding: "10px 30px",
    borderBottom: "none",
  },
  dialogInfo: {
    fontSize: 10,
  },
  dialogSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 30,
  },
  dialogSelectText: {
    fontSize: 12,
    fontWeight: "normal",
    paddingTop: 5,
  },
  dialogSelect: {
    paddingTop: 6,
    fontSize: 12,
    fontWeight: "normal",
    width: 312,
  },
  dialogEditContainer: {
    borderTop: "1px solid #AAAAAA",
    marginTop: 110,
    minHeight: 450,
  },
  dialogEditSection: {
    width: 400,
  },
  dialogConfirmButton: {
    borderRadius: 2,
    height: 36,
  },
  datePicker: {
    paddingTop: 5,
    "& .MuiInputBase-root": {
      "& input": {
        padding: 11,
      },
    },
  },
  imageGalleryContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    backgroundColor: "rgb(0, 0, 0, .5)",
    zIndex: 999,
  },
  imageGallery: {
    top: 0,
    bottom: 0,
  },
  imageGalleryImage: {
    height: 500,
    objectFit: "contain",
  },
  manageColumnContainer: {
    paddingTop: 30,
  },
  manageColumnBoxTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
  },
  manageColumnBox: {
    width: 320,
    border: "1px solid #000000",
    borderRadius: 4,
  },
  addTaskTypeButton: {
    marginTop: 8,
  },
}));

const LabSubmissionList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingTable, setLoadingTable] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [submissionList, setSubmissionList] = useState([]);
  const [filterName, setFilterName] = useState("");

  const actionMenuItems = [
    {
      name: "export",
      type: "export",
      label: `Export (${selectedItem.length === 0 ? "All" : selectedItem.length}) Submission`,
    },
    // {
    //   name: "download",
    //   type: "download",
    //   label: `Bulk Download`
    // },
  ];

  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [progressNumber, setProgressNumber] = useState({
    all: 0,
    completed: 0,
    selectedItem: 0,
    onGoing: 0,
  });

  const [selectedFilter, setSelectedFilter] = useState({
    taskType: [],
    taskStatus: [],
  });
  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });
  const [searchSectionData, setSearchSectionData] = useState({
    keyword: "",
  });

  const [bulkEditType, setBulkEditType] = useState("");
  const [bulkEditTaskType, setBulkEditTaskType] = useState("");
  const [newWbaDeadline, setNewWbaDeadline] = useState("");
  const [newShipByDate, setNewShipByDate] = useState("");
  const [bulkAddTaskTypes, setBulkAddTaskTypes] = useState([]);
  const [bulkDeleteTaskTypes, setBulkDeleteTaskTypes] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    atnGetSubmissionListHeader({
      type: 3,
    }).then(({ data, message }) => {
      if (data && data[0]) {
        setHeaderData(formatHeader(data[0].header, t));
      }
    });

    // atnGetSubmissionListData({
    //   type: 3,
    //   data: {},
    // })
    // .then(({data, message}) => {
    //   if (data) {
    //     setSubmissionList(data.list);
    //     setProgressNumber(data.progressNumber);

    //     let totalRecords = 0;

    //     if (tab === 0) {
    //       totalRecords = data.progressNumber.all || 0;
    //     } else if (tab === 1) {
    //       totalRecords = data.progressNumber.waitingForLabQuote || 0;
    //     } else if (tab === 2) {
    //       totalRecords = data.progressNumber.onGoing || 0;
    //     } else if (tab === 3) {
    //       totalRecords = data.progressNumber.completed || 0;
    //     }

    //     let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

    //     setPageData({
    //       ...data.page,
    //       totalRecords: totalRecords,
    //       totalPages: totalPages
    //     });
    //   }
    // });
  }, []);

  const searchSubmissions = (page) => {
    setLoadingTable(true);

    let filters = [];

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["waiting_for_lab_quote"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: pageData.rowsPerPage,
      filters: filters,
      search: searchSectionData.keyword,
    };

    atnGetSubmissionListData({
      type: 3,
      data: conditions,
    }).then(({ data, message }) => {
      if (data) {
        setSubmissionList(data.list);
        setProgressNumber(data.progressNumber);

        let totalRecords = 0;

        if (tab === 0) {
          totalRecords = data.progressNumber.all || 0;
        } else if (tab === 1) {
          totalRecords = data.progressNumber.waitingForLabQuote || 0;
        } else if (tab === 2) {
          totalRecords = data.progressNumber.onGoing || 0;
        } else if (tab === 3) {
          totalRecords = data.progressNumber.completed || 0;
        }

        let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setInitLoading(false);
        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    searchSubmissions();
  }, [
    pageData.currentPage,
    pageData.rowsPerPage,
    tab,
    searchConditions.orderBy,
    searchConditions.order,
    selectedFilter.taskType.length,
    selectedFilter.taskStatus.length,
  ]);

  const handleSearchDataChange = (e) => {
    const { name, value } = e.target;

    setSearchSectionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    searchSubmissions(1);
  };

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));
  };

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleSubmissionSelect = (e, submissionId, submissionIndex) => {
    if (e.target.checked) {
      if (selectedSubmission.indexOf(submissionId) === -1) {
        setSelectedSubmission((prevState) => {
          return prevState.concat(submissionId);
        });
      }

      let newTaskIds = [];

      for (let i = 0; i < submissionList[submissionIndex].tasks.length; i++) {
        if (selectedItem.indexOf(submissionList[submissionIndex].tasks[i].id) === -1) {
          newTaskIds.push(submissionList[submissionIndex].tasks[i].id);
        }
      }

      setSelectedItem((prevState) => {
        return prevState.concat(newTaskIds);
      });
    } else {
      if (selectedSubmission.indexOf(submissionId) >= 0) {
        setSelectedSubmission((prevState) => {
          return prevState.filter((item) => {
            return item !== submissionId;
          });
        });
      }

      let taskIds = selectedItem;

      for (let i = 0; i < submissionList[submissionIndex].tasks.length; i++) {
        if (selectedItem.indexOf(submissionList[submissionIndex].tasks[i].id) >= 0) {
          taskIds.splice(selectedItem.indexOf(submissionList[submissionIndex].tasks[i].id), 1);
        }
      }

      setSelectedItem(taskIds);
    }
  };

  const handleItemSelect = (e, projectId) => {
    if (e.target.checked) {
      if (selectedItem.indexOf(projectId) === -1) {
        setSelectedItem((prevState) => {
          return prevState.concat(projectId);
        });
      }
    } else {
      if (selectedItem.indexOf(projectId) >= 0) {
        setSelectedItem((prevState) => {
          return prevState.filter((item) => {
            return item !== projectId;
          });
        });
      }
    }
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [openDeleteTaskDialog, setOpenDeleteTaskDialog] = useState(false);

  const handleOpenDialog = (type) => {
    if (type === "edit") {
      setOpenEditDialog(true);
    } else if (type === "add") {
      setBulkAddTaskTypes([""]);
      setOpenAddTaskDialog(true);
    } else if (type === "delete") {
      setBulkDeleteTaskTypes([""]);
      setOpenDeleteTaskDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setBulkEditType("");
    setBulkEditTaskType("");
    setNewWbaDeadline("");
    setNewShipByDate("");
  };

  const handleCloseAddTaskDialog = () => {
    setOpenAddTaskDialog(false);
    setBulkAddTaskTypes([]);
  };

  const handleCloseDeleteTaskDialog = () => {
    setOpenDeleteTaskDialog(false);
    setBulkDeleteTaskTypes([]);
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleBulkEditTypeChange = (e) => {
    const { value } = e.target;

    setBulkEditType(value);
  };

  const handleBulkEditTaskTypeChange = (e) => {
    const { value } = e.target;

    setBulkEditTaskType(value);
  };

  const handleNewWbaDeadlineChange = (e) => {
    const { value } = e.target;

    setNewWbaDeadline(value);
  };

  const handleNewShipByDateChange = (e) => {
    const { value } = e.target;

    setNewShipByDate(value);
  };

  const handleBulkAddTaskTypeChange = (e, index) => {
    const { value } = e.target;

    setBulkAddTaskTypes((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item = value;
        }

        return item;
      });
    });
  };

  const handleBulkDeleteTaskTypeChange = (e, index) => {
    const { value } = e.target;

    setBulkDeleteTaskTypes((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item = value;
        }

        return item;
      });
    });
  };

  const handleAddTaskType = (type) => {
    if (type === "bulkAdd") {
      setBulkAddTaskTypes((prevState) => {
        return prevState.concat("");
      });
    } else if (type === "bulkDelete") {
      setBulkDeleteTaskTypes((prevState) => {
        return prevState.concat("");
      });
    }
  };

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (filterName === "taskType") {
      if (filterItemValue === "ST") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["ST", "STD"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "ST" && item !== "STD"),
          }));
        }
      } else if (filterItemValue === "SPU") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["SPU", "SPUD"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "SPU" && item !== "SPUD"),
          }));
        }
      } else if (filterItemValue === "FRI") {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(["FRI", "FRID"]),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== "FRI" && item !== "FRID"),
          }));
        }
      } else {
        if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].concat(filterItemValue),
          }));
        } else {
          setSelectedFilter((prevState) => ({
            ...prevState,
            [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
          }));
        }
      }
    } else {
      if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
        setSelectedFilter((prevState) => ({
          ...prevState,
          [filterName]: prevState[filterName].concat(filterItemValue),
        }));
      } else {
        setSelectedFilter((prevState) => ({
          ...prevState,
          [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
        }));
      }
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleOpenSubmission = (id) => {
    window.open(`/lab/submissions/${id}`);
  };

  return (
    <Box className={classes.root}>
      {loadingTable && <GifLoading />}

      <Box className={classes.topContainer}>
        <SearchSection
          title="Task Submission List"
          searchSectionData={searchSectionData}
          handleSearchDataChange={handleSearchDataChange}
          handleSearch={handleSearch}
          hasActionMenu={true}
          selectedItemNumber={selectedItem.length}
          actionMenuItems={actionMenuItems}
          handleOpenDialog={handleOpenDialog}
        />
      </Box>

      <Box>
        <SubmissionListTab
          tab={tab}
          progressNumber={progressNumber}
          handleTabChange={handleTabChange}
        />
      </Box>

      <Box className={classes.contentContainer}>
        <Typography className={classes.tableTitle} variant="body1">
          {tab === 0 && <>Viewing All Submissions</>}
          {tab === 1 && <>Viewing Waiting For Lab Quote Submissions</>}
          {tab === 2 && <>Viewing Ongoing Submissions</>}
          {tab === 3 && <>Viewing Completed Submissions</>}
        </Typography>
        <Typography className={classes.tableInfo} variant="body1">
          <>
            Showing{" "}
            {pageData.totalRecords > 0 ? (pageData.currentPage - 1) * pageData.rowsPerPage + 1 : 0}{" "}
            -{" "}
            {pageData.currentPage * pageData.rowsPerPage > pageData.totalRecords
              ? pageData.totalRecords
              : pageData.currentPage * pageData.rowsPerPage}{" "}
            of {pageData.totalRecords}
          </>
        </Typography>

        {(!initLoading || !loadingTable) && (
          <Box className={classes.tableContainer}>
            <SubmissionListTable
              selectedSubmission={selectedSubmission}
              selectedItem={selectedItem}
              selectedFilter={selectedFilter}
              filterName={filterName}
              handleSubmissionSelect={handleSubmissionSelect}
              handleItemSelect={handleItemSelect}
              headerData={headerData}
              rowData={submissionList}
              handleRowClick={handleOpenSubmission}
              handleSorting={handleSorting}
              handleFilterListToggle={handleFilterListToggle}
              handleFilterItemSelect={handleFilterItemSelect}
              rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
              currentPage={pageData.currentPage}
              rowsPerPage={pageData.rowsPerPage}
              totalPages={pageData.totalPages}
              totalRecords={pageData.totalRecords}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>

      <Dialog onClose={handleCloseEditDialog} open={openEditDialog} className={classes.dialog}>
        <MuiDialogTitle onClose={handleCloseEditDialog} className={classes.dialogTitle}>
          Bulk Edit Projects
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Editing 250 projects with the following criteria:
          </Box>
          <Box className={classes.dialogInfo}>All Ongoing Items</Box>

          <Box className={classes.dialogSubtitle}>Edit Properties</Box>

          <Box className={classes.dialogSelectText}>Property</Box>

          <Box>
            <Select
              value={bulkEditType}
              onChange={handleBulkEditTypeChange}
              input={<BootstrapInput />}
              className={classes.dialogSelect}
            >
              <MenuItem value="wbaDealine">{SIMPLE_BUYER_NAME} Deadline</MenuItem>
              <MenuItem value="shipByDate">Ship By Date</MenuItem>
            </Select>
          </Box>

          {bulkEditType === "wbaDealine" && (
            <Box display="flex" className={classes.dialogEditContainer}>
              <Box className={classes.dialogEditSection}>
                <Box className={classes.dialogSubtitle}>Task Type</Box>
                <Box className={classes.dialogSelectText}>Task Type</Box>
                <Box>
                  <Select
                    value={bulkEditTaskType}
                    onChange={handleBulkEditTaskTypeChange}
                    input={<BootstrapInput />}
                    className={classes.dialogSelect}
                  >
                    <MenuItem value="QT">Quality Test</MenuItem>
                    <MenuItem value="PST">Pre-Safety Test</MenuItem>
                    <MenuItem value="SS">Sample Sealing</MenuItem>
                    <MenuItem value="SPU">Sample Pickup</MenuItem>
                    <MenuItem value="ST">Safety Test</MenuItem>
                    <MenuItem value="DPI">During Production Inspection</MenuItem>
                    <MenuItem value="FRI">Final Random Inspection</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box className={classes.dialogEditSection}>
                <Box className={classes.dialogSubtitle}>New Value</Box>
                <Box className={classes.dialogSelectText}>{SIMPLE_BUYER_NAME} Dealine</Box>
                <Box>
                  <TextField
                    value={newWbaDeadline}
                    onChange={handleNewWbaDeadlineChange}
                    variant="outlined"
                    color="primary"
                    type="date"
                    className={classes.datePicker}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {bulkEditType === "shipByDate" && (
            <Box className={classes.dialogEditContainer}>
              <Box className={classes.dialogSubtitle}>New Value</Box>
              <Box className={classes.dialogSelectText}>Ship By Date</Box>
              <Box>
                <TextField
                  value={newShipByDate}
                  onChange={handleNewShipByDateChange}
                  variant="outlined"
                  color="primary"
                  type="date"
                  className={classes.datePicker}
                />
              </Box>
            </Box>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleCloseEditDialog}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled
          >
            CONFIRM EDIT
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseAddTaskDialog}
        open={openAddTaskDialog}
        className={classes.dialog}
      >
        <MuiDialogTitle onClose={handleCloseAddTaskDialog} className={classes.dialogTitle}>
          Bulk Add Task
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>Adding Task(s) to 250 selected item</Box>

          <Box className={classes.dialogSubtitle}>Bulk Add</Box>

          {bulkAddTaskTypes.map((item, index) => (
            <Box key={index}>
              <Box className={classes.dialogSelectText}>Task Type</Box>

              <Box>
                <Select
                  value={item}
                  onChange={(e) => handleBulkAddTaskTypeChange(e, index)}
                  input={<BootstrapInput />}
                  className={classes.dialogSelect}
                >
                  <MenuItem value="qualityTest">Quality Test</MenuItem>
                  <MenuItem value="preSafetyTest">Pre-Safety Test</MenuItem>
                  <MenuItem value="sampleSealing">Sample Sealing</MenuItem>
                  <MenuItem value="samplePickup">Sample Pickup</MenuItem>
                  <MenuItem value="safetyTest">Safety Test</MenuItem>
                  <MenuItem value="duringProductionInspection">
                    During Production Inspection
                  </MenuItem>
                  <MenuItem value="finalRandomInspection">Final Random Inspection</MenuItem>
                </Select>
              </Box>
            </Box>
          ))}

          <Button
            variant="outlined"
            color="primary"
            className={classes.addTaskTypeButton}
            onClick={() => handleAddTaskType("bulkAdd")}
          >
            ADD
          </Button>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseAddTaskDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleCloseAddTaskDialog}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled
          >
            CONFIRM ADD
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseDeleteTaskDialog}
        open={openDeleteTaskDialog}
        className={classes.dialog}
      >
        <MuiDialogTitle onClose={handleCloseDeleteTaskDialog} className={classes.dialogTitle}>
          Bulk Delete Task
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Deleting tasks for 250 selected item(s). Please select the task type for bulk delete.
          </Box>

          <Box className={classes.dialogSubtitle}>Bulk Delete</Box>

          {bulkDeleteTaskTypes.map((item, index) => (
            <Box key={index}>
              <Box className={classes.dialogSelectText}>Task Type</Box>

              <Box>
                <Select
                  value={item}
                  onChange={(e) => handleBulkDeleteTaskTypeChange(e, index)}
                  input={<BootstrapInput />}
                  className={classes.dialogSelect}
                >
                  <MenuItem value="qualityTest">Quality Test</MenuItem>
                  <MenuItem value="preSafetyTest">Pre-Safety Test</MenuItem>
                  <MenuItem value="sampleSealing">Sample Sealing</MenuItem>
                  <MenuItem value="samplePickup">Sample Pickup</MenuItem>
                  <MenuItem value="safetyTest">Safety Test</MenuItem>
                  <MenuItem value="duringProductionInspection">
                    During Production Inspection
                  </MenuItem>
                  <MenuItem value="finalRandomInspection">Final Random Inspection</MenuItem>
                </Select>
              </Box>
            </Box>
          ))}

          <Button
            variant="outlined"
            color="primary"
            className={classes.addTaskTypeButton}
            onClick={() => handleAddTaskType("bulkDelete")}
          >
            ADD
          </Button>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDeleteTaskDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleCloseDeleteTaskDialog}
            color="secondary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled
          >
            CONFIRM DELETE
          </Button>
        </MuiDialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(null, {
  atnGetSubmissionListHeader,
  atnGetSubmissionListData,
})(LabSubmissionList);
