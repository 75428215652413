import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  wrapperMultiSelect: {
    "& button": {
      width: 280,
      border: "1px solid #C4C8D0",
      borderRadius: 4,
      boxShadow: "none",
      padding: "7px 8px",
    },

    "& button + div": {
      margin: "0 !important",
      top: 0,
    },
  },

  selectCheckBox: {
    width: "280px",

    "& > div": {
      margin: 0,
    },
  },
}));

const MultiSelectAll = ({
  cbSetData,
  options,
  customStyles,
  textAll,
  setAllValue,
  allValue = false,
}) => {
  const classes = useStyles();

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (allValue) {
      setSelectedOptions([{ label: "All", value: "*" }, ...options]);
    } else {
      setSelectedOptions([]);
    }
  }, [options, setAllValue]);

  useEffect(() => {
    cbSetData(selectedOptions);
  }, [selectedOptions]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `All ${textAll}`;
    } else {
      if (value.length === 0) {
        return `Please selected`;
      } else {
        let str = "";
        for (let i = 0; i < value.length; i++) {
          let commar = i === value.length - 1 ? "" : ", ";
          str += value[i].label + commar;
        }
        return str;
      }
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  return (
    <Box display="block" className={classes.wrapperMultiSelect}>
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel=""
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
        styles={customStyles}
        className={classes.selectCheckBox}
      />
    </Box>
  );
};

export default MultiSelectAll;
