import React, { useCallback } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import OutlinedButton from "components/input/OutlinedButton";
import { atnUploadFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 5,
  },
  fileListContainer: {
    width: "fit-content",
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: "1px solid #777777",
  },
  fileItemContainer: {
    paddingBottom: 5,
  },
  fileName: {
    paddingTop: 5,
    paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  deleteFileBtn: {
    cursor: "pointer",
    paddingTop: 5,
  },
  deleteFileIcon: {
    color: "#777777",
    width: 14,
    height: 14,
  },
}));

const Upload = ({ buttonLabel = "UPLOAD", handleAddFiles, className }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadFile = (file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          atnUploadFile(formData).then(({ data, message }) => {
            if (data) {
              resolve(data);
            } else {
              reject();
            }
          });
        });
      };

      let newFiles = [];

      let uploadFileFunctionArray = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadFileFunctionArray.push(uploadFile(acceptedFiles[i]));
        newFiles.push({
          name: acceptedFiles[i].name,
        });
      }

      Promise.all(uploadFileFunctionArray).then((result) => {
        for (let i = 0; i < result.length; i++) {
          newFiles[i].id = result[i].id;
        }

        handleAddFiles(newFiles);
      });
    },
    [handleAddFiles],
  );

  return (
    <Box className={classes.container}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <OutlinedButton label={buttonLabel} color="primary" className={className} />
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

export default connect(null, { atnUploadFile })(Upload);
