import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { atnSendResetPasswordEmail } from "actions/authActions";
import TextInput from "components/input/TextInput";

const useStyles = makeStyles(() => ({
  pageTitle: {
    textAlign: "center",
    marginTop: 120,
    fontSize: 28,
    fontWeight: 500,
  },
  card: {
    marginTop: 80,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 6,
  },
  inputField: {
    width: "100%",
  },
  fieldPadding: {
    paddingTop: 20,
  },
  continueBtn: {
    marginTop: 20,
    width: "100%",
  },
}));

const ResetPassword = ({ history }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");

  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const Submit = () => {
    atnSendResetPasswordEmail({
      email: email,
    }).then(({ data, message }) => {
      setShowMessage(true);

      setTimeout(() => {
        history.push("/login");
      }, 10000);
    });
  };

  return (
    <Container maxWidth="sm">
      <Box className={classes.pageTitle}>Reset Password</Box>
      <Box className={classes.card}>
        {!showMessage && (
          <>
            <Box className={classes.title}>Email Address</Box>
            <TextInput
              placeholder="Email"
              name="email"
              value={email}
              additionalClass={classes.inputField}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.continueBtn}
              onClick={Submit}
              disabled={!email}
            >
              SEND VERIFICATION EMAIL
            </Button>
          </>
        )}

        {showMessage && (
          <>
            <Box>The verification email has been sent to the following email:</Box>
            <Box>{email}</Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ResetPassword;
