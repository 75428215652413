import React, { memo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const ErrorModal = ({
  className,
  title = "Error",
  content = "Please fill in required input(s).",
  closeText = "Close",
  submitText = "Confirm",
  open,
  disabled,
  onClose,
  onSubmit,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classnames(classes.dialog, className)}
      {...props}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {children}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={disabled}
          onClick={onClose}
          variant={onSubmit ? "text" : "contained"}
          color="primary"
        >
          {closeText}
        </Button>

        {onSubmit && (
          <Button disabled={disabled} onClick={onSubmit} variant="contained" color="primary">
            {submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(ErrorModal);

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 600,
        width: 800,
        minHeight: 200,
      },
    },
  },
  title: {
    color: "#D42600",
    fontSize: 14,
    fontWeight: 500,
    borderBottom: "1px solid #003DA5",
  },
  content: {
    fontSize: 10,
  },
  pbNumber: {
    color: "#D42600",
  },
}));
