import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Program777Chip from "components/display/Program777Chip";

import TrafficLight from "components/display/TrafficLight";

import { isShowProgram777 } from "services/pbService";
import NBChip from "../../../../components/display/NBChip";

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  actionCell: {
    minWidth: 110,
    width: 110,
    paddingLeft: "20px !important",
  },
  submissionCell: {
    minWidth: 180,
    width: 180,
  },
  productNameCell: {
    minWidth: 230,
    width: 230,
  },
  supplierCell: {
    minWidth: 160,
    width: 160,
  },
  factoryCell: {
    minWidth: 180,
    width: 180,
  },
  taskTypeCell: {
    minWidth: 120,
    width: 120,
  },
  taskStatusCell: {
    minWidth: 180,
    width: 180,
  },
  requestedDateCell: {
    minWidth: 120,
    width: 120,
  },
  ecdCell: {
    minWidth: 120,
    width: 120,
  },
  tableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    // maxWidth: 220,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  expandedTableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "none",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableText: {
    maxWidth: 190,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productNameText: {
    maxWidth: 160,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  taskStatusText: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    padding: 0,
  },
  filter: {
    color: "#000000",
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingContainerWithMargin: {
    position: "relative",
    paddingLeft: 2,
    marginRight: 30,
    cursor: "pointer",
  },
  sortingUp: {
    position: "absolute",
    top: -2,
    width: 15,
    height: "auto",
  },
  sortingDown: {
    position: "absolute",
    top: 6,
    width: 15,
    height: "auto",
  },
  selected: {
    color: "#196EFF",
  },
  trafficLight: {
    paddingLeft: 5,
    paddingRight: 35,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  red: {
    width: 7,
    height: 7,
    backgroundColor: "#D42600",
    borderRadius: "50%",
  },
  blue: {
    width: 7,
    height: 7,
    backgroundColor: "#196EFF",
    borderRadius: "50%",
  },
  yellow: {
    width: 7,
    height: 7,
    backgroundColor: "#F2DA00",
    borderRadius: "50%",
  },
  green: {
    width: 7,
    height: 7,
    backgroundColor: "#1BB135",
    borderRadius: "50%",
  },
  grey: {
    width: 7,
    height: 7,
    backgroundColor: "#A3A3A3",
    borderRadius: "50%",
  },
  indicatorContainer: {
    position: "absolute",
    right: 10,
    top: 16,
    // padding: "3px 0px 0px 10px",
  },
  itemDataCheckbox: {
    marginLeft: 30,
  },
}));

const SubmissionListTableRow = ({
  submissionId,
  submissionIndex,
  hasSelectBox,
  checked,
  selectedItem = [],
  row = {},
  itemData = [],
  handleRowClick,
  handleSubmissionSelect,
  handleItemSelect,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [expandMore, setExpandMore] = useState(true);

  const handleExpandClick = () => {
    if (itemData.length > 0) {
      if (!expanded) {
        setExpandMore(true);
      } else {
        setExpandMore(false);
      }
    } else {
      setExpandMore(false);
    }

    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow className={classes.tableRow} data-cy="row" data-cy-submission-id={submissionId}>
        <TableCell className={classnames(classes.tableCell, classes.actionCell)}>
          <IconButton size="small" onClick={() => handleExpandClick()}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>

          {/* <Checkbox
            color="primary"
            checked={checked}
            onChange={e => handleSubmissionSelect(e, submissionId, submissionIndex)}
          /> */}
        </TableCell>
        <TableCell
          className={classnames(classes.tableCell, classes.submissionCell)}
          onClick={() => handleRowClick(submissionId)}
        >
          <Box className={classes.tableText}>
            {row.submissionNumber} {row.isNb && <NBChip />}
            {row.inhouseLabNumber && `| ${row.inhouseLabNumber}`}
          </Box>
        </TableCell>
        <TableCell
          className={classnames(classes.tableCell, classes.productNameCell)}
          onClick={() => handleRowClick(submissionId)}
        />
        <TableCell
          className={classnames(classes.tableCell, classes.supplierCell)}
          onClick={() => handleRowClick(submissionId)}
        >
          <Box className={classes.tableText}>{row.supplierName || ""}</Box>
        </TableCell>
        <TableCell
          className={classnames(classes.tableCell, classes.factoryCell)}
          onClick={() => handleRowClick(submissionId)}
        >
          <Box className={classes.tableText}>{row.factoryName || ""}</Box>
        </TableCell>
        <TableCell
          className={classnames(classes.tableCell, classes.taskTypeCell)}
          onClick={() => handleRowClick(submissionId)}
        />
        <TableCell
          className={classnames(classes.tableCell, classes.taskStatusCell)}
          onClick={() => handleRowClick(submissionId)}
        />
        <TableCell
          className={classnames(classes.tableCell, classes.requestedDateCell)}
          onClick={() => handleRowClick(submissionId)}
        >
          <Box className={classes.tableText}>
            {row.requestedDate ? moment(row.requestedDate).format("DD/MMM/YYYY") : "N/A"}
          </Box>
        </TableCell>
        <TableCell
          className={classnames(classes.tableCell, classes.ecdCell)}
          onClick={() => handleRowClick(submissionId)}
        />
      </TableRow>

      {expandMore && (
        <>
          {itemData.map((item, index) => {
            const showProgram777 = isShowProgram777(item.triggeredBy);

            return (
              <TableRow
                key={item.id}
                className={classes.tableRow}
                data-cy="subrow"
                data-cy-submission={item.id}
                data-cy-product-name={item.productName}
              >
                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.actionCell)
                      : classnames(classes.tableCell, classes.actionCell)
                  }
                >
                  {/* <Checkbox
                  color="primary"
                  checked={selectedItem.indexOf(item.id) >= 0}
                  onChange={e => handleItemSelect(e, item.id)}
                  className={classes.itemDataCheckbox}
                /> */}
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.submissionCell)
                      : classnames(classes.tableCell, classes.submissionCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>{item.taskNumber}</Box>
                  <Box className={classnames(classes.buyer, classes.tableText)}>
                    {item.buyerCompanyName}
                  </Box>
                  <Box className={classnames(classes.info, classes.tableText)}>
                    {item.programName} {item.category === "everyday" ? "" : item.programYear}
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.productNameCell)
                      : classnames(classes.tableCell, classes.productNameCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box display="flex">
                    {showProgram777 && <Program777Chip />}
                    <Box className={classes.productNameText}>{item.productName}</Box>
                  </Box>
                  <Box className={classnames(classes.info, classes.tableText)}>
                    {item.wicNumber}
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.supplierCell)
                      : classnames(classes.tableCell, classes.supplierCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                />

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.factoryCell)
                      : classnames(classes.tableCell, classes.factoryCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                />

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.taskTypeCell)
                      : classnames(classes.tableCell, classes.taskTypeCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  {/* <Box className={classes.tableText}>{t(`task.${item.taskType}`)}</Box> */}
                  <Box>{t(`taskShort.${item.taskType}`)}</Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.taskStatusCell)
                      : classnames(classes.tableCell, classes.taskStatusCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.taskStatusText}>
                    {t(`taskStatus.${item.completedStatus || item.taskStatus}`)}
                  </Box>
                  <Box className={classes.indicatorContainer}>
                    <TrafficLight status={item.completedStatus || item.taskStatus} />
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.requestedDateCell)
                      : classnames(classes.tableCell, classes.requestedDateCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>
                    {item.requestedDate ? moment(item.requestedDate).format("DD/MMM/YYYY") : ""}
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.ecdCell)
                      : classnames(classes.tableCell, classes.ecdCell)
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>
                    {item.ecd ? moment(item.ecd).format("DD/MMM/YYYY") : "N/A"}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};

export default SubmissionListTableRow;
