import api from "./api";

import { BUYER } from "shared/constants";

class tenderingApi {
  static getTenderingExemptionLabList(masterWicId, taskTequirementId) {
    return api.get(
      `/${BUYER}/items/master_wic/${masterWicId}/task_requirements/${taskTequirementId}/exemption/labs`,
    );
  }

  static putTenderingLabExemption(masterWicId, data) {
    return api.put(`/${BUYER}/items/master_wic/${masterWicId}/exemption`, data);
  }
}

export default tenderingApi;
