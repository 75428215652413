import moment from "moment";
import { PB_STATUS, TASK_TRIGGERED_BY, TASK_TYPE_ENUM } from "shared/constants";
import { textOrUnknown } from "utils/string";

export const isTaskSPUD = (taskType) => taskType === TASK_TYPE_ENUM.SPUD;
export const isTaskFRID = (taskType) => taskType === TASK_TYPE_ENUM.FRID;

//show pb without 'shipped' status for spu
export const isValidPbStatusForSPU = ({ pbStatus = "" }) =>
  !!pbStatus && ![PB_STATUS.SHIPPED].includes(pbStatus);

//show pb with 'wip' / 'not existed' status for fri
export const isValidPbStatusForFRI = ({ pbStatus = "" }) =>
  !!pbStatus && [PB_STATUS.WIP, PB_STATUS.NOT_EXISTED].includes(pbStatus);

export const isPbNotExisted = ({ pbStatus = "" }) => pbStatus === PB_STATUS.NOT_EXISTED;

export const isShowProgram777 = (triggeredBy) => {
  return triggeredBy === TASK_TRIGGERED_BY.PB;
};

//show pb with 'wip' status & within valid date for fri
/* validity date (self inspection) - pb imported date >= 31days */
export const isValidPbStatusAndImportedDateForFRI = (
  { pbStatus = "", pbImportedDate = "" },
  selfValidityDate,
) => {
  if (!pbStatus || !selfValidityDate || !pbImportedDate) return false;

  const valDate = moment(selfValidityDate);
  const pbImpDate = moment(pbImportedDate);
  const isValidDate = moment.duration(valDate.diff(pbImpDate)).asDays() >= 31;

  return isValidDate && isValidPbStatusForFRI({ pbStatus });
};

export const getPbLabel = (pb = {}) => {
  const displayPb = `${pb?.pbNumber} [ ${textOrUnknown(pb?.pbLineId)} ]`;

  return pb.pbStatus === PB_STATUS.NOT_EXISTED ? displayPb.concat(" (Cancelled)") : displayPb;
};
