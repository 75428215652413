import api from "./api";

class authApi {
  static login(loginData) {
    return api.login("/users/login", loginData);
  }

  static me() {
    return api.get("/users/me");
  }

  static sendResetPasswordEmail(data) {
    return api.post("/users/password_reset_request", data);
  }

  static resetPasswordWithToken(data) {
    return api.post("/users/password_reset", data);
  }
}

export default authApi;
