import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const styles = () => ({
  tabItem: {
    position: "relative",
    height: 79,
    minWidth: 230,
    width: 230,
    borderBottom: "1px solid #cccccc",
    cursor: "pointer",
  },
  selectedTabItem: {
    position: "relative",
    height: 79,
    minWidth: 230,
    width: 230,
    borderBottom: "1px solid #cccccc",
    borderRight: "4px solid #005EFF",
    backgroundColor: "#E5EFFF",
  },
  title: {
    fontWeight: 500,
    fontSize: 12,
    paddingTop: 10,
    paddingLeft: 10,
  },
  contentContainer: {
    maxWidth: 120,
    paddingLeft: 10,
  },
  info: {
    color: "#777777",
    paddingTop: 2,
    fontSize: 10,
  },
  message: {
    color: "#777777",
    paddingTop: 2,
    paddingBottom: 5,
    fontSize: 10,
  },
  numberContainer: {
    position: "absolute",
    top: 25,
    right: 23,
    height: 30,
    width: 30,
    backgroundColor: "#EEEEEE",
    borderRadius: 5,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
  },
  selectedNumberContainer: {
    position: "absolute",
    top: 25,
    right: 19,
    height: 30,
    width: 30,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
  },
  number: {
    paddingTop: 7,
    fontSize: 12,
    fontWeight: 500,
  },
});

class SmallTabPanelContent extends PureComponent {
  render() {
    const { classes, panelItems = [], selectedPanelItem = {}, handlePanelItemChange } = this.props;

    return (
      <Box>
        {panelItems.map((item) => (
          <Box
            key={item.itemName}
            className={
              item.itemName === selectedPanelItem.item ? classes.selectedTabItem : classes.tabItem
            }
            onClick={() => handlePanelItemChange(item.itemName)}
          >
            <Box className={classes.title}>{item.title}</Box>
            <Box className={classes.contentContainer}>
              {item.info && <Box className={classes.info}>{item.info}</Box>}
              <Box className={classes.message}>{item.message}</Box>
            </Box>
            <Box
              className={
                item.itemName === selectedPanelItem.item
                  ? classes.selectedNumberContainer
                  : classes.numberContainer
              }
            >
              <Box className={classes.number}>{item.number}</Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
}

export default withStyles(styles)(SmallTabPanelContent);
