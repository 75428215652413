import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import ReactTable from "components/table/ReactTable";
import classTable from "../style.module.scss";

import Status from "./Filter/Status";
import { CATEGORY_FILTER, STATUS, STATUS_ALL } from "../constants";

import SearchFilter from "./Filter/SearchFilter";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    background: "#E8F1FF",
  },
  topShadow: {
    boxShadow: "inset 0px 2px 4px rgba(35, 43, 58, 0.25)",
    height: 1,
  },
  status: {
    textTransform: "capitalize",
  },
  showDetail: {
    color: "#003DA5",
  },
  noStatus: {
    textTransform: "capitalize",
    color: "#D42600",
  },
  blurDetail: {
    color: "#777777",
  },
  yesStatus: {
    textTransform: "capitalize",
    color: "#1BB135",
  },
  boldCell: {
    fontWeight: 500,
    color: "#000000",
    fontSize: 12,
  },
  tab: {
    minWidth: "110px",
  },
  iconSeven: {
    width: 25,
    height: 17,
    borderRadius: 2,
    background: "#668BC9",
    fontSize: 10,
    fontWeight: "bold",
    color: "#F4F6FA",
    textAlign: "center",
    lineHeight: "17px",
    marginRight: 7,
    border: "1px solid #003DA5",
  },
}));

const POChildSeries = ({ listPODaily = [] }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const filterOnly = { canSort: false, canFilter: true };
  const noSortFilter = { canSort: false, canFilter: false };

  // ============= GET FILTER OPTION LIST ====================
  const [listPODailyDone, setListPODailyDone] = useState(listPODaily);

  useEffect(() => {
    setListPODailyDone(listPODaily);
    getWbaQAList();
    getEverydayProgram();
    getALlFilterList();
  }, [listPODaily]);

  const getWbaQAList = () => {
    let listWbaQA = listPODaily.map((item) => item.wbaResponsibleQA);
    setWBAList(
      listWbaQA
        .filter((item, index) => {
          return listWbaQA.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item ? item : "NA", key: item ? item : "NA" };
        }),
    );
  };

  const getEverydayProgram = () => {
    let listProgramName = listPODaily.map((item) => item.programName);
    setProgramList(
      listProgramName
        .filter((item, index) => {
          return listProgramName.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );
  };

  const getALlFilterList = () => {
    let listSupplier = listPODaily.map((item) => item.supplierName);
    setSupplierList(
      listSupplier
        .filter((item, index) => {
          return listSupplier.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );

    let listFactory = listPODaily.map((item) => item.factoryName);
    setFactoryList(
      listFactory
        .filter((item, index) => {
          return listFactory.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );
  };

  const [wbaList, setWBAList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);

  // ============= END GET FILTER OPTION LIST ====================

  // ======== Handle Tab Change Program Category ========
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const ProCategoryFilter = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Paper className={classes.wrapperTab}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabHeader}
            >
              <Tab label="Program" className={classes.tab} />
              <Tab label="Category" className={classes.tab} />
            </Tabs>
          </Paper>
        </Box>

        <Box display="block">
          <TabPanel value={tab} index={0}>
            <SearchFilter
              cbSetFilter={cbSetFilterProgram}
              list={programList}
              selectedValues={selectedProgramOption}
            />
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <SearchFilter
              cbSetFilter={cbSetFilterCategory}
              list={CATEGORY_FILTER}
              selectedValues={selectedCategoryOption}
            />
          </TabPanel>
        </Box>
      </Box>
    );
  };
  // ======== Emd Handle Tab Change Program Category ========

  const [selectedWBAOption, setSelectedWBAOption] = useState([]);
  const cbSetFilterWBA = (data) => {
    setSelectedWBAOption(data);
  };

  const [selectedSupplierOption, setSelectedSupplierOption] = useState([]);
  const cbSetFilterSupplier = (data) => {
    setSelectedSupplierOption(data);
  };

  const [selectedFactoryOption, setSelectedFactoryOption] = useState([]);
  const cbSetFilterFactory = (data) => {
    setSelectedFactoryOption(data);
  };

  const [selectedProgramOption, setSelectedProgramOption] = useState([]);
  const cbSetFilterProgram = (data) => {
    setSelectedProgramOption(data);
  };

  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const cbSetFilterCategory = (data) => {
    setSelectedCategoryOption(data);
  };

  const [listPAC, setListPAC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterPAC = (data) => {
    setListPAC(data);
  };

  const [listGCC, setListGCC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterGCC = (data) => {
    setListGCC(data);
  };

  const [listQAA, setListQAA] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterQAA = (data) => {
    setListQAA(data);
  };

  const [listFRI, setListFRI] = useState(
    STATUS.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterFRI = (data) => {
    setListFRI(data);
  };

  const checkStatus = (status) => {
    if (status && status.toLowerCase() === "no") return classes.noStatus;
    if (status && status.toLowerCase() === "yes") return classes.yesStatus;
    else return classes.status;
  };

  const tableColumn = [
    {
      id: "poNumber",
      value: "poNumber",
      label: "PO Number ",
      width: 150,
      ...sortOnly,
      render: ({ cell: { value: poNumber } }) => {
        return <Box className={classes.boldCell}>{poNumber}</Box>;
      },
    },
    {
      id: "relatedPBNumber",
      value: "pbNumber",
      label: "Related PB Number [Production batch line ID ]",
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: pbNumber } }) => {
        return (
          <Box className={classes.normalCell}>
            {!pbNumber
              ? "N/A"
              : pbNumber.map((item) => (
                  <div>
                    {item.pbNumber} [{item.pbLineId}]
                  </div>
                ))}
          </Box>
        );
      },
    },
    {
      id: "wicNumber",
      value: ({ wicNumber, productName, isProgram777 }) => {
        return { wicNumber, productName, isProgram777 };
      },
      label: "WIC No. / Description ",
      width: 190,
      ...noSortFilter,
      render: ({
        cell: {
          value: { wicNumber, productName, isProgram777 },
        },
      }) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display={isProgram777 ? "block" : "none"} className={classes.iconSeven}>
              777
            </Box>
            <div>
              <div>{wicNumber}</div>
              <div className={classes.blurDetail}>{productName}</div>
            </div>
          </Box>
        );
      },
    },
    {
      id: "programName",
      value: ({ programName, category }) => {
        return { programName, category };
      },
      label: "Program / Category",
      width: 150,
      ...filterOnly,
      render: ({
        cell: {
          value: { programName, category },
        },
      }) => {
        return (
          <Box>
            <div>{programName}</div>
            <div className={classes.showDetail}>{category}</div>
          </Box>
        );
      },
      renderFilter: () => {
        return <ProCategoryFilter />;
      },
      selectedCheckBox: selectedProgramOption.length > 0 || selectedCategoryOption.length > 0,
    },
    {
      id: "supplierName",
      value: "supplierName",
      label: "Supplier Name",
      width: 199,
      ...filterOnly,
      render: ({ cell: { value: supplierName } }) => {
        return <Box className={classes.normalCell}>{supplierName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterSupplier}
            list={supplierList}
            selectedValues={selectedSupplierOption}
          />
        );
      },
      selectedCheckBox: selectedSupplierOption.length > 0,
    },
    {
      id: "factoryName",
      value: "factoryName",
      label: "Factory Name",
      ...filterOnly,
      render: ({ cell: { value: factoryName } }) => {
        return <Box className={classes.normalCell}>{factoryName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterFactory}
            list={factoryList}
            selectedValues={selectedFactoryOption}
          />
        );
      },
      selectedCheckBox: selectedFactoryOption.length > 0,
    },
    {
      id: "poQuantity",
      value: "poQuantity",
      label: "PO Quantity",
      ...sortOnly,
      render: ({ cell: { value: poQuantity } }) => {
        return (
          <Box className={classes.normalCell}>
            {(Math.round((parseFloat(poQuantity) + Number.EPSILON) * 100) / 100).toLocaleString()}
          </Box>
        );
      },
    },
    {
      id: "shipByDate",
      value: "shipByDate",
      label: "Ship By Date",
      ...sortOnly,
      render: ({ cell: { value: shipByDate } }) => {
        return <Box className={classes.normalCell}>{shipByDate}</Box>;
      },
    },
    {
      id: "pacStatus",
      value: "pacStatus",
      label: "PAC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: pacStatus } }) => {
        return <Box className={checkStatus(pacStatus)}>{!pacStatus ? "NA" : pacStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterPAC} list={listPAC} />;
      },
      selectedCheckBox: listPAC.filter((child) => child.checked).length > 0,
    },
    {
      id: "gccStatus",
      value: "gccStatus",
      label: "GCC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: gccStatus } }) => {
        return <Box className={checkStatus(gccStatus)}>{!gccStatus ? "NA" : gccStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterGCC} list={listGCC} />;
      },
      selectedCheckBox: listGCC.filter((child) => child.checked).length > 0,
    },
    {
      id: "qaaStatus",
      value: "qaaStatus",
      label: "QAA Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: qaaStatus } }) => {
        return <Box className={checkStatus(qaaStatus)}>{!qaaStatus ? "NA" : qaaStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterQAA} list={listQAA} />;
      },
      selectedCheckBox: listQAA.filter((child) => child.checked).length > 0,
    },
    {
      id: "friStatus",
      value: "friStatus",
      label: "FRI Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: friStatus } }) => {
        return <Box className={checkStatus(friStatus)}>{!friStatus ? "NA" : friStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterFRI} list={listFRI} />;
      },
      selectedCheckBox: listFRI.filter((child) => child.checked).length > 0,
    },
    {
      id: "wbaResponsibleQA",
      value: "wbaResponsibleQA",
      label: `${SIMPLE_BUYER_NAME} Responsible QA`,
      ...filterOnly,
      render: ({ cell: { value: wbaResponsibleQA } }) => {
        return (
          <Box className={classes.normalCell}>{!wbaResponsibleQA ? "NA" : wbaResponsibleQA}</Box>
        );
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterWBA}
            list={wbaList}
            selectedValues={selectedWBAOption}
          />
        );
      },
      selectedCheckBox: selectedWBAOption.length > 0,
    },
  ];

  // ============= HANDLE SEARCH AND FILTER LIST ====================

  useEffect(() => {
    handleFilteredList();
  }, [
    selectedWBAOption,
    selectedSupplierOption,
    selectedFactoryOption,
    selectedProgramOption,
    selectedCategoryOption,
    listPAC,
    listGCC,
    listQAA,
    listFRI,
  ]);

  const handleFilteredList = () => {
    let result = listPODaily
      .filter((item) => {
        return selectedWBAOption.length === 0
          ? item
          : selectedWBAOption
              .map((child) => child.value)
              .indexOf(item.wbaResponsibleQA ? item.wbaResponsibleQA : "NA") > -1;
      })
      .filter((item) => {
        return selectedProgramOption.length === 0
          ? item
          : selectedProgramOption.map((child) => child.value).indexOf(item.programName) > -1;
      })
      .filter((item) => {
        return selectedCategoryOption.length === 0
          ? item
          : selectedCategoryOption.map((child) => child.value).indexOf(item.category) > -1;
      })
      .filter((item) => {
        return selectedSupplierOption.length === 0
          ? item
          : selectedSupplierOption.map((child) => child.value).indexOf(item.supplierName) > -1;
      })
      .filter((item) => {
        return selectedFactoryOption.length === 0
          ? item
          : selectedFactoryOption.map((child) => child.value).indexOf(item.factoryName) > -1;
      })
      .filter((item) => {
        return listPAC.filter((child) => !child.checked).length === 3
          ? item
          : listPAC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.pacStatus ? "na" : item.pacStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listGCC.filter((child) => !child.checked).length === 3
          ? item
          : listGCC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.gccStatus ? "na" : item.gccStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listQAA.filter((child) => !child.checked).length === 3
          ? item
          : listQAA.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.qaaStatus ? "na" : item.qaaStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listFRI.filter((child) => !child.checked).length === 2
          ? item
          : listFRI.filter(
              (child) =>
                child.label.toLowerCase() === item.friStatus.toLowerCase() && child.checked,
            ).length > 0;
      });

    setListPODailyDone(result);
  };

  // ============= END GET HANDLE SEARCH AND FILTER LIST ====================

  return (
    <Box className={classes.wrapperBasic}>
      <Box className={classes.topShadow}></Box>
      <ReactTable
        columns={tableColumn}
        data={listPODailyDone}
        sizeOfPage={50}
        noPagingBottom={true}
        hasEmtyColumn={true}
        rowsPerPageOptions={[50, 100, 200, { label: "All", value: listPODailyDone.length }]}
        classes={{
          root: classTable.childPOSeriesTable,
          viewColumnIcon: classTable.viewColumnIcon,
        }}
      />
    </Box>
  );
};

export default POChildSeries;
