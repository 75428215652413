import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import TextInput from "components/input/TextInput";
import UploadWithPreview from "components/input/UploadWithPreview";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  uploadInfo: {
    paddingLeft: 3,
    fontSize: 10,
    color: "#777777",
  },
  uploadSection: {
    padding: "20px 10px 10px 10px",
  },
}));

const MasterWICDetails = ({
  data: { itemData = {} },
  func: { onItemDataChange, handleNextPage },
}) => {
  const classes = useStyles();

  const handleMasterWICChange = (e) => {
    const { name, value } = e.target;

    onItemDataChange({
      target: {
        name: "masterWic",
        value: {
          ...itemData.masterWic,
          [name]: value,
        },
      },
    });
  };

  const handleAddImages = (newImages) => {
    const images = itemData.masterWicImages.concat(newImages);

    onItemDataChange({
      target: {
        name: "masterWicImages",
        value: images,
      },
    });
  };

  const handleRemoveImage = (index) => {
    const images = itemData.masterWicImages.filter((item, i) => i !== index);

    onItemDataChange({
      target: {
        name: "masterWicImages",
        value: images,
      },
    });
  };

  const disabledSubmitCondition = () => {
    return !itemData.masterWic.wicNumber || !itemData.masterWic.productName;
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>1. Master WIC Number</Box>
        <Box className={classes.subtitle}>WIC Number</Box>
        <TextInput
          placeholder="WIC Number"
          name="wicNumber"
          value={itemData.masterWic.wicNumber}
          additionalClass={classes.inputField}
          onChange={handleMasterWICChange}
          cy={"ci-wic-no"}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          2. Master WIC Name {"&"} Description
        </Box>
        <Box className={classes.subtitle}>Name {"&"} Description</Box>
        <TextInput
          placeholder="Name & Description"
          name="productName"
          value={itemData.masterWic.productName}
          additionalClass={classes.inputField}
          onChange={handleMasterWICChange}
          cy={"ci-wic-name-desc"}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          3. Upload Master WIC Images
          <span className={classes.uploadInfo}>(File size must be above 30 KB)</span>
        </Box>

        <Box className={classes.uploadSection} data-cy={"ci-wic-upload-section"}>
          <UploadWithPreview
            images={itemData.masterWicImages}
            handleAddImages={handleAddImages}
            handleRemoveImage={handleRemoveImage}
            cy={"ci-wic-upload"}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={disabledSubmitCondition()}
        data-cy={"ci-continue"}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default MasterWICDetails;
