import React, { useEffect, useState } from "react";

import EditTestingTask from "./EditTestingTask";
import EditInspectionTask from "./EditInspectionTask";
import EditSamplePickUp from "./EditSamplePickUp";

import { atnGetSubmissionDetails, atnGetSubmissionTasks } from "actions/submissionActions";

const EditSubmission = ({ match, history }) => {
  const submissionId = match.params.submissionId;

  const [basicData, setBasicData] = useState({
    taskType: "",
    taskStatus: "",
    buyerProgramId: "",
    submissionTasksLength: 0,
    labIds: [],
  });
  const [submissionType, setSubmissionType] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [submissionTasks, setSubmissionTasks] = useState([]);
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [inspectionTaskDetail, setInspectionTaskDetail] = useState({});

  useEffect(() => {
    if (submissionId) {
      atnGetSubmissionDetails({ data: { id: submissionId }, type: 2 }).then(({ data, message }) => {
        if (data) {
          atnGetSubmissionTasks({
            submissionId: submissionId,
            taskType: data.taskType,
          }).then(({ data: taskData, message: taskMessage }) => {
            if (taskData != null) {
              setSubmissionTasks(taskData.tasks);
              setRelatedTasks(taskData.relatedTasks);
              setInspectionTaskDetail(taskData.inspectionTaskDetail);

              let billingAddress = taskData.billingAddress;
              let attachments = [];

              if (taskData.billingAddress.taxInformationFiles) {
                for (let i = 0; i < taskData.billingAddress.taxInformationFiles.length; i++) {
                  attachments.push({
                    id: taskData.billingAddress.taxInformationFiles[i].id,
                    name: taskData.billingAddress.taxInformationFiles[i].fileName,
                  });
                }
              }

              billingAddress.attachments = attachments;
              setBillingAddress(billingAddress);

              let labIds = [];

              for (let i = 0; i < taskData.labIds.length; i++) {
                labIds.push(taskData.labIds[i].id);
              }

              let taskTypes = [];

              for (let i = 0; i < taskData.tasks.length; i++) {
                if (taskTypes.indexOf(taskData.tasks[i].taskType) === -1) {
                  taskTypes.push(taskData.tasks[i].taskType);
                }
              }

              setBasicData({
                taskType: taskTypes.toString(),
                taskStatus: taskData.tasks[0].taskStatus,
                buyerProgramId: data.buyerProgramId,
                submissionTasksLength: taskData.tasks.length,
                labIds: labIds,
              });

              const samplePickUpTaskType = ["SPU", "SPUD"];
              const inspectionTaskType = ["SS", "DPI", "FRI", "FRID"];

              if (samplePickUpTaskType.indexOf(data.taskType) !== -1) {
                setSubmissionType("samplePickUp");
              } else if (inspectionTaskType.indexOf(data.taskType) !== -1) {
                setAssignTo(taskData.tasks[0].assignTo);
                setSubmissionType("inspection");
              } else {
                setSubmissionType("testing");
              }
            }
          });
        }
      });
    }
  }, [submissionId]);

  return (
    <>
      {submissionType === "samplePickUp" && (
        <EditSamplePickUp
          submissionId={submissionId}
          history={history}
          basicData={basicData}
          submissionTasks={submissionTasks}
          relatedTasks={relatedTasks}
          billingAddress={billingAddress}
          inspectionTaskDetail={inspectionTaskDetail}
        />
      )}

      {submissionType === "inspection" && (
        <EditInspectionTask
          submissionId={submissionId}
          history={history}
          assignTo={assignTo}
          basicData={basicData}
          submissionTasks={submissionTasks}
          relatedTasks={relatedTasks}
          billingAddress={billingAddress}
          inspectionTaskDetail={inspectionTaskDetail}
        />
      )}

      {submissionType === "testing" && (
        <EditTestingTask
          submissionId={submissionId}
          history={history}
          basicData={basicData}
          submissionTasks={submissionTasks}
          relatedTasks={relatedTasks}
          billingAddress={billingAddress}
        />
      )}
    </>
  );
};

export default EditSubmission;
