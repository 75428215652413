import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "248px",
    padding: "10px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginRight: "30px",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
  },

  title: {
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "30px",
  },

  fullPieChart: {
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    background: "#003DA5",
    margin: "0 auto",
    marginTop: "12px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  orange: {
    background: "#F57C00",
  },
  red: {
    background: "#D32F2F",
  },
  purple: {
    background: "#7B1FA2",
  },
  blue: {
    background: "#196EFF",
  },
  cyan: {
    background: "#0097A7",
  },
  yellow: {
    background: "#FBC02D",
  },
  green: {
    background: "#B0D400",
  },
  dark: {
    background: "#002563",
  },

  totalNumber: {
    fontSize: "24px",
    color: "#FFFFFF",
    textAlign: "center",
  },

  totalNumberSmall: {
    fontSize: "12px",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
  },

  subText: {
    fontSize: "12px",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
  },

  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "12px",
    fontWeight: "500",
    cursor: "pointer",
  },
}));

const TotalChart = ({ title, totalNumber, subTitle, textView, fontSize, bg, handleTabChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.title}>{title}</Box>

      <Box className={classnames(classes.fullPieChart, classes[bg])} display="inline-block">
        <Box
          component="span"
          className={fontSize === "small" ? classes.totalNumberSmall : classes.totalNumber}
        >
          {totalNumber}
        </Box>
        <Box component="span" className={classes.subText}>
          {subTitle}
        </Box>
      </Box>

      <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 1)}>
        {textView}
      </Box>
    </Box>
  );
};

export default TotalChart;
