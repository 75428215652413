import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Box, Grid } from "@material-ui/core";

import { defaultBillingAddress } from "pages/supplier/Workflow/components/PayerDetail";

import FileUpload from "components/input/FileUpload";

import { getInvoiceType } from "services/invoiceService";

import { INVOICE_TYPE_OPTIONS, NA } from "shared/constants";
import { textOrUnknown } from "utils/string";

function PayerInfo({ title = "Payer Details", payerInfo = defaultBillingAddress }) {
  const { isPlainVatInvoice, isSpecialVatInvoice } = getInvoiceType(payerInfo?.invoiceType);

  const getTextValue = (value) => {
    return textOrUnknown(value, NA);
  };

  const payerInvoiceType =
    INVOICE_TYPE_OPTIONS.filter((type) => type?.value === payerInfo?.invoiceType)[0]?.label || NA;

  return (
    <React.Fragment>
      <Grid container spacing={3} data-cy="payer-info">
        <Grid item xs={12}>
          <PayerInfoHeading>{title}</PayerInfoHeading>
        </Grid>

        {/* //* data for all invoice type */}
        <Grid item xs={4} sm={5}>
          <InfoLabel>Invoice Type</InfoLabel>

          <InfoText data-cy="payerInvoiceType">{payerInvoiceType}</InfoText>
        </Grid>

        <Grid item xs={4} sm={5}>
          <InfoLabel>Currency</InfoLabel>

          <InfoText data-cy="currency">{getTextValue(payerInfo?.currency)}</InfoText>
        </Grid>

        <Grid item xs={4} sm={5}>
          <InfoLabel>Payer Company Name</InfoLabel>

          <InfoText data-cy="payerCompanyName">
            {getTextValue(payerInfo?.payerCompanyName)}
          </InfoText>
        </Grid>

        <Grid item xs={4} sm={5}>
          <InfoLabel>Payer Address</InfoLabel>

          <InfoText data-cy="payerCompanyAddress">
            {getTextValue(payerInfo?.payerCompanyAddress)}
          </InfoText>
        </Grid>

        <Grid item xs={12}>
          <InfoLabel>Payer Contact Person Name</InfoLabel>

          <InfoText data-cy="payerContactPerson">
            {getTextValue(payerInfo?.payerContactPerson)}
          </InfoText>
        </Grid>

        <Grid item xs={4} sm={5}>
          <InfoLabel>Contact Person Email</InfoLabel>

          <InfoText data-cy="payerEmail">{getTextValue(payerInfo?.payerEmail)}</InfoText>
        </Grid>

        <Grid item xs={4} sm={5}>
          <InfoLabel>Contact Person Phone Number</InfoLabel>

          <InfoText data-cy="payerPhoneNumber">
            {getTextValue(payerInfo?.payerPhoneNumber)}
          </InfoText>
        </Grid>

        {/* //* data for plain & special vat type */}
        {(isPlainVatInvoice || isSpecialVatInvoice) && (
          <Grid item xs={12}>
            <InfoLabel>China BR copy and other attachments</InfoLabel>

            <InfoText
              data-cy="taxInformationFiles"
              data-cy-count={payerInfo?.taxInformationFiles?.length}
            >
              {payerInfo?.taxInformationFiles?.length > 0 ? (
                <FileUpload data={payerInfo?.taxInformationFiles} editable={false} multiple />
              ) : (
                NA
              )}
            </InfoText>
          </Grid>
        )}

        {/* //* data for special vat type */}
        {isSpecialVatInvoice && (
          <React.Fragment>
            <Grid item xs={4} sm={5}>
              <InfoLabel>Tax Registration Code</InfoLabel>

              <InfoText data-cy="taxRegistrationCode">
                {getTextValue(payerInfo?.taxRegistrationCode)}
              </InfoText>
            </Grid>

            <Grid item xs={4} sm={5}>
              <InfoLabel>Enterprise Name</InfoLabel>

              <InfoText data-cy="enterpriseName">
                {getTextValue(payerInfo?.enterpriseName)}
              </InfoText>
            </Grid>

            <Grid item xs={12}>
              <InfoLabel>Address (shown on invoice)</InfoLabel>

              <InfoText data-cy="address">{getTextValue(payerInfo?.address)}</InfoText>
            </Grid>

            <Grid item xs={4} sm={5}>
              <InfoLabel>Bank Name</InfoLabel>

              <InfoText data-cy="bankName">{getTextValue(payerInfo?.bankName)}</InfoText>
            </Grid>

            <Grid item xs={4} sm={5}>
              <InfoLabel>Contact No. (shown on invoice)</InfoLabel>

              <InfoText data-cy="contactNumber">{getTextValue(payerInfo?.contactNumber)}</InfoText>
            </Grid>

            <Grid item xs={12}>
              <InfoLabel>Bank Address (shown on invoice)</InfoLabel>

              <InfoText data-cy="bankAddress">{getTextValue(payerInfo?.bankAddress)}</InfoText>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default memo(PayerInfo);

const PayerInfoHeading = withStyles({
  root: {
    fontWeight: 500,
    marginBottom: "8px",
  },
})((props) => <Box {...props} />);

const InfoText = withStyles({
  root: {
    fontSize: "12px",
  },
})((props) => <Box {...props} />);

const InfoLabel = withStyles({
  root: {
    color: "#777777",
    marginBottom: "8px",
  },
})((props) => <InfoText {...props} />);
