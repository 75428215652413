import React, { useEffect, useState } from "react";
import OpenTestingTask from "./OpenTestingTask";
import OpenInspectionTask from "./OpenInspectionTask";
import OpenSamplePickUp from "./OpenSamplePickUp";
import OpenRetestTask from "./OpenRetestTask";

import { atnGetOpenTaskData } from "actions/taskActions";

const OpenTask = ({ match, history }) => {
  const taskId = match.params.taskId;

  const [type, setType] = useState("");
  const [taskDetails, setTaskDetails] = useState({});
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [assignTo, setAssignTo] = useState("");

  // check task type
  // if invlaid redirect to project details

  useEffect(() => {
    if (taskId) {
      atnGetOpenTaskData({
        id: taskId,
      }).then(({ data, message }) => {
        if (data) {
          setTaskDetails(data.task);
          setRelatedTasks(data.relatedTasks);

          if (data.task.taskType === "SPU" || data.task.taskType === "SPUD") {
            setType("samplePickUp");
          } else if (["SS", "DPI", "FRI", "FRID"].includes(data.task.taskType)) {
            setAssignTo(data.task.assignTo);
            setType("inspection");
          } else if (["ST", "STD", "PAC"].includes(data.task.taskType)) {
            if (data.task.isRetest) {
              setType("retest");
            } else {
              setType("testing");
            }
          } else if (["PST", "QT"].includes(data.task.taskType)) {
            setType("testing");
          }
        }
      });
    }
  }, [taskId]);

  return (
    <>
      {type === "testing" && (
        <OpenTestingTask history={history} taskDetails={taskDetails} relatedTasks={relatedTasks} />
      )}

      {type === "inspection" && (
        <OpenInspectionTask
          history={history}
          assignTo={assignTo}
          taskDetails={taskDetails}
          relatedTasks={relatedTasks}
        />
      )}

      {type === "samplePickUp" && (
        <OpenSamplePickUp history={history} taskDetails={taskDetails} relatedTasks={relatedTasks} />
      )}

      {type === "retest" && <OpenRetestTask history={history} taskDetails={taskDetails} />}
    </>
  );
};

export default OpenTask;
