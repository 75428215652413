import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  table: {
    marginTop: 15,
  },
  tableCell: {
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  labCell: {
    width: 200,
  },
  labLocationCell: {
    width: 1000,
    position: "relative",
  },
  labLocationsContainer: {
    width: 400,
    paddingTop: 10,
  },
  labLocation: {
    paddingBottom: 10,
  },
  labLocationName: {
    paddingTop: 5,
    cursor: "pointer",
  },
  disabledLabLocationName: {
    paddingTop: 5,
    color: "#CCCCCC",
  },
  viewContactDetails: {
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer",
    textDecoration: "underline",
    color: "#005EFF",
  },
  checkBoxContainer: {
    paddingRight: 8,
    cursor: "pointer",
  },
  disabledCheckBoxContainer: {
    paddingRight: 8,
    color: "#CCCCCC",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  disabledCheckBoxIcon: {
    color: "#CCCCCC",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  disabledCheckBoxOutlineBlankIcon: {
    color: "#CCCCCC",
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  radioGroup: {
    paddingTop: 10,
  },
}));

const SelectLab = ({
  editSubmissionData = {},
  labs = [],
  basicData = {},
  handleEditSubmissionDataChange,
  handleNextPage,
}) => {
  const classes = useStyles();

  const handleLabSelect = (labId) => {
    if (editSubmissionData.labIds.indexOf(labId) >= 0) {
      handleEditSubmissionDataChange({
        target: {
          name: "labIds",
          value: editSubmissionData.labIds.filter((item) => item !== labId),
        },
      });
    } else {
      handleEditSubmissionDataChange({
        target: {
          name: "labIds",
          value: editSubmissionData.labIds.concat(labId),
        },
      });
    }
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Currently Selected Task</Box>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classnames(classes.tableCell, classes.labCell)}>Lab</TableCell>
              <TableCell className={classnames(classes.tableCell, classes.labLocationCell)}>
                Lab Location
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labs.map((lab) => (
              <TableRow key={lab.labName}>
                <TableCell className={classnames(classes.tableCell, classes.labCell)}>
                  {lab.labName}
                </TableCell>
                <TableCell className={classnames(classes.tableCell, classes.labLocationCell)}>
                  <Grid container className={classes.labLocationsContainer}>
                    {lab.branches.map((branch, index) => (
                      <Grid item xs={6} key={branch.id} className={classes.labLocation}>
                        {basicData.taskStatus === "not_open_for_quote" ||
                        basicData.taskStatus === "waiting_for_lab_quote" ||
                        basicData.taskStatus === "quote_available" ? (
                          <>
                            {basicData.labIds.indexOf(branch.id) >= 0 ? (
                              <Box display="flex">
                                <Box className={classes.disabledCheckBoxContainer}>
                                  <CheckBoxIcon className={classes.disabledCheckBoxIcon} />
                                </Box>
                                <Box className={classes.disabledLabLocationName}>
                                  {branch.labBranchName}
                                </Box>
                              </Box>
                            ) : (
                              <Box display="flex" onClick={() => handleLabSelect(branch.id)}>
                                <Box className={classes.checkBoxContainer}>
                                  {editSubmissionData.labIds.indexOf(branch.id) >= 0 ? (
                                    <CheckBoxIcon className={classes.checkBoxIcon} />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      className={classes.checkBoxOutlineBlankIcon}
                                    />
                                  )}
                                </Box>
                                <Box className={classes.labLocationName}>
                                  {branch.labBranchName}
                                </Box>
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box display="flex">
                            <Box className={classes.disabledCheckBoxContainer}>
                              {editSubmissionData.labIds.indexOf(branch.id) >= 0 ? (
                                <CheckBoxIcon className={classes.disabledCheckBoxIcon} />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  className={classes.disabledCheckBoxOutlineBlankIcon}
                                />
                              )}
                            </Box>
                            <Box className={classes.disabledLabLocationName}>
                              {branch.labBranchName}
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  {/* <Box className={classes.viewContactDetails}>
                    View Contact Details
                  </Box> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={editSubmissionData.labIds.length === 0}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default SelectLab;
