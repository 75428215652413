import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import UploadCAP from "./UploadCAP";
import TrfForm from "./TrfForm/index";
import SelectLab from "../components/SelectLab";

import PayerDetail from "../../components/PayerDetail";

import { atnGetOpenTaskLabs, atnOpenTask } from "actions/taskActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const OpenRetestTask = ({ history, taskDetails = {} }) => {
  const classes = useStyles();
  const [step, setStep] = useState(taskDetails.taskType === "PAC" ? 1 : 0);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [openTaskData, setOpenTaskData] = useState({
    tasks: [taskDetails],
    capFile: {},
    // taskFiles: [{
    //   fileId: null,
    //   name: "",
    //   type: "cap",
    // }],
    labIds: [],
    isSkipQuote: true,
    billingAddress: {
      invoiceType: "",
      currency: "",
      payerCompanyName: "",
      payerCompanyAddress: "",
      payerContactPerson: "",
      payerEmail: "",
      payerPhoneNumber: "",
      attachments: [],
      taxInformationFiles: [],
      taxRegistrationCode: "",
      enterpriseName: "",
      address: "",
      bankName: "",
      contactNumber: "",
      bankAddress: "",
    },
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);

  const titleData = [
    "Upload CAP",
    "Select Lab for Quotation",
    "Fill in TRF Information",
    "Enter Payer Detail for Invoice",
  ];

  const handleOpenTaskDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setOpenTaskData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddCAPFile = (newFile) => {
    setOpenTaskData((prevState) => ({
      ...prevState,
      capFile: newFile,
    }));
  };

  const handleGetLabs = (getLabData) => {
    atnGetOpenTaskLabs(getLabData).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setOpenTaskData((prevState) => ({
        ...prevState,
        labIds: [data[0].id],
      }));
    });
  };

  useEffect(() => {
    handleGetLabs({
      taskId: taskDetails.id,
      taskType: taskDetails.taskType,
      buyerProgramId: taskDetails.buyerProgramId,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleSubmit = () => {
    setIsDisabledConfirmButton(true);

    let billingAddress = openTaskData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (openTaskData.billingAddress.invoiceType === "icw_company_invoice") {
      delete billingAddress.attachments;
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType !== "icw_company_invoice") {
      let taxInformationFiles = [];

      for (let i = 0; i < openTaskData.billingAddress.attachments.length; i++) {
        taxInformationFiles.push({
          id: openTaskData.billingAddress.attachments[i].id,
          type: "tax_information",
        });
      }

      billingAddress.taxInformationFiles = taxInformationFiles;
      delete billingAddress.attachments;
    }

    let selectedLabs = [];

    for (let i = 0; i < openTaskData.labIds.length; i++) {
      selectedLabs.push({
        id: openTaskData.labIds[i],
      });
    }

    let taskFiles = [];

    if (openTaskData.capFile && openTaskData.capFile.id) {
      taskFiles.push({
        type: "cap",
        fileId: openTaskData.capFile.id,
      });
    }

    let submitData = {
      isSkipQuote: openTaskData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: openTaskData.tasks,
      taskFiles: taskFiles,
    };

    atnOpenTask(submitData).then(({ data, message }) => {
      if (data) {
        setIsDisabledConfirmButton(false);
        history.push(`/supplier/submissions/${data.id}`);
      } else {
        alert(message);
        setIsDisabledConfirmButton(false);
      }
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header
        step={step}
        taskType={taskDetails.taskType}
        handlePrevPage={handlePrevPage}
        handleClose={handleClose}
      />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <UploadCAP
            openTaskData={openTaskData}
            handleAddCAPFile={handleAddCAPFile}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 1 && (
          <SelectLab
            isRetest={true}
            openTaskData={openTaskData}
            labs={labs}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 2 && (
          <TrfForm
            factoryId={taskDetails.factoryId}
            tasks={openTaskData.tasks}
            masterWicId={taskDetails.masterWicId}
            productName={taskDetails.productName}
            wicNumber={taskDetails.wicNumber}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 3 && (
          <PayerDetail
            isTestingTask
            isRetestTask
            isDisabledConfirmButton={isDisabledConfirmButton}
            data={openTaskData}
            handleDataChange={handleOpenTaskDataChange}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(null, {
  atnOpenTask,
  atnGetOpenTaskLabs,
})(OpenRetestTask);
