import React, { useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "@material-ui/core/Dialog";
import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";
import UploadFiles from "components/input/UploadFiles";

import SelectSearchPOTable from "components/table/SelectSearchPOTable";
import SelectedPOTable from "components/table/SelectedPOTable";
import SelectSearchPBTable from "components/table/SelectSearchPBTable";
import SelectedPBTable from "components/table/SelectedPBTable";
import Program777Chip from "components/display/Program777Chip";

import { atnGetPbEveryday, atnGetPoEveryday } from "actions/taskActions";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  grayText: {
    color: "#777",
  },
  small: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 300,
  },
  smallInputField: {
    width: 200,
  },
  largeInputField: {
    width: 450,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  smallFieldPadding: {
    paddingTop: 20,
  },
  blockPadding: {
    paddingRight: 10,
  },
  inputPadding: {
    paddingTop: 29,
  },
  WicContainer: {
    border: "1px solid #777777",
    padding: 20,
    position: "relative",
    marginBottom: 20,
  },
  WicTitleContainer: {
    position: "relative",
  },
  WicTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  itemName: {
    fontSize: 12,
    color: "#777777",
    marginTop: 4,

    "& span": {
      color: "#003DA5",
      textTransform: "capitalize",
    },
  },
  expandBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  tasksContainer: {
    position: "absolute",
    top: 32,
    right: 70,
  },
  task: {
    background: "#EBEDEF",
    borderRadius: 2,
    fontSize: 12,
    color: "#232B3A",
    padding: "3px 6px",
    marginRight: 10,
  },
  poDataContainer: {
    paddingBottom: 20,
  },
  deleteBtnContainer: {
    paddingTop: 10,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  btnEdit: {
    display: "block",
    marginTop: 10,
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  blockFull: {
    width: "100%",
    paddingRight: 10,
    marginTop: 10,

    "&:last-child": {
      paddingRight: 0,
    },
  },
}));

const WicDetails = ({
  task,
  masterWic = {},
  masterWicIndex,
  editSubmissionData = {
    masterWics: [],
    tasks: [],
  },
  handleEditSubmissionDataChange,
  handleDeletePo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isProgram777 = masterWic?.isProgram777;

  const [showProgram777, setShowProgram777] = useState(false);

  useEffect(() => {
    const displayProgram777 = isShowProgram777(masterWic?.triggeredBy);

    setShowProgram777(displayProgram777);

    displayProgram777 ? fetchPB() : fetchPO();
  }, [isProgram777, masterWic.triggeredBy]);

  const handleMasterWicExpand = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[index].expanded = !masterWics[index].expanded;

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddNewPo = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[index].po.push({
      firstShipmentTotalQuantity: "",
      fullPoTotalQuantity: "",
      poNumber: "",
      poQuantity: "",
      casePack: "",
    });

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemovePoData = (wicIndex, poIndex) => {
    let masterWics = editSubmissionData.masterWics;

    handleDeletePo({
      poId: masterWics[wicIndex].po[poIndex].id,
      type: masterWic.category || "seasonal",
    });

    masterWics[wicIndex].po.splice(poIndex, 1);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handlePoDataChange = (e, wicIndex, poIndex) => {
    const { name, value } = e.target;

    let masterWics = editSubmissionData.masterWics;

    masterWics[wicIndex].po[poIndex][name] = value;

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddProductSpecificationFiles = (newFiles) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].productSpecification =
      masterWics[masterWicIndex].productSpecification.concat(newFiles);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveProductSpecificationFile = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].productSpecification = masterWics[
      masterWicIndex
    ].productSpecification.filter((item, i) => i !== index);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddArtworksFiles = (newFiles) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].artworks = masterWics[masterWicIndex].artworks.concat(newFiles);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveArtworksFile = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].artworks = masterWics[masterWicIndex].artworks.filter(
      (item, i) => i !== index,
    );

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddAisUPCInformationFiles = (newFiles) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].aisUPCInformation =
      masterWics[masterWicIndex].aisUPCInformation.concat(newFiles);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveAisUPCInformationFile = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].aisUPCInformation = masterWics[
      masterWicIndex
    ].aisUPCInformation.filter((item, i) => i !== index);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddAisOuterInnerFiles = (newFiles) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].aisOuterInner =
      masterWics[masterWicIndex].aisOuterInner.concat(newFiles);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveAisOuterInnerFile = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].aisOuterInner = masterWics[masterWicIndex].aisOuterInner.filter(
      (item, i) => i !== index,
    );

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  // ==================== HANDLE SELECT PO ====================
  const buyerCompanyName = masterWic.buyerCompanyName;
  const category = masterWic.category;

  const [openSelectPODialog, setOpenSelectPODialog] = useState(false);
  const [listRelatedPO, setListRelatedPO] = useState([]);

  const fetchPO = () => {
    // let masterWics = editSubmissionData.masterWics;

    if (masterWic.po.length) {
      let tempPo = JSON.parse(JSON.stringify(masterWic.po));
      tempPo = tempPo.filter((item) => item.id && item.poNumber && item.poQuantity);

      setSelectedPOsShow(
        tempPo.map((item, index) => {
          return {
            id: item.id,
            // firstShipmentTotalQuantity: item.firstShipmentTotalQuantity,
            // fullPoTotalQuantity: item.fullPoTotalQuantity,
            poNumber: item.poNumber,
            poQuantity: item.poQuantity,
            shipByDate: item.shipByDate,
            casePack: item.casePack,
          };
        }),
      );
      // setShipTotalPO(tempPo[0].firstShipmentTotalQuantity);
      // setFullPOQty(tempPo[0].fullPoTotalQuantity);
    }

    if (masterWic.masterWicId) {
      atnGetPoEveryday({
        id: masterWic.masterWicId,
      }).then(({ data, message }) => {
        if (data) {
          if (masterWic.tasks[0].taskType === "FRID") {
            let tempPo = [];

            for (let i = 0; i < data.length; i++) {
              if ((data[i].friStatus || "").toLowerCase() === "no") {
                if (masterWic.assignTo === "lab") {
                  tempPo.push(data[i]);
                } else {
                  if (moment(masterWic.selfInspectionValidityDate) >= moment(data[i].shipByDate)) {
                    tempPo.push(data[i]);
                  }
                }
              }
            }

            setListRelatedPO(tempPo);
          } else {
            setListRelatedPO(
              data.map((item, index) => {
                return { ...item, id: item.id };
              }),
            );
          }
        }
      });
    }
  };

  const [filteredRelatedPOs, setFilteredRelatedPOs] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;
    setSearchKeyword(value);

    if (value) {
      let tempTasks = listRelatedPO.filter((item, i) => item.poNumber.indexOf(value) >= 0);
      setFilteredRelatedPOs(tempTasks);
    } else {
      setFilteredRelatedPOs(listRelatedPO);
    }
  };

  const [selectedPOsShow, setSelectedPOsShow] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [selectedPOIds, setSelectedPOIds] = useState([]);

  const handlePOSelect = (task) => {
    if (selectedPOIds.indexOf(task.id) >= 0) {
      setSelectedPOs((prevState) => {
        return prevState.filter((item) => item.id !== task.id);
      });

      setSelectedPOIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      setSelectedPOs((prevState) => {
        return prevState.concat(task);
      });

      setSelectedPOIds((prevState) => {
        return prevState.concat(task.id);
      });
    }
  };

  const handleOpenSelectPODialog = () => {
    setSelectedPOs(selectedPOsShow);

    const poIds = [];

    for (let i = 0; i < selectedPOsShow.length; i++) {
      poIds.push(selectedPOsShow[i].id);
    }

    setSelectedPOIds(poIds);
    setOpenSelectPODialog(true);
  };

  const handleCloseSelectPODialog = () => {
    setOpenSelectPODialog(false);
  };

  const handleConfirmPOSelect = () => {
    setOpenSelectPODialog(false);
    setSelectedPOsShow(selectedPOs);

    let masterWics = editSubmissionData.masterWics;

    let oldPo = JSON.parse(JSON.stringify(masterWics[masterWicIndex].po));
    let hasOldPo = false;
    let deletedPo = [];

    for (let i = 0; i < oldPo.length; i++) {
      hasOldPo = false;

      for (let j = 0; j < selectedPOs.length; j++) {
        if (selectedPOs[j].id === oldPo[i].id) {
          hasOldPo = true;
          break;
        }
      }

      if (!hasOldPo) {
        deletedPo.push({
          poId: oldPo[i].id,
          type: masterWic.category || "seasonal",
        });
      }
    }

    if (deletedPo.length > 0) {
      handleDeletePo(deletedPo);
    }

    masterWics[masterWicIndex].po = [];

    let isNewPo = true;

    for (let i = 0; i < selectedPOs.length; i++) {
      isNewPo = true;

      for (let j = 0; j < oldPo.length; j++) {
        if (oldPo[j].id === selectedPOs[i].id) {
          isNewPo = false;
          break;
        }
      }

      masterWics[masterWicIndex].po.push({
        id: selectedPOs[i].id,
        shipByDate: selectedPOs[i].shipByDate,
        poNumber: selectedPOs[i].poNumber,
        poQuantity: selectedPOs[i].poQuantity,
        casePack: selectedPOs[i].casePack || "",
        isNew: isNewPo,
      });
    }

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleConfirmEditPO = (e, index) => {
    const { name, value } = e.target;

    setSelectedPOsShow((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      });
    });

    let masterWics = editSubmissionData.masterWics;
    masterWics[masterWicIndex].po.map((item, idx) => {
      if (idx === index) item.casePack = value;
      return item;
    });
  };

  const handleRemovePO = (poId) => {
    let masterWics = editSubmissionData.masterWics;
    let oldPo = JSON.parse(JSON.stringify(masterWics[masterWicIndex].po));

    let newPOs = oldPo.filter((item) => item.id !== poId);

    setSelectedPOsShow(newPOs);

    let deletedPo = [];

    deletedPo.push({
      poId: poId,
      type: masterWic.category || "seasonal",
    });

    handleDeletePo(deletedPo);

    masterWics[masterWicIndex].po = newPOs;

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleMasterWicDataChange = (e) => {
    const { name, value } = e.target;

    let masterWics = editSubmissionData.masterWics;
    masterWics[masterWicIndex][name] = value;

    handleEditSubmissionDataChange({
      target: { name: "masterWics", value: masterWics },
    });
  };
  // ==================== END HANDLE SELECT PO ====================

  // ==================== START HANDLE SELECT PB ====================
  const [openSelectPBDialog, setOpenSelectPBDialog] = useState(false);
  const [selectedPBsShow, setSelectedPBsShow] = useState([]);
  const [selectedPBs, setSelectedPBs] = useState([]);
  const [selectedPBIds, setSelectedPBIds] = useState([]);
  const [filteredRelatedPBs, setFilteredRelatedPBs] = useState([]);
  const [listRelatedPB, setListRelatedPB] = useState([]);
  const [searchKeywordPB, setSearchKeywordPB] = useState("");

  const handleUploadE2openFiles = (newFiles) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open || [];

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open.concat(newFiles);

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const fetchPB = () => {
    masterWic.pb && setSelectedPBsShow(masterWic.pb);

    if (masterWic.masterWicId) {
      atnGetPbEveryday({
        id: masterWic.masterWicId,
      }).then(({ data, message }) => {
        if (data) {
          setListRelatedPB(
            data.map((item) => ({
              ...item,
              id: item.id,
            })),
          );
        }
      });
    }
  };

  const handleSearchKeywordChangePB = (e) => {
    const { value } = e.target;

    setSearchKeywordPB(value);

    if (value) {
      let tempTasks = listRelatedPB.filter(
        (item, i) => item.pbNumber?.toLowerCase().indexOf(value?.toLowerCase()) >= 0,
      );
      setFilteredRelatedPBs(tempTasks);
    } else {
      setFilteredRelatedPBs(listRelatedPB);
    }
  };

  const handleConfirmEditPB = (e, index) => {
    const { name, value } = e.target;

    setSelectedPBsShow((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      });
    });

    let masterWics = editSubmissionData.masterWics;
    masterWics[masterWicIndex].pb.map((item, idx) => {
      if (idx === index) item.casePack = value;
      return item;
    });

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemovePB = (pbid) => {
    let masterWics = editSubmissionData.masterWics;

    const newPBs = masterWics[masterWicIndex].pb.filter((item) => item.id !== pbid);

    setSelectedPBsShow(newPBs);

    masterWics[masterWicIndex].pb = newPBs;

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handlePBSelect = (task) => {
    if (selectedPBIds.indexOf(task.id) >= 0) {
      setSelectedPBs((state) => state.filter((item) => item.id !== task.id));
      setSelectedPBIds((state) => state.filter((item) => item !== task.id));
      return;
    }

    setSelectedPBs((state) => state.concat(task));
    setSelectedPBIds((state) => state.concat(task.id));
  };

  const handleConfirmPBSelect = () => {
    setOpenSelectPBDialog(false);
    setSelectedPBsShow(selectedPBs);
    let masterWics = editSubmissionData.masterWics;
    masterWics[masterWicIndex].pb = [];

    for (const selectedPb of selectedPBs) {
      masterWics[masterWicIndex].pb.push({
        id: selectedPb.id,
        pbNumber: selectedPb.pbNumber,
        pbQuantity: selectedPb.pbQuantity,
        pbLineId: selectedPb.pbLineId,
        pbStatus: selectedPb.pbStatus,
        casePack: selectedPb.casePack || "",
      });
    }

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleOpenSelectPBDialog = () => {
    const pbIds = [];

    setSelectedPBs(selectedPBsShow);

    for (let i = 0; i < selectedPBsShow.length; i++) {
      pbIds.push(selectedPBsShow[i].id);
    }

    setSelectedPBIds(pbIds);
    setOpenSelectPBDialog(true);
  };

  const handleCloseSelectPBDialog = () => {
    setOpenSelectPBDialog(false);
  };

  const handleRemoveE2openFiles = (index) => {
    let masterWics = editSubmissionData.masterWics;

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open.filter(
      (item, i) => i !== index,
    );

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  // ==================== END HANDLE SELECT PB ====================

  const isEveryday = category === "everyday";

  return (
    <Box className={classes.WicContainer}>
      <Box className={classes.WicTitleContainer}>
        <Box display="flex" className={classes.WicTitle}>
          {showProgram777 && <Program777Chip />}
          Wic Number: {masterWic.wicNumber}
        </Box>
        <Box className={classes.itemName}>Wic Description: {masterWic.productName}</Box>
        <Box className={classes.itemName}>
          Category: <span>{buyerCompanyName}</span>
        </Box>
      </Box>

      <Box display="flex" className={classes.tasksContainer}>
        {masterWic.tasks.map((task, taskIndex) => (
          <Box key={taskIndex} className={classes.task}>
            {t(`task.${task.taskType}`)}
          </Box>
        ))}
      </Box>

      <IconButton
        className={classes.expandBtn}
        onClick={() => handleMasterWicExpand(masterWicIndex)}
      >
        {masterWic.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      {masterWic.expanded && (
        <>
          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            1. Product Specification
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.productSpecification}
              handleAddFiles={handleAddProductSpecificationFiles}
              handleRemoveFile={handleRemoveProductSpecificationFile}
            />
          </Box>

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>2. Artworks</Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.artworks}
              handleAddFiles={handleAddArtworksFiles}
              handleRemoveFile={handleRemoveArtworksFile}
            />
          </Box>

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            3. Upload AIS/Stibo - UPC information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisUPCInformation}
              handleAddFiles={handleAddAisUPCInformationFiles}
              handleRemoveFile={handleRemoveAisUPCInformationFile}
            />
          </Box>

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            4. Upload AIS/Stibo - Outer/Inner
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisOuterInner}
              handleAddFiles={handleAddAisOuterInnerFiles}
              handleRemoveFile={handleRemoveAisOuterInnerFile}
            />
          </Box>

          {/* <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            5. Upload AIS/Stibo - Factory information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisFactoryInformation}
              handleAddFiles={handleAisFactoryinformationFiles}
              handleRemoveFile={handleRemoveAisFactoryinformationFile}
            />
          </Box> */}

          {showProgram777 ? (
            //pb
            <>
              <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
                <Box>
                  <Box>5. Upload E2Open - PB Detail</Box>

                  <Box className={classnames(classes.grayText, classes.small)}>
                    Please upload a file (screencap)
                  </Box>
                </Box>
              </Box>

              <Box className={classes.uploadContainer}>
                <UploadFiles
                  files={masterWic.e2open || []}
                  handleAddFiles={handleUploadE2openFiles}
                  handleRemoveFile={handleRemoveE2openFiles}
                />
              </Box>

              <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
                <Box>
                  <Box>6. PB Information</Box>
                  <Box className={classnames(classes.grayText, classes.small)}>
                    Please add at least one PB information
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            //po
            <>
              <Box className={classnames(classes.title, classes.smallFieldPadding)}>
                5. PO Information
              </Box>

              <Box display="flex">
                <Box className={classes.blockFull}>
                  <Box className={classes.subtitle}>1st Shipment Total PO Qty</Box>
                  <Box>
                    <TextInput
                      placeholder="1st Shipment Total PO Qty"
                      name="firstShipmentTotalQuantity"
                      value={masterWic.firstShipmentTotalQuantity || ""}
                      type="number"
                      additionalClass={classes.inputFieldFull}
                      onChange={handleMasterWicDataChange}
                    />
                  </Box>
                </Box>
                <Box className={classes.blockFull}>
                  <Box className={classes.subtitle}>Full PO Qty</Box>
                  <Box>
                    <TextInput
                      placeholder="PO Quantity"
                      name="fullPoTotalQuantity"
                      value={masterWic.fullPoTotalQuantity || ""}
                      type="number"
                      additionalClass={classes.inputFieldFull}
                      onChange={handleMasterWicDataChange}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {showProgram777 && isEveryday ? (
            <>
              <SelectedPBTable
                selected={selectedPBsShow}
                onRemove={handleRemovePB}
                onClickConfirm={handleConfirmEditPB}
              />

              <Dialog
                className={classes.dialog}
                open={openSelectPBDialog}
                onClose={handleCloseSelectPBDialog}
              >
                <SelectSearchPBTable
                  assignTo={task.assignTo}
                  selfInspectionValidityDate={task.selfInspectionValidityDate}
                  taskType={task.taskType}
                  options={searchKeywordPB ? filteredRelatedPBs : listRelatedPB}
                  selected={selectedPBIds}
                  searchKeyword={searchKeywordPB}
                  isDiasbledConfirm={!selectedPBIds}
                  onSelect={handlePBSelect}
                  onSearchKeywordChange={handleSearchKeywordChangePB}
                  onCloseSelectDialog={handleCloseSelectPBDialog}
                  onClickConfirm={handleConfirmPBSelect}
                />
              </Dialog>
            </>
          ) : isEveryday ? (
            <>
              <SelectedPOTable
                selectedPOs={selectedPOsShow}
                handleRemovePO={handleRemovePO}
                handleConfirmEditPO={handleConfirmEditPO}
              />

              <Dialog
                className={classes.dialog}
                onClose={handleCloseSelectPODialog}
                open={openSelectPODialog}
              >
                <SelectSearchPOTable
                  //  tasks={listRelatedPO}
                  tasks={searchKeyword ? filteredRelatedPOs : listRelatedPO}
                  selectedPOIds={selectedPOIds}
                  handlePOSelect={handlePOSelect}
                  searchKeyword={searchKeyword}
                  handleSearchKeywordChange={handleSearchKeywordChange}
                  handleCloseSelectPODialog={handleCloseSelectPODialog}
                  handleOpenSelectPODialog={handleOpenSelectPODialog}
                  handleConfirmPOSelect={handleConfirmPOSelect}
                />
              </Dialog>
            </>
          ) : (
            <>
              {(masterWic.po || []).map((data, poIndex) => (
                <Box key={poIndex} className={classes.poDataContainer}>
                  <Box display="flex">
                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>PO Number</Box>
                      <Box>
                        <TextInput
                          placeholder="PO Number"
                          name="poNumber"
                          value={data.poNumber || ""}
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>PO Quantity in Units</Box>
                      <Box>
                        <TextInput
                          type="number"
                          placeholder="PO Qty in units"
                          name="poQuantityInUnits"
                          value={data.poQuantityInUnits || ""}
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>Actual Quantity in Pieces</Box>
                      <Box>
                        <TextInput
                          type="number"
                          placeholder="Actual Qty in Pieces"
                          name="poQuantity"
                          value={data.poQuantity || ""}
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>Case Pack</Box>
                      <Box>
                        <TextInput
                          type="number"
                          placeholder="Qty Ready for Inspection"
                          name="casePack"
                          value={data.casePack || ""}
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    {poIndex !== 0 && (
                      <Box className={classes.deleteBtnContainer}>
                        <IconButton onClick={() => handleRemovePoData(masterWicIndex, poIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Box className={classes.addButtonContainer}>
            {showProgram777 && isEveryday ? (
              <OutlinedButton
                label="SELECT PB"
                handleClick={() => handleOpenSelectPBDialog()}
                color="primary"
                className={classes.btnEdit}
              />
            ) : isEveryday ? (
              <OutlinedButton
                label="SELECT PO"
                handleClick={() => handleOpenSelectPODialog()}
                color="primary"
                className={classes.btnEdit}
              />
            ) : (
              <OutlinedButton
                label="+ ADD A NEW PO"
                handleClick={() => handleAddNewPo(masterWicIndex)}
                color="primary"
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default WicDetails;
