import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchSection from "components/searchBar/SearchSection";
import TextInput from "components/input/TextInput";
import ItemListTab from "./ItemListTab";
import ItemListTable from "components/table/ItemListTable/index";
import { getFileSourceUrl } from "utils/files";
import { getUnselectedColumns } from "utils/tableHeader";
import { formatHeader, formatRow, updateHeader } from "utils/formatItemTableData";
import Select from "components/input/Select";

import GifLoading from "components/display/GifLoading";

import {
  atnBulkEditPlannedDate,
  atnCreateCustomHeader,
  atnDeleteCustomHeader,
  atnGetItemListData,
  atnGetItemListFilterData,
  atnGetItemListHeader,
  atnGetItemListNumberData,
  atnUpdateCustomHeader,
} from "actions/itemActions";
import { atnDownloadFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  topContainer: {
    height: 80,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #AAAAAA",
  },
  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  tableInfo: {
    fontWeight: "normal",
    fontSize: 10,
    paddingLeft: 10,
  },
  tableContainer: {
    paddingTop: 10,
    width: "fit-content",
  },
  manageColumnDialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 850,
        width: 850,
        minHeight: 500,
      },
    },
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
      padding: "4px 6px",
    },
  },
  dialogContent: {
    padding: "10px 30px",
    borderBottom: "none",
  },
  dialogInfo: {
    fontSize: 10,
  },
  dialogSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 30,
  },
  dialogSelectText: {
    fontSize: 12,
    fontWeight: "normal",
    paddingTop: 10,
    marginBottom: 5,
  },
  dialogSelect: {
    fontSize: 12,
    fontWeight: "normal",
    minWidth: 312,
  },
  dialogEditContainer: {
    borderTop: "1px solid #AAAAAA",
    marginTop: 110,
    minHeight: 450,
  },
  dialogEditSection: {
    width: 400,
  },
  dialogConfirmButton: {
    borderRadius: 2,
    height: 36,
  },
  datePicker: {
    paddingTop: 5,
    "& .MuiInputBase-root": {
      "& input": {
        padding: 11,
      },
    },
  },
  imageGalleryContainer: {
    margin: "100px 100px 0 100px",
  },
  manageColumnContainer: {
    paddingTop: 30,
  },
  manageColumnBoxTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
  },
  manageColumnBox: {
    width: 320,
    border: "1px solid #000000",
    borderRadius: 4,
  },
  addTaskTypeButton: {
    marginTop: 8,
  },
  manageColumnButton: {
    cursor: "pointer",
    fontSize: 12,
  },
  manageColumnMenu: {
    zIndex: 999,
  },
  manageColumnMenuList: {
    width: 280,
  },
  manageColumnMenuItemText: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  manageColumnMenuItem: {
    position: "relative",
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
  },
  manageColumnMenuItemWithBorder: {
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
  },
  manageColumnMenuItemEditButton: {
    position: "absolute",
    right: 30,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnMenuItemDeleteButton: {
    position: "absolute",
    right: 10,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnDialogButtonContainer: {
    paddingTop: 50,
  },
  currentHeaderLabel: {
    paddingRight: 3,
    color: "#A5CE2D",
  },
}));

const SupplierProjectList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingTable, setLoadingTable] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [unselectedItems, setUnselectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItemDetailsData, setSelectedItemDetailsData] = useState([]);
  const [headerFilter, setHeaderFilter] = useState({});
  const [headerData, setHeaderData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [fullItemList, setFullItemList] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [deleteHeaderData, setDeleteHeaderData] = useState({
    id: "",
    isCurrent: false,
    name: "",
    index: "",
  });

  const [openDeleteHeaderDialog, setOpenDeleteHeaderDialog] = useState(false);

  const [editHeaderData, setEditHeaderData] = useState({
    id: "",
  });

  const [openEditManageColumnDialog, setOpenEditManageColumnDialog] = useState(false);

  const [progressNumber, setProgressNumber] = useState({
    all: 0,
    completed: 0,
    notOpen: 0,
    onGoing: 0,
  });

  const [selectedFilter, setSelectedFilter] = useState({
    brand: [],
    programName: [],
    pacStatus: [],
    qtStatus: [],
    pstStatus: [],
    ssStatus: [],
    spuStatus: [],
    stStatus: [],
    dpiStatus: [],
    friStatus: [],
    labName: [],
    result: [],
    factory: [],
  });
  const [searchConditions, setSearchConditions] = useState({
    orderBy: "",
    order: "",
    search: "",
  });
  const [searchSectionData, setSearchSectionData] = useState({
    keyword: "",
  });

  const [commonTaskTypeList, setCommonTaskTypeList] = useState([]);
  const [bulkEditTaskType, setBulkEditTaskType] = useState("");
  const [newPlannedDate, setNewPlannedDate] = useState("");

  const allHeaderColumns = [
    "productName",
    "programName",
    "factory",
    "pacStatus",
    "pacValidityDate",
    "pacPlannedDate",
    "pacWbaDeadline",
    "pacEcd",
    "qtStatus",
    "qtPlannedDate",
    "qtWbaDeadline",
    "qtEcd",
    "pstStatus",
    "pstPlannedDate",
    "pstWbaDeadline",
    "pstEcd",
    "ssStatus",
    "ssPlannedDate",
    "ssWbaDeadline",
    "ssEcd",
    "spuStatus",
    "spuPlannedDate",
    "spuWbaDeadline",
    "spuEcd",
    "stStatus",
    "validityDate",
    "validityPeriod",
    "stPlannedDate",
    "stWbaDeadline",
    "stEcd",
    "dpiStatus",
    "dpiPlannedDate",
    "dpiWbaDeadline",
    "dpiEcd",
    "friStatus",
    "selfInspectionValidityDate",
    "friPlannedDate",
    "friWbaDeadline",
    "friEcd",
    "shipByDate",
  ];
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([
    "productName",
    "programName",
    "factory",
    "pacStatus",
    "qtStatus",
    "pstStatus",
    "ssStatus",
    "spuStatus",
    "stStatus",
    "dpiStatus",
    "friStatus",
    "shipByDate",
  ]);
  const [right, setRight] = useState(getUnselectedColumns(allHeaderColumns, left));
  const [manageColumnData, setManageColumnData] = useState({
    name: "",
    header: "",
  });
  const [galleryImages, setGalleryImages] = useState([]);

  const [tab, setTab] = useState(0);

  const [openImageGallery, setOpenImageGallery] = useState(false);

  const actionMenuItems = [
    {
      name: "edit",
      type: "edit",
      label: `Bulk Edit Planned Date`,
    },
    // {
    //   name: "download",
    //   type: "download",
    //   label: `Bulk Download`
    // },
  ];

  const [manageColumnMenuItems, setManageColumnMenuItems] = useState([]);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [openManageColumnMenu, setOpenManageColumnMenu] = useState(false);
  const anchorRef = useRef(null);

  const handleManageColumnMenuToggle = () => {
    setOpenManageColumnMenu((prevOpen) => !prevOpen);
  };

  const handleManageColumnMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenManageColumnMenu(false);
  };

  const prevOpen = useRef(openManageColumnMenu);

  useEffect(() => {
    if (prevOpen.current === true && openManageColumnMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openManageColumnMenu;
  }, [openManageColumnMenu]);

  const handleActionItemClick = (index) => {
    setOpenManageColumnMenu(false);
    setHeaderData(formatHeader(manageColumnMenuItems[index].header, t, headerFilter));
    setManageColumnMenuItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item.isCurrent = true;
        } else {
          item.isCurrent = false;
        }

        return item;
      });
    });

    setItemList(formatRow(manageColumnMenuItems[index].header, fullItemList, t));

    atnUpdateCustomHeader({
      id: manageColumnMenuItems[index].id,
      isCurrent: true,
    });
  };

  useEffect(() => {
    setLoadingTable(true);

    atnGetItemListHeader({
      type: 2,
    }).then(({ data, message }) => {
      setHeaderData(formatHeader(data[data.length - 1]?.header, t, headerFilter));
      setCustomHeaders(data);

      let tempCustomHeaders = [];
      let currentHeader = null;

      for (let i = 0; i < data.length; i++) {
        if (data[i].isCurrent) {
          currentHeader = data[i];
        }

        tempCustomHeaders.push({
          id: data[i].id,
          isCurrent: data[i].isCurrent,
          name: data[i].name,
          type: "custom_col",
          label: data[i].name,
          header: data[i].header,
        });
      }

      // setHeaderData(formatHeader(currentHeader ? currentHeader.header : data[0].header, t, headerFilter));

      setManageColumnMenuItems(tempCustomHeaders);

      let conditions = {
        ...searchConditions,
        currentPage: pageData.currentPage,
        rowsPerPage: pageData.rowsPerPage,
        filters: [],
        search: searchSectionData.keyword,
      };

      atnGetItemListData({
        type: 2,
        data: conditions,
      }).then(async ({ data: itemRow, message: itemMessage }) => {
        setFullItemList(
          itemRow.list.map((item) => {
            item.shipByDate = item?.category === "everyday" ? null : item.shipByDate;
            return item;
          }),
        );

        const [filterData, numberData] = await Promise.all([
          atnGetItemListFilterData({ type: 1, data: conditions }),
          atnGetItemListNumberData({ type: 1, data: conditions }),
        ]);

        setHeaderFilter(filterData?.data?.filter);
        setHeaderData(
          formatHeader(
            currentHeader ? currentHeader?.header : data[0]?.header,
            t,
            filterData?.data?.filter,
          ),
        );
        setItemList(
          formatRow(currentHeader ? currentHeader?.header : data[0]?.header, itemRow.list, t),
        );
        setProgressNumber(numberData?.data?.progressNumber);
        let totalRecords = numberData?.data?.progressNumber.all;
        let totalPages = Math.ceil(numberData?.data?.progressNumber.all / itemRow.page.rowsPerPage);

        setPageData({
          ...itemRow.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setInitLoading(false);
        setLoadingTable(false);
      });
    });
  }, []);

  const searchItems = (page, rowsPerPage, isKeepHeader) => {
    if (loadingTable) {
      return;
    }

    setLoadingTable(true);

    let filters = [];

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["not_acknowledge"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let conditions = {
      ...searchConditions,
      currentPage: page ? page : pageData.currentPage,
      rowsPerPage: rowsPerPage ? rowsPerPage : pageData.rowsPerPage,
      filters: filters,
      search: searchSectionData.keyword,
    };

    atnGetItemListData({
      type: 2,
      data: conditions,
    }).then(async ({ data, message }) => {
      if (data) {
        let totalRecords = 0;

        if (!isKeepHeader) {
          const [filterData, numberData] = await Promise.all([
            atnGetItemListFilterData({ type: 1, data: conditions }),
            atnGetItemListNumberData({ type: 1, data: conditions }),
          ]);

          setIsSelectedAll(false);
          setUnselectedItems([]);
          setSelectedItem([]);
          setSelectedItemDetailsData([]);
          setHeaderFilter(filterData?.data?.filter);
          setHeaderData(updateHeader(headerData, filterData?.data?.filter, t));

          setProgressNumber(numberData?.data?.progressNumber);

          if (tab === 0) {
            totalRecords = numberData?.data?.progressNumber.all;
          } else if (tab === 1) {
            totalRecords = numberData?.data?.progressNumber.notOpen;
          } else if (tab === 2) {
            totalRecords = numberData?.data?.progressNumber.onGoing;
          } else if (tab === 3) {
            totalRecords = numberData?.data?.progressNumber.completed;
          }
        }

        setFullItemList(
          data.list.map((item) => {
            item.shipByDate = item.category === "everyday" ? null : item.shipByDate;
            return item;
          }),
        );

        setItemList(formatRow(headerData, data.list, t));

        let totalPages = Math.ceil(totalRecords / data.page.rowsPerPage);

        setPageData({
          ...data.page,
          totalRecords: totalRecords,
          totalPages: totalPages,
        });

        setLoadingTable(false);
      }
    });
  };

  useEffect(() => {
    if (initLoading) {
      return;
    }
    searchItems();
  }, [
    tab,
    selectedFilter.brand.length,
    selectedFilter.programName.length,
    selectedFilter.pacStatus.length,
    selectedFilter.qtStatus.length,
    selectedFilter.pstStatus.length,
    selectedFilter.ssStatus.length,
    selectedFilter.spuStatus.length,
    selectedFilter.stStatus.length,
    selectedFilter.dpiStatus.length,
    selectedFilter.friStatus.length,
    selectedFilter.labName.length,
    selectedFilter.result.length,
    selectedFilter.factory.length,
  ]);

  useEffect(() => {
    if (initLoading) {
      return;
    }
    searchItems(null, null, true);
  }, [
    // pageData.currentPage,
    // pageData.rowsPerPage,
    searchConditions.orderBy,
    searchConditions.order,
  ]);

  const handleSearchDataChange = (e) => {
    const { name, value } = e.target;

    setSearchSectionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    searchItems(1);
  };

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      currentPage: newPage,
    }));

    searchItems(newPage, pageData.rowsPerPage);
  };

  const handleRowsPerPageChange = (e) => {
    const { value } = e.target;

    setPageData((prevState) => ({
      ...prevState,
      rowsPerPage: value,
    }));

    searchItems(pageData.currentPage, value);
  };

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleSelectAll = (e) => {
    setIsSelectedAll(e.target.checked);
    setUnselectedItems([]);
    setSelectedItem([]);
  };

  const handleItemSelect = (e, itemId) => {
    if (e.target.checked) {
      if (isSelectedAll) {
        if (unselectedItems.indexOf(itemId) >= 0) {
          setUnselectedItems((prevState) => {
            return prevState.filter((item) => {
              return item !== itemId;
            });
          });
        }
      } else {
        if (selectedItem.indexOf(itemId) === -1) {
          setSelectedItem((prevState) => {
            return prevState.concat(itemId);
          });

          let itemDetails = {};

          for (let i = 0; i < fullItemList.length; i++) {
            if (fullItemList[i].id === itemId) {
              itemDetails = {
                id: fullItemList[i].id,
                shipByDate: fullItemList[i].shipByDate,
                item: fullItemList[i],
              };
            }
          }

          setSelectedItemDetailsData((prevState) => {
            return prevState.concat(itemDetails);
          });
        }
      }
    } else {
      if (isSelectedAll) {
        if (unselectedItems.indexOf(itemId) === -1) {
          if (unselectedItems.length === parseInt(pageData.totalRecords) - 1) {
            setIsSelectedAll(false);
            setUnselectedItems([]);
            setSelectedItem([]);
          } else {
            setUnselectedItems((prevState) => {
              return prevState.concat(itemId);
            });
          }
        }
      } else {
        if (selectedItem.indexOf(itemId) >= 0) {
          setSelectedItem((prevState) => {
            return prevState.filter((item) => {
              return item !== itemId;
            });
          });

          setSelectedItemDetailsData((prevState) => {
            return prevState.filter((item) => {
              return item.id !== itemId;
            });
          });
        }
      }
    }
  };

  const handleViewImage = (images) => {
    const downloadImage = (location) => {
      return new Promise((resolve, reject) => {
        atnDownloadFile(location).then(async (res) => {
          if (res) {
            resolve(getFileSourceUrl(res));
          } else {
            reject();
          }
        });
      });
    };

    let downloadImageFunctionArray = [];

    for (let i = 0; i < images.length; i++) {
      downloadImageFunctionArray.push(downloadImage(images[i].location));
    }

    Promise.all(downloadImageFunctionArray).then((result) => {
      let formattedImages = [];

      for (let i = 0; i < result.length; i++) {
        formattedImages.push({
          original: result[i],
          thumbnail: result[i],
        });
      }

      setGalleryImages(formattedImages);
      setOpenImageGallery(true);
    });
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageColumnDialog, setOpenManageColumnDialog] = useState(false);

  const handleSelectedTaskCommonTaskType = () => {
    let taskTypes = ["PAC", "QT", "PST", "SS", "SPU", "ST", "DPI", "FRI"];

    for (let i = 0; i < selectedItemDetailsData.length; i++) {
      if (
        (!selectedItemDetailsData[i].item.pacStatus ||
          selectedItemDetailsData[i].item.pacStatus === "cancelled") &&
        taskTypes.indexOf("PAC") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("PAC"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.qtStatus ||
          selectedItemDetailsData[i].item.qtStatus === "cancelled") &&
        taskTypes.indexOf("QT") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("QT"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.pstStatus ||
          selectedItemDetailsData[i].item.pstStatus === "cancelled") &&
        taskTypes.indexOf("PST") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("PST"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.ssStatus ||
          selectedItemDetailsData[i].item.ssStatus === "cancelled") &&
        taskTypes.indexOf("SS") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("SS"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.spuStatus ||
          selectedItemDetailsData[i].item.spuStatus === "cancelled") &&
        taskTypes.indexOf("SPU") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("SPU"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.stStatus ||
          selectedItemDetailsData[i].item.stStatus === "cancelled") &&
        taskTypes.indexOf("ST") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("ST"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.dpiStatus ||
          selectedItemDetailsData[i].item.dpiStatus === "cancelled") &&
        taskTypes.indexOf("DPI") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("DPI"), 1);
      }

      if (
        (!selectedItemDetailsData[i].item.friStatus ||
          selectedItemDetailsData[i].item.friStatus === "cancelled") &&
        taskTypes.indexOf("FRI") >= 0
      ) {
        taskTypes.splice(taskTypes.indexOf("FRI"), 1);
      }
    }

    let taskTypeList = [];

    for (let i = 0; i < taskTypes.length; i++) {
      if (taskTypes[i] === "PAC") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Everyday Only)",
        });
      } else if (taskTypes[i] === "QT") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "PST") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "SS") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "SPU") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      } else if (taskTypes[i] === "ST") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      } else if (taskTypes[i] === "DPI") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`) + " (Seasonal Only)",
        });
      } else if (taskTypes[i] === "FRI") {
        taskTypeList.push({
          value: taskTypes[i],
          label: t(`task.${taskTypes[i]}`),
        });
      }
    }

    setCommonTaskTypeList(taskTypeList);
  };

  const handleOpenDialog = (type) => {
    if (type === "edit") {
      handleSelectedTaskCommonTaskType();

      let tempHasEverydayItem = false;
      let tempHasSeasonalItem = false;

      for (let i = 0; i < selectedItemDetailsData.length; i++) {
        if (selectedItemDetailsData[i].item.category === "everyday") {
          tempHasEverydayItem = true;
        } else if (selectedItemDetailsData[i].item.category === "seasonal") {
          tempHasSeasonalItem = true;
        }
      }

      setOpenEditDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setBulkEditTaskType("");
    setNewPlannedDate("");
  };

  const handleBulkEdit = () => {
    let change = {
      field: "Planned Date",
      action: "Update",
      before: "",
      after: newPlannedDate,
    };

    let filters = [];

    if (searchSectionData.programName) {
      filters.push({
        name: "programName",
        value: [searchSectionData.programName],
      });
    }

    if (searchSectionData.programYear) {
      filters.push({
        name: "programYear",
        value: [searchSectionData.programYear],
      });
    }

    Object.keys(selectedFilter).forEach((key) => {
      if (selectedFilter[key].length > 0) {
        filters.push({
          name: key,
          value: selectedFilter[key],
        });
      }
    });

    if (tab === 0) {
      // filters.push({
      //   name: "progress",
      //   value: ["all"]
      // });
    } else if (tab === 1) {
      filters.push({
        name: "progress",
        value: ["not_acknowledge"],
      });
    } else if (tab === 2) {
      filters.push({
        name: "progress",
        value: ["in_progress"],
      });
    } else if (tab === 3) {
      filters.push({
        name: "progress",
        value: ["completed"],
      });
    }

    let submitData = {
      listParams: {
        filters: filters,
        search: searchSectionData.keyword,
        // orderBy: searchConditions.orderBy,
        // order: searchConditions.orderBy,
      },
      selectCondition: {
        isSelectedAll: isSelectedAll && unselectedItems.length === 0 && selectedItem.length === 0,
        selectedIds: selectedItem,
        unselectedIds: unselectedItems,
      },
      taskType: bulkEditTaskType,
      plannedDate: newPlannedDate,
      changes: [change],
    };

    atnBulkEditPlannedDate(submitData).then(({ data, message }) => {
      if (data) {
        setOpenEditDialog(false);
        window.location.reload();
      }
    });
  };

  const handleCloseManageColumnDialog = () => {
    setOpenManageColumnDialog(false);
  };

  const handleOpenManageColumnDialog = () => {
    setLeft([
      "productName",
      "programName",
      "factory",
      "pacStatus",
      "qtStatus",
      "pstStatus",
      "ssStatus",
      "spuStatus",
      "stStatus",
      "dpiStatus",
      "friStatus",
      "shipByDate",
    ]);
    setRight(getUnselectedColumns(allHeaderColumns, left));
    setOpenManageColumnDialog(true);
  };

  const handleSubmitManageColumn = () => {
    let submitData = {
      name: manageColumnData.name,
      header: left.toString(),
    };

    atnCreateCustomHeader(submitData).then(({ data, message }) => {
      if (data) {
        setOpenManageColumnDialog(false);
        window.location.reload();
      }
    });
  };

  const handleSorting = (index, itemName, sorting) => {
    if (sorting === 2) {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: null,
        order: null,
      }));
    } else {
      setSearchConditions((prevState) => ({
        ...prevState,
        orderBy: itemName,
        order: sorting === 0 ? "ASC" : "DESC",
      }));
    }

    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.sorting === item.sortingOptions.length - 1) {
            item.sorting = 0;
          } else {
            item.sorting = item.sorting + 1;
          }
        } else {
          item.sorting = 0;
        }

        return item;
      });
    });
  };

  const handleBulkEditTaskTypeChange = (e) => {
    const { value } = e.target;

    setBulkEditTaskType(value);
  };

  const handleNewPlannedDateChange = (e) => {
    const { value } = e.target;

    setNewPlannedDate(value);
  };

  // manage column start
  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleManageColumnDataChange = (e) => {
    const { name, value } = e.target;

    setManageColumnData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const leftList = (items) => (
    <>
      <Box className={classes.manageColumnBoxTitle}>Selected Properties</Box>
      <Paper className={classes.manageColumnBox}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
                disabled={value === "productName" || value === "programName"}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                <ListItemText id={labelId} primary={t(`tableHeader.${value}`)} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  const rightList = (items) => (
    <>
      <Box className={classes.manageColumnBoxTitle}>Unselected Properties</Box>
      <Paper className={classes.manageColumnBox}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                <ListItemText id={labelId} primary={t(`tableHeader.${value}`)} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  const handleEditHeader = (e, index, item) => {
    e.stopPropagation();

    let selectedItem = [];

    for (let i = 0; i < customHeaders[index].header.length; i++) {
      selectedItem.push(customHeaders[index].header[i].name);
    }

    setLeft(selectedItem);
    setRight(getUnselectedColumns(allHeaderColumns, selectedItem));

    setEditHeaderData({
      id: item.id,
    });

    setOpenEditManageColumnDialog(true);
  };

  const handleDeleteHeaderDialogOpen = (e, index, item) => {
    e.stopPropagation();

    setDeleteHeaderData({
      id: item.id,
      isCurrent: item.isCurrent,
      name: item.name,
      index: index,
    });

    setOpenDeleteHeaderDialog(true);
  };

  const handleCloseDeleteHeaderDialog = () => {
    setOpenDeleteHeaderDialog(false);
    setDeleteHeaderData({
      id: "",
      isCurrent: false,
      name: "",
      index: "",
    });
  };

  const handleDeleteHeader = () => {
    atnDeleteCustomHeader({
      headerId: deleteHeaderData.id,
    }).then(({ data, message }) => {
      if (data) {
        setOpenDeleteHeaderDialog(false);

        setDeleteHeaderData({
          id: "",
          isCurrent: false,
          name: "",
          index: "",
        });

        let tempCustomHeaders = JSON.parse(JSON.stringify(manageColumnMenuItems));
        let newCustomHeaders = [];

        for (let i = 0; i < tempCustomHeaders.length; i++) {
          if (tempCustomHeaders[i].id !== deleteHeaderData.id) {
            newCustomHeaders.push(tempCustomHeaders[i]);
          }
        }

        if (deleteHeaderData.isCurrent) {
          setHeaderData(formatHeader(newCustomHeaders[0].header, t, headerFilter));
          newCustomHeaders[0].isCurrent = true;

          setItemList(formatRow(newCustomHeaders[0].header, fullItemList, t));

          atnUpdateCustomHeader({
            id: newCustomHeaders[0].id,
            isCurrent: true,
          });
        }

        setManageColumnMenuItems(newCustomHeaders);
      } else {
        alert(message);
      }
    });
  };

  const handleCloseEditManageColumnDialog = () => {
    setOpenEditManageColumnDialog(false);
    setEditHeaderData({
      id: "",
    });
  };

  const handleEditManageColumn = () => {
    let newHeader = [];

    for (let i = 0; i < allHeaderColumns.length; i++) {
      if (left.indexOf(allHeaderColumns[i]) >= 0) {
        newHeader.push(allHeaderColumns[i]);
      }
    }

    atnUpdateCustomHeader({
      id: editHeaderData.id,
      header: newHeader.toString(),
    }).then(({ data, message }) => {
      if (data) {
        window.location.reload();
      } else {
        alert(message);
      }
    });
  };
  // manage column end

  const handleFilterListToggle = (index) => {
    setHeaderData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          if (item.openFilterList) {
            item.openFilterList = false;
          } else {
            item.openFilterList = true;
          }
        } else {
          item.openFilterList = false;
        }

        return item;
      });
    });
  };

  const handleFilterItemSelect = (filterName, filterItemValue) => {
    if (selectedFilter[filterName].indexOf(filterItemValue) === -1) {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].concat(filterItemValue),
      }));
    } else {
      setSelectedFilter((prevState) => ({
        ...prevState,
        [filterName]: prevState[filterName].filter((item) => item !== filterItemValue),
      }));
    }

    if (pageData.currentPage !== 1) {
      setPageData((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
  };

  const handleOpenItem = (id) => {
    window.open(`/supplier/items/${id}`);
  };

  return (
    <Box className={classes.root}>
      {loadingTable && <GifLoading />}

      <Box className={classes.topContainer}>
        <SearchSection
          title="Project List"
          searchSectionData={searchSectionData}
          handleSearchDataChange={handleSearchDataChange}
          // handleSearchKeywordsChange={handleSearchKeywordsChange}
          handleSearch={handleSearch}
          hasActionMenu={true}
          selectedItemNumber={
            isSelectedAll
              ? parseInt(pageData.totalRecords) - unselectedItems.length
              : selectedItem.length
          }
          actionMenuItems={actionMenuItems}
          handleOpenDialog={handleOpenDialog}
        />
      </Box>

      <Box>
        <ItemListTab tab={tab} progressNumber={progressNumber} handleTabChange={handleTabChange} />
      </Box>

      <Box className={classes.contentContainer}>
        <Box display="flex">
          <Box display="flex" flexGrow={1}>
            <Typography className={classes.tableTitle} variant="body1">
              {tab === 0 && <>Viewing All Items</>}
              {tab === 1 && <>Viewing Not Open Items</>}
              {tab === 2 && <>Viewing Ongoing Items</>}
              {tab === 3 && <>Viewing Completed Items</>}
            </Typography>
            <Typography className={classes.tableInfo} variant="body1">
              <>
                Showing{" "}
                {pageData.totalRecords > 0
                  ? (pageData.currentPage - 1) * pageData.rowsPerPage + 1
                  : 0}{" "}
                -{" "}
                {pageData.currentPage * pageData.rowsPerPage > pageData.totalRecords
                  ? pageData.totalRecords
                  : pageData.currentPage * pageData.rowsPerPage}{" "}
                of {pageData.totalRecords}
              </>
            </Typography>
          </Box>

          <Box>
            <Box
              className={classes.manageColumnButton}
              ref={anchorRef}
              aria-controls={openManageColumnMenu ? "action-menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleManageColumnMenuToggle}
            >
              Manage Column
            </Box>
            <Popper
              className={classes.manageColumnMenu}
              open={openManageColumnMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="top-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleManageColumnMenuClose}>
                      <MenuList
                        // autoFocusItem={openManageColumnMenu}
                        id="action-menu-list-grow"
                        className={classes.manageColumnMenuList}
                      >
                        <MenuItem
                          className={classes.manageColumnMenuItemWithBorder}
                          onClick={() => handleOpenManageColumnDialog()}
                        >
                          Create a New View
                        </MenuItem>
                        <MenuItem className={classes.manageColumnMenuItem} disabled>
                          Saved Column Views
                        </MenuItem>
                        {manageColumnMenuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            className={classes.manageColumnMenuItem}
                            onClick={() => handleActionItemClick(index)}
                          >
                            <Box display="flex">
                              <Box className={classes.manageColumnMenuItemText}>
                                {item.isCurrent && (
                                  <span className={classes.currentHeaderLabel}>(Current)</span>
                                )}{" "}
                                {item.label}
                              </Box>
                              {index !== 0 && (
                                <Box display="flex">
                                  <Box>
                                    <EditIcon
                                      className={classes.manageColumnMenuItemEditButton}
                                      onClick={(e) => handleEditHeader(e, index, item)}
                                    />
                                  </Box>
                                  <Box>
                                    <CancelIcon
                                      className={classes.manageColumnMenuItemDeleteButton}
                                      onClick={(e) => handleDeleteHeaderDialogOpen(e, index, item)}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </MenuItem>
                        ))}
                        {/* <MenuItem
                          className={classes.manageColumnMenuItem}
                          onClick={handleOpenManageColumnDialog}
                        >
                          Manage Column
                        </MenuItem> */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>

        {(!initLoading || !loadingTable) && (
          <Box className={classes.tableContainer}>
            <ItemListTable
              selectedItem={selectedItem}
              handleItemSelect={handleItemSelect}
              handleViewImage={handleViewImage}
              headerData={headerData}
              rowData={itemList}
              selectedFilter={selectedFilter}
              isSelectedAll={isSelectedAll}
              unselectedItems={unselectedItems}
              handleSelectAll={handleSelectAll}
              handleRowClick={handleOpenItem}
              handleSorting={handleSorting}
              handleFilterListToggle={handleFilterListToggle}
              handleFilterItemSelect={handleFilterItemSelect}
              rowsPerPageOption={[20, 50, 75, 100, 150, 200]}
              currentPage={pageData.currentPage}
              rowsPerPage={pageData.rowsPerPage}
              totalPages={pageData.totalPages}
              totalRecords={pageData.totalRecords}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>

      <Dialog onClose={handleCloseEditDialog} open={openEditDialog} className={classes.dialog}>
        <MuiDialogTitle onClose={handleCloseEditDialog} className={classes.dialogTitle}>
          Bulk Edit Planned Date
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogInfo}>
            Please select the task type for planned date edit.
          </Box>

          <Box className={classes.dialogSubtitle}>Select Task Type</Box>
          <Box className={classes.dialogSelectText}>Task Type</Box>

          <Box>
            <Select
              label="Please choose task type here"
              name="bulkEditTaskType"
              value={bulkEditTaskType}
              options={commonTaskTypeList}
              handleChange={handleBulkEditTaskTypeChange}
              additionalClass={classes.dialogSelect}
            />
          </Box>

          {bulkEditTaskType && (
            <Box className={classes.dialogEditSection}>
              <Box className={classes.dialogSubtitle}>Select New Planned Date</Box>
              <Box className={classes.dialogSelectText}>Planned Date</Box>
              <Box>
                <TextField
                  value={newPlannedDate}
                  onChange={handleNewPlannedDateChange}
                  variant="outlined"
                  color="primary"
                  type="date"
                  className={classes.datePicker}
                />
              </Box>
            </Box>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleBulkEdit}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!bulkEditTaskType || !newPlannedDate}
          >
            CONFIRM EDIT
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseManageColumnDialog}
        open={openManageColumnDialog}
        className={classes.manageColumnDialog}
      >
        <MuiDialogTitle onClose={handleCloseManageColumnDialog} className={classes.dialogTitle}>
          Edit/Custom Column
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Box>
            <Box className={classes.dialogSubtitle}>Name</Box>

            <TextInput
              name="name"
              value={manageColumnData.name}
              onChange={handleManageColumnDataChange}
            />
          </Box>
          {/* <Grid container spacing={2} justify="center" alignItems="center" className={classes.manageColumnContainer}> */}
          <Grid container spacing={2} justify="center" className={classes.manageColumnContainer}>
            <Grid item>{leftList(left)}</Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.manageColumnDialogButtonContainer}
              >
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label='move all right'>
                  ≫
                </Button> */}
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label='move all left'>
                  ≪
                </Button> */}
              </Grid>
            </Grid>
            <Grid item>{rightList(right)}</Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseManageColumnDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleSubmitManageColumn}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
            disabled={!manageColumnData.name}
          >
            SAVE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog onClose={handleCloseDeleteHeaderDialog} open={openDeleteHeaderDialog}>
        <MuiDialogTitle onClose={handleCloseDeleteHeaderDialog} className={classes.dialogTitle}>
          Remove a Saved Column View
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          "{deleteHeaderData.name}" will be removed, and this action cannot be undone.
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDeleteHeaderDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleDeleteHeader}
            color="secondary"
            variant="contained"
            className={classes.dialogConfirmButton}
          >
            CONFIRM DELETE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseEditManageColumnDialog}
        open={openEditManageColumnDialog}
        className={classes.manageColumnDialog}
      >
        <MuiDialogTitle onClose={handleCloseEditManageColumnDialog} className={classes.dialogTitle}>
          Edit/Custom Column
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dialogContent}>
          <Grid container spacing={2} justify="center" className={classes.manageColumnContainer}>
            <Grid item>{leftList(left)}</Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.manageColumnDialogButtonContainer}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                {/* <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label='move all left'>
                  ≪
                </Button> */}
              </Grid>
            </Grid>
            <Grid item>{rightList(right)}</Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseEditManageColumnDialog} color="primary">
            CANCEL
          </Button>

          <Button
            onClick={handleEditManageColumn}
            color="primary"
            variant="contained"
            className={classes.dialogConfirmButton}
          >
            SAVE
          </Button>
        </MuiDialogActions>
      </Dialog>

      <Modal onClose={() => setOpenImageGallery(false)} open={openImageGallery}>
        <Box className={classes.imageGalleryContainer}>
          <ImageGallery
            items={galleryImages}
            showFullscreenButton={false}
            showPlayButton={false}
            showIndex={true}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default SupplierProjectList;
