import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormHelperText from "@material-ui/core/FormHelperText";

import Select from "components/input/Select";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  contentText: {
    marginBottom: 16,
  },
  selectTitle: {
    marginBottom: 8,
  },
  selectLabel: {
    fontSize: 12,
    marginBottom: 4,
  },
  selectField: {
    width: "100% !important",
    maxWidth: 600,
  },
  inputWidth: {
    width: "100%",
  },
  error: {
    marginTop: 8,
    color: "#D42600",
  },
  blackText: {
    color: "#000000",
  },
  grayText: {
    color: "#232B3A",
  },
  bold: {
    fontWeight: "bold",
  },
  small: {
    fontSize: 11,
  },
}));

const LabelModal = ({
  onSubmit,
  onChange,
  onClose,
  open,
  title = "Add / Cancel Label 777",
  contentText = "Please select add / cancel labeling in 777 item that you want to do.",
  error = false,
  errorMessages = [],
  isDisabledSubmit = false,
  selectedOption,
  options = [
    {
      value: "add",
      label: "Add Label 777",
    },
    {
      value: "cancel",
      label: "Cancel Label 777",
    },
  ],
  items = [],
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" className={classes.contentText}>
          <Box>
            <DialogContentText
              className={classnames(classes.small, classes.blackText)}
            >{`Labeling 777 program to ${items.length} selected item(s)`}</DialogContentText>

            <DialogContentText className={classnames(classes.small, classes.blackText)}>
              {contentText}
            </DialogContentText>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box className={classnames(classes.selectTitle, classes.bold)}>1. Choose Type</Box>

            <Box className={classnames(classes.selectLabel, classes.grayText)}>Type</Box>
            <Select
              label="Add / Cancel Label 777 (Select one)"
              name="labelOption"
              value={selectedOption}
              options={options}
              handleChange={onChange}
              formControlClass={classes.inputWidth}
              additionalClass={classes.selectField}
            />

            {error && errorMessages.length > 0
              ? errorMessages.map((err, index) => {
                  return (
                    <FormHelperText className={classes.error} key={index}>
                      {err}
                    </FormHelperText>
                  );
                })
              : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary" disabled={isDisabledSubmit}>
          CONFIRM EDIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(LabelModal);
