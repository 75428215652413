import React from "react";
import { v4 as uuidv4 } from "uuid";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const TableHead = ({ data }) => {
  const { headerGroups } = data;
  return (
    <MuiTableHead>
      {headerGroups.map((headerGroup) => (
        <MuiTableRow key={uuidv4()} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const sortProps = {
              ...(column.canSort && column.id !== "selection" && column.getSortByToggleProps()),
            };

            return (
              <MuiTableCell
                key={uuidv4()}
                variant="head"
                align={column.align || "left"}
                {...column.getHeaderProps()}
                className={column.className}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: column.width || "auto" }}
                >
                  <div {...sortProps}>{column.render("Header")}</div>
                  <Box display="flex" alignItems="center">
                    {column.canSort && column.id !== "selection" && (
                      <Box display="flex" alignItems="center" {...sortProps}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )
                        ) : (
                          <UnfoldMoreIcon />
                        )}
                      </Box>
                    )}
                    {column.canFilter && <div>{column.render("Filter")}</div>}
                  </Box>
                </Box>
              </MuiTableCell>
            );
          })}
        </MuiTableRow>
      ))}
    </MuiTableHead>
  );
};

export default TableHead;
