import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Pagination from "./Pagination";
import TrafficLight from "components/display/TrafficLight";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    padding: "26px 30px",
    whiteSpace: "nowrap",
    minWidth: 600,
  },
  table: {
    position: "relative",
    zIndex: 995,
  },
  title: {
    fontWeight: 500,
    fontSize: 12,
  },
  noContent: {
    fontWeight: 500,
    fontSize: 12,
    textAlign: "center",
  },
  totalNumber: {
    fontWeight: 500,
    fontSize: 24,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderTop: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableRow: {
    cursor: "pointer",
    // verticalAlign: "initial",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    minWidth: 110,
    padding: "0px 10px",
    // padding: "5px 10px 0px 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  itemDataTableCell: {
    height: 40,
    minWidth: 110,
    padding: "0px 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "none",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  actionText: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#005EFF",
    paddingTop: 5,
    paddingBottom: 5,
  },
  trafficLightTitle: {
    paddingRight: 30,
    fontSize: 12,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  trafficLight: {
    paddingLeft: 5,
    paddingRight: 35,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  circle: {
    width: 7,
    height: 7,
    borderRadius: "50%",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  grey: {
    backgroundColor: "#CCCCCC",
  },
  blue: {
    backgroundColor: "#196EFF",
  },
  yellow: {
    backgroundColor: "#FFD12E",
  },
  green: {
    backgroundColor: "#1BB135",
  },
  red: {
    backgroundColor: "#D42600",
  },
  indicatorContainer: {
    padding: "10px 0px 0px 10px",
  },
}));

const SubmissionActionTable = ({
  type,
  tableTitle,
  totalNumber,
  rowData = [],
  handleRowClick,
  handleSubRowClick,
  handleActionClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [pageData, setPageData] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRecords: parseInt(rowData.length),
    totalPages: Math.ceil(rowData.length / 10),
  });
  const [currentPageRowData, setCurrentPageRowData] = useState([]);

  useEffect(() => {
    setPageData({
      page: 1,
      rowsPerPage: 10,
      totalRecords: parseInt(rowData.length),
      totalPages: Math.ceil(rowData.length / 10),
    });

    setCurrentPageRowData(rowData.slice(0, 10));
  }, [type, rowData]);

  const handlePageChange = (newPage) => {
    setPageData((prevState) => ({
      ...prevState,
      page: newPage,
    }));

    setCurrentPageRowData(
      rowData.slice((newPage - 1) * pageData.rowsPerPage, newPage * pageData.rowsPerPage),
    );
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPageData((prevState) => ({
      ...prevState,
      page: 1,
      rowsPerPage: newRowsPerPage,
      totalPages: Math.ceil(prevState.totalRecords / newRowsPerPage),
    }));

    setCurrentPageRowData(rowData.slice(0, newRowsPerPage));
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      {tableTitle && <Box className={classes.title}>{tableTitle}</Box>}

      {(totalNumber || totalNumber === 0) && (
        <Box className={classes.totalNumber}>{totalNumber}</Box>
      )}

      {totalNumber === 0 ? (
        <Box className={classes.noContent}>No Data To Display</Box>
      ) : (
        <>
          <Pagination
            currentPage={pageData.page}
            rowsPerPage={pageData.rowsPerPage}
            totalPages={pageData.totalPages}
            totalRecords={pageData.totalRecords}
            rowsPerPageOption={[10, 20]}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  <Box className={classes.tableText}>Submission ID</Box>
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  <Box className={classes.tableText}>Product</Box>
                </TableCell>
                {type !== "waitingForLabQuote" && type !== "waitingLabToConfirmBooking" && (
                  <TableCell className={classes.tableHeaderCell}>
                    <Box className={classes.tableText}>Supplier Action</Box>
                  </TableCell>
                )}
                <TableCell className={classes.tableHeaderCell}>
                  <Box className={classes.tableText}>Program</Box>
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  <Box className={classes.tableText}>Task Type/Status</Box>
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  <Box className={classes.tableText}>ECD</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageRowData.map((row) => (
                <Fragment key={row.id}>
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      onClick={() => handleRowClick({ id: row.id })}
                    >
                      <Box display="flex">
                        <Box flexGrow={1} display="flex">
                          <Box>
                            <Box className={classes.tableText}>{row.submissionNumber}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      onClick={() => handleRowClick({ id: row.id })}
                    />

                    {type !== "waitingForLabQuote" && type !== "waitingLabToConfirmBooking" && (
                      <TableCell scope="row" align="left" className={classes.tableCell}>
                        <Box className={classes.actionText}>
                          <span onClick={() => handleActionClick(row.id, "confirmQuote")}>
                            Confirm Quote
                          </span>
                        </Box>
                      </TableCell>
                    )}

                    <TableCell
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      onClick={() => handleRowClick({ id: row.id })}
                    >
                      {/* <Box display='flex'>
                        <Box flexGrow={1} display='flex'>
                          <Box>
                            <Box className={classes.tableText}>{row.programName}</Box>
                          </Box>
                        </Box>
                      </Box> */}
                    </TableCell>

                    <TableCell
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      onClick={() => handleRowClick({ id: row.id })}
                    />

                    <TableCell
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      onClick={() => handleRowClick({ id: row.id })}
                    />
                  </TableRow>

                  {(row.tasks || []).map((item, index) => (
                    <TableRow key={item.id} className={classes.tableRow}>
                      <TableCell
                        className={
                          index !== row.tasks.length - 1
                            ? classes.itemDataTableCell
                            : classes.tableCell
                        }
                        onClick={() => handleSubRowClick({ submissionId: row.id })}
                      />

                      <TableCell
                        scope="row"
                        align="left"
                        className={
                          index !== row.tasks.length - 1
                            ? classes.itemDataTableCell
                            : classes.tableCell
                        }
                        onClick={() => handleSubRowClick({ submissionId: row.id })}
                      >
                        <Box display="flex">
                          <Box flexGrow={1} display="flex">
                            <Box>
                              <Box
                                className={classes.tableText}
                              >{`WIC Number: ${item.wicNumber}`}</Box>
                              <Box className={classnames(classes.info, classes.tableText)}>
                                {item.productName}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      {type !== "waitingForLabQuote" && type !== "waitingLabToConfirmBooking" && (
                        <TableCell
                          scope="row"
                          align="left"
                          className={
                            index !== row.tasks.length - 1
                              ? classes.itemDataTableCell
                              : classes.tableCell
                          }
                          onClick={() => handleSubRowClick({ submissionId: row.id })}
                        >
                          {/* {(data.value || []).map((action) => (
                            <Box key={action.type} className={classes.actionText}>
                              <span onClick={() => handleActionClick(row.id, action.type)}>
                                {action.label}
                              </span>
                            </Box>
                          ))} */}
                        </TableCell>
                      )}

                      <TableCell
                        className={
                          index !== row.tasks.length - 1
                            ? classes.itemDataTableCell
                            : classes.tableCell
                        }
                        onClick={() => handleSubRowClick({ submissionId: row.id })}
                      >
                        <Box display="flex">
                          <Box flexGrow={1} display="flex">
                            <Box>
                              <Box className={classes.tableText}>
                                {item.programName}{" "}
                                {item.category === "everyday" ? "" : item.programYear}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        scope="row"
                        align="left"
                        className={
                          index !== row.tasks.length - 1
                            ? classes.itemDataTableCell
                            : classes.tableCell
                        }
                        onClick={() => handleSubRowClick({ submissionId: row.id })}
                      >
                        <Box display="flex">
                          <Box flexGrow={1} display="flex">
                            <Box>
                              <Box className={classes.tableText}>{t(`task.${item.taskType}`)}</Box>
                              <Box className={classnames(classes.info, classes.tableText)}>
                                {t(`taskStatus.${item.taskStatus}`)}
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box className={classes.indicatorContainer}>
                              <TrafficLight status={item.taskStatus} />
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        scope="row"
                        align="left"
                        className={
                          index !== row.tasks.length - 1
                            ? classes.itemDataTableCell
                            : classes.tableCell
                        }
                        onClick={() => handleSubRowClick({ submissionId: row.id })}
                      >
                        <Box display="flex">
                          <Box flexGrow={1} display="flex">
                            <Box>
                              <Box className={classes.tableText}>
                                {item.ecd ? moment(item.ecd).format("DD/MM/YYYY") : "N/A"}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <Pagination
            currentPage={pageData.page}
            rowsPerPage={pageData.rowsPerPage}
            totalPages={pageData.totalPages}
            totalRecords={pageData.totalRecords}
            rowsPerPageOption={[10, 20]}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </TableContainer>
  );
};

export default SubmissionActionTable;
