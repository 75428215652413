import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import "@toast-ui/chart/dist/toastui-chart.min.css";
import { PieChart } from "@toast-ui/chart";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "30px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",
  },
  mainTitle: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 50,
  },
  rootPieChart: {
    display: "inline-block",
    marginTop: 15,
    backgroundColor: "white !important",

    "& .toastui-chart-tooltip-container": {
      zIndex: 99999,
    },
    "& .wrapper-tooltip": {
      width: "max-content",
      background: "white",
      padding: "20px 10px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
    },
    "& .title-tooltip": {
      fontWeight: "500",
      fontSize: 14,
      color: "#777777",
    },
    "& .body-tooltip": {
      marginTop: 15,
      display: "flex",
      alignItems: "center",
    },
    "& .point-tooltip": {
      display: "inline-block",
      width: 15,
      height: 15,
      borderRadius: "50%",
      marginRight: 20,
    },
    "& .item-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      marginRight: 20,
    },
    "& .including-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      color: "#777777",
    },
    "& .claim-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      color: "#777777",
      marginLeft: 20,
    },
  },
  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
}));

const TotalSpendingTask = ({ totalSpendingPerTask }) => {
  const classes = useStyles();

  useEffect(() => {
    let listObjTask = {};
    for (let i = 0; i < totalSpendingPerTask.length; i++) {
      listObjTask[totalSpendingPerTask[i].taskType] = totalSpendingPerTask[i];
    }

    let totalAllTask = 0;
    let fullName = {
      DPI: "During Production Inspection",
      FRI: "Final Random Inspection",
      PST: "Pre-Safety Test",
      QT: "Quality Test",
      SPU: "Sample Pick Up",
      SS: "Sample Sealing",
      ST: "Safety Test",
    };

    for (let i = 0; i < totalSpendingPerTask.length; i++) {
      totalAllTask += totalSpendingPerTask[i].total;
    }

    const el = document.getElementById("chart-area");
    el.innerHTML = "";

    const data = {
      categories: ["Total Spending per Task"],
      series: totalSpendingPerTask,
    };

    const options = {
      chart: {
        width: 1080,
        height: 415,
        title: "",
      },
      series: {
        dataLabels: {
          visible: true,
        },
      },
      tooltip: {
        template: (model) => {
          const data = model.data[0];
          return `
              <div class="wrapper-tooltip">
                <div class="title-tooltip">Total Spending in All Test : (USD$${(
                  Math.round((totalAllTask + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</div>
                <div class="body-tooltip">
                  <span class="point-tooltip" style="background:${data.color}"></span>
                  <span class="item-tooltip">${fullName[data.label]}</span>
                  <span class="item-tooltip">${data.percentValue.toFixed(2) + "%"}</span>
                  <span class="including-tooltip">(USD$${(
                    Math.round((parseFloat(data.value) + Number.EPSILON) * 100) / 100
                  ).toLocaleString()})</span>
                  <span class="claim-tooltip">| Including WIC : ${(
                    Math.round(
                      (parseFloat(listObjTask[data.label].includeWic) + Number.EPSILON) * 100,
                    ) / 100
                  ).toLocaleString()}</span>
                  <span class="claim-tooltip">| Suppliers : ${(
                    Math.round(
                      (parseFloat(listObjTask[data.label].suppliers) + Number.EPSILON) * 100,
                    ) / 100
                  ).toLocaleString()} </span>
                </div>
              </div>
            `;
        },
      },
      theme: {
        series: {
          colors: ["#D32F2F", "#7B1FA2", "#196EFF", "#0097A7", "#FBC02D", "#B0D400", "#F57C00"],
          dataLabels: {
            fontSize: 14,
          },
        },
      },
    };

    new PieChart({ el, data, options });
  }, [totalSpendingPerTask]);

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box className={classes.mainTitle}>Total Spending per Task</Box>

      <Box className={show ? classes.show : classes.hide}>
        <Box id="chart-area" className={classes.rootPieChart}></Box>
      </Box>
    </Box>
  );
};

export default TotalSpendingTask;
