import { USER_TYPE } from "shared/constants";

const useUserType = (userType = "") => {
  const isBuyer = userType === USER_TYPE.buyer;
  const isSupplier = userType === USER_TYPE.supplier;
  const isLab = userType === USER_TYPE.lab;

  return { isBuyer, isSupplier, isLab };
};

export default useUserType;
