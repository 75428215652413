export const capitalize = (s = '') => {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const textOrUnknown = (text, unknownText = 'N/A') => {
  return text || unknownText;
};

export const formatFloatingNumber = (val) => {
  return Number(val.toPrecision(15));
}