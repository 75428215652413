import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import ActionButton from "./ActionButton";
import InfoBox from "./InfoBox";

import auth from "auth/authenticator";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menuButton: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: theme.spacing(4),
  },
}));

const MenuBar = ({
  history,
  data,
  actions,
  userType,
  taskType,
  isTenderingLabExemption = false,
}) => {
  const classes = useStyles();

  const handleBack = () => {
    history.push(`/${auth.loginRole()}/items`);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleBack}
          >
            <BackIcon />
          </IconButton>
          <InfoBox data={data} />
          <ActionButton
            itemId={data.id}
            actions={actions}
            userType={userType}
            taskType={taskType}
            isTenderingLabExemption={isTenderingLabExemption}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(MenuBar);
