import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "components/display/Divider";

import styles from "./styles.json";

const useStyles = makeStyles(() => styles);

export const MODAL_SIZE = {
  SMALL: "small",
  NORMAL: "normal",
  LARGE: "large",
};

const Modal = (props) => {
  const {
    children,
    className = {},
    open,
    handleClose,
    title,
    subTitle,
    footer,
    size = MODAL_SIZE.NORMAL,
    hideCloseIcon,
    isCustomCloseIcon,
    ...other
  } = props;
  const classes = useStyles();

  const { classRoot, classTitle, classContent, classAction } = className;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="base-dialog-title"
      aria-describedby="base-dialog-description"
      className={classnames(
        classes.baseModal,
        isCustomCloseIcon ? classes.noTitle : "",
        {
          [classes.small]: size === "small",
          [classes.normal]: size === "normal",
          [classes.large]: size === "large",
        },
        classRoot,
      )}
      disableBackdropClick
      {...other}
    >
      <DialogTitle
        id="base-dialog-title"
        disableTypography
        className={classnames(
          classes.title,
          isCustomCloseIcon ? classes.nonTitle : !title ? classes.emptyTitle : "",
          classTitle,
        )}
      >
        <div>
          {title}{" "}
          {subTitle && (
            <>
              {" "}
              - <span className="subTitle">{subTitle}</span>
            </>
          )}
        </div>
        {((!hideCloseIcon && title) || isCustomCloseIcon) && (
          <div className={classes.closeIcon} data-cy="close-popup">
            <CloseIcon onClick={handleClose} />
          </div>
        )}
      </DialogTitle>
      {!!title && <Divider variant="fullWidth" className={classes.divider} />}
      <DialogContent
        id="modal-body-scroll"
        className={classnames(
          classes.content,
          isCustomCloseIcon ? classes.nonTitle : "",
          !title ? classes.emptyTitle : "",
          classContent,
        )}
      >
        <div id="base-dialog-description" className={classes.innerContent}>
          {children}
        </div>
      </DialogContent>
      {footer && (
        <DialogActions className={classnames(classes.action, classAction)}>{footer}</DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
