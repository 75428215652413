import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _find from "lodash/find";
import _isEqual from "lodash/isEqual";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  atnGetBrands,
  atnGetBuyerCompanyTeamsAndPrograms,
  atnGetCountries,
} from "actions/commonActions";

import { atnCreateItem, atnCreateTasks, atnGetSuppliersAndFactories } from "actions/itemActions";

import { ITEM_CATEGORY, ITEM_SAMPLE_DATA, NA, PROGRAM_TYPES } from "shared/constants";

import Header from "../components/Header";
import ItemDetails from "../components/ItemDetails";
import SupplierFactoryDetails from "../components/SupplierFactoryDetails";
import MasterWICDetails from "../components/MasterWICDetails";
import SubWICDetails from "../components/SubWICDetails";
import TaskAssignment from "./TaskAssignment";
import ValidityDetails from "../components/ValidityDetails";
import { getBuyerCompanyTeams, getProgramYears } from "services/buyerItemService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const CreateItem = ({ history }) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const [createItemData, setCreateItemData] = useState(ITEM_SAMPLE_DATA);

  const [buyerCompanyTeams, setBuyerCompanyTeams] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programYears, setProgramYears] = useState([]);
  const [exportMarkets, setExportMarkets] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [factories, setFactories] = useState([]);

  const isEverydayProgram = () => {
    const program = _get(createItemData, "buyerTeam");
    return _isEqual(program, PROGRAM_TYPES.walgreensEveryday.value);
  };

  const stepsData = [
    { step: 0, header: "Item Details", title: "Item Details" },
    {
      step: 1,
      header: "Supplier & Factory Details",
      title: "Supplier & Factory Details",
    },
    { step: 2, header: "Master WIC Details", title: "Master WIC Details" },
    { step: 3, header: "Sub WIC Details", title: "Sub WIC Details" },
    { step: 4, header: "Task Assignment", title: "Select Task for Item" },
    {
      step: 5,
      header: "Validity Date & Period",
      title: "Compliance Validity Details",
    },
  ];

  useEffect(() => {
    getBrands();
    getBuyerCompanyTeamsAndPrograms();
    getExportMarkets();
    fetchProgramYears();
  }, []);

  useEffect(() => {
    if (suppliers.length || factories.length) return;

    if (step === 1) getSuppliersAndFactories();
  }, [step]);

  const getBrands = () => {
    atnGetBrands().then(({ data }) => {
      const brandData = data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      setBrands(_orderBy(brandData, "label", "asc"));
    });
  };

  const getBuyerCompanyTeamsAndPrograms = () => {
    atnGetBuyerCompanyTeamsAndPrograms().then(({ data }) => {
      const buyerCompanyTeams = getBuyerCompanyTeams(data);

      setBuyerCompanyTeams(_orderBy(buyerCompanyTeams, "label", "asc"));
    });
  };

  const fetchProgramYears = () => {
    setProgramYears(getProgramYears());
  };

  const getExportMarkets = () => {
    atnGetCountries().then(({ data }) => {
      const exportMarketData = data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      setExportMarkets(_orderBy(exportMarketData, "label", "asc"));
    });
  };

  const getSuppliersAndFactories = () => {
    atnGetSuppliersAndFactories().then(({ data }) => {
      const supplierData = data?.reduce((acc, cur) => {
        const factoryList = cur?.supplierFactories?.map((item) => ({
          supplierFactoryId: item?.id,
          id: item?.factory.id,
          name: item?.factory.name,
          address: item?.factory.address1
            ? item?.factory.address1
            : item?.factory.address2
            ? item?.factory.address2
            : NA,
          code: item?.factory.factoryCode,
          contactPerson: item?.contactPerson,
          contactNumber: item?.contactNumber,
          contactEmail: item?.contactEmail,
        }));

        const supplier = {
          id: cur?.id,
          supplierCompanyName: cur?.name,
          contactPerson: cur?.contactPerson,
          supplierEmail: cur?.contactEmail,
          supplierID: cur?.supplierCode,
          factories: factoryList,
        };

        acc.push(supplier);

        return acc;
      }, []);

      setSuppliers(supplierData);
    });
  };

  const handlePrevPage = () => {
    if (!step) return;

    setStep(step - 1);
  };

  const handleConfirmSubWIC = () => {
    let { tasks = [] } = createItemData;
    let tempSubWICs = [];
    let unselectedSubWICs = [];

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].subWics.length === 0) {
        tasks[i].subWics = JSON.parse(JSON.stringify(createItemData.subWics));
      } else {
        tempSubWICs = JSON.parse(JSON.stringify(createItemData.subWics));
        unselectedSubWICs = [];

        for (let j = 0; j < tasks[i].subWics.length; j++) {
          if (!tasks[i].subWics[j].isSelected) {
            unselectedSubWICs.push(tasks[i].subWics[j].tempId);
          }
        }

        for (let j = 0; j < tempSubWICs.length; j++) {
          if (unselectedSubWICs.indexOf(tempSubWICs[j].tempId) >= 0) {
            tempSubWICs[j].isSelected = false;
          }
        }

        tasks[i].subWics = tempSubWICs;
      }
    }

    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: tasks,
    }));

    setStep(step + 1);
  };

  const handleNextPage = () => {
    if (step < (isEverydayProgram() ? 5 : 4)) {
      setStep(step + 1);
    }
  };

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleSubmit = async () => {
    setIsDisabledConfirmButton(true);

    const subWicData = createItemData.subWics.map((subWic) => {
      const commonSubwic = {
        productName: subWic.productName,
        wicNumber: subWic.wicNumber,
        assortments: subWic.assortments,
      };

      return isEverydayProgram()
        ? //everyday
          commonSubwic
        : //seasonal
          {
            ...commonSubwic,
            ageGrade: subWic.ageGrade,
            isFoodContact: subWic.isFoodContact,
            childOrNot: subWic.productCategory === "childrenProduct",
            walkThroughComments: subWic.walkthroughComments,
          };
    });

    const exportMarketData = createItemData.exportMarkets.map((m) => ({
      id: m.value,
    }));

    const supplierFactoryData = createItemData?.supplierFactory?.isNewAdd
      ? {
          supplierCompany: {
            id: createItemData.supplier.id,
          },
          factory: {
            factoryCode: createItemData.supplierFactory.code,
            name: createItemData.supplierFactory.name,
            address1: createItemData.supplierFactory.address,
          },
        }
      : {
          id: createItemData.supplierFactory.supplierFactoryId,
        };

    const masterWicFiles = createItemData.masterWicImages.map((image) => ({
      id: image.id,
      type: "product_image",
    }));

    let hasTasks = false;

    for (let i = 0; i < createItemData.tasks.length; i++) {
      if (createItemData.tasks[i].isSelected) {
        hasTasks = true;
        break;
      }
    }

    const taskList = [];

    for (const task of createItemData.tasks) {
      if (task.isSelected) {
        // Everyday
        let taskData = {
          taskType: task.taskType,
          deadLine: task.wbaDeadline ? moment(task.wbaDeadline).format("YYYY-MM-DD") : "",
          remark: task.remarks,
        };

        // Seasonal
        const seasonalData = {
          ...taskData,
          assignTo: task.handledBy || "lab",
          po: createItemData.shipByDates,
          shipByDate: createItemData.shipByDates[0].shipByDate,
          subWics: task.subWics
            .filter((subwic) => subwic.isSelected)
            .map((sorted) => ({
              wicNumber: sorted.wicNumber,
              productName: sorted.productName,
            })),
        };

        if (!isEverydayProgram()) {
          taskData = { ...taskData, ...seasonalData };
        }

        taskList.push(taskData);
      }
    }

    const itemData = {
      productName: createItemData.masterWic.productName,
      wicNumber: createItemData.masterWic.wicNumber,
      programYear: createItemData.programYear,
      category: isEverydayProgram() ? ITEM_CATEGORY.everyday.value : ITEM_CATEGORY.seasonal.value,
      pacValidityDate: createItemData.masterWic.pacValidityDate,
      validityType: createItemData.masterWic.validityType,
      validityDate: createItemData.masterWic.validityDate,
      validityPeriod: createItemData.masterWic.validityPeriod,
      selfInspectionValidityDate: createItemData.masterWic.selfInspectionValidityDate,
      // po: createItemData.shipByDates,
      masterWicFiles: masterWicFiles,
      subWics: subWicData,
      buyerCompanyTeamProgram: {
        id: createItemData.program,
      },
      brand: {
        id: createItemData.brand,
      },
      exportMarkets: exportMarketData,
      supplierFactory: supplierFactoryData,
      icl: createItemData.icl,
      shipByDate: isEverydayProgram() ? null : createItemData.shipByDates[0].shipByDate,
      hasTasks: hasTasks,
      tasks: taskList,
    };

    const res = await atnCreateItem(itemData);

    setIsDisabledConfirmButton(false);
    setOpenConfirmDialog(false);

    if (!res || (res.code !== 200 && res.code !== 201)) {
      return;
    }

    history.push(`/buyer/items/${res.data.id}`);
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleCreateItemDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "buyerTeam") {
      const getExportMarketDefault = () => {
        const isWalgreens = value === 1;
        const isBoots = value === 2;
        if (isWalgreens) {
          return _find(exportMarkets, { label: "United States" });
        } else if (isBoots) {
          return _find(exportMarkets, { label: "United Kingdom" });
        }

        return _find(exportMarkets, { label: "United States" });
      };

      setCreateItemData((prevState) => ({
        ...prevState,
        [name]: value,
        program: "",
        programYear: "",
        exportMarkets: [getExportMarketDefault()],
        // filter tasks by category
        tasks: ITEM_SAMPLE_DATA.tasks.filter(
          (task) =>
            task.category ===
            (_isEqual(value, PROGRAM_TYPES.walgreensEveryday.value)
              ? ITEM_CATEGORY.everyday.value
              : ITEM_CATEGORY.seasonal.value),
        ),
      }));

      for (let i = 0; i < buyerCompanyTeams.length; i++) {
        if (buyerCompanyTeams[i].value === value) {
          setPrograms(buyerCompanyTeams[i].programs);
          break;
        }
      }
    } else if (name === "program") {
      setCreateItemData((prevState) => ({
        ...prevState,
        [name]: value,
        programYear: _get(programYears, "0.value", ""),
      }));
    } else if (name === "supplier") {
      for (let i = 0; i < suppliers.length; i++) {
        if (suppliers[i].id === value.id) {
          setFactories(value.factories);
          break;
        }
      }

      setCreateItemData((prevState) => ({
        ...prevState,
        [name]: value,
        supplierFactory: {
          isNewAdd: false,
          id: "",
          name: "",
          address: "",
          code: "",
          contactPersonName: "",
          contactNumber: "",
          contactEmail: "",
        },
      }));
    } else if (
      [
        "pacValidityDate",
        "validityType",
        "validityDate",
        "validityPeriod",
        "selfInspectionValidityDate",
      ].includes(name)
    ) {
      setCreateItemData((prevState) => ({
        ...prevState,
        masterWic: {
          ...prevState.masterWic,
          [name]: value,
        },
      }));
    } else {
      setCreateItemData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddSubWIC = (subWIC) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.concat(subWIC),
    }));
  };

  const handleRemoveSubWIC = (index) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.filter((item, i) => i !== index),
    }));
  };

  const handleSubWICDataChange = (index, subWIC) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.map((item, i) => {
        if (i === index) {
          return subWIC;
        }

        return item;
      }),
    }));
  };

  const handleTaskSelect = (taskType) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item) => {
        if (item.taskType === taskType) {
          if (item.isSelected) {
            item.subWics = prevState.subWics;
            item.remarks = "";
          }
          item.isSelected = !item.isSelected;
        }

        return item;
      }),
    }));
  };

  const handleTaskDataChange = (e, index) => {
    const { name, value } = e.target;

    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleDeadlineChange = (e, index) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item, i) => {
        if (i === index) {
          item.wbaDeadline = e ? e._d : "";
        }

        return item;
      }),
    }));
  };

  const handleTaskSubWICChange = (taskType, subWICIndex) => {
    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item) => {
        if (item.taskType === taskType) {
          item.subWics = item.subWics.map((subWIC, i) => {
            if (i === subWICIndex) {
              subWIC.isSelected = !subWIC.isSelected;
            }

            return subWIC;
          });
        }

        return item;
      }),
    }));
  };

  const handleShipByDateChange = (e, index) => {
    const value = e.target.value;

    let tasks = createItemData.tasks;

    for (let i = 0; i < tasks.length; i++) {
      tasks[i].wbaDeadline = value ? moment(value).subtract(tasks[i].days, "days")._d : "";
    }

    setCreateItemData((prevState) => ({
      ...prevState,
      tasks: tasks,
      shipByDates: createItemData.shipByDates.map((item, i) => {
        if (i === index) {
          item.shipByDate = value;
        }

        return item;
      }),
    }));
  };

  const addNewShipByDate = () => {
    setCreateItemData((prevState) => ({
      ...prevState,
      shipByDates: createItemData.shipByDates.concat({
        shipByDate: "",
      }),
    }));
  };

  const handleExportMarketsChange = (e) => {
    const { value } = e.target;

    const exportMarketsData = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < exportMarkets.length; j++) {
        if (value[i] === exportMarkets[j].value) {
          exportMarketsData.push(exportMarkets[j]);
        }
      }
    }

    setCreateItemData((prevState) => ({
      ...prevState,
      exportMarkets: exportMarketsData,
    }));
  };

  return (
    <Box className={classes.root}>
      <Header
        data={{
          step,
          stepTotal: isEverydayProgram() ? stepsData.length : stepsData.length - 1,
          headerText: _get(_find(stepsData, { step }), "header"),
        }}
        func={{ handlePrevPage, handleClose }}
      />

      <Box className={classes.content}>
        <Box className={classes.title}>{_get(_find(stepsData, { step }), "title")}</Box>

        {step === 0 && (
          <ItemDetails
            data={{
              itemData: createItemData,
              buyerCompanyTeams,
              programs,
              programYears,
              exportMarkets,
              brands,
              isEverydayProgram: isEverydayProgram(),
            }}
            func={{
              handleCreateItemDataChange,
              handleShipByDateChange,
              addNewShipByDate,
              handleExportMarketsChange,
              handleNextPage,
            }}
          />
        )}

        {step === 1 && (
          <SupplierFactoryDetails
            data={{ itemData: createItemData, suppliers, factories }}
            func={{ onItemDataChange: handleCreateItemDataChange, handleNextPage }}
          />
        )}

        {step === 2 && (
          <MasterWICDetails
            data={{ itemData: createItemData }}
            func={{ onItemDataChange: handleCreateItemDataChange, handleNextPage }}
          />
        )}

        {step === 3 && (
          <SubWICDetails
            data={{
              itemData: createItemData,
              isEverydayProgram: isEverydayProgram(),
            }}
            func={{
              handleAddSubWIC,
              handleRemoveSubWIC,
              handleSubWICDataChange,
              handleNextPage: handleConfirmSubWIC,
            }}
          />
        )}

        {step === 4 && (
          <TaskAssignment
            data={{
              createItemData,
              openConfirmDialog,
              isEverydayProgram: isEverydayProgram(),
              taskList: createItemData.tasks,
            }}
            func={{
              handleTaskSelect,
              handleTaskDataChange,
              handleDeadlineChange,
              handleTaskSubWICChange,
              handleCreateItemDataChange,
              handleNextPage,
              handleSubmit: handleConfirmDialogOpen,
            }}
          />
        )}

        {isEverydayProgram() && step === 5 && (
          <ValidityDetails
            data={{ itemData: createItemData, openConfirmDialog }}
            func={{
              onItemDataChange: handleCreateItemDataChange,
              handleSubmit: handleConfirmDialogOpen,
            }}
          />
        )}
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        disableBackdropClick
        data-cy={"ci-confirm"}
      >
        <DialogTitle>Confirm to submit?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you confirm to submit?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmDialogClose}
            color="primary"
            variant="outlined"
            data-cy={"ci-confirm-cancel"}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isDisabledConfirmButton}
            data-cy={"ci-confirm-submit"}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(null, {
  atnGetBrands,
  atnGetBuyerCompanyTeamsAndPrograms,
  atnGetCountries,
  atnCreateItem,
  atnCreateTasks,
  atnGetSuppliersAndFactories,
})(CreateItem);
