import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ReactTable from "components/table/ReactTable";
import classTable from "./css/style.module.scss";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import "@toast-ui/chart/dist/toastui-chart.min.css";
import { PieChart } from "@toast-ui/chart";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",
  },

  title: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 50,
  },
  subtitle: {
    fontSize: 14,
    marginTop: 30,
    fontWeight: "500",
  },
  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",
    marginTop: 25,

    "& .key": {
      display: "inline-block",
      width: 12,
      height: 12,
      background: "#F57C00",
      borderRadius: "50%",
      marginRight: 10,
    },
    "& .key_0": {
      background: "#F57C00",
    },
    "& .key_1": {
      background: "#D32F2F",
    },
    "& .key_2": {
      background: "#7B1FA2",
    },
    "& .key_3": {
      background: "#196EFF",
    },
    "& .key_4": {
      background: "#0097A7",
    },
    "& .key_5": {
      background: "#FBC02D",
    },
    "& .key_6": {
      background: "#B0D400",
    },
    "& .key_7": {
      background: "#FFC7DB",
    },
    "& .key_8": {
      background: "#8DAA00",
    },
    "& .key_9": {
      background: "#47530D",
    },
    "& .key_10": {
      background: "#E8178A",
    },
    "& .key_11": {
      background: "#803D00",
    },
  },
  hint: {
    display: "flex",
    marginTop: 30,
  },
  between: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#003DA5",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  over: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#D42600",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  below: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#1BB135",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  wrapperUSD: {
    display: "flex",
    marginTop: "25px",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "1px solid #DADADA",
  },
  usdTotal: {
    fontFamily: "Roboto",
    fontSize: "26px",
    fontWeight: "bold",
    color: "#003DA5",
    marginRight: "14px",
  },

  dateTotal: {
    fontFamily: "Roboto",
    fontSize: "16px",
    color: "#777777",
  },

  yearFinancial: {
    fontWeight: "500",
    paddingRight: "10px",
    marginRight: "10px",
    borderRight: "1px solid #777777",
  },

  rootPieChart: {
    display: "inline-block",
    backgroundColor: "white !important",

    "& .toastui-chart-wrapper": {
      margin: "0 auto",
      width: "fit-content",
    },
    "& .toastui-chart-tooltip-container": {
      zIndex: 99999,
    },
    "& .wrapper-tooltip": {
      width: "max-content",
      background: "white",
      padding: "20px 10px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
    },
    "& .title-tooltip": {
      fontWeight: "500",
      fontSize: 14,
      color: "#777777",
    },
    "& .body-tooltip": {
      marginTop: 15,
      display: "flex",
      alignItems: "center",
    },
    "& .point-tooltip": {
      display: "inline-block",
      width: 15,
      height: 15,
      borderRadius: "50%",
      marginRight: 20,
    },
    "& .item-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      marginRight: 20,
    },
    "& .including-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      color: "#777777",
    },
    "& .claim-tooltip": {
      fontWeight: 500,
      fontSize: 12,
      color: "#777777",
      marginLeft: 20,
    },
  },

  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },

  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  pieChartZero: {
    width: 280,
    height: 280,
    borderRadius: "50%",
    background: "#CCCCCC",
    margin: "0 auto",
    textAlign: "center",
    lineHeight: "280px",
    marginTop: 28,
    marginBottom: 28,
    fontSize: 36,
    color: "white",
  },
}));

const QuantityClaim = ({
  handleTabChange,
  financialYearTotal = { value: -1, startAt: 0, endAt: 0 },
  objQuantityClaim = { quantityClaims: [], itemQuantityClaimSum: 0, totalClaim: 0 },
}) => {
  const classes = useStyles();
  const { quantityClaims, itemQuantityClaimSum, totalClaim } = objQuantityClaim;

  useEffect(() => {
    if (quantityClaims && quantityClaims.length) {
      let customSeries = quantityClaims.map((item, index) => {
        return { ...item, idClaim: `key_${index}`, data: parseFloat(item.percent) };
      });
      let objSeries = {};

      for (let i = 0; i < customSeries.length; i++) {
        objSeries[customSeries[i].name] = customSeries[i];
      }
      setDataTable(customSeries);
      // Handle Pie Chart
      handlePieChart(customSeries, objSeries);
    }
  }, [quantityClaims]);

  const handlePieChart = (customSeries, objSeries) => {
    if (itemQuantityClaimSum === 0) return;

    // ================ Start Handle for Chart ================
    const el = document.getElementById("quantity-pie-chart");
    el.innerHTML = "";

    const data = {
      categories: [""],
      series: customSeries,
    };

    const options = {
      chart: { width: 1060, height: 415, title: "" },
      series: {
        dataLabels: {
          visible: true,
        },
      },
      tooltip: {
        template: (model) => {
          const data = model.data[0];
          return `
              <div class="wrapper-tooltip">
                <div class="title-tooltip">Total Claim: (USD$${(
                  Math.round((parseFloat(totalClaim) + Number.EPSILON) * 100) / 100
                ).toLocaleString()})</div>
                <div class="body-tooltip">
                  <span class="point-tooltip" style="background:${data.color}"></span>
                  <span class="item-tooltip">${data.label}</span>
                  <span class="item-tooltip">${data.percentValue.toFixed(2) + "%"}</span>
                  <span class="claim-tooltip">| Claim item: ${(
                    Math.round(
                      (parseFloat(objSeries[data.label].itemQtyClaim) + Number.EPSILON) * 100,
                    ) / 100
                  ).toLocaleString()}</span>
                </div>
              </div>
            `;
        },
      },
      legend: {
        item: {
          width: 210,
          overflow: "ellipsis",
        },
      },
      theme: {
        legend: {
          label: {
            fontSize: 12,
            fontWeight: 500,
            color: "#000000",
          },
        },
        series: {
          colors: [
            "#F57C00",
            "#D32F2F",
            "#7B1FA2",
            "#196EFF",
            "#0097A7",
            "#FBC02D",
            "#B0D400",
            "#FFC7DB",
            "#8DAA00",
            "#47530D",
            "#E8178A",
            "#803D00",
          ],
          dataLabels: {
            fontSize: 14,
          },
        },
      },
    };

    new PieChart({ el, data, options });
    // ================ End Handle for Chart ================
  };

  const sortOnly = { canSort: true, canFilter: false };
  const noSortFilter = { canSort: false, canFilter: false };

  // const dataTable = quantityClaim.data.list
  const [dataTable, setDataTable] = useState([]);

  const tableColumn = [
    {
      id: "reason",
      value: ({ name, idClaim }) => {
        return { name, idClaim };
      },
      label: "Customer Reason",
      ...noSortFilter,
      render: ({
        cell: {
          value: { name, idClaim },
        },
      }) => {
        return (
          <Box>
            <span className={`key ${idClaim}`}></span>
            <span>{name}</span>
          </Box>
        );
      },
    },
    {
      id: "quantityClaim",
      value: "itemQtyClaim",
      label: "Item Quantity Claim",
      ...sortOnly,
      render: ({ cell: { value: itemQtyClaim } }) => {
        return (
          <Box>
            {(Math.round((parseFloat(itemQtyClaim) + Number.EPSILON) * 100) / 100).toLocaleString()}
          </Box>
        );
      },
    },
    {
      id: "total",
      value: "data",
      label: "% Total",
      ...sortOnly,
      render: ({ cell: { value: data } }) => {
        return <Box>{data}</Box>;
      },
    },
  ];

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box className={classes.title}>Quantity Claim</Box>

      <Box className={show ? classes.show : classes.hide}>
        <Box className={classes.subtitle}>Total Item Quantity Claim</Box>

        <Box className={classes.wrapperUSD}>
          <Box className={classes.usdTotal}>
            {(
              Math.round((parseFloat(itemQuantityClaimSum) + Number.EPSILON) * 100) / 100
            ).toLocaleString()}
          </Box>
          <Box className={classes.dateTotal}>
            {financialYearTotal.value > -1 && (
              <span className={classes.yearFinancial}>{financialYearTotal.value}</span>
            )}
            <span>
              {financialYearTotal.startAt} - {financialYearTotal.endAt}
            </span>
          </Box>
        </Box>

        {itemQuantityClaimSum === 0 ? (
          <Box className={classes.pieChartZero}>0 %</Box>
        ) : (
          <Box id="quantity-pie-chart" className={classes.rootPieChart}></Box>
        )}

        <Box className={classes.boxTable}>
          <ReactTable
            columns={tableColumn}
            data={dataTable}
            classes={{
              root: classTable.quantityClaimTable,
              viewColumnIcon: classTable.viewColumnIcon,
            }}
            hasFooter={true}
            dataFooter={[
              "Sum",
              (
                Math.round((parseFloat(itemQuantityClaimSum) + Number.EPSILON) * 100) / 100
              ).toLocaleString(),
              "100%",
            ]}
            noPagingBottom={true}
            sizeOfPage={12}
          />
        </Box>

        <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 0)}>
          VIEW ON LIST
        </Box>
      </Box>
    </Box>
  );
};

export default QuantityClaim;
