import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import DatePicker from "components/input/DatePicker";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  gridContainer: {
    paddingTop: 30,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputField: {
    width: "100%",
  },
  datePicker: {
    width: "100%",
  },
  to: {
    paddingTop: 10,
    textAlign: "center",
  },
  selectFieldContainer: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  fieldPadding: {
    paddingTop: 30,
  },
}));

const currencyOptions = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "RMB",
    label: "RMB",
  },
];

const InspectionQuotationDetails = ({
  quotationInspectionTaskDetail,
  handleQuotationInspectionTaskDetailChange,
  handleInspectionPrepaymentChange,
  handleSubmit,
}) => {
  const classes = useStyles();

  const inspectionLevelOptions = [
    {
      value: "I",
      label: "General Inspection Level I",
    },
    {
      value: "II",
      label: "General Inspection Level II",
    },
    {
      value: "III",
      label: "General Inspection Level III",
    },
    {
      value: "S1",
      label: "Special Inspection Level, S-1",
    },
    {
      value: "S2",
      label: "Special Inspection Level, S-2",
    },
    {
      value: "S3",
      label: "Special Inspection Level, S-3",
    },
    {
      value: "S4",
      label: "Special Inspection Level, S-4",
    },
  ];

  const aqlLevelOptions = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "0.065",
      label: "0.065",
    },
    {
      value: "0.1",
      label: "0.1",
    },
    {
      value: "0.15",
      label: "0.15",
    },
    {
      value: "0.25",
      label: "0.25",
    },
    {
      value: "0.4",
      label: "0.4",
    },
    {
      value: "0.65",
      label: "0.65",
    },
    {
      value: "1.0",
      label: "1.0",
    },
    {
      value: "1.5",
      label: "1.5",
    },
    {
      value: "2.5",
      label: "2.5",
    },
    {
      value: "4.0",
      label: "4.0",
    },
    {
      value: "6.5",
      label: "6.5",
    },
  ];

  return (
    <Paper className={classes.container}>
      <Box className={classes.sectionTitle}>Inspection Details</Box>

      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Inspection Date</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <Grid container>
              <Grid item xs={5}>
                <DatePicker
                  name="inspectionDateFrom"
                  value={quotationInspectionTaskDetail.inspectionDateFrom}
                  handleChange={handleQuotationInspectionTaskDetailChange}
                  additionalClass={classes.datePicker}
                />
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.to}>To</Box>
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  name="inspectionDateTo"
                  value={quotationInspectionTaskDetail.inspectionDateTo}
                  handleChange={handleQuotationInspectionTaskDetailChange}
                  additionalClass={classes.datePicker}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Inspection Level</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <Box className={classes.selectFieldContainer}>
              <Select
                label="Inspection Level (Select One)"
                name="inspectionLevel"
                value={quotationInspectionTaskDetail.inspectionLevel}
                options={inspectionLevelOptions}
                handleChange={handleQuotationInspectionTaskDetailChange}
                additionalClass={classes.inputField}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>AQL Level</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <Grid container>
              <Grid item xs={4}>
                <Box>Critical</Box>
                <Box className={classes.selectFieldContainer}>
                  <Select
                    label="Critical (Select One)"
                    name="aqlCritical"
                    value={quotationInspectionTaskDetail.aqlCritical}
                    options={aqlLevelOptions}
                    handleChange={handleQuotationInspectionTaskDetailChange}
                    additionalClass={classes.inputField}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>Major</Box>
                <Box className={classes.selectFieldContainer}>
                  <Select
                    label="Major (Select One)"
                    name="aqlMajor"
                    value={quotationInspectionTaskDetail.aqlMajor}
                    options={aqlLevelOptions}
                    handleChange={handleQuotationInspectionTaskDetailChange}
                    additionalClass={classes.inputField}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>Minor</Box>
                <Box className={classes.selectFieldContainer}>
                  <Select
                    label="Minor (Select One)"
                    name="aqlMinor"
                    value={quotationInspectionTaskDetail.aqlMinor}
                    options={aqlLevelOptions}
                    handleChange={handleQuotationInspectionTaskDetailChange}
                    additionalClass={classes.inputField}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Total Quantity</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Total Quantity"
              name="totalQuantity"
              value={quotationInspectionTaskDetail.totalQuantity}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.fieldPadding}
        sx={{ width: "100%" }}
      >
        <Box className={classnames(classes.sectionTitle)}>Design Inspection Plan</Box>
        <Box sx={{ width: "200px", float: "right" }}>
          <Select
            label="Currency (Select One)"
            name="currency"
            value={quotationInspectionTaskDetail.currency}
            options={currencyOptions}
            handleChange={handleQuotationInspectionTaskDetailChange}
            additionalClass={classes.selectField}
          />
        </Box>
      </Box>

      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Number of Mandays</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <TextInput
              type="number"
              placeholder="Number of Mandays"
              name="noOfMandays"
              value={quotationInspectionTaskDetail.noOfMandays}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Manday Unit Cost</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Manday Unit Cost"
              name="mandayUnitCost"
              value={quotationInspectionTaskDetail.mandayUnitCost}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Travel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Travel Expenses"
              name="travelExpenses"
              value={quotationInspectionTaskDetail.travelExpenses}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Hotel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Hotel Expenses"
              name="hotelExpenses"
              value={quotationInspectionTaskDetail.hotelExpenses}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Other Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Other Expenses"
              name="otherExpenses"
              value={quotationInspectionTaskDetail.otherExpenses}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Late Booking Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Late Booking Fee"
              name="lateBookingFee"
              value={quotationInspectionTaskDetail.lateBookingFee}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Cancellation Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Cancellation Fee"
              name="cancellationFee"
              value={quotationInspectionTaskDetail.cancellationFee}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">
                  {quotationInspectionTaskDetail.currency}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Sub-total</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {quotationInspectionTaskDetail.currency} {quotationInspectionTaskDetail.subTotal}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>VAT %</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="VAT %"
              name="vat"
              value={quotationInspectionTaskDetail.vat}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Total Price</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {quotationInspectionTaskDetail.currency} {quotationInspectionTaskDetail.totalPrice}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Request Pre-Payment</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <Checkbox
              name="isPrepayment"
              checked={quotationInspectionTaskDetail.isPrepayment}
              onChange={handleInspectionPrepaymentChange}
              color="primary"
            />{" "}
            Yes
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Remarks</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              placeholder="Remarks"
              name="remarks"
              value={quotationInspectionTaskDetail.remarks}
              additionalClass={classes.inputField}
              onChange={handleQuotationInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default InspectionQuotationDetails;
