import React, { memo, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Box, Button, Typography } from "@material-ui/core";

import Search from "components/input/Search";
import Pagination from "components/table/Pagination";

import {
  isPbNotExisted,
  isTaskFRID,
  isTaskSPUD,
  isValidPbStatusAndImportedDateForFRI,
  isValidPbStatusForFRI,
  isValidPbStatusForSPU,
} from "services/pbService";

import { SUPPLIER } from "shared/constants";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginTop: 8,
  },
  tableBody: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  table: {
    position: "relative",
    zIndex: 995,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxIconDisabled: {
    color: "#CCCCCC",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogContent: {
    maxHeight: "400px",
  },
  dialogMessage: {
    fontSize: 12,
  },
  dialogSearchContainer: {
    paddingTop: 10,
    width: 250,
  },
  pagination: {
    marginLeft: "auto",
    "& > div": {
      padding: "0 !important",
      marginLeft: "auto",
      marginTop: 8,
      justifyContent: "flex-end",
    },
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    backgroundColor: "rgba(169, 169, 169, 0.1)",
    "& > *": {
      color: "rgb(169, 169, 169)",
    },
  },
  error: {
    color: "#D42600",
  },
  cellMessage: {
    borderBottom: 0,
  },
  sectionSpacing: {
    padding: "8px 24px",
  },
}));

const SelectSearchPBTable = ({
  assignTo = "",
  selfInspectionValidityDate = "",
  taskType = "",
  options = [],
  selected = [],
  searchKeyword,
  isDiasbledConfirm = false,
  onSelect,
  onSearchKeywordChange,
  onCloseSelectDialog,
  onClickConfirm,
}) => {
  const classes = useStyles();

  const isAssignedToSupplier = assignTo === SUPPLIER;

  const [filteredPbOptions, setFilteredPbOptions] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 20,
    totalPages: 1,
    totalRecords: null,
  });

  useEffect(() => {
    if (!options) return;

    setFilteredPbOptions(getFilteredOptions(options));
  }, [options]);

  useEffect(() => {
    if (!filteredPbOptions) return;

    setPageData(getPaginatedData(filteredPbOptions));
  }, [pagination.rowsPerPage, pagination.currentPage, filteredPbOptions]);

  useEffect(() => {
    setPagination((state) => ({
      ...state,
      totalRecords: filteredPbOptions.length,
      totalPages: getPbTotalPage(filteredPbOptions, pagination.rowsPerPage),
    }));
  }, [filteredPbOptions]);

  const getFilteredOptions = (data) => {
    return data.filter((item) =>
      isTaskSPUD(taskType)
        ? isValidPbStatusForSPU(item)
        : isTaskFRID(taskType) && isAssignedToSupplier
        ? isValidPbStatusAndImportedDateForFRI(item, selfInspectionValidityDate)
        : isTaskFRID(taskType)
        ? isValidPbStatusForFRI(item)
        : options,
    );
  };

  const getPaginatedData = (data) => {
    const startIndex = pagination.currentPage * pagination.rowsPerPage - pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setPagination((state) => ({ ...state, currentPage: newPage }));
  };

  const handleRowsPerPageChange = (evt) => {
    const { value } = evt.target;

    setPagination((state) => ({ ...state, rowsPerPage: value }));
  };

  const getPbTotalPage = (data, rowsPerPage) => {
    return Math.ceil(data.length / rowsPerPage);
  };

  return (
    <>
      <MuiDialogTitle className={classes.dialogHeader}>
        <Typography className={classes.dialogTitle}>Select available PB for Submission</Typography>
      </MuiDialogTitle>

      <div className={classes.sectionSpacing}>
        <Box className={classes.dialogMessage}>List shown below are PB of involved WIC Item.</Box>
        <Box className={classes.dialogSearchContainer}>
          <Search name="searchKeyword" value={searchKeyword} handleChange={onSearchKeywordChange} />
        </Box>
      </div>

      <MuiDialogContent className={classes.dialogContent}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} />
                <TableCell className={classes.tableHeaderCell}>
                  PB Number [Production batch line ID]
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>PB Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.length > 0 ? (
                pageData.map((task, index) => {
                  const pbIdentity = `${task.pbNumber} [ ${task.pbLineId || "N/A"} ]`;

                  const isPbSelected = selected.indexOf(task.id) !== -1;

                  const isPbNotSelectedAndNotExisted = !isPbSelected && isPbNotExisted(task);

                  return (
                    <TableRow
                      key={task.id}
                      className={classnames(classes.tableRow, {
                        [classes.disabled]: isPbNotSelectedAndNotExisted,
                      })}
                      onClick={() => (isPbNotSelectedAndNotExisted ? null : onSelect(task))}
                    >
                      <TableCell
                        scope="row"
                        align="center"
                        className={classnames(classes.tableCell)}
                      >
                        {selected.indexOf(task.id) >= 0 ? (
                          <CheckBoxIcon
                            className={classnames(classes.checkBoxIcon, {
                              [classes.checkBoxIconDisabled]: isPbNotSelectedAndNotExisted,
                            })}
                          />
                        ) : (
                          <CheckBoxOutlineBlankIcon className={classes.checkBoxOutlineBlankIcon} />
                        )}
                      </TableCell>

                      <TableCell scope="row" align="left" className={classes.tableCell}>
                        <Box className={classes.tableText}>
                          {pbIdentity}
                          {isPbNotExisted(task) && (
                            <>
                              {" "}
                              <span className={classes.error}>(Cancelled)</span>
                            </>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell scope="row" align="left" className={classes.tableCell}>
                        <Box className={classes.tableText}>{task.pbQuantity}</Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3} className={classes.cellMessage}>
                    No available PB found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MuiDialogContent>

      <Pagination
        className={classnames(classes.pagination, classes.sectionSpacing)}
        currentPage={pagination.currentPage}
        totalRecords={pagination.totalRecords}
        rowsPerPage={pagination.rowsPerPage}
        totalPages={pagination.totalPages}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      <MuiDialogActions>
        <Button onClick={onCloseSelectDialog} color="primary">
          CANCEL
        </Button>

        <Button
          onClick={onClickConfirm}
          color="primary"
          variant="contained"
          disabled={!options.length || isDiasbledConfirm}
        >
          CONTINUE
        </Button>
      </MuiDialogActions>
    </>
  );
};

export default memo(SelectSearchPBTable);
