import React from "react";
import classnames from "classnames";
import _get from "lodash/get";
import { TASK_STATUS } from "shared/constants";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  circle: {
    width: 7,
    height: 7,
    borderRadius: "50%",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  grey: {
    backgroundColor: "#CCCCCC",
  },
  blue: {
    backgroundColor: "#196EFF",
  },
  yellow: {
    backgroundColor: "#FFD12E",
  },
  green: {
    backgroundColor: "#1BB135",
  },
  red: {
    backgroundColor: "#D42600",
  },
}));

const TrafficLight = ({ status, indicator }) => {
  const classes = useStyles();

  return (
    <Box
      className={classnames(
        classes.circle,
        classes[_get(TASK_STATUS[status ? status : indicator], "color") || "transparent"],
      )}
    />
  );
};

export default TrafficLight;
