import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        body1: "span",
        body2: "span",
      },
    },
  },
});

export default theme;
