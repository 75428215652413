/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Table1506 from "./Table1506.js";
import BasicInformationTable from "./BasicInformationTable.js";
import SpendingTable from "./SpendingTable.js";

const useStyles = makeStyles((theme) => ({
  wrapperGroupTable: {
    marginLeft: 30,
    marginRight: 30,
  },

  groupTabHeading: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    margin: 0,
    paddingLeft: 0,
  },

  wrapperTab: {
    width: "calc(100% + 60px)",
    alignItems: "center",
    display: "flex",
    background:
      "linear-gradient(0deg, #FAFAFA, #FAFAFA), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #aaaaaa",
    marginLeft: -30,
    marginRight: -30,
    borderRadius: 0,
  },

  tabHeader: {
    minHeight: 40,
    height: 40,
    marginLeft: 25,
  },

  tab: {
    fontSize: "10px",
    color: "#777777",
    padding: "14px 30px",
    textDecoration: "none",
    textTransform: "capitalize",
    fontWeight: 500,
    minHeight: 40,
    height: 40,
    minWidth: "initial",

    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "#003DA5",
    },

    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },

    "& svg": {
      marginRight: 12,
      marginBottom: "0 !important",
    },
  },

  tableContainer: {
    marginTop: 40,
  },

  fixedTabTable: {
    position: "fixed",
    width: "100%",
    background: "#fafafa",
    zIndex: 99,
  },
}));

const ListTable = ({
  basicTableData,
  spendingTableData,
  selectedTabList = 0,
  tableDataWic = [],
  tableDataSupplier = [],
  tableDataFactory = [],
}) => {
  const classes = useStyles();

  const [tab, setTab] = useState(selectedTabList);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box className={classes.wrapperGroupTable}>
      <Box display="block" overflow="hidden">
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          height="40px"
          className={classes.fixedTabTable}
        >
          <Paper className={classes.wrapperTab}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabHeader}
            >
              <Tab label="1506%" className={classes.tab} />
              <Tab label="Basic Information" className={classes.tab} />
              <Tab label="Spending" className={classes.tab} />
            </Tabs>
          </Paper>
        </Box>

        <Box className={classes.tableContainer}>
          {tab === 0 && (
            <Table1506
              tableDataWic={tableDataWic}
              tableDataSupplier={tableDataSupplier}
              tableDataFactory={tableDataFactory}
            />
          )}
          {tab === 1 && <BasicInformationTable tableData={basicTableData} />}
          {tab === 2 && <SpendingTable tableData={spendingTableData} />}
        </Box>
      </Box>
    </Box>
  );
};

export default ListTable;
