import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';

function replaceItemInArrayByIndex(arr, item, attrKey) {
  if (_isEmpty(arr) || _isEmpty(item)) return arr;

  const newArr = [...arr];
  const index = _findIndex(newArr, { [attrKey]: item[attrKey] });
  newArr.splice(index, 1, item);

  return newArr;
}

function isExistedItemInArray(arr, item, attrKey) {
  const index = _findIndex(arr, { [attrKey]: item[attrKey] });

  return index >= 0;
}

export { replaceItemInArrayByIndex, isExistedItemInArray };
