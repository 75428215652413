import React, { memo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import { NA, NO_DATA, PB_STATUS } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  blackText: {
    color: "#000000",
  },
  tableTitle: {
    color: "#777777",
    fontSize: 10,
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    padding: 10,
    border: "1px solid #777777",
    // borderBottom: "none",
    fontSize: 10,
  },
  gridItemRight: {
    borderLeft: "none",
  },
  gridItemBottom: {
    borderBottom: "1px solid #777777",
  },
  borderBottomNone: {
    borderBottom: "none",
  },
  borderRightNone: {
    borderRight: "none",
  },
  error: {
    color: "#D42600",
  },
}));

const PbInfoTable = ({ data = [] }) => {
  const classes = useStyles();

  const { NOT_EXISTED } = PB_STATUS;

  const isPbNotExisted = ({ pbStatus = "" }) => pbStatus === NOT_EXISTED;

  return (
    <Box py={2}>
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          PB information
        </Typography>

        {data && data.length > 0 ? (
          <Box className={classes.gridContainer}>
            <Grid container>
              <Grid
                item
                xs={3}
                className={classnames(
                  classes.gridItem,
                  classes.borderBottomNone,
                  classes.borderRightNone,
                )}
              >
                <Typography className={classes.tableTitle}>
                  PB Number [Production batch line ID]
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={classnames(
                  classes.gridItem,
                  classes.borderBottomNone,
                  classes.borderRightNone,
                )}
              >
                <Typography className={classes.tableTitle}>PB Quantity</Typography>
              </Grid>
              <Grid item xs className={classnames(classes.gridItem, classes.borderBottomNone)}>
                <Typography className={classes.tableTitle}>Case Pack</Typography>
              </Grid>
            </Grid>

            {data.map((item, index) => {
              const isLastRow = data.length - 1 === index;

              const pbIdentity = `${item.pbNumber} [ ${item.pbLineId || "N/A"} ]`;

              return (
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    className={classnames(classes.gridItem, classes.borderRightNone, {
                      [classes.borderBottomNone]: !isLastRow,
                    })}
                  >
                    {pbIdentity}
                    {isPbNotExisted(item) && (
                      <>
                        {" "}
                        <span className={classes.error}>(Cancelled)</span>
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs
                    className={classnames(classes.gridItem, classes.borderRightNone, {
                      [classes.borderBottomNone]: !isLastRow,
                    })}
                  >
                    {item?.pbQuantity || NA}
                  </Grid>
                  <Grid
                    item
                    xs
                    className={classnames(classes.gridItem, {
                      [classes.borderBottomNone]: !isLastRow,
                    })}
                  >
                    {item?.casePack || NA}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        ) : (
          NO_DATA
        )}
      </Box>
    </Box>
  );
};

export default memo(PbInfoTable);
