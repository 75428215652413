import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ItemActionTable from "./ItemActionTable";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 10,
    width: "fit-content",
    paddingRight: 30,
  },
}));

const Items = ({ itemData = {}, handleActionClick }) => {
  const classes = useStyles();

  const handleOpenItem = ({ id }) => {
    window.open(`/supplier/items/${id}`);
  };

  return (
    <Box display="flex" className={classes.tableContainer}>
      <ItemActionTable
        type={itemData.type}
        tableTitle={itemData.tableTitle}
        totalNumber={itemData.totalNumber}
        rowData={itemData.rowData}
        handleRowClick={handleOpenItem}
        handleActionClick={handleActionClick}
      />
    </Box>
  );
};

export default Items;
