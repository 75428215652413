import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import DatePicker from "components/input/DatePicker";
import MultipleSelect from "components/input/MultipleSelect";
import Select from "components/input/Select";
import Upload from "components/input/Upload";

import { LAB_REPORT_UPLOAD_RESULTS, PB_STATUS } from "shared/constants";

import { getPbLabel, isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 20,
    width: "100%",
  },
  uploadButton: {
    width: "100%",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
}));

const UploadReportAndResult = ({
  updateStatusData = {},
  handleAddNewFilesChange,
  handleReportDataChange,
  handleRemoveFile,
  handleSubmit,
  isDisabledConformButton,
  triggeredBy = "",
}) => {
  const classes = useStyles();

  const { NOT_EXISTED } = PB_STATUS;

  const [pbs, setPbs] = useState([]);
  const [pbOptions, setPbOptions] = useState([]);

  const isProgram777 = isShowProgram777(triggeredBy);

  useEffect(() => {
    if (isProgram777 && updateStatusData.selectedTasks && updateStatusData.selectedTasks[0]) {
      let tempPb = [];

      for (let i = 0; i < updateStatusData.selectedTasks[0].pb.length; i++) {
        //filter out Not Existed PB
        const isPbStatusNotExisted =
          updateStatusData.selectedTasks[0].pb[i].pbStatus !== NOT_EXISTED;

        if (isPbStatusNotExisted) {
          tempPb.push({
            value: updateStatusData.selectedTasks[0].pb[i].id,
            label: getPbLabel(updateStatusData.selectedTasks[0].pb[i]),
          });
        }
      }

      setPbOptions(tempPb);
      setPbs(updateStatusData.selectedTasks[0].pb);
    }
  }, [updateStatusData.selectedTasks]);

  const handleUploadReport = (newFiles) => {
    let files = [];

    for (let i = 0; i < newFiles.length; i++) {
      files.push({
        fileId: newFiles[i].id,
        name: newFiles[i].name,
        pb: [],
        result: "",
        issueDate: "",
      });
    }

    handleAddNewFilesChange(files);
  };

  const handlePbsChange = (e, index) => {
    const { value } = e.target;

    const pbData = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < pbs.length; j++) {
        if (value[i] === pbs[j].id) {
          pbData.push({
            ...pbs[j],
            value: pbs[j].id,
            label: pbs[j].pbNumber,
          });
        }
      }
    }

    handleReportDataChange(
      {
        target: {
          name: "pb",
          value: pbData,
        },
      },
      index,
    );
  };

  const handleSubmitClick = () => {
    if (!updateStatusData.reports || updateStatusData.reports.length === 0) {
      return alert("please upload report");
    }

    let hasEmptyData = false;

    for (let i = 0; i < updateStatusData.reports.length; i++) {
      if (!updateStatusData.reports[i].result || !updateStatusData.reports[i].issueDate) {
        hasEmptyData = true;
        break;
      }

      if (isProgram777 && !updateStatusData.reports[i]?.pb?.length > 0) {
        hasEmptyData = true;
        break;
      }
    }

    if (hasEmptyData) {
      return alert("please input fields");
    }

    handleSubmit();
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Confirm Inspection Booking</Box>

      <Paper className={classes.container}>
        <Box className={classes.title}>Reports & Results</Box>

        {isProgram777 ? (
          <>
            <Box className={classes.subtitleSection}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  Report Documents
                </Grid>
                <Grid item xs={3}>
                  PB Information
                </Grid>
                <Grid item xs={2}>
                  Result
                </Grid>
                <Grid item xs={3}>
                  Issue Date
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Box>

            <Divider />

            {updateStatusData.reports.map((item, index) => (
              <Grid key={item.fileId} container spacing={3} className={classes.rowContainer}>
                <Grid item xs={3}>
                  {item.name}
                </Grid>
                <Grid item xs={3}>
                  <MultipleSelect
                    label="Choose PB"
                    name="pb"
                    value={item.pb || []}
                    options={pbOptions}
                    handleChange={(e) => handlePbsChange(e, index)}
                    additionalClass={classes.pbSelectField}
                    formControlClass={classes.inputFormControl}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Select
                    label="Result"
                    name="result"
                    value={item.result}
                    options={LAB_REPORT_UPLOAD_RESULTS}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    name="issueDate"
                    value={item.issueDate}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemoveFile(index)} className={classes.deleteBtn}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Box className={classes.subtitleSection}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  Report Documents
                </Grid>
                <Grid item xs={3}>
                  Result
                </Grid>
                <Grid item xs={3}>
                  Issue Date
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Box>

            <Divider />

            {updateStatusData.reports.map((item, index) => (
              <Grid key={item.fileId} container spacing={3} className={classes.rowContainer}>
                <Grid item xs={5}>
                  {item.name}
                </Grid>
                <Grid item xs={3}>
                  <Select
                    label="Result"
                    name="result"
                    value={item.result}
                    options={LAB_REPORT_UPLOAD_RESULTS}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    name="issueDate"
                    value={item.issueDate}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemoveFile(index)} className={classes.deleteBtn}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        )}

        <Box className={classes.addButtonContainer}>
          <Upload
            buttonLabel="UPLOAD REPORT(S)"
            handleAddFiles={handleUploadReport}
            className={classes.uploadButton}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={isDisabledConformButton}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default UploadReportAndResult;
