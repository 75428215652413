import React, { Fragment } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Header from "../containers/layout/Header/index";
import MainWrapper from "./MainWrapper";
import history from "../helpers/history";

import ProtectedRoute from "./ProtectedRoute";
import CommonRoute from "./CommonRoute";

// common route
import Login from "../pages/login/index";
import NotFound from "../pages/NotFound";
import ResetPassword from "../pages/resetPassword/index";
import ResetPasswordWithToken from "../pages/resetPassword/ResetPasswordWithToken";
import HackAccount from "../pages/login/HackAccount";
import FileDownloadPage from "../pages/FileDownloadPage";
import UploadResumeReport from "../pages/common/UploadResumeReport";

// buyer route
import BuyerHomePage from "../pages/buyer/Home/index";
import BuyerItemList from "../pages/buyer/ItemList/index";
import PO from "../pages/buyer/PO/index";
import Analytics from "../pages/buyer/Analytics/index";
import CreateItem from "../pages/buyer/CreateItem/index";
import BuyerItemDetail from "../pages/buyer/ItemDetail";
import BuyerEditItem from "../pages/buyer/EditItem";

// buyer sourcing route
import BuyerSourcingHomePage from "../pages/buyerSourcing/Home/index";
import BuyerSourcingItemList from "../pages/buyerSourcing/ItemList/index";
import BuyerSourcingPO from "../pages/buyerSourcing/PO/index";
import BuyerSourcingItemDetail from "../pages/buyerSourcing/ItemDetail";

// supplier route
import SupplierHomePage from "../pages/supplier/Home/index";
import SupplierItemList from "../pages/supplier/ItemList/index";
import POSuplier from "../pages/supplier/PO/index";
import SupplierItemDetail from "../pages/supplier/ItemDetail";
import SupplierSubmissionList from "../pages/supplier/SubmissionList/index";
import SupplierSubmissionDetail from "../pages/supplier/SubmissionDetail";
import AcknowledgeItem from "../pages/supplier/Workflow/AcknowledgeItem";
import OpenTask from "../pages/supplier/Workflow/OpenTask";
import EditSubmission from "../pages/supplier/Workflow/EditSubmission";
import ConfirmQuote from "../pages/ConfirmQuote";
import UploadCAP from "../pages/supplier/Workflow/UploadCAP/index";
import VideoPage from "../pages/supplier/Video/index";
import EditPayerInfo from "../pages/supplier/Workflow/EditPayerInfo";

// lab route
import LabHomePage from "../pages/lab/Home/index";
import LabSubmissionList from "../pages/lab/SubmissionList/index";
import LabSubmissionDetail from "../pages/lab/SubmissionDetail";
import LabUpdateStatusOld from "../pages/lab/UpdateStatus_old";
import LabUpdateStatus from "../pages/lab/UpdateStatus";
import LabUpdateCost from "../pages/lab/UpdateCost";
import ResumeInvoice from "../pages/lab/ResumeInvoice";
import LabUpdateSteps from "../pages/lab/UpdateSteps";
import LabQuotation from "../pages/lab/Quotation";
import LabUploadRevisedReport from "../pages/lab/UploadRevisedReport/index";
import LabNewInvoice from "../pages/lab/NewInvoice/index";

// admin route
import AdminSubmissionDetail from "../pages/admin/SubmissionDetail";

// lab admin
import LabAdminHomePage from "../pages/labAdmin/Home/index";
import LabAdminSubmissionList from "../pages/labAdmin/SubmissionList/index";
import LabAdminActivityLog from "../pages/labAdmin/ActivityLog/index";
import LabAdminSubmissionDetail from "../pages/labAdmin/SubmissionDetail";

// admin
import AdminHome from "../pages/admin/Home/index";
import AdminBuyerList from "../pages/admin/BuyerList/index";
import AdminSupplierList from "../pages/admin/SupplierList/index";
import AdminLabList from "../pages/admin/LabList/index";

const AppRouter = () => {
  return (
    <MainWrapper>
      <main>
        <BrowserRouter history={history}>
          <Fragment>
            <Header />
            <Box style={{ marginTop: 40 }}>
              <Switch>
                <Route exact path="/404" component={NotFound} />
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/resetPassword" component={ResetPassword} />
                <Route exact path="/resetPassword/:token" component={ResetPasswordWithToken} />
                <Route exact path="/hackAccount/:role/:token" component={HackAccount} />
                <Route exact path="/hackAccount/:role/:token/:useremail" component={HackAccount} />

                <CommonRoute exact path="/file/:token/:name" component={FileDownloadPage} />

                <ProtectedRoute exact path="/buyer" role="buyer">
                  <Redirect to="/buyer/home" />
                </ProtectedRoute>
                <ProtectedRoute exact path="/buyer/home" component={BuyerHomePage} role="buyer" />
                <ProtectedRoute exact path="/buyer/items" component={BuyerItemList} role="buyer" />
                <ProtectedRoute exact path="/buyer/po" component={PO} role="buyer" />
                <ProtectedRoute exact path="/buyer/analytics" component={Analytics} role="buyer" />
                <ProtectedRoute
                  exact
                  path="/buyer/createItem"
                  component={CreateItem}
                  role="buyer"
                />
                <ProtectedRoute
                  exact
                  path="/buyer/items/:id"
                  component={BuyerItemDetail}
                  role="buyer"
                />
                <ProtectedRoute
                  exact
                  path="/buyer/editItem/:id"
                  component={BuyerEditItem}
                  role="buyer"
                />

                <ProtectedRoute exact path="/buyer_sourcing" role="buyer_sourcing">
                  <Redirect to="/buyer_sourcing/home" />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/buyer_sourcing/home"
                  component={BuyerSourcingHomePage}
                  role="buyer_sourcing"
                />
                <ProtectedRoute
                  exact
                  path="/buyer_sourcing/items"
                  component={BuyerSourcingItemList}
                  role="buyer_sourcing"
                />
                <ProtectedRoute
                  exact
                  path="/buyer_sourcing/po"
                  component={BuyerSourcingPO}
                  role="buyer_sourcing"
                />
                <ProtectedRoute
                  exact
                  path="/buyer_sourcing/items/:id"
                  component={BuyerSourcingItemDetail}
                  role="buyer_sourcing"
                />

                <ProtectedRoute exact path="/supplier" role="supplier">
                  <Redirect to="/supplier/home" />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/supplier/home"
                  component={SupplierHomePage}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/items"
                  component={SupplierItemList}
                  role="supplier"
                />
                <ProtectedRoute exact path="/supplier/po" component={POSuplier} role="supplier" />
                <ProtectedRoute
                  exact
                  path="/supplier/items/:id"
                  component={SupplierItemDetail}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions"
                  component={SupplierSubmissionList}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:submissionId/edit"
                  component={EditSubmission}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:submissionId/confirmQuote/:id"
                  component={ConfirmQuote}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:id"
                  component={SupplierSubmissionDetail}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/acknowledgeItem/:itemId"
                  component={AcknowledgeItem}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/openTask/:taskId"
                  component={OpenTask}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:submissionId/task/:taskId/uploadCAP"
                  component={UploadCAP}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/video"
                  component={VideoPage}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:submissionId/resume/report"
                  component={UploadResumeReport}
                  key={"supplier"}
                  role="supplier"
                />
                <ProtectedRoute
                  exact
                  path="/supplier/submissions/:submissionId/edit/:taskId/payerInfo"
                  component={EditPayerInfo}
                  key={"supplier"}
                  role="supplier"
                />

                <ProtectedRoute exact path="/lab" role="lab">
                  <Redirect to="/lab/home" />
                </ProtectedRoute>
                <ProtectedRoute exact path="/lab/home" component={LabHomePage} role="lab" />
                <ProtectedRoute
                  exact
                  path="/lab/items/:id"
                  component={LabSubmissionDetail}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/quotation"
                  component={LabQuotation}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions"
                  component={LabSubmissionList}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:id"
                  component={LabSubmissionDetail}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/admin/lab/submissions/:id"
                  component={AdminSubmissionDetail}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:id/update/status_old"
                  component={LabUpdateStatusOld}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/update/cost"
                  component={LabUpdateCost}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/invoice/resume/:invoiceId"
                  component={ResumeInvoice}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:id/update/:type/steps"
                  component={LabUpdateSteps}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/update/status"
                  component={LabUpdateStatus}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/revisedReport"
                  component={LabUploadRevisedReport}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/newInvoice"
                  component={LabNewInvoice}
                  role="lab"
                />
                <ProtectedRoute
                  exact
                  path="/lab/submissions/:submissionId/resume/report"
                  component={UploadResumeReport}
                  key={"lab"}
                  role="lab"
                />

                <ProtectedRoute exact path="/lab_admin" role="lab_admin">
                  <Redirect to="/lab_admin/home" />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/lab_admin/home"
                  component={LabAdminHomePage}
                  role="lab_admin"
                />
                <ProtectedRoute
                  exact
                  path="/lab_admin/submissions"
                  component={LabAdminSubmissionList}
                  role="lab_admin"
                />
                <ProtectedRoute
                  exact
                  path="/lab_admin/submissions/:submissionId"
                  component={LabAdminSubmissionDetail}
                  role="lab_admin"
                />
                <ProtectedRoute
                  exact
                  path="/lab_admin/activity_log"
                  component={LabAdminActivityLog}
                  role="lab_admin"
                />

                <ProtectedRoute exact path="/admin/home" component={AdminHome} role="admin">
                  <Redirect to="/admin/buyers" />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/admin/buyers"
                  component={AdminBuyerList}
                  role="admin"
                />
                <ProtectedRoute
                  exact
                  path="/admin/suppliers"
                  component={AdminSupplierList}
                  role="admin"
                />
                <ProtectedRoute exact path="/admin/labs" component={AdminLabList} role="admin" />

                <ProtectedRoute exact component={NotFound} />
              </Switch>
            </Box>
          </Fragment>
        </BrowserRouter>
      </main>
    </MainWrapper>
  );
};

export default AppRouter;
