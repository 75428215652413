import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import SelectTask from "../components/SelectTask";
import TaskDetail from "./TaskDetail";
import SelectLab from "../components/SelectLab";
import PayerDetail from "../../components/PayerDetail";
import UploadReportAndResult from "./UploadReportAndResult";

import { atnGetOpenTaskLabs } from "actions/taskActions";
import { atnEditSubmission } from "actions/submissionActions";
import { atnDeleteFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const EditInspectionTask = ({
  history,
  submissionId,
  assignTo,
  basicData = {},
  submissionTasks = [],
  relatedTasks = [],
  billingAddress = {},
  inspectionTaskDetail = {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleData =
    assignTo === "supplier"
      ? ["Enter Task Detail", "Report and Result"]
      : [
          "Select Task(s) for Share Cost",
          "Enter Task Detail",
          "Select Lab for Quotation",
          "Enter Payer Detail for Invoice",
        ];

  const [step, setStep] = useState(0);
  const [filteredRelatedTasks, setFilteredRelatedTasks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [originalSubmissionData, setOriginalSubmissionData] = useState({
    tasks: JSON.parse(JSON.stringify(submissionTasks)),
    inspectionTaskDetail: JSON.parse(JSON.stringify(inspectionTaskDetail)),
    masterWics: [],
    labIds: JSON.parse(JSON.stringify(basicData.labIds)),
    billingAddress: JSON.parse(JSON.stringify(billingAddress)),
  });
  const [editSubmissionData, setEditSubmissionData] = useState({
    tasks: submissionTasks,
    inspectionTaskDetail: inspectionTaskDetail,
    masterWics: [],
    labIds: basicData.labIds,
    isSkipQuote: false,
    billingAddress: billingAddress,
    reports: [],
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);
  const [deletedPo, setDeletedPo] = useState([]);

  const [payerDetailErrors, setPayerDetailErrors] = useState([]);

  // for open task with tendering program
  // update handleGetLabs func with state
  const [tenderingMatchedLabs, setTenderingMatchedLabs] = useState([]);

  const masterWicFileList = [
    "product_specification",
    "artworks",
    "ais_stibo_upc_information",
    "ais_stibo_outer_inner",
    "ais_stibo_factory_information",
    "e2open",
  ];

  const handleTenderingMatchedLab = (matchedTenderingLabs) => {
    setTenderingMatchedLabs(matchedTenderingLabs);
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = relatedTasks.filter(
        (item, i) =>
          item.wicNumber.indexOf(value) >= 0 ||
          item.taskNumber.indexOf(value) >= 0 ||
          item.productName.indexOf(value) >= 0,
      );

      setFilteredRelatedTasks(tempTasks);
    } else {
      setFilteredRelatedTasks(relatedTasks);
    }
  };

  const handleDeletePo = (po) => {
    setDeletedPo((prevState) => {
      return prevState.concat(po);
    });
  };

  const handleEditSubmissionDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setEditSubmissionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabs = (data) => {
    atnGetOpenTaskLabs(data).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setEditSubmissionData((prevState) => ({
        ...prevState,
        labIds: basicData.labIds,
      }));
    });
  };

  useEffect(() => {
    const stringifyTenderingLabs = !!tenderingMatchedLabs?.length
      ? tenderingMatchedLabs?.toString()
      : "";

    handleGetLabs({
      taskType: basicData.taskType,
      buyerProgramId: basicData.buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleConfirmTaskSelect = (stayCurrentPage = false) => {
    let oldMasterWics = editSubmissionData.masterWics;

    if (oldMasterWics.length > 0) {
      let masterWicIds = []; // 新data所有 wic id
      let newMasterWicWithTasks = []; // 新data所有 wic with task

      for (const submission of editSubmissionData.tasks) {
        if (masterWicIds.indexOf(submission.tasks.masterWicId) === -1) {
          masterWicIds.push(submission.tasks.masterWicId);
          newMasterWicWithTasks.push({
            masterWicId: submission.tasks.masterWicId,
            wicNumber: submission.tasks.wicNumber,
            productName: submission.tasks.productName,
            tasks: [
              {
                taskType: submission.tasks.taskType,
              },
            ],
            masterWicFiles: submission.tasks.masterWicFiles,
            firstShipmentTotalQuantity: submission.tasks.firstShipmentTotalQuantity,
            fullPoTotalQuantity: submission.tasks.fullPoTotalQuantity,
            po: submission.tasks.po || submission.tasks.dailyPo,
            pb: submission.tasks.pb || [],
            isProgram777: submission.tasks.isProgram777 || false,
            triggeredBy: submission.tasks.triggeredBy || "",
            buyerCompanyName: submission.tasks.buyerCompanyName,
            category: submission.tasks.category,
            programName: submission.tasks.programName,
            selfInspectionValidityDate: submission.tasks.selfInspectionValidityDate,
            assignTo: submission.tasks.assignTo,
          });
        } else {
          for (const newMasterWic of newMasterWicWithTasks) {
            if (newMasterWic.masterWicId === submission.tasks.masterWicId) {
              newMasterWic.tasks.push({
                taskType: submission.tasks.taskType,
              });
              break;
            }
          }
        }
      }

      let newMasterWics = []; // 舊data已經有的master wic update相關task
      let newMasterWicIds = []; // 舊data已經有的master wic update相關task

      for (const oldMasterWic of oldMasterWics) {
        if (masterWicIds.indexOf(oldMasterWic.masterWicId) >= 0) {
          for (const newMasterWicWithTask of newMasterWicWithTasks) {
            if (newMasterWicWithTask.masterWicId === oldMasterWic.masterWicId) {
              oldMasterWic.tasks = newMasterWicWithTask.tasks;
              break;
            }
          }

          newMasterWics.push(oldMasterWic);
          newMasterWicIds.push(oldMasterWic.masterWicId);
        }
      }

      // 加新的master wic
      let productSpecification = [];
      let artworks = [];
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (let i = 0; i < newMasterWicWithTasks.length; i++) {
        if (newMasterWicIds.indexOf(newMasterWicWithTasks[i].masterWicId) === -1) {
          productSpecification = [];
          artworks = [];
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (let j = 0; j < newMasterWicWithTasks[i].masterWicFiles.length; j++) {
            const isMaterWicType = (type) =>
              type === newMasterWicWithTasks[i].masterWicFiles[j].type;

            const dataObj = {
              id: newMasterWicWithTasks[i].masterWicFiles[j].id,
              name: newMasterWicWithTasks[i].masterWicFiles[j].fileName,
            };

            if (isMaterWicType("product_specification")) {
              productSpecification.push(dataObj);
            } else if (isMaterWicType("artworks")) {
              artworks.push(dataObj);
            } else if (isMaterWicType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMaterWicType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMaterWicType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMaterWicType("e2open")) {
              e2open.push(dataObj);
            }
          }

          newMasterWics.push({
            expanded: false,
            masterWicId: newMasterWicWithTasks[i].masterWicId,
            wicNumber: newMasterWicWithTasks[i].wicNumber,
            productName: newMasterWicWithTasks[i].productName,
            tasks: newMasterWicWithTasks[i].tasks,
            productSpecification: productSpecification,
            artworks: artworks,
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation: aisFactoryInformation,
            firstShipmentTotalQuantity: newMasterWicWithTasks[i].firstShipmentTotalQuantity,
            fullPoTotalQuantity: newMasterWicWithTasks[i].fullPoTotalQuantity,
            po: newMasterWicWithTasks[i].po,
            pb: newMasterWicWithTasks[i].pb || [],
            isProgram777: newMasterWicWithTasks[i].isProgram777 || false,
            triggeredBy: newMasterWicWithTasks[i].triggeredBy || "",
            e2open: e2open,
            buyerCompanyName: newMasterWicWithTasks[i].buyerCompanyName,
            category: newMasterWicWithTasks[i].category,
            programName: newMasterWicWithTasks[i].programName,
            selfInspectionValidityDate: newMasterWicWithTasks[i].selfInspectionValidityDate,
            assignTo: newMasterWicWithTasks[i].assignTo,
          });
        }
      }

      setOriginalSubmissionData((prevState) => ({
        ...prevState,
        masterWics: JSON.parse(JSON.stringify(newMasterWics)),
      }));

      setEditSubmissionData((prevState) => ({
        ...prevState,
        masterWics: newMasterWics,
      }));
    } else {
      let masterWicIds = [];
      let masterWics = [];
      let productSpecification = [];
      let artworks = [];
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (let i = 0; i < editSubmissionData.tasks.length; i++) {
        if (masterWicIds.indexOf(editSubmissionData.tasks[i].masterWicId) === -1) {
          productSpecification = [];
          artworks = [];
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (let j = 0; j < (editSubmissionData.tasks[i].masterWicFiles || []).length; j++) {
            const isMaterWicType = (type) =>
              type === editSubmissionData.tasks[i].masterWicFiles[j].type;

            const dataObj = {
              id: editSubmissionData.tasks[i].masterWicFiles[j].id,
              name: editSubmissionData.tasks[i].masterWicFiles[j].fileName,
            };

            if (isMaterWicType("product_specification")) {
              productSpecification.push(dataObj);
            } else if (isMaterWicType("artworks")) {
              artworks.push(dataObj);
            } else if (isMaterWicType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMaterWicType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMaterWicType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMaterWicType("e2open")) {
              e2open.push(dataObj);
            }
          }

          masterWicIds.push(editSubmissionData.tasks[i].masterWicId);
          masterWics.push({
            expanded: false,
            masterWicId: editSubmissionData.tasks[i].masterWicId,
            wicNumber: editSubmissionData.tasks[i].wicNumber,
            productName: editSubmissionData.tasks[i].productName,
            tasks: [
              {
                taskType: editSubmissionData.tasks[i].taskType,
              },
            ],
            productSpecification: productSpecification,
            artworks: artworks,
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation: aisFactoryInformation,
            firstShipmentTotalQuantity: editSubmissionData.tasks[i].firstShipmentTotalQuantity,
            fullPoTotalQuantity: editSubmissionData.tasks[i].fullPoTotalQuantity,
            po: editSubmissionData.tasks[i].po || editSubmissionData.tasks[i].dailyPo,
            pb: editSubmissionData.tasks[i].pb || [],
            isProgram777: editSubmissionData.tasks[i].isProgram777 || false,
            triggeredBy: editSubmissionData.tasks[i].triggeredBy || "",
            e2open: e2open,
            buyerCompanyName: editSubmissionData.tasks[i].buyerCompanyName,
            category: editSubmissionData.tasks[i].category,
            programName: editSubmissionData.tasks[i].programName,
            selfInspectionValidityDate: editSubmissionData.tasks[i].selfInspectionValidityDate,
            assignTo: editSubmissionData.tasks[i].assignTo,
          });
        } else {
          for (let j = 0; j < masterWics.length; j++) {
            if (masterWics[j].masterWicId === editSubmissionData.tasks[i].masterWicId) {
              masterWics[j].tasks.push({
                taskType: editSubmissionData.tasks[i].taskType,
              });
            }
          }
        }
      }

      setOriginalSubmissionData((prevState) => ({
        ...prevState,
        masterWics: JSON.parse(JSON.stringify(masterWics)),
      }));

      setEditSubmissionData((prevState) => ({
        ...prevState,
        masterWics: masterWics,
      }));
    }

    if (!stayCurrentPage) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (assignTo === "supplier") {
      handleConfirmTaskSelect(true);
    }
  }, [assignTo]);

  const handleRemoveFile = (index) => {
    setEditSubmissionData((prevState) => ({
      ...prevState,
      reports: prevState.reports.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewFilesChange = (files) => {
    setEditSubmissionData((prevState) => ({
      ...prevState,
      reports: prevState.reports.concat(files),
    }));
  };

  const handleReportDataChange = (e, index) => {
    const { name, value } = e.target;

    setEditSubmissionData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleReportPBChange = (evt, index, pbList) => {
    const { value } = evt.target;

    setEditSubmissionData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          const selected = pbList.filter((pb) => value.indexOf(pb.id) !== -1);

          item["pb"] = selected;
        }

        return item;
      }),
    }));
  };

  const getLabSubmitTasks = () => {
    let tasks = editSubmissionData.tasks;

    for (const task of tasks) {
      for (const editMasterWic of editSubmissionData.masterWics) {
        const isMatchId = editMasterWic.masterWicId === task.masterWicId;

        if (isMatchId) {
          task.firstShipmentTotalQuantity = editMasterWic.firstShipmentTotalQuantity;
          task.fullPoTotalQuantity = editMasterWic.fullPoTotalQuantity;

          if (task.category === "everyday") {
            task.dailyPo = editMasterWic.po;
            delete task.po;
          } else {
            task.po = editMasterWic.po;
            delete task.dailyPo;
          }

          //pb
          task.pb = editMasterWic.pb;

          break;
        }
      }
    }

    return tasks;
  };

  const getSubmitChanges = () => {
    let changes = [];

    if (editSubmissionData.tasks.length !== originalSubmissionData.tasks.length) {
      let oldTasks = "";
      let newTasks = "";

      for (const originalTask of originalSubmissionData.tasks) {
        oldTasks += originalTask.wicNumber + "(" + t(`task.${originalTask.taskType}`) + ")\n";
      }

      for (const editTask of editSubmissionData.tasks) {
        newTasks += editTask.wicNumber + "(" + t(`task.${editTask.taskType}`) + ")\n";
      }

      changes.push({
        field: "Share Cost Tasks",
        action: "Update",
        before: oldTasks,
        after: newTasks,
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.expectedInspectionDate !==
      originalSubmissionData.inspectionTaskDetail.expectedInspectionDate
    ) {
      changes.push({
        field: "Expected Inspection Date",
        action: "Update",
        before: originalSubmissionData.inspectionTaskDetail.expectedInspectionDate,
        after: editSubmissionData.inspectionTaskDetail.expectedInspectionDate,
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.inspectionLevel !==
      originalSubmissionData.inspectionTaskDetail.inspectionLevel
    ) {
      changes.push({
        field: "Inspection Level",
        action: "Update",
        before: t(`inspectionLevel.${originalSubmissionData.inspectionTaskDetail.inspectionLevel}`),
        after: t(`inspectionLevel.${editSubmissionData.inspectionTaskDetail.inspectionLevel}`),
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.aqlCritical !==
      originalSubmissionData.inspectionTaskDetail.aqlCritical
    ) {
      changes.push({
        field: "AQL Level (Critical)",
        action: "Update",
        before: originalSubmissionData.inspectionTaskDetail.aqlCritical,
        after: editSubmissionData.inspectionTaskDetail.aqlCritical,
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.aqlMajor !==
      originalSubmissionData.inspectionTaskDetail.aqlMajor
    ) {
      changes.push({
        field: "AQL Level (Major)",
        action: "Update",
        before: originalSubmissionData.inspectionTaskDetail.aqlMajor,
        after: editSubmissionData.inspectionTaskDetail.aqlMajor,
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.aqlMinor !==
      originalSubmissionData.inspectionTaskDetail.aqlMinor
    ) {
      changes.push({
        field: "AQL Level (Minor)",
        action: "Update",
        before: originalSubmissionData.inspectionTaskDetail.aqlMinor,
        after: editSubmissionData.inspectionTaskDetail.aqlMinor,
      });
    }

    let oldPbData = "";
    let newPbData = "";

    let oldPoData = "";
    let newPoData = "";

    let isNewMasterWic = true;

    for (let i = 0; i < editSubmissionData.masterWics.length; i++) {
      for (let j = 0; j < originalSubmissionData.masterWics.length; j++) {
        if (
          originalSubmissionData.masterWics[j].masterWicId ===
          editSubmissionData.masterWics[i].masterWicId
        ) {
          isNewMasterWic = false;

          //original submission pb
          for (let k = 0; k < originalSubmissionData.masterWics[j].pb.length; k++) {
            oldPbData += "PB Number: " + originalSubmissionData.masterWics[j].pb[k].pbNumber + "\n";
            oldPbData +=
              "PB Line Id: " + originalSubmissionData.masterWics[j].pb[k].pbLineId + "\n";
            oldPbData +=
              "PB Quantity: " + originalSubmissionData.masterWics[j].pb[k].pbQuantity + "\n";
            oldPbData += "Case Pack: " + originalSubmissionData.masterWics[j].pb[k].casePack + "\n";
            oldPbData += "PB Status: " + originalSubmissionData.masterWics[j].pb[k].pbStatus + "\n";
          }

          //edit submission pb
          for (let k = 0; k < editSubmissionData.masterWics[i].pb.length; k++) {
            newPbData += "PB Number: " + editSubmissionData.masterWics[i].pb[k].pbNumber + "\n";
            newPbData += "PB Line Id: " + editSubmissionData.masterWics[i].pb[k].pbLineId + "\n";
            newPbData += "PB Quantity: " + editSubmissionData.masterWics[i].pb[k].pbQuantity + "\n";
            newPbData += "Case Pack: " + editSubmissionData.masterWics[i].pb[k].casePack + "\n";
            newPbData += "PB Status: " + editSubmissionData.masterWics[i].pb[k].pbStatus + "\n";
          }

          //original submission po
          for (let k = 0; k < originalSubmissionData.masterWics[j].po.length; k++) {
            if (k === 0) {
              oldPoData +=
                "1st Shipment Total PO Qty: " +
                originalSubmissionData.masterWics[j].po[k].firstShipmentTotalQuantity +
                "\n";
              oldPoData +=
                "Full PO Qty: " +
                originalSubmissionData.masterWics[j].po[k].fullPoTotalQuantity +
                "\n";
            }

            oldPoData += "PO Number: " + originalSubmissionData.masterWics[j].po[k].poNumber + "\n";
            oldPoData +=
              "PO Quantity: " + originalSubmissionData.masterWics[j].po[k].poQuantity + "\n";
            oldPoData +=
              "Qty Ready for inspection: " +
              originalSubmissionData.masterWics[j].po[k].quantityReadyForInspection;
          }

          //edit submission po
          for (let k = 0; k < editSubmissionData.masterWics[i].po.length; k++) {
            if (k === 0) {
              newPoData +=
                "1st Shipment Total PO Qty: " +
                editSubmissionData.masterWics[i].po[k].firstShipmentTotalQuantity +
                "\n";
              newPoData +=
                "Full PO Qty: " + editSubmissionData.masterWics[i].po[k].fullPoTotalQuantity + "\n";
            }

            newPoData += "PO Number: " + editSubmissionData.masterWics[i].po[k].poNumber + "\n";
            newPoData += "PO Quantity: " + editSubmissionData.masterWics[i].po[k].poQuantity + "\n";
            newPoData +=
              "Qty Ready for inspection: " +
              editSubmissionData.masterWics[i].po[k].quantityReadyForInspection;
          }

          //push updated pb data
          if (oldPbData !== newPbData) {
            changes.push({
              field: "PB",
              action: "Update",
              before: oldPbData,
              after: newPbData,
            });
          }

          //push updated po data
          if (oldPoData !== newPoData) {
            changes.push({
              field: "PO",
              action: "Update",
              before: oldPoData,
              after: newPoData,
            });
          }

          break;
        }
      }

      if (isNewMasterWic) {
        //pb
        for (let k = 0; k < editSubmissionData.masterWics[i].pb.length; k++) {
          newPbData += "PB Number: " + editSubmissionData.masterWics[i].pb[k].pbNumber + "\n";

          newPbData += "PB Line Id: " + editSubmissionData.masterWics[i].pb[k].pbLineId + "\n";

          newPbData += "PB Quantity: " + editSubmissionData.masterWics[i].pb[k].pbQuantity + "\n";

          newPbData += "Case Pack: " + editSubmissionData.masterWics[i].pb[k].casePack + "\n";

          newPbData += "PB Status: " + editSubmissionData.masterWics[i].pb[k].pbStatus + "\n";
        }

        //po
        for (let k = 0; k < editSubmissionData.masterWics[i].po.length; k++) {
          if (k === 0) {
            newPoData +=
              "1st Shipment Total PO Qty: " +
              editSubmissionData.masterWics[i].po[k].firstShipmentTotalQuantity +
              "\n";
            newPoData +=
              "Full PO Qty: " + editSubmissionData.masterWics[i].po[k].fullPoTotalQuantity + "\n";
          }

          newPoData += "PO Number: " + editSubmissionData.masterWics[i].po[k].poNumber + "\n";
          newPoData += "PO Quantity: " + editSubmissionData.masterWics[i].po[k].poQuantity + "\n";
          newPoData +=
            "Qty Ready for inspection: " +
            editSubmissionData.masterWics[i].po[k].quantityReadyForInspection;
        }

        changes.push({
          field: "PB",
          action: "Update",
          before: oldPbData,
          after: newPbData,
        });

        changes.push({
          field: "PO",
          action: "Update",
          before: oldPoData,
          after: newPoData,
        });
      }
    }

    return changes;
  };

  const handleSubmit = () => {
    setPayerDetailErrors([]);
    setIsDisabledConfirmButton(true);

    let deletedFiles = [];
    let billingAddress = editSubmissionData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (editSubmissionData.billingAddress.invoiceType === "icw_company_invoice") {
      billingAddress.attachments = [];
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType !== "icw_company_invoice") {
      let newFileIds = [];
      let tempTaxInformationFiles = [];

      for (const attachment of editSubmissionData.billingAddress.attachments) {
        newFileIds.push(attachment.id);

        tempTaxInformationFiles.push({
          id: attachment.id,
          type: "tax_information",
        });
      }

      // for (let i = 0; i < editSubmissionData.billingAddress.taxInformationFiles.length; i++) {
      //   if (newFileIds.indexOf(editSubmissionData.billingAddress.taxInformationFiles[i].id) === -1) {
      //     deletedFiles.push({
      //       fileId: editSubmissionData.billingAddress.taxInformationFiles[i].id
      //     });
      //   }
      // }

      billingAddress.taxInformationFiles = tempTaxInformationFiles;
      // delete billingAddress.attachments;
    }

    let selectedLabs = [];
    let newLabs = [];
    let existingLabs = [];

    for (const labId of editSubmissionData.labIds) {
      if (basicData.labIds.indexOf(labId) === -1) {
        selectedLabs.push({
          id: labId,
          isNew: true,
        });
        newLabs.push(labId);
      } else {
        selectedLabs.push({
          id: labId,
          isNew: false,
        });
        existingLabs.push(labId);
      }
    }

    //convert masterwic files
    let masterWics = [];
    let masterWicFiles = [];

    for (const editMasterWic of editSubmissionData.masterWics) {
      masterWicFiles = [];

      for (const productSpec of editMasterWic.productSpecification) {
        masterWicFiles.push({
          id: productSpec.id,
          type: "product_specification",
        });
      }

      for (const artwork of editMasterWic.artworks) {
        masterWicFiles.push({
          id: artwork.id,
          type: "artworks",
        });
      }

      for (const upcInfo of editMasterWic.aisUPCInformation) {
        masterWicFiles.push({
          id: upcInfo.id,
          type: "ais_stibo_upc_information",
        });
      }

      for (const outerInner of editMasterWic.aisOuterInner) {
        masterWicFiles.push({
          id: outerInner.id,
          type: "ais_stibo_outer_inner",
        });
      }

      for (const factoryInfo of editMasterWic.aisFactoryInformation) {
        masterWicFiles.push({
          id: factoryInfo.id,
          type: "ais_stibo_factory_information",
        });
      }

      for (const e2o of editMasterWic.e2open) {
        masterWicFiles.push({
          id: e2o.id,
          type: "e2open",
        });
      }

      masterWics.push({
        id: editMasterWic.masterWicId,
        masterWicFiles,
      });
    }

    let newFileIds = [];

    for (let i = 0; i < masterWics.length; i++) {
      for (let j = 0; j < masterWics[i].masterWicFiles.length; j++) {
        newFileIds.push(masterWics[i].masterWicFiles[j].id);
      }
    }

    for (const editTask of editSubmissionData.tasks) {
      for (const editTaskMasterWicFile of editTask.masterWicFiles) {
        if (masterWicFileList.indexOf(editTaskMasterWicFile.type) !== -1) {
          if (newFileIds.indexOf(editTaskMasterWicFile.id) === -1) {
            deletedFiles.push({
              fileId: editTaskMasterWicFile.id,
              masterWicId: editTask.masterWicId,
            });
          }
        }
      }
    }

    const { id, expectedInspectionDate, inspectionLevel, aqlCritical, aqlMajor, aqlMinor } =
      editSubmissionData.inspectionTaskDetail;

    let submitData = {
      submissionId: submissionId,
      isSkipQuote: editSubmissionData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: getLabSubmitTasks(),
      masterWics: masterWics,
      inspectionTaskDetail: {
        id,
        expectedInspectionDate,
        inspectionLevel,
        aqlCritical,
        aqlMajor,
        aqlMinor,
      },
      deletedPo: deletedPo,
      newLabs: newLabs,
    };

    if (getSubmitChanges().length > 0) {
      submitData.changes = getSubmitChanges();
      submitData.existingLabs = existingLabs;
    }

    if (deletedFiles.length > 0) {
      let formatedDeletedFiles = [];

      for (const masterwic of masterWics) {
        formatedDeletedFiles.push({
          masterWicId: masterwic.id,
          files: [],
        });
      }

      for (const formatedDeletedFile of formatedDeletedFiles) {
        for (const deletedFile of deletedFiles) {
          if (deletedFile.masterWicId === formatedDeletedFile.masterWicId) {
            formatedDeletedFile.files.push({
              fileId: deletedFile.fileId,
            });
          }
        }
      }

      for (const deletedFile of formatedDeletedFiles) {
        if (deletedFile.files.length > 0) {
          atnDeleteFile({
            type: "masterWicFiles",
            typeId: deletedFile.masterWicId,
            data: deletedFile.files,
          });
        }
      }
    }

    atnEditSubmission(submitData).then((res) => {
      setIsDisabledConfirmButton(false);

      if (!res?.data) return setPayerDetailErrors([res?.message]);

      history.push(`/supplier/submissions/${res?.data.id}`);
    });
  };

  const handleSupplierSubmit = () => {
    setIsDisabledConfirmButton(true);

    let deletedFiles = [];
    let masterWics = [];
    let masterWicFiles = [];

    for (const editMasterWic of editSubmissionData.masterWics) {
      masterWicFiles = [];

      for (const productSpec of editMasterWic.productSpecification) {
        masterWicFiles.push({
          id: productSpec.id,
          type: "product_specification",
        });
      }

      for (const artworks of editMasterWic.artworks) {
        masterWicFiles.push({
          id: artworks.id,
          type: "artworks",
        });
      }

      for (const upcInfo of editMasterWic.aisUPCInformation) {
        masterWicFiles.push({
          id: upcInfo.id,
          type: "ais_stibo_upc_information",
        });
      }

      for (const outerInner of editMasterWic.aisOuterInner) {
        masterWicFiles.push({
          id: outerInner.id,
          type: "ais_stibo_outer_inner",
        });
      }

      for (const factoryInfo of editMasterWic.aisFactoryInformation) {
        masterWicFiles.push({
          id: factoryInfo.id,
          type: "ais_stibo_factory_information",
        });
      }

      for (const e2o of editMasterWic.e2open) {
        masterWicFiles.push({
          id: e2o.id,
          type: "e2open",
        });
      }

      masterWics.push({
        id: editMasterWic.masterWicId,
        masterWicFiles: masterWicFiles,
      });
    }

    let newFileIds = [];

    for (const masterwic of masterWics) {
      for (const masterWicFile of masterwic.masterWicFiles) {
        newFileIds.push(masterWicFile.id);
      }
    }

    for (const task of editSubmissionData.tasks) {
      for (const taskMasterWicFile of task.masterWicFiles) {
        if (masterWicFileList.indexOf(taskMasterWicFile.type) !== -1) {
          if (newFileIds.indexOf(taskMasterWicFile.id) === -1) {
            deletedFiles.push({
              fileId: taskMasterWicFile.id,
              masterWicId: task.masterWicId,
            });
          }
        }
      }
    }

    let tasks = editSubmissionData.tasks;

    for (const task of tasks) {
      for (let j = 1; j < task.po.length; j++) {
        if (!task.po[j].id) {
          task.po[j].firstShipmentTotalQuantity = task.po[0].firstShipmentTotalQuantity;
          task.po[j].fullPoTotalQuantity = task.po[0].fullPoTotalQuantity;
        }
      }
    }

    const { id, expectedInspectionDate, inspectionLevel, aqlCritical, aqlMajor, aqlMinor } =
      editSubmissionData.inspectionTaskDetail;

    let submitData = {
      submissionId: submissionId,
      tasks: tasks,
      masterWics: masterWics,
      inspectionTaskDetail: {
        id,
        expectedInspectionDate,
        inspectionLevel,
        aqlCritical,
        aqlMajor,
        aqlMinor,
      },
      deletedPo: deletedPo,
      reports: editSubmissionData.reports,
    };

    if (deletedFiles.length > 0) {
      let formatedDeletedFiles = [];

      for (const masterwic of masterWics) {
        formatedDeletedFiles.push({
          masterWicId: masterwic.id,
          files: [],
        });
      }

      for (const formatedDeletedFile of formatedDeletedFiles) {
        for (const deletedFile of deletedFiles) {
          if (deletedFile.masterWicId === formatedDeletedFile.masterWicId) {
            formatedDeletedFile.files.push({
              fileId: deletedFile.fileId,
            });
          }
        }
      }

      for (const formatedDeletedFile of formatedDeletedFiles) {
        if (formatedDeletedFile.files.length > 0) {
          atnDeleteFile({
            type: "masterWicFiles",
            typeId: formatedDeletedFile.masterWicId,
            data: formatedDeletedFile.files,
          });
        }
      }
    }

    atnEditSubmission(submitData).then(({ data, message }) => {
      if (data) {
        setIsDisabledConfirmButton(false);
        history.push(`/supplier/submissions/${data.id}`);
      } else {
        setIsDisabledConfirmButton(false);
        alert(message);
      }
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  const masterWicId = submissionTasks[0]?.masterWicId || null;
  const isProgram777 = submissionTasks[0]?.isProgram777 || false;
  const triggeredBy = submissionTasks[0]?.triggeredBy || "";

  return (
    <Box className={classes.root}>
      <Header
        step={step}
        assignTo={assignTo}
        handlePrevPage={handlePrevPage}
        handleClose={handleClose}
      />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {assignTo === "supplier" ? (
          <>
            {step === 0 && (
              <TaskDetail
                editSubmissionData={editSubmissionData}
                handleEditSubmissionDataChange={handleEditSubmissionDataChange}
                handleDeletePo={handleDeletePo}
                handleNextPage={handleNextPage}
              />
            )}

            {step === 1 && (
              <UploadReportAndResult
                masterWicId={masterWicId}
                isProgram777={isProgram777}
                triggeredBy={triggeredBy}
                editSubmissionData={editSubmissionData}
                handleAddNewFilesChange={handleAddNewFilesChange}
                handleReportDataChange={handleReportDataChange}
                handleReportPBChange={handleReportPBChange}
                handleRemoveFile={handleRemoveFile}
                handleSubmit={handleSupplierSubmit}
                isDisabledConfirmButton={isDisabledConfirmButton}
              />
            )}
          </>
        ) : (
          <>
            {step === 0 && (
              <SelectTask
                editSubmissionData={editSubmissionData}
                relatedTasks={searchKeyword ? filteredRelatedTasks : relatedTasks}
                searchKeyword={searchKeyword}
                handleSearchKeywordChange={handleSearchKeywordChange}
                basicData={basicData}
                handleEditSubmissionDataChange={handleEditSubmissionDataChange}
                handleGetLabs={handleGetLabs}
                handleNextPage={handleConfirmTaskSelect}
                handleTenderingMatchedLab={handleTenderingMatchedLab}
              />
            )}

            {step === 1 && (
              <TaskDetail
                editSubmissionData={editSubmissionData}
                handleEditSubmissionDataChange={handleEditSubmissionDataChange}
                handleDeletePo={handleDeletePo}
                handleNextPage={handleNextPage}
              />
            )}

            {step === 2 && (
              <SelectLab
                editSubmissionData={editSubmissionData}
                labs={labs}
                basicData={basicData}
                handleEditSubmissionDataChange={handleEditSubmissionDataChange}
                handleNextPage={handleNextPage}
              />
            )}

            {step === 3 && (
              <PayerDetail
                errors={payerDetailErrors}
                data={editSubmissionData}
                handleDataChange={handleEditSubmissionDataChange}
                handleSubmit={handleSubmit}
                isDisabledConfirmButton={isDisabledConfirmButton}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditInspectionTask;
