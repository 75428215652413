import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Paper } from "@material-ui/core";

import Select from "components/input/Select";
import DatePicker from "components/input/DatePicker";
import NoticeBar from "components/display/NoticeBar";

import WicDetails from "./components/WicDetails";

import locale from "shared/locale.json";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  wicDetailsWdith: {
    "& > div": {
      width: "100%",
    },
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 300,
  },
  smallInputField: {
    width: 200,
  },
  largeInputField: {
    width: 450,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  smallFieldPadding: {
    paddingTop: 20,
  },
  blockPadding: {
    paddingRight: 10,
  },
  inputPadding: {
    paddingTop: 29,
  },
  WicSectionContainer: {
    paddingTop: 20,
    display: "flex",
    position: "relateive",
  },
  expandAll: {
    cursor: "pointer",
    color: "#005EFF",
    paddingTop: 50,
    fontSize: 10,
  },
  WicContainer: {
    border: "1px solid #777777",
    padding: 20,
    position: "relative",
    marginBottom: 20,
  },
  WicTitleContainer: {
    position: "relative",
    height: 40,
  },
  WicTitle: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: 16,
    fontWeight: 500,
  },
  expandBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  tasksContainer: {
    position: "absolute",
    top: 32,
    right: 70,
  },
  task: {
    background: "#EBEDEF",
    borderRadius: 2,
    fontSize: 12,
    color: "#232B3A",
    padding: "3px 6px",
    marginRight: 10,
  },
  poDataContainer: {
    paddingBottom: 20,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const TaskDetail = ({
  editSubmissionData = {},
  handleEditSubmissionDataChange,
  handleDeletePo,
  handleNextPage,
}) => {
  const classes = useStyles();

  const inspectionLevelOptions = [
    {
      value: "I",
      label: "General Inspection Level I",
    },
    {
      value: "II",
      label: "General Inspection Level II",
    },
    {
      value: "III",
      label: "General Inspection Level III",
    },
    {
      value: "S1",
      label: "Special Inspection Level, S-1",
    },
    {
      value: "S2",
      label: "Special Inspection Level, S-2",
    },
    {
      value: "S3",
      label: "Special Inspection Level, S-3",
    },
    {
      value: "S4",
      label: "Special Inspection Level, S-4",
    },
  ];

  const aqlLevelOptions = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "0.065",
      label: "0.065",
    },
    {
      value: "0.1",
      label: "0.1",
    },
    {
      value: "0.15",
      label: "0.15",
    },
    {
      value: "0.25",
      label: "0.25",
    },
    {
      value: "0.4",
      label: "0.4",
    },
    {
      value: "0.65",
      label: "0.65",
    },
    {
      value: "1.0",
      label: "1.0",
    },
    {
      value: "1.5",
      label: "1.5",
    },
    {
      value: "2.5",
      label: "2.5",
    },
    {
      value: "4.0",
      label: "4.0",
    },
    {
      value: "6.5",
      label: "6.5",
    },
  ];

  const [showErrorNotice, setShowErrorNotice] = useState(false);

  const onShowErrorNotice = () => {
    setShowErrorNotice(true);
  };

  const onHideErrorNotice = () => {
    setShowErrorNotice(false);
  };

  const handleTaskDetailChange = (e) => {
    const { name, value } = e.target;

    let inspectionTaskDetail = editSubmissionData.inspectionTaskDetail;

    inspectionTaskDetail[name] = value;

    handleEditSubmissionDataChange({
      target: {
        name: "inspectionTaskDetail",
        value: inspectionTaskDetail,
      },
    });
  };

  const handleExpandAll = () => {
    let masterWics = editSubmissionData.masterWics;

    for (let i = 0; i < masterWics.length; i++) {
      masterWics[i].expanded = true;
    }

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const isAllFilled = () => {
    let hasMissingData = false;

    for (const taskData of editSubmissionData.masterWics) {
      const showProgram777 = isShowProgram777(taskData?.triggeredBy);

      if (
        (taskData.productSpecification.length === 0 &&
          taskData.programName.toLowerCase().indexOf("toys") === -1) ||
        taskData.artworks.length === 0 ||
        taskData.aisUPCInformation.length === 0 ||
        taskData.aisOuterInner.length === 0
      ) {
        hasMissingData = true;
        break;
      }

      //po
      if (!showProgram777) {
        if (
          (!taskData.firstShipmentTotalQuantity && taskData.firstShipmentTotalQuantity !== 0) ||
          (!taskData.fullPoTotalQuantity && taskData.fullPoTotalQuantity !== 0)
        ) {
          hasMissingData = true;
          break;
        }

        if (!taskData.po[0]) {
          hasMissingData = true;
          break;
        }

        if (
          (!taskData.po[0].poNumber && taskData.po[0].poNumber !== 0) ||
          (!taskData.po[0].poQuantity && taskData.po[0].poQuantity !== 0) ||
          (!taskData.po[0].casePack && taskData.po[0].casePack !== 0)
        ) {
          hasMissingData = true;
          break;
        }
      }

      //pb
      if (showProgram777) {
        if (taskData?.e2open?.length === 0) {
          hasMissingData = true;
          break;
        }

        if (
          taskData.pb.length === 0 ||
          !taskData.pb[0]?.pbNumber ||
          !taskData.pb[0]?.pbQuantity ||
          !taskData.pb[0]?.casePack
        ) {
          hasMissingData = true;
          break;
        }
      }
    }

    const { expectedInspectionDate, inspectionLevel, aqlCritical, aqlMajor, aqlMinor } =
      editSubmissionData.inspectionTaskDetail;

    return hasMissingData ||
      !expectedInspectionDate ||
      !inspectionLevel ||
      (!aqlCritical && aqlCritical !== "0") ||
      (!aqlMajor && aqlMajor !== "0") ||
      (!aqlMinor && aqlMinor !== "0")
      ? false
      : true;
  };

  const handleClickNextPage = () => {
    return isAllFilled() ? (handleNextPage(), onHideErrorNotice()) : onShowErrorNotice();
  };

  // const handleClickNextPage = () => {
  //   let hasMissingData = false;

  //   for (let i = 0; i < editSubmissionData.masterWics.length; i++) {
  //     if ((editSubmissionData.masterWics[i].productSpecification.length === 0 && editSubmissionData.masterWics[i].programName.toLowerCase().indexOf("toys") === -1)
  //       || editSubmissionData.masterWics[i].artworks.length === 0
  //       || editSubmissionData.masterWics[i].aisUPCInformation.length === 0
  //       || editSubmissionData.masterWics[i].aisOuterInner.length === 0) {
  //         hasMissingData = true;
  //         break;
  //     }

  //     if ((!editSubmissionData.masterWics[i].firstShipmentTotalQuantity && editSubmissionData.masterWics[i].firstShipmentTotalQuantity !== 0)
  //       || (!editSubmissionData.masterWics[i].fullPoTotalQuantity && editSubmissionData.masterWics[i].fullPoTotalQuantity !== 0)) {
  //         hasMissingData = true;
  //         break;
  //     }

  //     if (!editSubmissionData.masterWics[i].po[0]) {
  //       hasMissingData = true;
  //       break;
  //     }

  //     if ((!editSubmissionData.masterWics[i].po[0].poNumber && editSubmissionData.masterWics[i].po[0].poNumber !== 0)
  //       || (!editSubmissionData.masterWics[i].po[0].poQuantity && editSubmissionData.masterWics[i].po[0].poQuantity !== 0)
  //       || (!editSubmissionData.masterWics[i].po[0].casePack && editSubmissionData.masterWics[i].po[0].casePack !== 0)) {
  //         hasMissingData = true;
  //         break;
  //     }
  //   }

  //   if (hasMissingData || !editSubmissionData.inspectionTaskDetail.expectedInspectionDate
  //     || !editSubmissionData.inspectionTaskDetail.inspectionLevel
  //     || (!editSubmissionData.inspectionTaskDetail.aqlCritical && editSubmissionData.inspectionTaskDetail.aqlCritical !== "0")
  //     || (!editSubmissionData.inspectionTaskDetail.aqlMajor && editSubmissionData.inspectionTaskDetail.aqlMajor !== "0")
  //     || (!editSubmissionData.inspectionTaskDetail.aqlMinor && editSubmissionData.inspectionTaskDetail.aqlMinor !== "0")) {
  //       alert("please input necessary data and upload file");
  //   } else {
  //     handleNextPage();
  //   }
  // };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>1. Inspection Information</Box>

        <Box display="flex">
          <Box className={classes.blockPadding}>
            <Box className={classes.subtitle}>Expected Inspection Date</Box>
            <Box>
              <DatePicker
                name="expectedInspectionDate"
                value={editSubmissionData.inspectionTaskDetail.expectedInspectionDate || ""}
                handleChange={handleTaskDetailChange}
                additionalClass={classes.smallInputField}
              />
            </Box>
          </Box>

          <Box>
            <Box className={classes.subtitle}>Inspection Level</Box>
            <Box>
              <Select
                label="Inspection Level (Select One)"
                name="inspectionLevel"
                value={editSubmissionData.inspectionTaskDetail.inspectionLevel}
                options={inspectionLevelOptions}
                handleChange={handleTaskDetailChange}
                additionalClass={classes.inputField}
              />
            </Box>
          </Box>
        </Box>

        <Box display="flex">
          <Box className={classes.blockPadding}>
            <Box className={classes.subtitle}>AQL Level (Critical/Major/Minor)</Box>
            <Box>
              <Select
                label="Critical (Select One)"
                name="aqlCritical"
                value={editSubmissionData.inspectionTaskDetail.aqlCritical}
                options={aqlLevelOptions}
                handleChange={handleTaskDetailChange}
                additionalClass={classes.smallInputField}
              />
            </Box>
          </Box>

          <Box>
            <Box className={classes.blockPadding}>
              <Box className={classes.inputPadding}>
                <Select
                  label="Major (Select One)"
                  name="aqlMajor"
                  value={editSubmissionData.inspectionTaskDetail.aqlMajor}
                  options={aqlLevelOptions}
                  handleChange={handleTaskDetailChange}
                  additionalClass={classes.smallInputField}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box className={classes.inputPadding}>
              <Select
                label="Minor (Select One)"
                name="aqlMinor"
                value={editSubmissionData.inspectionTaskDetail.aqlMinor}
                options={aqlLevelOptions}
                handleChange={handleTaskDetailChange}
                additionalClass={classes.smallInputField}
              />
            </Box>
          </Box>
        </Box>

        <Box display="flex">
          <Box flexGrow={1} className={classnames(classes.title, classes.fieldPadding)}>
            2. Task Information (Per Wic Item)
          </Box>

          <Box className={classes.expandAll} onClick={handleExpandAll}>
            expand all
          </Box>
        </Box>

        <Box className={classes.WicSectionContainer}>
          <Grid container>
            {editSubmissionData.masterWics.map((masterWic, index) => (
              <Grid item xs={12} className={classes.wicDetailsWdith} key={masterWic.masterWicId}>
                <WicDetails
                  task={editSubmissionData.tasks[index]}
                  masterWic={masterWic}
                  masterWicIndex={index}
                  editSubmissionData={editSubmissionData}
                  handleEditSubmissionDataChange={handleEditSubmissionDataChange}
                  handleDeletePo={handleDeletePo}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleClickNextPage}
        disabled={!isAllFilled()}
      >
        CONTINUE
      </Button>

      <NoticeBar
        message={locale.errors.fill}
        error
        open={showErrorNotice}
        onClose={onHideErrorNotice}
      />
    </>
  );
};

export default TaskDetail;
