import { combineReducers } from "redux";
import task from "./task/reducer";
import item from "./item/reducer";
import submissionDetail from "./submissionDetail/reducer";

const rootReducer = combineReducers({
  task,
  item,
  submissionDetail,
});

export default rootReducer;
