import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import SearchPOButton from "components/input/SearchPOButton";

import POTable from "../../buyer/PO/POTable";
import POSeriesTable from "../../buyer/PO/POSeriesTable";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import GifLoading from "components/display/GifLoading";

import { atnGetDailyPOListSupplier, atnGetDailyPOSeriesListSupplier } from "actions/poActions";

import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  title: {
    fontWeight: 500,
    fontSize: "16px",
    marginRight: "30px",
  },

  txtText: {
    display: "block",
    fontSize: "12px",
    color: "#232B3A",
    padding: "10px 17px",
    cursor: "pointer",

    "&:hover": {
      background: "#AAAAAA",
    },
  },

  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  boxViewing: {
    borderTop: "1px solid #AAAAAA",
    borderBottom: "1px solid #AAAAAA",
  },

  txtView: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#000000",
    paddingLeft: "30px",
    marginRight: "30px",
  },

  boxLast: {
    fontSize: "10px",
    color: "#000000",
    marginRight: "10px",
  },

  first: {
    color: "#777777",
  },
  wrapperTab: {
    width: "100%",
    background: "white",
    boxShadow: "inset 0px -1px 0px #aaaaaa",
    borderTop: "1px solid #aaaaaa",
  },
  parentTab: {
    width: "100%",
    boxShadow: "inset 0px -1px 0px #aaaaaa",
    borderRadius: 0,

    "& .MuiTabs-root": {
      minHeight: 40,
    },

    "& button": {
      minHeight: 40,
      fontSize: 10,
    },
  },
  uploadPOBtn: {
    "& button": {
      background: "transparent",
      color: "#232B3A",
      fontWeight: "normal",
      padding: "12px 17px",

      "&:hover": {
        background: "transparent",
      },
    },
  },
}));

const PO = () => {
  const classes = useStyles();

  // ==================== GET LIST PO ====================
  const [listPODaily, setListPODaily] = useState([]);
  const [listPODailySeries, setListPODailySeries] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const [isGetPOSeriesDone, setIsGetPOSeriesDone] = useState(false);

  useEffect(() => {
    getListDailyPO({ rowsPerPage: 999999999 });
    // getListDailyPOSeries({'rowsPerPage': 999999999});
  }, []);

  const getListDailyPO = (data) => {
    setLoadingTable(true);

    atnGetDailyPOListSupplier(data).then(({ data, message }) => {
      setListPODaily(data ? data.list : []);
      setLoadingTable(false);
    });
  };

  const getListDailyPOSeries = (data) => {
    setLoadingTable(true);

    atnGetDailyPOSeriesListSupplier(data).then(({ data, message }) => {
      setListPODailySeries(data ? data.list : []);
      setLoadingTable(false);
      setIsGetPOSeriesDone(true);
    });
  };
  // ==================== END GET LIST PO ====================

  // ==================== HANDLE SEARCH BUTTON ====================
  const [searchQueryData, setSearchQueryData] = useState("");
  const cbHandleSearch = (data) => {
    setSearchQueryData(data);
  };

  const [searchQuerySeriesData, setSearchQuerySeriesData] = useState("");
  const cbHandleSearchPOSeries = (data) => {
    setSearchQuerySeriesData(data);
  };
  // ==================== END HANDLE SEARCH BUTTON ====================

  // ==================== HANDLE SELECT TAB ====================
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !isGetPOSeriesDone) {
      getListDailyPOSeries({ rowsPerPage: 999999999 });
    }
    setTab(newValue);
  };
  // ==================== END HANDLE SELECT TAB ====================

  return (
    <Box className={classes.root} width="fit-content">
      {loadingTable && <GifLoading />}

      <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 30px">
        <Box display={tab === 0 ? "flex" : "none"} alignItems="center">
          <Box className={classes.title}>Outstanding PO List</Box>
          <SearchPOButton cbHandleSearch={cbHandleSearchPOSeries} placeHolder="Search PO Series" />
        </Box>

        <Box display={tab === 1 ? "flex" : "none"} alignItems="center">
          <Box className={classes.title}>Outstanding PO List</Box>
          <SearchPOButton
            cbHandleSearch={cbHandleSearch}
            placeHolder="Search PO No., WIC No., WIC Description"
          />
        </Box>
      </Box>

      <Box display="flex" paddingLeft="30px" className={classes.wrapperTab}>
        <Paper className={classes.parentTab}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabHeader}
          >
            <Tab label="PO" className={classes.tab} />
            <Tab label="PO Series" className={classes.tab} />
          </Tabs>
        </Paper>
      </Box>

      <Box display="flex" className={classes.contentContainer}>
        <Box display={tab === 0 ? "block" : "none"}>
          <POTable listSearch={searchQueryData} listPODaily={listPODaily} />
        </Box>
        <Box display={tab === 1 ? "block" : "none"}>
          <POSeriesTable listSearch={searchQuerySeriesData} listPODailySeries={listPODailySeries} />
        </Box>
      </Box>
    </Box>
  );
};

export default PO;
