import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  alert: {
    "& .MuiAlert-message": {
      width: "100%",
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiBox-root": {
        alignItems: "center",
      },
    },
    "& .MuiAlert-icon": {
      display: "flex",
      alignItems: "center",
      fontSize: 16,
    },
    "& > *": {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  message: {
    flex: 1,
    paddingRight: 32,
    color: "#000",
  },
  button: {
    color: "#005EFF",
    borderColor: "none",
    fontWeight: 400,
    textTransform: "none",
  },
}));

const TaskAlert = ({
  buttonText = "CLOSE",
  message = "",
  onClick,
  className,
  onClose,
  open = false,
  disabled = false,
}) => {
  const classes = useStyles();

  return open ? (
    <Alert severity="error" className={classnames(className, classes.alert)} onClose={onClose}>
      <Box display="flex">
        <Box display="flex" alignItems="center" className={classes.message}>
          {message}
        </Box>

        <Box display="flex" alignItems="center">
          <Button onClick={onClick} className={classes.button} disabled={disabled}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Alert>
  ) : null;
};

export default memo(TaskAlert);
