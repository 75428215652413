import React from "react";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar as Image, Badge, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Cancel";

import {
  atnDeleteFile,
  atnDownloadFile,
  atnUploadFile,
  atnUploadFileWithType,
} from "actions/fileActions";
import FileUploadButton from "components/input/FileUploadButton";
import { downloadFile, getFileSourceUrl } from "utils/files";
import { SUPPORT_IMAGE_FILE_TYPES } from "shared/constants";

import NoImage from "assets/no_image.png";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 90,
    height: 90,
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteIcon: {
    color: "#DDDDDD",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.2)",
    },
  },
}));

const ImagePreview = (props) => {
  const classes = useStyles();

  const {
    data = [],
    config = {},
    editable = false,
    multiple = false,
    downloadable = false,
    className = {},
  } = props;

  const [imagesState, setImagesState] = React.useState([]);

  const downloadImage = ({ id, fileName, location }) => {
    atnDownloadFile(location).then(async (res) => {
      const curImageLoaded = {
        id,
        fileName,
        fileUrl: getFileSourceUrl(res),
        fileData: res,
      };
      setImagesState((prev) => prev.concat(curImageLoaded));
    });
  };

  React.useEffect(() => {
    if (data.length) {
      data.forEach((img) => {
        downloadImage(img);
      });
    }
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const handleUploadNewImages = (newImages) => {
    newImages.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      atnUploadFile(formData).then((res) => {
        if (res) downloadImage(res.data);

        // save image to server
        const params = {
          ...config,
          data: { data: [{ fileId: res.data.id, type: config.fileType }] },
        };
        atnUploadFileWithType(params).then((res) => {});
      });
    });
  };

  const handleDeleteImage = (imageId) => {
    // delete image from server
    const params = {
      ...config,
      data: { data: [{ fileId: imageId, type: config.fileType }] },
    };
    atnDeleteFile(params).then((res) => {});

    setImagesState(imagesState.filter((f) => f.id !== imageId));
  };

  return (
    <>
      <Box display="flex">
        {imagesState.map(({ id, fileName, fileUrl, fileData }) => (
          <Box key={id} pr={2}>
            <Badge
              overlap="rectangle"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={
                editable && (
                  <DeleteIcon
                    className={classes.deleteIcon}
                    onClick={() => handleDeleteImage(id)}
                  />
                )
              }
            >
              <Image
                variant="square"
                alt={fileName}
                src={fileUrl || NoImage}
                className={classnames(classes.image, className.image, {
                  [classes.hover]: downloadable,
                })}
                onClick={() => fileData && downloadable && downloadFile(fileData, fileName)}
              />
            </Badge>
          </Box>
        ))}
      </Box>
      {editable && (
        <Box pt={2}>
          <FileUploadButton
            id={uuidv4()}
            variant="outlined"
            multiple={multiple}
            supportFileTypes={SUPPORT_IMAGE_FILE_TYPES}
            onChange={(res) => handleUploadNewImages(res)}
          />
        </Box>
      )}
    </>
  );
};

export default ImagePreview;
