import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SearchableMultiSelect from "components/input/SearchableMultiSelect";
import SubmissionListTableRow from "./SubmissionListTableRow";
import Pagination from "components/table/Pagination";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    paddingBottom: 13,
    whiteSpace: "nowrap",
    minWidth: 600,
    minHeight: 300,
  },
  table: {
    position: "relative",
    zIndex: 995,
  },
  actionCell: {
    minWidth: "110px !important",
    width: 110,
  },
  submissionCell: {
    minWidth: 180,
    width: 180,
  },
  productNameCell: {
    minWidth: 230,
    width: 230,
  },
  supplierCell: {
    minWidth: 160,
    width: 160,
  },
  factoryCell: {
    minWidth: 180,
    width: 180,
  },
  taskTypeCell: {
    minWidth: 120,
    width: 120,
  },
  taskStatusCell: {
    minWidth: 180,
    width: 180,
  },
  requestedDateCell: {
    minWidth: 120,
    width: 120,
  },
  ecdCell: {
    minWidth: 120,
    width: 120,
  },
  tableHeaderCell: {
    position: "relative",
    zIndex: 996,
    padding: 10,
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
    minWidth: 180,
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    position: "absolute",
    top: 13,
    right: 10,
    padding: 0,
  },
  filter: {
    color: "#000000",
    width: 18,
    height: 18,
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingUp: {
    position: "absolute",
    top: 0,
    width: 15,
    height: "auto",
  },
  sortingDown: {
    position: "absolute",
    top: 7,
    width: 15,
    height: "auto",
  },
  selected: {
    color: "#196EFF",
  },
  trafficLightTitle: {
    paddingRight: 30,
    fontSize: 12,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  filterMenuContainer: {
    position: "absolute",
    top: 40,
    right: -90,
  },
  lastFilterMenuContainer: {
    position: "absolute",
    top: 40,
    right: 0,
  },
}));

const SubmissionListTable = ({
  selectedSubmission = [],
  selectedItem = [],
  handleSubmissionSelect,
  headerData = {},
  rowData = [],
  handleRowClick,
  handleSorting,
  handleFilterListToggle,
  handleFilterItemSelect,
  filterName,
  selectedFilter,
  // selectedFilter,

  currentPage,
  rowsPerPage,
  totalPages,
  totalRecords,
  rowsPerPageOption,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classnames(classes.tableHeaderCell, classes.actionCell)} />
            {headerData.map((item, index) => (
              <TableCell key={item.name} className={classes.tableHeaderCell}>
                <Box display="flex">
                  <Box flexGrow={1} display="flex" className={classes.headerLabel}>
                    <Box className={classes.tableText}>{item.label}</Box>

                    {item.hasSorting && (
                      <Box
                        display="flex"
                        className={
                          item.hasFilter
                            ? classes.sortingContainerWithMargin
                            : classes.sortingContainer
                        }
                        onClick={() => handleSorting(index, item.name, item.sorting)}
                      >
                        <ExpandLessIcon
                          className={classnames(classes.sortingUp, {
                            [classes.selected]: item.sorting === 1,
                          })}
                        />
                        <ExpandMoreIcon
                          className={classnames(classes.sortingDown, {
                            [classes.selected]: item.sorting === 2,
                          })}
                        />
                      </Box>
                    )}
                  </Box>
                  {item.hasFilter && (
                    <Box>
                      <IconButton
                        className={classes.filterContainer}
                        onClick={() => handleFilterListToggle(index)}
                      >
                        <FilterListIcon
                          className={classnames(classes.filter, {
                            [classes.selected]: item.selectedFilter.length > 0,
                          })}
                        />
                      </IconButton>

                      {item.openFilterList && (
                        <Box
                          className={
                            index === headerData.length - 1
                              ? classes.lastFilterMenuContainer
                              : classes.filterMenuContainer
                          }
                        >
                          <SearchableMultiSelect
                            filterName={item.name}
                            filterItems={item.filterItems}
                            selectedFilter={selectedFilter}
                            handleFilterItemSelect={handleFilterItemSelect}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row, index) => (
            <SubmissionListTableRow
              key={row.id}
              submissionId={row.id}
              submissionIndex={index}
              checked={selectedSubmission.indexOf(row.id) >= 0}
              selectedItem={selectedItem}
              row={row}
              itemData={row.tasks}
              handleRowClick={handleRowClick}
              handleSubmissionSelect={handleSubmissionSelect}
            />
          ))}
        </TableBody>
      </Table>

      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default SubmissionListTable;
