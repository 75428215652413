import React from "react";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import Table from "components/table/Table";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  tableCellCenter: {
    textAlign: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
}));

const WICDetails = ({ data }) => {
  const classes = useStyles();

  const DescriptionBlock = ({ data: descriptionData }) => {
    const { walkThroughComments: comments, ageGrade, isFoodContact, childOrNot } = descriptionData;

    const Item = ({ label, value }) => (
      <Box className={classes.item} pb={3}>
        <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>
        <Typography className={classnames(classes.text, classes.blackText)}>
          {textOrUnknown(value)}
        </Typography>
      </Box>
    );

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Walk Through Comments" value={comments} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Age Grade" value={ageGrade} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Food Contact?" value={isFoodContact ? "Yes" : "No"} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Product Category"
            value={childOrNot ? "Toy & Children Product" : "General Use"}
          />
        </Grid>
      </Grid>
    );
  };

  const tableColumn = [
    {
      columnId: "subWICNo",
      label: "SubWIC No.",
      render: ({ wicNumber, subWICNo, wicNubmer }) => {
        return textOrUnknown(wicNumber || subWICNo || wicNubmer);
      },
    },
    {
      columnId: "subWICDetails",
      label: "SubWIC Details",
      render: ({ subWICDetails, productName }) => {
        return textOrUnknown(subWICDetails || productName);
      },
    },
    {
      columnId: "assortment",
      label: "Assortment",
      render: ({ assortments = [], assortment = "" }) => {
        return textOrUnknown(assortments.map((item) => item.name).toString() || assortment);
      },
    },
  ];

  const tableData = data.map((item) => {
    const descriptionItem = _omit(item, [
      "id",
      "wicNumber",
      "assortments",
      "productName",
      "subWICNo",
      "subWICDetails",
      "assortment",
    ]);

    return {
      ...item,
      additionalRow: !_isEmpty(descriptionItem)
        ? {
            classes: { cell: classes.tableCellDescription },
            content: <DescriptionBlock data={descriptionItem} />,
          }
        : null,
    };
  });

  return (
    <Box py={2}>
      <Table data={tableData} columnData={tableColumn} noPagination />
    </Box>
  );
};

export default WICDetails;
