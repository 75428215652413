import React from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { NativeSelect as Select } from "components/form/Form";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  pageNumberWrapper: {
    display: "flex",
    marginLeft: 28,
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      marginRight: 15,
    },
    "& > div > div": {
      margin: 0,
      height: 30,
      minWidth: 65,
    },
    "& > div > div > fieldset": {
      border: "0 !important",
    },
  },
  pageNumberSelect: {
    padding: "0px 0px 0 12px !important",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const minPageNumber = 0;
  const maxPageNumber = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  const getPageNumberList = () => {
    const list = [];
    // eslint-disable-next-line no-plusplus
    for (let i = minPageNumber; i <= maxPageNumber; i++) {
      list.push({ label: i + 1, value: i });
    }
    return list;
  };

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleChangePageNumber = (event) => {
    onChangePage(event, Number(event.target.value));
  };

  return (
    <>
      <div className={classes.pageNumberWrapper}>
        <Typography variant="body2">Page:</Typography>
        <Select
          options={getPageNumberList()}
          selectProps={{
            classes: { root: classes.pageNumberSelect },
            value: page,
            onChange: handleChangePageNumber,
          }}
        />
      </div>
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    </>
  );
};

export default TablePaginationActions;
