import React from "react";
import { Redirect, Route } from "react-router-dom";
import auth from "../auth/authenticator";

const CommonRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.loggedIn()) {
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: props.location,
            },
          }}
        />
      );
    }}
  />
);

export default CommonRoute;
