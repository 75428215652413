import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = () => ({
  select: {
    width: 200,
    height: 36,
    "& .MuiTextField-root": {
      "& .MuiInputBase-root": {
        height: 36,
        "& .MuiInputBase-input": {
          fontSize: 12,
          padding: "0px 5px",
        },
        "& fieldset legend": {
          fontSize: "0.55em",
        },
      },
      "& .MuiInputLabel-outlined": {
        fontSize: 12,
        transform: "translate(14px, 12px) scale(1)",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
    },
  },
});

class SelectAutocomplete extends PureComponent {
  render() {
    const { classes, label, options, ...otherProps } = this.props;

    return (
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        className={classes.select}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(SelectAutocomplete);
