import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from '@material-ui/core/TextField';
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import TabPanelContent from "./TabPanelContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: "none",
  },
  tabHeader: {
    borderBottom: "1px solid #cccccc",
    minHeight: 40,
    height: 40,
  },
  tab: {
    minWidth: 100,
    width: 100,
    minHeight: 40,
    height: 40,
    textTransform: "none",
    fontSize: 10,
  },
  show: {
    display: "normal",
  },
  hide: {
    display: "none",
  },
  searchField: {
    width: "100%",
    fontSize: 10,
    "& .MuiInputBase-input": {
      height: 20,
      padding: "5px 0 5px 10px",
    },
  },
  tabPanelWithSearch: {
    position: "absolute",
    top: 70,
    bottom: 0,
    overflowY: "auto",
    // width: "100%",
  },
  tabPanel: {
    position: "absolute",
    top: 40,
    bottom: 0,
    overflowY: "auto",
  },
}));

const TabSideMenu = ({
  sideMenuItems = [],
  sideMenuItemValue = 0,
  sideMenuSearchKeyword = "",
  tabItems = {},
  filteredTabItems = {},
  selectedPanelItem = {},
  handleMenuChange,
  handlePanelItemChange,
  handleSideMenuSearchKeywordChange,
}) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.container}>
      <Tabs
        value={sideMenuItemValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleMenuChange}
        className={classes.tabHeader}
      >
        {sideMenuItems.map((item) => (
          <Tab key={item.name} label={item.label} className={classes.tab} />
        ))}
      </Tabs>
      <Box
        className={sideMenuItems[sideMenuItemValue].hasSearchFunction ? classes.show : classes.hide}
      >
        <Input
          className={classes.searchField}
          placeholder={sideMenuItems[sideMenuItemValue].searchKeywordPlaceholder}
          value={sideMenuSearchKeyword}
          onChange={(e) => handleSideMenuSearchKeywordChange(e)}
        />
      </Box>
      {sideMenuItems.map((item, index) => (
        <TabPanel
          key={item.name}
          value={sideMenuItemValue}
          index={index}
          className={
            sideMenuItems[sideMenuItemValue].hasSearchFunction
              ? classes.tabPanelWithSearch
              : classes.tabPanel
          }
        >
          <TabPanelContent
            panelItems={sideMenuSearchKeyword ? filteredTabItems[item.name] : tabItems[item.name]}
            selectedPanelItem={selectedPanelItem}
            handlePanelItemChange={handlePanelItemChange}
          />
        </TabPanel>
      ))}
    </Paper>
  );
};

export default TabSideMenu;
