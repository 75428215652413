import { BUYER, SUPPLIER, USER_TYPE } from "shared/constants";
import api from "./api";

class taskApi {
  static getOpenTaskData(data) {
    return api.get(`/${SUPPLIER}/open_task/${data.id}`, {});
  }

  static getPoEveryday(data) {
    return api.get(`/${SUPPLIER}/open_task/get_po/${data.id}`, {});
  }

  static getPbEveryday(data) {
    return api.get(`/${SUPPLIER}/open_task/pb/${data.id}`, {});
  }

  static getOpenTaskLabs(data) {
    return api.get(
      `/${SUPPLIER}/open_task/get_labs/?taskType=${data.taskType}&buyerProgramId=${
        data.buyerProgramId
      }${data?.taskId ? `&taskId=${data.taskId}` : ""}${
        data?.labCompanyId ? `&labCompanyId=${data.labCompanyId}` : ""
      }`,
      {},
    );
  }

  static openTask(data) {
    return api.post(`/${SUPPLIER}/open_task`, data);
  }

  static openTaskSupplier(data) {
    return api.post(`/${SUPPLIER}/open_task/supplier`, data);
  }

  // data: { plannedDate: null, deadLine: null }
  static updateTaskRequirement({ data: { taskRequirementId, data }, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.put(`/${SUPPLIER}/task_requirements/${taskRequirementId}`, data);
    } else if (type === USER_TYPE.buyer) {
      return api.put(`/${BUYER}/task_requirements/${taskRequirementId}`, data);
    }
  }

  static getTrfFactoryData(data) {
    return api.get(`/${SUPPLIER}/open_task/factories/${data.factoryId}`, data);
  }

  static completedTask(data) {
    return api.put(`/${BUYER}/tasks/${data.id}/complete`, data.params);
  }

  static updateTaskLabReport({ taskId, ...data }) {
    return api.put(`/lab/tasks/${taskId}/pb_reports`, data);
  }

  //* get SS task payer info
  static getTaskPayer({ taskId, type = USER_TYPE.supplier }) {
    if (type === USER_TYPE.supplier) {
      return api.get(`/${SUPPLIER}/tasks/${taskId}/payer`);
    } else if (type === USER_TYPE.buyer) {
      return api.get(`/${BUYER}/tasks/${taskId}/payer`);
    }
  }

  //* create/update SS task payer info
  static updateTaskPayer({ taskId, data }) {
    return api.put(`/${SUPPLIER}/tasks/${taskId}/payer`, data);
  }

  //* get available task type options to add in add task modal
  static getOpenTaskTaskTypes({ masterWicId, userType = USER_TYPE.buyer }) {
    if (userType === USER_TYPE.supplier) {
      return api.get(`/${SUPPLIER}/open_task/${masterWicId}/task_types`);
    } else if (userType === USER_TYPE.buyer) {
      return api.get(`/${BUYER}/open_task/${masterWicId}/task_types`);
    }
  }

  static updateNb({ masterWicId, taskId }) {
    return api.put(`/${BUYER}/items/master_wic/${masterWicId}/tasks/${taskId}/set_nb`);
  }
}

export default taskApi;
