import React, { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Program777Chip from "components/display/Program777Chip";
import TrafficLight from "components/display/TrafficLight";

import { isShowProgram777 } from "services/pbService";
import TenderingChip from "components/display/TenderingChip";
import NBChip from "../../../../components/display/NBChip";

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  actionCell: {
    paddingLeft: "20px !important",
  },
  tableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    // maxWidth: 220,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  expandedTableCell: {
    position: "relative",
    zIndex: 991,
    height: 40,
    minWidth: 110,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "none",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productNameText: {
    maxWidth: 160,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    padding: 0,
  },
  filter: {
    color: "#000000",
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingContainerWithMargin: {
    position: "relative",
    paddingLeft: 2,
    marginRight: 30,
    cursor: "pointer",
  },
  sortingUp: {
    position: "absolute",
    top: -2,
    width: 15,
    height: "auto",
  },
  sortingDown: {
    position: "absolute",
    top: 6,
    width: 15,
    height: "auto",
  },
  selected: {
    color: "#196EFF",
  },
  imageButtonContainer: {
    padding: "4px 0 0 0",
  },
  imageButton: {
    color: "#DADADA",
  },
  trafficLight: {
    paddingLeft: 5,
    paddingRight: 35,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  red: {
    width: 7,
    height: 7,
    backgroundColor: "#D42600",
    borderRadius: "50%",
  },
  blue: {
    width: 7,
    height: 7,
    backgroundColor: "#196EFF",
    borderRadius: "50%",
  },
  yellow: {
    width: 7,
    height: 7,
    backgroundColor: "#F2DA00",
    borderRadius: "50%",
  },
  green: {
    width: 7,
    height: 7,
    backgroundColor: "#1BB135",
    borderRadius: "50%",
  },
  grey: {
    width: 7,
    height: 7,
    backgroundColor: "#A3A3A3",
    borderRadius: "50%",
  },
  indicatorContainer: {
    padding: "3px 0px 0px 10px",
  },
  itemDataCheckbox: {
    marginLeft: 30,
  },
  alignCenter: {
    alignItems: "center",
  },
}));

const SubmissionListTableRow = ({
  submissionId,
  submissionIndex,
  hasSelectBox,
  checked,
  selectedItem = [],
  row = {},
  itemData = [],
  handleRowClick,
  handleSubmissionSelect,
  handleItemSelect,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [expandMore, setExpandMore] = useState(true);

  const handleExpandClick = () => {
    if (itemData.length > 0) {
      if (!expanded) {
        setExpandMore(true);
      } else {
        setExpandMore(false);
      }
    } else {
      setExpandMore(false);
    }

    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow data-cy-submission={`submissionId-${submissionId}`} className={classes.tableRow}>
        <TableCell className={classnames(classes.tableCell, classes.actionCell)}>
          <IconButton size="small" onClick={() => handleExpandClick()}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>

          {hasSelectBox && (
            <Checkbox
              color="primary"
              checked={checked}
              onChange={(e) => handleSubmissionSelect(e, submissionId, submissionIndex)}
            />
          )}
        </TableCell>
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)}>
          <Box className={classes.tableText}>
            {row.submissionNumber} {row.isNb && <NBChip />}
          </Box>
        </TableCell>
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)} />
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)}>
          <Box className={classes.tableText}>{row.labName || ""}</Box>
        </TableCell>
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)} />
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)} />
        <TableCell className={classes.tableCell} onClick={() => handleRowClick(submissionId)} />
      </TableRow>

      {expandMore && (
        <>
          {itemData.map((item, index) => {
            const showProgram777 = isShowProgram777(item.triggeredBy);

            return (
              <TableRow
                data-cy-wicNumber={`${item?.wicNumber}`}
                data-cy-task={`taskId-${item?.id}`}
                key={item.id}
                className={classes.tableRow}
              >
                <TableCell
                  className={
                    index !== itemData.length - 1
                      ? classnames(classes.expandedTableCell, classes.actionCell)
                      : classnames(classes.tableCell, classes.actionCell)
                  }
                >
                  {hasSelectBox && (
                    <Checkbox
                      color="primary"
                      checked={selectedItem.indexOf(item.id) >= 0}
                      onChange={(e) => handleItemSelect(e, item.id)}
                      className={classes.itemDataCheckbox}
                    />
                  )}
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>{item.taskNumber}</Box>
                  <Box className={classnames(classes.buyer, classes.tableText)}>
                    {item.buyer} {item.category}
                  </Box>
                  <Box
                    display="flex"
                    className={classnames(classes.alignCenter, classes.info, classes.tableText)}
                  >
                    {item?.isTenderingProgram && item?.tenderingProgram.assignedLab !== "" && (
                      <TenderingChip detail={item?.tenderingProgram} />
                    )}
                    {item.programName} {item.category === "everyday" ? "" : item.programYear}
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box display="flex">
                    {showProgram777 && <Program777Chip />}
                    <Box className={classes.productNameText}>{item.productName}</Box>
                  </Box>
                  <Box className={classnames(classes.info, classes.tableText)}>
                    {item.wicNumber}
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                />

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>{t(`task.${item.taskType}`)}</Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box display="flex">
                    <Box flexGrow={1} display="flex">
                      <Box className={classes.tableText}>
                        {t(`taskStatus.${item.completedStatus || item.taskStatus}`)}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box className={classes.indicatorContainer}>
                        <TrafficLight status={item.completedStatus || item.taskStatus} />
                      </Box>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell
                  className={
                    index !== itemData.length - 1 ? classes.expandedTableCell : classes.tableCell
                  }
                  onClick={() => handleRowClick(submissionId)}
                >
                  <Box className={classes.tableText}>{item.ecd || "N/A"}</Box>
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};

export default SubmissionListTableRow;
