import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Container, IconButton, LinearProgress, Toolbar } from "@material-ui/core";
import { ArrowBack as BackIcon, Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  defaultText: {
    color: "#000000",
    flexGrow: 1,
    textAlign: "center",
  },
  menuButton: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  processRoot: {
    backgroundColor: "#E5ECF6",
    height: 7,
  },
  processBar: {
    backgroundColor: "#003DA5",
  },
}));

const MenuBar = ({ data, exitAction }) => {
  const classes = useStyles();

  const stepperClasses = { root: classes.processRoot, bar: classes.processBar };

  const { preLabel, label, backAction, process } = data;

  const handleBack = () => {
    if (backAction) backAction();
  };

  const handleQuit = () => {
    if (exitAction) exitAction();
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleBack}
              >
                <BackIcon />
              </IconButton>
              <span className={classes.defaultText}>
                <b>{preLabel} |</b> {label}
              </span>
              <IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleQuit}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Container>
        </Toolbar>
        <LinearProgress variant="determinate" value={process} classes={stepperClasses} />
      </AppBar>
    </div>
  );
};

export default MenuBar;
