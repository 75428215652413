import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import TextInput from "components/input/TextInput";
import Select from "components/input/Select";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  taskContainer: {
    paddingTop: 18,
    paddingBottom: 18,
    borderBottom: "1px solid #cccccc",
    "&:first-child": {
      paddingTop: 0,
    },
    "&:last-child": {
      borderBottom: "none",
      paddingBottom: 0,
    },
  },
  taskTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingTop: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  wbaDeadline: {
    borderRight: "1px solid #cccccc",
    minWidth: 260,
    width: 260,
  },
  deadlineInput: {
    width: 240,
  },
  selectSubWIC: {
    borderRight: "1px solid #cccccc",
    minWidth: 228,
    width: 228,
    paddingLeft: 30,
    paddingRight: 30,
  },
  handledBy: {
    borderRight: "1px solid #cccccc",
    minWidth: 210,
    width: 210,
    paddingLeft: 30,
    paddingRight: 30,
  },
  handledByInput: {
    width: 150,
  },
  remark: {
    minWidth: 342,
    width: 342,
    paddingLeft: 30,
  },
  remarkInput: {
    width: 312,
  },
  icl: {
    paddingTop: 30,
  },
  iclInput: {
    width: 500,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      fontSize: 12,
    },
  },
}));

const TaskAssignment = ({
  data: {
    createItemData = {},
    openConfirmDialog = false,
    isEverydayProgram = false,
    taskList = [],
  },
  func: {
    handleTaskSelect,
    handleTaskDataChange,
    handleDeadlineChange,
    handleTaskSubWICChange,
    handleCreateItemDataChange,
    handleNextPage,
    handleSubmit,
  },
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubmitClick = () => {
    let hasSelectTask = false;
    let hasSelectTaskWithoutSubWIC = false;
    let hasSelectedSubWIC = false;

    for (const task of taskList) {
      if (task.isSelected) {
        hasSelectTask = true;
        hasSelectTaskWithoutSubWIC = false;
        hasSelectedSubWIC = false;

        for (const subWic of task.subWics) {
          if (subWic.isSelected) {
            hasSelectedSubWIC = true;
            break;
          }
        }

        if (hasSelectedSubWIC) {
          hasSelectTaskWithoutSubWIC = false;
        } else {
          hasSelectTaskWithoutSubWIC = true;
          break;
        }
      }
    }

    // if (!hasSelectTask) {
    //   return alert('Please select at least one task');
    // } else
    if (hasSelectTaskWithoutSubWIC) {
      return alert("Please select at least one SubWIC");
    } else {
      isEverydayProgram ? handleNextPage() : handleSubmit();
    }
  };

  return (
    <>
      <Paper className={classes.container}>
        {taskList.map((task, index) => (
          <Box key={task.taskType} className={classes.taskContainer}>
            <Box display="flex">
              <Box
                flexGrow={1}
                display="flex"
                className={classes.taskTitle}
                data-cy={`ci-task-${task.taskType}`}
              >
                {t(`task.${task.taskType}`)}
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleTaskSelect(task.taskType)}
                  data-cy={`ci-task-tick-${task.taskType}`}
                >
                  {task.isSelected ? (
                    <CheckBoxIcon
                      className={classes.checkBoxIcon}
                      data-cy={`ci-task-checked-${task.taskType}`}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkBoxOutlineBlankIcon}
                      data-cy={`ci-task-check-${task.taskType}`}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>
            {task.isSelected && (
              <Box display="flex" data-cy={`ci-task-selected-${task.taskType}`}>
                <Box className={classes.wbaDeadline}>
                  <Box className={classes.title}>{`1. Set ${SIMPLE_BUYER_NAME} Deadline${
                    isEverydayProgram ? " (Optional)" : ""
                  }`}</Box>
                  <Box className={classes.subtitle}>{SIMPLE_BUYER_NAME} Deadline</Box>
                  <Box>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        key={index}
                        disableToolbar
                        className={classes.datePicker}
                        format="DD/MMM/YYYY"
                        placeholder="dd/mm/yyyy"
                        name="wbaDeadline"
                        value={task.wbaDeadline || null}
                        onChange={(e) => handleDeadlineChange(e, index)}
                        maxDate={moment(createItemData.shipByDates[0].shipByDate)._d}
                        data-cy={`ci-task-deadline-${task.taskType}`}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Box>

                <Box className={classes.selectSubWIC}>
                  <Box className={classes.title}>2. Select SubWIC for Task</Box>

                  {task.subWics.map((subWIC, i) => (
                    <Box key={subWIC.tempId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={subWIC.isSelected}
                            onChange={() => handleTaskSubWICChange(task.taskType, i)}
                            color="primary"
                            data-cy={`ci-task-subwic-${task.taskType}`}
                          />
                        }
                        label={subWIC.wicNumber}
                      />
                    </Box>
                  ))}
                </Box>

                {!isEverydayProgram && task.hasHandledBy && (
                  <Box className={classes.handledBy}>
                    <Box className={classes.title}>3. Assign Task to</Box>
                    <Box className={classes.subtitle}>Assign to</Box>
                    <Box>
                      <Select
                        label=""
                        name="handledBy"
                        value={task.handledBy}
                        options={task.handledByOptions}
                        handleChange={(e) => handleTaskDataChange(e, index)}
                        additionalClass={classes.handledByInput}
                        cy={`ci-task-assign-${task.taskType}`}
                      />
                    </Box>
                  </Box>
                )}

                <Box className={classes.remark}>
                  <Box className={classes.title}>
                    {`${!isEverydayProgram && task.hasHandledBy ? "4" : "3"}. Task Remark${
                      isEverydayProgram ? " (Optional)" : ""
                    }`}
                  </Box>
                  <Box className={classes.subtitle}>Remark</Box>
                  <Box>
                    <TextInput
                      placeholder="Remark"
                      name="remarks"
                      value={task.remarks}
                      additionalClass={classes.remarkInput}
                      onChange={(e) => handleTaskDataChange(e, index)}
                      cy={`ci-task-remark-${task.taskType}`}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}

        {isEverydayProgram && (
          <Box className={classes.icl}>
            <Box className={classes.title}>ICL</Box>
            <Box className={classes.subtitle}>ICL Number</Box>
            <Box>
              <TextInput
                placeholder="Fill in ICL Number here"
                name="icl"
                value={createItemData.icl}
                additionalClass={classes.iclInput}
                onChange={(e) => handleCreateItemDataChange(e)}
                cy={`ci-task-icl-number`}
              />
            </Box>
          </Box>
        )}
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleSubmitClick}
        disabled={!isEverydayProgram && openConfirmDialog}
        data-cy={`ci-continue`}
      >
        {isEverydayProgram ? "CONTINUE" : "CONFIRM"}
      </Button>
    </>
  );
};

export default TaskAssignment;
