import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import TrfForm from "./TrfForm/index";

import SelectTask from "../components/SelectTask";
import SelectLab from "../components/SelectLab";

import PayerDetail from "../../components/PayerDetail";

import { atnGetOpenTaskLabs, atnOpenTask } from "actions/taskActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const OpenTestingTask = ({ history, taskDetails = {}, relatedTasks = [] }) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [filteredRelatedTasks, setFilteredRelatedTasks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openTaskData, setOpenTaskData] = useState({
    tasks: [taskDetails],
    labIds: [],
    isSkipQuote: false,
    billingAddress: {
      invoiceType: "",
      currency: "",
      payerCompanyName: "",
      payerCompanyAddress: "",
      payerContactPerson: "",
      payerEmail: "",
      payerPhoneNumber: "",
      attachments: [],
      taxInformationFiles: [],
      taxRegistrationCode: "",
      enterpriseName: "",
      address: "",
      bankName: "",
      contactNumber: "",
      bankAddress: "",
    },
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);

  // for open task with tendering program
  // update handleGetLabs func with state
  const [tenderingMatchedLabs, setTenderingMatchedLabs] = useState([]);

  const titleData = [
    "Select Task(s) for Share Cost",
    "Select Lab for Quotation",
    "Enter Payer Detail for Invoice",
    "Fill in TRF Information to Confirm Quote",
  ];

  const handleTenderingMatchedLab = (matchedTenderingLabs) => {
    setTenderingMatchedLabs(matchedTenderingLabs);
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = relatedTasks.filter(
        (item, i) =>
          item.wicNumber.indexOf(value) >= 0 ||
          item.taskNumber.indexOf(value) >= 0 ||
          item.productName.indexOf(value) >= 0,
      );

      setFilteredRelatedTasks(tempTasks);
    } else {
      setFilteredRelatedTasks(relatedTasks);
    }
  };

  const handleOpenTaskDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setOpenTaskData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabs = (getLabData) => {
    atnGetOpenTaskLabs(getLabData).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setOpenTaskData((prevState) => ({
        ...prevState,
        labIds: [],
      }));
    });
  };

  useEffect(() => {
    if (
      taskDetails &&
      (taskDetails.taskType === "ST" || taskDetails.taskType === "STD") &&
      taskDetails.nb
    ) {
      setOpenTaskData((prevState) => ({
        ...prevState,
        isSkipQuote: true,
      }));
    }
  }, [taskDetails]);

  useEffect(() => {
    const stringifyTenderingLabs = !!tenderingMatchedLabs?.length
      ? tenderingMatchedLabs?.toString()
      : "";

    handleGetLabs({
      taskType: taskDetails.taskType,
      buyerProgramId: taskDetails.buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleSubmit = () => {
    setIsDisabledConfirmButton(true);

    let billingAddress = openTaskData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (openTaskData.billingAddress.invoiceType === "icw_company_invoice") {
      delete billingAddress.attachments;
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType !== "icw_company_invoice") {
      let taxInformationFiles = [];

      for (let i = 0; i < openTaskData.billingAddress.attachments.length; i++) {
        taxInformationFiles.push({
          id: openTaskData.billingAddress.attachments[i].id,
          type: "tax_information",
        });
      }

      billingAddress.taxInformationFiles = taxInformationFiles;
      delete billingAddress.attachments;
    }

    let selectedLabs = [];

    for (let i = 0; i < openTaskData.labIds.length; i++) {
      selectedLabs.push({
        id: openTaskData.labIds[i],
      });
    }

    let submitData = {
      isSkipQuote: openTaskData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: openTaskData.tasks,
    };

    atnOpenTask(submitData).then(({ data, message }) => {
      if (data) {
        setIsDisabledConfirmButton(false);
        history.push(`/supplier/submissions/${data.id}`);
      } else {
        alert(message);
        setIsDisabledConfirmButton(false);
      }
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header step={step} handlePrevPage={handlePrevPage} handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <SelectTask
            openTaskData={openTaskData}
            relatedTasks={searchKeyword ? filteredRelatedTasks : relatedTasks}
            searchKeyword={searchKeyword}
            handleSearchKeywordChange={handleSearchKeywordChange}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleGetLabs={handleGetLabs}
            handleNextPage={handleNextPage}
            handleTenderingMatchedLab={handleTenderingMatchedLab}
          />
        )}

        {step === 1 && (
          <SelectLab
            openTaskData={openTaskData}
            labs={labs}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 2 && (
          <PayerDetail
            isTestingTask
            isDisabledConfirmButton={isDisabledConfirmButton}
            data={openTaskData}
            handleDataChange={handleOpenTaskDataChange}
            handleNextPage={handleNextPage}
            handleSubmit={handleSubmit}
          />
        )}

        {step === 3 && (
          <TrfForm
            factoryId={taskDetails.factoryId}
            tasks={openTaskData.tasks}
            masterWicId={taskDetails.masterWicId}
            productName={taskDetails.productName}
            wicNumber={taskDetails.wicNumber}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(null, {
  atnOpenTask,
  atnGetOpenTaskLabs,
})(OpenTestingTask);
