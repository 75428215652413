/* eslint-disable consistent-return */
import React, { useState } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import get from "lodash/get";
import T from "i18n-react";

import classes from "./Select.module.scss";

const MenuList = (props) => {
  const { children, className, ...other } = props;
  return (
    <components.MenuList {...other} className={clsx(className, classes.menuWithCheckbox)}>
      {children}
    </components.MenuList>
  );
};

const SelectInput = (props) => {
  const { selectProps = {} } = props;
  const {
    label,
    onChange,
    className,
    required,
    touched,
    errorText,
    noError = false,
    onBlur,
    loading,
    isDisabled,
    isMulti,
    isCreate,
    name,
    formProps,
    value,
    options,
    placeholder = T.translate("general.select"),
    limit = 100,
    isCheckboxOption,
    isTagField,
    hideCloseSelectedOption,
    ...other
  } = selectProps;
  const [labelFocus, setLabelFocus] = useState(false);
  const slTouched = touched || get(formProps, `touched.${name}`, "");
  const slErrorText = errorText || get(formProps, `errors.${name}`, "");
  const slValue = value || get(formProps, `values.${name}`, "");
  const hasError = !!(slTouched && slErrorText);

  const selectStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      borderColor: state.isFocused
        ? "#003DA5 !important"
        : hasError
        ? "#f44336 !important"
        : "rgba(0, 0, 0, 0.23)",
      boxShadow: "none !important",
      minHeight: "40px",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      " > div": {
        fontSize: "13px",
        fontWeight: "500",
      },
    }),
    noOptionsMessage: (bases) => {
      return {
        ...bases,
        "&:before": {
          display: "none !important",
        },
        "&:after": {
          display: "none !important",
        },
      };
    },
    placeholder: (provided) => ({
      ...provided,
      color: "hsl(0,0%,60%)",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
      marginTop: "3px",
      boxShadow: "0px 2px 10px rgba(35, 43, 58, 0.25)",
      // display: isTagField ? 'none' : 'block',
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.isDisabled ? "#AAAAAA" : "#232B3A",
        fontSize: "14px",
        backgroundColor:
          // eslint-disable-next-line no-nested-ternary
          isCheckboxOption && state.isSelected
            ? "transparent !important"
            : state.isSelected || state.isFocused
            ? "#EEEEEE !important"
            : "transparent",
        "&:before": {
          display: state.value === "new" ? "none !important" : "block !important",
          borderColor: state.isSelected ? "#67DF54 !important" : "",
          background: state.isSelected ? "#67DF54 !important" : "",
        },
        "&:after": {
          display: state.isSelected && state.value !== "new" ? "block !important" : "none",
        },
      };
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      display: isTagField ? "none" : "flex",
      "> div:first-of-type": {
        display: hideCloseSelectedOption ? "none" : "flex",
      },
      "> div:last-of-type": {
        display: "flex",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "2px",
      backgroundColor: "#EBEDEF",
      padding: "4px 8px",
      " > div": {
        paddingTop: "0",
        paddingBottom: "0",
        color: "#232B3A",
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      " > div:first-of-type": {
        paddingRight: "4px",
      },
    }),
  };

  let selectComponents = {};
  if (isCheckboxOption) {
    selectComponents = {
      MenuList,
    };
  }

  const renderRequired = () => {
    return required ? (
      <span>
        {label}
        <span className="required-mark">*</span>
      </span>
    ) : (
      label
    );
  };

  const commonProps = {
    ...other,
    styles: selectStyles,
    inputId: name,
    onChange: (obj) => {
      if (formProps) {
        formProps.setFieldValue(name, obj);
      }
      if (onChange) {
        onChange(name, obj);
      }
    },
    onBlur: (e) => {
      if (formProps) {
        formProps.handleBlur(e);
      }
      if (onBlur) {
        onBlur(e);
      }
      setLabelFocus(false);
    },
    onFocus: () => {
      setLabelFocus(true);
    },
    isClearable: true,
    isMulti: isTagField ? true : isMulti,
    isCreate: isTagField ? true : isCreate,
    name,
    value: slValue,
    // eslint-disable-next-line no-nested-ternary
    options: slValue && slValue.length === limit ? [] : options,
    placeholder,
  };
  return (
    <div
      className={clsx(
        classes.selectWrapper,
        isMulti && classes.isMulti,
        isDisabled && classes.isDisabled,
        label ? "" : classes.noLabel,
        className,
      )}
    >
      {!!label && (
        <FormLabel
          component="legend"
          className={clsx(classes.label, labelFocus && classes.focus, hasError && classes.error)}
        >
          {renderRequired()}
        </FormLabel>
      )}
      {isCreate || isTagField ? (
        <CreatableSelect {...commonProps} />
      ) : (
        <Select {...commonProps} components={selectComponents} />
      )}
      {!noError && (
        <FormHelperText
          className={slTouched && !!slErrorText ? classes.error : classes.errorTextHide}
        >
          {slErrorText}
        </FormHelperText>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

export default SelectInput;
