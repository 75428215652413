import React from "react";
import classnames from "classnames";
import _get from "lodash/get";
import { TASK_STATUS } from "shared/constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Button, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  circle: {
    width: 14,
    height: 14,
    borderRadius: "50%",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  grey: {
    backgroundColor: "#CCCCCC",
  },
  blue: {
    backgroundColor: "#196EFF",
  },
  yellow: {
    backgroundColor: "#FFD12E",
  },
  green: {
    backgroundColor: "#1BB135",
  },
  red: {
    backgroundColor: "#D42600",
  },
  taskStatusButton: {
    padding: 0,
    minWidth: "fit-content",
    color: "#777777",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#232B3A",
    color: "#FFFFFF",
    // maxWidth: 220,
    fontSize: 10,
    fontWeight: 400,
    // border: '1px solid #dadde9',
  },
  arrow: {
    color: "#232B3A",
  },
}))(Tooltip);

const TrafficLight = ({ status, tooltipDetails = [] }) => {
  const classes = useStyles();

  return (
    <HtmlTooltip
      arrow
      title={
        <>
          {tooltipDetails.map((item, index) => (
            <Box key={index}>{item}</Box>
          ))}
        </>
      }
    >
      <Button className={classes.taskStatusButton}>
        <Box
          className={classnames(
            classes.circle,
            classes[_get(TASK_STATUS[status], "color") || "transparent"],
          )}
        ></Box>
      </Button>
    </HtmlTooltip>
  );
};

export default TrafficLight;
