import React, { PureComponent } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = () => ({
  button: {
    fontSize: 14,
    fontWeight: 500,
  },
});

class OutlinedButton extends PureComponent {
  render() {
    const { classes, label, handleClick, additionalClass, ...otherProps } = this.props;

    return (
      <Button
        variant="outlined"
        className={classnames(classes.button, additionalClass)}
        onClick={handleClick}
        {...otherProps}
      >
        {label}
      </Button>
    );
  }
}

export default withStyles(styles)(OutlinedButton);
