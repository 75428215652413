import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";
import Button from "components/input/Button";

import Form from "./Form";
import MenuBar from "./MenuBar";

import { atnGetCities, atnGetCountries, atnGetStates } from "actions/commonActions";
import {
  atnConfirmQuotation,
  atnCreateTrfForm,
  atnGetMasterWicTrfFormData,
  atnGetSubmissionTrfDetails,
} from "actions/submissionActions";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 24,
    fontWeight: 500,
    color: "#000000",
    padding: `${theme.spacing(4)}px 0`,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
    marginBottom: 50,
  },
}));

const ConfirmQuote = ({ match, history }) => {
  const submissionId = match.params.submissionId;
  const quotationId = match.params.id;
  const classes = useStyles();

  const [trfFormData, setTrfFormData] = useState({
    factory: {},
    masterWics: [],
  });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hideState, setHideState] = useState(false);

  const [manufactureStateList, setManufactureStateList] = useState([]);
  const [manufactureCityList, setManufactureCityList] = useState([]);
  const [hideManufactureState, setHideManufactureState] = useState(false);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);

  const getCountries = () => {
    atnGetCountries().then(({ data, message }) => {
      let countries = [];

      for (let i = 0; i < data.length; i++) {
        countries.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCountryList(countries);
    });
  };

  const getStates = (countryId, type) => {
    atnGetStates({
      countryId: countryId,
    }).then(({ data, message }) => {
      if (data && data.length === 1 && !data[0].name) {
        getCities(data[0].id, type);

        if (type === "manufacture") {
          setTrfFormData((prevState) => ({
            ...prevState,
            factory: {
              ...prevState.factory,
              placeOfManufactureStateId: data[0].id,
            },
          }));

          setHideManufactureState(true);
        } else {
          setTrfFormData((prevState) => ({
            ...prevState,
            factory: {
              ...prevState.factory,
              countryOfOriginStateId: data[0].id,
            },
          }));

          setHideState(true);
        }
      } else {
        if (type === "manufacture") {
          setHideManufactureState(false);
        } else {
          setHideState(false);
        }
      }

      let states = [];

      for (let i = 0; i < data.length; i++) {
        states.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      if (type === "manufacture") {
        setManufactureStateList(states);
      } else {
        setStateList(states);
      }
    });
  };

  const getCities = (stateId, type) => {
    atnGetCities({
      stateId: stateId,
    }).then(({ data, message }) => {
      let cities = [];

      for (let i = 0; i < data.length; i++) {
        cities.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      if (type === "manufacture") {
        setManufactureCityList(cities);
      } else {
        setCityList(cities);
      }
    });
  };

  useEffect(() => {
    getCountries();

    atnGetSubmissionTrfDetails({
      submissionId: submissionId,
    }).then(({ data, message }) => {
      if (data) {
        const getTrfFormData = (masterWicId) => {
          return new Promise((resolve, reject) => {
            atnGetMasterWicTrfFormData({
              masterWicId: masterWicId,
            }).then(({ data, message }) => {
              if (data) {
                resolve(data);
              } else {
                reject();
              }
            });
          });
        };

        let masterWics = [];
        let getTrfFormFunctionArray = [];

        for (let i = 0; i < data.masterWics.length; i++) {
          getTrfFormFunctionArray.push(getTrfFormData(data.masterWics[i].id));

          masterWics.push({
            showMore: false,
            masterWicId: data.masterWics[i].id,
            productName: data.masterWics[i].productName,
            wicNumber: data.masterWics[i].wicNumber,
            additionalWicNumber: "",
            styleNumber: "",
            contentSizeCount: "",
            dateOfManufacture: "",
            isGoodNotForResale: false,
            goodNotForResaleReason: "",
            isSourcedByWBA: false,
            isDuaneReadeTestSubmission: false,
            isChildResistancePackage: false,
            isFollowingCpsiaTrackingLabel: false,
            trackingLabelRemark: "",
            reasonNotBeingMarkedWithTrackingLabel: [],
            sampleSubmission: "",
            returnSample: "No",
          });
        }

        Promise.all(getTrfFormFunctionArray).then((result) => {
          for (let i = 0; i < result.length; i++) {
            if (result[i].id) {
              masterWics[i].id = result[i].id;
              masterWics[i].additionalWicNumber = result[i].additionalWicNumber;
              masterWics[i].styleNumber = result[i].styleNumber;
              masterWics[i].contentSizeCount = result[i].contentSizeCount;
              masterWics[i].dateOfManufacture = result[i].dateOfManufacture;
              masterWics[i].isGoodNotForResale = result[i].isGoodNotForResale;
              masterWics[i].goodNotForResaleReason = result[i].goodNotForResaleReason;
              masterWics[i].isSourcedByWBA = result[i].isSourcedByWBA;
              masterWics[i].isDuaneReadeTestSubmission = result[i].isDuaneReadeTestSubmission;
              masterWics[i].isChildResistancePackage = result[i].isChildResistancePackage;
              masterWics[i].isFollowingCpsiaTrackingLabel = result[i].isFollowingCpsiaTrackingLabel;
              masterWics[i].trackingLabelRemark = result[i].trackingLabelRemark;
              masterWics[i].reasonNotBeingMarkedWithTrackingLabel = result[i]
                .reasonNotBeingMarkedWithTrackingLabel
                ? result[i].reasonNotBeingMarkedWithTrackingLabel.split(",")
                : [];
              masterWics[i].sampleSubmission = result[i].sampleSubmission;
              masterWics[i].returnSample = result[i].returnSample;
            }
          }

          let factory = {
            factoryAddress: data.factory.factoryAddress,
            factoryContactEmail: data.factory.factoryContactEmail,
            factoryContactNumber: data.factory.factoryContactNumber,
            factoryContactPerson: data.factory.factoryContactPerson,
            factoryName: data.factory.factoryName,
            factoryId: data.factory.id,
            countryOfOriginCityId: data.factory.countryOfOriginCityId,
            countryOfOriginCityName: data.factory.countryOfOriginCityName,
            countryOfOriginCountryId: data.factory.countryOfOriginCountryId,
            countryOfOriginCountryName: data.factory.countryOfOriginCountryName,
            countryOfOriginStateId: data.factory.countryOfOriginStateId,
            countryOfOriginStateName: data.factory.countryOfOriginStateName,
            placeOfManufactureCityId: data.factory.placeOfManufactureCityId,
            placeOfManufactureCityName: data.factory.placeOfManufactureCityName,
            placeOfManufactureCountryId: data.factory.placeOfManufactureCountryId,
            placeOfManufactureCountryName: data.factory.placeOfManufactureCountryName,
            placeOfManufactureStateId: data.factory.placeOfManufactureStateId,
            placeOfManufactureStateName: data.factory.placeOfManufactureStateName,
          };

          if (data.factory.countryOfOriginCountryId) {
            getStates(data.factory.countryOfOriginCountryId, "origin");
          }

          if (data.factory.countryOfOriginStateId) {
            getCities(data.factory.countryOfOriginStateId, "origin");
          }

          if (data.factory.placeOfManufactureCountryId) {
            getStates(data.factory.placeOfManufactureCountryId, "manufacture");
          }

          if (data.factory.placeOfManufactureStateId) {
            getCities(data.factory.placeOfManufactureStateId, "manufacture");
          }

          setTrfFormData({
            factory: factory,
            masterWics: masterWics,
          });
        });
      }
    });
  }, [submissionId]);

  const handleFactoryDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "countryOfOriginCountryId") {
      getStates(value, "origin");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          countryOfOriginStateId: "",
          countryOfOriginStateName: "",
          countryOfOriginCityId: "",
          countryOfOriginCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "countryOfOriginStateId") {
      getCities(value, "origin");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          countryOfOriginCityId: "",
          countryOfOriginCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "placeOfManufactureCountryId") {
      getStates(value, "manufacture");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          placeOfManufactureStateId: "",
          placeOfManufactureStateName: "",
          placeOfManufactureCityId: "",
          placeOfManufactureCityName: "",
          [name]: value,
        },
      }));
    } else if (name === "placeOfManufactureStateId") {
      getCities(value, "manufacture");
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          placeOfManufactureCityId: "",
          placeOfManufactureCityName: "",
          [name]: value,
        },
      }));
    } else {
      setTrfFormData((prevState) => ({
        ...prevState,
        factory: {
          ...prevState.factory,
          [name]: value,
        },
      }));
    }
  };

  const handleMasterWicDataChange = (e, index) => {
    const { name, value } = e.target;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleMasterWicDataRadioChange = (e, index) => {
    const { name, value } = e.target;

    let newValue = value === "yes" ? true : false;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item[name] = newValue;
        }

        return item;
      }),
    }));
  };

  const handleMasterWicDataCheckBoxChange = (e, index) => {
    const { value } = e.target;

    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          if (item.reasonNotBeingMarkedWithTrackingLabel.indexOf(value) >= 0) {
            item.reasonNotBeingMarkedWithTrackingLabel =
              item.reasonNotBeingMarkedWithTrackingLabel.filter((label) => label !== value);
          } else {
            item.reasonNotBeingMarkedWithTrackingLabel.push(value);
          }
        }

        return item;
      }),
    }));
  };

  const handleExpandClick = (index) => {
    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item, i) => {
        if (i === index) {
          item.showMore = !item.showMore;
        }

        return item;
      }),
    }));
  };

  const handleExpandAll = () => {
    setTrfFormData((prevState) => ({
      ...prevState,
      masterWics: prevState.masterWics.map((item) => {
        item.showMore = true;

        return item;
      }),
    }));
  };

  const handleConfirmQuoteSubmit = () => {
    // if (!trfFormData.factory.placeOfManufactureCityId || !trfFormData.factory.countryOfOrigin) {
    //   return alert("missing data");
    // }

    let tempTrfFormData = JSON.parse(JSON.stringify(trfFormData));

    let hasMissingData = false;

    for (let i = 0; i < tempTrfFormData.masterWics.length; i++) {
      if (
        !tempTrfFormData.masterWics[i].additionalWicNumber ||
        !tempTrfFormData.masterWics[i].styleNumber ||
        !tempTrfFormData.masterWics[i].contentSizeCount ||
        !tempTrfFormData.masterWics[i].dateOfManufacture ||
        !tempTrfFormData.masterWics[i].sampleSubmission ||
        (tempTrfFormData.masterWics[i].isGoodNotForResale &&
          !tempTrfFormData.masterWics[i].goodNotForResaleReason) ||
        (tempTrfFormData.masterWics[i].isFollowingCpsiaTrackingLabel &&
          !tempTrfFormData.masterWics[i].trackingLabelRemark) ||
        (!tempTrfFormData.masterWics[i].isFollowingCpsiaTrackingLabel &&
          tempTrfFormData.masterWics[i].reasonNotBeingMarkedWithTrackingLabel.length === 0)
      ) {
        hasMissingData = true;
        break;
      }

      if (tempTrfFormData.masterWics[i].dateOfManufacture) {
        tempTrfFormData.masterWics[i].dateOfManufacture = moment(
          tempTrfFormData.masterWics[i].dateOfManufacture,
        ).format("YYYY-MM-DD");
      }
    }

    if (hasMissingData) {
      return alert("missing data");
    }

    setIsDisabledConformButton(true);

    atnConfirmQuotation({
      submissionId: submissionId,
      quotationId: quotationId,
    }).then(({ data, message }) => {
      if (data) {
        atnCreateTrfForm({
          factory: tempTrfFormData.factory,
          trf: tempTrfFormData.masterWics,
        }).then(() => {
          setIsDisabledConformButton(false);
          history.push(`/supplier/submissions/${submissionId}`);
        });
      }
    });
  };

  return (
    <>
      <MenuBar />
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.text}>Fill in TRF Information to Confirm Quote</Typography>
          <Form
            data={trfFormData}
            countryList={countryList}
            stateList={stateList}
            cityList={cityList}
            hideState={hideState}
            manufactureStateList={manufactureStateList}
            manufactureCityList={manufactureCityList}
            hideManufactureState={hideManufactureState}
            handleFactoryDataChange={handleFactoryDataChange}
            handleMasterWicDataChange={handleMasterWicDataChange}
            handleMasterWicDataRadioChange={handleMasterWicDataRadioChange}
            handleMasterWicDataCheckBoxChange={handleMasterWicDataCheckBoxChange}
            handleExpandClick={handleExpandClick}
            handleExpandAll={handleExpandAll}
          />
          <Button
            className={classes.bottomButton}
            onClick={handleConfirmQuoteSubmit}
            disabled={isDisabledConformButton}
          >
            Continue
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default ConfirmQuote;
