import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 2,
    fontSize: 14,
  },
}));

const Button = (props) => {
  const classes = useStyles();

  const {
    children,
    myRef,
    className = "",
    type = "button",
    variant = "contained",
    color = "primary",
    endIcon,
    disabled = false,
    onClick,
    cy,
  } = props;

  return (
    <MuiButton
      ref={myRef}
      type={type}
      variant={variant}
      className={classnames(classes.button, classes[variant], className)}
      color={color}
      endIcon={endIcon}
      disabled={disabled}
      onClick={onClick}
      data-cy={cy}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
