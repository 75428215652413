import React from "react";
import _get from "lodash/get";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import Button from "components/input/Button";
import Divider from "components/display/Divider";
import Table from "components/table/Table";
import Checkbox from "components/input/Checkbox";
import Radio from "components/input/Radio";

import dataJSON from "pages/lab/UpdateStatus/data";

const useStyles = makeStyles((theme) => ({
  gridContainer: {},
  gridItemRight: {
    paddingLeft: theme.spacing(4),
  },
  container: {
    // width: '100%',
    // margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#232B3A",
  },
  blackText: {
    color: "#000000",
  },
  textLabel: {
    paddingBottom: theme.spacing(1),
  },
  iconButton: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  tableCell: {
    border: "none",
    padding: `${theme.spacing(1)}px`,
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
}));

const InvoiceUpdate = ({ data }) => {
  const classes = useStyles();

  const { nextAction, type } = data;

  const handleNext = () => {
    if (nextAction) nextAction();
  };

  const List = ({ title, items = [] }) => {
    return (
      <Paper className={classes.container}>
        <Typography className={classnames(classes.title, classes.blackText)}>{title}</Typography>
        <Divider className={classes.divider} />
        {items.map((item) => (
          <Grid key={item.id} container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classnames(classes.text, classes.blackText)}>
                {item.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography className={classnames(classes.text, classes.blackText)}>
                {item.detail}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    );
  };

  const ProductDetails = () => {
    const GridRow = ({ label, value }) => (
      <>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Typography className={classnames(classes.text, classes.blackText)}>{label}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Typography className={classnames(classes.text, classes.blackText)}>{value}</Typography>
        </Grid>
      </>
    );
    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Product Details
        </Typography>
        <Divider className={classes.divider} />
        <Grid container alignItems="center">
          <GridRow label="WIC Number" value="Information Detail" />
          <GridRow label="Product Name" value="Information Detail" />
          <GridRow label="Brand" value="Information Detail" />
          <GridRow label="Sample Photo(s)" value="Information Detail" />
        </Grid>
      </Box>
    );
  };

  const DesignTestPlan = () => {
    const currencies = [
      { id: 1, label: "USD", value: "USD" },
      { id: 2, label: "HKD", value: "HKD" },
    ];

    const [tableData, setTableData] = React.useState(dataJSON.testPlan);
    const [currency, setCurrency] = React.useState(currencies[0]);

    const handleChangeCurrency = (value) => {
      if (value.target) setCurrency(value.target);
    };

    const updateTableData = (id, name, value) => {
      const arr = tableData.map((item) => {
        return item.id === id ? { ...item, [name]: value } : item;
      });

      setTableData(arr);
    };

    const tableColumn = [
      {
        columnId: "item",
        label: "Test Item",
        classes: { cell: classnames(classes.tableCell, classes.tableCellNoPaddingLeft) },
        render: (data) => {
          return (
            <TextInput
              placeholder="Test Item"
              name="item"
              value={data.item}
              onChange={(event) =>
                updateTableData(data.id, "item", _get(event.target, "value", data.item))
              }
            />
          );
        },
      },
      {
        columnId: "quantity",
        label: "Quantity",
        classes: { cell: classes.tableCell },
        render: (data) => {
          return (
            <TextInput
              placeholder="Quantity"
              name="quantity"
              value={data.quantity}
              onChange={(event) =>
                updateTableData(data.id, "quantity", _get(event.target, "value", data.quantity))
              }
            />
          );
        },
      },
      {
        columnId: "cost",
        label: "Cost",
        classes: { cell: classes.tableCell },
        render: (data) => {
          return (
            <TextInput
              placeholder="Cost"
              name="cost"
              value={data.cost}
              onChange={(event) =>
                updateTableData(data.id, "cost", _get(event.target, "value", data.cost))
              }
            />
          );
        },
      },
      {
        columnId: "amount",
        label: "Sub-Total",
        classes: {
          cell: classnames(classes.tableCell, classes.tableCellRight),
        },
        render: ({ cost = 0, quantity = 1 }) => {
          return `${cost * quantity} ${currency.value}`;
        },
      },
      {
        columnId: "remove",
        classes: {
          cell: classnames(classes.tableCell, classes.tableCellRight),
        },
        render: (data) => {
          return (
            <IconButton
              className={classes.iconButton}
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => handleRemoveTestItem(data.id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];

    const handleAddNewTestItem = () => {
      const newItem = {
        id: uuidv4(),
        item: "",
        quantity: 1,
        cost: "",
        amount: "",
      };

      setTableData([...tableData, newItem]);
    };

    const handleRemoveTestItem = (id) => {
      setTableData(tableData.filter((item) => item.id !== id));
    };

    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Design Test Plan
        </Typography>
        <Divider className={classes.divider} />
        <Select
          label="Currency"
          name="currency"
          value={currency.value}
          options={currencies}
          handleChange={handleChangeCurrency}
        />
        <Box py={4}>
          <Table data={tableData} columnData={tableColumn} noPagination />
          <Button variant="outlined" onClick={handleAddNewTestItem}>
            + Add new test item
          </Button>
        </Box>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} sm={12} md={9} lg={9}></Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  Sub-Total:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  3000.00
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  Pre-Payment:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  -3000.00
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  Sub-total:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <TextInput
                  placeholder="Sub-total %"
                  name="subTotal"
                  // value={}
                  onChange={() => {}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  Total Price:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  0.00 USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const AdditionalInformation = () => {
    const [isPackagePrice, setIsPackagePrice] = React.useState(false);

    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Additional Information
        </Typography>
        <Divider className={classes.divider} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              Sample Size
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextInput
              placeholder="Sample Size"
              name="sampleSize"
              // value={}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              This is a package price
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Checkbox
              list={[
                {
                  label: "Yes",
                  value: isPackagePrice,
                  onChange: (value) => setIsPackagePrice(value),
                },
              ]}
            />
          </Grid>
          {isPackagePrice && (
            <>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classnames(classes.text, classes.blackText)}>
                  Package Price
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <TextInput
                  placeholder="Package price"
                  name="packagePrice"
                  // value={}
                  onChange={() => {}}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              Estimated Turnaround Time (TAT)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextInput
              placeholder="Estimated Turnaround Time (TAT)"
              name="tat"
              // value={}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              Delivery Notes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Button variant="outlined">Upload Document(s)</Button>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>
              Request Pre-Payment
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box pl={1}>
              <Radio
                direction="row"
                name="requestPrePayment"
                options={[
                  {
                    value: "no",
                    label: "No",
                  },
                  {
                    value: "yes",
                    label: "Yes",
                  },
                ]}
                // defaultValue={}
                // onChange={}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Typography className={classnames(classes.text, classes.blackText)}>Remarks</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <TextInput
              placeholder="Additional Remarks (Optional)"
              name="remarks"
              // value={}
              onChange={() => {}}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid container justify="space-between" className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <List
          title="Project Details"
          items={[
            { id: 1, title: "Info title", detail: "Information Detail" },
            { id: 2, title: "Info title", detail: "Information Detail" },
            { id: 3, title: "Info title", detail: "Information Detail" },
            { id: 4, title: "Info title", detail: "Information Detail" },
          ]}
        />
        <Box pt={4}>
          <List
            title="Task Details"
            items={[
              { id: 1, title: "Info title", detail: "Information Detail" },
              { id: 2, title: "Info title", detail: "Information Detail" },
              { id: 3, title: "Info title", detail: "Information Detail" },
            ]}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} className={classes.gridItemRight}>
        <Paper className={classes.container}>
          <Box py={4}>
            <ProductDetails />
            <Box py={4}>
              <DesignTestPlan />
            </Box>
            <AdditionalInformation />
          </Box>
        </Paper>
        <Button className={classes.bottomButton} onClick={handleNext}>
          {type === "cost" ? "Confirm" : "Continue"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default InvoiceUpdate;
