import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classnames from "classnames";

import Status from "components/display/Status";

const useStyles = makeStyles(() => ({
  subTitle: {
    fontSize: 12,
  },
}));

const TaskHeaderStatus = ({ className, labStatus, status }) => {
  const classes = useStyles();

  return (
    <Typography className={classnames(classes.subTitle, className)}>
      Status:{" "}
      {labStatus === "rejected" ? (
        <Status value={"closed"} className={{ text: classes.subTitle }} withText withIcon={false} />
      ) : (
        <Status value={status} className={{ text: classes.subTitle }} withText withIcon={false} />
      )}
    </Typography>
  );
};

export default memo(TaskHeaderStatus);
