import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { atnResetPasswordWithToken } from "actions/authActions";
import TextInput from "components/input/TextInput";

const useStyles = makeStyles(() => ({
  pageTitle: {
    textAlign: "center",
    marginTop: 120,
    fontSize: 28,
    fontWeight: 500,
  },
  card: {
    marginTop: 80,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 6,
  },
  inputField: {
    width: "100%",
  },
  fieldPadding: {
    paddingTop: 20,
  },
  continueBtn: {
    marginTop: 20,
    width: "100%",
  },
  messageContainer: {
    paddingTop: 15,
  },
}));

const ResetPasswordWithToken = ({ match, history }) => {
  const token = match.params.token;
  const classes = useStyles();

  const [newPassword, setNewPassword] = useState("");
  const [correctFormat, setCorrectFormat] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    setNewPassword(value);

    let passwordFormat = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$");
    setCorrectFormat(passwordFormat.test(value));
  };

  const handleGoToLogin = () => {
    setOpenSuccessDialog(false);
    history.push("/login");
  };

  const Submit = () => {
    atnResetPasswordWithToken({
      token: token,
      password: newPassword,
    }).then(({ data, message }) => {
      if (data) {
        setOpenSuccessDialog(true);
      } else {
        alert(message);
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Box className={classes.pageTitle}>Reset Password</Box>
      <Box className={classes.card}>
        <Box className={classes.title}>New Password</Box>
        <TextInput
          type="password"
          placeholder="New Password"
          name="newPassword"
          value={newPassword}
          additionalClass={classes.inputField}
          onChange={handleChange}
        />

        <Box className={classes.messageContainer}>
          <Box>Your Password must contain:</Box>
          <Box>* At least one uppercase character</Box>
          <Box>* At least one lowercase character</Box>
          <Box>* At least one number</Box>
          <Box>* Minimum 8 characters</Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={classes.continueBtn}
          onClick={Submit}
          disabled={!newPassword || !correctFormat}
        >
          SUBMIT
        </Button>
      </Box>

      <Dialog open={openSuccessDialog}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>Your passowrd has been updated successfully!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoToLogin} color="primary">
            GO TO LOGIN
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ResetPasswordWithToken;
