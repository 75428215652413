import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "@material-ui/core/Dialog";

import TextInput from "components/input/TextInput";
import OutlinedButton from "components/input/OutlinedButton";
import UploadFiles from "components/input/UploadFiles";

import SelectSearchPOTable from "components/table/SelectSearchPOTable";
import SelectedPoSpuTable from "components/table/SelectedPoSpuTable";

import SelectSearchPBTable from "components/table/SelectSearchPBTable";
import SelectedPBTable from "components/table/SelectedPBTable";

import Select from "components/input/Select";
import Program777Chip from "components/display/Program777Chip";

import { atnGetPbEveryday, atnGetPoEveryday } from "actions/taskActions";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  grayText: {
    color: "#777",
  },
  small: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 300,
  },
  inputFieldFull: {
    width: "100%",
  },
  smallInputField: {
    width: 200,
  },
  largeInputField: {
    width: 450,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  smallFieldPadding: {
    paddingTop: 20,
  },
  blockFull: {
    width: "100%",
    paddingRight: 10,
    marginTop: 10,

    "&:last-child": {
      paddingRight: 0,
    },
  },
  blockPadding: {
    paddingRight: 10,
  },
  inputPadding: {
    paddingTop: 29,
  },
  WicContainer: {
    border: "1px solid #777777",
    padding: 20,
    position: "relative",
    marginBottom: 20,
  },
  WicTitleContainer: {
    position: "relative",
  },
  WicTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  itemName: {
    fontSize: 12,
    color: "#777777",
    marginTop: 4,

    "& span": {
      color: "#003DA5",
      textTransform: "capitalize",
    },
  },
  expandBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  tasksContainer: {
    position: "absolute",
    top: 32,
    right: 70,
  },
  task: {
    background: "#EBEDEF",
    borderRadius: 2,
    fontSize: 12,
    color: "#232B3A",
    padding: "3px 6px",
    marginRight: 10,
  },
  poDataContainer: {
    paddingBottom: 20,
  },
  deleteBtnContainer: {
    paddingTop: 10,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    paddingTop: 17,
  },
  btnEdit: {
    display: "block",
    marginTop: 10,
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 1000,
      },
    },
  },
  inputFieldPick: {
    width: 400,
  },
}));

const WicDetails = ({
  task,
  masterWic = {},
  masterWicIndex,
  openTaskData = {
    masterWics: [],
    tasks: [],
  },
  handleOpenTaskDataChange,
}) => {
  const classes = useStyles();

  const taskTypePickUp = [
    { value: "PAC", label: "PAC" },
    { value: "STD", label: "ST" },
    { value: "PAC,STD", label: "Both" },
  ];

  const buyerCompanyName = masterWic.buyerCompanyName;

  const category = masterWic.category;
  const isEveryday = category === "everyday";

  const isProgram777 = task?.isProgram777;

  const [taskType, setTaskType] = useState("");

  //po
  const [openSelectPODialog, setOpenSelectPODialog] = useState(false);
  const [listRelatedPO, setListRelatedPO] = useState([]);
  const [filteredRelatedPOs, setFilteredRelatedPOs] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [selectedPOsShow, setSelectedPOsShow] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [selectedPOIds, setSelectedPOIds] = useState([]);

  //pb
  const [openSelectPBDialog, setOpenSelectPBDialog] = useState(false);
  const [selectedPBsShow, setSelectedPBsShow] = useState([]);
  const [selectedPBs, setSelectedPBs] = useState([]);
  const [selectedPBIds, setSelectedPBIds] = useState([]);
  const [filteredRelatedPBs, setFilteredRelatedPBs] = useState([]);
  const [listRelatedPB, setListRelatedPB] = useState([]);
  const [searchKeywordPB, setSearchKeywordPB] = useState("");

  const [showProgram777, setShowProgram777] = useState(false);

  useEffect(() => {
    const displayProgram777 = isShowProgram777(task?.triggeredBy);

    setShowProgram777(displayProgram777);
  }, [isProgram777, task.triggeredBy]);

  const handleMasterWicExpand = (index) => {
    let masterWics = openTaskData.masterWics;

    masterWics[index].expanded = !masterWics[index].expanded;

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddNewPo = (index) => {
    let masterWics = openTaskData.masterWics;

    masterWics[index].po.push({
      quantityReadyForInspection: "",
      firstShipmentTotalQuantity: "",
      fullPoTotalQuantity: "",
      poNumber: "",
      poQuantity: "",
    });

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemovePoData = (WicIndex, poIndex) => {
    let masterWics = openTaskData.masterWics;

    // masterWics[WicIndex].po = masterWics[WicIndex].po.splice(poIndex, 1);
    masterWics[WicIndex].po = masterWics[WicIndex].po.filter((item, i) => i !== poIndex);

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handlePoDataChange = (e, WicIndex, poIndex) => {
    const { name, value } = e.target;

    let masterWics = openTaskData.masterWics;

    masterWics[WicIndex].po[poIndex][name] = value;

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddAisUPCInformationFiles = (newFiles) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].aisUPCInformation =
      masterWics[masterWicIndex].aisUPCInformation.concat(newFiles);

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveAisUPCInformationFile = (index) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].aisUPCInformation = masterWics[
      masterWicIndex
    ].aisUPCInformation.filter((item, i) => i !== index);

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleAddAisOuterInnerFiles = (newFiles) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].aisOuterInner =
      masterWics[masterWicIndex].aisOuterInner.concat(newFiles);

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveAisOuterInnerFile = (index) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].aisOuterInner = masterWics[masterWicIndex].aisOuterInner.filter(
      (item, i) => i !== index,
    );

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  // ==================== START HANDLE SELECT PO ====================
  const fetchPO = () => {
    let masterWics = openTaskData.masterWics;

    if (masterWics[masterWicIndex].po.length) {
      let tempPo = JSON.parse(JSON.stringify(masterWics[masterWicIndex].po));
      tempPo = tempPo.filter((item) => item.id && item.poNumber && item.poQuantity);

      setSelectedPOsShow(
        tempPo.map((item) => {
          return {
            id: item.id,
            poNumber: item.poNumber,
            poQuantity: item.poQuantity,
            shipByDate: item.shipByDate,
            quantityReadyForInspection: item.quantityReadyForInspection || "",
          };
        }),
      );
    }

    if (masterWic.masterWicId) {
      atnGetPoEveryday({
        id: masterWic.masterWicId,
      }).then(({ data, message }) => {
        if (data) {
          if (masterWic.tasks[0].taskType === "SPUD") {
            let tempPo = [];

            for (let i = 0; i < data.length; i++) {
              if (
                (data[i].pacStatus || "").toLowerCase() === "no" ||
                (data[i].gccStatus || "").toLowerCase() === "no" ||
                (data[i].qaaStatus || "").toLowerCase() === "no"
              ) {
                tempPo.push(data[i]);
              }
            }

            setListRelatedPO(tempPo);
          } else {
            setListRelatedPO(
              data.map((item, index) => {
                return { ...item, id: item.id };
              }),
            );
          }
        }
      });
    }
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = listRelatedPO.filter((item, i) => item.poNumber.indexOf(value) >= 0);
      setFilteredRelatedPOs(tempTasks);
    } else {
      setFilteredRelatedPOs(listRelatedPO);
    }
  };

  const handlePOSelect = (task) => {
    if (selectedPOIds.indexOf(task.id) >= 0) {
      setSelectedPOs((prevState) => {
        return prevState.filter((item) => item.id !== task.id);
      });

      setSelectedPOIds((prevState) => {
        return prevState.filter((item) => item !== task.id);
      });
    } else {
      setSelectedPOs((prevState) => {
        return prevState.concat(task);
      });

      setSelectedPOIds((prevState) => {
        return prevState.concat(task.id);
      });
    }
  };

  const handleOpenSelectPODialog = () => {
    setSelectedPOs(selectedPOsShow);

    const poIds = [];

    for (let i = 0; i < selectedPOsShow.length; i++) {
      poIds.push(selectedPOsShow[i].id);
    }

    setSelectedPOIds(poIds);
    setOpenSelectPODialog(true);
  };

  const handleCloseSelectPODialog = () => {
    setOpenSelectPODialog(false);
  };

  const handleConfirmPOSelect = () => {
    setOpenSelectPODialog(false);
    setSelectedPOsShow(selectedPOs);

    let masterWics = openTaskData.masterWics;
    masterWics[masterWicIndex].po = [];

    for (let i = 0; i < selectedPOs.length; i++) {
      masterWics[masterWicIndex].po.push({
        id: selectedPOs[i].id,
        shipByDate: selectedPOs[i].shipByDate,
        poNumber: selectedPOs[i].poNumber,
        poQuantity: selectedPOs[i].poQuantity,
        quantityReadyForInspection: selectedPOs[i].quantityReadyForInspection || "",
      });
    }

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleConfirmEditPO = (e, index) => {
    const { name, value } = e.target;
    let tempPo = JSON.parse(JSON.stringify(selectedPOsShow));

    setSelectedPOsShow(
      tempPo.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }
        return item;
      }),
    );

    let masterWics = openTaskData.masterWics;
    masterWics[masterWicIndex].po.map((item, idx) => {
      if (idx === index) item[name] = value;
      return item;
    });

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemovePO = (poId) => {
    let masterWics = openTaskData.masterWics;
    let oldPo = JSON.parse(JSON.stringify(masterWics[masterWicIndex].po));

    let newPOs = oldPo.filter((item) => item.id !== poId);

    setSelectedPOsShow(newPOs);

    masterWics[masterWicIndex].po = newPOs;

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleChangeTaskType = (evt) => {
    const { value } = evt.target;
    setTaskType(value);

    let masterWics = openTaskData.masterWics;
    masterWics[masterWicIndex]["spuForEverydayTaskType"] = value;

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };
  // ==================== END HANDLE SELECT PO ====================

  // ==================== START HANDLE SELECT PB ====================
  const fetchPB = () => {
    masterWic.pb && setSelectedPBsShow(masterWic.pb);

    if (masterWic.masterWicId) {
      atnGetPbEveryday({
        id: masterWic.masterWicId,
      }).then(({ data, message }) => {
        if (data) {
          setListRelatedPB(data);
        }
      });
    }
  };

  const handleUploadE2openFiles = (newFiles) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open || [];

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open.concat(newFiles);

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemoveE2openFiles = (index) => {
    let masterWics = openTaskData.masterWics;

    masterWics[masterWicIndex].e2open = masterWics[masterWicIndex].e2open.filter(
      (item, i) => i !== index,
    );

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleSearchKeywordChangePB = (e) => {
    const { value } = e.target;

    setSearchKeywordPB(value);

    if (value) {
      let tempTasks = listRelatedPB.filter(
        (item, i) => item.pbNumber.toLowerCase().indexOf(value.toLowerCase()) >= 0,
      );
      setFilteredRelatedPBs(tempTasks);
    } else {
      setFilteredRelatedPBs(listRelatedPB);
    }
  };

  const handleConfirmEditPB = (e, index) => {
    const { name, value } = e.target;

    setSelectedPBsShow((prevState) => {
      return prevState.map((selected, i) => {
        if (i === index) {
          selected[name] = value;
        }

        return selected;
      });
    });

    let masterWics = openTaskData.masterWics;
    masterWics[masterWicIndex].pb.map((item, idx) => {
      if (idx === index) item.casePack = value;
      return item;
    });

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleRemovePB = (pbid) => {
    let masterWics = openTaskData.masterWics;

    const newPBs = masterWics[masterWicIndex].pb.filter((item) => item.id !== pbid);

    setSelectedPBsShow(newPBs);

    masterWics[masterWicIndex].pb = newPBs;

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handlePBSelect = (task) => {
    if (selectedPBIds.indexOf(task.id) >= 0) {
      setSelectedPBs((state) => state.filter((item) => item.id !== task.id));
      setSelectedPBIds((state) => state.filter((item) => item !== task.id));
      return;
    }

    setSelectedPBs((state) => state.concat(task));
    setSelectedPBIds((state) => state.concat(task.id));
  };

  const handleConfirmPBSelect = () => {
    setOpenSelectPBDialog(false);
    setSelectedPBsShow(selectedPBs);
    let masterWics = openTaskData.masterWics;
    masterWics[masterWicIndex].pb = [];

    for (const selectedPb of selectedPBs) {
      masterWics[masterWicIndex].pb.push(selectedPb);
    }

    handleOpenTaskDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const handleOpenSelectPBDialog = () => {
    const pbIds = [];

    setSelectedPBs(selectedPBsShow);

    for (const selectedPb of selectedPBsShow) {
      pbIds.push(selectedPb.id);
    }

    setSelectedPBIds(pbIds);
    setOpenSelectPBDialog(true);
  };

  const handleCloseSelectPBDialog = () => {
    setOpenSelectPBDialog(false);
  };

  // ==================== END HANDLE SELECT PB ====================

  useEffect(() => {
    let masterWics = openTaskData.masterWics;
    setTaskType(masterWics[masterWicIndex].spuForEverydayTaskType);

    fetchPO();
    fetchPB();
  }, []);

  return (
    <Box className={classes.WicContainer}>
      <Box className={classes.WicTitleContainer}>
        <Box display="flex" className={classes.WicTitle}>
          {showProgram777 && <Program777Chip />}
          Wic Number: {masterWic.wicNumber}
        </Box>
        <Box className={classes.itemName}>Wic Description: {masterWic.productName}</Box>
        <Box className={classes.itemName}>
          Category: <span>{buyerCompanyName}</span>
        </Box>
      </Box>

      <IconButton
        className={classes.expandBtn}
        onClick={() => handleMasterWicExpand(masterWicIndex)}
      >
        {masterWic.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      {masterWic.expanded && (
        <>
          {/* <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            1. Upload AIS/Stibo - Factory information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisFactoryInformation}
              handleAddFiles={handleAisFactoryinformationFiles}
              handleRemoveFile={handleRemoveAisFactoryinformationFile}
            />
          </Box> */}

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            1. Upload AIS/Stibo - UPC information
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisUPCInformation}
              handleAddFiles={handleAddAisUPCInformationFiles}
              handleRemoveFile={handleRemoveAisUPCInformationFile}
            />
          </Box>

          <Box className={classnames(classes.title, classes.smallFieldPadding)}>
            2. Upload AIS/Stibo - Outer/Inner
          </Box>
          <Box className={classes.uploadContainer}>
            <UploadFiles
              files={masterWic.aisOuterInner}
              handleAddFiles={handleAddAisOuterInnerFiles}
              handleRemoveFile={handleRemoveAisOuterInnerFile}
            />
          </Box>

          {showProgram777 ? (
            <>
              <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
                <Box>
                  <Box>3. Upload E2Open - PB Detail</Box>

                  <Box className={classnames(classes.grayText, classes.small)}>
                    Please upload a file (screencap)
                  </Box>
                </Box>
              </Box>

              <Box className={classes.uploadContainer}>
                <UploadFiles
                  files={masterWic.e2open}
                  handleAddFiles={handleUploadE2openFiles}
                  handleRemoveFile={handleRemoveE2openFiles}
                />
              </Box>

              <Box display="flex" className={classnames(classes.title, classes.smallFieldPadding)}>
                <Box>
                  <Box>4. PB Information</Box>
                  <Box className={classnames(classes.grayText, classes.small)}>
                    Please add at least one PB information
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box className={classnames(classes.title, classes.smallFieldPadding)}>
              3. PO Information
            </Box>
          )}

          {showProgram777 && isEveryday ? (
            <>
              <SelectedPBTable
                selected={selectedPBsShow}
                onRemove={handleRemovePB}
                onClickConfirm={handleConfirmEditPB}
              />

              <Dialog
                className={classes.dialog}
                open={openSelectPBDialog}
                onClose={handleCloseSelectPBDialog}
              >
                <SelectSearchPBTable
                  assignTo={task.assignTo}
                  selfInspectionValidityDate={task.selfInspectionValidityDate}
                  taskType={task.taskType}
                  options={searchKeywordPB ? filteredRelatedPBs : listRelatedPB}
                  selected={selectedPBIds}
                  searchKeyword={searchKeywordPB}
                  isDiasbledConfirm={!selectedPBIds}
                  onSelect={handlePBSelect}
                  onSearchKeywordChange={handleSearchKeywordChangePB}
                  onCloseSelectDialog={handleCloseSelectPBDialog}
                  onClickConfirm={handleConfirmPBSelect}
                />
              </Dialog>
            </>
          ) : isEveryday ? (
            <>
              <SelectedPoSpuTable
                selectedPOs={selectedPOsShow}
                handleRemovePO={handleRemovePO}
                handleConfirmEditPO={handleConfirmEditPO}
              />

              <Dialog
                className={classes.dialog}
                onClose={handleCloseSelectPODialog}
                open={openSelectPODialog}
              >
                <SelectSearchPOTable
                  //  tasks={listRelatedPO}
                  tasks={searchKeyword ? filteredRelatedPOs : listRelatedPO}
                  selectedPOIds={selectedPOIds}
                  handlePOSelect={handlePOSelect}
                  searchKeyword={searchKeyword}
                  handleSearchKeywordChange={handleSearchKeywordChange}
                  handleCloseSelectPODialog={handleCloseSelectPODialog}
                  handleOpenSelectPODialog={handleOpenSelectPODialog}
                  handleConfirmPOSelect={handleConfirmPOSelect}
                />
              </Dialog>
            </>
          ) : (
            <>
              {masterWic.po.map((data, poIndex) => (
                <Box key={poIndex} className={classes.poDataContainer}>
                  <Box display="flex">
                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>PO Number</Box>
                      <Box>
                        <TextInput
                          placeholder="PO Number"
                          name="poNumber"
                          value={data.poNumber || ""}
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>PO Quantity</Box>
                      <Box>
                        <TextInput
                          placeholder="PO Quantity"
                          name="poQuantity"
                          value={data.poQuantity || ""}
                          type="number"
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.blockPadding}>
                      <Box className={classes.subtitle}>Qty Ready for inspection</Box>
                      <Box>
                        <TextInput
                          placeholder="Qty Ready for Inspection"
                          name="quantityReadyForInspection"
                          value={data.quantityReadyForInspection || ""}
                          type="number"
                          additionalClass={classes.smallInputField}
                          onChange={(e) => handlePoDataChange(e, masterWicIndex, poIndex)}
                        />
                      </Box>
                    </Box>

                    {poIndex !== 0 && (
                      <Box className={classes.deleteBtnContainer}>
                        <IconButton onClick={() => handleRemovePoData(masterWicIndex, poIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}

          <Box className={classes.addButtonContainer}>
            {showProgram777 && isEveryday ? (
              <OutlinedButton
                label="SELECT PB"
                handleClick={() => handleOpenSelectPBDialog()}
                color="primary"
                className={classes.btnEdit}
              />
            ) : isEveryday ? (
              <OutlinedButton
                label="SELECT PO"
                handleClick={() => handleOpenSelectPODialog()}
                color="primary"
                className={classes.btnEdit}
              />
            ) : (
              <OutlinedButton
                label="+ ADD A NEW PO"
                handleClick={() => handleAddNewPo(masterWicIndex)}
                color="primary"
              />
            )}
          </Box>

          {isEveryday && (
            <>
              <Box className={classnames(classes.title, classes.smallFieldPadding)}>
                {showProgram777 ? "5." : "4."} Sample Pick Up for
              </Box>
              <Box className={classes.uploadContainer}>Task Type (Everyday)</Box>
              <Select
                label="Please Select"
                name="spuForEverydayTaskType"
                value={taskType || ""}
                options={taskTypePickUp}
                handleChange={handleChangeTaskType}
                additionalClass={classes.inputFieldPick}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default WicDetails;
