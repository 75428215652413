import api from "./api";

class submissionRemarkApi {
  static getSubmissionRemarkData(taskId) {
    return api.get(`/submission_remark/task/${taskId}`);
  }

  static createSubmissionRemark(taskId, data) {
    return api.post(`/submission_remark/task/${taskId}`, data);
  }

  static getSubmissionRemarkDataBySubmission(submissionId) {
    return api.get(`/submission_remark/submission/${submissionId}`);
  }

  static createSubmissionRemarkBySubmission(submissionId, data) {
    return api.post(`/submission_remark/submission/${submissionId}`, data);
  }
}

export default submissionRemarkApi;
