import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

import TextInput from "components/input/TextInput";
import Select from "../../../components/input/Select";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  gridContainer: {
    paddingTop: 30,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputField: {
    width: "100%",
  },
  datePicker: {
    width: "100%",
  },
  to: {
    paddingTop: 10,
    textAlign: "center",
  },
  selectFieldContainer: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  fieldPadding: {
    paddingTop: 30,
  },
}));

const currencyOptions = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "RMB",
    label: "RMB",
  },
];

const InspectionInvoice = ({
  inspectionTaskDetail,
  handleInspectionTaskDetailChange,
  handleInspectionPrepaymentChange,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.fieldPadding}
        sx={{ width: "100%" }}
      >
        <Box className={classnames(classes.sectionTitle)}>Design Inspection Plan</Box>
        <Box sx={{ width: "200px", float: "right" }}>
          <Select
            label="Currency (Select One)"
            name="currency"
            value={inspectionTaskDetail.currency}
            options={currencyOptions}
            handleChange={handleInspectionTaskDetailChange}
            additionalClass={classes.selectField}
          />
        </Box>
      </Box>

      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Number of Mandays</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <TextInput
              type="number"
              placeholder="Number of Mandays"
              name="noOfMandays"
              value={inspectionTaskDetail.noOfMandays}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Manday Unit Cost</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Manday Unit Cost"
              name="mandayUnitCost"
              value={inspectionTaskDetail.mandayUnitCost}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Travel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Travel Expenses"
              name="travelExpenses"
              value={inspectionTaskDetail.travelExpenses}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Hotel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Hotel Expenses"
              name="hotelExpenses"
              value={inspectionTaskDetail.hotelExpenses}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Other Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Other Expenses"
              name="otherExpenses"
              value={inspectionTaskDetail.otherExpenses}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Late Booking Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Late Booking Fee"
              name="lateBookingFee"
              value={inspectionTaskDetail.lateBookingFee}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Cancellation Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Cancellation Fee"
              name="cancellationFee"
              value={inspectionTaskDetail.cancellationFee}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
              startAdornment={
                <InputAdornment position="start">{inspectionTaskDetail.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Sub-total</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {inspectionTaskDetail.currency} {inspectionTaskDetail.subTotal}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>VAT %</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="VAT %"
              name="vat"
              value={inspectionTaskDetail.vat}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Total Price</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {inspectionTaskDetail.currency} {inspectionTaskDetail.totalPrice}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Remarks</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              placeholder="Remarks"
              name="remarks"
              value={inspectionTaskDetail.remarks}
              additionalClass={classes.inputField}
              onChange={handleInspectionTaskDetailChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default InspectionInvoice;
