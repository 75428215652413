import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Items from "./Items/index";
import Submissions from "./Submissions/index";
import Tasks from "./Tasks/index";

const useStyles = makeStyles(() => ({
  tabSideMenuContainer: {
    position: "fixed",
    top: 40,
    bottom: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 999,
  },
  contentContainer: {
    marginLeft: 300,
  },
  taskDataContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#F7F9FF",
  },
  tasksContainer: {
    padding: "30px 0 30px 30px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "#FAFAFA",
  },
}));

const PageContent = ({ type = "", contentData, handleActionClick }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.tasksContainer}>
        {type === "item" && <Items itemData={contentData} handleActionClick={handleActionClick} />}

        {type === "submission" && (
          <Submissions submissionData={contentData} handleActionClick={handleActionClick} />
        )}

        {type === "task" && <Tasks taskData={contentData} handleActionClick={handleActionClick} />}
      </Box>
    </Box>
  );
};

export default PageContent;
