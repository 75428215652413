import React from "react";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import {
  Check as SubmitIcon,
  Close as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import {
  atnDeleteFile,
  atnDownloadFile,
  atnUploadFile,
  atnUploadFileWithType,
} from "actions/fileActions";
import Link from "components/display/Link";
import FileUploadButton from "components/input/FileUploadButton";
import { downloadFile, getFileNameDisplay } from "utils/files";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(2),
    color: "#777777",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  iconButtonLast: {
    marginLeft: theme.spacing(1),
  },
}));

const FileUpload = (props) => {
  const classes = useStyles();

  const {
    data = [],
    config = {},
    editable = true,
    multiple = false,
    isPreviewFiles = true,
    maxWidth = false,
    buttonText = "Upload More",
  } = props;

  const [editing, setEditing] = React.useState(false);
  const [filesState, setFilesState] = React.useState(data);
  const [deletedFileIds, setDeletedFileIds] = React.useState([]);

  const handleSubmitEdit = () => {
    // upload new file
    filesState
      .filter((f) => f.fileRawData)
      .forEach(({ fileRawData }) => {
        const formData = new FormData();
        formData.append("file", fileRawData);
        atnUploadFile(formData).then((res) => {
          // save new file to server
          const params = {
            ...config,
            data: { data: [{ fileId: res.data.id, type: config.fileType }] },
          };
          atnUploadFileWithType(params).then((res) => {
            // console.log(res);
          });
        });
      });

    if (deletedFileIds.length) {
      // delete existing file
      const params = {
        ...config,
        data: {
          data: deletedFileIds.map((id) => ({
            fileId: id,
            type: config.fileType,
          })),
        },
      };
      atnDeleteFile(params).then((res) => {
        setDeletedFileIds([]);
      });
    }

    setFilesState(filesState);

    setEditing(false);
  };

  const handleCancelEdit = () => {
    setFilesState(data);
    setDeletedFileIds([]);

    setEditing(false);
  };

  const handleDelete = (fileId, fileIndex) => {
    const changedFiles = [...filesState];
    changedFiles.splice(fileIndex, 1);
    setFilesState(changedFiles);

    setDeletedFileIds([...deletedFileIds, fileId]);
  };

  const handleUpload = (newUploadFiles = []) => {
    setFilesState([
      ...filesState,
      ...newUploadFiles.map((f) => ({
        fileRawData: f,
        fileName: f.name,
        fileUrl: URL.createObjectURL(f),
      })),
    ]);

    if (!editing) setEditing(true);
  };

  const handleClickFile = ({ fileData, id, fileUrl, fileName }) => {
    if (!id) {
      // new file upload
      downloadFile(fileData, fileName);
    } else {
      // existing files
      atnDownloadFile(fileUrl).then(async (data) => {
        downloadFile(data, fileName);
      });
    }
  };

  const showActionButtons =
    editable && (filesState.length > 0 || deletedFileIds.length > 0) && editing;
  const showEditButton = editable && filesState.length > 0 && !editing;
  const showFileUploadButton = (editable && filesState.length === 0) || editing || !isPreviewFiles;

  return (
    <>
      {isPreviewFiles && (
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            {filesState.length ? (
              filesState.map((file, index) => {
                return (
                  <Box key={file.id || uuidv4()} pb={1} display="flex" alignItems="center">
                    <Box minWidth={100} maxWidth={300}>
                      <Link
                        path={file.fileUrl}
                        text={getFileNameDisplay(file.fileName)}
                        action={() => handleClickFile(file)}
                      />
                    </Box>
                    {editing && (
                      <DeleteIcon
                        className={classes.iconButton}
                        onClick={() => handleDelete(file.id, index)}
                      />
                    )}
                  </Box>
                );
              })
            ) : (
              <Box minWidth={100} maxWidth={300}>
                No file found.
              </Box>
            )}
          </Box>
          {showActionButtons && (
            <Box display="flex">
              <SubmitIcon className={classes.iconButton} onClick={() => handleSubmitEdit()} />
              <CancelIcon
                className={classnames(classes.iconButton, classes.iconButtonLast)}
                onClick={() => handleCancelEdit()}
              />
            </Box>
          )}
          {showEditButton && (
            <EditIcon
              className={classes.iconButton}
              onClick={() => {
                setEditing(true);
              }}
            />
          )}
        </Box>
      )}

      {showFileUploadButton && (
        <Box pt={editing ? 2 : 0}>
          <FileUploadButton
            id={uuidv4()}
            variant="outlined"
            multiple={multiple}
            maxWidth={maxWidth}
            onChange={(res) => handleUpload(res)}
          >
            {buttonText}
          </FileUploadButton>
        </Box>
      )}
    </>
  );
};

export default FileUpload;
