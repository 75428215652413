import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import TenderingChip from "components/display/TenderingChip";

const styles = () => ({
  tabItem: {
    position: "relative",
    // height: 79,
    minHeight: 79,
    minWidth: 300,
    width: 300,
    borderBottom: "1px solid #cccccc",
    cursor: "pointer",
  },
  selectedTabItem: {
    position: "relative",
    // height: 79,
    minHeight: 79,
    minWidth: 300,
    width: 300,
    borderBottom: "1px solid #cccccc",
    borderRight: "4px solid #005EFF",
    backgroundColor: "#E5EFFF",
  },
  title: {
    fontWeight: 500,
    fontSize: 12,
    paddingTop: 10,
    paddingLeft: 10,
  },
  contentContainer: {
    maxWidth: 220,
    paddingLeft: 10,
  },
  info: {
    color: "#777777",
    paddingTop: 2,
    fontSize: 10,
  },
  message: {
    color: "#777777",
    paddingTop: 2,
    paddingBottom: 5,
    fontSize: 10,
  },
  numberContainer: {
    position: "absolute",
    top: 25,
    right: 23,
    height: 30,
    width: 30,
    backgroundColor: "#EEEEEE",
    borderRadius: 5,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
  },
  selectedNumberContainer: {
    position: "absolute",
    top: 25,
    right: 19,
    height: 30,
    width: 30,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
  },
  number: {
    paddingTop: 7,
    fontSize: 12,
    fontWeight: 500,
  },
  noResult: {
    fontSize: 12,
    paddingTop: 10,
    paddingLeft: 10,
    color: "#777777",
  },
});

class TabPanelContent extends PureComponent {
  render() {
    const { classes, panelItems = [], selectedPanelItem = {}, handlePanelItemChange } = this.props;

    return (
      <Box>
        {panelItems.length === 0 ? (
          <Box className={classes.noResult}>No Result</Box>
        ) : (
          panelItems.map((item) => (
            <Box
              key={item.itemName}
              className={
                item.itemName === selectedPanelItem.item ? classes.selectedTabItem : classes.tabItem
              }
              onClick={() => handlePanelItemChange(item.itemName, item)}
            >
              <Box className={classes.title}>
                {item?.isTenderingProgram && <TenderingChip detail={item?.tenderingProgram} />}

                {item.title}
              </Box>
              <Box className={classes.contentContainer}>
                {item.info && <Box className={classes.info}>{item.info}</Box>}
                <Box className={classes.message}>{item.message}</Box>
              </Box>
              <Box
                className={
                  item.itemName === selectedPanelItem.item
                    ? classes.selectedNumberContainer
                    : classes.numberContainer
                }
              >
                <Box className={classes.number}>{item.number}</Box>
              </Box>
            </Box>
          ))
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(TabPanelContent);
