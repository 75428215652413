import React, { memo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import FileUpload from "components/input/FileUpload";

import { NO_DATA } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  blackText: {
    color: "#000000",
  },
  tableTitle: {
    color: "#777777",
    fontSize: 10,
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    padding: 10,
    border: "1px solid #777777",
    // borderBottom: "none",
    fontSize: 10,
  },
  gridItemRight: {
    borderLeft: "none",
  },
  gridItemBottom: {
    borderBottom: "1px solid #777777",
  },
  borderBottomNone: {
    borderBottom: "none",
  },
  borderRightNone: {
    borderRight: "none",
  },
}));

const E2OpenTable = ({ data = [] }) => {
  const classes = useStyles();

  return (
    <Box py={2}>
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Upload E2open - PB detail
        </Typography>

        <Box className={classes.gridContainer}>
          <Grid container>
            <Grid item xs={3} className={classnames(classes.gridItem, classes.borderRightNone)}>
              BBR - PB detail - Screencap
            </Grid>
            <Grid item xs className={classnames(classes.gridItem)}>
              {data && data.length > 0
                ? data.map(({ id, fileName, location }) => {
                    return (
                      <Box display="flex">
                        <FileUpload data={[{ id, fileName, fileUrl: location }]} editable={false} />
                      </Box>
                    );
                  })
                : NO_DATA}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(E2OpenTable);
