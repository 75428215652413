import * as types from "./types";
import { failure, success } from "../commonAction";

import { atnGetSubmissionTasks } from "../../actions/submissionActions";

//navigate to invoice tab
export const ratnNavigateToInvoiceTab = (bool) => {
  return {
    type: types.NAVIGATE_SUBMISSION_INVOICE_TAB,
    show: bool,
  };
};

//supplier actions in pb revision
export const ratnTogglePbErrorModal = (bool) => {
  return {
    type: types.TOGGLE_PB_ERROR_MODAL,
    open: bool,
  };
};

export const ratnTogglePbRevisionModal = (bool) => {
  return async (dispatch, getState) => {
    try {
      const tasksWithNotExpand = getState().submissionDetail.tasks.map((task) => {
        return {
          ...task,
          expanded: false,
        };
      });

      return dispatch(
        success(types.TOGGLE_SUBMISSION_PB_REVISION_MODAL, {
          open: bool,
          tasks: tasksWithNotExpand,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.TOGGLE_SUBMISSION_PB_REVISION_MODAL_FAILURE, err));
    }
  };
};

export const ratnGetSubmissionTasksData = (submissionId, taskType, submission) => {
  return async (dispatch, getState) => {
    try {
      const res = await atnGetSubmissionTasks({ submissionId, taskType });

      const tasksWithExpand =
        (await res.data.tasks.map((task) => {
          return {
            ...task,
            expanded: false,
          };
        })) || [];

      return dispatch(
        success(types.GET_SUBMISSION_TASKS_DATA, {
          tasks: tasksWithExpand,
          submission,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.GET_SUBMISSION_TASKS_DATA_FAILURE, err));
    }
  };
};

export const ratnExpandPbWicDetail = (masterWicIndex) => {
  return async (dispatch, getState) => {
    try {
      let prevStateTasks = getState().submissionDetail.tasks;

      prevStateTasks = prevStateTasks.map((task, index) => {
        if (masterWicIndex === index) {
          return {
            ...task,
            expanded: !task.expanded,
          };
        } else {
          return task;
        }
      });

      return dispatch(
        success(types.EXPAND_SUBMISSION_PB_WIC_DETAIL, {
          tasks: prevStateTasks,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.EXPAND_SUBMISSION_PB_WIC_DETAIL_FAILURE, err));
    }
  };
};

export const ratnExpandAllPbWicDetail = (expanded) => {
  return async (dispatch, getState) => {
    try {
      let prevStateTasks = getState().submissionDetail.tasks;

      prevStateTasks = prevStateTasks.map((task) => {
        return {
          ...task,
          expanded,
        };
      });

      return dispatch(
        success(types.EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL, {
          tasks: prevStateTasks,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL_FAILURE, err));
    }
  };
};

export const ratnConvertWicFiles = ({
  productSpecification,
  artworks,
  aisUPCInformation,
  aisOuterInner,
  aisFactoryInformation,
  e2open,
}) => {
  return async (dispatch, getState) => {
    try {
      let prevStateTasks = getState().submissionDetail.tasks;

      prevStateTasks = prevStateTasks.map((task) => {
        return {
          ...task,
          productSpecification,
          artworks,
          aisUPCInformation,
          aisOuterInner,
          aisFactoryInformation,
          e2open,
        };
      });

      return dispatch(
        success(types.CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES, {
          tasks: prevStateTasks,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES_FAILURE, err));
    }
  };
};

export const ratnUpdatePbWicDetail = (tasks) => {
  return async (dispatch, getState) => {
    try {
      return dispatch(
        success(types.UPDATE_SUBMISSION_PB_WIC_DETAIL, {
          tasks,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.UPDATE_SUBMISSION_PB_WIC_DETAIL_FAILURE, err));
    }
  };
};

//lab actions in pb revision
export const ratnToggleLabReportModal = (bool) => {
  return (dispatch, getState) => {
    try {
      return dispatch(
        success(types.TOGGLE_SUBMISSION_LAB_REPORT_MODAL, {
          open: bool,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.TOGGLE_SUBMISSION_LAB_REPORT_MODAL_FAILURE, err));
    }
  };
};

export const ratnGetCurrentTaskDetail = (taskDetail) => {
  return (dispatch, getState) => {
    try {
      return dispatch(
        success(types.GET_SUBMISSION_CURRENT_TASK_DETAIL, {
          taskDetail,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.GET_SUBMISSION_CURRENT_TASK_DETAIL_FAILURE, err));
    }
  };
};

export const ratnUpdateCurrentTaskReport = (taskReports) => {
  return async (dispatch, getState) => {
    try {
      return dispatch(
        success(types.UPDATE_SUBMISSION_CURRENT_TASK_REPORT, {
          taskReports,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.UPDATE_SUBMISSION_CURRENT_TASK_REPORT_FAILURE, err));
    }
  };
};

export const ratnGetCurrentTaskStatus = (currentTaskStatus) => {
  return async (dispatch, getState) => {
    try {
      return dispatch(
        success(types.GET_SUBMISSION_TASK_STATUS, {
          currentTaskStatus,
        }),
      );
    } catch (err) {
      return dispatch(failure(types.GET_SUBMISSION_TASK_STATUS_FAILURE, err));
    }
  };
};
