import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import Button from "components/input/Button";
import TextInput from "components/input/TextInput";

import { LAB_TYPE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#232B3A",
  },
  blackText: {
    color: "#000000",
  },
  textLabel: {
    paddingBottom: theme.spacing(1),
  },
}));

const UpdateInformation = ({ data }) => {
  const classes = useStyles();

  const { labType, nextAction } = data;

  const handleNext = () => {
    if (nextAction) nextAction();
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.item} py={4}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            {labType === LAB_TYPE.type01
              ? "1. Update Expected Completion Date"
              : "1. Update Confirmed Inspection Date"}
          </Typography>
          <Typography className={classnames(classes.text, classes.grayText, classes.textLabel)}>
            {labType === LAB_TYPE.type01
              ? "Expected Completion Date (ECD)"
              : "Confirmed Inspection Date"}
          </Typography>
          <TextInput
            placeholder="DD/MMM/YYYY"
            name="ecd"
            // value={}
            additionalClass=""
            onChange={() => {}}
          />
        </Box>
        <Box className={classes.item} py={4}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            2. Inhouse Lab Number (Optional)
          </Typography>
          <Typography className={classnames(classes.text, classes.grayText, classes.textLabel)}>
            Lab Number
          </Typography>
          <TextInput
            placeholder="Lab Number"
            name="ecd"
            // value={}
            additionalClass=""
            onChange={() => {}}
          />
        </Box>
      </Paper>
      <Button className={classes.bottomButton} onClick={handleNext}>
        Confirm
      </Button>
    </>
  );
};

export default UpdateInformation;
