import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Tab, Tabs } from "@material-ui/core";

import POTable from "./components/POTable";
import POSeriesTable from "./../../buyer/PO/POSeriesTable";
import PBTable from "./components/PBTable";

import SearchPOButton from "components/input/SearchPOButton";
import GifLoading from "components/display/GifLoading";

// import pbTable from "./pbTable.json";
import { atnGetDailyPOList, atnGetDailyPOSeriesList } from "actions/poActions";

import { atnGetPBList } from "actions/pbActions";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    padding: "10px 30px",
  },

  searchContent: {
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "500",

    "& span:not(:last-child)": {
      marginRight: "10px",
    },
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  wrapperTab: {
    width: "100%",
    boxShadow: "none",
    alignItems: "center",
    display: "flex",
    background: "none",

    "& .MuiTabs-indicator": {
      background: "transparent",
    },
  },

  tabHeader: {
    minHeight: "initial",
  },

  boldCell: {
    fontWeight: 900,
  },

  tab: {
    fontSize: 10,
    color: "#777777",
    padding: "6px 13px",
    textDecoration: "none",
    textTransform: "capitalize",
    fontWeight: "normal",
    minWidth: "initial",
    marginRight: 13,
    borderRadius: 2,
    minHeight: 28,
    background: "#EBEDEF",

    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "#2154A2",
      background: "#AEC5E9",
      boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    },

    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },

    "& svg": {
      marginRight: 12,
      marginBottom: "0 !important",
    },
  },
}));

const PO = ({ masterWicId, isProgram777 }) => {
  const classes = useStyles();

  const [loadingTable, setLoadingTable] = useState(false);

  const [listPODaily, setListPODaily] = useState([]);
  const [listPB, setListPB] = useState([]);
  const [listPODailySeries, setListPODailySeries] = useState([]);

  const [isGetPODone, setIsGetPODone] = useState(false);
  const [isGetPOSeriesDone, setIsGetPOSeriesDone] = useState(false);
  const [isGetPBDone, setIsGetPBDone] = useState(false);

  useEffect(() => {
    if (isProgram777) {
      getListPB({
        rowsPerPage: 999999999,
        filters: [{ name: "masterWicId", value: [masterWicId] }],
      });
    } else {
      getListDailyPO({
        rowsPerPage: 999999999,
        filters: [{ name: "masterWicId", value: [masterWicId] }],
      });
    }
  }, []);

  const getListDailyPO = (data) => {
    setLoadingTable(true);

    atnGetDailyPOList(data).then(({ data, message }) => {
      setListPODaily(data.list);
      setLoadingTable(false);
    });
  };

  const getListPB = (data) => {
    setLoadingTable(true);

    atnGetPBList(data).then(({ data, message }) => {
      setListPB(data.list);
      setLoadingTable(false);
      setIsGetPBDone(true);
    });
  };

  const getListDailyPOSeries = (data) => {
    setLoadingTable(true);

    atnGetDailyPOSeriesList(data).then(({ data, message }) => {
      setListPODailySeries(data ? data.list : []);
      setLoadingTable(false);
      setIsGetPOSeriesDone(true);
    });
  };

  const [tab, setTab] = useState(isProgram777 ? 2 : 0);
  const [nameTable, setNameTable] = useState(isProgram777 ? "PB" : "PO");

  const handleTabChange = (event, value) => {
    setSearchPOQueryData("");
    setSearchPOSeriesQueryData("");
    setSearchPBQueryData("");

    if (value === 0 && !isGetPODone) {
      getListDailyPO({
        rowsPerPage: 999999999,
        filters: [{ name: "masterWicId", value: [masterWicId] }],
      });
    }

    if (value === 1 && !isGetPOSeriesDone) {
      getListDailyPOSeries({
        rowsPerPage: 999999999,
        filters: [{ name: "masterWicId", value: [masterWicId] }],
      });
    }

    if (value === 2 && !isGetPBDone) {
      getListPB({
        rowsPerPage: 999999999,
        filters: [{ name: "masterWicId", value: [masterWicId] }],
      });
    }

    switch (value) {
      case 0:
        setNameTable("PO");
        break;
      case 1:
        setNameTable("PO Series");
        break;
      case 2:
        setNameTable("PB");
        break;
      default:
        break;
    }

    setTab(value);
  };

  // ==================== HANLE SEARCH PO BUTTON ====================
  const [searchPOQueryData, setSearchPOQueryData] = useState("");

  const cbHandleSearchPO = (data) => {
    setSearchPOQueryData(data);
  };
  // ==================== END HANLE SEARCH PO BUTTON ====================

  // ==================== HANLE SEARCH PO SERIES BUTTON ====================
  const [searchPOSeriesQueryData, setSearchPOSeriesQueryData] = useState("");

  const cbHandleSearchPOSeries = (data) => {
    setSearchPOSeriesQueryData(data);
  };
  // ==================== END HANLE SEARCH PO BUTTON ====================

  // ==================== HANLE SEARCH PB BUTTON ====================
  const [searchPBQueryData, setSearchPBQueryData] = useState("");

  const cbHandleSearchPB = (data) => {
    setSearchPBQueryData(data);
  };
  // ==================== END HANLE SEARCH PB BUTTON ====================

  // ==================== HANDLE SHOW NUMBER PAGING ====================
  const [dataPagePO, setDataPagePO] = useState({
    pageSize: 10,
    newPage: 0,
    listDone: [],
  });
  const [dataPagePOSeries, setDataPagePOSeries] = useState({
    pageSize: 10,
    newPage: 0,
    listDone: [],
  });
  const [dataPagePB, setDataPagePB] = useState({
    pageSize: 10,
    newPage: 0,
    listDone: [],
  });

  const cbSetDataShowTablePO = (data) => {
    setDataPagePO(data);
  };

  const cbSetDataShowTablePOSeries = (data) => {
    setDataPagePOSeries(data);
  };

  const cbSetDataShowTablePB = (data) => {
    setDataPagePB(data);
  };
  // ==================== END HANDLE SHOW NUMBER PAGING ====================

  return (
    <Box className={classes.wrapperBasic}>
      {loadingTable && <GifLoading />}

      <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px 0">
        <Box display="flex" alignItems="center">
          {/* SHOWING DATA PAGING */}
          <Box display={tab === 0 ? "block" : "none"} className={classes.allWicText}>
            Viewing All {nameTable} Item{" "}
            {`Showing ${dataPagePO.newPage * dataPagePO.pageSize + 1} - ${
              dataPagePO.newPage * dataPagePO.pageSize + dataPagePO.pageSize >
              dataPagePO.listDone.length
                ? dataPagePO.listDone.length
                : dataPagePO.newPage * dataPagePO.pageSize + dataPagePO.pageSize
            } of ${dataPagePO.listDone.length}`}
          </Box>

          <Box display={tab === 1 ? "block" : "none"} className={classes.allWicText}>
            Viewing All {nameTable} Item{" "}
            {`Showing ${dataPagePOSeries.newPage * dataPagePOSeries.pageSize + 1} - ${
              dataPagePOSeries.newPage * dataPagePOSeries.pageSize + dataPagePOSeries.pageSize >
              dataPagePOSeries.listDone.length
                ? dataPagePOSeries.listDone.length
                : dataPagePOSeries.newPage * dataPagePOSeries.pageSize + dataPagePOSeries.pageSize
            } of ${dataPagePOSeries.listDone.length}`}
          </Box>

          <Box display={tab === 2 ? "block" : "none"} className={classes.allWicText}>
            Viewing All {nameTable} Item{" "}
            {`Showing ${dataPagePB.newPage * dataPagePB.pageSize + 1} - ${
              dataPagePB.newPage * dataPagePB.pageSize + dataPagePB.pageSize >
              dataPagePB.listDone.length
                ? dataPagePB.listDone.length
                : dataPagePB.newPage * dataPagePB.pageSize + dataPagePB.pageSize
            } of ${dataPagePB.listDone.length}`}
          </Box>
          {/* END SHOWING DATA PAGING */}

          <Box className={classes.wrapSelectTable} padding="0 10px 0 20px">
            <Paper className={classes.wrapperTab}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabHeader}
              >
                <Tab label="PO" className={classes.tab} />
                <Tab label="PO Series" className={classes.tab} />
                <Tab label="PB" className={classes.tab} />
              </Tabs>
            </Paper>
          </Box>
        </Box>

        <Box>
          <Box className={classes.wrapperSearch} display={tab === 0 ? "block" : "none"}>
            <SearchPOButton
              value={searchPOQueryData}
              cbHandleSearch={cbHandleSearchPO}
              placeHolder="Search PO No."
              size="small"
            />

            {searchPOQueryData.length > 0 && (
              <Box className={classes.searchContent}>
                Viewing Searched Result of : "
                {searchPOQueryData.split(new RegExp("[,]", "g")).map((item, index) => (
                  <span key={index}>{item.trim()}</span>
                ))}
                "{" "}
              </Box>
            )}
          </Box>

          <Box className={classes.wrapperSearch} display={tab === 1 ? "block" : "none"}>
            <SearchPOButton
              value={searchPOSeriesQueryData}
              cbHandleSearch={cbHandleSearchPOSeries}
              placeHolder="Search PO Series"
              size="small"
            />

            {searchPOSeriesQueryData.length > 0 && (
              <Box className={classes.searchContent}>
                Viewing Searched Result of : "
                {searchPOSeriesQueryData.split(new RegExp("[,]", "g")).map((item, index) => (
                  <span key={index}>{item.trim()}</span>
                ))}
                "{" "}
              </Box>
            )}
          </Box>

          <Box className={classes.wrapperSearch} display={tab === 2 ? "block" : "none"}>
            <SearchPOButton
              value={searchPBQueryData}
              cbHandleSearch={cbHandleSearchPB}
              placeHolder="Search PB No."
              size="small"
            />

            {searchPBQueryData.length > 0 && (
              <Box className={classes.searchContent}>
                Viewing Searched Result of : "
                {searchPBQueryData.split(new RegExp("[,]", "g")).map((item, index) => (
                  <span key={index}>{item.trim()}</span>
                ))}
                "{" "}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box display="flex" className={classes.contentContainer}>
        <Box display={tab === 0 ? "block" : "none"} data-cy="po-table-list">
          <POTable
            listSearch={searchPOQueryData}
            listPODaily={listPODaily}
            cbSetDataShowTable={cbSetDataShowTablePO}
          />
        </Box>
        <Box display={tab === 1 ? "block" : "none"}>
          <POSeriesTable
            listSearch={searchPOSeriesQueryData}
            listPODailySeries={listPODailySeries}
            noPadding={true}
            noViewExport={true}
            cbSetDataShowTable={cbSetDataShowTablePOSeries}
          />
        </Box>
        <Box display={tab === 2 ? "block" : "none"} width="100%" data-cy="pb-table-list">
          <PBTable
            listSearch={searchPBQueryData}
            listPBDaily={listPB}
            cbSetDataShowTable={cbSetDataShowTablePB}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PO;
