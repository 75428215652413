import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TaskCompletionRate from "./TaskCompletionRate";
import OutstandingTasks from "./OutstandingTasks";

const useStyles = makeStyles((theme) => ({
  outstandingTasksContainer: {
    marginLeft: 10,
    paddingRight: 30,
  },
}));

const TaskData = ({
  taskCompletionRateData = [],
  outstandingTaskData = [],
  handleTableDataClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [taskCompletionRateTableHeaderData, setTaskCompletionRateTableHeaderData] = useState([]);
  const [taskCompletionRateTableRowData, setTaskCompletionRateTableRowData] = useState([]);

  const [outstandingTasksTableHeaderData, setOutstandingTasksTableHeaderData] = useState([]);
  const [outstandingTasksTableRowData, setOutstandingTasksTableRowData] = useState([]);

  useEffect(() => {
    if (!taskCompletionRateData || taskCompletionRateData.length === 0) {
      return;
    }

    let headerData = [
      {
        name: "task",
        label: "Task",
        hasNumber: true,
        number: taskCompletionRateData[0].total || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "all",
          taskType: "all",
        },
      },
      {
        name: "notStarted",
        label: "Not Started",
        hasNumber: true,
        number: taskCompletionRateData[0].not_started || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "not_started",
          taskType: "all",
        },
      },
      {
        name: "inProgress",
        label: "In Progress",
        hasNumber: true,
        number: taskCompletionRateData[0].in_progress || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "in_progress",
          taskType: "all",
        },
      },
      {
        name: "completed",
        label: "Completed",
        hasNumber: true,
        number: taskCompletionRateData[0].completed || 0,
        searchConditions: {
          table: "taskCompletionRateTable",
          dataType: "completed",
          taskType: "all",
        },
      },
      // {
      //   name: "passRate",
      //   label: "1st time pass rate %",
      //   hasNumber: false,
      // },
    ];

    let rowData = [];

    for (let i = 1; i < taskCompletionRateData.length; i++) {
      rowData.push({
        name: taskCompletionRateData[i].name,
        data: [
          {
            name: taskCompletionRateData[i].name,
            label: t(`task.${taskCompletionRateData[i].name}`),
            hasNumber: true,
            number: taskCompletionRateData[i].total || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "all",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "notStarted",
            label: `${(taskCompletionRateData[i].not_started_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].not_started || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "not_started",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "inProgress",
            label: `${(taskCompletionRateData[i].in_progress_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].in_progress || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "in_progress",
              taskType: taskCompletionRateData[i].name,
            },
          },
          {
            name: "completed",
            label: `${(taskCompletionRateData[i].completed_percentage || 0).toFixed(2)}%`,
            hasNumber: true,
            number: taskCompletionRateData[i].completed || 0,
            searchConditions: {
              table: "taskCompletionRateTable",
              dataType: "completed",
              taskType: taskCompletionRateData[i].name,
            },
          },
          // {
          //   name: "passRate",
          //   label: taskCompletionRateData[i].first_time !== "" ? `${parseFloat(taskCompletionRateData[i].first_time).toFixed(2)}%` : "",
          //   hasNumber: false,
          // },
        ],
      });
    }

    setTaskCompletionRateTableHeaderData(headerData);
    setTaskCompletionRateTableRowData(rowData);
  }, [taskCompletionRateData]);

  useEffect(() => {
    let headerData = [
      {
        name: "type",
        label: "Type",
        hasNumber: false,
      },
    ];

    setOutstandingTasksTableHeaderData(headerData);
    setOutstandingTasksTableRowData(outstandingTaskData);
  }, [outstandingTaskData]);

  return (
    <Box>
      <Box display="flex">
        <Box display="flex">
          <TaskCompletionRate
            tableTitle="Task Completion Rate %"
            headerData={taskCompletionRateTableHeaderData}
            rowData={taskCompletionRateTableRowData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>

        <Box display="flex" className={classes.outstandingTasksContainer}>
          <OutstandingTasks
            tableTitle="Outstanding Tasks"
            headerData={outstandingTasksTableHeaderData}
            rowData={outstandingTasksTableRowData}
            handleTableDataClick={handleTableDataClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TaskData;
