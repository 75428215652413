import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { ArrowDropDown as DropDownIcon } from "@material-ui/icons";

import { atnDownloadAllFiles } from "actions/submissionActions";
import { atnDownloadTrfBulk } from "actions/fileActions";
import { downloadFile, getTRFDocumentName } from "utils/files";

import Button from "components/input/Button";

const options = ["Download All Report Attachment(s)", "Download All TRF Document(s)"];

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    fontSize: 12,
    color: "#232B3A",
  },
}));

const DownloadButton = ({ submissionId, taskListData }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (index) => {
    setOpen(false);

    let paramsTrfBulk = taskListData
      .filter((item) => item.lab)
      .map((item) => {
        let objectData = {
          taskId: item.id,
          fileName: getTRFDocumentName(item.lab, item.taskType),
        };
        return objectData;
      });

    if (index === 0) {
      atnDownloadAllFiles({
        type: 3,
        submissionId: submissionId,
      }).then(async (data) => {
        downloadFile(data, "all_documents.zip");
      });
    } else if (index === 1) {
      if (!paramsTrfBulk.length) return alert("No document to download");

      atnDownloadTrfBulk({ trfList: paramsTrfBulk }).then(async (data) => {
        downloadFile(data, "all_documents.zip");
      });
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        myRef={anchorRef}
        className={classes.button}
        variant="outlined"
        endIcon={<DropDownIcon />}
        onClick={handleToggle}
      >
        Download
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-end" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      className={classes.menuItem}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DownloadButton;
