import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _get from "lodash/get";
import _find from "lodash/find";
import _groupBy from "lodash/groupBy";
import moment from "moment";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { atnAddTasks, atnGetAddTaskDetails, atnGetItemDetailsTaskList } from "actions/itemActions";
import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import Button from "components/input/Button";
import Checkbox from "components/input/Checkbox";
import DateTimePicker from "components/input/DateTimePicker";
import Modal from "components/modal/Modal";

import {
  DATE_FORMAT_02,
  LAB,
  SEASONAL,
  SUPPLIER,
  TASK_ASSIGN_LIST_GROUP_01,
  TASK_ASSIGN_LIST_GROUP_02,
  TASK_TYPE,
  TASK_TYPE_ENUM,
  TASK_TYPE_EVERYDAY,
  USER_TYPE,
  WBA,
} from "shared/constants";
import { replaceItemInArrayByIndex } from "utils/array";
import useUserType from "hooks/useUserType";

import { getCategory } from "services/categoryService";

import { atnGetOpenTaskTaskTypes } from "actions/taskActions";
import { getOpenTaskTypeList, getTaskAssignFlow, toTaskTypeList } from "services/openTaskService";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles((theme) => ({
  block: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  element: {
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  subText: {
    fontSize: 10,
  },
  text: {
    fontSize: 12,
  },
  blackText: {
    color: "#232B3A",
  },
  bold: {
    fontWeight: 500,
  },
  datePicker: {
    "& > div": {
      height: 40,
      padding: 8,
      "& input": {
        fontSize: 12,
      },
    },
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
  groupRadio: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#B0D400",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
  selectTaskType: {
    "& span.MuiTypography-body1": {
      marginBottom: 6,
    },
  },
}));

const sampleTypes = [TASK_TYPE_ENUM.SS];
const assignTypesGroupDPIandFRI = [TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID];

const initialValue = {
  assignTo: "lab",
  deadLine: moment().format(DATE_FORMAT_02),
  remark: "",
  taskType: "",
  po: [],
  subWics: [],
};

const AddTask = ({
  data: { masterWicId },
  category = SEASONAL,
  userType = USER_TYPE.buyer,
  open = false,
  setParams = () => {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [taskTypes, setTaskTypes] = useState([]);
  const [taskTypeList, setTaskTypeList] = useState([]);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);

  const [formData, setFormData] = useState(initialValue);

  const [addTaskDetail, setAddTaskDetail] = useState({});

  const [taskAssignList, setTaskAssignList] = useState([]);
  const [taskListCountByType, setTaskTypeCountByType] = useState([]);
  const [numberItemsByType, setNumberItemsByType] = useState(0);
  const [maxDate, setMaxDate] = useState(moment()._d);
  const [subWicList, setSubWicList] = useState([]);

  const [taskTypeFlow, setTaskTypeFlow] = useState(null);

  const [existingTaskTypeList, setExistingTaskTypeList] = useState([]);

  // get initial task type from api
  const [initialTaskTypes, setInitialTaskTypes] = useState({});

  const { isBuyer, isSupplier } = useUserType(userType);

  const { isEveryday } = getCategory(category);

  const { canLabFlow, canSupplierFlow, canWbaFlow } = useMemo(
    () => getTaskAssignFlow(userType, category, initialTaskTypes, formData.taskType),
    [userType, category, initialTaskTypes, formData.taskType],
  );

  const defaultTaskList = isEveryday ? TASK_TYPE_EVERYDAY : TASK_TYPE;

  useEffect(() => {
    if (!open && !masterWicId && !userType) return;
    setIsDataLoading(true);

    setTaskTypes(defaultTaskList);
    setTaskTypeList(defaultTaskList);

    fetchExistingTaskList();
    fetchTaskTypes();
    fetchAddTaskDetails();
  }, [open, masterWicId, userType]);

  useEffect(() => {
    // update assign to
    setFormData((state) => ({ ...state, assignTo: taskTypeFlow }));
  }, [taskTypeFlow]);

  useEffect(() => {
    // set default task flow for DPI & FRI task
    if (!taskTypeFlow && assignTypesGroupDPIandFRI?.includes(formData?.taskType)) {
      setTaskTypeFlow(canSupplierFlow ? SUPPLIER : LAB);
    }
  }, [taskTypeFlow, canSupplierFlow, formData.taskType]);

  useEffect(() => {
    setTaskTypes(defaultTaskList);
    setTaskTypeList(defaultTaskList);
  }, [category]);

  useEffect(() => {
    if (!formData?.taskType) return;

    if (numberItemsByType === 0) {
      handleSetAdvisedWbaDeadline(_get(addTaskDetail, "shipByDate"));
    }

    handleSetSubWicList(_get(addTaskDetail, "subWics", []));

    handleSetTaskAssignList(formData.taskType);
  }, [formData.taskType]);

  useEffect(() => {
    handleSetRequirements(addTaskDetail?.tasks || []);
  }, [addTaskDetail?.tasks]);

  const fetchAddTaskDetails = async () => {
    await atnGetAddTaskDetails({ id: masterWicId }).then((res) => {
      setAddTaskDetail(res?.data);
    });
  };

  const fetchTaskTypes = async () => {
    // get initial task type
    await atnGetOpenTaskTaskTypes({ masterWicId, userType }).then(({ data }) => {
      setInitialTaskTypes(data);
    });
  };

  const fetchExistingTaskList = async () => {
    // get existing task type in list
    await atnGetItemDetailsTaskList({ data: { id: masterWicId }, type: userType }).then(
      ({ data }) => {
        const existingTaskTypes = toTaskTypeList(data);

        setExistingTaskTypeList(existingTaskTypes);

        setIsDataLoading(false);
      },
    );
  };

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "taskType") {
      const count = _get(_find(taskListCountByType, { taskType: value }), "count");
      setNumberItemsByType(count || 0);
    }
  };

  const handleChangeSubWics = (list, value, name) => {
    const newList = replaceItemInArrayByIndex(list, { ..._find(list, { name }), value }, "id");
    handleSetSubWicList(newList);
  };

  const handleSetRequirements = (tasks) => {
    if (!tasks?.length) return;

    const counts = Object.values(_groupBy(tasks, "taskType")).map((group) => ({
      taskType: group[0].taskType,
      count: group.length,
    }));

    if (category === "seasonal") {
      const openNewList = getOpenTaskTypeList(userType, category, existingTaskTypeList, tasks);

      // set filtered task list
      setTaskTypeList(openNewList);
    }

    // set counts
    setTaskTypeCountByType(counts);
  };

  const handleSetAdvisedWbaDeadline = (shipByDate) => {
    if (!shipByDate) return;

    const daysBefore = _get(_find(taskTypes, { value: formData.taskType }), "days", 0);
    const advisedDeadline = moment(shipByDate).subtract(daysBefore, "days").format(DATE_FORMAT_02);

    setFormData((prev) => {
      return { ...prev, deadLine: advisedDeadline, po: [{ shipByDate }] };
    });

    setMaxDate(moment(shipByDate).format(DATE_FORMAT_02));
  };

  const handleSetSubWicList = (subWics) => {
    if (!subWics?.length) return;

    const list = subWics.map((item) => ({
      id: item.id,
      name: `subWics_${item.id}`,
      value: item.value === undefined ? true : item.value,
      label: item.wicNumber || item.label,
      onChange: (value, name) => handleChangeSubWics(list, value, name),
    }));

    setFormData((prev) => ({
      ...prev,
      subWics: list.filter((s) => s.value === true).map((s) => ({ id: s.id })),
    }));

    setSubWicList(list);
  };

  const handleSetTaskAssignList = (selectedTaskType) => {
    setTaskAssignList(
      assignTypesGroupDPIandFRI.includes(selectedTaskType)
        ? TASK_ASSIGN_LIST_GROUP_01
        : sampleTypes.includes(selectedTaskType)
        ? TASK_ASSIGN_LIST_GROUP_02
        : [],
    );
  };

  const handleResetAndClose = () => {
    setIsDisabledConformButton(false);
    setFormData(initialValue);
    setParams((prev) => ({ ...prev, open: false }));
    setTaskTypeFlow(null);
  };

  const handleSubmit = () => {
    setIsDisabledConformButton(true);

    if (numberItemsByType !== 0 && formData.deadLine) {
      // remove advised deadLine value in case 1 -> 2+
      formData.deadLine = "";
    }

    formData.assignTo = formData.assignTo
      ? formData.assignTo
      : !isSupplier && !!taskAssignList.length && !formData.assignTo
      ? taskTypeFlow
      : LAB;

    formData.masterWicId = masterWicId;

    if (isBuyer) {
      formData.changes = [
        {
          field: t(`task.${formData.taskType}`),
          action: "Add Task",
          before: "",
          after: "New " + t(`task.${formData.taskType}`),
        },
      ];
    }

    atnAddTasks({
      data: [formData],
      type: userType,
    }).then(() => {
      handleResetAndClose();
      setParams((prev) => ({ ...prev, refresh: true }));
    });
  };

  const handleChangeTaskTypeFlow = (e) => {
    setTaskTypeFlow(e.target.value);
  };

  const hasTaskType = formData.taskType;

  const ActionButtons = () => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          className={classes.btnLeft}
          onClick={() => handleResetAndClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !hasTaskType ||
            isDisabledConformButton ||
            (!!taskAssignList.length && !isSupplier && !taskTypeFlow)
          }
          onClick={() => handleSubmit()}
        >
          Confirm Add
        </Button>
      </Box>
    );
  };

  const Block = ({ title, content: { label, element } }) => {
    return (
      <Box className={classes.block}>
        <Typography className={classnames(classes.text, classes.bold)}>{title}</Typography>

        <Box className={classes.element}>
          {label && (
            <Typography className={classnames(classes.text, classes.blackText)}>{label}</Typography>
          )}
          {element}
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      title="Add Task"
      open={open}
      disabled={isDataLoading}
      footer={<ActionButtons />}
      hideCloseIcon
    >
      <Box>
        <Typography className={classes.subText}>
          Please select the additional task type you want to add.
        </Typography>
      </Box>
      <Box className={classes.selectTaskType}>
        <Block
          title={"Choose Task Type"}
          content={{
            label: "Task Type",
            element: (
              <Select
                label="Select a task"
                name="taskType"
                value={formData.taskType}
                options={taskTypeList}
                disabled={isDataLoading}
                handleChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            ),
          }}
        />
      </Box>

      {formData.taskType && (
        <>
          {/* ============= Temporary comment this code ============= */}
          {/* {taskAssignList.length > 0 && auth.loginRole() !== 'supplier' && (
            <Block
              title='Assign Task to'
              content={{
                label: 'Assign To',
                element: (
                  <Select
                    label='Please Select'
                    name='assignTo'
                    value={formData.assignTo || initialValue.assignTo}
                    options={taskAssignList}
                    handleChange={(e) => handleChange(e.target.name, e.target.value)}
                    // additionalClass={}
                  />
                ),
              }}
            />
          )} */}
          {/* ============= End Temporary comment this code ============= */}

          {/* ============= Add 2 option lab and supplier ============= */}
          {taskAssignList?.length > 0 && (
            <FormControl component="fieldset" className={classes.groupRadio}>
              <RadioGroup
                row
                aria-label="taskTypeFlow"
                name="assignTo"
                value={taskTypeFlow}
                onChange={handleChangeTaskTypeFlow}
              >
                {/* //* supplier ss task can assign to wba flow */}
                {canWbaFlow && <FormControlLabel value={WBA} control={<Radio />} label="WBA" />}

                {canSupplierFlow && (
                  <FormControlLabel
                    value={SUPPLIER}
                    control={<Radio />}
                    label="Self Report Upload"
                  />
                )}

                {canLabFlow && <FormControlLabel value={LAB} control={<Radio />} label="To Lab" />}
              </RadioGroup>
            </FormControl>
          )}

          {/* ============= End Add 2 option lab and supplier ============= */}

          {numberItemsByType === 0 && !isEveryday && !isSupplier && (
            <Block
              title={`Advise ${SIMPLE_BUYER_NAME} Deadline`}
              content={{
                label: `${SIMPLE_BUYER_NAME} Deadline`,
                element: (
                  <Box width="40%">
                    <DateTimePicker
                      type="date"
                      isPicker
                      pickerProps={{
                        className: classes.datePicker,
                        initialValue: initialValue.deadLine,
                        value: formData.deadLine,
                        setValue: (value) => handleChange("deadLine", value),
                        maxDate,
                      }}
                    />
                  </Box>
                ),
              }}
            />
          )}

          <Block
            title="Select SubWIC for Task"
            content={{
              element: <Checkbox list={subWicList} />,
            }}
          />

          {!isSupplier && (
            <Block
              title="Task Remark (Optional)"
              content={{
                element: (
                  <TextInput
                    autoFocus
                    placeholder="Remark"
                    name="remark"
                    value={formData.remark}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                  />
                ),
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default AddTask;
