import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Header from "./Header";
import TestingCostDetails from "./../UpdateCost/TestingCostDetails";
import InspectionCostDetails from "./../UpdateCost/InspectionCostDetails";

import ConfirmModal from "components/modal/ConfirmModal";
import WarningModal from "components/lab/WarningModal";
import { formatFloatingNumber } from "utils/string";

import {
  atnGetSubmissionDetails,
  atnGetSubmissionDetailsInvoice,
  atnGetSubmissionDetailsQuotations,
  atnResumeInvoice,
} from "actions/submissionActions";

import { isNegativePrice } from "pages/lab/common/noInvoiceCostService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  totalPriceMsg: {
    marginTop: "16px",
  },
}));

const initialTotalPriceError = () => (
  <>
    <div>Total price of resume report cannot be larger than total price of original invoice.</div>
    <div>Please update it again.</div>
  </>
);

const initialPrepaymentPriceError = () => (
  <>
    <div>Invalid amount, please make sure to spend all prepayment amount.</div>
    <div>Please update it again.</div>
  </>
);

const totalPriceRemark =
  "Remark: Total price of your resume invoice cannot be larger than original total price.";

const ResumeInvoice = ({ match, history }) => {
  const { submissionId, invoiceId } = match.params;

  const classes = useStyles();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [status, setStatus] = useState("");
  const [submissionType, setSubmissionType] = useState("");
  const [testItemLength, setTestItemLength] = useState(0);
  const [inspectionTestItem, setInspectionTestItem] = useState([]);
  const [inspectionSubTotal, setInspectionSubTotal] = useState(0);

  const [detailData, setDetailData] = useState({
    id: "",
    currency: "",
    isPrepayment: false,
    subTotal: 0,
    totalPrice: 0,
    packagePrice: "",
    vat: "",
    remarks: "",
    packageRemark: "",
    tat: "",
    sampleSize: "",
    isPackagePrice: false,
  });

  const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(0);

  const [prepaymentData, setPrepaymentData] = useState({
    showPrepaymentOption: true,
    hasPrepayment: false,
    prepaymentPrice: 0,
  });

  const [testItems, setTestItems] = useState([
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
  ]);

  const [inspectionTaskDetail, setInspectionTaskDetail] = useState({
    id: "",
    currency: "",
    aqlCritical: "",
    aqlMajor: "",
    aqlMinor: "",
    inspectionDateFrom: "",
    inspectionDateTo: "",
    inspectionLevel: "II",
    totalQuantity: "",
    isPrepayment: false,
    isPackagePrice: false,
    remarks: "",
    vat: 0,

    noOfMandays: 0,
    mandayUnitCost: 0,
    travelExpenses: 0,
    hotelExpenses: 0,
    otherExpenses: 0,
    lateBookingFee: 0,
    cancellationFee: 0,
    totalPrice: 0,
  });

  const [openWarningModal, setOpenWarningModal] = useState(false);

  // check if cost input value if larger than existing
  const [originalTotalPrice, setOriginalTotalPrice] = useState();

  // check if current total price is > originalTotalPrice
  const [isOverOriginalTotalPrice, setIsOverOriginalTotalPrice] = useState(false);

  const [isBelowPrepaymentPrice, setIsBelowPrepaymentPrice] = useState(false);

  const [totalPriceError, setTotalPriceError] = useState("");

  const hasMissingItem =
    testItems.length === 0 ||
    testItems?.some((item) => !item?.itemName || !item?.quantity || item?.unitPrice === "");

  const isTestingType = submissionType === "testing";
  const isInspectionType = submissionType === "inspection";

  const totalPrice = parseFloat(
    isTestingType ? detailData.totalPrice : inspectionTaskDetail.totalPrice,
  ).toFixed(2);

  const subTotal = parseFloat(
    isTestingType ? detailData.subTotal : inspectionTaskDetail.subTotal,
  ).toFixed(2);

  // disable to show prepayment price in total for new invoice
  const isNewInvoice = currentInvoiceIndex > 1;

  const isNegativeAmount = isNegativePrice(
    isInspectionType,
    totalPrice,
    subTotal,
    !isNewInvoice ? prepaymentData.prepaymentPrice : 0,
  );

  useEffect(() => {
    if (
      !checkBelowPrepaymentPriceAlert(
        isInspectionType ? inspectionSubTotal : detailData.subTotal,
      ) &&
      !checkHigherTotalPriceAlert(totalPrice)
    ) {
      setIsOverOriginalTotalPrice(false);
      setIsBelowPrepaymentPrice(false);
      setTotalPriceError("");
    }
  }, [submissionType, totalPrice, detailData.subTotal, inspectionSubTotal]);

  useEffect(() => {
    atnGetSubmissionDetails({ data: { id: submissionId }, type: 3 }).then(({ data, message }) => {
      if (data && data.taskType) {
        let type = "";

        if (["QT", "PST", "ST", "SS", "STD", "PAC"].includes(data.taskType)) {
          type = "testing";
        } else if (["SPU", "SPUD", "DPI", "FRI", "FRID"].includes(data.taskType)) {
          type = "inspection";
        }

        setStatus(data?.status);

        if (data.labStatus === "pending") {
          atnGetSubmissionDetailsQuotations({
            data: { id: submissionId },
            type: 3,
          }).then(({ data: details, message: detailsMessage }) => {
            if (details && details.length > 0) {
              const quotationDetails = details.filter((item) => item.id === parseInt(invoiceId))[0];

              setPrepaymentData({
                showPrepaymentOption: true,
                hasPrepayment: false,
                prepaymentPrice: 0,
              });

              if (type === "testing") {
                setDetailData({
                  id: quotationDetails.id,
                  currency: quotationDetails.currency,
                  isPrepayment: quotationDetails.isPrepayment,
                  subTotal: quotationDetails.subTotal || 0,
                  totalPrice: quotationDetails.totalPrice || 0,
                  packagePrice: quotationDetails.packagePrice || "",
                  vat: quotationDetails.vat,
                  remarks: quotationDetails.remarks || "",
                  packageRemark: quotationDetails.packageRemark || "",
                  tat: quotationDetails.tat || "",
                  sampleSize: quotationDetails.sampleSize || "",
                  isPackagePrice: quotationDetails.isPackagePrice,
                });

                setOriginalTotalPrice(quotationDetails.totalPrice);

                setTestItemLength(quotationDetails.testPlans.length);
                setTestItems(quotationDetails.testPlans);
              } else if (type === "inspection") {
                setInspectionTestItem(quotationDetails.testPlans);

                let noOfMandays = 0;
                let mandayUnitCost = 0;
                let travelExpenses = 0;
                let hotelExpenses = 0;
                let otherExpenses = 0;
                let lateBookingFee = 0;
                let cancellationFee = 0;

                for (let i = 0; i < quotationDetails.testPlans.length; i++) {
                  if (quotationDetails.testPlans[i].itemName === "Manday") {
                    noOfMandays = quotationDetails.testPlans[i].quantity;
                    mandayUnitCost = quotationDetails.testPlans[i].unitPrice;
                  } else if (quotationDetails.testPlans[i].itemName === "Travel Expenses") {
                    travelExpenses = quotationDetails.testPlans[i].unitPrice;
                  } else if (quotationDetails.testPlans[i].itemName === "Hotel Expenses") {
                    hotelExpenses = quotationDetails.testPlans[i].unitPrice;
                  } else if (quotationDetails.testPlans[i].itemName === "Other Expenses") {
                    otherExpenses = quotationDetails.testPlans[i].unitPrice;
                  } else if (quotationDetails.testPlans[i].itemName === "Late Booking Fee") {
                    lateBookingFee = quotationDetails.testPlans[i].unitPrice;
                  } else if (quotationDetails.testPlans[i].itemName === "Cancellation Fee") {
                    cancellationFee = quotationDetails.testPlans[i].unitPrice;
                  }
                }

                setInspectionTaskDetail({
                  id: quotationDetails.id,
                  inspectionTaskDetailId: quotationDetails.inspectionTaskDetail.id,
                  currency: quotationDetails.currency,
                  aqlCritical: quotationDetails.inspectionTaskDetail.aqlCritical,
                  aqlMajor: quotationDetails.inspectionTaskDetail.aqlMajor,
                  aqlMinor: quotationDetails.inspectionTaskDetail.aqlMinor,
                  inspectionDateFrom: quotationDetails.inspectionTaskDetail.inspectionDateFrom,
                  inspectionDateTo: quotationDetails.inspectionTaskDetail.inspectionDateTo,
                  inspectionLevel: quotationDetails.inspectionTaskDetail.inspectionLevel,
                  totalQuantity: quotationDetails.inspectionTaskDetail.totalQuantity,
                  isPrepayment: quotationDetails.isPrepayment,
                  isPackagePrice: quotationDetails.isPackagePrice,
                  remarks: quotationDetails.remarks,
                  vat: quotationDetails.vat,
                  noOfMandays,
                  mandayUnitCost,
                  travelExpenses,
                  hotelExpenses,
                  otherExpenses,
                  lateBookingFee,
                  cancellationFee,
                  totalPrice: quotationDetails.totalPrice,
                });

                let inspectionSubtotal = 0;
                inspectionSubtotal += noOfMandays * mandayUnitCost;
                inspectionSubtotal += travelExpenses;
                inspectionSubtotal += hotelExpenses;
                inspectionSubtotal += otherExpenses;
                inspectionSubtotal += lateBookingFee;
                inspectionSubtotal += cancellationFee;

                setInspectionSubTotal(inspectionSubtotal);

                setOriginalTotalPrice(quotationDetails.totalPrice);
              }

              setSubmissionType(type);
            }
          });
        } else if (data.labStatus === "accepted") {
          atnGetSubmissionDetailsInvoice({
            data: { id: submissionId },
            type: 3,
          }).then(({ data: details, message: detailsMessage }) => {
            if (details && details.length > 0) {
              const invoiceDetails = details.filter((item, index) => {
                if (item.id === parseInt(invoiceId)) {
                  setCurrentInvoiceIndex(index);

                  return item;
                }
              })[0];

              let hasPrepayment = false;
              let prepaymentPrice = 0;

              for (let i = 0; i < details.length; i++) {
                if (details[i].isPrepayment) {
                  hasPrepayment = true;
                  prepaymentPrice = details[i].totalPrice;
                  break;
                }
              }

              setPrepaymentData({
                showPrepaymentOption: false,
                hasPrepayment: hasPrepayment,
                prepaymentPrice: prepaymentPrice,
              });

              if (type === "testing") {
                setDetailData({
                  id: invoiceDetails.id,
                  currency: invoiceDetails.currency,
                  isPrepayment: invoiceDetails.isPrepayment,
                  subTotal: invoiceDetails.subTotal || 0,
                  totalPrice: invoiceDetails.totalPrice || 0,
                  packagePrice: invoiceDetails.packagePrice,
                  vat: invoiceDetails.vat,
                  remarks: invoiceDetails.remarks || "",
                  packageRemark: invoiceDetails.packageRemark || "",
                  tat: invoiceDetails.tat || "",
                  sampleSize: invoiceDetails.sampleSize || "",
                  isPackagePrice: invoiceDetails.isPackagePrice,
                });

                setOriginalTotalPrice(invoiceDetails?.totalPrice);

                setTestItemLength(invoiceDetails.testPlans.length);
                setTestItems(invoiceDetails.testPlans);
              } else if (type === "inspection") {
                setInspectionTestItem(invoiceDetails.testPlans);

                let noOfMandays = 0;
                let mandayUnitCost = 0;
                let travelExpenses = 0;
                let hotelExpenses = 0;
                let otherExpenses = 0;
                let lateBookingFee = 0;
                let cancellationFee = 0;

                for (let i = 0; i < invoiceDetails.testPlans.length; i++) {
                  if (invoiceDetails.testPlans[i].itemName === "Manday") {
                    noOfMandays = invoiceDetails.testPlans[i].quantity;
                    mandayUnitCost = invoiceDetails.testPlans[i].unitPrice;
                  } else if (invoiceDetails.testPlans[i].itemName === "Travel Expenses") {
                    travelExpenses = invoiceDetails.testPlans[i].unitPrice;
                  } else if (invoiceDetails.testPlans[i].itemName === "Hotel Expenses") {
                    hotelExpenses = invoiceDetails.testPlans[i].unitPrice;
                  } else if (invoiceDetails.testPlans[i].itemName === "Other Expenses") {
                    otherExpenses = invoiceDetails.testPlans[i].unitPrice;
                  } else if (invoiceDetails.testPlans[i].itemName === "Late Booking Fee") {
                    lateBookingFee = invoiceDetails.testPlans[i].unitPrice;
                  } else if (invoiceDetails.testPlans[i].itemName === "Cancellation Fee") {
                    cancellationFee = invoiceDetails.testPlans[i].unitPrice;
                  }
                }

                setInspectionTaskDetail({
                  id: invoiceDetails.id,
                  inspectionTaskDetailId: invoiceDetails.inspectionTaskDetail.id,
                  currency: invoiceDetails.currency,
                  aqlCritical: invoiceDetails.inspectionTaskDetail.aqlCritical,
                  aqlMajor: invoiceDetails.inspectionTaskDetail.aqlMajor,
                  aqlMinor: invoiceDetails.inspectionTaskDetail.aqlMinor,
                  inspectionDateFrom: invoiceDetails.inspectionTaskDetail.inspectionDateFrom,
                  inspectionDateTo: invoiceDetails.inspectionTaskDetail.inspectionDateTo,
                  inspectionLevel: invoiceDetails.inspectionTaskDetail.inspectionLevel,
                  totalQuantity: invoiceDetails.inspectionTaskDetail.totalQuantity,
                  isPrepayment: invoiceDetails.isPrepayment,
                  isPackagePrice: invoiceDetails.isPackagePrice,
                  remarks: invoiceDetails.remarks,
                  vat: invoiceDetails.vat,

                  noOfMandays,
                  mandayUnitCost,
                  travelExpenses,
                  hotelExpenses,
                  otherExpenses,
                  lateBookingFee,
                  cancellationFee,
                  totalPrice: invoiceDetails.totalPrice,
                });

                let inspectionSubtotal = 0;
                inspectionSubtotal += noOfMandays * mandayUnitCost;
                inspectionSubtotal += travelExpenses;
                inspectionSubtotal += hotelExpenses;
                inspectionSubtotal += otherExpenses;
                inspectionSubtotal += lateBookingFee;
                inspectionSubtotal += cancellationFee;

                setInspectionSubTotal(inspectionSubtotal);

                setOriginalTotalPrice(invoiceDetails?.totalPrice);
              }

              setSubmissionType(type);
            }
          });
        }
      }
    });
  }, []);

  const handleDetailDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "isPackagePrice") {
      if (value) {
        let newTotalPrice = detailData.packagePrice || 0;

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        if (detailData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      } else {
        let newTotalPrice = detailData.subTotal;

        if (!isNewInvoice && prepaymentData?.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        if (detailData?.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      }
    } else if (name === "packagePrice") {
      let newTotalPrice = value || 0;

      if (prepaymentData.hasPrepayment) {
        newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
      }

      if (detailData.vat) {
        newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
      }

      setDetailData((prevState) => ({
        ...prevState,
        [name]: value,
        totalPrice: newTotalPrice,
      }));
    } else if (name === "vat") {
      if (detailData.isPackagePrice) {
        let newTotalPrice = detailData.packagePrice || 0;

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      } else {
        let newTotalPrice = detailData.subTotal || 0;

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      }
    } else {
      setDetailData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInspectionTaskDetailChange = (e) => {
    const { name, value } = e.target;
    setInspectionTaskDetail((prevState) => {
      let tempTotal = 0;
      let tempSubTotal = 0;
      if (
        name === "noOfMandays" ||
        name === "mandayUnitCost" ||
        name === "travelExpenses" ||
        name === "hotelExpenses" ||
        name === "otherExpenses" ||
        name === "lateBookingFee" ||
        name === "vat" ||
        name === "cancellationFee"
      ) {
        const newValue = parseFloat(value);

        prevState[name] = newValue;
        tempTotal += (prevState.noOfMandays || 0) * (prevState.mandayUnitCost || 0);
        tempTotal += prevState.travelExpenses || 0;
        tempTotal += prevState.hotelExpenses || 0;
        tempTotal += prevState.otherExpenses || 0;
        tempTotal += prevState.lateBookingFee || 0;
        tempTotal += prevState.cancellationFee || 0;

        tempSubTotal = tempTotal;
        if (prevState.vat > 0) {
          tempTotal = tempTotal * (1 + parseFloat(prevState.vat) / 100);
        }

        setInspectionSubTotal(tempTotal);

        if (!isNewInvoice && prepaymentData.hasPrepayment) {
          tempTotal = tempTotal - prepaymentData.prepaymentPrice;
        }

        return {
          ...prevState,
          [name]: newValue,
          totalPrice: tempTotal.toFixed(2),
          subTotal: tempSubTotal.toFixed(2),
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "isPackagePrice") {
      handleDetailDataChange({
        target: {
          name: name,
          value: checked,
        },
      });
    } else {
      setDetailData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleInspectionPrepaymentChange = (e) => {
    const { name, checked } = e.target;

    setInspectionTaskDetail((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleTestItemDataChange = (e, index) => {
    const { name, value } = e.target;

    setTestItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;

          if (name === "quantity") {
            if (value && item.unitPrice) {
              item.subTotal = parseInt(value) * parseFloat(item.unitPrice);
            } else {
              item.subTotal = 0;
            }
          }

          if (name === "unitPrice") {
            if (value && item.quantity) {
              item.subTotal = parseInt(item.quantity) * parseFloat(value);
            } else {
              item.subTotal = 0;
            }
          }
        }

        return item;
      });
    });

    setTimeout(() => {
      let subTotal = 0;

      for (let i = 0; i < testItems.length; i++) {
        if (testItems[i].quantity && testItems[i].unitPrice) {
          subTotal = formatFloatingNumber(
            subTotal + parseInt(testItems[i].quantity) * parseFloat(testItems[i].unitPrice),
          );
        }
      }

      let totalPrice = subTotal;

      if (detailData.isPackagePrice) {
        totalPrice = detailData.packagePrice || 0;
      }

      if (!isNewInvoice && prepaymentData?.hasPrepayment) {
        totalPrice = totalPrice - prepaymentData.prepaymentPrice;
      }

      if (detailData.vat) {
        totalPrice = (totalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
      }

      setDetailData((prevState) => ({
        ...prevState,
        subTotal: subTotal,
        totalPrice: totalPrice,
      }));
    }, 200);
  };

  const handleAddNewTestItem = () => {
    setTestItems((prevState) => {
      return prevState.concat({
        itemName: "",
        quantity: "",
        unitPrice: "",
        subTotal: 0,
      });
    });
  };

  const handleRemoveTestItem = (index) => {
    let subTotal = 0;

    for (let i = 0; i < testItems.length; i++) {
      if (i !== index && testItems[i].quantity && testItems[i].unitPrice) {
        subTotal = formatFloatingNumber(
          subTotal + (testItems[i].quantity || 0) * (testItems[i].unitPrice || 0),
        );
      }
    }

    let totalPrice = subTotal;

    if (detailData.isPackagePrice) {
      totalPrice = detailData.packagePrice || 0;
    }

    if (!isNewInvoice && prepaymentData.hasPrepayment) {
      totalPrice = totalPrice - prepaymentData.prepaymentPrice;
    }

    if (detailData.vat) {
      totalPrice = (totalPrice * (1 + parseFloat(detailData.vat || 0) / 100)).toFixed(2);
    }

    setDetailData((prevState) => ({
      ...prevState,
      subTotal: subTotal,
      totalPrice: totalPrice,
    }));

    setTestItems((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const handleTestingSubmit = () => {
    let tempTestItems = [];
    let hasEmptyData = false;

    for (let i = 0; i < testItems.length; i++) {
      if (
        testItems[i].quantity !== null &&
        testItems[i].quantity !== "" &&
        testItems[i].unitPrice !== null &&
        testItems[i].unitPrice !== ""
      ) {
        if (testItems[i].id) {
          tempTestItems.push({
            id: testItems[i].id,
            itemName: testItems[i].itemName,
            quantity: parseInt(testItems[i].quantity),
            unitPrice: parseFloat(testItems[i].unitPrice),
          });
        } else {
          tempTestItems.push({
            itemName: testItems[i].itemName,
            quantity: parseInt(testItems[i].quantity),
            unitPrice: parseFloat(testItems[i].unitPrice),
          });
        }
      }
    }

    if (hasEmptyData) {
      setOpenConfirmDialog(false);
      return alert("missing data");
    }

    setIsDisabledConfirmButton(true);

    let tempInvoiceData = detailData;
    tempInvoiceData.vat = detailData.vat || 0;

    if (tempInvoiceData.isPackagePrice && !tempInvoiceData.packagePrice) {
      tempInvoiceData.packagePrice = 0;
    }

    let submitData = {
      submissionId: submissionId,
      invoice: tempInvoiceData,
      invoiceTestItems: tempTestItems,
    };

    atnResumeInvoice(submitData).then((res) => {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);

      if (res?.data) {
        history.push(`/lab/submissions/${submissionId}`);
      } else {
        return setTotalPriceError(res?.message);
      }
    });
  };

  const handleInspectionSubmit = () => {
    setIsDisabledConfirmButton(true);

    let tempData = Object.keys(inspectionTaskDetail)
      .filter(
        (key) =>
          key === "id" ||
          key === "currency" ||
          key === "isPrepayment" ||
          key === "expectedCompletionDate" ||
          key === "cancellationFee" ||
          key === "totalPrice" ||
          key === "expressServicePercentage" ||
          key === "vat" ||
          key === "remarks" ||
          key === "tat" ||
          key === "sampleSize" ||
          key === "isPackagePrice" ||
          key === "packagePrice" ||
          key === "reasonOption" ||
          key === "reasonRemarks",
      )
      .reduce((obj, key) => {
        obj[key] = inspectionTaskDetail[key];
        return obj;
      }, {});

    let tempInspectionTaskDetail = Object.keys(inspectionTaskDetail)
      .filter(
        (key) =>
          key === "aqlCritical" ||
          key === "aqlMajor" ||
          key === "aqlMinor" ||
          key === "expectedInspectionDate" ||
          key === "inspectionDateFrom" ||
          key === "inspectionDateTo" ||
          key === "inspectionLevel" ||
          key === "totalQuantity",
      )
      .reduce((obj, key) => {
        obj[key] = inspectionTaskDetail[key];
        return obj;
      }, {});

    tempInspectionTaskDetail.id = inspectionTaskDetail.inspectionTaskDetailId;
    tempData.expectedCompletionDate = tempData.expectedCompletionDate
      ? moment(tempData.expectedCompletionDate).format("YYYY-MM-DD")
      : null;
    tempInspectionTaskDetail.expectedInspectionDate =
      tempInspectionTaskDetail.expectedInspectionDate
        ? moment(tempInspectionTaskDetail.expectedInspectionDate).format("YYYY-MM-DD")
        : null;
    tempInspectionTaskDetail.inspectionDateFrom = tempInspectionTaskDetail.inspectionDateFrom
      ? moment(tempInspectionTaskDetail.inspectionDateFrom).format("YYYY-MM-DD")
      : null;
    tempInspectionTaskDetail.inspectionDateTo = tempInspectionTaskDetail.inspectionDateTo
      ? moment(tempInspectionTaskDetail.inspectionDateTo).format("YYYY-MM-DD")
      : null;

    let mandayId = "";
    let travelExpensesId = "";
    let hotelExpensesId = "";
    let otherExpensesId = "";
    let lateBookingFeeId = "";
    let cancellationFeeId = "";

    for (let i = 0; i < inspectionTestItem.length; i++) {
      if (inspectionTestItem[i].itemName === "Manday") {
        mandayId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Travel Expenses") {
        travelExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Hotel Expenses") {
        hotelExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Other Expenses") {
        otherExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Late Booking Fee") {
        lateBookingFeeId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Cancellation Fee") {
        cancellationFeeId = inspectionTestItem[i].id;
      }
    }

    const newTempTestItems = [
      {
        id: mandayId,
        itemName: "Manday",
        quantity: inspectionTaskDetail.noOfMandays,
        unitPrice: inspectionTaskDetail.mandayUnitCost || 0,
      },
      {
        id: travelExpensesId,
        itemName: "Travel Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.travelExpenses || 0,
      },
      {
        id: hotelExpensesId,
        itemName: "Hotel Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.hotelExpenses || 0,
      },
      {
        id: otherExpensesId,
        itemName: "Other Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.otherExpenses || 0,
      },
      {
        id: lateBookingFeeId,
        itemName: "Late Booking Fee",
        quantity: 1,
        unitPrice: inspectionTaskDetail.lateBookingFee || 0,
      },
      {
        id: cancellationFeeId,
        itemName: "Cancellation Fee",
        quantity: 1,
        unitPrice: inspectionTaskDetail.cancellationFee || 0,
      },
    ];

    if (
      !tempInspectionTaskDetail.inspectionDateFrom ||
      !tempInspectionTaskDetail.inspectionDateTo ||
      !newTempTestItems[0].quantity
    ) {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);
      return alert("missing data");
    }

    let submitData = {
      submissionId: submissionId,
      invoice: tempData,
      invoiceTestItems: newTempTestItems,
      inspectionTaskDetail: tempInspectionTaskDetail,
    };

    atnResumeInvoice(submitData).then((res) => {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);

      if (res?.data) {
        history.push(`/lab/submissions/${submissionId}`);
      } else {
        return setTotalPriceError(res?.message);
      }
    });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleClose = () => {
    history.goBack();
  };

  const onShowWarningModal = () => {
    setOpenWarningModal(true);
  };

  const onHideWarningModal = () => {
    setOpenWarningModal(false);
  };

  const onClickSubmit = () => {
    if (isNegativeAmount) return onShowWarningModal();

    handleOpenConfirmDialog();
  };

  const checkHigherTotalPriceAlert = (newValue) => {
    // set state if new total price is larger than existing
    if (parseFloat(newValue) > parseFloat(originalTotalPrice)) {
      setIsOverOriginalTotalPrice(true);

      setTotalPriceError(
        <div>
          <strong>Original total price is ${originalTotalPrice}.</strong>
          {initialTotalPriceError()}
        </div>,
      );

      return true;
    }
  };

  const checkBelowPrepaymentPriceAlert = (newValue) => {
    if (isNewInvoice) return false;

    // show alert when subtotal is lower than prepayment price
    if (parseFloat(newValue) < parseFloat(prepaymentData.prepaymentPrice)) {
      setIsBelowPrepaymentPrice(true);

      setTotalPriceError(
        <div>
          <strong>Prepayment price is ${prepaymentData.prepaymentPrice}.</strong>
          {initialPrepaymentPriceError()}
        </div>,
      );

      return true;
    }
  };

  return (
    <Box className={classes.root}>
      <Header handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>
          {isTestingType && <Box>Please Fill Out the Invoice Detail</Box>}

          {isInspectionType && <Box>Update and Confirm the Invoice Detail</Box>}
        </Box>

        {isTestingType && (
          <TestingCostDetails
            isNewInvoice={isNewInvoice}
            subtitle={totalPriceRemark}
            totalPriceError={totalPriceError}
            detailData={detailData}
            testItems={testItems}
            testItemLength={testItemLength}
            prepaymentData={prepaymentData}
            handleDetailDataChange={handleDetailDataChange}
            handleTestItemDataChange={handleTestItemDataChange}
            handleAddNewTestItem={handleAddNewTestItem}
            handleRemoveTestItem={handleRemoveTestItem}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}

        {isInspectionType && (
          <InspectionCostDetails
            isNewInvoice={isNewInvoice}
            subtitle={totalPriceRemark}
            totalPriceError={totalPriceError}
            subTotal={inspectionSubTotal}
            inspectionTaskDetail={inspectionTaskDetail}
            prepaymentData={prepaymentData}
            handleInspectionTaskDetailChange={handleInspectionTaskDetailChange}
            handleInspectionPrepaymentChange={handleInspectionPrepaymentChange}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={onClickSubmit}
          disabled={
            isBelowPrepaymentPrice ||
            isOverOriginalTotalPrice ||
            isNegativeAmount ||
            (isTestingType && hasMissingItem)
          }
        >
          SUBMIT
        </Button>
      </Box>

      <WarningModal
        open={openWarningModal}
        onSubmit={onHideWarningModal}
        onClose={onHideWarningModal}
      />

      <ConfirmModal
        isDisabledSubmit={isDisabledConfirmButton}
        onSubmit={isTestingType ? handleTestingSubmit : handleInspectionSubmit}
        onClose={handleConfirmDialogClose}
        open={openConfirmDialog}
        contentText="Do you confirm to submit?"
      />
    </Box>
  );
};

export default ResumeInvoice;
