import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

const placeholderCharDefault = "\u2000";

const TextMaskCustom = (props) => {
  const { inputRef, mask, placeholderChar, keepCharPositions, ...others } = props;

  return (
    <MaskedInput
      {...others}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={mask}
      placeholderChar={placeholderChar}
      keepCharPositions={keepCharPositions}
    />
  );
};

TextMaskCustom.defaultProps = {
  placeholderChar: placeholderCharDefault,
  keepCharPositions: false,
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.array.isRequired,
  placeholderChar: PropTypes.string,
  keepCharPositions: PropTypes.bool,
};

export default TextMaskCustom;
