import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";

import FactoryInformation from "./FactoryInformation";
import ProductInformation from "./ProductInformation";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
}));

const Form = ({
  data,
  countryList = [],
  stateList = [],
  cityList = [],
  hideState,
  manufactureStateList = [],
  manufactureCityList = [],
  hideManufactureState,
  handleFactoryDataChange,
  handleMasterWicDataChange,
  handleMasterWicDataRadioChange,
  handleMasterWicDataCheckBoxChange,
  handleExpandClick,
  handleExpandAll,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <FactoryInformation
        data={data.factory || {}}
        countryList={countryList}
        stateList={stateList}
        cityList={cityList}
        hideState={hideState}
        manufactureStateList={manufactureStateList}
        manufactureCityList={manufactureCityList}
        hideManufactureState={hideManufactureState}
        handleFactoryDataChange={handleFactoryDataChange}
      />
      <Box pt={4}>
        <ProductInformation
          data={data.masterWics || []}
          handleMasterWicDataChange={handleMasterWicDataChange}
          handleMasterWicDataRadioChange={handleMasterWicDataRadioChange}
          handleMasterWicDataCheckBoxChange={handleMasterWicDataCheckBoxChange}
          handleExpandClick={handleExpandClick}
          handleExpandAll={handleExpandAll}
        />
      </Box>
    </Paper>
  );
};

export default Form;
