import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import TextInput from "components/input/TextInput";
import _get from "lodash/get";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  buttonContainer: {
    marginTop: 20,
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogCloseBtn: {
    position: "absolute",
    right: 10,
  },
  searchFieldContainer: {
    paddingTop: 15,
  },
  listContainer: {
    paddingBottom: 20,
  },
  listRowContainer: {
    position: "relative",
    padding: "20px 0px",
    borderTop: "1px solid #777777",
    "&:last-child": {
      borderBottom: "1px solid #777777",
    },
  },
  rowTitle: {
    fontSize: 12,
    fontWeight: 500,
  },
  rowData: {
    paddingTop: 10,
    fontSize: 12,
    color: "#777777",
    maxWidth: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expandBtn: {
    position: "absolute",
    top: 5,
    right: 120,
  },
  selectBtn: {
    position: "absolute",
    top: 10,
    right: 0,
  },
  dataCard: {
    border: "1px solid #C4C8D0",
    padding: 10,
    width: 350,
    borderRadius: 4,
    marginTop: 20,
  },
  dataCardTitle: {
    fontSize: 12,
    fontWeight: 500,
  },
  dataCardContentContainer: {
    paddingTop: 5,
    fontSize: 12,
    color: "#777777",
  },
  dataCardContentPadding: {
    paddingTop: 5,
  },
  removeBtn: {
    marginTop: 10,
  },
  newFactoryMessage: {
    fontSize: 12,
    paddingTop: 25,
    paddingBottom: 10,
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#005EFF",
  },
  factoryEmptyResultContainer: {
    textAlign: "center",
    marginTop: 6,
    paddingTop: 30,
    borderTop: "1px solid #263238",
  },
  emptyMessage: {
    fontSize: 12,
  },
  addNewBtn: {
    marginTop: 25,
    marginBottom: 25,
  },
}));

const SupplierFactoryDetails = ({
  data: { itemData = {}, suppliers = [], factories = [] },
  func: { onItemDataChange, handleNextPage },
}) => {
  const classes = useStyles();

  const [openFactoryDialog, setOpenFactoryDialog] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [factoryKeyword, setFactoryKeyword] = useState("");
  const [supplierKeyword, setSupplierKeyword] = useState("");
  const [factoryList, setFactoryList] = useState(factories);
  // const [factoryList, setFactoryList] = useState([]);
  // const [supplierList, setSupplierList] = useState(suppliers);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    setFactoryList(factories);
  }, [factories]);

  const handleFactoryKeywordChange = (e) => {
    const { value } = e.target;

    setFactoryKeyword(value);

    if (value) {
      let newFactories = factories.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
          item.code.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setFactoryList(newFactories);
    } else {
      setFactoryList(factories);
    }
  };

  const handleSupplierKeywordChange = (e) => {
    const { value } = e.target;

    setSupplierKeyword(value);

    if (value) {
      let newSuppliers = suppliers.filter((item) => {
        return (
          (item.supplierCompanyName &&
            item.supplierCompanyName.toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
          (item.supplierID && item.supplierID.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        );
      });

      setSupplierList(newSuppliers);
    } else {
      setSupplierList([]);
    }
  };

  const handleFactoryShowMore = (index) => {
    setFactoryList((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item.showMore = !item.showMore;
        }

        return item;
      });
    });
  };

  const handleSupplierShowMore = (index) => {
    setSupplierList((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item.showMore = !item.showMore;
        }

        return item;
      });
    });
  };

  const handleFactorySelect = (factory) => {
    onItemDataChange({
      target: {
        name: "supplierFactory",
        value: factory,
      },
    });

    setOpenFactoryDialog(false);
  };

  const handleSupplierSelect = (supplier) => {
    onItemDataChange({
      target: {
        name: "supplier",
        value: supplier,
      },
    });

    setOpenSupplierDialog(false);
  };

  const handleRemoveSupplier = () => {
    onItemDataChange({
      target: {
        name: "supplier",
        value: {},
      },
    });
  };

  const handleRemoveFactory = () => {
    onItemDataChange({
      target: {
        name: "supplierFactory",
        value: {},
      },
    });
  };

  const handleAddNewFactory = () => {
    onItemDataChange({
      target: {
        name: "supplierFactory",
        value: {
          isNewAdd: true,
          name: "",
          address: "",
          code: "",
          contactPersonName: "",
          contactNumber: "",
          contactEmail: "",
        },
      },
    });

    setOpenFactoryDialog(false);
  };

  const handleFactoryDataChange = (e) => {
    const { name, value } = e.target;

    onItemDataChange({
      target: {
        name: "supplierFactory",
        value: {
          ...itemData.supplierFactory,
          [name]: value,
        },
      },
    });
  };

  const handleFactoryDialogOpen = () => {
    setOpenFactoryDialog(true);
  };

  const handleFactoryDialogClose = () => {
    setOpenFactoryDialog(false);
  };

  const handleSupplierDialogOpen = () => {
    setOpenSupplierDialog(true);
  };

  const handleSupplierDialogClose = () => {
    setOpenSupplierDialog(false);
  };

  const disabledSubmitCondition = () => {
    return (
      !itemData.supplier.id ||
      (!itemData.supplierFactory.isNewAdd && !itemData.supplierFactory.id) ||
      (itemData.supplierFactory.isNewAdd && !itemData.supplierFactory.name)
    );
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>1. Select Supplier</Box>

        {itemData.supplier && itemData.supplier.id ? (
          <>
            <Box className={classes.dataCard} data-cy={"ci-supplier-card"}>
              <Box className={classes.dataCardTitle}>{itemData.supplier.supplierCompanyName}</Box>
              <Box className={classes.dataCardContentContainer}>
                <Box className={classes.dataCardContentPadding}>
                  Contact Person: {itemData.supplier.contactPerson}
                </Box>
                <Box className={classes.dataCardContentPadding}>
                  Supplier Email: {itemData.supplier.supplierEmail}
                </Box>
                <Box className={classes.dataCardContentPadding}>
                  Supplier ID: {itemData.supplier.supplierID}
                </Box>
              </Box>
            </Box>

            <Box className={classes.removeBtn}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleRemoveSupplier}
                data-cy={"ci-supplier-remove"}
              >
                REMOVE
              </Button>
            </Box>
          </>
        ) : (
          <Box display="flex" className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSupplierDialogOpen}
              data-cy={"ci-supplier-btn"}
            >
              SELECT SUPPLIER
            </Button>
          </Box>
        )}

        <Box className={classnames(classes.title, classes.fieldPadding)}>2. Select Factory</Box>

        {itemData.supplierFactory &&
        (itemData.supplierFactory.id || itemData.supplierFactory.isNewAdd) ? (
          itemData.supplierFactory.isNewAdd ? (
            <>
              <Box className={classes.newFactoryMessage}>
                You have seleted to add a new factory, if not click{" "}
                <span className={classes.linkText} onClick={handleFactoryDialogOpen}>
                  here
                </span>{" "}
                to select an existing supplier factory.
              </Box>
              <Box className={classes.subtitle}>ID</Box>
              <TextInput
                placeholder="ID"
                name="code"
                value={itemData.supplierFactory.code}
                onChange={handleFactoryDataChange}
                additionalClass={classes.inputField}
                disabled={!itemData.supplierFactory.isNewAdd}
              />

              <Box className={classes.subtitle}>Company Name</Box>
              <TextInput
                placeholder="Company Name"
                name="name"
                value={itemData.supplierFactory.name}
                onChange={handleFactoryDataChange}
                additionalClass={classes.inputField}
              />

              <Box className={classes.subtitle}>Address</Box>
              <TextInput
                placeholder="Address"
                name="address"
                value={itemData.supplierFactory.address}
                onChange={handleFactoryDataChange}
                additionalClass={classes.inputField}
              />
            </>
          ) : (
            <>
              <Box className={classes.dataCard} data-cy={"ci-factory-card"}>
                <Box className={classes.dataCardTitle}>{itemData.supplierFactory.name}</Box>
                <Box className={classes.dataCardContentContainer}>
                  <Box className={classes.dataCardContentPadding}>
                    Factory Address: {itemData.supplierFactory.address}
                  </Box>
                  <Box className={classes.dataCardContentPadding}>
                    Factory ID: {itemData.supplierFactory.code}
                  </Box>
                  <Box className={classes.dataCardContentPadding}>
                    Contact Person Name: {itemData.supplierFactory.contactPersonName}
                  </Box>
                  <Box className={classes.dataCardContentPadding}>
                    Contact Number: {itemData.supplierFactory.contactNumber}
                  </Box>
                  <Box className={classes.dataCardContentPadding}>
                    Contact Person Email: {itemData.supplierFactory.contactEmail}
                  </Box>
                </Box>
              </Box>

              <Box className={classes.removeBtn}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleRemoveFactory}
                  data-cy={"ci-factory-remove"}
                >
                  REMOVE
                </Button>
              </Box>
            </>
          )
        ) : (
          <Box display="flex" className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFactoryDialogOpen}
              disabled={!itemData.supplier.id}
              data-cy={"ci-factory-btn"}
            >
              SELECT SUPPLIER FACTORY
            </Button>
          </Box>
        )}
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={disabledSubmitCondition()}
        data-cy={"ci-continue"}
      >
        CONTINUE
      </Button>

      <Dialog
        className={classes.dialog}
        onClose={handleSupplierDialogClose}
        open={openSupplierDialog}
        PaperProps={{
          "data-cy": "ci-dialog",
        }}
      >
        <MuiDialogTitle>
          <Typography className={classes.dialogTitle}>Please Select Supplier</Typography>
          <IconButton
            className={classes.dialogCloseBtn}
            onClick={handleSupplierDialogClose}
            data-cy={`ci-supplier-close`}
          >
            <CloseIcon />
          </IconButton>
          <Box className={classes.searchFieldContainer}>
            <TextInput
              placeholder="Search Supplier Name or ID"
              name="supplierKeyword"
              value={supplierKeyword}
              onChange={handleSupplierKeywordChange}
              cy={"ci-supplier-search"}
            />
          </Box>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box
            className={classes.listContainer}
            data-cy={"ci-supplier-list"}
            data-cy-count={supplierList.length}
          >
            {supplierList.map((supplier, index) => (
              <Box key={supplier.id} className={classes.listRowContainer}>
                <Box className={classes.rowTitle}>{supplier.supplierCompanyName}</Box>

                <IconButton
                  className={classes.expandBtn}
                  onClick={() => handleSupplierShowMore(index)}
                  data-cy={`ci-supplier-showmore-${supplier.id}`}
                >
                  {supplier.showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.selectBtn}
                  onClick={() => handleSupplierSelect(supplier)}
                  data-cy={`ci-supplier-select-${supplier.id}`}
                >
                  SELECT
                </Button>

                {supplier.showMore && (
                  <Box className={classes.rowData} data-cy={`ci-supplier-info-${supplier.id}`}>
                    <Box>Contact Person: {supplier.contactPerson}</Box>
                    <Box>Supplier Email: {supplier.supplierEmail}</Box>
                    <Box>Supplier ID: {supplier.supplierID}</Box>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </MuiDialogContent>
      </Dialog>

      <Dialog
        className={classes.dialog}
        onClose={handleFactoryDialogClose}
        open={openFactoryDialog}
        PaperProps={{
          "data-cy": "ci-dialog",
        }}
      >
        <MuiDialogTitle>
          <Typography className={classes.dialogTitle}>Please Select Supplier Factory</Typography>
          <IconButton className={classes.dialogCloseBtn} onClick={handleFactoryDialogClose}>
            <CloseIcon />
          </IconButton>
          <Box className={classes.searchFieldContainer}>
            <TextInput
              placeholder="Search factory name or id"
              name="factoryKeyword"
              value={factoryKeyword}
              onChange={handleFactoryKeywordChange}
              cy={"ci-factory-search"}
            />
          </Box>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box
            className={classes.listContainer}
            data-cy={"ci-factory-list"}
            data-cy-count={factoryList.length}
          >
            {factoryKeyword && factoryList.length === 0 ? (
              <Box className={classes.factoryEmptyResultContainer}>
                <Box className={classes.emptyMessage}>
                  No Result for "{factoryKeyword}", Click Add New for a New Factory
                </Box>
                <Box className={classes.addNewBtn}>
                  <Button variant="outlined" color="primary" onClick={handleAddNewFactory}>
                    ADD NEW
                  </Button>
                </Box>
              </Box>
            ) : (
              factoryList.map((factory, index) => (
                <Box
                  key={factory.id}
                  className={classes.listRowContainer}
                  data-cy={`ci-factory-item-${factory.id}`}
                >
                  <Box className={classes.rowTitle}>{factory.name}</Box>

                  <IconButton
                    className={classes.expandBtn}
                    onClick={() => handleFactoryShowMore(index)}
                    data-cy={`ci-factory-showmore-${factory.id}`}
                  >
                    {factory.showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.selectBtn}
                    onClick={() => handleFactorySelect(factory)}
                    data-cy={`ci-factory-select-${factory.id}`}
                  >
                    SELECT
                  </Button>

                  {factory.showMore && (
                    <Box className={classes.rowData} data-cy={`ci-factory-info-${factory.id}`}>
                      <Box>Factory Address: {factory.address}</Box>
                      <Box>Factory ID: {factory.code}</Box>
                      <Box>Contact Person Name: {_get(factory, "contactPerson", "N/A")}</Box>
                      <Box>Contact Number: {_get(factory, "contactNumber", "N/A")}</Box>
                      <Box>Contact Person Email: {_get(factory, "contactEmail", "N/A")}</Box>
                    </Box>
                  )}
                </Box>
              ))
            )}
          </Box>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default SupplierFactoryDetails;
