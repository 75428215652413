import React from "react";
import classnames from "classnames";
import moment from "moment";
import MomentFnsUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Check as SubmitIcon, Close as CancelIcon, Edit as EditIcon } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { DATE_FORMAT_02, DD_MMM_YYYY } from "shared/constants";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  rootEditing: {
    flexWrap: "wrap",
  },
  dateTimePicker: {
    "& > div": {
      height: 24,
      padding: 4,
      "& input": {
        padding: 4,
      },
      "& svg": {
        width: "0.8em",
        height: "0.8em",
      },
    },
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: "#777777",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
}));

const DateTimePicker = (props) => {
  const classes = useStyles();

  const todayValue = moment().format(DATE_FORMAT_02); // today system format

  const {
    type = "date",
    variant = "inline", // 'dialog' | 'inline' | 'static'
    inputVariant = "outlined",
    label,
    pickerProps,
    textClassName,
    onSubmit,
    isPicker = false,
  } = props;
  const {
    className: classNameProps,
    initialValue,
    value = todayValue,
    setValue,
    format: formatDisplayed = DD_MMM_YYYY, // display format
    open = false,
    ...others
  } = pickerProps;

  const [editing, setEditing] = React.useState(false);
  const [openState, setOpenState] = React.useState(open);

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleChange = (dateTime) => {
    const dateTimeFormatted = moment(dateTime).format(DATE_FORMAT_02);
    setValue(dateTimeFormatted);

    setOpenState(true);
  };

  const handleClose = () => {
    setOpenState(false);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    if (setValue) setValue(initialValue);
    setEditing(false);
  };

  const handleSubmit = () => {
    if (setValue) setValue(value);
    if (onSubmit) onSubmit(value);
    setEditing(false);
  };

  const renderComponent = () => {
    const className = classnames(classes.dateTimePicker, classNameProps);

    const defaultProps = {
      className,
      variant,
      inputVariant,
      value: value,
      format: formatDisplayed,
      open: openState,
      onOpen: handleOpen,
      onChange: handleChange,
      onClose: handleClose,
      disableToolbar: true,
      autoOk: true,
    };

    switch (type) {
      case "date":
        return <KeyboardDatePicker {...defaultProps} {...others} />;
      case "time":
        return <KeyboardTimePicker {...defaultProps} {...others} />;
      case "datetime":
        return <KeyboardDateTimePicker {...defaultProps} {...others} />;
      default:
        return <KeyboardDatePicker {...defaultProps} {...others} />;
    }
  };

  return isPicker ? (
    <MuiPickersUtilsProvider utils={MomentFnsUtils}>{renderComponent()}</MuiPickersUtilsProvider>
  ) : (
    <Box className={classnames(classes.root, { [classes.rootEditing]: editing })}>
      {editing ? (
        <>
          {label && <Typography className={textClassName}>{label}</Typography>}
          <Box display="flex" alignItems="flex-start">
            <MuiPickersUtilsProvider utils={MomentFnsUtils}>
              {renderComponent()}
            </MuiPickersUtilsProvider>
            <Box display="flex" mt={0.5}>
              <SubmitIcon className={classes.iconButton} onClick={handleSubmit} />
              <CancelIcon className={classes.iconButton} onClick={handleCancel} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography className={textClassName}>
            {`${label ? `${label}: ` : ""}${textOrUnknown(
              value ? moment(value).format(formatDisplayed) : null,
            )}`}
          </Typography>
          <EditIcon className={classes.iconButton} onClick={handleEdit} />
        </>
      )}
    </Box>
  );
};

DateTimePicker.propTypes = {};

export default DateTimePicker;
