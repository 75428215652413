import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TextInput from "components/input/TextInput";
// import Card from 'components/display/Card';
import DatePicker from "components/input/DatePicker";

const buyerName = process.env.REACT_APP_BUYERNAME;

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    border: "1px solid #777777",
    boxShadow: "none",

    "& > div": {
      padding: 0,
    },
  },
  cardContent: {
    padding: 0,
    paddingBottom: "0 !important",
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
    // paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  textLabel: {
    paddingBottom: theme.spacing(1),
  },
  rowContainer: {
    display: "flex",
    flexGrow: 1,
  },
  rowLeftCol: {
    display: "flex",
    flexDirection: "column",
    width: 280,
    padding: theme.spacing(2),
    borderRight: "1px solid #777777",
  },
  rowRightCol: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(2),
  },
  headerLeftCol: {
    width: 282,
  },
  headerItem: {
    display: "flex",
    flexDirection: "column",
    width: "24%",
  },
  contentContainer: {
    borderTop: "1px solid #777777",
  },
  gridContainer: {
    paddingTop: 10,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    borderRight: "none",
    "&:last-child": {
      borderRight: "1px solid #777777",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputSubTitle: {
    fontSize: 10,
    color: "#777777",
  },
  viewText: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputField: {
    width: "100%",
  },
  noLeftBorder: {
    borderLeft: "none",
  },
  noRightBorder: {
    borderRight: "none",
  },
  noTopBorder: {
    borderTop: "none",
  },
  noBottomBorder: {
    borderBottom: "none",
  },
  expandAll: {
    cursor: "pointer",
    color: "#005EFF",
    fontSize: 10,
  },
  expandBtnContainer: {
    textAlign: "right",
  },
  leftRadio: {
    marginRight: 20,
  },
  radio: {
    "& .MuiTypography-body1": {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  checkbox: {
    "& .MuiTypography-body1": {
      fontSize: 10,
      fontWeight: 400,
    },
  },
}));

const ProductInformation = ({
  data,
  handleMasterWicDataChange,
  handleMasterWicDataRadioChange,
  handleMasterWicDataCheckBoxChange,
  handleExpandClick,
  handleExpandAll,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* <Typography className={classes.title}>Product Information (per WIC)</Typography> */}
      <Box display="flex">
        <Box flexGrow={1} className={classes.title}>
          Product Information (per WIC)
        </Box>

        <Box className={classes.expandAll} onClick={handleExpandAll}>
          expand all
        </Box>
      </Box>

      {data.map((item, index) => (
        <Box key={item.masterWicId} className={classes.gridContainer}>
          <Grid container>
            <Grid item xs={2} className={classes.firstGrid}>
              <Box className={classes.inputSubTitle}>Master WIC</Box>
              <Box className={classes.viewText}>{item.wicNumber}</Box>
              <Box className={classes.viewText}>{item.productName}</Box>
            </Grid>

            <Grid item xs={2} className={classnames(classes.firstGrid, classes.noRightBorder)}>
              <Box className={classes.viewText}>Additional WIC Number</Box>
              <Box>
                <TextInput
                  placeholder=""
                  name="additionalWicNumber"
                  value={item.additionalWicNumber}
                  additionalClass={classes.inputField}
                  onChange={(e) => handleMasterWicDataChange(e, index)}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={2}
              className={classnames(classes.firstGrid, classes.noLeftBorder, classes.noRightBorder)}
            >
              <Box className={classes.viewText}>Style Number</Box>
              <Box>
                <TextInput
                  placeholder=""
                  name="styleNumber"
                  value={item.styleNumber}
                  additionalClass={classes.inputField}
                  onChange={(e) => handleMasterWicDataChange(e, index)}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={2}
              className={classnames(classes.firstGrid, classes.noLeftBorder, classes.noRightBorder)}
            >
              <Box className={classes.viewText}>Content / Size / Count</Box>
              <Box>
                <TextInput
                  placeholder=""
                  name="contentSizeCount"
                  value={item.contentSizeCount}
                  additionalClass={classes.inputField}
                  onChange={(e) => handleMasterWicDataChange(e, index)}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={2}
              className={classnames(classes.firstGrid, classes.noLeftBorder, classes.noRightBorder)}
            >
              <Box className={classes.viewText}>Date of Manufacture</Box>
              <Box>
                <DatePicker
                  name="dateOfManufacture"
                  value={item.dateOfManufacture}
                  handleChange={(e) => handleMasterWicDataChange(e, index)}
                  additionalClass={classes.inputField}
                />
              </Box>
            </Grid>

            <Grid item xs={2} className={classnames(classes.firstGrid, classes.noLeftBorder)}>
              <Box className={classes.expandBtnContainer}>
                <IconButton size="small" onClick={() => handleExpandClick(index)}>
                  {item.showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          {item.showMore && (
            <>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  className={classnames(classes.grid, {
                    [classes.noBottomBorder]: item.isGoodNotForResale,
                  })}
                >
                  <Box className={classes.inputTitle}>Good Not for Resale:</Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="isGoodNotForResale"
                    value={item.isGoodNotForResale ? "yes" : "no"}
                    onChange={(e) => handleMasterWicDataRadioChange(e, index)}
                  >
                    <Box display="flex">
                      <Box className={classes.leftRadio}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          className={classes.radio}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          className={classes.radio}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid>

              {item.isGoodNotForResale && (
                <Grid container>
                  <Grid item xs={2} className={classnames(classes.grid, classes.noTopBorder)}>
                    <Box className={classes.inputTitle}>
                      If yes, please specify whether this is a promotional free giveaway OR store
                      expense item:
                    </Box>
                  </Grid>

                  <Grid item xs={10} className={classes.grid}>
                    <RadioGroup
                      name="goodNotForResaleReason"
                      value={item.goodNotForResaleReason}
                      onChange={(e) => handleMasterWicDataChange(e, index)}
                    >
                      <FormControlLabel
                        value="Promotional Free Giveaway"
                        control={<Radio color="primary" />}
                        label="Promotional Free Giveaway"
                        className={classes.radio}
                      />

                      <FormControlLabel
                        value="Store Expense Item"
                        control={<Radio color="primary" />}
                        label="Store Expense Item"
                        className={classes.radio}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              )}

              {/* <Grid container>
                <Grid item xs={2} className={classes.grid}>
                  <Box className={classes.inputTitle}>
                    Sourced by Walgreens Boots Alliance (WBA) Asia Office:
                  </Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="isSourcedByWBA"
                    value={item.isSourcedByWBA ? "yes" : "no"}
                    onChange={e => handleMasterWicDataRadioChange(e, index)}
                  >
                    <Box display="flex">
                      <Box className={classes.leftRadio}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary"/>}
                          label="Yes"
                          className={classes.radio}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary"/>}
                          label="No"
                          className={classes.radio}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid> */}

              <Grid container>
                <Grid item xs={2} className={classes.grid}>
                  <Box className={classes.inputTitle}>Is this a Duane Reade Test Submission?</Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="isDuaneReadeTestSubmission"
                    value={item.isDuaneReadeTestSubmission ? "yes" : "no"}
                    onChange={(e) => handleMasterWicDataRadioChange(e, index)}
                  >
                    <Box display="flex">
                      <Box className={classes.leftRadio}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          className={classes.radio}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          className={classes.radio}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={2} className={classes.grid}>
                  <Box className={classes.inputTitle}>
                    The product has a child resistance package:
                  </Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="isChildResistancePackage"
                    value={item.isChildResistancePackage ? "yes" : "no"}
                    onChange={(e) => handleMasterWicDataRadioChange(e, index)}
                  >
                    <Box display="flex">
                      <Box className={classes.leftRadio}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          className={classes.radio}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          className={classes.radio}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={2} className={classnames(classes.grid, classes.noBottomBorder)}>
                  <Box className={classes.inputTitle}>
                    For children product, the product is labeled with the following CPSIA Tracking
                    Label:
                  </Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="isFollowingCpsiaTrackingLabel"
                    value={item.isFollowingCpsiaTrackingLabel ? "yes" : "no"}
                    onChange={(e) => handleMasterWicDataRadioChange(e, index)}
                  >
                    <Box display="flex">
                      <Box className={classes.leftRadio}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          className={classes.radio}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          className={classes.radio}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid>

              {item.isFollowingCpsiaTrackingLabel && (
                <Grid container>
                  <Grid item xs={2} className={classnames(classes.grid, classes.noTopBorder)}>
                    <Box className={classes.inputTitle}>
                      If Yes, list Tracking Label on product and how to interpret it:
                    </Box>
                  </Grid>

                  <Grid item xs={10} className={classes.grid}>
                    <TextInput
                      placeholder=""
                      name="trackingLabelRemark"
                      value={item.trackingLabelRemark}
                      additionalClass={classes.inputField}
                      onChange={(e) => handleMasterWicDataChange(e, index)}
                    />
                  </Grid>
                </Grid>
              )}

              {!item.isFollowingCpsiaTrackingLabel && (
                <Grid container>
                  <Grid item xs={2} className={classnames(classes.grid, classes.noTopBorder)}>
                    <Box className={classes.inputTitle}>
                      If No, check the reason(s) for NOT being marked with a tracking label:
                    </Box>
                  </Grid>

                  <Grid item xs={10} className={classes.grid}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "Major component(s) is labeled but not all individual pieces due to feasibility",
                              ) >= 0
                            }
                            value="Major component(s) is labeled but not all individual pieces due to feasibility"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="Major component(s) is labeled but not all individual pieces due to feasibility"
                        className={classes.checkbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "Product is too small to be marked",
                              ) >= 0
                            }
                            value="Product is too small to be marked"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="Product is too small to be marked"
                        className={classes.checkbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "Product surface would be impossible to mark permanently",
                              ) >= 0
                            }
                            value="Product surface would be impossible to mark permanently"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="Product surface would be impossible to mark permanently"
                        className={classes.checkbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "The product is not for children",
                              ) >= 0
                            }
                            value="The product is not for children"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="The product is not for children"
                        className={classes.checkbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "Tracking label would damage or weaken the product or impair its function",
                              ) >= 0
                            }
                            value="Tracking label would damage or weaken the product or impair its function"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="Tracking label would damage or weaken the product or impair its function"
                        className={classes.checkbox}
                      />
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              item.reasonNotBeingMarkedWithTrackingLabel.indexOf(
                                "Tracking label would ruin the aesthetics of the products",
                              ) >= 0
                            }
                            value="Tracking label would ruin the aesthetics of the products"
                            onChange={(e) => handleMasterWicDataCheckBoxChange(e, index)}
                            name="reasonNotBeingMarkedWithTrackingLabel"
                            color="primary"
                          />
                        }
                        label="Tracking label would ruin the aesthetics of the products"
                        className={classes.checkbox}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={2} className={classes.grid}>
                  <Box className={classes.inputTitle}>Sample Submission</Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="sampleSubmission"
                    value={item.sampleSubmission}
                    onChange={(e) => handleMasterWicDataChange(e, index)}
                  >
                    <FormControlLabel
                      value="Supplier Direct Submit"
                      control={<Radio color="primary" />}
                      label="Supplier Direct Submit"
                      className={classes.radio}
                    />

                    <FormControlLabel
                      value={`Sample Purchased in ${buyerName} Retail Store (Probationary USA and Retail Audit)`}
                      control={<Radio color="primary" />}
                      label={`Sample Purchased in ${buyerName} Retail Store (Probationary USA and Retail Audit)`}
                      className={classes.radio}
                    />

                    <FormControlLabel
                      value="Sample Collection"
                      control={<Radio color="primary" />}
                      label="Sample Collection"
                      className={classes.radio}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={2} className={classes.grid}>
                  <Box className={classes.inputTitle}>Return Sample</Box>
                </Grid>

                <Grid item xs={10} className={classes.grid}>
                  <RadioGroup
                    name="returnSample"
                    value={item.returnSample}
                    onChange={(e) => handleMasterWicDataChange(e, index)}
                  >
                    <FormControlLabel
                      value="No"
                      control={<Radio color="primary" />}
                      label="No"
                      className={classes.radio}
                    />

                    <FormControlLabel
                      value="Yes – Self Collect"
                      control={<Radio color="primary" />}
                      label="Yes – Self Collect"
                      className={classes.radio}
                    />

                    <FormControlLabel
                      value="Yes – Lab to return (chargeable, provide courier #)"
                      control={<Radio color="primary" />}
                      label="Yes – Lab to return (chargeable, provide courier #)"
                      className={classes.radio}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ))}
    </>
  );
};

export default ProductInformation;
