import React from "react";
import { atnLogin, atnMe } from "../../actions/authActions";

const HackAccount = ({ match, history }) => {
  const role = match.params.role;
  const token = match.params.token;
  const useremail = match.params.useremail;

  if (role && token) {
    localStorage.setItem("token", `Bearer ${token}`);
    localStorage.setItem("role", role);
    localStorage.setItem("useremail", useremail);

    let checkTokenTimes = 0;

    const checkToken = setInterval(() => {
      checkTokenTimes += 1;

      if (checkTokenTimes > 20) {
        clearInterval(checkToken);
        alert("hack account fail");
      }

      if (localStorage.getItem("token") && localStorage.getItem("role")) {
        clearInterval(checkToken);

        atnMe().then((result) => {
          console.log(result.data.companyName);
          localStorage.setItem("companyName", result.data.companyName);
          history.push(`/${role}/home`);
        });
      }
    }, 200);
  }

  return <></>;
};

export default HackAccount;
