import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import {
  atnGetSupplierHomeFilterList,
  atnGetSupplierHomeResultList,
  atnGetSupplierProfile,
  atnUpdateSupplierProfile,
} from "actions/homePageActions";
import SidePanelMenu from "components/tab/TabSideMenu/TabPanelContent";
import { SUPPLIER_HOME_CONTENT_TYPE, SUPPLIER_HOME_FILTER_LIST } from "shared/constants";

import PageContent from "./PageContent";
import dataJSON from "./data.json";
import Modal from "components/modal/Modal";
import Button from "../../../components/input/Button";
import TextInput from "../../../components/input/TextInput";
import Select from "../../../components/input/Select";
import { atnGetCities, atnGetCountries, atnGetStates } from "../../../actions/commonActions";

const useStyles = makeStyles((theme) => ({
  tabSideMenuContainer: {
    position: "fixed",
    top: 40,
    bottom: 0,
    width: 300,
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 999,
  },
  contentContainer: {
    marginLeft: 300,
  },
  panelContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    overflowY: "auto",
  },
  discardPop: {
    "& hr": {
      background: "#003DA5",
    },
  },
  textContent: {
    color: "black",
    fontSize: 14,
    fontWeight: "initial",
  },
  applyBtn: {
    marginLeft: 25,
  },
  fourBox: {
    float: "right",
    paddingBottom: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  inputPadding: {
    paddingTop: 10,
  },
  shortInputField: {
    width: 400,
  },
  blockPadding: {
    paddingRight: 10,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  hide: {
    display: "none",
  },
}));

const SupplierHomePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [contentType, setContentType] = useState(SUPPLIER_HOME_CONTENT_TYPE.item.value);
  const [panelItems, setPanelItems] = useState(SUPPLIER_HOME_FILTER_LIST);
  const [selectedPanelItem, setSelectedPanelItem] = useState({});
  const [supplierProfileModal, setSupplierProfileModal] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hideState, setHideState] = useState(false);

  const [contentData, setContentData] = useState({
    type: "",
    tableTitle: "N/A",
    totalNumber: 0,
    rowData: [],
  });

  const [supplierData, setSupplierData] = useState({
    cityId: null,
    stateId: null,
    countryId: null,
  });

  const getCountries = () => {
    atnGetCountries().then(({ data, message }) => {
      let countries = [];

      for (let i = 0; i < data.length; i++) {
        countries.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCountryList(countries);
    });
  };

  const getStates = (countryId) => {
    atnGetStates({
      countryId: countryId,
    }).then(({ data, message }) => {
      if (data && data.length === 1 && !data[0].name) {
        getCities(data[0].id);
        setHideState(true);
      } else {
        setHideState(false);
      }

      let states = [];

      for (let i = 0; i < data.length; i++) {
        states.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setStateList(states);
    });
  };

  const getCities = (stateId) => {
    atnGetCities({
      stateId: stateId,
    }).then(({ data, message }) => {
      let cities = [];

      for (let i = 0; i < data.length; i++) {
        cities.push({
          value: data[i].id,
          label: data[i].name,
        });
      }

      setCityList(cities);
    });
  };

  useEffect(() => {
    if (selectedPanelItem && selectedPanelItem.item) {
      handleFetchResultList(selectedPanelItem.item);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPanelItem]);

  const handleFetchResultList = (item) => {
    const qs = {
      page: 1,
      limit: 999,
      filterType: item,
    };

    atnGetSupplierHomeResultList(queryString.stringify(qs)).then(({ data }) => {
      if (data) {
        if (
          item === "waitingForLabQuote" ||
          item === "confirmQuote" ||
          item === "waitingLabToConfirmBooking"
        ) {
          setContentType("submission");
          // handleSetSubmissionTableData(data.list, t(`supplierHomeItem.${item}`), data.page.total);
        } else if (item === "uploadCAPForFailTask" || item === "overdueTask") {
          setContentType("task");
          // handleSetTaskTableData(data.list, t(`supplierHomeItem.${item}`), data.page.total);
        } else {
          setContentType("item");
          // handleSetItemsTableData(data.list, t(`supplierHomeItem.${item}`), data.page.total);
        }
        if (data.list && data.list.length) {
          setContentData({
            type: item,
            tableTitle: t(`supplierHomeItem.${item}`),
            totalNumber: data.page.total,
            rowData: data.list,
          });
        } else {
          setContentData({
            type: item,
            tableTitle: t(`supplierHomeItem.${item}`),
            totalNumber: 0,
            rowData: [],
          });
        }
      }
    });
  };

  const handleFetchFilterList = () => {
    atnGetSupplierHomeFilterList().then(({ data }) => {
      if (data) {
        let filterList = SUPPLIER_HOME_FILTER_LIST;

        for (let i = 0; i < filterList.length; i++) {
          filterList[i].number = data[filterList[i].itemName];
        }

        setPanelItems(filterList);

        // set first type by default
        setSelectedPanelItem({
          item: filterList[0].itemName,
        });
      }
    });
  };

  const handleFetchSupplierProfile = () => {
    atnGetSupplierProfile().then(async ({ data }) => {
      if (data && !data.isProfileSetup) {
        await setSupplierProfileModal(true);
        await getCountries();

        if (data.countryId) {
          await getStates(data.countryId);
          await getCities(data.stateId);
        }

        await setSupplierData(data);
      }
    });
  };

  useEffect(() => {
    handleFetchSupplierProfile();
    handleFetchFilterList();
    return () => {
      // cleanup
    };
  }, []);

  const handlePanelItemChange = (item) => {
    setSelectedPanelItem({ item });
  };

  const handleSubmit = async () => {
    if (
      !supplierData.cityId ||
      !supplierData.contactPerson ||
      !supplierData.contactEmail ||
      !supplierData.contactNumber ||
      !supplierData.address
    ) {
      return alert("Please fill all data before confirm");
    }

    await atnUpdateSupplierProfile(supplierData);
    await setSupplierData({
      cityId: null,
      stateId: null,
      countryId: null,
    });

    await setSupplierProfileModal(false);
  };

  const handleActionClick = (id, actionType) => {
    const typeActions = Object.values(dataJSON.supplierActions).map(({ type }) => type);
    switch (actionType) {
      case typeActions[0]: // acknowledgeProject
        window.open(`/supplier/acknowledgeItem/${id}`);
        break;
      case typeActions[1]: // openTask
      case typeActions[7]: // uploadCap
        window.open(`/supplier/openTask/${id}`);
        break;
      case typeActions[2]: // confirmQuote
        window.open(`/supplier/submissions/${id}?tab=quote`);
        break;
      case typeActions[3]: // uploadProductSpecification
      case typeActions[4]: // uploadArtwork
      case typeActions[5]: // uploadAisDocument
        window.open(`/supplier/items/${id}?tab=products`);
        break;
      case typeActions[6]: // uploadAisFactoryDocument
        window.open(`/supplier/items/${id}?tab=supplierFactory`);
        break;
      default:
        break;
    }
  };

  const handleSupplierDataChange = async (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      await getStates(value);
      await setSupplierData((prevState) => ({
        ...prevState,
        countryId: value,
        cityId: null,
      }));

      await setCityList([]);
    } else if (name === "state") {
      await getCities(value);
      await setSupplierData((prevState) => ({
        ...prevState,
        stateId: value,
        cityId: null,
      }));
    } else if (name === "city") {
      await setSupplierData((prevState) => ({
        ...prevState,
        cityId: value,
      }));
    } else {
      setSupplierData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Box>
      <Box className={classes.tabSideMenuContainer}>
        <Box className={classes.panelContainer}>
          <SidePanelMenu
            panelItems={panelItems}
            selectedPanelItem={selectedPanelItem}
            handlePanelItemChange={handlePanelItemChange}
          />
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <PageContent
          type={contentType}
          contentData={contentData}
          handleActionClick={handleActionClick}
        />
      </Box>
      <Modal
        title="Supplier Information"
        open={supplierProfileModal}
        disableEscapeKeyDown
        hideCloseIcon
        style={{ zIndex: 999 }}
        className={{ classRoot: classes.discardPop }}
      >
        <Box className={classes.title}>1. Supplier Information from WBA</Box>
        <Box>
          <Box className={classes.subtitle}>Company Name</Box>
          <TextInput
            placeholder="Company Name"
            name="name"
            value={supplierData.name || ""}
            additionalClass={classes.inputField}
            disabled
          />
        </Box>

        <Box>
          <Box className={classes.subtitle}>Company Code</Box>
          <TextInput
            placeholder="Company Code"
            name="supplierCode"
            value={supplierData.supplierCode || ""}
            additionalClass={classes.inputField}
            disabled
          />
        </Box>

        <Box>
          <Box className={classes.subtitle}>Supplier Address</Box>

          <TextInput
            placeholder="Address"
            name="address"
            value={supplierData.address || ""}
            additionalClass={classes.inputField}
            onChange={handleSupplierDataChange}
          />

          <Box className={classes.subtitle}>Area</Box>

          <Box display="flex">
            <Box>
              <Select
                label="Select Country"
                name="country"
                style={{ width: 188, marginRight: 20 }}
                value={supplierData.countryId ? supplierData.countryId : ""}
                options={countryList}
                handleChange={handleSupplierDataChange}
                additionalClass={classes.inputField}
                data-cy="select-country"
              />
            </Box>
            {!hideState && (
              <Box>
                <Select
                  label="Select State"
                  name="state"
                  style={{ width: 188, marginRight: 20 }}
                  value={supplierData.stateId ? supplierData.stateId : ""}
                  options={stateList}
                  handleChange={handleSupplierDataChange}
                  additionalClass={classes.inputField}
                  // disabled={!supplierData?.stateId}
                />
              </Box>
            )}

            <Box>
              <Select
                data-cy="select-city"
                label="Select City"
                name="city"
                style={{ width: 188, marginRight: 20 }}
                value={supplierData.cityId ? supplierData.cityId : ""}
                options={cityList}
                handleChange={handleSupplierDataChange}
                additionalClass={classes.inputField}
                // disabled={!supplierData.cityId}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.title} style={{ marginTop: 30 }}>
          2. Contact Information
        </Box>

        <Box>
          <Box className={classes.subtitle}>Contact Person</Box>

          <TextInput
            placeholder="Contact Person"
            name="contactPerson"
            value={supplierData.contactPerson || ""}
            additionalClass={classes.inputField}
            onChange={handleSupplierDataChange}
          />
        </Box>

        <Box>
          <Box className={classes.subtitle}>Contact Email</Box>

          <TextInput
            placeholder="Contact Email"
            name="contactEmail"
            value={supplierData.contactEmail || ""}
            additionalClass={classes.inputField}
            onChange={handleSupplierDataChange}
          />
        </Box>

        <Box>
          <Box className={classes.subtitle}>Contact Number</Box>

          <TextInput
            placeholder="Contact Number"
            name="contactNumber"
            value={supplierData.contactNumber || ""}
            additionalClass={classes.inputField}
            onChange={handleSupplierDataChange}
            error
          />
        </Box>

        <Box display="block" className={classes.fourBox} marginTop="40px">
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={handleSubmit}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SupplierHomePage;
