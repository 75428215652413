import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import ReactTable from "components/table/ReactTable";
import classTable from "./style.module.scss";

import POChildSeries from "./components/POChildSeries";
import CsvLink from "../Analytics/List/CSVLink";

import Status from "./components/Filter/Status";
import SearchFilter from "./components/Filter/SearchFilter";
import { STATUS, STATUS_ALL } from "./constants";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    padding: "0 0 70px",
  },
  wrapperBasicNoPadding: {
    position: "relative",
    maxWidth: "100%",
  },
  searchContent: {
    marginBottom: "10px",
    fontWeight: "500",

    "& span:not(:last-child)": {
      marginRight: "10px",
    },
  },
  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },
  allWicText: {
    fontWeight: "500",
    fontSize: 12,
    marginRight: 12,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 12,
    marginRight: 38,
  },
  exportText: {
    "& span": {
      fontWeight: "500",
      fontSize: 12,
      color: "#005EFF",
      cursor: "pointer",
    },
  },
  status: {
    textTransform: "capitalize",
  },
  noStatus: {
    textTransform: "capitalize",
    color: "#D42600",
  },
  yesStatus: {
    textTransform: "capitalize",
    color: "#1BB135",
  },
  blueCell: {
    color: "#003DA5",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "28px",
  },
}));

const POSeriesTable = ({
  listPODailySeries = [],
  listSearch = "",
  noPadding = false,
  noViewExport = false,
  cbSetDataShowTable = () => {},
}) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const filterOnly = { canSort: false, canFilter: true };

  // ============= GET FILTER OPTION LIST ====================
  const [listPODailyDone, setListPODailyDone] = useState(listPODailySeries);

  useEffect(() => {
    setListPODailyDone(listPODailySeries);
    getWbaQAList();
    getALlFilterList();
  }, [listPODailySeries]);

  const getWbaQAList = () => {
    let listWbaQA = listPODailySeries.map((item) => item.wbaResponsibleQA);
    setWBAList(
      listWbaQA
        .filter((item, index) => {
          return listWbaQA.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item ? item : "NA", key: item ? item : "NA" };
        }),
    );
  };

  const getALlFilterList = () => {
    let listSupplier = listPODailySeries.map((item) => item.supplierName);
    setSupplierList(
      listSupplier
        .filter((item, index) => {
          return listSupplier.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );

    let listFactory = listPODailySeries.map((item) => item.factoryName);
    setFactoryList(
      listFactory
        .filter((item, index) => {
          return listFactory.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );
  };

  const [wbaList, setWBAList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);

  // ============= END GET FILTER OPTION LIST ====================

  const [selectedWBAOption, setSelectedWBAOption] = useState([]);
  const cbSetFilterWBA = (data) => {
    setSelectedWBAOption(data);
  };

  const [selectedSupplierOption, setSelectedSupplierOption] = useState([]);
  const cbSetFilterSupplier = (data) => {
    setSelectedSupplierOption(data);
  };

  const [selectedFactoryOption, setSelectedFactoryOption] = useState([]);
  const cbSetFilterFactory = (data) => {
    setSelectedFactoryOption(data);
  };

  const [listPAC, setListPAC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterPAC = (data) => {
    setListPAC(data);
  };

  const [listGCC, setListGCC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterGCC = (data) => {
    setListGCC(data);
  };

  const [listQAA, setListQAA] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterQAA = (data) => {
    setListQAA(data);
  };

  const [listFRI, setListFRI] = useState(
    STATUS.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterFRI = (data) => {
    setListFRI(data);
  };

  const checkStatus = (status) => {
    if (status && status.toLowerCase() === "no") return classes.noStatus;
    if (status && status.toLowerCase() === "yes") return classes.yesStatus;
    else return classes.status;
  };

  const tableColumn = [
    {
      id: "poSeries",
      value: "poSeries",
      label: "PO# series",
      width: 721,
      ...sortOnly,
      render: ({ row, cell: { value: poSeries } }) => {
        return (
          <Box {...row.getToggleRowExpandedProps()} className={classes.blueCell}>
            {row.isExpanded ? poSeries : poSeries}{" "}
          </Box>
        );
      },
    },
    {
      id: "supplierName",
      value: ({ supplierName, id }) => {
        return { supplierName, id };
      },
      label: "Supplier Name",
      width: 199,
      ...filterOnly,
      render: ({
        cell: {
          value: { supplierName, id },
        },
      }) => {
        return <Box className={classes.normalCell}>{supplierName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterSupplier}
            list={supplierList}
            selectedValues={selectedSupplierOption}
          />
        );
      },
      selectedCheckBox: selectedSupplierOption.length > 0,
    },
    {
      id: "factoryName",
      value: "factoryName",
      label: "Factory Name",
      ...filterOnly,
      render: ({ cell: { value: factoryName } }) => {
        return <Box className={classes.normalCell}>{factoryName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterFactory}
            list={factoryList}
            selectedValues={selectedFactoryOption}
          />
        );
      },
      selectedCheckBox: selectedFactoryOption.length > 0,
    },
    {
      id: "poQuantity",
      value: "poQuantity",
      label: "PO Quantity",
      ...sortOnly,
      render: ({ cell: { value: poQuantity } }) => {
        return (
          <Box className={classes.normalCell}>
            {(Math.round((parseFloat(poQuantity) + Number.EPSILON) * 100) / 100).toLocaleString()}
          </Box>
        );
      },
    },
    {
      id: "shipByDate",
      value: "shipByDate",
      label: "Ship By Date",
      ...sortOnly,
      render: ({ cell: { value: shipByDate } }) => {
        return <Box className={classes.normalCell}>{shipByDate}</Box>;
      },
    },
    {
      id: "pacStatus",
      value: "pacStatus",
      label: "PAC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: pacStatus } }) => {
        return <Box className={checkStatus(pacStatus)}>{!pacStatus ? "NA" : pacStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterPAC} list={listPAC} />;
      },
      selectedCheckBox: listPAC.filter((child) => child.checked).length > 0,
    },
    {
      id: "gccStatus",
      value: "gccStatus",
      label: "GCC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: gccStatus } }) => {
        return <Box className={checkStatus(gccStatus)}>{!gccStatus ? "NA" : gccStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterGCC} list={listGCC} />;
      },
      selectedCheckBox: listGCC.filter((child) => child.checked).length > 0,
    },
    {
      id: "qaaStatus",
      value: "qaaStatus",
      label: "QAA Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: qaaStatus } }) => {
        return <Box className={checkStatus(qaaStatus)}>{!qaaStatus ? "NA" : qaaStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterQAA} list={listQAA} />;
      },
      selectedCheckBox: listQAA.filter((child) => child.checked).length > 0,
    },
    {
      id: "friStatus",
      value: "friStatus",
      label: "FRI Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: friStatus } }) => {
        return <Box className={checkStatus(friStatus)}>{!friStatus ? "NA" : friStatus}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterFRI} list={listFRI} />;
      },
      selectedCheckBox: listFRI.filter((child) => child.checked).length > 0,
    },
    {
      id: "wbaResponsibleQA",
      value: "wbaResponsibleQA",
      label: `${SIMPLE_BUYER_NAME} Responsible QA`,
      ...filterOnly,
      render: ({ cell: { value: wbaResponsibleQA } }) => {
        return (
          <Box className={classes.normalCell}>{!wbaResponsibleQA ? "NA" : wbaResponsibleQA}</Box>
        );
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterWBA}
            list={wbaList}
            selectedValues={selectedWBAOption}
          />
        );
      },
      selectedCheckBox: selectedWBAOption.length > 0,
    },
  ];

  // ============= HANDLE SEARCH ANG FILTER LIST ====================

  const [listKeySearch, setListKeySearch] = useState([]);

  useEffect(() => {
    setListKeySearch(listSearch.split(new RegExp("[,]", "g")).map((item, index) => item.trim()));
  }, [listSearch]);

  useEffect(() => {
    handleFilteredList();
  }, [
    listKeySearch,
    selectedWBAOption,
    selectedSupplierOption,
    selectedFactoryOption,
    listPAC,
    listGCC,
    listQAA,
    listFRI,
  ]);

  const handleFilteredList = () => {
    let result = listPODailySeries
      .filter((item) => {
        return listKeySearch.length === 1 && listKeySearch[0] === ""
          ? item
          : listKeySearch.filter((itemSearch) => item.poSeries.includes(itemSearch)).length > 0;
      })
      .filter((item) => {
        return selectedWBAOption.length === 0
          ? item
          : selectedWBAOption
              .map((child) => child.value)
              .indexOf(item.wbaResponsibleQA ? item.wbaResponsibleQA : "NA") > -1;
      })
      .filter((item) => {
        return selectedSupplierOption.length === 0
          ? item
          : selectedSupplierOption.map((child) => child.value).indexOf(item.supplierName) > -1;
      })
      .filter((item) => {
        return selectedFactoryOption.length === 0
          ? item
          : selectedFactoryOption.map((child) => child.value).indexOf(item.factoryName) > -1;
      })
      .filter((item) => {
        return listPAC.filter((child) => !child.checked).length === 3
          ? item
          : listPAC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.pacStatus ? "na" : item.pacStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listGCC.filter((child) => !child.checked).length === 3
          ? item
          : listGCC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.gccStatus ? "na" : item.gccStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listQAA.filter((child) => !child.checked).length === 3
          ? item
          : listQAA.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.qaaStatus ? "na" : item.qaaStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listFRI.filter((child) => !child.checked).length === 2
          ? item
          : listFRI.filter(
              (child) =>
                child.label.toLowerCase() === item.friStatus.toLowerCase() && child.checked,
            ).length > 0;
      });

    setListPODailyDone(result);
  };

  // ============= GET HANDLE SEARCH ANG FILTER LIST ====================

  // ============= HANDLE SUB COMPONENT ==================
  const getListPOChild = (id) => {
    let data = listPODailyDone.filter((item) => item.id === id);
    return (data[0] && data[0].poChilds) || [];
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <POChildSeries key={uuidv4()} listPODaily={getListPOChild(row.values.supplierName.id)} />
    ),
    [listPODailyDone],
  );

  // ============= END HANDLE SUB COMPONENT ==================

  const [pageSize, setPageSize] = useState(50);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  useEffect(() => {
    cbSetDataShowTable({ pageSize, newPage, listDone: listPODailyDone });
  }, [listPODailyDone]);

  // ==================== HANDLE EXPORT DATA ====================
  const headerExport = [
    { label: "PO# series", key: "poSeries" },
    { label: "Supplier Name", key: "supplierName" },
    { label: "Factory Name", key: "factoryName" },
    { label: "PO Quantity", key: "poQuantity" },
    { label: "Ship By Date", key: "shipByDate" },
    { label: "PAC Compliance Status", key: "pacStatus" },
    { label: "GCC Compliance Status", key: "gccStatus" },
    { label: "QAA Compliance Status", key: "qaaStatus" },
    { label: "FRI Compliance Status", key: "friStatus" },
    { label: `${SIMPLE_BUYER_NAME} Responsible QA`, key: "wbaResponsibleQA" },
  ];

  const dataExport = listPODailyDone.map((item, index) => {
    return {
      ...item,
      wbaResponsibleQA: !item.wbaResponsibleQA ? "NA" : item.wbaResponsibleQA,
      wicNumberNew: item.wicNumber + " / " + item.productName,
      programNameNew: item.programName + " / " + item.category,
      poQuantityNew: (
        Math.round((parseFloat(item.poQuantity) + Number.EPSILON) * 100) / 100
      ).toLocaleString(),
      pacStatus: !item.pacStatus ? "NA" : item.pacStatus,
      gccStatus: !item.gccStatus ? "NA" : item.gccStatus,
      qaaStatus: !item.qaaStatus ? "NA" : item.qaaStatus,
      friStatus: !item.friStatus ? "NA" : item.friStatus,
    };
  });
  // ==================== END HANDLE EXPORT DATA ====================

  return (
    <Box className={noPadding ? classes.wrapperBasicNoPadding : classes.wrapperBasic}>
      {!noViewExport && (
        <>
          {listSearch.length > 0 && (
            <Box className={classes.searchContent}>
              Viewing Searched Result of : "
              {listSearch.split(new RegExp("[,]", "g")).map((item, index) => (
                <span key={index}>{item.trim()}</span>
              ))}
              "
            </Box>
          )}

          <Box display="flex" alignItems="center" padding="18px 0">
            <Box display="block" className={classes.allWicText}>
              Viewing PO items
            </Box>
            <Box display="block" className={classes.allShowing}>{`Showing ${
              newPage * pageSize + 1
            } - ${
              newPage * pageSize + pageSize > listPODailyDone.length
                ? listPODailyDone.length
                : newPage * pageSize + pageSize
            } of ${listPODailyDone.length}`}</Box>
            <Box display="block" className={classes.exportText} onClick={() => {}}>
              <Typography className={classes.txtText}>
                <CsvLink
                  header={headerExport}
                  data={dataExport}
                  fileName="po_series_table.csv"
                  text={`Export All`}
                />
              </Typography>
            </Box>
          </Box>
        </>
      )}
      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          columns={tableColumn}
          data={listPODailyDone}
          hasExpanded={true}
          renderRowSubComponent={renderRowSubComponent}
          hasPagingTop={true}
          sizeOfPage={50}
          rowsPerPageOptions={[50, 100, 200, { label: "All", value: listPODailyDone.length }]}
          classes={{
            root: classTable.poSeriesTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default POSeriesTable;
