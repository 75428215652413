import React from "react";
import { connect } from "react-redux";

import SharedItemDetail from "pages/SharedItemDetail";
import { USER_TYPE } from "shared/constants";

const BuyerItemDetail = ({ match }) => {
  return <SharedItemDetail type={USER_TYPE.buyer} itemId={match.params.id} />;
};

export default connect(null, null)(BuyerItemDetail);
