import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import SelectTask from "../components/SelectTask";
import TaskDetail from "./TaskDetail";
import SelectLab from "../components/SelectLab";
import PayerDetail from "../../components/PayerDetail";

import { atnGetOpenTaskLabs } from "actions/taskActions";
import { atnEditSubmission } from "actions/submissionActions";
import { atnDeleteFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const EditSamplePickUp = ({
  history,
  submissionId,
  basicData = {},
  submissionTasks = [],
  relatedTasks = [],
  billingAddress = {},
  inspectionTaskDetail = {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [filteredRelatedTasks, setFilteredRelatedTasks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [originalSubmissionData, setOriginalSubmissionData] = useState({
    tasks: JSON.parse(JSON.stringify(submissionTasks)),
    inspectionTaskDetail: JSON.parse(JSON.stringify(inspectionTaskDetail)),
    masterWics: [],
    labIds: JSON.parse(JSON.stringify(basicData.labIds)),
    billingAddress: JSON.parse(JSON.stringify(billingAddress)),
  });
  const [editSubmissionData, setEditSubmissionData] = useState({
    tasks: submissionTasks,
    // inspectionTaskDetail: {
    //   expectedSamplePickUpDate: "",
    // },
    inspectionTaskDetail: inspectionTaskDetail,
    masterWics: [],
    labIds: basicData.labIds,
    isSkipQuote: false,
    billingAddress: billingAddress,
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);
  const [deletedPo, setDeletedPo] = useState([]);

  const [payerDetailErrors, setPayerDetailErrors] = useState([]);

  // for open task with tendering program
  // update handleGetLabs func with state
  const [tenderingMatchedLabs, setTenderingMatchedLabs] = useState([]);

  const titleData = [
    "Select Task(s) for Share Cost",
    "Enter Task Detail",
    "Select Lab for Quotation",
    "Enter Payer Detail for Invoice",
  ];

  const handleTenderingMatchedLab = (matchedTenderingLabs) => {
    setTenderingMatchedLabs(matchedTenderingLabs);
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = relatedTasks.filter(
        (item, i) =>
          item.wicNumber.indexOf(value) >= 0 ||
          item.taskNumber.indexOf(value) >= 0 ||
          item.productName.indexOf(value) >= 0,
      );

      setFilteredRelatedTasks(tempTasks);
    } else {
      setFilteredRelatedTasks(relatedTasks);
    }
  };

  const handleDeletePo = (po) => {
    setDeletedPo((prevState) => {
      return prevState.concat(po);
    });
  };

  const handleEditSubmissionDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setEditSubmissionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabs = (data) => {
    atnGetOpenTaskLabs(data).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setEditSubmissionData((prevState) => ({
        ...prevState,
        labIds: basicData.labIds,
      }));
    });
  };

  useEffect(() => {
    const stringifyTenderingLabs = !!tenderingMatchedLabs?.length
      ? tenderingMatchedLabs?.toString()
      : "";

    handleGetLabs({
      taskType: basicData.taskType,
      buyerProgramId: basicData.buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleConfirmTaskSelect = () => {
    let oldMasterWics = editSubmissionData.masterWics;

    if (oldMasterWics.length > 0) {
      let masterWicIds = []; // 新data所有 wic id
      let newMasterWicWithTasks = []; // 新data所有 wic with task

      for (const task of editSubmissionData.tasks) {
        if (masterWicIds.indexOf(task.masterWicId) === -1) {
          masterWicIds.push(task.masterWicId);
          newMasterWicWithTasks.push({
            masterWicId: task.masterWicId,
            wicNumber: task.wicNumber,
            productName: task.productName,
            tasks: [
              {
                taskType: task.taskType,
              },
            ],
            masterWicFiles: task.masterWicFiles,
            po: task.po || task.dailyPo,
            pb: task.pb || [],
            isProgram777: task.isProgram777 || false,
            triggeredBy: task.triggeredBy || "",
            spuForEverydayTaskType: task.spuForEverydayTaskType,
            buyerCompanyName: task.buyerCompanyName,
            category: task.category,
          });
        } else {
          for (const newMasterWicWithTask of newMasterWicWithTasks) {
            if (newMasterWicWithTask.masterWicId === task.masterWicId) {
              newMasterWicWithTask.tasks.push({
                taskType: task.taskType,
              });
              break;
            }
          }
        }
      }

      let newMasterWics = []; // 舊data已經有的master wic update相關task
      let newMasterWicIds = []; // 舊data已經有的master wic update相關task

      for (const oldMasterWic of oldMasterWics) {
        if (masterWicIds.indexOf(oldMasterWic.masterWicId) >= 0) {
          for (const newMasterWicWithTask of newMasterWicWithTasks) {
            if (newMasterWicWithTask.masterWicId === oldMasterWic.masterWicId) {
              oldMasterWic.tasks = newMasterWicWithTask.tasks;
              break;
            }
          }

          newMasterWics.push(oldMasterWic);
          newMasterWicIds.push(oldMasterWic.masterWicId);
        }
      }

      // 加新的master wic
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (const newMasterWicWithTask of newMasterWicWithTasks) {
        if (newMasterWicIds.indexOf(newMasterWicWithTask.masterWicId) === -1) {
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (const masterWicFile of newMasterWicWithTask.masterWicFiles) {
            const isMatchType = (type) => masterWicFile.type === type;

            const dataObj = {
              id: masterWicFile.id,
              name: masterWicFile.fileName,
            };

            if (isMatchType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMatchType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMatchType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMatchType("e2open")) {
              e2open.push(dataObj);
            }
          }

          newMasterWics.push({
            expanded: false,
            masterWicId: newMasterWicWithTask.masterWicId,
            wicNumber: newMasterWicWithTask.wicNumber,
            productName: newMasterWicWithTask.productName,
            tasks: newMasterWicWithTask.tasks,
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation: aisFactoryInformation,
            e2open: e2open,
            masterWicFiles: newMasterWicWithTask.masterWicFiles,
            po: newMasterWicWithTask.po,
            pb: newMasterWicWithTask.pb,
            isProgram777: newMasterWicWithTask.isProgram777 || false,
            triggeredBy: newMasterWicWithTask.triggeredBy || "",
            spuForEverydayTaskType: newMasterWicWithTask.spuForEverydayTaskType,
            buyerCompanyName: newMasterWicWithTask.buyerCompanyName,
            category: newMasterWicWithTask.category,
          });
        }
      }

      setOriginalSubmissionData((prevState) => ({
        ...prevState,
        masterWics: JSON.parse(JSON.stringify(newMasterWics)),
      }));

      setEditSubmissionData((prevState) => ({
        ...prevState,
        masterWics: newMasterWics,
      }));
    } else {
      let masterWicIds = [];
      let masterWics = [];
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (const editDataTask of editSubmissionData.tasks) {
        if (masterWicIds.indexOf(editDataTask.masterWicId) === -1) {
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (const editDataTaskFile of editDataTask.masterWicFiles) {
            const isMatchType = (type) => editDataTaskFile.type === type;

            const dataObj = {
              id: editDataTaskFile.id,
              name: editDataTaskFile.fileName,
            };

            if (isMatchType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMatchType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMatchType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMatchType("e2open")) {
              e2open.push(dataObj);
            }
          }

          masterWicIds.push(editDataTask.masterWicId);
          masterWics.push({
            expanded: false,
            masterWicId: editDataTask.masterWicId,
            wicNumber: editDataTask.wicNumber,
            productName: editDataTask.productName,
            tasks: [
              {
                taskType: editDataTask.taskType,
              },
            ],
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation,
            e2open,
            masterWicFiles: editDataTask.masterWicFiles,
            po: editDataTask.po || editDataTask.dailyPo,
            pb: editDataTask.pb || [],
            isProgram777: editDataTask.isProgram777 || false,
            triggeredBy: editDataTask.triggeredBy || "",
            spuForEverydayTaskType: editDataTask.spuForEverydayTaskType,
            buyerCompanyName: editDataTask.buyerCompanyName,
            category: editDataTask.category,
          });
        } else {
          for (let j = 0; j < masterWics.length; j++) {
            if (masterWics[j].masterWicId === editDataTask.masterWicId) {
              masterWics[j].tasks.push({
                taskType: editDataTask.taskType,
              });
            }
          }
        }
      }

      setOriginalSubmissionData((prevState) => ({
        ...prevState,
        masterWics: JSON.parse(JSON.stringify(masterWics)),
      }));

      setEditSubmissionData((prevState) => ({
        ...prevState,
        masterWics: masterWics,
      }));
    }

    setStep(step + 1);
  };

  const getSubmitTasks = () => {
    let tasks = editSubmissionData.tasks;

    for (const task of tasks) {
      for (const editMasterWic of editSubmissionData.masterWics) {
        const isMatchId = editMasterWic.masterWicId === task.masterWicId;

        if (isMatchId) {
          if (task.category === "everyday") {
            task.spuForEverydayTaskType = editMasterWic.spuForEverydayTaskType;
            task.dailyPo = editMasterWic.po;
            delete task.po;
          } else {
            task.po = editMasterWic.po;
            delete task.dailyPo;
          }

          //pb
          task.pb = editMasterWic.pb;

          break;
        }
      }
    }
    return tasks;
  };

  const handleSubmit = () => {
    setPayerDetailErrors([]);
    setIsDisabledConfirmButton(true);

    let deletedFiles = [];
    let billingAddress = editSubmissionData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (editSubmissionData.billingAddress.invoiceType === "icw_company_invoice") {
      billingAddress.attachments = [];
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType !== "icw_company_invoice") {
      let newFileIds = [];
      let tempTaxInformationFiles = [];

      for (let i = 0; i < editSubmissionData.billingAddress.attachments.length; i++) {
        newFileIds.push(editSubmissionData.billingAddress.attachments[i].id);

        tempTaxInformationFiles.push({
          id: editSubmissionData.billingAddress.attachments[i].id,
          type: "tax_information",
        });
      }

      // for (let i = 0; i < editSubmissionData.billingAddress.taxInformationFiles.length; i++) {
      //   if (newFileIds.indexOf(editSubmissionData.billingAddress.taxInformationFiles[i].id) === -1) {
      //     deletedFiles.push({
      //       fileId: editSubmissionData.billingAddress.taxInformationFiles[i].id
      //     });
      //   }
      // }

      billingAddress.taxInformationFiles = tempTaxInformationFiles;
      // delete billingAddress.attachments;
    }

    let selectedLabs = [];
    let newLabs = [];
    let existingLabs = [];

    for (const labId of editSubmissionData.labIds) {
      if (basicData.labIds.indexOf(labId) === -1) {
        selectedLabs.push({
          id: labId,
          isNew: true,
        });
        newLabs.push(labId);
      } else {
        selectedLabs.push({
          id: labId,
          isNew: false,
        });
        existingLabs.push(labId);
      }
    }

    let masterWics = [];
    let masterWicFiles = [];

    for (const editDataMasterWic of editSubmissionData.masterWics) {
      masterWicFiles = [];

      for (const upcInfo of editDataMasterWic.aisUPCInformation) {
        masterWicFiles.push({
          id: upcInfo.id,
          type: "ais_stibo_upc_information",
        });
      }

      for (const outerInner of editDataMasterWic.aisOuterInner) {
        masterWicFiles.push({
          id: outerInner.id,
          type: "ais_stibo_outer_inner",
        });
      }

      for (const factoryInfo of editDataMasterWic.aisFactoryInformation) {
        masterWicFiles.push({
          id: factoryInfo.id,
          type: "ais_stibo_factory_information",
        });
      }

      for (const e2o of editDataMasterWic.e2open) {
        masterWicFiles.push({
          id: e2o.id,
          type: "e2open",
        });
      }

      masterWics.push({
        id: editDataMasterWic.masterWicId,
        masterWicFiles,
      });
    }

    let newFileIds = [];

    for (const masterwic of masterWics) {
      for (const masterWicFile of masterwic.masterWicFiles) {
        newFileIds.push(masterWicFile.id);
      }
    }

    for (const editTask of editSubmissionData.tasks) {
      for (const masterWicFile of editTask.masterWicFiles) {
        if (
          masterWicFile.type === "ais_stibo_upc_information" ||
          masterWicFile.type === "ais_stibo_outer_inner" ||
          masterWicFile.type === "ais_stibo_factory_information"
        ) {
          if (newFileIds.indexOf(masterWicFile.id) === -1) {
            deletedFiles.push({
              fileId: masterWicFile.id,
              masterWicId: editTask.masterWicId,
            });
          }
        }
      }
    }

    let changes = [];

    if (editSubmissionData.tasks.length !== originalSubmissionData.tasks.length) {
      let oldTasks = "";
      let newTasks = "";

      for (let i = 0; i < originalSubmissionData.tasks.length; i++) {
        oldTasks +=
          originalSubmissionData.tasks[i].wicNumber +
          "(" +
          t(`task.${originalSubmissionData.tasks[i].taskType}`) +
          ")\n";
      }

      for (let i = 0; i < editSubmissionData.tasks.length; i++) {
        newTasks +=
          editSubmissionData.tasks[i].wicNumber +
          "(" +
          t(`task.${editSubmissionData.tasks[i].taskType}`) +
          ")\n";
      }

      changes.push({
        field: "Share Cost Tasks",
        action: "Update",
        before: oldTasks,
        after: newTasks,
      });
    }

    if (
      editSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate !==
      originalSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate
    ) {
      changes.push({
        field: "Expected Sample Pick Up Date",
        action: "Update",
        before: originalSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate,
        after: editSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate,
      });
    }

    let oldPoData = "";
    let newPoData = "";
    let isNewMasterWic = true;

    for (let i = 0; i < editSubmissionData.masterWics.length; i++) {
      oldPoData = "";
      newPoData = "";
      isNewMasterWic = true;

      for (let j = 0; j < originalSubmissionData.masterWics.length; j++) {
        if (
          originalSubmissionData.masterWics[j].masterWicId ===
          editSubmissionData.masterWics[i].masterWicId
        ) {
          isNewMasterWic = false;

          for (let k = 0; k < originalSubmissionData.masterWics[j].po.length; k++) {
            oldPoData += "PO Number: " + originalSubmissionData.masterWics[j].po[k].poNumber + "\n";
            oldPoData +=
              "PO Quantity: " + originalSubmissionData.masterWics[j].po[k].poQuantity + "\n";
            oldPoData +=
              "Qty Ready for inspection: " +
              originalSubmissionData.masterWics[j].po[k].quantityReadyForInspection;
          }

          for (let k = 0; k < editSubmissionData.masterWics[i].po.length; k++) {
            newPoData += "PO Number: " + editSubmissionData.masterWics[i].po[k].poNumber + "\n";
            newPoData += "PO Quantity: " + editSubmissionData.masterWics[i].po[k].poQuantity + "\n";
            newPoData +=
              "Qty Ready for inspection: " +
              editSubmissionData.masterWics[i].po[k].quantityReadyForInspection;
          }

          if (oldPoData !== newPoData) {
            changes.push({
              field: "PO",
              action: "Update",
              before: oldPoData,
              after: newPoData,
            });
          }

          break;
        }
      }

      if (isNewMasterWic) {
        for (let k = 0; k < editSubmissionData.masterWics[i].po.length; k++) {
          newPoData += "PO Number: " + editSubmissionData.masterWics[i].po[k].poNumber + "\n";
          newPoData += "PO Quantity: " + editSubmissionData.masterWics[i].po[k].poQuantity + "\n";
          newPoData +=
            "Qty Ready for inspection: " +
            editSubmissionData.masterWics[i].po[k].quantityReadyForInspection;
        }

        changes.push({
          field: "PO",
          action: "Update",
          before: oldPoData,
          after: newPoData,
        });
      }
    }

    const { id, expectedSamplePickUpDate } = editSubmissionData.inspectionTaskDetail;

    let submitData = {
      submissionId: submissionId,
      isSkipQuote: editSubmissionData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: getSubmitTasks(),
      masterWics: masterWics,
      inspectionTaskDetail: {
        id,
        expectedSamplePickUpDate,
      },
      deletedPo: deletedPo,
      newLabs: newLabs,
    };

    if (changes.length > 0) {
      submitData.changes = changes;
      submitData.existingLabs = existingLabs;
    }

    if (deletedFiles.length > 0) {
      let formatedDeletedFiles = [];

      for (const masterwic of masterWics) {
        formatedDeletedFiles.push({
          masterWicId: masterwic.id,
          files: [],
        });
      }

      for (const formatedDeletedFile of formatedDeletedFiles) {
        for (const deletedFile of deletedFiles) {
          if (deletedFile.masterWicId === formatedDeletedFile.masterWicId) {
            formatedDeletedFile.files.push({
              fileId: deletedFile.fileId,
            });
          }
        }
      }

      for (const formatedDeletedFile of formatedDeletedFiles) {
        if (formatedDeletedFile.files.length > 0) {
          atnDeleteFile({
            type: "masterWicFiles",
            typeId: formatedDeletedFile.masterWicId,
            data: formatedDeletedFile.files,
          });
        }
      }
    }

    atnEditSubmission(submitData).then((res) => {
      setIsDisabledConfirmButton(false);

      if (!res?.data) return setPayerDetailErrors([res?.message]);

      history.push(`/supplier/submissions/${res?.data.id}`);
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header step={step} handlePrevPage={handlePrevPage} handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <SelectTask
            editSubmissionData={editSubmissionData}
            relatedTasks={searchKeyword ? filteredRelatedTasks : relatedTasks}
            searchKeyword={searchKeyword}
            handleSearchKeywordChange={handleSearchKeywordChange}
            basicData={basicData}
            handleEditSubmissionDataChange={handleEditSubmissionDataChange}
            handleGetLabs={handleGetLabs}
            handleNextPage={handleConfirmTaskSelect}
            handleTenderingMatchedLab={handleTenderingMatchedLab}
          />
        )}

        {step === 1 && (
          <TaskDetail
            editSubmissionData={editSubmissionData}
            handleEditSubmissionDataChange={handleEditSubmissionDataChange}
            handleDeletePo={handleDeletePo}
            handleNextPage={handleConfirmTaskSelect}
          />
        )}

        {step === 2 && (
          <SelectLab
            editSubmissionData={editSubmissionData}
            labs={labs}
            basicData={basicData}
            handleEditSubmissionDataChange={handleEditSubmissionDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 3 && (
          <PayerDetail
            errors={payerDetailErrors}
            data={editSubmissionData}
            handleDataChange={handleEditSubmissionDataChange}
            handleSubmit={handleSubmit}
            isDisabledConfirmButton={isDisabledConfirmButton}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditSamplePickUp;
