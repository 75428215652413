import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import FileUpload from "components/input/FileUpload";
import { GUARANTEE_LETTER } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  gridItem: {
    padding: 10,
    border: "1px solid #777777",
    borderBottom: "none",
    fontSize: 10,
  },
  gridItemRight: {
    borderLeft: "none",
  },
  gridItemBottom: {
    borderBottom: "1px solid #777777",
  },
  text: {
    fontSize: 10,
  },

  blackText: {
    color: "#000000",
  },
}));

const GuaranteeLetters = ({ data = [], taskId, editable = true }) => {
  const classes = useStyles();

  const rows = Object.values(GUARANTEE_LETTER).map((obj) => ({
    title: obj.label,
    type: obj.value,
    files: data.filter((item) => item.type === obj.value),
  }));

  const Row = ({ title, type, files, lastRow }) => {
    const rowFiles = files.map((item) => ({
      id: item.id,
      fileName: item.fileName,
      fileUrl: item.location,
    }));

    return (
      <>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className={classnames(classes.gridItem, { [classes.gridItemBottom]: lastRow })}
        >
          <Typography className={classnames(classes.text, classes.blackText)}>{title}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className={classnames(classes.gridItem, classes.gridItemRight, {
            [classes.gridItemBottom]: lastRow,
          })}
        >
          <FileUpload
            data={rowFiles}
            config={{ type: "taskFiles", typeId: taskId, fileType: type }}
            editable={editable}
            multiple
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container className={classes.gridContainer}>
      {rows.map((row, index) => (
        <Row key={index} {...row} lastRow={index === rows.length - 1} />
      ))}
    </Grid>
  );
};

export default GuaranteeLetters;
