import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";

import ICWLogo from "assets/logo/icw_logo-white.svg";
import auth from "auth/authenticator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    minHeight: 40,
    height: 40,
    boxShadow: "none",
    backgroundColor: "rgb(65, 136, 245)",
    "& .MuiToolbar-regular": {
      minHeight: 40,
      height: 40,
    },
    zIndex: 2000,
  },
  logo: {
    paddingLeft: 6,
    paddingRight: 50,
  },
  menuItemContainer: {
    padding: "0px 50px",
    height: 40,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(55, 126, 235)",
    },
  },
  menuItemText: {
    paddingTop: 13,
    fontSize: 10,
    fontWeight: "normal",
  },
  selectedMenuItemContainer: {
    padding: "0px 50px",
    height: 40,
    cursor: "pointer",
    backgroundColor: "#003DA5",
    borderBottom: "3px solid #B0D400",
  },
  createProjectButton: {
    height: 40,
    fontWeight: "normal",
    fontSize: 10,
  },
  menuDivider: {
    height: 30,
    borderLeft: "1px solid #FFFFFF",
    marginLeft: 10,
    marginRight: 10,
  },
  profileIcon: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
  },
  profileIconText: {
    color: "rgb(55, 126, 235)",
    fontSize: 10,
    paddingTop: 5,
  },
  iconButtonContainer: {
    padding: "8px 12px",
  },
}));

const LabHeader = ({ history }) => {
  const classes = useStyles();

  const [openActionMenu, setOpenActionMenu] = useState(false);
  const prevOpen = useRef(openActionMenu);
  const anchorRef = useRef(null);

  const handleActionMenuToggle = () => {
    setOpenActionMenu((prevOpen) => !prevOpen);
  };

  const handleActionMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenActionMenu(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && openActionMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openActionMenu;
  }, [openActionMenu]);

  const [selectedItem, setSelectedItem] = useState("");

  const headerData = [
    {
      name: "home",
      label: "HOME",
      path: "/lab_admin/home",
    },
    // {
    //   name: 'overview',
    //   label: 'OVERVIEW',
    //   path: '/lab_admin/overview',
    // },
    {
      name: "submissions",
      label: "SUBMISSIONS",
      path: "/lab_admin/submissions",
    },
    // {
    //   name: 'activityLog',
    //   label: 'ACTIVITY LOG',
    //   path: '/lab_admin/activity_log',
    // },
  ];

  useEffect(() => {
    if (history.location.pathname === "/lab_admin/home") {
      setSelectedItem("home");
    } else if (history.location.pathname === "/lab_admin/submissions") {
      setSelectedItem("submissions");
    } else if (history.location.pathname === "/lab_admin/activity_log") {
      setSelectedItem("activityLog");
    }
  }, [history.location.pathname]);

  const handleMenuItemClick = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    auth.logOut();
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Link to="/lab/home" className={classes.logo}>
            <img src={ICWLogo} alt="logo" width="40" height="40" />
          </Link>
          <Box flexGrow={1} display="flex">
            {headerData.map((item) => (
              <Box
                key={item.name}
                display="flex"
                className={
                  item.name === selectedItem
                    ? classes.selectedMenuItemContainer
                    : classes.menuItemContainer
                }
                onClick={() => handleMenuItemClick(item.path)}
              >
                <Typography className={classes.menuItemText}>{item.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex">
            <IconButton color="inherit" className={classes.iconButtonContainer}>
              <NotificationsIcon />
            </IconButton>
          </Box>

          <Box display="flex">
            <IconButton
              color="inherit"
              className={classes.iconButtonContainer}
              ref={anchorRef}
              onClick={handleActionMenuToggle}
            >
              <Box className={classes.profileIcon}>
                <Box className={classes.profileIconText}>W</Box>
              </Box>
            </IconButton>
          </Box>

          <Popper
            className={classes.actionMenu}
            open={openActionMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="top-end"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleActionMenuClose}>
                    <MenuList autoFocusItem={openActionMenu}>
                      <MenuItem className={classes.actionMenuItem} onClick={handleLogout}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(LabHeader);
