import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import Select from "components/input/Select";
import DatePicker from "components/input/DatePicker";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

export default function BulkEditItemsModal({
  open = false,
  selectedCount = 0,
  bulkEditTaskData = {},
  taskTypeList = [],
  onBulkdEditChange,
  onBulkEdit,
  onClose,
}) {
  const classes = useStyles();

  const propertyOptions = [
    {
      value: "deadLine",
      label: `${SIMPLE_BUYER_NAME} Deadline`,
    },
    {
      value: "shipByDate",
      label: "Ship By Date (Seasonal only)",
    },
    {
      value: "pacValidityDate",
      label: "PAC Validity Date (Everyday only)",
    },
    {
      value: "validityDate",
      label: "GCC/QAA Validity Date (Everyday only)",
    },
    {
      value: "selfInspectionValidityDate",
      label: "Inspection - Self Validity Date (Everyday only)",
    },
  ];

  const validityOptions = [
    {
      value: "validityDate",
      label: "Validity Date",
    },
    {
      value: "validityPeriod",
      label: "Validity Period",
    },
  ];

  const validityPeriodOptions = [
    {
      value: 6,
      label: "6 Months",
    },
    {
      value: 9,
      label: "9 Months",
    },
    {
      value: 12,
      label: "12 Months",
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} data-cy="dialog">
      <MuiDialogTitle onClose={onClose} className={classes.dialogTitle}>
        Bulk Edit Items
      </MuiDialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        <Box className={classes.dialogInfo}>
          Editing {selectedCount} items with the following criteria:
        </Box>
        <Box className={classes.dialogInfo}>All Ongoing Items</Box>

        <Box className={classes.dialogSubtitle}>Edit Properties</Box>

        <Box className={classes.dialogSelectText}>Property</Box>

        <Box>
          <Select
            label="Please choose property"
            name="property"
            value={bulkEditTaskData.property}
            options={propertyOptions}
            handleChange={onBulkdEditChange}
            additionalClass={classes.dialogSelect}
            cy="property-select"
          />
        </Box>

        {bulkEditTaskData.property === "deadLine" && (
          <Box display="flex" className={classes.dialogEditContainer}>
            <Box className={classes.dialogEditSection}>
              <Box className={classes.dialogSelectText}>Task Type</Box>
              <Box>
                <Select
                  label="Please choose task type"
                  name="taskType"
                  value={bulkEditTaskData.taskType}
                  options={taskTypeList}
                  handleChange={onBulkdEditChange}
                  additionalClass={classes.dialogSelect}
                  cy="edit-deadLine-select"
                />
              </Box>
            </Box>

            <Box className={classes.dialogEditSection}>
              <Box className={classes.dialogSelectText}>{SIMPLE_BUYER_NAME} Deadline</Box>
              <Box>
                <DatePicker
                  name="newDate"
                  value={bulkEditTaskData.newDate}
                  onChange={onBulkdEditChange}
                  type="date"
                  className={classes.datePicker}
                  cy="edit-deadline-date"
                />
              </Box>
            </Box>
          </Box>
        )}

        {bulkEditTaskData.property === "shipByDate" && (
          <Box className={classes.dialogEditContainer}>
            <Box className={classes.dialogSelectText}>New Date</Box>
            <Box>
              <DatePicker
                name="newDate"
                value={bulkEditTaskData.newDate}
                onChange={onBulkdEditChange}
                type="date"
                className={classes.datePicker}
                cy="edit-shipByDate-date"
              />
            </Box>
          </Box>
        )}

        {bulkEditTaskData.property === "pacValidityDate" && (
          <Box className={classes.dialogEditContainer}>
            <Box className={classes.dialogSelectText}>New Date</Box>
            <Box>
              <DatePicker
                name="newDate"
                value={bulkEditTaskData.newDate}
                onChange={onBulkdEditChange}
                type="date"
                className={classes.datePicker}
                cy="edit-pacValidity-date"
              />
            </Box>
          </Box>
        )}

        {bulkEditTaskData.property === "selfInspectionValidityDate" && (
          <Box className={classes.dialogEditContainer}>
            <Box className={classes.dialogSelectText}>New Date</Box>
            <Box>
              <DatePicker
                name="newDate"
                value={bulkEditTaskData.newDate}
                onChange={onBulkdEditChange}
                type="date"
                className={classes.datePicker}
                cy="edit-selfInspectionValidity-date"
              />
            </Box>
          </Box>
        )}

        {bulkEditTaskData.property === "validityDate" && (
          <Box className={classes.dialogEditContainer}>
            <Box>
              <Box className={classes.dialogSelectText}>Date or Period</Box>
              <Box>
                <Select
                  label="Select Date or Period"
                  name="validityType"
                  value={bulkEditTaskData.validityType}
                  options={validityOptions}
                  handleChange={onBulkdEditChange}
                  additionalClass={classes.dialogSelect}
                  cy="edit-validityDate-select"
                />
              </Box>
            </Box>

            {bulkEditTaskData.validityType === "validityDate" && (
              <Box mt={1}>
                <Box className={classes.dialogSelectText}>New Date</Box>
                <Box>
                  <DatePicker
                    name="newDate"
                    value={bulkEditTaskData.newDate}
                    onChange={onBulkdEditChange}
                    type="date"
                    className={classes.datePicker}
                    cy="edit-validityDate-date"
                  />
                </Box>
              </Box>
            )}

            {bulkEditTaskData.validityType === "validityPeriod" && (
              <Box mt={1}>
                <Box className={classes.dialogSelectText}>New Validity Period</Box>
                <Box>
                  <Select
                    label="Select Period"
                    name="validityPeriod"
                    value={bulkEditTaskData.validityPeriod}
                    options={validityPeriodOptions}
                    handleChange={onBulkdEditChange}
                    additionalClass={classes.dialogSelect}
                    cy="edit-validityDate-period"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </MuiDialogContent>
      <MuiDialogActions>
        <Button onClick={onClose} color="primary" data-cy="dialog-cancel">
          CANCEL
        </Button>

        <Button
          onClick={onBulkEdit}
          color="primary"
          variant="contained"
          className={classes.dialogConfirmButton}
          disabled={
            !bulkEditTaskData.property ||
            (!bulkEditTaskData.newDate && !bulkEditTaskData.validityPeriod)
          }
          data-cy="dialog-confirm"
        >
          CONFIRM EDIT
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  topContainer: {
    height: 80,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #AAAAAA",
  },
  contentContainer: {
    backgroundColor: "#F7F9FF",
    padding: 30,
    overflowX: "auto",
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 10,
  },
  tableInfo: {
    fontWeight: "normal",
    fontSize: 10,
    paddingLeft: 10,
  },
  exportAll: {
    fontWeight: 500,
    fontSize: 10,
    paddingLeft: 20,
    cursor: "pointer",
    color: "#005EFF",
  },
  tableContainer: {
    paddingTop: 10,
    width: "fit-content",
  },
  manageColumnDialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 850,
        width: 850,
        minHeight: 500,
      },
    },
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 800,
        width: 800,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
      padding: "4px 6px",
    },
  },
  dialogMessage: {
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  },
  dialogContent: {
    padding: "10px 30px",
    borderBottom: "none",
  },
  dialogInfo: {
    fontSize: 10,
  },
  dialogSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 30,
  },
  dialogSelectText: {
    fontSize: 12,
    fontWeight: "normal",
    paddingTop: 5,
  },
  dialogSelect: {
    // paddingTop: 6,
    // fontSize: 12,
    // fontWeight: 'normal',
    minWidth: 300,
  },
  dialogEditContainer: {
    borderTop: "1px solid #AAAAAA",
    marginTop: "36px",
    paddingTop: "16px",
    minHeight: 450,
  },
  dialogEditSection: {
    width: 400,
  },
  dialogConfirmButton: {
    borderRadius: 2,
    height: 36,
  },
  datePicker: {
    // paddingTop: 5,
    "& .MuiInputBase-root": {
      "& input": {
        padding: 11,
      },
    },
  },
  imageGalleryContainer: {
    margin: "100px 100px 0 100px",
  },
  manageColumnContainer: {
    paddingTop: 30,
  },
  manageColumnBoxTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 8,
  },
  manageColumnBox: {
    width: 320,
    border: "1px solid #000000",
    borderRadius: 4,
  },
  addTaskTypeButton: {
    marginTop: 8,
  },
  manageColumnButton: {
    cursor: "pointer",
    fontSize: 12,
  },
  manageColumnMenu: {
    zIndex: 999,
  },
  manageColumnMenuList: {
    width: 280,
  },
  manageColumnMenuItemText: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  manageColumnMenuItem: {
    position: "relative",
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
  },
  manageColumnMenuItemWithBorder: {
    color: "#232B3A",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
  },
  manageColumnMenuItemEditButton: {
    position: "absolute",
    right: 30,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnMenuItemDeleteButton: {
    position: "absolute",
    right: 10,
    color: "#777777",
    width: 15,
    height: 15,
  },
  manageColumnDialogButtonContainer: {
    paddingTop: 50,
  },
  handledByInput: {
    width: 300,
  },
  currentHeaderLabel: {
    paddingRight: 3,
    color: "#A5CE2D",
  },
}));
