import authApi from "../api/authApi";
import history from "../helpers/history";

export function atnLogin(loginData) {
  return authApi.login(loginData);
}

export function atnMe() {
  return authApi.me();
}

export function atnSendResetPasswordEmail(data) {
  return authApi.sendResetPasswordEmail(data);
}

export function atnResetPasswordWithToken(data) {
  return authApi.resetPasswordWithToken(data);
}

export function atnCheckToken(token) {
  return authApi.checkToken(token).then((result) => {
    if (result.status === 200) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("role", result.data.role);

      setTimeout(() => {
        history.push(`/${result.data.role}/home`);
      }, 1000);
    } else {
      // dispatch(loginFail(loginResult));
    }
  });
}
