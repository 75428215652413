import React from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Box from "@material-ui/core/Box";
import Program777Chip from "components/display/Program777Chip";

import { isShowProgram777 } from "services/pbService";
import TenderingChip from "components/display/TenderingChip";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    // padding: "26px 30px",
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 50,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
    textTransform: "capitalize",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  checkboxCell: {
    width: 25,
  },
  productCell: {
    width: 160,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  programCell: {
    width: 108,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  taskTypeCell: {
    width: 136,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  factoryCell: {
    width: 300,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  deadlineCell: {
    width: 78,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
}));

const SelectTasksTable = ({ tasks = [], selectedTaskIds = [], handleTaskSelect }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} />
            <TableCell className={classes.tableHeaderCell}>Product Name</TableCell>
            <TableCell className={classes.tableHeaderCell}>Program / Category</TableCell>
            <TableCell className={classes.tableHeaderCell}>Task Type</TableCell>
            <TableCell className={classes.tableHeaderCell}>Factory</TableCell>
            <TableCell className={classes.tableHeaderCell}>{SIMPLE_BUYER_NAME} Deadline</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tasks.map((task) => {
            // const isProgram777 = task?.isProgram777;
            const triggeredBy = task?.triggeredBy;

            const showProgram777 = isShowProgram777(triggeredBy);

            return (
              <TableRow
                key={task.id}
                className={classes.tableRow}
                onClick={() => handleTaskSelect(task)}
              >
                <TableCell scope="row" align="center" className={classes.tableCell}>
                  {selectedTaskIds.indexOf(task.id) >= 0 ? (
                    <CheckBoxIcon
                      className={classnames(classes.checkBoxIcon, classes.checkboxCell)}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      className={classnames(classes.checkBoxOutlineBlankIcon, classes.checkboxCell)}
                    />
                  )}
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box display="flex" className={classes.productCell}>
                    {showProgram777 && <Program777Chip />}
                    {task.wicNumber}
                  </Box>

                  <Box className={classnames(classes.info, classes.productCell)}>
                    {task.productName}
                  </Box>
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  {/* <Box className={classes.tableText}>{task.programName}{" "}{task.programYear}</Box> */}
                  <Box className={classes.programCell}>
                    {task?.isTenderingProgram && <TenderingChip detail={task?.tenderingProgram} />}

                    {task.programName}
                  </Box>

                  <Box className={classnames(classes.buyer, classes.programCell)}>
                    {task.buyerCompanyName}
                  </Box>
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.taskTypeCell}>{t(`task.${task.taskType}`)}</Box>
                  <Box className={classnames(classes.info, classes.taskTypeCell)}>
                    {task.taskNumber}
                  </Box>
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.factoryCell}>{task.factoryName || "N/A"}</Box>
                  <Box className={classnames(classes.info, classes.factoryCell)}>
                    {task.factoryAddress || "N/A"}
                  </Box>
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.deadlineCell}>
                    {task.deadLine ? moment(task.deadLine).format("DD/MMM/YYYY") : "N/A"}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      /> */}
    </TableContainer>
  );
};

export default SelectTasksTable;
