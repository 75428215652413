import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Select from "components/input/Select";
import MultipleSelect from "components/input/MultipleSelect";
import DatePicker from "components/input/DatePicker";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    maxWidth: 600,
    width: "100%",
  },
  inputFormControl: {
    width: "100%",
  },

  programYear: {
    width: 150,
  },
  blockPadding: {
    paddingRight: 10,
  },
  fieldPadding: {
    paddingTop: 32,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const ItemDetails = ({
  itemId,
  data: {
    itemData = {},
    buyerCompanyTeams = [],
    programs = [],
    programYears = [],
    exportMarkets = [],
    brands = [],
    isEverydayProgram = false,
  },
  func: {
    handleCreateItemDataChange,
    handleShipByDateChange,
    handleExportMarketsChange,
    handleNextPage,
  },
}) => {
  const classes = useStyles();
  const usermeail = localStorage.getItem("useremail");

  const disabledSubmitCondition = () => {
    const condition =
      !itemData.buyerTeam ||
      !itemData.program ||
      !itemData.programYear ||
      itemData.exportMarkets.length === 0 ||
      !itemData.brand;

    return !isEverydayProgram ? condition || !itemData.shipByDates[0].shipByDate : condition;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Container>
            <Paper className={classes.container}>
              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={8}>
                  <Box className={classes.title}>1. Program</Box>

                  <Box className={classes.subtitle}>Category</Box>

                  <Select
                    label="Buyer Team (Select One)"
                    name="buyerTeam"
                    value={itemData.buyerTeam}
                    options={buyerCompanyTeams}
                    handleChange={handleCreateItemDataChange}
                    additionalClass={classes.inputField}
                    formControlClass={classes.inputFormControl}
                    disabled={
                      usermeail !== "harry.lie@wba.hk" && usermeail !== "buyer@icw.io" && itemId
                    }
                    cy={"ci-team"}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={8}>
                  <Box className={classes.subtitle}>Program</Box>

                  <Select
                    label="Program / Season (Select One)"
                    name="program"
                    value={itemData.program}
                    options={programs}
                    handleChange={handleCreateItemDataChange}
                    additionalClass={classes.inputField}
                    formControlClass={classes.inputFormControl}
                    disabled={
                      !itemData.buyerTeam ||
                      (usermeail !== "harry.lie@wba.hk" && usermeail !== "buyer@icw.io" && itemId)
                    }
                    cy={"ci-program"}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <Box className={classes.subtitle}>Program Year</Box>
                    <Box>
                      <Select
                        label="Program Year"
                        name="programYear"
                        value={itemData.programYear}
                        options={programYears}
                        handleChange={handleCreateItemDataChange}
                        additionalClass={classes.inputField}
                        formControlClass={classes.inputFormControl}
                        disabled={!itemData.program}
                        cy={"ci-program-year"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {!isEverydayProgram && (
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Box className={classnames(classes.title, classes.fieldPadding)}>
                      2. Ship by Date
                    </Box>

                    <Box className={classes.subtitle}>Ship by Date</Box>

                    {itemData?.shipByDates?.map((item, index) => (
                      <DatePicker
                        key={index}
                        name="shipByDate"
                        value={item.shipByDate}
                        handleChange={(e) => handleShipByDateChange(e, index)}
                        additionalClass={classes.inputField}
                        cy={"ci-ship-by-date"}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={8}>
                  <Box className={classnames(classes.title, classes.fieldPadding)}>{`${
                    isEverydayProgram ? "2" : "3"
                  }. Export Market`}</Box>
                  <Box className={classes.subtitle}>Export Market(s)</Box>

                  <MultipleSelect
                    label="Select Export Market(s)"
                    name="exportMarkets"
                    value={itemData.exportMarkets}
                    options={exportMarkets}
                    handleChange={handleExportMarketsChange}
                    additionalClass={classes.inputField}
                    formControlClass={classes.inputFormControl}
                    cy={"ci-export-market"}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box className={classnames(classes.title, classes.fieldPadding)}>{`${
                    isEverydayProgram ? "3" : "4"
                  }. Brand`}</Box>
                  <Box className={classes.subtitle}>Brand</Box>
                  <Select
                    label="Brand (Select One)"
                    name="brand"
                    value={itemData.brand}
                    options={brands}
                    handleChange={handleCreateItemDataChange}
                    additionalClass={classes.inputField}
                    formControlClass={classes.inputFormControl}
                    disabled={
                      usermeail !== "harry.lie@wba.hk" && usermeail !== "buyer@icw.io" && itemId
                    }
                    cy={"ci-brand"}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Button
              variant="contained"
              color="primary"
              className={classes.continueBtn}
              onClick={handleNextPage}
              disabled={disabledSubmitCondition()}
              data-cy={"ci-continue"}
            >
              CONTINUE
            </Button>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemDetails;
