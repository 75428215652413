import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import DatePicker from "components/input/DatePicker";

import WicDetails from "./components/WicDetails";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 300,
  },
  smallInputField: {
    width: 200,
  },
  largeInputField: {
    width: 450,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  smallFieldPadding: {
    paddingTop: 20,
  },
  blockPadding: {
    paddingRight: 10,
  },
  inputPadding: {
    paddingTop: 29,
  },
  WICSectionContainer: {
    paddingTop: 20,
    // display: "flex",
    position: "relative",
  },
  expandAll: {
    cursor: "pointer",
    color: "#005EFF",
    paddingTop: 50,
    fontSize: 10,
  },
  WICContainer: {
    border: "1px solid #777777",
    width: 1140,
    padding: 20,
    position: "relative",
    marginBottom: 20,
  },
  WICTitleContainer: {
    position: "relative",
    height: 40,
  },
  WICTitle: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: 16,
    fontWeight: 500,
  },
  itemName: {
    position: "absolute",
    top: 22,
    left: 0,
    fontSize: 12,
    color: "#777777",
  },
  expandBtn: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  tasksContainer: {
    position: "absolute",
    top: 32,
    right: 70,
  },
  task: {
    background: "#EBEDEF",
    borderRadius: 2,
    fontSize: 12,
    color: "#232B3A",
    padding: "3px 6px",
    marginRight: 10,
  },
  poDataContainer: {
    paddingBottom: 20,
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const TaskDetail = ({
  editSubmissionData = {},
  handleEditSubmissionDataChange,
  handleDeletePo,
  handleNextPage,
}) => {
  const classes = useStyles();

  const handleTaskDetailChange = (e) => {
    const { name, value } = e.target;

    let inspectionTaskDetail = editSubmissionData.inspectionTaskDetail;

    inspectionTaskDetail[name] = value;

    handleEditSubmissionDataChange({
      target: {
        name: "inspectionTaskDetail",
        value: inspectionTaskDetail,
      },
    });
  };

  const handleExpandAll = () => {
    let masterWics = editSubmissionData.masterWics;

    for (let i = 0; i < masterWics.length; i++) {
      masterWics[i].expanded = true;
    }

    handleEditSubmissionDataChange({
      target: {
        name: "masterWics",
        value: masterWics,
      },
    });
  };

  const isAllFilled = () => {
    let hasMissingData = false;

    for (const taskData of editSubmissionData.masterWics) {
      const showProgram777 = isShowProgram777(taskData?.triggeredBy);

      if (taskData.aisUPCInformation.length === 0 || taskData.aisOuterInner.length === 0) {
        hasMissingData = true;
        break;
      }

      if (
        taskData.category === "everyday" &&
        !(taskData.spuForEverydayTaskType && taskData.spuForEverydayTaskType !== "")
      ) {
        hasMissingData = true;
        break;
      }

      //po
      if (!showProgram777) {
        if (!taskData.po[0]) {
          hasMissingData = true;
          break;
        }

        if (
          (!taskData.po[0].poNumber && taskData.po[0].poNumber !== 0) ||
          (!taskData.po[0].poQuantity && taskData.po[0].poQuantity !== 0) ||
          (!taskData.po[0].quantityReadyForInspection &&
            taskData.po[0].quantityReadyForInspection !== 0)
        ) {
          hasMissingData = true;
          break;
        }
      }

      //pb
      if (showProgram777) {
        if (taskData?.e2open?.length === 0) {
          hasMissingData = true;
          break;
        }

        if (
          taskData.pb.length === 0 ||
          !taskData.pb[0]?.pbNumber ||
          !taskData.pb[0]?.pbQuantity ||
          !taskData.pb[0]?.casePack
        ) {
          hasMissingData = true;
          break;
        }
      }
    }

    return hasMissingData || !editSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate
      ? false
      : true;
  };

  const handleClickNextPage = () => {
    return isAllFilled() ? handleNextPage() : alert("please input necessary data and upload file");
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>1. Expected Sample Pick Up Date</Box>

        <Box className={classes.subtitle}>Expected Sample Pick Up Date</Box>
        <Box>
          <DatePicker
            name="expectedSamplePickUpDate"
            value={editSubmissionData.inspectionTaskDetail.expectedSamplePickUpDate || ""}
            handleChange={handleTaskDetailChange}
            additionalClass={classes.smallInputField}
          />
        </Box>

        <Box display="flex">
          <Box flexGrow={1} className={classnames(classes.title, classes.fieldPadding)}>
            2. Task Information (Per WIC Item)
          </Box>

          <Box className={classes.expandAll} onClick={handleExpandAll}>
            expand all
          </Box>
        </Box>

        <Box className={classes.WICSectionContainer}>
          {editSubmissionData.masterWics.map((masterWic, index) => (
            <WicDetails
              key={masterWic.masterWicId}
              task={editSubmissionData.tasks[index]}
              masterWic={masterWic}
              masterWicIndex={index}
              editSubmissionData={editSubmissionData}
              handleEditSubmissionDataChange={handleEditSubmissionDataChange}
              handleDeletePo={handleDeletePo}
            />
          ))}
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleClickNextPage}
        disabled={!isAllFilled()}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default TaskDetail;
