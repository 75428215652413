import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import bgImage from "assets/bg-image/Frame_2.jpg";
import wbaLogo from "assets/logo/Full color logo - transparent background (For screen).png";
import icwLogo from "assets/logo/icw_full-logo.png";

import { atnLogin } from "actions/authActions";
import auth from "auth/authenticator";
import TextInput from "components/input/TextInput";

const buyerName = process.env.REACT_APP_BUYERNAME;

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    height: "calc(100vh - 40px)",
  },
  card: {
    marginTop: 105,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 6,
  },
  inputField: {
    width: "100%",
  },
  fieldPadding: {
    paddingTop: 20,
  },
  continueBtn: {
    marginTop: 20,
    width: "100%",
  },
  forgotPassword: {
    paddingTop: 15,
    cursor: "pointer",
    textAlign: "center",
    fontWeight: 500,
    color: "#005EFF",
  },
  checkboxContainer: {
    paddingTop: 15,
  },
  iconContainer: {
    padding: "10px 0 20px 0",
    textAlign: "center",
  },
  icon: {
    width: 300,
  },
}));

const Login = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (auth.loggedIn()) {
      history.push(`/${auth.loginRole()}/home`);
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckBoxChange = () => {
    setChecked(!checked);
  };

  const handleEnterLogin = (e) => {
    if (formData.email && formData.password && checked && e.key === "Enter") {
      login();
    }
  };

  const login = () => {
    atnLogin(formData).then((result) => {
      if (result) {
        localStorage.setItem("token", result.authorization);
        localStorage.setItem("companyName", result.companyname);
        localStorage.setItem("role", result.role);
        localStorage.setItem("useremail", result.useremail);
        localStorage.setItem("buyerteam", result.buyerteam || "all");

        let checkTokenTimes = 0;

        const checkToken = setInterval(() => {
          checkTokenTimes += 1;

          if (checkTokenTimes > 20) {
            clearInterval(checkToken);
            alert("please login again");
          }

          if (
            localStorage.getItem("token") &&
            localStorage.getItem("role") &&
            localStorage.getItem("buyerteam")
          ) {
            clearInterval(checkToken);

            if (
              history.location &&
              history.location.state &&
              history.location.state.from &&
              history.location.state.from.pathname
            ) {
              history.push(history.location.state.from.pathname);
            } else {
              history.push(`/${result.role}/home`);
            }
          }
        }, 200);
      } else {
        return alert("invalid username or password");
      }
    });
  };

  return (
    <div className={classes.container}>
      <Container style={{ maxWidth: 475 }}>
        <Box className={classes.card}>
          <Box className={classes.iconContainer}>
            {buyerName !== "Buyer" && <img className={classes.icon} src={wbaLogo} alt="logo" />}
          </Box>

          <Box
            className={classes.title}
            style={{
              textAlign: "center",
              fontSize: 17,
              marginBottom: 15,
              color: "rgb(115,184,241)",
            }}
          >
            Supplier and Product Compliance Management
          </Box>
          {/* <Box>buyer: buyer@test.com</Box>
        <Box>supplier: supplier@test.com</Box>
        <Box>lab: ulhk@test.com / ulsh@test.com / bvhk@test.com / ulss@test.com / bvs@test.com</Box>
        <Box>lab admin: uladmin@test.com</Box>
        <Box>password: 12345678</Box> */}

          {/* <Box>buyer: harry.lie@wba.hk</Box>
        <Box>supplier: shansuenrainnie1@126.com</Box>
        <Box>lab: moon.zhang@ul.com</Box>

        <hr></hr> */}

          <Box className={classes.title}>Username</Box>
          <TextInput
            placeholder={t("login.username")}
            name="email"
            value={formData.email}
            additionalClass={classes.inputField}
            onChange={handleChange}
            onKeyUp={handleEnterLogin}
            cy="login-email"
          />

          <Box className={classnames(classes.title, classes.fieldPadding)}>Password</Box>
          <TextInput
            placeholder={t("login.password")}
            name="password"
            type="password"
            value={formData.password}
            additionalClass={classes.inputField}
            onChange={handleChange}
            onKeyUp={handleEnterLogin}
            cy="login-password"
          />

          <Box className={classes.checkboxContainer} style={{ fontSize: 10 }}>
            <Checkbox
              checked={checked}
              value="checked"
              style={{ padding: "9px 9px 9px 0" }}
              onChange={() => handleCheckBoxChange()}
              name="checked"
              color="primary"
              data-cy={"login-checked"}
            />{" "}
            I agree to the{" "}
            <a
              href="https://www.icw.io/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Conditions
            </a>
            ,{" "}
            <a
              href="https://www.icw.io/privacy-policy-enterprise/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://www.icw.io/cookie-policy/" target="_blank" rel="noopener noreferrer">
              Cookie Policy
            </a>
            .
          </Box>

          <Button
            variant="contained"
            color="primary"
            className={classes.continueBtn}
            onClick={login}
            disabled={!formData.email || !formData.password || !checked}
            data-cy={"login-submit"}
          >
            {t("login.login")}
          </Button>

          <Box className={classes.forgotPassword} onClick={() => history.push("/resetPassword")}>
            Forgot Password?
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box p={1}>Powered by</Box>
          <Box p={1}>
            <img src={icwLogo} alt="" style={{ width: 70 }} />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default connect(null, { atnLogin })(Login);
