import { BUYER, BUYER_SOURCING, SUPPLIER, USER_TYPE } from "shared/constants";

class Auth {
  static loggedIn() {
    // return !!localStorage.token;
    return localStorage.token && localStorage.role;
  }

  static loginRole() {
    return localStorage.role;
  }

  static loginBuyerTeam() {
    return localStorage.buyerteam;
  }

  static logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("buyerteam");
    localStorage.removeItem("useremail");
    localStorage.removeItem("companyName");
  }

  static userType() {
    let role;
    switch (localStorage.role) {
      case BUYER:
        role = USER_TYPE.buyer;
        break;
      case BUYER_SOURCING:
        role = USER_TYPE.buyer_sourcing;
        break;
      case SUPPLIER:
        role = USER_TYPE.supplier;
        break;
      default:
        role = USER_TYPE.lab;
        break;
    }
    return role;
  }
}

export default Auth;
