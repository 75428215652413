import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  tableContainer: {
    padding: "26px 30px",
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    paddingBottom: 26,
  },
  infoMessage: {
    fontSize: 10,
    color: "#777777",
    paddingLeft: 10,
    paddingBottom: 10,
  },
  tableHeaderCell: {
    padding: "8px 10px 8px 0px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
  },
  tableCell: {
    padding: "10px 10px 10px 0px",
    fontSize: 12,
  },
  number: {
    cursor: "pointer",
    color: "#005EFF",
    textDecoration: "underline",
    fontSize: 12,
  },
}));

const ClickableNumericTable = ({
  tableTitle = "",
  infoMessage = "",
  headerData = [],
  rowData = [],
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      {tableTitle && <Typography className={classes.title}>{tableTitle}</Typography>}
      {infoMessage && <Typography className={classes.infoMessage}>{infoMessage}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            {headerData.map((item) => (
              <TableCell key={item.name} align="left" className={classes.tableHeaderCell}>
                {item.label}{" "}
                {item.hasNumber && (
                  <>
                    (
                    <Typography
                      component="span"
                      className={classes.number}
                      onClick={() => handleClick(item.searchConditions)}
                    >
                      {item.number}
                    </Typography>
                    )
                  </>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row) => (
            <TableRow key={row.name}>
              {(row.data || []).map((item) => (
                <TableCell key={item.name} scope="row" align="left" className={classes.tableCell}>
                  {item.label}{" "}
                  {item.hasNumber && (
                    <>
                      (
                      <Typography
                        component="span"
                        className={classes.number}
                        onClick={() => handleClick(item.searchConditions)}
                      >
                        {item.number}
                      </Typography>
                      )
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClickableNumericTable;
