import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MuiDivider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#AAAAAA",
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const Divider = ({ className, ...others }) => {
  const classes = useStyles();

  return <MuiDivider className={classnames(classes.divider, className)} {...others} />;
};

export default Divider;
