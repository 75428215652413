import api from "./api";

import Auth from "auth/authenticator";
import { BUYER, SUPPLIER } from "shared/constants";

const isBuyer = Auth.loginRole() === BUYER;

class pbApi {
  static getPBList(data) {
    return api.post(`/${isBuyer ? BUYER : SUPPLIER}/pb/list`, data);
  }

  static reviseSupplierPb(data) {
    return api.put("/supplier/pb/revise", data);
  }
}

export default pbApi;
