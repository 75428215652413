import moment from "moment";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

export const formatHeaderBuyer = (headerData = {}, t) => {
  let formatedHeader = [];
  const sortingOptions = ["none", "ascending", "descending"];

  let supplierFilter = [];

  if (headerData.supplierName) {
    for (let i = 0; i < headerData.supplierName.length; i++) {
      supplierFilter.push({
        value: headerData.supplierName[i].name,
        label: headerData.supplierName[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "supplierName",
    label: "Supplier Name",
    hasFilter: true,
    filterItems: supplierFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  formatedHeader.push({
    name: "product",
    label: "Product",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  let labFilter = [];

  if (headerData.labName) {
    for (let i = 0; i < headerData.labName.length; i++) {
      labFilter.push({
        value: headerData.labName[i].name,
        label: headerData.labName[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "labName",
    label: "Lab Name",
    hasFilter: true,
    filterItems: labFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  let taskFilter = [];

  if (headerData.taskType) {
    for (let i = 0; i < headerData.taskType.length; i++) {
      taskFilter.push({
        value: headerData.taskType[i].name,
        label: headerData.taskType[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "taskType",
    label: "Task",
    hasFilter: true,
    filterItems: taskFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  formatedHeader.push({
    name: "deadLine",
    label: `${SIMPLE_BUYER_NAME}  Deadline`,
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  formatedHeader.push({
    name: "plannedDate",
    label: "Planned Date",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  formatedHeader.push({
    name: "shipByDate",
    label: "Ship By Date",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  formatedHeader.push({
    name: "ecd",
    label: "ECD",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  let taskStatusFilter = [];

  if (headerData.taskStatus) {
    for (let i = 0; i < headerData.taskStatus.length; i++) {
      taskStatusFilter.push({
        value: headerData.taskStatus[i].name,
        label: t(`taskStatus.${headerData.taskStatus[i].name}`),
      });
    }
  }

  formatedHeader.push({
    name: "taskStatus",
    label: "Task Status",
    hasFilter: true,
    filterItems: taskStatusFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  return formatedHeader;
};

export const formatHeader = (headerData = [], t, filter = {}) => {
  let formatedHeader = [];
  let filterItems = [];
  const sortingOptions = ["none", "ascending", "descending"];

  for (let i = 0; i < headerData.length; i++) {
    filterItems = [];

    if (filter[headerData[i].name]) {
      let tempFilterItems = filter[headerData[i].name].split("$$");

      for (let j = 0; j < tempFilterItems.length; j++) {
        if (tempFilterItems[j]) {
          filterItems.push({
            value: tempFilterItems[j],
            label:
              headerData[i].name.indexOf("Status") >= 0
                ? t(`taskStatus.${tempFilterItems[j]}`)
                : tempFilterItems[j],
          });
        }
      }
    }

    // if (headerData[i].filterItems) {
    //   if (headerData[i].name === "taskStatus") {
    //     for (let j = 0; j < headerData[i].filterItems.length; j++) {
    //       if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
    //         filterItems.push({
    //           value: headerData[i].filterItems[j].name,
    //           label: t(`taskStatus.${headerData[i].filterItems[j].name}`),
    //         });
    //       }
    //     }
    //   } else {
    //     for (let j = 0; j < headerData[i].filterItems.length; j++) {
    //       if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
    //         filterItems.push({
    //           value: headerData[i].filterItems[j].name,
    //           label: headerData[i].filterItems[j].name,
    //         });
    //       }
    //     }
    //   }
    // }

    if (headerData[i].name === "supplierName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Supplier Name",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "product") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Product",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "labName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Lab Name",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "taskType") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "deadLine") {
      formatedHeader.push({
        name: headerData[i].name,
        label: `${SIMPLE_BUYER_NAME} Deadline`,
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "plannedDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Planned Date",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "shipByDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Ship By Date",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "ecd") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "ECD",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "taskStatus") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task Status",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    }
  }

  return formatedHeader;
};

export const formatRow = (headerData = [], rowData = [], t) => {
  let formatedRows = [];
  let data = [];

  for (let i = 0; i < rowData.length; i++) {
    data = [];

    for (let j = 0; j < headerData.length; j++) {
      if (headerData[j].name === "productName") {
        data.push({
          name: headerData[j].name,
          value: rowData[i].wicNumber,
          info: rowData[i][headerData[j].name],
          hasImageButton: rowData[i].masterWicImages.length > 0,
          images: rowData[i].masterWicImages,
        });
      } else if (headerData[j].name === "shipByDate") {
        data.push({
          name: headerData[j].name,
          value: moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY"),
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name.indexOf("Status") >= 0) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name],
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name.indexOf("WbaDeadline") >= 0) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name]
            ? moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY")
            : "N/A",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name.indexOf("Ecd") >= 0) {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name]
            ? moment(rowData[i][headerData[j].name]).format("DD/MMM/YYYY")
            : "N/A",
          info: "",
          hasImageButton: false,
          images: [],
        });
      } else if (headerData[j].name === "factory") {
        data.push({
          name: headerData[j].name,
          value: rowData[i].factoryName,
          info:
            rowData[i].factoryCountry +
            "/" +
            rowData[i].factoryState +
            "/" +
            rowData[i].factoryCity,
          hasImageButton: false,
          images: [],
        });
      } else {
        data.push({
          name: headerData[j].name,
          value: rowData[i][headerData[j].name],
          info: "",
          hasImageButton: false,
          images: [],
        });
      }
    }

    formatedRows.push({
      id: rowData[i].id,
      data: data,
    });
  }

  return formatedRows;
};
