import React from "react";
import _get from "lodash/get";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import { atnGetItemDetailsSupplierFactory } from "actions/itemActions";

import Divider from "components/display/Divider";

import EditItem from "./EditItem";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: "#777777",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
}));

const SupplierFactory = ({ userType, data }) => {
  const classes = useStyles();

  const [supplierFactoryData, setSupplierFactoryData] = React.useState({});
  const [masterWicId, setMasterWicId] = React.useState(0);

  const [wicStatus, setWicStatus] = React.useState(data && data.status ? data.status : "");

  React.useEffect(() => {
    if (!data.id) return;

    getSupplierFactory(data.id);
    setMasterWicId(data.id);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupplierFactory = (id) => {
    atnGetItemDetailsSupplierFactory({ data: { id }, type: userType }).then((result) => {
      setSupplierFactoryData(result.data);
    });
  };

  const callGetSupplierFactory = () => {
    getSupplierFactory(masterWicId);
  };

  const Item = ({ label, value, hasEdit = false, typeEdit }) => (
    <Box className={classes.item} pb={3}>
      <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>

      <Box display="flex" alignItems="center" marginTop="5px">
        <EditItem
          hasEdit={hasEdit}
          value={value}
          masterWicId={masterWicId}
          typeEdit={typeEdit}
          userType={userType}
          cbSetData={callGetSupplierFactory}
        />
      </Box>
    </Box>
  );

  const SupplierDetails = ({ data: supplierData }) => {
    const {
      name,
      address,
      supplierCode: supplierId,
      contactPerson: contactName,
      contactNumber,
      contactEmail,
    } = supplierData;

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Supplier Name" value={name} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Supplier Address" value={address} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Supplier ID" value={supplierId} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Name"
            value={contactName}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "supplierCompany", name: "contactPerson" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Number"
            value={contactNumber}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "supplierCompany", name: "contactNumber" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Email"
            value={contactEmail}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "supplierCompany", name: "contactEmail" }}
          />
        </Grid>
      </Grid>
    );
  };

  const FactoryDetails = ({ data: factoryData, masterWicId }) => {
    const {
      name,
      address,
      address2,
      factoryCode: factoryId,
      contactName,
      contactNumber,
      contactEmail,
    } = factoryData;

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory Name" value={name} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory Address" value={address} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Factory ID" value={factoryId} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Factory Address (in Local Language)"
            value={address2}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "factory", name: "address2" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Name"
            value={contactName}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "factory", name: "contactPerson" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Number"
            value={contactNumber}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "factory", name: "contactNumber" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item
            label="Contact Email"
            value={contactEmail}
            hasEdit={wicStatus === "not_acknowledge" ? false : true}
            typeEdit={{ type: "factory", name: "contactEmail" }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper className={classes.container}>
      <Box py={2}>
        <Typography className={classes.title}>Supplier Details</Typography>
        <Divider />
        <SupplierDetails data={_get(supplierFactoryData, "supplier", {})} />
      </Box>
      <Box py={2}>
        <Typography className={classes.title}>Factory Details</Typography>
        <Divider />
        <FactoryDetails
          data={_get(supplierFactoryData, "factory", {})}
          masterWicId={supplierFactoryData.masterWicId}
        />
      </Box>
    </Paper>
  );
};

export default SupplierFactory;
