import React from "react";
import classnames from "classnames";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  styled,
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 430,
      width: "100%",
      minHeight: 180,
    },
  },
});

export default function BasicModal({
  title = "Confirm to submit?",
  loadingText = "Submitting",
  closeText = "Close",
  submitText = "Submit",
  maxWidth = "sm",
  fullWidth = true,
  errorTitle = false,
  errorText = false,
  errorButton = false,
  divider = false,
  loading = false,
  disabled = false,
  submitDisabled = false,
  closeIconEnabled = false,
  actionsDisabled = false,
  open,
  onClose,
  onSubmit,
  children,
  ...props
}) {
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (onSubmit) onSubmit();
  };

  return (
    <StyledDialog
      data-cy="basic-modal"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      {...props}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        data-cy={`${title.split(" ").join("")}-modal`}
      >
        {title && (
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: !title ? "flex-end" : "space-between",
              alignItems: "center",
              fontSize: 14,
            }}
            className={classnames({
              [classes.errorColor]: errorTitle,
            })}
          >
            {title}

            {closeIconEnabled && (
              <IconButton onClick={onClose}>
                <ClearIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}

        {divider && <Divider />}

        <DialogContent
          className={classnames(classes.dialogContent, {
            [classes.errorColor]: errorText,
          })}
        >
          {children}
        </DialogContent>

        {!actionsDisabled && (
          <DialogActions sx={{ padding: "16px 24px" }}>
            <Button
              autoFocus
              onClick={onClose}
              disabled={disabled || loading}
              color={errorButton ? "error" : "primary"}
            >
              {closeText}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color={errorButton ? "error" : "primary"}
              disabled={submitDisabled || disabled || loading}
            >
              {loading ? loadingText : submitText}
            </Button>
          </DialogActions>
        )}
      </Box>
    </StyledDialog>
  );
}

const useStyles = makeStyles({
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    flexDirection: "column",
    fontSize: "14px",
    margin: "16px 0",
  },
  RemarkWrapper: {
    height: "150px",

    "& .MuiOutlinedInput-root, .MuiOutlinedInput-root > textarea": {
      height: "100% !important",
    },
  },
  errorColor: {
    color: "#d32f2f",
  },
});
