import api from "./api";

class adminApi {
  static getUserList(data) {
    return api.get(`/admin/user/${data.type}`, {});
  }

  static getUserToken(data) {
    return api.post(`/admin/user_account/${data.userId}`, {});
  }
}

export default adminApi;
