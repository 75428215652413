import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import BookingReserved from "./BookingReserved";
import UploadReportAndResult from "./UploadReportAndResult";
import Complete from "./Complete";
import CompleteWithDialog from "../components/CompleteWithDialog";
import UpdateStatusHeader from "../components/UpdateStatusHeader";

import UpdateStatusSelectUpdateType from "../components/UpdateStatusSelectUpdateType";

import ConfirmModal from "components/modal/ConfirmModal";
import PbNotExistedModal from "components/modal/PbNotExistedModal";
import ErrorModal from "components/modal/ErrorModal";

import {
  atnGetCompleteData,
  atnGetSubmissionDetailsTaskList,
  atnNotifyCannotComplete,
  atnUpdateCompleteStatus,
  atnUpdateTaskStatus,
} from "actions/submissionActions";

import { TASK_STATUS } from "shared/constants";

import { isPbNotExisted, isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectFieldContainer: {
    paddingTop: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  selectField: {
    width: "100%",
  },
  selectTaskContainer: {
    paddingTop: 50,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  error: {
    color: "#D42600",
  },
  errorModalContent: {
    marginBottom: "8px",
  },
}));

const UpdateInspectionStatus = ({ history, submissionId, submissionType, isProgram777 }) => {
  const classes = useStyles();

  // const isProgram777 = useSelector((s) => s.submissionDetail.currentTaskDetail.isProgram777);

  const triggeredBy = useSelector((s) => s.submissionDetail.currentTaskDetail.triggeredBy);

  const intialNotExistedState = {
    task: [],
    report: [],
  };

  const [notExistedPbNumbers, setNotExistedPbNumbers] = useState(intialNotExistedState);
  const [missingPbNumbers, setMissingPbNumbers] = useState([]);

  const [step, setStep] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("Step 1: Select Update Type");
  const [updateStatusData, setUpdateStatusData] = useState({
    updateType: "",
    selectedTasks: [],
    confirmedInspectionDate: "", // booking_reserved
    inhouseLabNumber: "", // booking_reserved
    reports: [], // upload report and result
  });

  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [showPbNotExistedDialog, setShowPbNotExistedDialog] = useState(false);
  const [showPbMissingDialog, setShowPbMissingDialog] = useState(false);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const [completeData, setCompleteData] = useState({
    id: "",
    currency: "USD",
    aqlCritical: "0",
    aqlMajor: "2.5",
    aqlMinor: "4.0",
    inspectionDateFrom: "",
    inspectionDateTo: "",
    inspectionLevel: "II",
    totalQuantity: "",
    remarks: "",
    inspectionTaskDetailId: "",

    mandayId: "",
    travelExpenseId: "",
    hotelExpenseId: "",
    otherExpenseId: "",
    lateBookingFeeId: "",
    cancellationFeeId: "",

    noOfMandays: 0,
    mandayUnitCost: 0,
    travelExpenses: 0,
    hotelExpenses: 0,
    otherExpenses: 0,
    lateBookingFee: 0,
    cancellationFee: 0,
    totalPrice: 0,

    reasonOption: "",
    reasonRemarks: "",
  });

  const [prepaymentData, setPrepaymentData] = useState({
    hasPrepayment: false,
    prepaymentPrice: 0,
  });

  const isTotalPriceZero = parseFloat(completeData.totalPrice) === 0;

  const isUpdateTypeBookingReversed =
    updateStatusData.updateType === TASK_STATUS.booking_reserved.value;

  const isUpdateTypeInProgressReportResult =
    updateStatusData.updateType === TASK_STATUS.in_progress_result_report_updated.value;

  const isUpdateTypeCompleted = updateStatusData.updateType === TASK_STATUS.completed.value;

  const handleResetReasonModal = () => {
    setCompleteData((state) => ({
      ...state,
      reasonOption: "",
      reasonRemarks: "",
    }));
  };

  const handleCompleteDataChange = (e) => {
    const { name, value } = e.target;

    setCompleteData((prevState) => {
      let tempTotal = 0;
      let tempSubTotal = 0;
      if (
        name === "noOfMandays" ||
        name === "mandayUnitCost" ||
        name === "travelExpenses" ||
        name === "hotelExpenses" ||
        name === "otherExpenses" ||
        name === "lateBookingFee" ||
        name === "vat" ||
        name === "cancellationFee"
      ) {
        prevState[name] = parseFloat(value);
        tempTotal += (prevState.noOfMandays || 0) * (prevState.mandayUnitCost || 0);
        tempTotal += prevState.travelExpenses || 0;
        tempTotal += prevState.hotelExpenses || 0;
        tempTotal += prevState.otherExpenses || 0;
        tempTotal += prevState.lateBookingFee || 0;
        tempTotal += prevState.cancellationFee || 0;

        if (prepaymentData.hasPrepayment) {
          tempTotal = tempTotal - prepaymentData.prepaymentPrice;
        }

        tempSubTotal = tempTotal;
        if (prevState.vat > 0) {
          tempTotal = tempTotal * (1 + parseFloat(prevState.vat) / 100);
        }

        return {
          ...prevState,
          [name]: parseFloat(value),
          totalPrice: parseFloat(tempTotal.toFixed(2)),
          subTotal: parseFloat(tempSubTotal.toFixed(2)),
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const initData = (updateType) => {
    setUpdateStatusData({
      updateType: updateType,
      selectedTasks: [],
      confirmedInspectionDate: "",
      inhouseLabNumber: "",
      reports: [],
    });
  };

  const handleUpdateStatusDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "updateType") {
      initData(value);
    } else {
      setUpdateStatusData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRemoveFile = (index) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewFilesChange = (files) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.concat(files),
    }));
  };

  const handleReportDataChange = (e, index) => {
    const { name, value } = e.target;

    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleCompleteDialogClose = () => {
    setShowCompleteDialog(false);
  };

  const onOpenPbMissingDialog = () => {
    setShowPbMissingDialog(true);
  };

  const onClosePbMissingDialog = () => {
    setShowPbMissingDialog(false);
  };

  const onOpenPbNotExistedDialog = () => {
    setShowPbNotExistedDialog(true);
  };

  const onClosePbNotExistedDialog = () => {
    setShowPbNotExistedDialog(false);

    history.push(`/lab/submissions/${submissionId}`);
  };

  const pbDisplayName = (item) => `${item.pbNumber} [${item.pbLineId}]`;

  const matchPbWithTask = (selectedTasks = []) => {
    let returnData = {
      taskNotExistedPb: [],
      reportNotExistedPb: [],
      reportMissingPb: [],
      hasNotExistedPb: false,
      hasMissedTaskPb: false,
    };

    const { taskNotExistedPb, reportNotExistedPb } = returnData;

    //get initial task & report pb
    const taskPbList = [];
    const reportPbList = [];

    const showProgram777 = isShowProgram777(triggeredBy);

    //check if not 777, don't have task pb & report pb
    if (!selectedTasks?.length > 0 && !showProgram777) return returnData;

    for (let i = 0; i < selectedTasks.length; i++) {
      const selectedTask = selectedTasks[i];

      //mapping task pb
      if (selectedTask.pb?.length > 0) {
        for (let j = 0; j < selectedTask.pb.length; j++) {
          const taskPb = selectedTask.pb[j];
          const taskPbName = pbDisplayName(taskPb);

          //set pb in task list
          if (taskPbList.findIndex((task) => task.id === -1)) {
            taskPbList.push(taskPb);
          }

          //set not existed pb in task
          if (isPbNotExisted(taskPb) && taskNotExistedPb.indexOf(taskPbName) === -1) {
            taskNotExistedPb.push(taskPbName);
          }
        }
      }

      //mapping lab report pb
      if (selectedTask.reports?.length > 0) {
        for (let x = 0; x < selectedTask.reports.length; x++) {
          if (selectedTask?.reports[x]?.pb?.length > 0) {
            for (let z = 0; z < selectedTask.reports[x].pb.length; z++) {
              const reportPb = selectedTask.reports[x].pb[z];
              const reportPbName = pbDisplayName(reportPb);

              //set pb in report list
              if (reportPbList.findIndex((task) => task.id === -1)) {
                reportPbList.push(reportPb);
              }

              //set not existed pb in report
              if (isPbNotExisted(reportPb) && reportNotExistedPb.indexOf(reportPbName) === -1) {
                reportNotExistedPb.push(reportPbName);
              }
            }
          }
        }
      }
    }

    //check if has missing pb in report only
    returnData.reportMissingPb =
      taskPbList.length > 0
        ? taskPbList
            .filter((tpb) => reportPbList.every((rpb) => tpb.id !== rpb.id))
            .map((pb) => pbDisplayName(pb))
        : [];

    //check if has not existed pb in task OR report
    returnData.hasNotExistedPb = taskNotExistedPb.length > 0 || reportNotExistedPb.length > 0;

    //check has missing pb in task
    returnData.hasMissedTaskPb =
      !returnData.hasNotExistedPb && returnData.reportMissingPb?.length > 0;

    return returnData;
  };

  const handlePrevPage = () => {
    if (step > 0) {
      if (step === 1) {
        if (updateStatusData.updateType === TASK_STATUS.booking_reserved.value) {
          setHeaderTitle("Step 2: Update Information");
        } else if (
          updateStatusData.updateType === TASK_STATUS.in_progress_result_report_updated.value
        ) {
          setHeaderTitle("Step 2: Update Report and Result");
        }
      }

      setStep(step - 1);
    }
  };

  const handleNextPage = (selectedTasks = []) => {
    if (step === 0) {
      if (isUpdateTypeBookingReversed) {
        setHeaderTitle("Step 2: Update Information");
        setStep(step + 1);
      } else if (isUpdateTypeInProgressReportResult) {
        setHeaderTitle("Step 2: Update Report and Result");
        setStep(step + 1);
      } else if (isUpdateTypeCompleted) {
        const {
          taskNotExistedPb,
          reportNotExistedPb,
          reportMissingPb,
          hasNotExistedPb,
          hasMissedTaskPb,
        } = matchPbWithTask(selectedTasks);

        //send alert email & show pb not existed modal for task & report pb OR report pb only
        const taskIdNumber = selectedTasks?.map((task) => task.id).toString();

        if (hasNotExistedPb) {
          setNotExistedPbNumbers((state) => ({
            ...state,
            task: taskNotExistedPb,
            report: reportNotExistedPb,
          }));

          onOpenPbNotExistedDialog();

          atnNotifyCannotComplete({
            taskId: taskIdNumber,
          });
          return;
        }

        //show pb missing modal for missing pb in report
        if (hasMissedTaskPb) {
          setMissingPbNumbers(reportMissingPb);
          onOpenPbMissingDialog();
          return;
        }

        atnGetSubmissionDetailsTaskList({
          data: { id: submissionId },
          type: 3,
        }).then((result) => {
          if (result && result.data) {
            let showInvoice = true;
            let selectedTaskIds = [];

            for (let i = 0; i < selectedTasks.length; i++) {
              selectedTaskIds.push(selectedTasks[i].id);
            }

            for (let i = 0; i < result.data.length; i++) {
              if (
                selectedTaskIds.indexOf(result.data[i].id) === -1 &&
                result.data[i].isCancelled === "false" &&
                result.data[i].taskStatus !== "completed"
              ) {
                showInvoice = false;
                break;
              }
            }

            if (showInvoice) {
              atnGetCompleteData({
                submissionId: submissionId,
              }).then(({ data, message }) => {
                if (data && data.length > 0) {
                  let lastData = data[data.length - 1];

                  for (let i = 0; i < data.length; i++) {
                    if (data[i].isPrepayment) {
                      setPrepaymentData({
                        hasPrepayment: true,
                        prepaymentPrice: data[i].totalPrice,
                      });
                    }
                  }

                  let noOfMandays = 0;
                  let mandayUnitCost = 0;
                  let travelExpenses = 0;
                  let hotelExpenses = 0;
                  let otherExpenses = 0;
                  let lateBookingFee = 0;
                  let cancellationFee = 0;

                  let mandayId = "";
                  let travelExpenseId = "";
                  let hotelExpenseId = "";
                  let otherExpenseId = "";
                  let lateBookingFeeId = "";
                  let cancellationFeeId = "";

                  for (let i = 0; i < lastData.testPlans.length; i++) {
                    switch (lastData.testPlans[i].itemName) {
                      case "Manday":
                        mandayId = lastData.testPlans[i].id;
                        noOfMandays = lastData.testPlans[i].quantity || 0;
                        mandayUnitCost = lastData.testPlans[i].unitPrice || 0;
                        break;
                      case "Travel Expenses":
                        travelExpenseId = lastData.testPlans[i].id;
                        travelExpenses = lastData.testPlans[i].unitPrice || 0;
                        break;
                      case "Hotel Expenses":
                        hotelExpenseId = lastData.testPlans[i].id;
                        hotelExpenses = lastData.testPlans[i].unitPrice || 0;
                        break;
                      case "Other Expenses":
                        otherExpenseId = lastData.testPlans[i].id;
                        otherExpenses = lastData.testPlans[i].unitPrice || 0;
                        break;
                      case "Late Booking Fee":
                        lateBookingFeeId = lastData.testPlans[i].id;
                        lateBookingFee = lastData.testPlans[i].unitPrice || 0;
                        break;
                      case "Cancellation Fee":
                        cancellationFeeId = lastData.testPlans[i].id;
                        cancellationFee = lastData.testPlans[i].unitPrice || 0;
                        break;
                      default:
                        break;
                    }
                  }

                  setCompleteData({
                    id: lastData.id,
                    currency: "USD",
                    aqlCritical: lastData.inspectionTaskDetail.aqlCritical,
                    aqlMajor: lastData.inspectionTaskDetail.aqlMajor,
                    aqlMinor: lastData.inspectionTaskDetail.aqlMinor,
                    inspectionDateFrom: lastData.inspectionTaskDetail.inspectionDateFrom
                      ? moment(lastData.inspectionTaskDetail.inspectionDateFrom).format(
                          "YYYY-MM-DD",
                        )
                      : "",
                    inspectionDateTo: lastData.inspectionTaskDetail.inspectionDateTo
                      ? moment(lastData.inspectionTaskDetail.inspectionDateTo).format("YYYY-MM-DD")
                      : "",
                    inspectionLevel: lastData.inspectionTaskDetail.inspectionLevel,
                    totalQuantity: lastData.inspectionTaskDetail.totalQuantity || "",
                    remarks: lastData.inspectionTaskDetail.remarks || "",
                    inspectionTaskDetailId: lastData.inspectionTaskDetail.id,

                    mandayId,
                    travelExpenseId,
                    hotelExpenseId,
                    otherExpenseId,
                    lateBookingFeeId,
                    cancellationFeeId,

                    noOfMandays,
                    mandayUnitCost,
                    travelExpenses,
                    hotelExpenses,
                    otherExpenses,
                    lateBookingFee,
                    cancellationFee,
                    totalPrice: lastData.totalPrice || 0,

                    reasonOption: lastData.reasonOption,
                    reasonRemarks: lastData.reasonRemarks,
                  });

                  setHeaderTitle("Step 2: Invoice Update");
                  setStep(step + 1);
                }
              });
            } else {
              setShowCompleteDialog(true);
            }
          }
        });
      }
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    setIsDisabledConfirmButton(true);
    if (isUpdateTypeCompleted) {
      if (showCompleteDialog) {
        let submitData = {
          submissionId: submissionId,
          tasks: updateStatusData.selectedTasks,
        };

        atnUpdateCompleteStatus(submitData).then(({ data, message }) => {
          if (data) {
            setIsDisabledConfirmButton(false);
            history.push(`/lab/submissions/${submissionId}`);
          }
        });
      } else {
        let testItems = [
          {
            id: completeData.mandayId,
            itemName: "Manday",
            quantity: completeData.noOfMandays,
            unitPrice: completeData.mandayUnitCost,
          },
          {
            id: completeData.travelExpenseId,
            itemName: "Travel Expenses",
            quantity: 1,
            unitPrice: completeData.travelExpenses,
          },
          {
            id: completeData.hotelExpenseId,
            itemName: "Hotel Expenses",
            quantity: 1,
            unitPrice: completeData.hotelExpenses,
          },
          {
            id: completeData.otherExpenseId,
            itemName: "Other Expenses",
            quantity: 1,
            unitPrice: completeData.otherExpenses,
          },
          {
            id: completeData.lateBookingFeeId,
            itemName: "Late Booking Fee",
            quantity: 1,
            unitPrice: completeData.lateBookingFee,
          },
          {
            id: completeData.cancellationFeeId,
            itemName: "Cancellation Fee",
            quantity: 1,
            unitPrice: completeData.cancellationFee,
          },
        ];

        const invoice = {
          id: completeData.id,
          currency: completeData.currency,
          totalPrice: completeData.totalPrice,
          remarks: completeData.remarks,
          vat: completeData.vat,
        };

        let submitData = {
          submissionId: submissionId,
          invoice:
            isUpdateTypeCompleted && isTotalPriceZero
              ? {
                  ...invoice,
                  reasonOption: completeData.reasonOption,
                  reasonRemarks: completeData.reasonRemarks,
                }
              : invoice,
          // inspectionTaskDetail: {
          //   id: completeData.inspectionTaskDetailId,
          //   aqlCritical: completeData.aqlCritical,
          //   aqlMajor: completeData.aqlMajor,
          //   aqlMinor: completeData.aqlMinor,
          //   inspectionDateFrom: moment(completeData.inspectionDateFrom).format("YYYY-MM-DD"),
          //   inspectionDateTo: moment(completeData.inspectionDateTo).format("YYYY-MM-DD"),
          //   inspectionLevel: completeData.inspectionLevel,
          //   totalQuantity: completeData.totalQuantity,
          // },
          invoiceTestItems: testItems,
          tasks: updateStatusData.selectedTasks,
        };

        atnUpdateCompleteStatus(submitData).then(({ data, message }) => {
          if (data) {
            setIsDisabledConfirmButton(false);
            history.push(`/lab/submissions/${submissionId}`);
          }
        });
      }
    } else {
      let submitData = [];

      if (updateStatusData.updateType === TASK_STATUS.booking_reserved.value) {
        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
            confirmedInspectionDate: moment(updateStatusData.confirmedInspectionDate).format(
              "YYYY-MM-DD",
            ),
            inhouseLabNumber: updateStatusData.inhouseLabNumber,
          });
        }
      } else if (
        updateStatusData.updateType === TASK_STATUS.in_progress_result_report_updated.value
      ) {
        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
            reports: updateStatusData.reports,
          });
        }
      }

      atnUpdateTaskStatus(submitData).then(({ data, message }) => {
        if (data) {
          setIsDisabledConfirmButton(false);
          history.push(`/lab/submissions/${submissionId}`);
        }
      });
    }
  };

  return (
    <Box className={classes.root}>
      <UpdateStatusHeader
        step={step}
        headerTitle={headerTitle}
        handlePrevPage={handlePrevPage}
        handleClose={handleClose}
      />

      {step === 0 && (
        <UpdateStatusSelectUpdateType
          updateStatusData={updateStatusData}
          submissionId={submissionId}
          triggeredBy={triggeredBy}
          isProgram777={isProgram777}
          submissionType={submissionType}
          handleNextPage={handleNextPage}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
        />
      )}

      {step === 1 && updateStatusData.updateType === TASK_STATUS.booking_reserved.value && (
        <BookingReserved
          updateStatusData={updateStatusData}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
          handleSubmit={handleSubmit}
        />
      )}

      {step === 1 &&
        updateStatusData.updateType === TASK_STATUS.in_progress_result_report_updated.value && (
          <UploadReportAndResult
            updateStatusData={updateStatusData}
            handleAddNewFilesChange={handleAddNewFilesChange}
            handleReportDataChange={handleReportDataChange}
            handleRemoveFile={handleRemoveFile}
            handleSubmit={handleSubmit}
            isProgram777={isProgram777}
            triggeredBy={triggeredBy}
          />
        )}

      {step === 1 && isUpdateTypeCompleted && (
        <CompleteWithDialog
          isInspection
          title="Update and Confirm the Invoice Detail"
          completeData={completeData}
          prepaymentData={prepaymentData}
          onSubmit={handleSubmit}
          isDisabledConfirmButton={isDisabledConfirmButton}
          handleCompleteDataChange={handleCompleteDataChange}
          handleResetReasonModal={handleResetReasonModal}
          component={Complete}
        />
      )}

      <ConfirmModal
        open={showCompleteDialog}
        onClose={handleCompleteDialogClose}
        onSubmit={handleSubmit}
        hasContentList
      />

      <PbNotExistedModal
        pbNumbers={notExistedPbNumbers.task}
        reportPbNumbers={notExistedPbNumbers.report}
        open={showPbNotExistedDialog}
        onClose={onClosePbNotExistedDialog}
      />

      <ErrorModal
        title="Missing Task PB in Report"
        content=""
        open={showPbMissingDialog}
        onClose={onClosePbMissingDialog}
      >
        <Box className={classes.errorModalContent}>Please update missing PB in the following:</Box>"
        {missingPbNumbers?.length > 0 && (
          <span className={classes.error}>{missingPbNumbers.join(", ")}</span>
        )}
        "
      </ErrorModal>
    </Box>
  );
};

export default UpdateInspectionStatus;
