export const SUBMISSION_ACTION_TYPE = {
  EDIT_SUBMISSION: "edit_submission",
  CANCEL_TASK: "cancel_task",
  REVISE_PB: "revise_pb",
  UPDATE_REPORT_PB: "update_report_pb",
  RESUME_REPORT: "resume_report",
  UPDATE_COST: "update_cost",
  UPDATE_STATUS: "update_status",
  UPLOAD_REVISE_REPORT: "upload_revise_report",
  CREATE_NEW_INVOICE: "create_new_invoice",
};
