import React, { useEffect, useState } from "react";
import MuiTablePagination from "@material-ui/core/TablePagination";

// import { makeStyles } from '@material-ui/core/styles';
import TablePaginationActions from "./TablePaginationActions";

// const useStyles = makeStyles((theme) => ({}));

const TablePagination = ({
  data,
  func,
  rowsPerPageOptions = [5, 10, 25, { label: "All", value: data.rowsTotal || 1000 }],
  cbSetPageData = () => {},
}) => {
  // const classes = useStyles();

  const { rowsTotal, columnsTotal, pageIndex, pageSize } = data;
  const { gotoPage, setPageSize } = func;

  const [pageData, setPageData] = useState({ pageSize: pageSize, newPage: 0 });

  useEffect(() => {
    cbSetPageData(pageData);
  }, [pageData]);

  const handleChangePage = (event, newPage) => {
    setPageData({
      pageSize: pageSize,
      newPage: newPage,
    });
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
    gotoPage(0);

    setPageData({
      pageSize: parseInt(event.target.value),
      newPage: 0,
    });
  };

  return (
    <MuiTablePagination
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={columnsTotal + 1}
      count={rowsTotal}
      rowsPerPage={pageSize}
      page={pageIndex}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: true,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default TablePagination;
