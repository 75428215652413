import React, { useState } from "react";

export default function useToggle() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return [open, toggleOpen];
}
