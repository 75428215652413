import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";

import Button from "components/input/Button";
import Link from "components/display/Link";
import Table from "components/table/Table";
import FileUpload from "components/input/FileUpload";

import { LAB_TYPE, TEST_RESULTS } from "shared/constants";
import { capitalize } from "utils/string";

import UploadNewReport from "./UploadNewReport";

import dataJSON from "../data";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(10),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#232B3A",
  },
  blackText: {
    color: "#000000",
  },
  textLabel: {
    paddingBottom: theme.spacing(1),
  },
  tableCellTop: {
    borderTop: "none",
  },
  tableCellLeftRight: {
    borderLeft: "none",
    borderRight: "none",
  },
  tableCellTextAlignRight: {
    textAlign: "right",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  // tableCellNoPaddingRight: {
  //   paddingRight: 0,
  // },
  iconButton: {
    marginLeft: theme.spacing(2),
    color: "#777777",
    width: "0.8em",
    height: "0.8em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  iconButtonLast: {
    marginLeft: theme.spacing(1),
  },
}));

const ReportUpload = ({ data, type }) => {
  const classes = useStyles();

  const { nextAction } = data;

  const [openModal, setOpenModal] = React.useState(false);

  const handleNext = () => {
    if (nextAction) nextAction();
  };

  const ReportsType01 = () => {
    const tableData01 = dataJSON.reports;

    const tableColumn01 = [
      {
        columnId: "report",
        label: "Report",
        width: "50%",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight, classes.tableCellNoPaddingLeft),
        },
        render: (data) => {
          return data.url ? <Link path={data.url} text={data.name} /> : <span>{data.name}</span>;
        },
      },
      {
        columnId: "result",
        label: "Overall Result",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight, classes.tableCellTextAlignRight),
        },
        render: (data) => {
          return capitalize(data.result);
        },
      },
      {
        columnId: "issueDate",
        label: "Issue Date",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight, classes.tableCellTextAlignRight),
        },
        render: (data) => {
          return data.issueDate;
        },
      },
      {
        columnId: "actions",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight),
        },
        render: () => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <EditIcon className={classes.iconButton} onClick={() => {}} />
              <DeleteIcon
                className={classnames(classes.iconButton, classes.iconButtonLast)}
                onClick={() => {}}
              />
            </Box>
          );
        },
      },
    ];

    const handleUploadNew = () => {
      setOpenModal(true);
    };

    return (
      <>
        <Box className={classes.item} py={4}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            {"Results & Reports"}
          </Typography>
          <Table data={tableData01} columnData={tableColumn01} noPagination />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleUploadNew}>
            Upload New Report
          </Button>
        </Box>
      </>
    );
  };

  const ReportsType02 = () => {
    const tableData02 = dataJSON.reports;

    const tableColumn02 = [
      {
        columnId: "report",
        label: "Report",
        width: "50%",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight, classes.tableCellNoPaddingLeft),
        },
        render: (data) => {
          return data.url ? <Link path={data.url} text={data.name} /> : <span>{data.name}</span>;
        },
      },
      {
        columnId: "result",
        label: "Result",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight),
        },
        render: (data) => {
          return (
            <Select
              name="testResults"
              value={data.result}
              options={TEST_RESULTS}
              // handleChange={}
              // additionalClass={}
            />
          );
        },
      },
      {
        columnId: "issueDate",
        label: "Issue Date",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight),
        },
        render: (data) => {
          return (
            <DatePicker
              name="issueDate"
              value={data.issueDate}
              // handleChange={() => {}}
              // additionalClass={}
            />
          );
        },
      },
      {
        columnId: "actions",
        classes: {
          head: classes.tableCellTop,
          cell: classnames(classes.tableCellLeftRight),
        },
        render: () => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <DeleteIcon
                className={classnames(classes.iconButton, classes.iconButtonLast)}
                onClick={() => {}}
              />
            </Box>
          );
        },
      },
    ];

    return (
      <>
        <Box className={classes.item} py={4}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            {"Results & Reports"}
          </Typography>
          <Table data={tableData02} columnData={tableColumn02} noPagination />
        </Box>
        <FileUpload
          data={tableData02}
          config={{ type: "reportFiles", typeId: data.id, fileType: "report_file" }}
          multiple
          maxWidth
          isPreviewFiles={false}
          buttonText="Upload Report(s)"
        />
      </>
    );
  };

  return (
    <>
      <Paper className={classes.container}>
        {type === LAB_TYPE.type01 && <ReportsType01 />}
        {type === LAB_TYPE.type02 && <ReportsType02 />}
      </Paper>

      <Button className={classes.bottomButton} onClick={handleNext}>
        {"Update & Upload"}
      </Button>

      {type === LAB_TYPE.type01 && <UploadNewReport open={openModal} setOpen={setOpenModal} />}
    </>
  );
};

export default ReportUpload;
