import pbApi from '../api/pbApi';

export function atnGetPBList(data) {
  return pbApi.getPBList(data);
}

export function atnReviseSupplierPb(data) {
  return pbApi.reviseSupplierPb(data);
}

