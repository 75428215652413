import React from "react";
import { atnDownloadFile } from "actions/fileActions";
import { downloadFile } from "utils/files";
import auth from "auth/authenticator";

const FileDownloadPage = ({ match, history }) => {
  const token = match.params.token;
  const name = match.params.name;

  if (token) {
    atnDownloadFile(token).then(async (data) => {
      downloadFile(data, name);

      history.push(`/${auth.loginRole()}/home`);
    });
  }

  return <></>;
};

export default FileDownloadPage;
