import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import ReactTable from "components/table/ReactTable";
import classTable from "./style.module.scss";

// import poTable from './mock/poTable.json';
import Status from "./components/Filter/Status";
import SearchFilter from "./components/Filter/SearchFilter";
import { CATEGORY_FILTER, MODE, STATUS, STATUS_ALL } from "./constants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { atnGetFilterList } from "actions/analyticsActions";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
    maxWidth: "100%",
    padding: "10px 30px 70px",
  },

  searchContent: {
    marginBottom: "10px",
    fontWeight: "500",

    "& span:not(:last-child)": {
      marginRight: "10px",
    },
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  normalCell: {},

  warningCell: {
    color: "#D32F2F",
    fontWeight: "bold",
  },

  tab: {
    minWidth: "110px",
  },
  showDetail: {
    color: "#003DA5",
  },
  status: {
    textTransform: "capitalize",
  },
  noStatus: {
    textTransform: "capitalize",
    color: "#D42600",
  },
}));

const POTable = ({ listPODaily = [], listSearch = [] }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const filterOnly = { canSort: false, canFilter: true };
  const noSortFilter = { canSort: false, canFilter: false };

  // ============= GET FILTER OPTION LIST ====================
  const [listPODailyDone, setListPODailyDone] = useState(listPODaily);

  useEffect(() => {
    setListPODailyDone(listPODaily);
    getWbaQAList();
    getALlFilterList();
    getEverydayProgram();
  }, [listPODaily]);

  const getWbaQAList = () => {
    let listWbaQA = listPODaily.map((item) => item.wbaResponsibleQA);
    setWBAList(
      listWbaQA
        .filter((item, index) => {
          return listWbaQA.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item ? item : "NA", key: item ? item : "NA" };
        }),
    );
  };

  const getEverydayProgram = () => {
    let listProgramName = listPODaily.map((item) => item.programName);
    setProgramList(
      listProgramName
        .filter((item, index) => {
          return listProgramName.indexOf(item) === index;
        })
        .map((item, index) => {
          return { value: item, key: item };
        }),
    );
  };

  const getALlFilterList = () => {
    atnGetFilterList().then(({ data, message }) => {
      setSupplierList(
        data.suppliers
          .filter((item) => item.name)
          .map((item, index) => {
            return { ...item, value: item.id, key: item.name };
          }),
      );
      setFactoryList(
        data.factories.map((item, index) => {
          return { ...item, value: item.factoryName, key: item.factoryName };
        }),
      );
    });
  };

  const [wbaList, setWBAList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);

  // ============= END GET FILTER OPTION LIST ====================

  // ======== Handle Tab Change Program Category ========
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const ProCategoryFilter = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Paper className={classes.wrapperTab}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabHeader}
            >
              <Tab label="Program" className={classes.tab} />
              <Tab label="Category" className={classes.tab} />
            </Tabs>
          </Paper>
        </Box>

        <Box display="block">
          <TabPanel value={tab} index={0}>
            <SearchFilter
              cbSetFilter={cbSetFilterProgram}
              list={programList}
              selectedValues={selectedProgramOption}
            />
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <SearchFilter
              cbSetFilter={cbSetFilterCategory}
              list={CATEGORY_FILTER}
              selectedValues={selectedCategoryOption}
            />
          </TabPanel>
        </Box>
      </Box>
    );
  };
  // ======== Emd Handle Tab Change Program Category ========
  const [selectedWBAOption, setSelectedWBAOption] = useState([]);
  const cbSetFilterWBA = (data) => {
    setSelectedWBAOption(data);
  };

  const [selectedSupplierOption, setSelectedSupplierOption] = useState([]);
  const cbSetFilterSupplier = (data) => {
    setSelectedSupplierOption(data);
  };

  const [selectedFactoryOption, setSelectedFactoryOption] = useState([]);
  const cbSetFilterFactory = (data) => {
    setSelectedFactoryOption(data);
  };

  const [selectedProgramOption, setSelectedProgramOption] = useState([]);
  const cbSetFilterProgram = (data) => {
    setSelectedProgramOption(data);
  };

  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const cbSetFilterCategory = (data) => {
    setSelectedCategoryOption(data);
  };

  const [listPAC, setListPAC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterPAC = (data) => {
    setListPAC(data);
  };

  const [listGCC, setListGCC] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterGCC = (data) => {
    setListGCC(data);
  };

  const [listQAA, setListQAA] = useState(
    STATUS_ALL.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterQAA = (data) => {
    setListQAA(data);
  };

  const [listFRI, setListFRI] = useState(
    STATUS.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterFRI = (data) => {
    setListFRI(data);
  };

  const [listMode, setListMode] = useState(
    MODE.map((item, index) => {
      return { ...item, checked: false };
    }),
  );
  const cbSetFilterMode = (data) => {
    setListMode(data);
  };

  const tableColumn = [
    {
      id: "wbaResponsibleQA",
      value: "wbaResponsibleQA",
      label: `${SIMPLE_BUYER_NAME} Responsible QA`,
      ...filterOnly,
      render: ({ cell: { value: wbaResponsibleQA } }) => {
        return (
          <Box className={classes.normalCell}>{!wbaResponsibleQA ? "NA" : wbaResponsibleQA}</Box>
        );
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterWBA}
            list={wbaList}
            selectedValues={selectedWBAOption}
          />
        );
      },
      selectedCheckBox: selectedWBAOption.length > 0,
    },
    {
      id: "poNumber",
      value: "poNumber",
      label: "PO Number ",
      ...noSortFilter,
      render: ({ cell: { value: poNumber } }) => {
        return <Box className={classes.normalCell}>{poNumber}</Box>;
      },
    },
    {
      id: "wicNumber",
      value: ({ wicNumber, productName }) => {
        return { wicNumber, productName };
      },
      label: "WIC No. / Description ",
      ...noSortFilter,
      render: ({
        cell: {
          value: { wicNumber, productName },
        },
      }) => {
        return (
          <Box>
            <div>{wicNumber}</div>
            <div className={classes.showDetail}>{productName}</div>
          </Box>
        );
      },
    },
    {
      id: "programName",
      value: ({ programName, category }) => {
        return { programName, category };
      },
      label: "Program / Category",
      ...filterOnly,
      render: ({
        cell: {
          value: { programName, category },
        },
      }) => {
        return (
          <Box>
            <div>{programName}</div>
            <div className={classes.showDetail}>{category}</div>
          </Box>
        );
      },
      renderFilter: () => {
        return <ProCategoryFilter />;
      },
      selectedCheckBox: selectedProgramOption.length > 0 || selectedCategoryOption.length > 0,
    },
    {
      id: "supplierName",
      value: "supplierName",
      label: "Supplier Name",
      ...filterOnly,
      render: ({ cell: { value: supplierName } }) => {
        return <Box className={classes.normalCell}>{supplierName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterSupplier}
            list={supplierList}
            selectedValues={selectedSupplierOption}
          />
        );
      },
      selectedCheckBox: selectedSupplierOption.length > 0,
    },
    {
      id: "factoryName",
      value: "factoryName",
      label: "Factory Name",
      ...filterOnly,
      render: ({ cell: { value: factoryName } }) => {
        return <Box className={classes.normalCell}>{factoryName}</Box>;
      },
      renderFilter: () => {
        return (
          <SearchFilter
            cbSetFilter={cbSetFilterFactory}
            list={factoryList}
            selectedValues={selectedFactoryOption}
          />
        );
      },
      selectedCheckBox: selectedFactoryOption.length > 0,
    },
    {
      id: "poQuantity",
      value: "poQuantity",
      label: "PO Quantity",
      ...sortOnly,
      render: ({ cell: { value: poQuantity } }) => {
        return <Box className={classes.normalCell}>{poQuantity}</Box>;
      },
    },
    {
      id: "shipByDate",
      value: "shipByDate",
      label: "Ship By Date",
      ...sortOnly,
      render: ({ cell: { value: shipByDate } }) => {
        return <Box className={classes.normalCell}>{shipByDate}</Box>;
      },
    },
    {
      id: "pacStatus",
      value: "pacStatus",
      label: "PAC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: pacStatus } }) => {
        return (
          <Box
            className={
              pacStatus && pacStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!pacStatus ? "NA" : pacStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterPAC} list={listPAC} />;
      },
      selectedCheckBox: listPAC.filter((child) => child.checked).length > 0,
    },
    {
      id: "gccStatus",
      value: "gccStatus",
      label: "GCC Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: gccStatus } }) => {
        return (
          <Box
            className={
              gccStatus && gccStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!gccStatus ? "NA" : gccStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterGCC} list={listGCC} />;
      },
      selectedCheckBox: listGCC.filter((child) => child.checked).length > 0,
    },
    {
      id: "qaaStatus",
      value: "qaaStatus",
      label: "QAA Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: qaaStatus } }) => {
        return (
          <Box
            className={
              qaaStatus && qaaStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!qaaStatus ? "NA" : qaaStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterQAA} list={listQAA} />;
      },
      selectedCheckBox: listQAA.filter((child) => child.checked).length > 0,
    },
    {
      id: "friStatus",
      value: "friStatus",
      label: "FRI Compliance Status",
      ...filterOnly,
      render: ({ cell: { value: friStatus } }) => {
        return (
          <Box
            className={
              friStatus && friStatus.toLowerCase() === "no" ? classes.noStatus : classes.status
            }
          >
            {!friStatus ? "NA" : friStatus}
          </Box>
        );
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterFRI} list={listFRI} />;
      },
      selectedCheckBox: listFRI.filter((child) => child.checked).length > 0,
    },
    {
      id: "loadingMode",
      value: "loadingMode",
      label: "Loading Mode",
      ...filterOnly,
      render: ({ cell: { value: loadingMode } }) => {
        return <Box className={classes.normalCell}>{loadingMode}</Box>;
      },
      renderFilter: () => {
        return <Status cbSetFilter={cbSetFilterMode} list={listMode} />;
      },
      selectedCheckBox: listMode.filter((child) => child.checked).length > 0,
    },
  ];

  // ============= HANDLE SEARCH ANG FILTER LIST ====================

  const [listKeySearch, setListKeySearch] = useState([]);

  useEffect(() => {
    setListKeySearch(listSearch.split(new RegExp("[,]", "g")).map((item, index) => item.trim()));
  }, [listSearch]);

  useEffect(() => {
    handleFilteredList();
  }, [
    listKeySearch,
    selectedWBAOption,
    selectedSupplierOption,
    selectedFactoryOption,
    selectedProgramOption,
    selectedCategoryOption,
    listPAC,
    listGCC,
    listQAA,
    listFRI,
    listMode,
  ]);

  const handleFilteredList = () => {
    let result = listPODaily
      .filter((item) => {
        return listKeySearch.length === 1 && listKeySearch[0] === ""
          ? item
          : listKeySearch.filter((itemSearch) => item.poNumber.includes(itemSearch)).length > 0 ||
              listKeySearch.filter((itemSearch) => item.wicNumber.includes(itemSearch)).length >
                0 ||
              listKeySearch.filter((itemSearch) => item.productName.includes(itemSearch)).length >
                0;
      })
      .filter((item) => {
        return selectedWBAOption.length === 0
          ? item
          : selectedWBAOption
              .map((child) => child.value)
              .indexOf(item.wbaResponsibleQA ? item.wbaResponsibleQA : "NA") > -1;
      })
      .filter((item) => {
        return selectedProgramOption.length === 0
          ? item
          : selectedProgramOption.map((child) => child.value).indexOf(item.programName) > -1;
      })
      .filter((item) => {
        return selectedCategoryOption.length === 0
          ? item
          : selectedCategoryOption.map((child) => child.value).indexOf(item.category) > -1;
      })
      .filter((item) => {
        return selectedSupplierOption.length === 0
          ? item
          : selectedSupplierOption.map((child) => child.name).indexOf(item.supplierName) > -1;
      })
      .filter((item) => {
        return selectedFactoryOption.length === 0
          ? item
          : selectedFactoryOption.map((child) => child.factoryName).indexOf(item.factoryName) > -1;
      })
      .filter((item) => {
        return listPAC.filter((child) => !child.checked).length === 3
          ? item
          : listPAC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.pacStatus ? "na" : item.pacStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listGCC.filter((child) => !child.checked).length === 3
          ? item
          : listGCC.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.gccStatus ? "na" : item.gccStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listQAA.filter((child) => !child.checked).length === 3
          ? item
          : listQAA.filter(
              (child) =>
                child.label.toLowerCase() ===
                  (!item.qaaStatus ? "na" : item.qaaStatus.toLowerCase()) && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listFRI.filter((child) => !child.checked).length === 2
          ? item
          : listFRI.filter(
              (child) =>
                child.label.toLowerCase() === item.friStatus.toLowerCase() && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listMode.filter((child) => !child.checked).length === 2
          ? item
          : listMode.filter(
              (child) =>
                child.label.toLowerCase() === item.loadingMode.toLowerCase() && child.checked,
            ).length > 0;
      })
      .filter((item) => {
        return listMode.filter((child) => !child.checked).length === 2
          ? item
          : listMode.filter((child) => child.label === item.loadingMode && child.checked).length >
              0;
      });

    setListPODailyDone(result);
  };

  // ============= GET HANDLE SEARCH ANG FILTER LIST ====================

  return (
    <Box className={classes.wrapperBasic}>
      {listSearch.length > 0 && (
        <Box className={classes.searchContent}>
          Viewing Searched Result of : "
          {listSearch.split(new RegExp("[,]", "g")).map((item, index) => (
            <span key={index}>{item.trim()}</span>
          ))}
          "
        </Box>
      )}
      <Box className={classes.boxTable}>
        <ReactTable
          columns={tableColumn}
          data={listPODailyDone}
          hasCheckbox={true}
          hasPagingTop={true}
          sizeOfPage={50}
          rowsPerPageOptions={[50, 100, 200, { label: "All", value: listPODailyDone.length }]}
          classes={{
            root: classTable.analyticsTable,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
        />
      </Box>
    </Box>
  );
};

export default POTable;
