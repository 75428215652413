import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Grid, Typography } from "@material-ui/core";

import Divider from "components/display/Divider";
import InspectionDetails from "pages/common/InspectionDetails";
import OtherDetails from "pages/common/OtherDetails";
import TestPlanTable from "pages/common/TestPlanTable";
import { INVOICE_TYPE, INVOICE_TYPE_OPTIONS, TASK_INSPECTION_TYPE } from "shared/constants";
import { getDateFormat } from "utils/date";
import { capitalize, textOrUnknown } from "utils/string";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Button from "@material-ui/core/Button";
import auth from "auth/authenticator";
import { useHistory } from "react-router-dom";

import useUserType from "hooks/useUserType";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hideButton: {
    display: "none",
  },
  content: {
    marginTop: 20,
    border: "1px solid #AAAAAA",
    padding: 30,

    "&:last-child": {
      marginBottom: 20,
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  titleTop30: {
    display: "block",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 30,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  text5: {
    display: "block",
    fontSize: 10,
    marginTop: 5,
  },
  text25: {
    display: "block",
    fontSize: 10,
    marginTop: 15,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#777777",
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "0 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
    lineHeight: "20px",
  },
  headerAccordion: {
    cursor: "pointer",
  },
  arrowIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },
  invoiceName: {
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    marginBottom: 10,
    alignItems: "center",

    "& span": {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "18px",
      color: "#433501",
      padding: "2px",
      backgroundColor: "#FFD12E",
      border: "1px solid #D5A910",
      borderRadius: "2px",
      display: "inline-block",
      marginLeft: 10,
    },
  },
  normalText: {
    fontSize: 10,
  },
  opText: {
    fontSize: 10,
    color: "#777777",
  },
  totalSmall: {
    fontSize: 14,
    fontWeight: 500,
  },
  updateInvoice: {
    float: "right",
    marginTop: 15,
  },
}));

export default function InvoiceItem({
  submissionId,
  item: tableData = {},
  index,
  taskType,
  prepaymentData,
}) {
  const classes = useStyles();

  const userType = auth.userType();

  const { isLab } = useUserType(userType);

  const {
    testPlans,
    lastUpdated,
    paymentStatus,
    inspectionTaskDetail,
    isPrepayment,
    status,
    isResume,
    id,
    payerCurrency,
    invoiceType,
    ...rest
  } = tableData;

  const history = useHistory();

  const [show, setShow] = useState(false);
  const [showResaonRemark, setShowResaonRemark] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (!prepaymentData.hasPrepayment && tableData.totalPrice === 0) {
      setShowResaonRemark(true);
    }
  }, [tableData.totalPrice, prepaymentData.hasPrepayment]);

  const handleUpdateInvoice = () => {
    history.push(`/lab/submissions/${submissionId}/invoice/resume/${id}`);
  };

  const getInvoiceTypeLabel = (type) => {
    const [icwCompanyOption, plainVatOption, specialVatOption] = INVOICE_TYPE_OPTIONS;

    switch (type) {
      case INVOICE_TYPE.ICW_COMPANY_INVOICE:
        return icwCompanyOption.label;
        break;
      case INVOICE_TYPE.PLAIN_VAT_INVOICE:
        return plainVatOption.label;
        break;
      case INVOICE_TYPE.SPECIAL_VAT_INVOICE:
        return specialVatOption.label;
        break;
      default:
        return "";
        break;
    }
  };

  return (
    <Box>
      <Box key={index} className={classes.content}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerAccordion}
          onClick={() => toggleShow()}
        >
          <Box>
            <Box className={classnames(classes.invoiceName, classes.blackText)}>
              Invoice
              {isResume && <span>Resume Invoice</span>}
              {isPrepayment && <Box className={classes.prepaymentContainer}>Prepayment</Box>}
            </Box>
            <Box className={classes.normalText}>
              Payment Status: {textOrUnknown(capitalize(paymentStatus))}
            </Box>
          </Box>
          <Box
            position="relative"
            paddingRight="50px"
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Box className={classes.totalSmall}>
              {tableData.totalPrice} {tableData.currency}
            </Box>
            <Box className={classes.opText}>
              Last Updated: {textOrUnknown(getDateFormat(lastUpdated))}
            </Box>
            <Box className={classes.arrowIcon}>
              {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
          </Box>
        </Box>
        {show && (
          <Box overflow="hidden">
            {/* <Box display="flex" paddingTop="20px">
              {isPrepayment && <Box className={classes.prepaymentContainer}>Prepayment</Box>}
            </Box> */}

            <TestPlanTable
              data={{ testPlans, ...rest }}
              taskType={taskType}
              index={index}
              prepaymentData={prepaymentData}
            />

            {TASK_INSPECTION_TYPE.includes(taskType) && (
              <>
                <Typography className={classnames(classes.title, classes.blackText)}>
                  Inspection Details
                </Typography>
                <InspectionDetails data={inspectionTaskDetail} />
              </>
            )}

            <Divider />

            <Typography className={classnames(classes.title, classes.blackText)}>
              Other Details
            </Typography>
            <OtherDetails data={rest} taskType={taskType} />

            <Divider />

            <Typography className={classnames(classes.title, classes.blackText)}>
              Payer Details
            </Typography>
            <Box pt={2} paddingBottom="25px">
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Payer Company Name:
                  </Typography>
                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(tableData?.payerCompanyName))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Payer Address:
                  </Typography>
                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(tableData?.payerCompanyAddress))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Payer Contact Person Name:
                  </Typography>
                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(tableData?.payerContactPerson))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Contact Person Email:
                  </Typography>
                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(tableData?.payerEmail))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Contact Person Phone Number:
                  </Typography>
                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(tableData?.payerPhoneNumber))}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Typography className={classnames(classes.title, classes.blackText)}>
              Payment Details
            </Typography>

            <Box pt={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Invoice Type:
                  </Typography>

                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(getInvoiceTypeLabel(invoiceType))}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Currency:
                  </Typography>

                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(payerCurrency)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box pb={2}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Payment Status:
                  </Typography>

                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(capitalize(paymentStatus))}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography className={classnames(classes.text25, classes.grayText)}>
                    Last Updated:
                  </Typography>

                  <Typography
                    className={classnames(classes.left, classes.text5, classes.blackText)}
                  >
                    {textOrUnknown(getDateFormat(lastUpdated))}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {showResaonRemark && (
              <>
                <Divider />

                <Typography className={classnames(classes.title, classes.blackText)}>
                  Remarks for No Cost
                </Typography>

                <Box pt={2}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Typography className={classnames(classes.text, classes.grayText)}>
                        Reason:
                      </Typography>
                      <Typography
                        className={classnames(classes.left, classes.text, classes.blackText)}
                      >
                        {tableData?.reasonOption &&
                          textOrUnknown(capitalize(tableData?.reasonOption))}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Typography className={classnames(classes.text, classes.grayText)}>
                        Remarks:
                      </Typography>
                      <Typography
                        className={classnames(classes.left, classes.text, classes.blackText)}
                      >
                        {tableData?.reasonRemarks &&
                          textOrUnknown(capitalize(tableData?.reasonRemarks))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            {isLab && isResume && (
              <>
                <Divider />

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.updateInvoice}
                  onClick={handleUpdateInvoice}
                >
                  UPDATE INVOICE
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
