import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TaskActionTable from "./TaskActionTable";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 10,
    width: "fit-content",
    paddingRight: 30,
  },
}));

const Tasks = ({ taskData = {}, handleActionClick }) => {
  const classes = useStyles();

  const handleOpenItem = ({ masterWicId }) => {
    window.open(`/supplier/items/${masterWicId}`);
  };

  return (
    <Box display="flex" className={classes.tableContainer}>
      <TaskActionTable
        type={taskData.type}
        tableTitle={taskData.tableTitle}
        totalNumber={taskData.totalNumber}
        rowData={taskData.rowData}
        handleRowClick={handleOpenItem}
        handleActionClick={handleActionClick}
      />
    </Box>
  );
};

export default Tasks;
