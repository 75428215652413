import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import DatePicker from "components/input/DatePicker";
import MultipleSelect from "components/input/MultipleSelect";
import Select from "components/input/Select";
import Upload from "components/input/Upload";

import { ratnUpdateCurrentTaskReport } from "state/submissionDetail/action";

import { LAB_REPORT_UPLOAD_RESULTS, PB_STATUS } from "shared/constants";

import {
  getPbLabel,
  isPbNotExisted,
  isShowProgram777,
  isTaskFRID,
  isTaskSPUD,
  isValidPbStatusForFRI,
  isValidPbStatusForSPU,
} from "services/pbService";

const useStyles = makeStyles(() => ({
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 20,
    width: "100%",
  },
  uploadButton: {
    width: "100%",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  pbNotExistedWrapper: {
    display: "flex",
    flexFlow: "row wrap",
  },
  pbNotExisted: {
    flex: "0 0 auto",
  },
}));

const UploadReportAndResult = ({ pb: pbList = [], onSubmit, disabled = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { NOT_EXISTED } = PB_STATUS;

  const taskDetail = useSelector((s) => s.submissionDetail.currentTaskDetail);
  const taskType = useSelector((s) => s.submissionDetail.currentTaskDetail.taskType);

  const [supplierPb, setSupplierPb] = useState([]);
  const [pbOptions, setPbOptions] = useState([]);

  const showProgram777 = isShowProgram777(taskDetail?.triggeredBy);

  useEffect(() => {
    if (showProgram777 && pbList) {
      let pbFilteredOptions = [];

      const pbFilteredTypeList = pbList.filter((item) => {
        return isTaskSPUD(taskType)
          ? isValidPbStatusForSPU(item)
          : isTaskFRID(taskType)
          ? isValidPbStatusForFRI(item)
          : pbList;
      });

      for (let i = 0; i < pbFilteredTypeList.length; i++) {
        const pbLabel = getPbLabel(pbFilteredTypeList[i]);

        const isPBNotInTask = taskDetail.pb.every((task) => pbFilteredTypeList[i].id !== task.id);

        pbFilteredOptions.push({
          label: pbLabel,
          value: pbFilteredTypeList[i].id,
          disabled: isPBNotInTask || isPbNotExisted(pbFilteredTypeList[i]),
        });
      }

      setSupplierPb(taskDetail.pb);
      setPbOptions(pbFilteredOptions);
    }
  }, [taskDetail, pbList]);

  const hasReport = () => taskDetail?.reports?.length > 0;

  const hasPbNotExistedInReport = () => {
    for (let j = 0; j < taskDetail.reports.length; j++) {
      return taskDetail.reports[j].pb.some((pb) => pb.pbStatus === NOT_EXISTED);
      break;
    }
  };

  const isMissingData = () => {
    let isMissing = false;

    for (let i = 0; i < taskDetail.reports.length; i++) {
      if (
        !taskDetail.reports[i].pb.length ||
        !taskDetail.reports[i].result ||
        !taskDetail.reports[i].issueDate
      ) {
        isMissing = true;
        break;
      }
    }

    return isMissing;
  };

  const handleRemoveFile = (index) => {
    let updatedTaskDetail = taskDetail;
    updatedTaskDetail.reports = updatedTaskDetail.reports.filter((item, pos) => pos !== index);

    dispatch(ratnUpdateCurrentTaskReport(updatedTaskDetail.reports));
  };

  const handleAddFile = (newFiles) => {
    let updatedTaskDetail = taskDetail;
    updatedTaskDetail.reports = [...updatedTaskDetail.reports, ...newFiles];

    dispatch(ratnUpdateCurrentTaskReport(updatedTaskDetail.reports));
  };

  const handleUploadReport = (newFiles) => {
    let files = [];

    for (let i = 0; i < newFiles.length; i++) {
      files.push({
        fileId: newFiles[i].id,
        fileName: newFiles[i].name,
        pb: [],
        result: "",
        issueDate: "",
      });
    }

    handleAddFile(files);
  };

  const handlePbsChange = (e, index) => {
    const { value } = e.target;

    const pbData = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < supplierPb.length; j++) {
        const isMatchedId = value[i] === supplierPb[j].id;

        //block unselected not existed pb
        if (isMatchedId && isPbNotExisted(supplierPb[j])) return;

        if (isMatchedId) {
          pbData.push(supplierPb[j]);
        }
      }
    }

    let updatedTaskDetail = taskDetail;
    updatedTaskDetail.reports = updatedTaskDetail.reports.map((item, pos) => {
      if (pos === index) {
        item["pb"] = pbData;
      }
      return item;
    });

    dispatch(ratnUpdateCurrentTaskReport(updatedTaskDetail.reports));
  };

  const handleReportDataChange = (e, index) => {
    const { name, value } = e.target;

    let updatedTaskDetail = taskDetail;
    updatedTaskDetail.reports = updatedTaskDetail.reports.map((item, pos) => {
      if (pos === index) {
        item[name] = value;
      }
      return item;
    });

    dispatch(ratnUpdateCurrentTaskReport(updatedTaskDetail.reports));
  };

  const handleSubmitClick = () => {
    if (isMissingData()) {
      return alert("missing data");
    }

    onSubmit();
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Update Inspection Report</Box>

      <Paper className={classes.container}>
        <Box className={classes.title}>Reports & Results</Box>

        <Box className={classes.subtitleSection}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              Report Documents
            </Grid>
            <Grid item xs={3}>
              PB Information
            </Grid>
            <Grid item xs={2}>
              Result
            </Grid>
            <Grid item xs={3}>
              Issue Date
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>

        <Divider />

        {taskDetail.reports &&
          taskDetail.reports.map((item, index) => {
            const selectedPb =
              item?.pb?.length > 0
                ? item.pb.map((item) => {
                    return {
                      label: getPbLabel(item),
                      value: item.id,
                    };
                  })
                : [];

            const hasPbNotExisted = [];

            item?.pb?.length > 0 &&
              item.pb.map((cur) => {
                if (cur.pbStatus === NOT_EXISTED) {
                  hasPbNotExisted.push(getPbLabel(cur));
                }
              });

            return (
              <Grid key={item.fileId} container spacing={2} className={classes.rowContainer}>
                <Grid item xs={3}>
                  {item.fileName}
                </Grid>

                <Grid item xs={3}>
                  <MultipleSelect
                    label="Choose PB"
                    name="pb"
                    value={selectedPb || []}
                    options={pbOptions}
                    handleChange={(e) => handlePbsChange(e, index)}
                    additionalClass={classes.pbSelectField}
                    formControlClass={classes.inputFormControl}
                  />

                  {hasPbNotExisted.length > 0 && (
                    <Box className={classnames(classes.error, classes.pbNotExistedWrapper)}>
                      Not existed:{" "}
                      {hasPbNotExisted.map((pbNumber, idx) => {
                        const getComma = idx === 0 || idx === hasPbNotExisted.length ? ", " : "";

                        return (
                          <span
                            className={classes.pbNotExisted}
                            key={idx}
                          >{`${pbNumber}${getComma}`}</span>
                        );
                      })}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Select
                    formControlClass={classes.inputFormControl}
                    additionalClass={classes.inputFormControl}
                    label="Result"
                    name="result"
                    value={item.result}
                    options={LAB_REPORT_UPLOAD_RESULTS}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    additionalClass={classes.inputFormControl}
                    name="issueDate"
                    value={item.issueDate}
                    handleChange={(e) => handleReportDataChange(e, index)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemoveFile(index)} className={classes.deleteBtn}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

        <Box className={classes.addButtonContainer}>
          <Upload
            buttonLabel="UPLOAD REPORT(S)"
            handleAddFiles={handleUploadReport}
            className={classes.uploadButton}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={disabled || !hasReport() || isMissingData() || hasPbNotExistedInReport()}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default memo(UploadReportAndResult);
