import _orderBy from "lodash/orderBy";
import moment from "moment";

export const getBuyerCompanyTeams = (data) => {
  const programData = data?.reduce((acc, cur) => {
    if (!cur?.buyerCompanyTeamPrograms?.length) return acc;

    const list = cur?.buyerCompanyTeamPrograms?.map((item) => ({
      label: item?.programName,
      value: item?.id,
    }));

    acc.push(...list);

    return acc;
  }, []);

  return data?.map((item) => ({
    label: item?.name,
    value: item?.id,
    programs: _orderBy(programData, "label", "asc"),
  }));
};

export const getProgramYears = () => {
  const year = moment().year();
  const month = moment().month();
  const startYear = month < 6 ? year : year + 1;

  const programYearData = [];

  for (let i = 0; i < 3; i++) {
    programYearData.push({
      label: startYear + i,
      value: startYear + i,
    });
  }

  return programYearData;
};
