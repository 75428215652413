import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 0,
    borderBottom: "1px solid #AAAAAA",
  },
  tabHeader: {
    minHeight: 40,
    height: 40,
  },
  tab: {
    minWidth: 120,
    padding: "0px 10px",
    minHeight: 40,
    height: 40,
    textTransform: "none",
    fontSize: 10,
  },
}));

const SubmissionListTab = ({ tab, progressNumber, handleTabChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabHeader}
      >
        <Tab label={`All(${progressNumber.all || 0})`} className={classes.tab} />
        <Tab
          label={`Waiting For Lab Quote(${progressNumber.waitingForLabQuote || 0})`}
          className={classes.tab}
        />
        <Tab label={`Ongoing(${progressNumber.onGoing || 0})`} className={classes.tab} />
        <Tab label={`Completed(${progressNumber.completed || 0})`} className={classes.tab} />
      </Tabs>
    </Paper>
  );
};

export default SubmissionListTab;
