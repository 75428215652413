import React from "react";
import MuiTableFooter from "@material-ui/core/TableFooter";
import MuiTableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

const TableFooter = ({ data = [], func }) => {
  return (
    <MuiTableFooter>
      <MuiTableRow>
        {data.map((item, index) => (
          <MuiTableCell key={index} variant="footer">
            <Box>{item}</Box>
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </MuiTableFooter>
  );
};

export default TableFooter;
