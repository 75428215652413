import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import auth from "../../../auth/authenticator";
import CommonHeader from "./CommonHeader";
import BuyerHeader from "./BuyerHeader";
import BuyerSourcingHeader from "./BuyerSourcingHeader";
import SupplierHeader from "./SupplierHeader";
import LabHeader from "./LabHeader";
import LabAdminHeader from "./LabAdminHeader";
import AdminHeader from "./AdminHeader";

const Header = ({ history }) => {
  const [role, setRole] = useState(auth.loginRole());

  useEffect(() => {
    setRole(auth.loginRole());
  }, [history.location.pathname]);

  return (
    <>
      {!role && <CommonHeader />}

      {role === "admin" && <AdminHeader />}

      {role === "buyer" && <BuyerHeader />}

      {role === "buyer_sourcing" && <BuyerSourcingHeader />}

      {role === "supplier" && <SupplierHeader />}

      {role === "lab" && <LabHeader />}

      {role === "lab_admin" && <LabAdminHeader />}
    </>
  );
};

export default withRouter(Header);
