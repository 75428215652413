import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import TaskDetail from "./TaskDetail";

import SelectTask from "../components/SelectTask";
import SelectLab from "../components/SelectLab";

import PayerDetail from "../../components/PayerDetail";

import { atnGetOpenTaskLabs, atnOpenTask } from "actions/taskActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const OpenSamplePickUpTask = ({ history, taskDetails = {}, relatedTasks = [] }) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [filteredRelatedTasks, setFilteredRelatedTasks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [openTaskData, setOpenTaskData] = useState({
    tasks: [taskDetails],
    inspectionTaskDetail: {
      expectedSamplePickUpDate: "",
    },
    masterWics: [],
    labIds: [],
    isSkipQuote: false,
    billingAddress: {
      invoiceType: "",
      currency: "",
      payerCompanyName: "",
      payerCompanyAddress: "",
      payerContactPerson: "",
      payerEmail: "",
      payerPhoneNumber: "",
      attachments: [],
      taxInformationFiles: [],
      taxRegistrationCode: "",
      enterpriseName: "",
      address: "",
      bankName: "",
      contactNumber: "",
      bankAddress: "",
    },
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);

  // for open task with tendering program
  // update handleGetLabs func with state
  const [tenderingMatchedLabs, setTenderingMatchedLabs] = useState([]);

  const titleData = [
    "Select Task(s) for Share Cost",
    "Enter Task Detail",
    "Select Lab for Quotation",
    "Enter Payer Detail for Invoice",
  ];

  const handleTenderingMatchedLab = (matchedTenderingLabs) => {
    setTenderingMatchedLabs(matchedTenderingLabs);
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = relatedTasks.filter(
        (item, i) =>
          item.wicNumber.indexOf(value) >= 0 ||
          item.taskNumber.indexOf(value) >= 0 ||
          item.productName.indexOf(value) >= 0,
      );

      setFilteredRelatedTasks(tempTasks);
    } else {
      setFilteredRelatedTasks(relatedTasks);
    }
  };

  const handleOpenTaskDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setOpenTaskData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabs = (data) => {
    atnGetOpenTaskLabs(data).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setOpenTaskData((prevState) => ({
        ...prevState,
        labIds: [],
      }));
    });
  };

  useEffect(() => {
    const stringifyTenderingLabs = !!tenderingMatchedLabs?.length
      ? tenderingMatchedLabs?.toString()
      : "";

    handleGetLabs({
      taskType: taskDetails.taskType,
      buyerProgramId: taskDetails.buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleConfirmTaskSelect = () => {
    let oldMasterWics = openTaskData.masterWics;

    if (oldMasterWics.length > 0) {
      let masterWicIds = []; // 新data所有 wic id
      let newMasterWicWithTasks = []; // 新data所有 wic with task

      for (const task of openTaskData.tasks) {
        if (masterWicIds.indexOf(task.masterWicId) === -1) {
          masterWicIds.push(task.masterWicId);
          newMasterWicWithTasks.push({
            masterWicId: task.masterWicId,
            wicNumber: task.wicNumber,
            productName: task.productName,
            tasks: [
              {
                taskType: task.taskType,
              },
            ],
            masterWicFiles: task.masterWicFiles,
            // po: task.po,
            po: [],
            pb: task.pb || [],
            buyerCompanyName: task.buyerCompanyName,
            category: task.category,
          });
        } else {
          for (const newMasterWicWithTask of newMasterWicWithTasks) {
            if (newMasterWicWithTask.masterWicId === task.masterWicId) {
              newMasterWicWithTask.tasks.push({
                taskType: task.taskType,
              });
              break;
            }
          }
        }
      }

      let newMasterWics = []; // 舊data已經有的master wic update相關task
      let newMasterWicIds = []; // 舊data已經有的master wic update相關task

      for (const oldMasterWic of oldMasterWics) {
        if (masterWicIds.indexOf(oldMasterWic.masterWicId) >= 0) {
          for (const newMasterWicWithTask of newMasterWicWithTasks) {
            if (newMasterWicWithTask.masterWicId === oldMasterWic.masterWicId) {
              oldMasterWic.tasks = newMasterWicWithTask.tasks;
              break;
            }
          }

          newMasterWics.push(oldMasterWic);
          newMasterWicIds.push(oldMasterWic.masterWicId);
        }
      }

      // 加新的master wic
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (const newMasterWicWithTask of newMasterWicWithTasks) {
        if (newMasterWicIds.indexOf(newMasterWicWithTask.masterWicId) === -1) {
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (const masterWicFile of newMasterWicWithTask.masterWicFiles) {
            const isMatchType = (type) => masterWicFile.type === type;

            const dataObj = {
              id: masterWicFile.id,
              name: masterWicFile.fileName,
            };

            if (isMatchType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMatchType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMatchType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMatchType("e2open")) {
              e2open.push(dataObj);
            }
          }

          newMasterWics.push({
            expanded: false,
            masterWicId: newMasterWicWithTask.masterWicId,
            wicNumber: newMasterWicWithTask.wicNumber,
            productName: newMasterWicWithTask.productName,
            tasks: newMasterWicWithTask.tasks,
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation: aisFactoryInformation,
            e2open: e2open,
            isProgram777: newMasterWicWithTask.isProgram777,
            triggeredBy: newMasterWicWithTask.triggeredBy,
            masterWicFiles: newMasterWicWithTask.masterWicFiles,
            // po: newMasterWicWithTask.po,
            po: [],
            pb: newMasterWicWithTask.pb || [],
            buyerCompanyName: newMasterWicWithTask.buyerCompanyName,
            category: newMasterWicWithTask.category,
          });
        }
      }

      setOpenTaskData((prevState) => ({
        ...prevState,
        masterWics: newMasterWics,
      }));
    } else {
      let masterWicIds = [];
      let masterWics = [];
      let aisUPCInformation = [];
      let aisOuterInner = [];
      let aisFactoryInformation = [];
      let e2open = [];

      for (const task of openTaskData.tasks) {
        if (masterWicIds.indexOf(task.masterWicId) === -1) {
          aisUPCInformation = [];
          aisOuterInner = [];
          aisFactoryInformation = [];
          e2open = [];

          for (const masterWicFile of task.masterWicFiles) {
            const isMatchType = (type) => masterWicFile.type === type;

            const dataObj = {
              id: masterWicFile.id,
              name: masterWicFile.fileName,
            };

            if (isMatchType("ais_stibo_upc_information")) {
              aisUPCInformation.push(dataObj);
            } else if (isMatchType("ais_stibo_outer_inner")) {
              aisOuterInner.push(dataObj);
            } else if (isMatchType("ais_stibo_factory_information")) {
              aisFactoryInformation.push(dataObj);
            } else if (isMatchType("e2open")) {
              e2open.push(dataObj);
            }
          }

          masterWicIds.push(task.masterWicId);
          masterWics.push({
            expanded: false,
            masterWicId: task.masterWicId,
            wicNumber: task.wicNumber,
            productName: task.productName,
            tasks: [
              {
                taskType: task.taskType,
              },
            ],
            aisUPCInformation: aisUPCInformation,
            aisOuterInner: aisOuterInner,
            aisFactoryInformation: aisFactoryInformation,
            e2open: e2open,
            isProgram777: task.isProgram777,
            triggeredBy: task.triggeredBy,
            masterWicFiles: task.masterWicFiles,
            // po: task.po,
            po: [],
            pb: task.pb || [],
            buyerCompanyName: task.buyerCompanyName,
            category: task.category,
          });
        } else {
          for (const masterwic of masterWics) {
            if (masterwic.masterWicId === task.masterWicId) {
              masterwic.tasks.push({
                taskType: task.taskType,
              });
            }
          }
        }
      }

      setOpenTaskData((prevState) => ({
        ...prevState,
        masterWics: masterWics,
      }));
    }

    setStep(step + 1);
  };

  const getConvertedTasks = () => {
    let tasks = openTaskData.tasks;

    for (const task of tasks) {
      for (const masterwic of openTaskData.masterWics) {
        const isMatchId = masterwic.masterWicId === task.masterWicId;

        if (isMatchId) {
          //po
          if (task.category === "everyday") {
            task.spuForEverydayTaskType = masterwic.spuForEverydayTaskType;
            task.dailyPo = masterwic.po;
            delete task.po;
          } else {
            task.po = masterwic.po;
            delete task.dailyPo;
          }

          //pb
          task.pb = masterwic.pb;

          break;
        }
      }
    }

    return tasks;
  };

  const getConvertedMasterWicFiles = () => {
    let masterWics = [];
    let masterWicFiles = [];

    for (const masterwic of openTaskData.masterWics) {
      masterWicFiles = [];

      for (const upcInfo of masterwic.aisUPCInformation) {
        masterWicFiles.push({
          id: upcInfo.id,
          type: "ais_stibo_upc_information",
        });
      }

      for (const outerInner of masterwic.aisOuterInner) {
        masterWicFiles.push({
          id: outerInner.id,
          type: "ais_stibo_outer_inner",
        });
      }

      for (const factoryInfo of masterwic.aisFactoryInformation) {
        masterWicFiles.push({
          id: factoryInfo.id,
          type: "ais_stibo_factory_information",
        });
      }

      for (const e2o of masterwic.e2open) {
        masterWicFiles.push({
          id: e2o.id,
          type: "e2open",
        });
      }

      masterWics.push({
        id: masterwic.masterWicId,
        masterWicFiles: masterWicFiles,
      });
    }

    return masterWics;
  };

  const handleSubmit = () => {
    setIsDisabledConfirmButton(true);

    let billingAddress = openTaskData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (openTaskData.billingAddress.invoiceType === "icw_company_invoice") {
      delete billingAddress.attachments;
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (openTaskData.billingAddress.invoiceType !== "icw_company_invoice") {
      let taxInformationFiles = [];

      for (const attachment of openTaskData.billingAddress.attachments) {
        taxInformationFiles.push({
          id: attachment.id,
          type: "tax_information",
        });
      }

      billingAddress.taxInformationFiles = taxInformationFiles;
      // delete billingAddress.attachments;
    }

    let selectedLabs = [];

    for (const labId of openTaskData.labIds) {
      selectedLabs.push({
        id: labId,
      });
    }

    let submitData = {
      isSkipQuote: openTaskData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: getConvertedTasks(),
      masterWics: getConvertedMasterWicFiles(),
      inspectionTaskDetail: {
        expectedSamplePickUpDate: moment(
          openTaskData.inspectionTaskDetail.expectedSamplePickUpDate,
        ).format("YYYY-MM-DD"),
      },
    };

    atnOpenTask(submitData).then(({ data, message }) => {
      if (data) {
        setIsDisabledConfirmButton(false);
        history.push(`/supplier/submissions/${data.id}`);
      } else {
        setIsDisabledConfirmButton(false);
        alert(message);
      }
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header step={step} handlePrevPage={handlePrevPage} handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <SelectTask
            openTaskData={openTaskData}
            relatedTasks={searchKeyword ? filteredRelatedTasks : relatedTasks}
            searchKeyword={searchKeyword}
            handleSearchKeywordChange={handleSearchKeywordChange}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleGetLabs={handleGetLabs}
            handleNextPage={handleConfirmTaskSelect}
            handleTenderingMatchedLab={handleTenderingMatchedLab}
          />
        )}

        {step === 1 && (
          <TaskDetail
            openTaskData={openTaskData}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleNextPage={handleConfirmTaskSelect}
          />
        )}

        {step === 2 && (
          <SelectLab
            openTaskData={openTaskData}
            labs={labs}
            handleOpenTaskDataChange={handleOpenTaskDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 3 && (
          <PayerDetail
            isDisabledConfirmButton={isDisabledConfirmButton}
            data={openTaskData}
            handleDataChange={handleOpenTaskDataChange}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(null, {
  atnOpenTask,
  atnGetOpenTaskLabs,
})(OpenSamplePickUpTask);
