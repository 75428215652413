import { useState } from "react";

export default function useLoading() {
  const [isLoading, setLoading] = useState(false);

  const loading = () => {
    setLoading(true);
  };

  const done = () => {
    setLoading(false);
  };

  return { isLoading, loading, done };
}
