import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import Divider from "components/display/Divider";
import Status from "components/display/Status";
import DateItem from "pages/common/DateItem";
import { TASK_DATE_TYPE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    // transition: 'all .5s ease-in-out',

    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      // transform: 'scale(1.05)',
    },
  },
  disabledItemWrapper: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    transition: "all .5s ease-in-out",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  itemActivated: {
    borderLeft: "4px solid #196EFF",
  },
  itemTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemStatus: {
    minWidth: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginLeft: theme.spacing(0.5),
    backgroundColor: "#CCCCCC",
  },
  circleCompleted: {
    backgroundColor: "#1BB135",
  },
  circleCancelled: {
    backgroundColor: "#D42600",
  },
  circleInProgress: {
    backgroundColor: "#FFD12E",
  },
  mainText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
  disabledMainText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#AAAAAA",
  },
  subText: {
    fontSize: 10,
    color: "#777777",
  },
  disabledSubText: {
    fontSize: 10,
    color: "#AAAAAA",
  },
  divider: {
    margin: `${theme.spacing(1)}px -${theme.spacing(1)}px`,
  },
}));

const TaskList = ({ userType, data, func }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { list } = data;
  const { onChange } = func;

  const [activeItem, setActiveItem] = React.useState(0);

  const handleChange = (id, index) => {
    onChange(id);
    setActiveItem(index);
  };

  const Item = ({
    id,
    taskRequirementId,
    taskType,
    taskStatus: status,
    wicNo,
    wbaDeadline,
    plannedDate,
    labStatus,
    index,
    assignTo,
  }) => {
    return (
      <Paper
        className={classnames(classes.itemWrapper, {
          [classes.itemActivated]: activeItem === index,
        })}
        onClick={() => handleChange(id, index)}
      >
        <Box className={classes.itemTitle}>
          {labStatus === "rejected" ? (
            <Typography className={classes.disabledMainText}>
              {t(`task.${taskType}`)} (Closed)
            </Typography>
          ) : (
            <Typography className={classes.mainText}>{t(`task.${taskType}`)}</Typography>
          )}
          <Box className={classes.itemStatus}>
            {labStatus === "rejected" ? (
              <Status
                value={"closed"}
                className={{
                  root: classes.itemStatus,
                  text: classes.disabledSubText,
                }}
                withText
                withIcon
              />
            ) : (
              <Status
                value={status}
                className={{ root: classes.itemStatus, text: classes.subText }}
                withText
                withIcon
              />
            )}
          </Box>
        </Box>
        {labStatus !== "rejected" && (
          <>
            <Divider className={classes.divider} />
            <Box className={classes.itemDescription}>
              <Typography className={classes.subText}>{wicNo}</Typography>
              <DateItem value={wbaDeadline} type={TASK_DATE_TYPE.deadLine} />
              <DateItem
                value={plannedDate}
                isEditable={false}
                type={TASK_DATE_TYPE.plannedDate}
                taskRequirementId={taskRequirementId}
                userType={userType}
              />
            </Box>
          </>
        )}
        {assignTo && (
          <Typography className={classes.subText}>Assign To: {t(`user.${assignTo}`)}</Typography>
        )}
      </Paper>
    );
  };

  return (
    <Box>
      {list.map((item, index) => (
        <Item key={index} {...item} index={index} />
      ))}
    </Box>
  );
};

export default TaskList;
