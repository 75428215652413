import React from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "components/display/Chip";
import Table from "components/table/Table";
import Grid from "@material-ui/core/Grid";
import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";

import { downloadFile } from "utils/files";
import FileUpload from "components/input/FileUpload";

import SampleSealingResultTable from "./SampleSealingResultTable";

import {
  atnDownloadTrfFile,
  atnDownloadTUVTrfFile,
  atnDownloadULTrfFile,
} from "actions/fileActions";
import ReportDetails from "pages/common/ReportDetails";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  title2: {
    fontSize: 12,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  leftButton: {
    paddingRight: 20,
  },
  trf: {
    cursor: "pointer",
    color: "#005EFF",
  },
  gridContainer: {
    padding: "16px 0",
  },
  gridItem: {
    fontSize: 10,
    border: "1px solid #777777",
    padding: 10,
    "&:first-child": {
      borderRight: "none",
    },
  },
}));

const TaskDetailContent = ({
  taskId,
  submissionId,
  category,
  userType,
  assignTo,
  createdAt,
  taskType,
  taskNumber,
  masterWicId,
  pendingReason,
  taskFiles,
  lab,
  icl,
  lastUpdated,
  labStatus,
  reports = [],
  pacCert = [],
  po = [],
  pb = [],
  selectedLabs = [],
  expectedCompletionDate,
  sampleReceivedDate,
  inspectionTaskDetails,
  sampleSealingResultData,
  editSampleSealingResultData,
  firstShipmentTotalQuantity,
  fullPoTotalQuantity,
  handleResultDataChange,
  handleAddResultData,
  handleRemoveResultData,
  handleCostDialogOpen,
  status,
  completedStatus,
  isUpdateResult,
  handleCancelUpdateResult,
  handleConfirmUpdateResult,
  handleUpdateResultClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDownloadTrfFile = (taskId, fileName) => {
    if (fileName === "F-Admin-Booking_Form-WBA-210104-4.xlsx") {
      atnDownloadTUVTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    } else if (fileName === "Generic Inspection Request Form Rev15 Protect.xlsm") {
      atnDownloadULTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    } else {
      atnDownloadTrfFile({ taskId, fileName }).then(async (data) => {
        downloadFile(data, fileName);
      });
    }
  };

  const detailsTableColumn = [
    {
      columnId: "title",
      width: "30%",
      render: (data) => {
        return data.title;
      },
    },
    {
      columnId: "value",
      render: ({ title, value }) => {
        if (title === "TRF Document") {
          return value.trfDocument && value.taskId ? (
            <Box
              className={classes.trf}
              onClick={() => handleDownloadTrfFile(value.taskId, value.trfDocument)}
            >
              {value.trfDocument}
            </Box>
          ) : (
            textOrUnknown(value.trfDocument)
          );
        } else if (title === "Supporting Documents") {
          return value.supportingDocuments ? (
            <FileUpload data={value.supportingDocuments} editable={false} multiple />
          ) : (
            textOrUnknown(value.supportingDocuments)
          );
        }

        return value;
      },
    },
  ];

  let detailsTableData = [];

  if (selectedLabs && selectedLabs.length > 0) {
    detailsTableData.push({
      id: uuid(),
      title: "Designated Labs",
      value: selectedLabs.map((item) => <Chip key={item.id} label={item.labName} />),
    });
  }

  if (taskType === "SS") {
    detailsTableData.push({
      id: uuid(),
      title: "Responsible Party",
      value: assignTo ? t(`user.${assignTo}`) : "N/A",
    });
  } else {
    detailsTableData.push({
      id: uuid(),
      title: "Date",
      value: textOrUnknown(getDateFormat(createdAt)),
    });

    detailsTableData.push({
      id: uuid(),
      title: "Task ID",
      value: taskNumber,
    });
  }

  let trfDocument = "";

  if (lab.indexOf("BV") >= 0) {
    if (
      taskType === "SPU" ||
      taskType === "SPUD" ||
      taskType === "DPI" ||
      taskType === "FRI" ||
      taskType === "FRID"
    ) {
      trfDocument = "WBA_SD001_WI_I_BOOKING_FORM_11_May_2020_FOR_PLATFORM_USE.doc";
    } else if (
      taskType === "QT" ||
      taskType === "PST" ||
      taskType === "ST" ||
      taskType === "STD" ||
      taskType === "PAC"
    ) {
      trfDocument = "WALG-GB-TRFS-GB-GENERAL-TESTING-V17.doc";
    }
  } else if (lab.indexOf("SGS") >= 0) {
    if (taskType === "SPU" || taskType === "SPUD") {
      trfDocument = "WBA_Sampling_Booking.xlsx";
    } else if (
      taskType === "QT" ||
      taskType === "PST" ||
      taskType === "ST" ||
      taskType === "STD" ||
      taskType === "PAC"
    ) {
      trfDocument = "SGS_Walgreens_TRF_Apr.2019.doc";
    }
  } else if (lab.indexOf("UL-sample sealing") >= 0) {
    if (
      taskType === "SPU" ||
      taskType === "SPUD" ||
      taskType === "DPI" ||
      taskType === "FRI" ||
      taskType === "FRID" ||
      taskType === "SS"
    ) {
      trfDocument = "Generic Inspection Request Form Rev15 Protect.xlsm";
    }
  } else if (lab.indexOf("UL") >= 0) {
    if (
      taskType === "SPU" ||
      taskType === "SPUD" ||
      taskType === "DPI" ||
      taskType === "FRI" ||
      taskType === "FRID"
    ) {
      trfDocument = "Generic Inspection Request Form Rev15 Protect.xlsm";
    } else if (
      taskType === "QT" ||
      taskType === "PST" ||
      taskType === "ST" ||
      taskType === "STD" ||
      taskType === "PAC"
    ) {
      trfDocument = "Walgreens_TRF.docx";
    }
  } else if (lab.indexOf("TUV SUD") >= 0) {
    if (taskType === "FRI" || taskType === "FRID") {
      trfDocument = "F-Admin-Booking_Form-WBA-210104-4.xlsx";
    }
  }

  detailsTableData.push({
    id: uuid(),
    title: "TRF Document",
    value: { trfDocument, taskId },
  });

  if (pendingReason) {
    detailsTableData.push({
      id: uuid(),
      title: "Pending Reason",
      value: pendingReason,
    });
  }

  if (taskFiles && taskFiles.length > 0) {
    let supportingDocuments = [];

    for (let i = 0; i < taskFiles.length; i++) {
      if (taskFiles[i].type === "supporting_document") {
        supportingDocuments.push({
          id: taskFiles[i].id,
          fileName: taskFiles[i].fileName,
          fileUrl: taskFiles[i].location,
        });
      }
    }

    detailsTableData.push({
      id: uuid(),
      title: "Supporting Documents",
      value: { supportingDocuments },
    });
  }

  if (expectedCompletionDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Completion Date",
      value: expectedCompletionDate,
    });
  }

  if (sampleReceivedDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Sample Received Date",
      value: sampleReceivedDate,
    });
  }

  if (inspectionTaskDetails && inspectionTaskDetails.expectedInspectionDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Inspection Date",
      value: inspectionTaskDetails.expectedInspectionDate,
    });
  }

  if (inspectionTaskDetails && inspectionTaskDetails.expectedSamplePickUpDate) {
    detailsTableData.push({
      id: uuid(),
      title: "Expected Sample Pick Up Date",
      value: inspectionTaskDetails.expectedSamplePickUpDate,
    });
  }

  if (
    (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") &&
    inspectionTaskDetails &&
    inspectionTaskDetails.inspectionLevel
  ) {
    detailsTableData.push({
      id: uuid(),
      title: "Inspection Level",
      value: t(`inspectionLevel.${inspectionTaskDetails.inspectionLevel}`),
    });

    detailsTableData.push({
      id: uuid(),
      title: "AQL Level",
      value: (
        <>
          <div>AQL Level(Critical) - {inspectionTaskDetails.aqlCritical}</div>
          <div>AQL Level(Major) - {inspectionTaskDetails.aqlMajor}</div>
          <div>AQL Level(Minor) - {inspectionTaskDetails.aqlMinor}</div>
        </>
      ),
    });
  }

  if (
    po.length > 0 &&
    (taskType === "DPI" ||
      taskType === "FRI" ||
      taskType === "FRID" ||
      taskType === "SPU" ||
      taskType === "SPUD")
  ) {
    let purchaseOrder = [];
    // let firstTotalPoQuantity = 0;
    // let totalPoQuantity = 0;
    let totalQuantityAvailableForInspection = 0;
    let inspectionPercentage = 0;

    po.map((item) => {
      purchaseOrder.push(item);
      totalQuantityAvailableForInspection += parseInt(item.poQuantity || "0");
    });

    if (totalQuantityAvailableForInspection && fullPoTotalQuantity) {
      inspectionPercentage = (
        (totalQuantityAvailableForInspection / fullPoTotalQuantity) *
        100
      ).toFixed(2);
    }

    if (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") {
      detailsTableData.push({
        id: uuid(),
        title: "1st Shipment Total PO Quantity",
        value: parseInt(firstShipmentTotalQuantity || "0"),
      });

      detailsTableData.push({
        id: uuid(),
        title: "Total P.O. Quantity",
        value: parseInt(fullPoTotalQuantity || "0"),
      });
    }

    detailsTableData.push({
      id: uuid(),
      title: "Purchase Order",
      value: (
        <>
          <Grid container>
            <Grid item xs={3}>
              PO Number
            </Grid>
            {category === "everyday" ? (
              <>
                <Grid item xs={3}>
                  Po Quantity
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  Po Quantity in Units
                </Grid>
                <Grid item xs={3}>
                  Actual Quantity in Pieces
                </Grid>
              </>
            )}

            <Grid item xs={3}>
              {taskType === "SPU" || taskType === "SPUD" ? "Qty Ready for Inspection" : "Case Pack"}
            </Grid>
          </Grid>
          {purchaseOrder.map((item) => (
            <Grid key={item.id} container>
              <Grid item xs={3}>
                {item.poNumber}
              </Grid>
              {category !== "everyday" && (
                <Grid item xs={3}>
                  {item.poQuantityInUnits}
                </Grid>
              )}
              <Grid item xs={3}>
                {item.poQuantity}
              </Grid>
              <Grid item xs={3}>
                {taskType === "SPU" || taskType === "SPUD"
                  ? item.quantityReadyForInspection
                  : item.casePack}
              </Grid>
            </Grid>
          ))}
        </>
      ),
    });

    if (taskType === "DPI" || taskType === "FRI" || taskType === "FRID") {
      detailsTableData.push({
        id: uuid(),
        title: "Total Quantity Available for Inspection",
        value: totalQuantityAvailableForInspection,
      });

      detailsTableData.push({
        id: uuid(),
        title: "Inspection Percentage",
        value: `${inspectionPercentage} %`,
      });
    }
  }

  if (pb.length > 0 && (taskType === "FRID" || taskType === "SPUD")) {
  }

  if (taskType === "SS") {
    // setSampleSealingResultData(sampleSealingResults);
  }

  return (
    <>
      <Typography className={classes.title2}>Task Details</Typography>
      <Box mt={2} mb={6}>
        <Table data={detailsTableData} columnData={detailsTableColumn} noHeader noPagination />
      </Box>

      <Box py={2}>
        <Typography className={classes.title2}>ICL Number</Typography>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridItem}>
            ICL Number
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem}>
            {icl}
          </Grid>
        </Grid>
      </Box>

      {labStatus !== "rejected" && taskType !== "SS" && (
        <>
          <Typography className={classes.title2}>Report Details</Typography>
          <Box mt={2} className={classes.reportsTable}>
            <ReportDetails
              data={{ reports, certifications: pacCert }}
              status={status}
              completedStatus={completedStatus}
              submissionId={submissionId}
              taskType={taskType}
            />
            <Box pt={4} display="flex" justifyContent="space-between">
              <Typography className={classes.text}>
                Last Updated: <br />
                {moment(lastUpdated).format("DD/MMM/YYYY HH:mm:ss")}
              </Typography>
              {/* {userType === USER_TYPE.supplier && (
                <Button variant='outlined' disabled>
                  View Reporting Information
                </Button>
              )} */}
            </Box>
          </Box>
        </>
      )}

      {taskType === "SS" && (
        <>
          <Box display="flex">
            <Box display="flex" flexGrow={1} className={classes.title2}>
              Result
            </Box>
          </Box>

          <Box mt={2} className={classes.reportsTable}>
            <SampleSealingResultTable
              isUpdateResult={false}
              sampleSealingResultData={sampleSealingResultData}
              editSampleSealingResultData={editSampleSealingResultData}
              handleResultDataChange={handleResultDataChange}
              handleAddResultData={handleAddResultData}
              handleRemoveResultData={handleRemoveResultData}
            />
            <Box pt={4} display="flex" justifyContent="space-between">
              <Typography className={classes.text}>
                Last Updated: <br />
                {moment(lastUpdated).format("DD/MMM/YYYY HH:mm:ss")}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default TaskDetailContent;
