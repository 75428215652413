import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ReactTable from "components/table/ReactTable";
import classTable from "./css/style.module.scss";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "1100px",
    padding: "20px",
    display: "inline-block",
    boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
    color: "#000000",
  },

  title: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 50,
  },
  subtitle: {
    fontSize: 10,
    color: "#777777",
    marginTop: 25,
  },
  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",
    marginTop: 25,
  },
  hint: {
    display: "flex",
    marginTop: 30,
  },
  between: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#003DA5",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  over: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#D42600",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  below: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
    marginRight: 30,

    "& span": {
      width: 15,
      height: 15,
      background: "#1BB135",
      borderRadius: "50%",
      marginRight: 20,
    },
  },
  viewList: {
    fontSize: "14px",
    color: "#005EFF",
    textAlign: "center",
    marginTop: "45px",
    fontWeight: "500",
    cursor: "pointer",
  },
  arrowIcon: {
    position: "absolute",
    left: 20,
    top: 13,
    cursor: "pointer",

    "& svg": {
      fontSize: 32,
    },
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  avgOver: {
    fontWeight: 900,
    color: "#D42600",
    background: "#FFDDD6",

    "&:hover": {
      background: "#eeeeee",
    },
  },
  avgBelow: {
    fontWeight: 900,
    color: "#1BB135",
    background: "#F4FEEF",

    "&:hover": {
      background: "#eeeeee",
    },
  },
  avgBetween: {
    fontWeight: 900,
    color: "#003DA5",
    background: "#E5ECF6",

    "&:hover": {
      background: "#eeeeee",
    },
  },
}));

const TopAverage = ({ handleTabChange, tenAvgPerSupplier }) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const noSortFilter = { canSort: false, canFilter: false };

  const tableColumn = [
    {
      id: "ranking",
      value: "index",
      label: "Ranking",
      ...noSortFilter,
      render: ({ cell: { value: index } }) => {
        return <Box>{index}</Box>;
      },
    },
    {
      id: "supplier",
      value: "name",
      label: "Supplier",
      ...noSortFilter,
      render: ({ cell: { value: name } }) => {
        return <Box>{name}</Box>;
      },
    },
    {
      id: "average",
      value: "rateInUnit",
      label: "Average 1506%",
      ...noSortFilter,
      render: ({ cell: { value: rateInUnit } }) => {
        return (
          <Box
            className={
              parseFloat(rateInUnit) > 2
                ? classes.avgOver
                : parseFloat(rateInUnit) <= 1
                ? classes.avgBelow
                : classes.avgBetween
            }
          >
            {rateInUnit === null || rateInUnit === "" ? "-" : parseFloat(rateInUnit) + "%"}
          </Box>
        );
      },
    },
    {
      id: "averageUnit",
      value: "sellThroughRate",
      label: "Average IOH Unit ST%",
      ...noSortFilter,
      render: ({ cell: { value: sellThroughRate } }) => {
        return (
          <Box>
            {sellThroughRate === null || sellThroughRate === ""
              ? "-"
              : parseFloat(parseFloat(sellThroughRate).toFixed(3))}
            %
          </Box>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.arrowIcon} onClick={toggleShow}>
        {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box className={classes.title}>Top 10 Average 1506% in unit Per Supplier</Box>

      <Box className={show ? classes.show : classes.hide}>
        <Box className={classes.subtitle}>Average 1506% Color Guideline</Box>

        <Box className={classes.hint}>
          <Box className={classes.over}>
            <span></span>Over 2%
          </Box>
          <Box className={classes.between}>
            <span></span>Between 1-2%
          </Box>
          <Box className={classes.below}>
            <span></span>Below 1%
          </Box>
        </Box>

        <Box className={classes.boxTable}>
          <ReactTable
            columns={tableColumn}
            data={tenAvgPerSupplier}
            classes={{
              root: classTable.topAverage,
              viewColumnIcon: classTable.viewColumnIcon,
            }}
            noPagingBottom={true}
          />
        </Box>

        <Box className={classes.viewList} onClick={() => handleTabChange(0, 1, 0)}>
          VIEW ON LIST
        </Box>
      </Box>
    </Box>
  );
};

export default TopAverage;
