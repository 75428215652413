import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SearchableMultiSelect from "components/input/SearchableMultiSelect";
import Pagination from "components/table/Pagination";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    padding: "26px 30px",
    whiteSpace: "nowrap",
    minWidth: 600,
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 26,
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    maxWidth: "85%",
  },
  topButton: {
    position: "absolute",
    top: 26,
    right: 30,
    color: "#003DA5",
    fontSize: 14,
    fontWeight: 500,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    minWidth: 110,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    borderBottom: "1px solid #777777",
    borderRight: "1px solid #777777",
    "&:last-child": {
      borderRight: "none",
    },
  },
  tableText: {
    maxWidth: 170,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  headerLabel: {
    float: "left",
  },
  filterContainer: {
    padding: 0,
  },
  filter: {
    color: "#000000",
    width: 18,
    height: 18,
  },
  sortingContainer: {
    position: "relative",
    paddingLeft: 2,
    cursor: "pointer",
  },
  sortingContainerWithMargin: {
    position: "relative",
    paddingLeft: 2,
    marginRight: 30,
    cursor: "pointer",
  },
  sortingUp: {
    position: "absolute",
    top: 0,
    width: 15,
    height: "auto",
  },
  sortingDown: {
    position: "absolute",
    top: 7,
    width: 15,
    height: "auto",
  },
  selected: {
    color: "#196EFF",
  },
  filterMenuContainer: {
    position: "absolute",
    top: 40,
    right: -90,
  },
  lastFilterMenuContainer: {
    position: "absolute",
    top: 40,
    right: 0,
  },
}));

const TaskTable = ({
  tableTitle = "",
  headerData = [],
  rowData = [],
  handleRowClick,
  hasTopButton,
  topButtonLabel,
  handleTopButtonClick,
  handleSorting,
  handleFilterListToggle,
  handleFilterItemSelect,
  selectedFilter,
  currentPage,
  rowsPerPage,
  totalPages,
  totalRecords,
  rowsPerPageOption,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openActionMenu, setOpenActionMenu] = useState(false);
  const anchorRef = useRef(null);

  const prevOpen = useRef(openActionMenu);
  useEffect(() => {
    if (prevOpen.current === true && openActionMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openActionMenu;
  }, [openActionMenu]);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      {tableTitle && <Typography className={classes.title}>{tableTitle}</Typography>}

      {hasTopButton && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.topButton}
          onClick={handleTopButtonClick}
        >
          {topButtonLabel}
        </Button>
      )}

      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell key={item.name} className={classes.tableHeaderCell}>
                <Box display="flex">
                  <Box flexGrow={1} display="flex" className={classes.headerLabel}>
                    <Box className={classes.tableText}>{item.label}</Box>

                    {item.hasSorting && (
                      <Box
                        display="flex"
                        className={
                          item.hasFilter
                            ? classes.sortingContainerWithMargin
                            : classes.sortingContainer
                        }
                        onClick={() => handleSorting(index, item.name, item.sorting)}
                      >
                        <ExpandLessIcon
                          className={classnames(classes.sortingUp, {
                            [classes.selected]: item.sorting === 1,
                          })}
                        />
                        <ExpandMoreIcon
                          className={classnames(classes.sortingDown, {
                            [classes.selected]: item.sorting === 2,
                          })}
                        />
                      </Box>
                    )}
                  </Box>
                  {item.hasFilter && (
                    <Box>
                      <IconButton
                        className={classes.filterContainer}
                        onClick={() => handleFilterListToggle(index)}
                      >
                        <FilterListIcon
                          className={classnames(classes.filter, {
                            [classes.selected]: item.selectedFilter.length > 0,
                          })}
                        />
                      </IconButton>

                      {item.openFilterList && (
                        <Box
                          className={
                            index === headerData.length - 1
                              ? classes.lastFilterMenuContainer
                              : classes.filterMenuContainer
                          }
                        >
                          <SearchableMultiSelect
                            filterName={item.name}
                            filterItems={item.filterItems}
                            selectedFilter={selectedFilter}
                            handleFilterItemSelect={handleFilterItemSelect}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row) => (
            <TableRow
              key={row.id}
              className={classes.tableRow}
              onClick={() => handleRowClick(row.masterWicId)}
            >
              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{row.supplierName}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{row.wicNumber}</Box>
                <Box className={classnames(classes.info, classes.tableText)}>{row.productName}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{row.labName || "N/A"}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{t(`task.${row.taskType}`)}</Box>
                <Box className={classnames(classes.info, classes.tableText)}>{row.taskNumber}</Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>
                  {row.deadLine ? moment(row.deadLine).format("DD/MMM/YYYY") : "N/A"}
                </Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>
                  {row.plannedDate ? moment(row.plannedDate).format("DD/MMM/YYYY") : "N/A"}
                </Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>
                  {row.shipByDate ? moment(row.shipByDate).format("DD/MMM/YYYY") : "N/A"}
                </Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>
                  {row.ecd ? moment(row.ecd).format("DD/MMM/YYYY") : "N/A"}
                </Box>
              </TableCell>

              <TableCell scope="row" align="left" className={classes.tableCell}>
                <Box className={classes.tableText}>{t(`taskStatus.${row.taskStatus}`)}</Box>
              </TableCell>

              {/* {(row.data || []).map(item => (
                <TableCell key={item.name} scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.tableText}>{item.value}</Box>
                  {item.info &&
                    <Box className={classnames(classes.info, classes.tableText)}>{item.info}</Box>
                  }
                </TableCell>
              ))} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        rowsPerPageOption={rowsPerPageOption}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default TaskTable;
