import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Header from "./Header";
import SelectTask from "../components/SelectTask";
import SelectLab from "../components/SelectLab";
import PayerDetail from "../../components/PayerDetail";
import TrfForm from "./TrfForm/index";

import { atnGetOpenTaskLabs } from "actions/taskActions";
import { atnEditSubmission } from "actions/submissionActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const EditTestingTask = ({
  history,
  submissionId,
  basicData = {},
  submissionTasks = [],
  relatedTasks = [],
  billingAddress = {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [filteredRelatedTasks, setFilteredRelatedTasks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [originalSubmissionData, setOriginalSubmissionData] = useState({
    tasks: JSON.parse(JSON.stringify(submissionTasks)),
    labIds: JSON.parse(JSON.stringify(basicData.labIds)),
    billingAddress: JSON.parse(JSON.stringify(billingAddress)),
  });
  const [editSubmissionData, setEditSubmissionData] = useState({
    tasks: submissionTasks,
    labIds: basicData.labIds,
    isSkipQuote: false,
    billingAddress: billingAddress,
  });
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [labs, setLabs] = useState([]);

  const [payerDetailErrors, setPayerDetailErrors] = useState([]);

  // for open task with tendering program
  // update handleGetLabs func with state
  const [tenderingMatchedLabs, setTenderingMatchedLabs] = useState([]);

  const titleData = [
    "Select Task(s) for Share Cost",
    "Select Lab for Quotation",
    "Enter Payer Detail for Invoice",
    "Fill in TRF Information to Confirm Quote",
  ];

  const handleTenderingMatchedLab = (matchedTenderingLabs) => {
    setTenderingMatchedLabs(matchedTenderingLabs);
  };

  const handleSearchKeywordChange = (e) => {
    const { value } = e.target;

    setSearchKeyword(value);

    if (value) {
      let tempTasks = relatedTasks.filter(
        (item, i) =>
          item.wicNumber.indexOf(value) >= 0 ||
          item.taskNumber.indexOf(value) >= 0 ||
          item.productName.indexOf(value) >= 0,
      );

      setFilteredRelatedTasks(tempTasks);
    } else {
      setFilteredRelatedTasks(relatedTasks);
    }
  };

  const handleEditSubmissionDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setEditSubmissionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabs = (data) => {
    atnGetOpenTaskLabs(data).then(({ data, message }) => {
      let labData = [];
      let labDataIndex = 0;
      let hasSameLabName = false;

      for (let i = 0; i < data.length; i++) {
        hasSameLabName = false;

        for (let j = 0; j < labData.length; j++) {
          if (labData[j].labName === data[i].labName) {
            hasSameLabName = true;
            labDataIndex = j;
          }
        }

        if (hasSameLabName) {
          labData[labDataIndex].branches.push({
            id: data[i].id,
            labBranchName: data[i].labBranchName,
          });
        } else {
          labData.push({
            labName: data[i].labName,
            branches: [
              {
                id: data[i].id,
                labBranchName: data[i].labBranchName,
              },
            ],
          });
        }
      }

      setLabs(labData);
      setEditSubmissionData((prevState) => ({
        ...prevState,
        labIds: basicData.labIds,
      }));
    });
  };

  useEffect(() => {
    const stringifyTenderingLabs = !!tenderingMatchedLabs?.length
      ? tenderingMatchedLabs?.toString()
      : "";

    handleGetLabs({
      taskType: basicData.taskType,
      buyerProgramId: basicData.buyerProgramId,
      labCompanyId: stringifyTenderingLabs,
    });
  }, []);

  const handlePrevPage = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleSubmit = () => {
    setPayerDetailErrors([]);
    setIsDisabledConfirmButton(true);

    // let deletedFiles = [];
    let billingAddress = editSubmissionData.billingAddress;

    if (isNewBillingAddress) {
      delete billingAddress.id;
    }

    if (editSubmissionData.billingAddress.invoiceType === "icw_company_invoice") {
      billingAddress.attachments = [];
      delete billingAddress.taxInformationFiles;
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType === "plain_vat_invoice") {
      delete billingAddress.taxRegistrationCode;
      delete billingAddress.enterpriseName;
      delete billingAddress.address;
      delete billingAddress.bankName;
      delete billingAddress.contactNumber;
      delete billingAddress.bankAddress;
    }

    if (editSubmissionData.billingAddress.invoiceType !== "icw_company_invoice") {
      let newFileIds = [];
      let tempTaxInformationFiles = [];

      for (let i = 0; i < editSubmissionData.billingAddress.attachments.length; i++) {
        newFileIds.push(editSubmissionData.billingAddress.attachments[i].id);

        tempTaxInformationFiles.push({
          id: editSubmissionData.billingAddress.attachments[i].id,
          type: "tax_information",
        });
      }

      // for (let i = 0; i < editSubmissionData.billingAddress.taxInformationFiles.length; i++) {
      //   if (newFileIds.indexOf(editSubmissionData.billingAddress.taxInformationFiles[i].id) === -1) {
      //     deletedFiles.push({
      //       fileId: editSubmissionData.billingAddress.taxInformationFiles[i].id
      //     });
      //   }
      // }

      billingAddress.taxInformationFiles = tempTaxInformationFiles;

      // delete billingAddress.attachments;
    }

    let selectedLabs = [];
    let newLabs = [];
    let existingLabs = [];

    for (let i = 0; i < editSubmissionData.labIds.length; i++) {
      if (basicData.labIds.indexOf(editSubmissionData.labIds[i]) === -1) {
        selectedLabs.push({
          id: editSubmissionData.labIds[i],
          isNew: true,
        });
        newLabs.push(editSubmissionData.labIds[i]);
      } else {
        selectedLabs.push({
          id: editSubmissionData.labIds[i],
          isNew: false,
        });
        existingLabs.push(editSubmissionData.labIds[i]);
      }
    }

    let changes = [];

    if (editSubmissionData.tasks.length !== originalSubmissionData.tasks.length) {
      let oldTasks = "";
      let newTasks = "";

      for (let i = 0; i < originalSubmissionData.tasks.length; i++) {
        oldTasks +=
          originalSubmissionData.tasks[i].wicNumber +
          "(" +
          t(`task.${originalSubmissionData.tasks[i].taskType}`) +
          ")\n";
      }

      for (let i = 0; i < editSubmissionData.tasks.length; i++) {
        newTasks +=
          editSubmissionData.tasks[i].wicNumber +
          "(" +
          t(`task.${editSubmissionData.tasks[i].taskType}`) +
          ")\n";
      }

      changes.push({
        field: "Share Cost Tasks",
        action: "Update",
        before: oldTasks,
        after: newTasks,
      });
    }

    let submitData = {
      submissionId: submissionId,
      isSkipQuote: editSubmissionData.isSkipQuote,
      billingAddress: billingAddress,
      selectedLabs: selectedLabs,
      tasks: editSubmissionData.tasks,
      newLabs: newLabs,
    };

    if (changes.length > 0) {
      submitData.changes = changes;
      submitData.existingLabs = existingLabs;
    }

    atnEditSubmission(submitData).then((res) => {
      setIsDisabledConfirmButton(false);

      if (!res?.data) return setPayerDetailErrors([res?.message]);

      history.push(`/supplier/submissions/${res?.data.id}`);
    });
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header step={step} handlePrevPage={handlePrevPage} handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>{titleData[step]}</Box>

        {step === 0 && (
          <SelectTask
            editSubmissionData={editSubmissionData}
            relatedTasks={searchKeyword ? filteredRelatedTasks : relatedTasks}
            searchKeyword={searchKeyword}
            handleSearchKeywordChange={handleSearchKeywordChange}
            basicData={basicData}
            handleEditSubmissionDataChange={handleEditSubmissionDataChange}
            handleGetLabs={handleGetLabs}
            handleNextPage={handleNextPage}
            handleTenderingMatchedLab={handleTenderingMatchedLab}
          />
        )}

        {step === 1 && (
          <SelectLab
            editSubmissionData={editSubmissionData}
            labs={labs}
            basicData={basicData}
            handleEditSubmissionDataChange={handleEditSubmissionDataChange}
            handleNextPage={handleNextPage}
          />
        )}

        {step === 2 && (
          <PayerDetail
            isTestingTask
            isDisabledConfirmButton={isDisabledConfirmButton}
            errors={payerDetailErrors}
            testingData={basicData}
            data={editSubmissionData}
            handleDataChange={handleEditSubmissionDataChange}
            handleNextPage={handleNextPage}
            handleSubmit={handleSubmit}
          />
        )}

        {step === 3 && (
          <TrfForm
            errors={payerDetailErrors}
            submissionId={submissionId}
            tasks={editSubmissionData.tasks}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditTestingTask;
