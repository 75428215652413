import React from "react";
import _isEmpty from "lodash/isEmpty";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import InvoiceItem from "./InvoiceItem";
import PayerInfo from "pages/common/PayerInfo";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hideButton: {
    display: "none",
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: 40,
    marginBottom: 40,
    borderBottom: "1px solid #000000",
    "&:last-child": {
      marginBottom: 0,
      borderBottom: "none",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  right: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#777777",
  },
  prepaymentContainer: {
    marginLeft: 10,
    padding: "2px 10px",
    borderRadius: 4,
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
  },
  downloadBtn: {
    marginTop: 20,
  },
  boxTotal: {
    height: 57,
    background: "#E5ECF6",
    border: "1px solid #777777",
    padding: "0 30px",
    marginTop: 20,
  },
  textTotal: {
    fontSize: 16,
    color: "#000000",
    fontWeight: "bold",
  },
  totalNumber: {
    color: "#003DA5",
    fontWeight: "bold",
    fontSize: 16,
  },
  textUpdate: {
    fontSize: 10,
    color: "#777777",
  },
}));

const Invoice = ({ payerInfo, data, taskType }) => {
  const classes = useStyles();

  let prepaymentData = {
    hasPrepayment: false,
    prepaymentPrice: 0,
  };

  let totalAll = 0;
  for (let i = 0; i < data.length; i++) {
    totalAll += data[i].totalPrice;
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].isPrepayment) {
      prepaymentData = {
        hasPrepayment: true,
        prepaymentPrice: data[i].totalPrice,
      };
      break;
    }
  }

  const NoDataFound = () => <NoDataBox>No invoice data</NoDataBox>;

  return (
    <React.Fragment>
      <InvoiceWrapperBox data-cy="invoice-tab">
        {data.length > 0 ? (
          <Box overflow="hidden">
            {data?.map((invoice, index) => (
              <Paper key={invoice.id} className={classes.container}>
                {!_isEmpty(invoice) ? (
                  <InvoiceItem
                    key={index}
                    item={invoice}
                    index={index}
                    taskType={taskType}
                    prepaymentData={prepaymentData}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Paper>
            ))}
          </Box>
        ) : (
          <NoDataFound />
        )}

        {payerInfo && (
          <WrapperPaper>
            <PayerInfo payerInfo={payerInfo} />
          </WrapperPaper>
        )}
      </InvoiceWrapperBox>
    </React.Fragment>
  );
};

export default Invoice;

const NoDataBox = withStyles({
  root: {
    marginBottom: "16px",
  },
})((props) => <Box {...props} />);

const InvoiceWrapperBox = withStyles({
  root: {
    margin: "24px",
  },
})((props) => <Box {...props} />);

const WrapperPaper = withStyles({
  root: {
    padding: "24px",
  },
})((props) => <Paper elevation={2} {...props} />);
