import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import PbWicDetail from "./PbWicDetail";

import { ratnExpandAllPbWicDetail } from "state/submissionDetail/action";

import { isShowProgram777 } from "services/pbService";

const useStyles = makeStyles(() => ({
  expandAll: {
    cursor: "pointer",
    color: "#005EFF",
    fontSize: 10,
  },
  modal: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 104px) !important",
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  titleBorder: {
    borderBottom: "1px solid #003DA5",
  },
  contentText: {
    padding: "16px 0",
  },
  PbWicDetailWrapper: {
    flexFlow: "column",
  },
}));

const PbRevisionModal = ({
  title = "Revise PB",
  open,
  onClose,
  onSubmit,
  isLoading = false,
  fullWidth = true,
  maxWidth = "lg",
  className,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const submissionTasks = useSelector((s) => s.submissionDetail.tasks);

  const [isExpandAll, setIsExpandAll] = useState(false);

  const onExpandAll = () => {
    setIsExpandAll(!isExpandAll);
  };

  useEffect(() => {
    open && dispatch(ratnExpandAllPbWicDetail(isExpandAll));
  }, [isExpandAll, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={classnames(classes.modal, className)}
      {...props}
    >
      <DialogTitle className={classes.titleBorder}>{title}</DialogTitle>

      <DialogContent>
        <Box display="flex" className={classes.contentText}>
          <Box flexGrow={1} className={classnames(classes.title)}>
            Task Information (Per Wic Item)
          </Box>

          <Box className={classnames(classes.expandAll)} onClick={onExpandAll}>
            expand all
          </Box>
        </Box>

        <Box display="flex" className={classes.PbWicDetailWrapper}>
          {submissionTasks &&
            submissionTasks.map((task, index) => {
              const showProgram777 = isShowProgram777(task.triggeredBy);

              if (showProgram777) {
                return <PbWicDetail key={task.id} masterWic={task} masterWicIndex={index} />;
              } else {
                return null;
              }
            })}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>

        <Button onClick={onSubmit} variant="contained" color="primary" disabled={isLoading}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PbRevisionModal);
