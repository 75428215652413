import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import MultiSelectAll from "components/analytics/MultiSelectAll";

import Modal from "components/modal/Modal";
import GifLoading from "components/display/GifLoading";
import FinancialYearPopup from "./FinancialYearPopup";

import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import TuneIcon from "@material-ui/icons/Tune";

import Dashboard from "./Dashboard";
import ListTable from "./List";

import {
  atnGetAnalyticsBasicTable,
  atnGetAnalyticsChart,
  atnGetAnalyticsSpendingTable,
  atnGetAnalyticsTotal,
  atnGetAvgSellThroughPerSupplier,
  atnGetAvgUnitValue,
  atnGetFilterList,
  atnGetFinancialList,
  atnGetTable1506WithType,
  atnGetTop10SpendingSupplier,
  atnGetTopTenAvgPerSupplier,
  atnGetTotalItemQuanityClaim,
} from "actions/analyticsActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  leftSideFilter: {
    width: 300,
    padding: "0 10px 100px",
    boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
    background: "white",
  },

  rightMainContain: {
    width: "calc(100% - 300px)",
    paddingBottom: 500,
  },

  container: {
    float: "left",
    boxShadow: "none",
  },

  boxMainTitle: {
    borderBottom: "solid 1px #000000",
    position: "fixed",
    width: 280,
    background: "white",
    zIndex: 99,

    "& svg": {
      color: "#777777",
    },
  },

  mainTitle: {
    display: "block",
    fontSize: 14,
    color: "#777777",
    padding: "12px 0 12px 10px",
    fontWeight: 500,
  },

  title: {
    display: "block",
    fontSize: 12,
    lineHeight: "16px",
    color: "#232B3A",
    marginTop: 15,
    marginBottom: 6,
  },

  thirdBox: {
    marginBottom: 30,
    position: "fixed",
    overflowY: "auto",
    height: "100%",
  },

  fourBox: {
    borderTop: "1px solid #AAAAAA",
    textAlign: "right",
    paddingTop: 10,
    paddingBottom: 20,
    position: "fixed",
    bottom: 0,
    width: 280,
    background: "white",
  },

  applyBtn: {
    marginLeft: 15,
  },

  boxFinancialYear: {
    border: "1px solid #C4C8D0",
    boxSizing: "border-box",
    borderRadius: 4,
    height: 40,
    padding: "6px 8px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  mainYear: {
    paddingRight: 10,
    borderRight: "1px solid black",
  },

  rangeYear: {
    paddingLeft: 10,
    opacity: 0.7,
  },

  calendarIcon: {
    position: "absolute",
    right: 8,
    opacity: 0.7,
  },

  groupClearBtn: {
    float: "right",
    marginTop: 50,
  },

  textClear: {
    "& span": {
      color: "red",
    },
  },

  wrapperTab: {
    width: "100%",
    boxShadow: "none",
    height: 84,
    alignItems: "center",
    display: "flex",
    background: "none",
    borderBottom: "1px solid #C4C8D0",
    borderRadius: 0,
  },

  tabHeader: {
    "& .MuiTabs-indicator": {
      height: 0,
    },
  },

  tab: {
    textTransform: "capitalize",
    fontSize: 14,
    color: "#AAAAAA",

    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "#005EFF",

      "& svg": {
        color: "#005EFF",
      },
    },

    "&:nth-child(2)": {
      "&.MuiTab-textColorPrimary.Mui-selected": {
        color: "#003DA5",

        "& svg": {
          color: "#003DA5",
        },
      },
    },

    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },

    "& svg": {
      marginRight: 12,
      marginBottom: "0 !important",
    },
  },

  groupMoreExpand: {
    cursor: "pointer",
    "& svg": {
      fill: "#003DA5",
    },
  },

  textExpand: {
    color: "#003DA5",
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 10,
  },

  fixDashboard: {
    position: "fixed",
    width: "100%",
    background: "#fafafa",
    zIndex: 99,
  },
}));

const Analytics = () => {
  const classes = useStyles();

  const customStyles = {
    container: () => ({
      width: 280,
      border: "1px solid #003DA5",
      borderRadius: "4px",
    }),

    option: (provided, state) => ({
      ...provided,
      color: "#232B3A",
      padding: "5px 10px",
      fontWeight: "normal",
      fontFamily: "Roboto",
      background: "white",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  // ================== STATES - OBJECT FILTER FULL ==================
  const [objFilterFull, setObjFilterFull] = useState({});

  // ================== STATES - CHECK CALL ONLY 1 TIMES ==================
  const [isGetYearFinance, setIsGetYearFinance] = useState(false);
  const [isFullListed, setIsFullListed] = useState(false);
  const [isGetFirstTime, setIsGetFirstTime] = useState(false);

  // ================== STATES - SET LOADING ==================
  const [loadingFilter, setLoadingFiter] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);

  // ================== STATES - SET FINANCIAL YEAR ==================
  const [financialYearInit, setFinancialYearInit] = useState({
    id: 1,
    value: "0",
    startAt: "0",
    endAt: "0",
  });
  const [financialYear, setFinancialYear] = useState({
    id: 1,
    value: "0",
    startAt: "0",
    endAt: "0",
  });
  const [financialYearTotal, setFinancialYearTotal] = useState({
    id: 1,
    value: "0",
    startAt: "0",
    endAt: "0",
  });

  const [financialYearSelected, setFinancialYearSelected] = useState({
    name: "buyerFinancialYear",
    value: [],
  });
  const [listFinancialYear, setListFinancialYear] = useState([
    { id: 1, value: "0", startAt: "0", endAt: "0" },
  ]);

  const cbSetSelectedFinancialYear = (data) => {
    setFinancialYear(data);
    setFinancialYearSelected({ name: "buyerFinancialYear", value: [data.startAt, data.endAt] });
  };

  const cbSetDataListFinancialYearFilter = (data) => {
    setListFinancialYear(data);
  };

  // ================== STATES - TABLE DATA ==================
  const [basicTableData, setBasicTableData] = useState([]);
  const [spendingTableData, setSpendingTableData] = useState([]);

  // ================== STATES - TOTAL SPENDING - DASHBOARD ==================
  const [overAllTotalData, setOverAllTotalData] = useState([]);
  const [totalSpendingPerTask, setTotalSpendingPerTask] = useState([]);
  const [totalSpendingPerLab, setTotalSpendingPerLab] = useState(null);
  const [totalSpendingPerLabOrigin, setTotalSpendingPerLabOrigin] = useState(null);

  // ================== STATES - MAIN TAB DASHBOARD / LIST ==================
  const [selectedTabList, setSelectedTabList] = useState(0);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue, tablistIndex = 0) => {
    setTab(newValue);
    setSelectedTabList(tablistIndex);
    window.scrollTo(0, 0);
  };

  const [openFinancialYearPop, setFinancialYearPop] = useState(false);
  const [openClearDataPop, setClearDataPop] = useState(false);

  const [openSorryPop, setOpenSorryPop] = useState(false);

  // ================== STATES - LIST FILTER GROUP ==================
  const [allValue, setAllValue] = useState(false);

  const [listSupplier, setListSupplier] = useState([]);
  const [listSupplierSelected, setListSupplierSelected] = useState({
    name: "supplierName",
    value: [],
  });

  const cbSetListSupplierSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListSupplierSelected({ name: "supplierName", value: temp.map((item, index) => item.value) });
  };

  const [listFactories, setListFactories] = useState([]);
  const [listFactorySelected, setListFactorySelected] = useState({
    name: "factoryName",
    value: [],
  });

  const cbSetListFactorySelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListFactorySelected({ name: "factoryName", value: temp.map((item, index) => item.value) });
  };

  const [listLab, setListLab] = useState([]);
  const [listLabSelected, setListLabSelected] = useState({ name: "labName", value: [] });

  const cbSetListLabSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListLabSelected({ name: "labName", value: temp.map((item, index) => item.value) });
  };

  const [listBrandType, setListBrandType] = useState([]);
  const [listBrandTypeSelected, setListBrandTypeSelected] = useState({
    name: "brandName",
    value: [],
  });

  const cbSetListBrandTypeSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListBrandTypeSelected({ name: "brandName", value: temp.map((item, index) => item.value) });
  };

  const [listWIC, setListWIC] = useState([]);
  const [listWICSelected, setListWICSelected] = useState({ name: "wicNumber", value: [] });

  const cbSetListWICSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListWICSelected({ name: "wicNumber", value: temp.map((item, index) => item.value) });
  };

  const [listTaskType, setListTaskType] = useState([]);
  const [listTaskTypeSelected, setListTaskTypeSelected] = useState({ name: "taskType", value: [] });

  const cbSetListTaskTypeSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListTaskTypeSelected({ name: "taskType", value: temp.map((item, index) => item.value) });
  };

  const [listProgram, setListProgram] = useState([]);
  const [listProgramSelected, setListProgramSelected] = useState({
    name: "programName",
    value: [],
  });

  const cbSetListProgramSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListProgramSelected({ name: "programName", value: temp.map((item, index) => item.value) });
  };

  const year = moment().year();
  const startYear = 2019;

  const programYearData = [];

  for (let i = startYear; i < year + 3; i++) {
    programYearData.push({
      label: i.toString(),
      value: i,
    });
  }

  const [listProgramYear, setListProgramYear] = useState(programYearData);
  const [listProgramYearSelected, setListProgramYearSelected] = useState({
    name: "programYear",
    value: [],
  });

  const isSelectedAllProgramYear = listProgramYear.length === listProgramYearSelected.value.length;

  const programYearSubtitle = (isSelectedAll, { value: years }) => {
    if (!isSelectedAll && years.length === 1) {
      return years[0];
    } else if (!isSelectedAll && years.length > 1) {
      const sortedYears = years.sort();

      return `${sortedYears[0]} - ${sortedYears[sortedYears.length - 1]}`;
    } else {
      return "All";
    }
  };

  const cbSetListProgramYearSelected = (data) => {
    let temp = [];
    data.length && data[0].value === "*"
      ? (temp = data.filter((item, index) => {
          return item.value !== "*";
        }))
      : (temp = [...data]);
    setListProgramYearSelected({
      name: "programYear",
      value: temp.map((item, index) => item.value),
    });
  };

  // const [listCategorySelected, setListCategorySelected] = useState({name: 'categoryName', value: []});

  // const cbSetListCategories = (data) => {
  //   let temp = [];
  //   data.length && data[0].value === '*' ? temp = data.filter((item, index) => { return item.value !== '*'}) : temp = [...data];
  //   setListCategorySelected({name: 'categoryName', value: temp.map((item, index) => item.value)});
  // }

  // ================== STATES - TOTAL DASHBOARD GROUP ==================
  const [totalMasterWic, setTotalMasterWic] = useState(0);
  const [totalSupplier, setTotalSupplier] = useState(0);
  const [totalFactory, setTotalFactory] = useState(0);
  const [totalProgram, setTotalProgram] = useState(0);

  const [totalBrand, setTotalBrand] = useState(0);
  const [avgSSTimes, setAvgSSTimes] = useState(0);
  const [firstTimePassRateFRI, setFirstTimePassRateFRI] = useState(0);
  const [firstTimePassRateST, setFirstTimePassRateST] = useState(0);

  // ================== Call API - HANDLE Average 1506% in unit / value ==================
  const [avgUnitValue, setAvgUnitValue] = useState({});
  const getAvgUnitValue = (data) => {
    atnGetAvgUnitValue(data).then(({ data, message }) => {
      const value = data || { avgRage: { unitAvgRate: 0, valueAvgRate: 0 }, unit: [], value: [] };
      setAvgUnitValue(value);
    });
  };

  // ================== Call API - HANDLE get Average Sell Through% Per supplier ==================
  const [avgSellPerSupplier, setAvgSellPerSupplier] = useState({});
  const getAvgSellThroughPerSupplier = (data) => {
    atnGetAvgSellThroughPerSupplier(data).then(({ data, message }) => {
      setAvgSellPerSupplier(data);
    });
  };

  // ================== Call API - HANDLE Quantity Claim ==================
  const [objQuantityClaim, setObjQuantityClaim] = useState({});
  const getTotalItemQuanityClaim = (data) => {
    atnGetTotalItemQuanityClaim(data).then(({ data, message }) => {
      setObjQuantityClaim(data || { quantityClaims: [], itemQuantityClaimSum: 0, totalClaim: 0 });
    });
  };

  // ================== Call API - Top 10 Average 1506% in unit Per Supplier ==================
  const [tenAvgPerSupplier, setTenAvgPerSupplier] = useState([]);
  const getTenAvgPerSupplier = (data) => {
    atnGetTopTenAvgPerSupplier(data).then(({ data, message }) => {
      setTenAvgPerSupplier(data);
    });
  };

  // ================== Call API - Top 10 Spending Suppliers ==================
  const [tenSpendingSupplier, setTenSpendingSupplier] = useState([]);
  const getTenSpendingSupplier = (data) => {
    atnGetTop10SpendingSupplier(data).then(({ data, message }) => {
      setTenSpendingSupplier(data && data.list ? data.list : []);
    });
  };

  // ================== Call API - GET 1506 Table ==================
  const [wicTable, setWicTable] = useState([]);
  const [supplierTable, setSupplierTable] = useState([]);
  const [factoryTable, setFactoryTable] = useState([]);

  const getGetTable1506WithType = ({ type, object }) => {
    atnGetTable1506WithType({ type, object }).then(({ data, message }) => {
      const dataReturn = data || [];
      const dataTable = dataReturn.map((item, index) => {
        return {
          ...item,
          rateInUnitNew: item.rateInUnit + "%",
          rateInValueNew: item.rateInValue + "%",
          sellThroughRateNew: item.sellThroughRate + "%",
          customerReturnNew:
            item.customerReturn === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.customerReturn) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          damagedInTransitNew:
            item.damagedInTransit === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.damagedInTransit) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          damagedPackagingNew:
            item.damagedPackaging === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.damagedPackaging) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          dcPoorPackagingNew:
            item.dcPoorPackaging === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.dcPoorPackaging) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          outdatedNew:
            item.outdated === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.outdated) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          authorizedReturnNew:
            item.authorizedReturn === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.authorizedReturn) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          damagedDonatableNew:
            item.damagedDonatable === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.damagedDonatable) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          brokenNew:
            item.broken === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.broken) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          soonToExpireDonateNew:
            item.soonToExpireDonate === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.soonToExpireDonate) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          soonToExpireNew:
            item.soonToExpire === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.soonToExpire) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          damagedNew:
            item.damaged === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.damaged) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          dcRazorCutNew:
            item.dcRazorCut === ""
              ? `-`
              : (
                  Math.round((parseFloat(item.dcRazorCut) + Number.EPSILON) * 100) / 100
                ).toLocaleString(),
          totalClaimNew: (
            Math.round((parseFloat(item.totalClaim) + Number.EPSILON) * 100) / 100
          ).toLocaleString(),
        };
      });

      switch (type) {
        case "wic":
          setWicTable(dataTable);
          break;
        case "supplier":
          setSupplierTable(dataTable);
          break;
        case "factory":
          setFactoryTable(dataTable);
          break;
        default:
          break;
      }
    });
  };

  // ================== Call API - HANDLE get FILTER LIST ==================
  const getALlFilterList = () => {
    // Show loadingFilter popup
    setLoadingFiter(true);

    atnGetFilterList().then(({ data, message }) => {
      handleDataFilterList(data);
      setIsFullListed(true);
      // Hide loadingFilter popup
      setLoadingFiter(false);
    });
  };

  const handleDataFilterList = (data) => {
    let { suppliers, factories, labs, brands, masterWics, taskType, programs } = data;

    //listSupplier
    let tempListSupplier = suppliers.map((item, index) => {
      return { ...item, value: item.name, label: item.name };
    });
    setListSupplier(tempListSupplier);

    //listFactories
    let tempListFactories = factories.map((item, index) => {
      return { ...item, value: item.factoryName, label: item.factoryName };
    });
    setListFactories(tempListFactories);

    //listLab
    let tempListLab = labs.map((item, index) => {
      return { ...item, value: item.name, label: item.name };
    });
    setListLab(tempListLab);

    //listBrandType
    let tempListBrandType = brands.map((item, index) => {
      return { ...item, value: item.name, label: item.name };
    });
    setListBrandType(tempListBrandType);

    //listWIC
    let tempListWIC = masterWics.map((item, index) => {
      return { ...item, value: item.wicNumber, label: item.wicNumber };
    });
    setListWIC(tempListWIC);

    //taskType
    let tempTaskType = taskType.map((item, index) => {
      return { value: item, label: item };
    });
    setListTaskType(tempTaskType);

    //listProgram
    let tempPrograms = programs.map((item, index) => {
      return { ...item, value: item.name, label: item.name };
    });
    setListProgram(tempPrograms);

    //listCategories - wait updating from API ............
  };

  // ================== Call API - HANDLE get ANALYSIS TOTAL ==================
  const getAnalysisTotal = (data) => {
    atnGetAnalyticsTotal(data).then(({ data, message }) => {
      if (data) {
        handleAnalysisTotal(data[0]);
      } else {
        handleAnalysisTotal({
          totalProgram: 0,
          avgSSTimes: 0,
          totalFactory: 0,
          firstTimePassRateFRI: 0,
          totalMasterWic: 0,
          totalBrand: 0,
          firstTimePassRateST: 0,
          totalSupplier: 0,
        });

        setOpenSorryPop(true);
      }
    });
  };

  const handleAnalysisTotal = (data) => {
    let {
      totalProgram,
      avgSSTimes,
      totalFactory,
      firstTimePassRateFRI,
      totalMasterWic,
      totalBrand,
      firstTimePassRateST,
      totalSupplier,
    } = data;

    setTotalMasterWic((Math.round((totalMasterWic + Number.EPSILON) * 100) / 100).toLocaleString());
    setTotalSupplier((Math.round((totalSupplier + Number.EPSILON) * 100) / 100).toLocaleString());
    setTotalFactory((Math.round((totalFactory + Number.EPSILON) * 100) / 100).toLocaleString());
    setTotalProgram((Math.round((totalProgram + Number.EPSILON) * 100) / 100).toLocaleString());

    setTotalBrand((Math.round((totalBrand + Number.EPSILON) * 100) / 100).toLocaleString());
    setAvgSSTimes((Math.round((avgSSTimes + Number.EPSILON) * 100) / 100).toLocaleString());
    setFirstTimePassRateFRI(
      (Math.round((firstTimePassRateFRI * 100 + Number.EPSILON) * 100) / 100).toLocaleString() +
        "%",
    );
    setFirstTimePassRateST(
      (Math.round((firstTimePassRateST * 100 + Number.EPSILON) * 100) / 100).toLocaleString() + "%",
    );
  };

  // ================== Call API - HANDLE get ANALYSIS CHART ==================
  const getAnalysisChart = (data, financialYearInit) => {
    setLoadingChart(true);

    atnGetAnalyticsChart(data).then(({ data, message }) => {
      if (data) {
        handleAnalysisChart(data, financialYearInit);
      } else {
        handleAnalysisChart(
          { overAllTotalData: [], totalSpendingPerTask: [], totalSpendingPerLab: [] },
          financialYearInit,
        );
        setOpenSorryPop(true);
      }
      setLoadingChart(false);
    });
  };

  const handleAnalysisChart = (data, financialYearInit = null) => {
    const { overAllTotalData = [], totalSpendingPerTask = [], totalSpendingPerLab = [] } = data;

    // set over all total data
    let objTotalData = {};
    overAllTotalData.map((item) => (objTotalData[item.labCompanyName] = item));
    setOverAllTotalData(objTotalData);

    setTotalSpendingPerLabOrigin(totalSpendingPerLab);

    let series = ["DPI", "FRI", "PST", "QT", "SPU", "SS", "ST"];

    let tempTask = totalSpendingPerTask.map((item, index) => {
      return { ...item, name: item.taskType, data: item.total };
    });

    let tempSeries = series.map((itemSeries, index) => {
      let data = [];

      for (let i = 0; i < totalSpendingPerLab.length; i++) {
        let temp = totalSpendingPerLab[i].chartItems.filter((itemLab, index) => {
          return itemLab.taskType === itemSeries;
        });

        temp.length ? data.push(temp[0].total) : data.push(0);
      }

      return {
        name: itemSeries,
        data: data,
      };
    });

    let listSumTotalItems = [];
    for (let i = 0; i < totalSpendingPerLab.length; i++) {
      let tempTotal = totalSpendingPerLab[i].chartItems.reduce((total, item, index) => {
        return (total += item.total);
      }, 0);
      listSumTotalItems.push(tempTotal);
    }

    let maxNumber = parseInt(Math.max(...listSumTotalItems) * 1.1);

    let categories = totalSpendingPerLab.map((item, index) => {
      return item.name;
    });

    setTotalSpendingPerTask(tempTask);
    setTotalSpendingPerLab({
      categories: categories,
      series: tempSeries,
      maxNumber: maxNumber,
    });

    setFinancialYearTotal(financialYearInit ? financialYearInit : financialYear);
  };

  // ================== Call API - HANDLE get BASIC TABLE ==================
  const getAnalysisBasicTable = (data) => {
    setLoadingTable(true);

    atnGetAnalyticsBasicTable(data).then(({ data, message }) => {
      if (data) {
        handleAnalysisBasicTable(data);
      } else {
        handleAnalysisBasicTable({ list: [] });
        setOpenSorryPop(true);
      }

      setLoadingTable(false);
    });
  };

  const handleAnalysisBasicTable = (data) => {
    setBasicTableData(data.list);
  };

  // ================== Call API - HANDLE get SPENDING TABLE ==================
  const getAnalysisSpendingTable = (data) => {
    atnGetAnalyticsSpendingTable(data).then(({ data, message }) => {
      if (data) {
        handleAnalysisSpendingTable(data);
      } else {
        setOpenSorryPop(true);
        handleAnalysisSpendingTable([]);
      }
    });
  };

  const handleAnalysisSpendingTable = (data) => {
    setSpendingTableData(data);
  };

  // ================== Call API - HANDLE get LIST FINANCIAL YEAR ==================
  const getListFinancialYear = (data) => {
    atnGetFinancialList(data).then(({ data, message }) => {
      setIsGetYearFinance(true);
      handleListFinancialYear(data);
    });
  };

  const handleListFinancialYear = (data = [{ startAt: "0", value: "2021", id: 1, endAt: "0" }]) => {
    setFinancialYearInit(data[0]);
    setListFinancialYear(data);
    setFinancialYear(data[0]);
    setFinancialYearTotal(data[0]);

    setFinancialYearSelected({
      name: "buyerFinancialYear",
      value: [data[0].startAt, data[0].endAt],
    });
  };

  // ================== Call API FIRST TIME - ==================

  useEffect(() => {
    if (isGetYearFinance && isFullListed) {
      setIsGetFirstTime(true);
    }
  }, [isGetYearFinance, isFullListed]);

  useEffect(() => {
    getListFinancialYear({});
    getALlFilterList({});
  }, []);

  // Parameter customer reason
  const customerReason = {
    name: "customerReason",
    value: [
      "CUSTOMER RETURN",
      "DAMAGED IN TRANSIT",
      "OUTDATED",
      "AUTHORIZED RETURN",
      "DAMAGED-DONATABLE",
      "BROKEN",
      "DC POOR PACKAGING",
      "DC RAZOR CUT",
      "SOON TO EXPIRE",
      "SOON TO EXPIRE-DONATE",
      "DAMAGED",
      "DAMAGED PACKAGING",
    ],
  };

  useEffect(() => {
    let obj = getAllParameters(),
      objCustomerReason = getAllParameters(customerReason);
    setObjFilterFull(obj);

    if (isGetFirstTime) {
      getAnalysisTotal(obj);
      getAnalysisChart(obj);
      getAnalysisBasicTable(obj);
      getAnalysisSpendingTable(obj);
      // Call API 1506
      getAvgUnitValue(obj);
      getAvgSellThroughPerSupplier(obj);
      getTotalItemQuanityClaim(objCustomerReason);
      getTenAvgPerSupplier(obj);
      getTenSpendingSupplier(obj);
      getGetTable1506WithType({ type: "wic", object: obj });
      getGetTable1506WithType({ type: "supplier", object: obj });
      getGetTable1506WithType({ type: "factory", object: obj });
    }
  }, [isGetFirstTime]);

  // ================== APPLY BUTTON CALL AJAX ==================
  const handleApply = () => {
    let obj = getAllParameters(),
      objCustomerReason = getAllParameters(customerReason);

    getAnalysisTotal(obj);
    getAnalysisChart(obj);
    getAnalysisBasicTable(obj);
    getAnalysisSpendingTable(obj);

    // Call API 1506
    getAvgUnitValue(obj);
    getAvgSellThroughPerSupplier(obj);
    getTotalItemQuanityClaim(objCustomerReason);
    getTenAvgPerSupplier(obj);
    getTenSpendingSupplier(obj);
    getGetTable1506WithType({ type: "wic", object: obj });
    getGetTable1506WithType({ type: "supplier", object: obj });
    getGetTable1506WithType({ type: "factory", object: obj });
  };

  const getAllParameters = (reason) => {
    let obj = {
      orderBy: "",
      order: "",
      search: "",
      currentPage: 1,
      rowsPerPage: 999999999,
      filters: [],
    };

    // obj.filters.push(financialYearSelected);
    obj.filters.push(listSupplierSelected);
    obj.filters.push(listFactorySelected);
    obj.filters.push(listLabSelected);
    obj.filters.push(listBrandTypeSelected);
    obj.filters.push(listWICSelected);
    obj.filters.push(listTaskTypeSelected);
    obj.filters.push(listProgramSelected);
    obj.filters.push(listProgramYearSelected);
    // obj.filters.push(listCategorySelected);
    reason && obj.filters.push(reason);
    return obj;
  };

  // ================== CLEAR BUTTON ==================
  const handleClearData = () => {
    setAllValue(!allValue);
    setClearDataPop(!openClearDataPop);

    setFinancialYear(financialYearInit);
    setFinancialYearSelected({
      name: "buyerFinancialYear",
      value: [financialYearInit.startAt, financialYearInit.endAt],
    });

    // Call Apply again after clear filter
    getAnalysisTotal(objFilterFull);
    getAnalysisChart(objFilterFull, financialYearInit);
    getAnalysisBasicTable(objFilterFull);
    getAnalysisSpendingTable(objFilterFull);
  };

  // ================== HANDLE POP FINANCIAL YEAR ==================
  const handlePopFinacialYear = () => {
    setFinancialYearPop(!openFinancialYearPop);
  };

  const handlePopClearData = () => {
    setClearDataPop(!openClearDataPop);
  };

  // ================== HANDLE POP SORRY FILTER ==================
  const handlePopSorry = () => {
    setOpenSorryPop(!openSorryPop);
  };

  // ================== HANDLE EXPAND AND COPPLAPSE ====================
  const [expandFilter, setExpandFilter] = useState(false);

  return (
    <Box className={classes.root}>
      {(loadingFilter || loadingChart || loadingTable) && <GifLoading />}

      <Box display="flex">
        <Box className={classes.leftSideFilter}>
          <Box display="flex" alignItems="center" className={classes.boxMainTitle}>
            <TuneIcon />
            <Typography className={classes.mainTitle}>Filter</Typography>
          </Box>

          {/* <Box display='block' marginTop="62px">
            <Typography className={classes.title}>Financial Year</Typography>
            <Box display='block' className={classes.boxFinancialYear} onClick={() => handlePopFinacialYear()}>
              { financialYear.value !== -1 && <Box component="span" className={classes.mainYear}>{financialYear.value}</Box> }
              <Box component="span" className={classes.rangeYear}>{financialYear.startAt} - {financialYear.endAt}</Box>
              <CalendarTodayIcon className={classes.calendarIcon} />
            </Box>
          </Box> */}

          <Box display="block" className={classes.thirdBox} marginTop="62px">
            <Typography className={classes.title}>Supplier</Typography>
            <MultiSelectAll
              cbSetData={cbSetListSupplierSelected}
              options={listSupplier}
              customStyles={customStyles}
              setAllValue={allValue}
              textAll="Suppliers"
            />

            <Typography className={classes.title}>Factory</Typography>
            <MultiSelectAll
              cbSetData={cbSetListFactorySelected}
              options={listFactories}
              customStyles={customStyles}
              setAllValue={allValue}
              textAll="Factories"
            />

            <Typography className={classes.title}>Program</Typography>
            <MultiSelectAll
              cbSetData={cbSetListProgramSelected}
              options={listProgram}
              customStyles={customStyles}
              setAllValue={allValue}
              textAll="Programs"
            />

            <Typography className={classes.title}>Program Year</Typography>
            <MultiSelectAll
              cbSetData={cbSetListProgramYearSelected}
              options={listProgramYear}
              customStyles={customStyles}
              setAllValue={allValue}
              allValue={true}
              textAll="Program Years"
            />

            {/* <Typography className={classes.title}>Category</Typography>
            <MultiSelectAll cbSetData={cbSetListCategories} options={categoryList.data.list} customStyles={customStyles} setAllValue={allValue} textAll="Categories " /> */}

            <Box
              display="flex"
              paddingTop="15px"
              alignItems="center"
              className={classes.groupMoreExpand}
              onClick={() => setExpandFilter(!expandFilter)}
            >
              {!expandFilter ? <AddIcon /> : <RemoveIcon />}
              <Typography className={classes.textExpand}>
                {!expandFilter ? "More Filter option" : "Less Filter option"}
              </Typography>
            </Box>

            <Box display={expandFilter ? "block" : "none"}>
              <Typography className={classes.title}>Lab</Typography>
              <MultiSelectAll
                cbSetData={cbSetListLabSelected}
                options={listLab}
                customStyles={customStyles}
                setAllValue={allValue}
                textAll="Labs"
              />

              <Typography className={classes.title}>Brand Type</Typography>
              <MultiSelectAll
                cbSetData={cbSetListBrandTypeSelected}
                options={listBrandType}
                customStyles={customStyles}
                setAllValue={allValue}
                textAll="Brand Types"
              />

              <Typography className={classes.title}>WIC</Typography>
              <MultiSelectAll
                cbSetData={cbSetListWICSelected}
                options={listWIC}
                customStyles={customStyles}
                setAllValue={allValue}
                textAll="WIC"
              />

              <Typography className={classes.title}>Task Type</Typography>
              <MultiSelectAll
                cbSetData={cbSetListTaskTypeSelected}
                options={listTaskType}
                customStyles={customStyles}
                setAllValue={allValue}
                textAll="Task Types"
              />
            </Box>
          </Box>

          <Box display="block" className={classes.fourBox}>
            <Button color="primary" onClick={() => handlePopClearData()}>
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.applyBtn}
              onClick={() => handleApply()}
            >
              Apply
            </Button>
          </Box>
        </Box>

        <Box className={classes.rightMainContain}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="84px"
            className={classes.fixDashboard}
          >
            <Paper className={classes.wrapperTab}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabHeader}
              >
                <Tab label="Dashboard" icon={<AppsIcon />} className={classes.tab} />
                <Tab label="List" icon={<ListIcon />} className={classes.tab} />
              </Tabs>
            </Paper>
          </Box>

          <Box display="block" marginTop="84px">
            <TabPanel value={tab} index={0}>
              <Dashboard
                programYearPeriod={programYearSubtitle(
                  isSelectedAllProgramYear,
                  listProgramYearSelected,
                )}
                totalProgram={totalProgram}
                avgSSTimes={avgSSTimes}
                totalFactory={totalFactory}
                firstTimePassRateFRI={firstTimePassRateFRI}
                totalMasterWic={totalMasterWic}
                totalBrand={totalBrand}
                firstTimePassRateST={firstTimePassRateST}
                totalSupplier={totalSupplier}
                avgUnitValue={avgUnitValue}
                avgSellPerSupplier={avgSellPerSupplier}
                objQuantityClaim={objQuantityClaim}
                tenAvgPerSupplier={tenAvgPerSupplier}
                tenSpendingSupplier={tenSpendingSupplier}
                overAllTotalData={overAllTotalData}
                totalSpendingPerTask={totalSpendingPerTask}
                totalSpendingPerLab={totalSpendingPerLab}
                totalSpendingPerLabOrigin={totalSpendingPerLabOrigin}
                financialYearTotal={financialYearTotal}
                handleTabChange={handleTabChange}
              />
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <ListTable
                basicTableData={basicTableData}
                spendingTableData={spendingTableData}
                selectedTabList={selectedTabList}
                tableDataWic={wicTable}
                tableDataSupplier={supplierTable}
                tableDataFactory={factoryTable}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>

      {/* ------------ MODAL FINANCIAL YEAR ------------ */}
      <Modal title="Select Date" open={openFinancialYearPop} handleClose={handlePopFinacialYear}>
        <FinancialYearPopup
          currentFinancialYear={financialYear}
          listFinancialYear={listFinancialYear}
          cbSetData={cbSetSelectedFinancialYear}
          cbSetDataListFinancialYear={cbSetDataListFinancialYearFilter}
          handleClose={handlePopFinacialYear}
        />
      </Modal>
      {/* ------------ END MODAL FINANCIAL YEAR ------------ */}

      {/* ------------ MODAL CLEAR FILTER ------------ */}
      <Modal
        title="Clear the Filter Selection"
        open={openClearDataPop}
        handleClose={handlePopClearData}
        size="small"
      >
        <Box display="block" className={classes.textClear}>
          Do you want to <span>Clear</span> the selection on the Filter.
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button color="primary" onClick={() => handlePopClearData()}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={() => handleClearData()}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
      {/* ------------ END MODAL CLEAR FILTER ------------ */}

      {/* ------------ MODAL CLEAR FILTER ------------ */}
      <Modal
        title="Sorry,  There are no result."
        open={openSorryPop}
        handleClose={handlePopSorry}
        size="small"
      >
        <Box display="block" className={classes.textClear}>
          Please select option item and apply again.
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={() => handlePopSorry()}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
      {/* ------------ END MODAL CLEAR FILTER ------------ */}
    </Box>
  );
};

export default Analytics;
