import React, { useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";
import UploadSingleFile from "components/input/UploadSingleFile";
import OutlinedButton from "components/input/OutlinedButton";
import Textarea from "components/input/Textarea";

import { TESTING_REPORT_RESULTS } from "shared/constants";

import { atnGetTestItems } from "actions/submissionActions";

import useLoading from "hooks/useLoading";

export default function TestingReport({
  submissionId,
  reportData = {},
  onAddNewFilesChange,
  onReportDataChange,
  onRemoveFile,
  onSubmit,
  disabledSubmit,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isLoading, loading, done } = useLoading();

  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [isEditReport, setIsEditReport] = useState(false);

  const [reportIndex, setReportIndex] = useState("");
  const [newReportData, setNewReportData] = useState({
    fileId: "",
    fileName: "",
    issueDate: "",
    remark: "",
    result: "",
    testItems: [],
  });

  const [originalTestItems, setOriginalTestItems] = useState([]);

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    fetchTestItems();
  }, []);

  const fetchTestItems = async () => {
    loading();

    await atnGetTestItems({
      submissionId: submissionId,
    })
      .then(({ data }) => {
        done();
        if (!data) return;

        const items = [];

        for (let i = 0; i < data?.length; i++) {
          items.push({
            invoiceTestItemId: data[i].testItemId,
            itemName: data[i].itemName,
            result: "",
            isSelected: false,
          });
        }

        setOriginalTestItems(items);
      })
      .catch((err) => {
        done();
      });
  };

  const handleReportDialogOpen = () => {
    setNewReportData({
      fileId: "",
      fileName: "",
      issueDate: "",
      remark: "",
      testItems: JSON.parse(JSON.stringify(originalTestItems)),
    });
    setIsEditReport(false);
    setOpenReportDialog(true);
  };

  const resetSubmitError = () => {
    setSubmitError("");
  };

  const handleReportDialogClose = () => {
    setOpenReportDialog(false);
    resetSubmitError();
  };

  const handleUploadReport = (newFile) => {
    setNewReportData((prevState) => ({
      ...prevState,
      fileId: newFile.id,
      fileName: newFile.name,
    }));
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;

    setNewReportData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTestItemSelect = (index) => {
    setNewReportData((prevState) => ({
      ...prevState,
      testItems: prevState.testItems.map((item, i) => {
        if (i === index) {
          item.isSelected = !item.isSelected;
        }

        return item;
      }),
    }));
  };

  const handleTestItemResultChange = (e, index) => {
    const { name, value } = e.target;

    setNewReportData((state) => ({
      ...state,
      testItems: state.testItems.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleEditReport = (index) => {
    let data = reportData.reports[index];
    data.issueDate = moment(data.issueDate).format("YYYY-MM-DD");
    setNewReportData(data);
    setIsEditReport(true);
    setReportIndex(index);
    setOpenReportDialog(true);
  };

  const checkIsMissingData = () => {
    resetSubmitError();

    const isMissingReport = !newReportData?.fileName && !newReportData?.fileId;

    const isMissingDate = !newReportData?.issueDate;

    const hasNotSelectedTestItem = !newReportData?.testItems?.some(
      (testItem) => testItem?.isSelected,
    );

    const isMissingTestItemResult = !newReportData?.testItems?.some((testItem) => testItem?.result);

    switch (true) {
      case isMissingReport:
        setSubmitError("Please upload a report");
        break;
      case isMissingDate:
        setSubmitError("Please fill in report issue date");
        break;
      case hasNotSelectedTestItem:
        setSubmitError("Please select at least 1 test item");
        break;
      case isMissingTestItemResult:
        setSubmitError("Please fill in test item result");
        break;
      default:
        break;
    }

    return isMissingReport || isMissingDate || hasNotSelectedTestItem || isMissingTestItemResult;
  };

  const handleSubmitNewReport = () => {
    if (checkIsMissingData()) return;

    let result = "";

    for (let i = 0; i < newReportData.testItems.length; i++) {
      if (newReportData.testItems[i].result === "fail") {
        result = "fail";
        break;
      } else if (newReportData.testItems[i].result === "reportAsIs") {
        result = "reportAsIs";
      } else if (newReportData.testItems[i].result === "pass") {
        if (result !== "reportAsIs") {
          result = "pass";
        }
      }
    }

    let report = newReportData;
    report.issueDate = moment(report.issueDate).format("YYYY-MM-DD");
    report.result = result;

    if (isEditReport) {
      onReportDataChange(reportIndex, report);
    } else {
      onAddNewFilesChange(report);
    }

    setOpenReportDialog(false);
  };

  const handleSubmitClick = () => {
    if (checkIsMissingData()) return;

    onSubmit();
    resetSubmitError();
  };

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.pageTitle}>Upload Report</Box>

        <Paper className={classes.container}>
          <Box className={classes.title}>Results & Reports</Box>
          <Box className={classes.subtitleSection}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                Report
              </Grid>
              <Grid item xs={3}>
                Overall Result
              </Grid>
              <Grid item xs={3}>
                Issue Date
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>

          <Divider />

          {reportData?.reports?.map((item, index) => (
            <Grid key={item.fileId} container spacing={3} className={classes.rowContainer}>
              <Grid item xs={5}>
                {item.fileName}
              </Grid>
              <Grid item xs={3}>
                {t(`reportResult.${item.result}`)}
              </Grid>
              <Grid item xs={2}>
                {moment(item.issueDate).format("DD/MMM/YYYY")}
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleEditReport(index)} className={classes.deleteBtn}>
                  <EditIcon />
                </IconButton>

                <IconButton onClick={() => onRemoveFile(index)} className={classes.deleteBtn}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Paper>

        <Box className={classes.addButtonContainer}>
          <OutlinedButton
            label="UPLOAD NEW REPORT"
            handleClick={handleReportDialogOpen}
            color="primary"
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={handleSubmitClick}
          disabled={disabledSubmit}
        >
          SUBMIT
        </Button>
      </Box>

      {/* upload report modal */}
      <Dialog open={openReportDialog} onClose={handleReportDialogClose} className={classes.dialog}>
        <MuiDialogTitle>
          <Typography className={classes.dialogTitle}>Upload New Report</Typography>
          <Divider />
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.inputTitle}>1. Upload Report File</Box>

          <Box>
            <UploadSingleFile
              buttonLabel="UPLOAD SUPPORTING DOC"
              handleAddFile={handleUploadReport}
              className={classes.uploadButton}
            />
          </Box>

          {!isLoading && newReportData.fileId && (
            <Box>
              <Box className={classes.fileName}>{newReportData.fileName}</Box>
              <Divider className={classes.divider} />
              <Box className={classes.inputTitle}>2. Issue Date</Box>
              <Box className={classes.subtitle}>Issue Date</Box>
              <DatePicker
                name="issueDate"
                value={newReportData.issueDate}
                handleChange={handleDataChange}
              />
              <Divider className={classes.divider} />
              <Box className={classes.inputTitle}>3. Test Results</Box>

              {newReportData?.testItems?.map((item, index) => (
                <Box
                  key={item?.invoiceTestItemId}
                  display="flex"
                  className={classes.testItemContainer}
                >
                  <Box>
                    <Checkbox
                      checked={item?.isSelected}
                      color="primary"
                      onChange={() => handleTestItemSelect(index)}
                    />
                  </Box>

                  <Box className={classes.testItemName}>{item.itemName}</Box>

                  <Box>
                    <Select
                      label="Select Result"
                      name="result"
                      value={item.result}
                      options={TESTING_REPORT_RESULTS}
                      handleChange={(e) => handleTestItemResultChange(e, index)}
                      additionalClass={classes.selectField}
                    />
                  </Box>
                </Box>
              ))}

              <Divider className={classes.divider} />

              <Box className={classes.inputTitle}>4. Remark</Box>
              <Box className={classes.subtitle}>Remark</Box>

              <Textarea
                rowsMin={5}
                name="remark"
                value={newReportData.remark}
                onChange={handleDataChange}
                className={classes.remark}
              />
            </Box>
          )}
        </MuiDialogContent>

        <DialogActions>
          <Button onClick={handleReportDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>

          <Button onClick={handleSubmitNewReport} color="primary" variant="contained">
            Upload
          </Button>
        </DialogActions>

        {submitError ? (
          <Box className={classnames(classes.errorBox, classes.error)}>{submitError}</Box>
        ) : null}
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 60,
    paddingRight: 60,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 15,
    width: "100%",
    textAlign: "center",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
  errorBox: {
    display: "flex",
    justifyContent: "center",
    padding: "12px",
  },
  error: {
    color: "#D42600",
    fontSize: 14,
  },
}));
