import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import UploadSingleFile from "components/input/UploadSingleFile";
import Header from "./Header";

import { atnGetSubmissionDetailsTaskDetail, atnUploadCAPReport } from "actions/submissionActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  info: {
    paddingTop: 5,
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const UploadCAP = ({ match, history }) => {
  const classes = useStyles();

  const submissionId = match.params.submissionId;
  const taskId = match.params.taskId;

  const [originalCapFile, setOriginalCapFile] = useState({});
  const [capFile, setCapFile] = useState({});

  useEffect(() => {
    atnGetSubmissionDetailsTaskDetail({
      type: 2,
      data: {
        id: submissionId,
        taskId: taskId,
      },
    }).then(({ data, message }) => {
      if (data && data.taskFiles) {
        for (let i = 0; i < data.taskFiles.length; i++) {
          if (data.taskFiles[i].type === "cap") {
            let fileData = {
              id: data.taskFiles[i].id,
              name: data.taskFiles[i].fileName,
            };

            setOriginalCapFile(fileData);
            setCapFile(fileData);

            break;
          }
        }
      }
    });
  }, []);

  const handleAddCAPFile = (newFile) => {
    setCapFile(newFile);
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    let changes = [];

    if (capFile.id !== originalCapFile.id) {
      changes.push({
        field: "CAP",
        action: "Update",
        before: originalCapFile.name,
        after: capFile.name,
      });
    }

    atnUploadCAPReport({
      submissionId: submissionId,
      taskId: taskId,
      data: {
        fileId: capFile.id,
        changes: changes,
      },
    }).then(({ data, message }) => {
      if (data) {
        history.push(`/supplier/submissions/${submissionId}`);
      }
    });
  };

  return (
    <Box className={classes.root}>
      <Header handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.pageTitle}>Upload CAP</Box>

        <Paper className={classes.container}>
          <Box className={classes.title}>Upload CAP</Box>

          <Box className={classes.info}>
            Please upload CAP in order to perform retest for the failed task.
          </Box>

          <Box className={classes.uploadContainer}>
            <UploadSingleFile file={capFile} handleAddFile={handleAddCAPFile} />
          </Box>
        </Paper>

        <Button
          variant="contained"
          color="primary"
          className={classes.continueBtn}
          onClick={handleSubmit}
          disabled={!capFile || !capFile.id}
        >
          CONFIRM
        </Button>
      </Box>
    </Box>
  );
};

export default UploadCAP;
