import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  message: {
    flex: 1,
    paddingRight: 32,
  },
  button: {
    color: "#fff",
    borderColor: "#fff",
  },
  alert: {
    "& .MuiAlert-filledInfo": {
      backgroundColor: "#003DA5",
      color: "#fff",
    },
    "& .MuiAlert-filledError": {
      backgroundColor: "#D42600",
      color: "#fff",
    },
  },
}));

const NoticeBar = ({
  closeText = "CLOSE",
  message = "",
  error = false,
  open = false,
  icon = false,
  duration = 5000,
  onClose,
  buttonVariant = "outlined",
}) => {
  const classes = useStyles();

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <div className={classes.alert}>
        <Alert icon={icon} variant="filled" severity={error ? "error" : "info"}>
          <Box display="flex">
            <Box display="flex" alignItems="center" className={classes.message}>
              {message}
            </Box>

            <Box display="flex" alignItems="center">
              <Button variant={buttonVariant} onClick={onClose} className={classes.button}>
                {closeText}
              </Button>
            </Box>
          </Box>
        </Alert>
      </div>
    </Snackbar>
  );
};

export default NoticeBar;
