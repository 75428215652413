import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import SearchPOButton from "components/input/SearchPOButton";

import CsvLink from "./CSVLink";
import POTable from "./POTable";

import { atnGetDailyPOList } from "actions/poActions";

const SIMPLE_BUYER_NAME = process.env.REACT_APP_SIMPLEBUYERNAME;

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },

  title: {
    fontWeight: 500,
    fontSize: "16px",
    marginRight: "30px",
  },

  txtText: {
    display: "block",
    fontSize: "12px",
    color: "#232B3A",
    padding: "10px 17px",
    cursor: "pointer",

    "&:hover": {
      background: "#AAAAAA",
    },
  },

  boxViewing: {
    borderTop: "1px solid #AAAAAA",
    borderBottom: "1px solid #AAAAAA",
  },

  txtView: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#000000",
    paddingLeft: "30px",
    marginRight: "30px",
  },

  boxLast: {
    fontSize: "10px",
    color: "#000000",
    marginRight: "10px",
  },

  first: {
    color: "#777777",
  },
}));

const PO = () => {
  const classes = useStyles();

  // ==================== GET LIST PO ====================
  const [listPODaily, setListPODaily] = useState([]);

  useEffect(() => {
    getListDailyPO({ rowsPerPage: 999999999 });
  }, []);

  const getListDailyPO = (data) => {
    atnGetDailyPOList(data).then(({ data, message }) => {
      setListPODaily(data ? data.list : []);
    });
  };
  // ==================== END GET LIST PO ====================

  // ==================== HANDLE ACTIONS BUTTON ====================
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const idExportAction = openAction ? "simple-popover" : undefined;

  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
  };
  // ==================== END HANDLE ACTIONS BUTTON ====================

  // ==================== HANDLE SEARCH BUTTON ====================
  const [searchQueryData, setSearchQueryData] = useState("");

  const cbHandleSearch = (data) => {
    setSearchQueryData(data);
  };
  // ==================== END HANDLE SEARCH BUTTON ====================

  // ==================== HANDLE EXPORT DATA ====================
  const headerExport = [
    { label: `${SIMPLE_BUYER_NAME} Responsible QA`, key: "wbaResponsibleQA" },
    { label: "PO Number", key: "poNumber" },
    { label: "WIC No. / Description", key: "wicNumber" },
    { label: "Program / Category", key: "programName" },
    { label: "Supplier Name", key: "supplierName" },
    { label: "Factory Name", key: "factoryName" },
    { label: "PO Quantity", key: "poQuantity" },
    { label: "Ship By Date", key: "shipByDate" },
    { label: "PAC Compliance Status", key: "pacStatus" },
    { label: "GCC Compliance Status", key: "gccStatus" },
    { label: "QAA Compliance Status", key: "qaaStatus" },
    { label: "FRI Compliance Status", key: "friStatus" },
    { label: "Loading Mode", key: "loadingMode" },
  ];

  const dataExport = listPODaily.map((item, index) => {
    return {
      ...item,
      wbaResponsibleQA: !item.wbaResponsibleQA ? "NA" : item.wbaResponsibleQA,
      pacStatus: !item.pacStatus ? "NA" : item.pacStatus,
      gccStatus: !item.gccStatus ? "NA" : item.gccStatus,
      qaaStatus: !item.qaaStatus ? "NA" : item.qaaStatus,
      friStatus: !item.friStatus ? "NA" : item.friStatus,
    };
  });
  // ==================== END HANDLE EXPORT DATA ====================

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 30px">
        <Box display="flex" alignItems="center">
          <Box className={classes.title}>Outstanding PO List</Box>
          <SearchPOButton
            cbHandleSearch={cbHandleSearch}
            placeHolder="Search PO No., WIC No., WIC Description"
          />
        </Box>

        <Box display="block" className={classes.actionExport}>
          <Button
            aria-describedby={idExportAction}
            variant="contained"
            color="primary"
            onClick={handleClickAction}
          >
            Actions <ArrowDropDownIcon />
          </Button>
          <Popover
            id={idExportAction}
            open={openAction}
            anchorEl={anchorEl}
            onClose={handleCloseAction}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Typography className={classes.txtText}>
              <CsvLink
                header={headerExport}
                data={dataExport}
                fileName="po_table.csv"
                text={`File Export Filtered (${dataExport.length}) Item`}
              />
            </Typography>
          </Popover>
        </Box>
      </Box>

      {/* <Box display='flex' padding="14px 0" className={classes.boxViewing}>
        <Box className={classes.boxLast} paddingLeft="30px">
          <Dropzone onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
              <section className={classes.uploadBtnContainer}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} multiple={false} />
                  <Button variant="contained" color="primary" size="small">
                    Upload PO Tracker
                  </Button>
                </div>
              </section>
            )}
          </Dropzone>
        </Box>
      </Box> */}

      <Box display="flex">
        <POTable listSearch={searchQueryData} listPODaily={listPODaily} />
      </Box>
    </Box>
  );
};

export default PO;
