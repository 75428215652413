import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import ConfirmModal from "components/modal/ConfirmModal";

import ReasonRemarkModal from "components/lab/ReasonRemarkModal";
import WarningModal from "components/lab/WarningModal";

import {
  hasPrepaymentAndNegativePrice,
  isNegativePrice,
  isPriceNotZeroAndNoPrepayment,
  isPriceValidAndHasPrepayment,
  isPriceZeroAndNoPrepayment,
} from "pages/lab/common/noInvoiceCostService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    marginTop: 30,
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 30,
    paddingBottom: 5,
  },
  selectField: {
    width: "100% !important",
    maxWidth: 600,
  },
  inputWidth: {
    width: "100%",
  },
  largeInputField: {
    width: 340,
  },
  inputField: {
    width: 130,
  },
  smallInputField: {
    width: 60,
  },
  deleteBtn: {
    padding: 5,
    marginLeft: 25,
  },
  subTotalTitle: {
    textAlign: "right",
    paddingRight: 10,
  },
  subTotal: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  addButtonContainer: {
    paddingTop: 20,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  priceSection: {
    paddingRight: 10,
    textAlign: "right",
  },
  vat: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 5,
  },
  totalPriceSection: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 30,
    textAlign: "right",
  },
  additionalInputTitle: {
    width: 300,
    paddingTop: 10,
  },
  displayInline: {
    display: "inline-block",
  },
  inlineTitle: {
    paddingRight: 40,
  },
}));

const CompleteWithDialog = ({
  isInspection = false,
  title = "",
  completeData = {},
  testItems = [],
  prepaymentData = {},
  isDisabledConfirmButton,
  onSubmit,
  onReasonOptionChange,
  onReasonRemarksChange,
  handleCompleteDataChange,
  handleResetReasonModal,
  component: WrappedComponent,
  ...componentProps
}) => {
  const classes = useStyles();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const { totalPrice, subTotal } = completeData;
  const { hasPrepayment, prepaymentPrice } = prepaymentData;

  const isNegativeAmount = isNegativePrice(isInspection, totalPrice, subTotal, prepaymentPrice);

  //show reason modal
  const isAmountZeroAndNoPrepayment = isPriceZeroAndNoPrepayment(totalPrice, hasPrepayment);

  //show warning modal
  const hasPrepaymentAndNegativeAmount = hasPrepaymentAndNegativePrice(
    hasPrepayment,
    isNegativeAmount,
  );

  //nextpage
  const isAmountNotZeroAndHasPrepayment = isPriceValidAndHasPrepayment(
    isInspection,
    hasPrepayment,
    prepaymentPrice,
    totalPrice,
    subTotal,
  );

  //nextpage
  const isAmountNotZeroAndNoPrepayment = isPriceNotZeroAndNoPrepayment(hasPrepayment, totalPrice);

  const hasTestItems = testItems.length > 0;

  const handleCheckTotalPrice = () => {
    if (isAmountNotZeroAndNoPrepayment || isAmountNotZeroAndHasPrepayment) {
      return handleOpenConfirmDialog();
    } else if (isAmountZeroAndNoPrepayment) {
      return handleOpenRemarkModal();
    } else if (hasPrepaymentAndNegativeAmount || isNegativeAmount) {
      return onShowWarningModal();
    }
  };

  const submit = () => {
    setOpenConfirmDialog(false);
    onSubmit();
  };

  const handleSubmitClick = () => {
    if (!hasTestItems) return submit();

    //* for Testing & Inspection
    let hasMissingData = false;
    for (let i = 0; i < testItems.length; i++) {
      if (
        testItems[i].quantity === null ||
        testItems[i].quantity === "" ||
        testItems[i].unitPrice === null ||
        testItems[i].unitPrice === ""
      ) {
        hasMissingData = true;
        break;
      }
    }
    return hasMissingData ? alert("missing data") : submit();
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleOpenRemarkModal = () => {
    setOpenRemarkModal(true);
  };

  const handleReasonModalCloseAndReset = () => {
    setOpenRemarkModal(false);
    handleResetReasonModal();
  };

  const handleRemarkModalClose = () => {
    setOpenRemarkModal(false);
  };

  const onShowWarningModal = () => {
    setOpenWarningModal(true);
  };

  const onHideWarningModal = () => {
    setOpenWarningModal(false);
  };

  const goToSubmitModal = () => {
    handleRemarkModalClose();
    handleOpenConfirmDialog();
  };

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.pageTitle}>{title}</Box>

        <Paper className={classes.container}>
          <WrappedComponent
            completeData={completeData}
            testItems={testItems}
            prepaymentData={prepaymentData}
            handleCompleteDataChange={handleCompleteDataChange}
            {...componentProps}
          />
        </Paper>

        <Button
          variant="contained"
          color="primary"
          disabled={isDisabledConfirmButton}
          className={classes.submitBtn}
          onClick={handleCheckTotalPrice}
        >
          SUBMIT
        </Button>
      </Box>

      <ReasonRemarkModal
        data={completeData}
        open={openRemarkModal}
        onSubmit={goToSubmitModal}
        onClose={handleReasonModalCloseAndReset}
        onReasonChange={handleCompleteDataChange}
      />

      <WarningModal
        open={openWarningModal}
        onSubmit={onHideWarningModal}
        onClose={onHideWarningModal}
      />

      <ConfirmModal
        isDisabledSubmit={isDisabledConfirmButton}
        onSubmit={handleSubmitClick}
        onClose={handleConfirmDialogClose}
        open={openConfirmDialog}
        hasContentList
      />
    </>
  );
};

export default memo(CompleteWithDialog);
