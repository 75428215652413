import { TRF_DOCUMENT } from "shared/constants";

/* eslint-disable consistent-return */
const MAX_FILE_NAME = 40;

export const getFileNameDisplay = (fileName, size = MAX_FILE_NAME) => {
  if (!fileName) return;

  let result = fileName;

  const parts = fileName.split(".");
  const name = parts[0];
  const extension = parts[1];

  if (name.length > size) {
    const middle = Math.floor(size / 2);
    result = `${name.substring(0, middle)}...${name.substring(
      name.length - middle,
      name.length,
    )}.${extension}`;
  }

  return result;
};

export const openFileURL = (url) => {
  if (url) window.open(url, "_blank");
};

export const openFileFromData = (currentFileUrl) => {
  const el = document.createElement("iframe");
  el.src = currentFileUrl;
  el.style.width = "100%";
  el.style.height = "100%";
  const win = window.open("", "_blank");
  win.document.write(el.outerHTML);
  win.focus();
};

export const readFileFromData = (file) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    openFileFromData(reader.result);
  };
  reader.readAsDataURL(file);
};

export const downloadFile = (fileData, fileName) => {
  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let blob = new Blob([fileData], { type: "application/json" });
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // other browsers
    let blob = new File([fileData], fileName, { type: "application/json" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blobUrl = URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    }, 0);
  }
};

export const downloadVideo = (fileData, fileName) => {
  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let blob = new Blob([fileData], { type: "video/mov" });
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // other browsers
    let blob = new File([fileData], fileName, { type: "video/mov" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blobUrl = URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    }, 0);
  }
};

export const getFileSourceUrl = (fileData, fileName = "Untitled") => {
  let blob = null;
  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    blob = new Blob([fileData], { type: "application/json" });
  } else {
    // other browsers
    // blob = new Blob([data], { type: 'application/octet-stream' });
    blob = new File([fileData], fileName, { type: "application/json" });
  }

  const url = URL.createObjectURL(blob);
  return url;
};

export const getTRFDocumentName = (lab, taskType) => {
  let trfDocument = "";

  if (!lab) return trfDocument;

  if (lab.indexOf("BV") >= 0) {
    if (["SPU", "SPUD", "DPI", "FRI", "FRID"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.BOOKING_FORM_PLATFORM_USE_DOC;
    } else if (["QT", "PST", "ST", "STD", "PAC"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.GENERAL_TESING_PLATFORM_USE_DOC;
    }
  } else if (lab.indexOf("SGS") >= 0) {
    if (["SPU", "SPUD"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.SAMPLING_BOOKING_DOC;
    } else if (["QT", "PST", "ST", "STD", "PAC"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.SGS_WALGREENS_DOC;
    }
  } else if (lab.indexOf("UL-sample sealing") >= 0) {
    if (["SPU", "SPUD", "DPI", "FRI", "FRID", "SS"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.GENERAL_INPECTION_REQUEST_FORM;
    }
  } else if (lab.indexOf("UL") >= 0) {
    if (["SPU", "SPUD", "DPI", "FRI", "FRID"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.GENERAL_INPECTION_REQUEST_FORM;
    } else if (["QT", "PST", "ST", "STD", "PAC"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.PRODUCT_TESTING_DOWNLIST_DOC;
    }
  } else if (lab.indexOf("TUV SUD") >= 0) {
    if (["FRI", "FRID"].includes(taskType)) {
      trfDocument = TRF_DOCUMENT.ADMIN_BOOKING_FORM;
    }
  }

  return trfDocument;
};
