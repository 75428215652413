import React from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ICWLogo from "../../../assets/logo/icw_logo-white.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    minHeight: 40,
    height: 40,
    boxShadow: "none",
    backgroundColor: "rgb(65, 136, 245)",
    "& .MuiToolbar-regular": {
      minHeight: 40,
      height: 40,
    },
    zIndex: 2000,
  },
  logo: {
    paddingLeft: 6,
    paddingRight: 50,
  },
}));

const CommonHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Link to="/" className={classes.logo}>
            <img src={ICWLogo} alt="logo" width="40" height="40" />
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(CommonHeader);
