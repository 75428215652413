import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import _get from "lodash/get";

import Button from "components/input/Button";
import ActionButton from "./ActionButton";
import DownloadButton from "./DownloadButton";
import CompleteButton from "./CompleteButton";
import InfoBox from "./InfoBox";

import ErrorModal from "components/modal/ErrorModal";

import { LAB, SUPPLIER, USER_TYPE } from "shared/constants";
import {
  atnGetSubmissionDetailsTaskDetail,
  atnGetSubmissionDetailsTaskList,
  atnPutSubmissionCompleteResumeReport,
} from "actions/submissionActions";

import { ratnGetCurrentTaskDetail } from "state/submissionDetail/action";

import useUserType from "hooks/useUserType";

import useLoading from "hooks/useLoading";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menuButton: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: theme.spacing(4),
  },
  buttonContainer: {
    marginRight: 15,
  },
}));

const MenuBar = ({
  showPayerInfoButton = false,
  history,
  data,
  currentTaskId,
  submissionId,
  userType,
  isAdmin,
  labStatus,
  handleCancelTaskDialogOpen,
  handleCAPAccept,
  handleCAPReject,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isSupplier, isLab } = useUserType(userType);

  const { isLoading: isFetchLoading, loading: fetchLoading, done: fetchDone } = useLoading();

  const [taskListData, setTaskListData] = useState([]);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);

  useEffect(() => {
    if (!data.id) return;
    getTaskList(data.id);
  }, []);

  const getTaskList = (id) => {
    atnGetSubmissionDetailsTaskList({ data: { id }, type: userType }).then((result) => {
      setTaskListData(result.data);

      if (result?.data?.length) {
        const firstItemId = _get(result, "data.0.id");
        getTaskDetail(firstItemId);
      }
    });
  };

  const getTaskDetail = (id) => {
    if (id) {
      atnGetSubmissionDetailsTaskDetail({
        data: { id: data.id, taskId: id },
        type: userType,
      }).then((result) => {
        dispatch(ratnGetCurrentTaskDetail(result.data));
      });
    }
  };

  const currentTaskDetail = useSelector((s) => s.submissionDetail.currentTaskDetail);

  const handleBack = () => {
    if (isSupplier) {
      history.push("/supplier/submissions");
    } else if (isLab) {
      history.push("/lab/submissions");
    }
  };

  const handleCompleteButton = () => {
    setOpenCompleteModal(true);
  };

  const handleCloseCompleteModal = () => {
    setOpenCompleteModal(false);
  };

  const handleCompleteModalSubmit = async () => {
    fetchLoading();

    await atnPutSubmissionCompleteResumeReport({
      data: { id: data?.id },
      type: userType,
    })
      .then((res) => {
        fetchDone();

        if (!res?.data) return;

        setOpenCompleteModal(false);
        window.location.reload();
      })
      .catch((err) => {
        fetchDone();
      });
  };

  const handleClickEditPayerInfo = () => {
    history.push(`/supplier/submissions/${submissionId}/edit/${currentTaskId}/payerInfo`);
  };

  const isLabFlow = data?.assignTo === LAB;
  const isSupplierFlow = data?.assignTo === SUPPLIER;

  const isShowResumeAction =
    ((isLab && isLabFlow) || (isSupplier && isSupplierFlow)) && data?.isResumeReport;

  const hasResumeReportTask = taskListData.some((task) => task?.isResumeReport);

  const isShowAction =
    (hasResumeReportTask && isShowResumeAction) ||
    (!isAdmin &&
      labStatus !== "rejected" &&
      data.taskType !== "SS" &&
      data.status !== "cancelled" &&
      !(isSupplier && data.status === "completed") &&
      (isLab || !data.capStatus || data.capStatus === "accepted"));

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleBack}
          >
            <BackIcon />
          </IconButton>
          <InfoBox data={data} />

          {isShowResumeAction && (
            <>
              <CompleteButton onClick={handleCompleteButton} />

              <ErrorModal
                title="Do you want to complete this submission"
                content="Please confirm your report to complete your submission."
                closeText="Cancel"
                open={openCompleteModal}
                disabled={isFetchLoading}
                onClose={handleCloseCompleteModal}
                onSubmit={handleCompleteModalSubmit}
              />
            </>
          )}

          {labStatus !== "rejected" && data.status !== "cancelled" && (
            <DownloadButton
              userType={userType}
              submissionId={submissionId}
              taskListData={taskListData}
            />
          )}

          {userType === USER_TYPE.lab && data.capStatus === "uploaded" && (
            <Box display="flex">
              <Box className={classes.buttonContainer}>
                <Button onClick={() => handleCAPAccept()}>Accept CAP</Button>
              </Box>
              <Box className={classes.buttonContainer}>
                <Button color="secondary" onClick={() => handleCAPReject()}>
                  Reject CAP
                </Button>
              </Box>
            </Box>
          )}

          {showPayerInfoButton && data.status !== "completed" && (
            <Button
              cy="edit-payer-info"
              color="primary"
              variant="contained"
              onClick={handleClickEditPayerInfo}
            >
              Edit Payer information
            </Button>
          )}

          {isShowAction && (
            <ActionButton
              isNb={data?.isNb}
              status={data?.status}
              taskDetail={currentTaskDetail || taskListData}
              submissionId={submissionId}
              showResumeReport={hasResumeReportTask && isShowResumeAction}
              handleCancelTaskDialogOpen={handleCancelTaskDialogOpen}
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(MenuBar);
