import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Box, Typography } from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";

const styles = () => ({
  input: {
    width: "100%",
    border: "1px solid #AAAAAA",
    borderRadius: 4,
    height: 36,
    "& .MuiInputBase-input": {
      fontSize: 12,
      padding: "10px 8px",
    },
  },
  iconButton: {
    marginLeft: 15,
    color: "#777777",
    // width: '0.7em',
    // height: '0.7em',
    width: 15,
    height: 15,
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  largeIconButton: {
    marginLeft: 15,
    color: "#777777",
    // width: '0.7em',
    // height: '0.7em',
    width: 25,
    height: 25,
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
});

const EditableInput = ({
  classes,
  placeholder,
  name,
  value,
  onKeyUp,
  additionalClass,
  textClassName,
  handleSubmitEdit,
  ...otherProps
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setCurrentValue(value);
  };

  const handleSubmit = () => {
    handleSubmitEdit(currentValue);
    setEditing(false);
  };

  return editing ? (
    <>
      <Box display="flex">
        <Box>
          <InputBase
            className={classnames(classes.input, additionalClass)}
            placeholder={placeholder}
            name={name}
            value={currentValue}
            onChange={handleChange}
            onKeyUp={onKeyUp}
            {...otherProps}
          />
        </Box>
        <Box>
          <Check className={classes.largeIconButton} onClick={handleSubmit} />
        </Box>
        <Box>
          <Close className={classes.largeIconButton} onClick={handleCancel} />
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Typography className={textClassName}>{value}</Typography>
      <Edit className={classes.iconButton} onClick={handleEdit} />
    </>
  );
};

export default withStyles(styles)(EditableInput);
