import React from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import MultipleSelect from "components/input/MultipleSelect";

import UploadSingleFile from "components/input/UploadSingleFile";
import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";
import Textarea from "components/input/Textarea";

import { isPbNotExisted } from "services/pbService";

import { LAB_REPORT_UPLOAD_RESULTS } from "shared/constants";

export default function EditInspectionReportModal({
  open = false,
  showProgram777 = false,
  loading = false,
  report = {
    id: null,
    fileId: null,
    fileName: null,
    isReviseReport: null,
    issueDate: null,
    location: null,
    pb: [],
    remark: null,
    result: null,
  },
  errors = {
    issueDate: false,
    fileId: false,
    result: false,
    pb: false,
  },
  hasSelectedNotExistedPb,
  pbOptions,
  selectedPb,
  onChangePb,
  onClose,
  onUploadReport,
  onChange,
  onSubmit,
}) {
  const classes = useStyles();

  const hasPbNotExisted = report.pb?.filter((item) => isPbNotExisted(item))?.length > 0;

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <MuiDialogTitle>
        <Typography className={classes.dialogTitle}>Upload Report</Typography>
        <Divider />
      </MuiDialogTitle>
      <MuiDialogContent>
        <Box className={classes.inputTitle}>Upload Report File</Box>

        <Box>
          <UploadSingleFile
            buttonLabel="UPLOAD SUPPORTING DOC"
            handleAddFile={onUploadReport}
            className={classes.uploadButton}
          />
        </Box>

        {report?.fileId && (
          <Box>
            <Box className={classes.fileName}>{report?.fileName}</Box>

            <Divider className={classes.divider} />

            {showProgram777 && (
              <>
                <Box className={classes.inputTitle}>PB Information</Box>

                <MultipleSelect
                  label="Choose PB"
                  name="pb"
                  value={selectedPb}
                  options={pbOptions}
                  handleChange={onChangePb}
                  additionalClass={classes.pbSelectField}
                  formControlClass={classes.inputFormControl}
                  error={errors.pb}
                  helperText={errors.pb ? "Please select a PB" : null}
                />

                {hasSelectedNotExistedPb().hasNotExistedPb && (
                  <div className={classnames(classes.errorSpacing, classes.error)}>
                    {hasSelectedNotExistedPb()?.notExistedPb?.join(", ")} PB not existed
                  </div>
                )}

                <Divider className={classes.divider} />
              </>
            )}

            <Box className={classes.inputTitle}>Result</Box>

            <Select
              label="Select Result"
              name="result"
              value={report?.result}
              options={LAB_REPORT_UPLOAD_RESULTS}
              handleChange={onChange}
              additionalClass={classes.selectField}
              error={errors.result}
              helperText={errors.result ? "Please select a report result" : null}
            />

            <Divider className={classes.divider} />

            <Box className={classes.inputTitle}>Issue Date</Box>

            <DatePicker
              additionalClass={classes.datePickerMaxWidth}
              name="issueDate"
              value={report?.issueDate}
              handleChange={onChange}
              error={errors.issueDate}
              helperText={errors.issueDate ? "Please select a date" : null}
            />
          </Box>
        )}

        <Divider className={classes.divider} />

        <Box className={classes.inputTitle}>Remark</Box>

        <Textarea
          rowsMin={5}
          name="remark"
          value={report?.remark || ""}
          onChange={onChange}
          className={classes.remark}
        />
      </MuiDialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          disabled={loading || hasPbNotExisted}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  actionBtn: {
    cursor: "pointer",
    color: "#999999",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      maxHeight: "calc(100% - 64px - 40px) !important",
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: "2px",
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
  reportNameContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  errorSpacing: {
    marginTop: "4px",
  },
  datePickerMaxWidth: {
    maxWidth: "300px",
  },
}));
