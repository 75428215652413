import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import DatePicker from "components/input/DatePicker";
import MultipleSelect from "components/input/MultipleSelect";
import Select from "components/input/Select";
import Upload from "components/input/Upload";
import Textarea from "components/input/Textarea";

import { LAB_REPORT_UPLOAD_RESULTS, PB_STATUS } from "shared/constants";

import { getPbLabel } from "services/pbService";

export default function InspectionReport({
  isProgram777 = false,
  reportData = {},
  onAddNewFilesChange,
  onReportDataChange,
  onRemoveFile,
  onSubmit,
  disabledSubmit,
}) {
  const classes = useStyles();
  const { NOT_EXISTED } = PB_STATUS;

  const [pbOptions, setPbOptions] = useState([]);
  const [selectedPb, setSelectedPb] = useState([]);

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    if (isProgram777 && reportData?.selectedTasks?.length > 0) {
      const pbWithoutNotExisted = [];

      //can only select 1 task with pb
      //loop pb list to filter out Not Existed PB
      for (let i = 0; i < reportData?.selectedTasks?.length; i++) {
        for (let j = 0; j < reportData?.selectedTasks[i]?.pb?.length; j++) {
          const isPbStatusNotExisted =
            reportData?.selectedTasks[i]?.pb[j]?.pbStatus !== NOT_EXISTED;

          if (isPbStatusNotExisted) {
            pbWithoutNotExisted.push({
              ...reportData.selectedTasks[i]?.pb[j],
              value: reportData.selectedTasks[i]?.pb[j]?.id,
              label: getPbLabel(reportData.selectedTasks[i]?.pb[j]),
            });
          }
        }
      }

      setPbOptions(pbWithoutNotExisted);
      setSelectedPb(reportData?.selectedTasks?.pb);
    }
  }, [reportData.selectedTasks]);

  const handleUploadReport = (newFiles) => {
    const files = [];

    for (let i = 0; i < newFiles.length; i++) {
      files.push({
        fileId: newFiles[i]?.id,
        name: newFiles[i]?.name,
        pb: [],
        result: "",
        issueDate: "",
      });
    }

    onAddNewFilesChange(files);
  };

  const handlePbsChange = (e, index) => {
    const { value } = e.target;

    const pbData = [];

    for (let i = 0; i < value?.length; i++) {
      for (let j = 0; j < pbOptions?.length; j++) {
        if (value[i] === pbOptions[j].id) {
          pbData.push({
            ...pbOptions[j],
            value: pbOptions[j]?.id,
            label: pbOptions[j]?.pbNumber,
          });
        }
      }
    }

    onReportDataChange(
      {
        target: {
          name: "pb",
          value: pbData,
        },
      },
      index,
    );
  };

  const resetSubmitError = () => {
    setSubmitError("");
  };

  const checkIsMissingData = () => {
    resetSubmitError();

    const isNoReport = reportData?.reports?.length === 0 || false;

    const isMissingReportData =
      reportData?.reports?.some((report) => !report?.issueDate || !report?.result) || false;

    switch (true) {
      case isNoReport:
        setSubmitError("Please upload at least 1 report");
        break;
      case isMissingReportData:
        setSubmitError("Please fill in all report information");
        break;
      default:
        break;
    }

    return isNoReport || isMissingReportData;
  };

  const handleSubmitClick = () => {
    if (checkIsMissingData()) return;

    onSubmit();
    resetSubmitError();
  };

  const gridSize = isProgram777 ? 2 : 3;

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Upload Report</Box>

      <Paper className={classes.container}>
        <Box className={classes.title}>Reports & Results</Box>

        <Box className={classes.subtitleSection}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              Report Documents
            </Grid>

            {isProgram777 && (
              <Grid item xs={3}>
                PB Information
              </Grid>
            )}

            <Grid item xs={gridSize}>
              Result
            </Grid>
            <Grid item xs={gridSize}>
              Issue Date
            </Grid>

            <Grid item xs={gridSize}>
              Remark
            </Grid>

            <Grid item xs={1} />
          </Grid>
        </Box>

        <Divider />

        {reportData.reports.map((item, index) => (
          <Grid key={item.fileId} container spacing={3} className={classes.rowContainer}>
            <Grid item xs={2} className={classes.reportFileName}>
              {item.name}
            </Grid>

            {isProgram777 && (
              <Grid item xs={3}>
                <MultipleSelect
                  label="Choose PB"
                  name="pb"
                  value={item.pb || []}
                  options={pbOptions}
                  handleChange={(e) => handlePbsChange(e, index)}
                  formControlClass={classes.inputFormControl}
                />
              </Grid>
            )}

            <Grid item xs={gridSize}>
              <Select
                label="Result"
                name="result"
                value={item.result}
                options={LAB_REPORT_UPLOAD_RESULTS}
                handleChange={(e) => onReportDataChange(e, index)}
              />
            </Grid>
            <Grid item xs={gridSize}>
              <DatePicker
                name="issueDate"
                value={item.issueDate}
                handleChange={(e) => onReportDataChange(e, index)}
              />
            </Grid>

            <Grid item xs={gridSize}>
              <Textarea
                rowsMin={3}
                name="remark"
                value={item?.remark || ""}
                onChange={(e) => onReportDataChange(e, index)}
                className={classes.remark}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={() => onRemoveFile(index)} className={classes.deleteBtn}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Box className={classes.addButtonContainer}>
          <Upload
            buttonLabel="UPLOAD REPORT(S)"
            handleAddFiles={handleUploadReport}
            className={classes.uploadButton}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={disabledSubmit}
      >
        SUBMIT
      </Button>

      {submitError ? (
        <Box className={classnames(classes.errorBox, classes.error)}>{submitError}</Box>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 60,
    paddingRight: 60,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 20,
    width: "100%",
  },
  uploadButton: {
    width: "100%",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  inputFormControl: {
    height: "35px",
  },
  remark: {
    width: "100% !important",
    maxWidth: "100%",
    fontFamily: "inherit",
  },
  reportFileName: {
    fontSize: 12,
  },
  errorBox: {
    display: "flex",
    justifyContent: "center",
    padding: "8px",
  },
  error: {
    color: "#D42600",
    fontSize: 14,
  },
}));
