import api from "./api";

import Auth from "auth/authenticator";
import { BUYER, SUPPLIER } from "shared/constants";

const isBuyer = Auth.loginRole() === BUYER;

class poApi {
  static getDailyPOList(data) {
    return api.post(`/${isBuyer ? BUYER : SUPPLIER}/daily_po/list`, data);
  }

  static getDailyPOSeriesList(data) {
    return api.post("/buyer/daily_po/daily_po_series/list", data);
  }

  static uploadPOChecker(file) {
    return api.uploadAndDownload("/buyer/daily_po/upload", file);
  }

  static getDailyPOListSupplier(data) {
    return api.post("/supplier/daily_po/list", data);
  }

  static getDailyPOSeriesListSupplier(data) {
    return api.post("/supplier/daily_po/daily_po_series/list", data);
  }
}

export default poApi;
