import taskApi from "../api/taskApi";

export const atnGetOpenTaskData = (reqData = {}) => {
  return taskApi.getOpenTaskData(reqData);
};

export const atnGetPoEveryday = (reqData = {}) => {
  return taskApi.getPoEveryday(reqData);
};

export const atnGetPbEveryday = (reqData = {}) => {
  return taskApi.getPbEveryday(reqData);
};

export const atnGetOpenTaskLabs = (reqData = {}) => {
  return taskApi.getOpenTaskLabs(reqData);
};

export const atnOpenTask = (reqData = {}) => {
  return taskApi.openTask(reqData);
};

export const atnOpenTaskSupplier = (reqData = {}) => {
  return taskApi.openTaskSupplier(reqData);
};

export const atnUpdateTaskRequirement = (reqData = {}) => {
  return taskApi.updateTaskRequirement(reqData);
};

export const atnGetTrfFactoryData = (reqData = {}) => {
  return taskApi.getTrfFactoryData(reqData);
};

export const atnUpdateCompletedTask = (reqData = {}) => {
  return taskApi.completedTask(reqData);
};

export const atnUpdateTaskLabReport = (reqData = {}) => {
  return taskApi.updateTaskLabReport(reqData);
};

export const atnGetTaskPayer = (reqData = {}) => {
  return taskApi.getTaskPayer(reqData);
};

export const atnUpdateTaskPayer = (reqData = {}) => {
  return taskApi.updateTaskPayer(reqData);
};

export const atnGetOpenTaskTaskTypes = (reqData = {}) => {
  return taskApi.getOpenTaskTaskTypes(reqData);
};

export const atnUpdateNb = (reqData = {}) => {
  return taskApi.updateNb(reqData);
};
