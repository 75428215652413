import React, { memo } from "react";

import DetailBox from "./DetailBox";

const TaskDetail = ({
  data: { list, masterWicId, itemStatus, taskType, category, brand, allowSvrr },
  func: { onUpdate },
  hasPayerInfo = false,
}) => {
  return (
    <>
      {list.map((item) => (
        <DetailBox
          key={item.id}
          taskId={item.id}
          brand={brand}
          allowSvrr={allowSvrr}
          data={item}
          itemStatus={itemStatus}
          taskType={taskType}
          category={category}
          totalTasks={list.length}
          listTasks={list}
          masterWicId={masterWicId}
          onUpdate={onUpdate}
          hasPayerInfo={hasPayerInfo}
        />
      ))}
    </>
  );
};

export default memo(TaskDetail);
