import moment from 'moment';

export const formatHeaderLab = (headerData = {}, t) => {
  let formatedHeader = [];
  const sortingOptions = ['none', 'ascending', 'descending'];

  formatedHeader.push({
    name: "submissionNo",
    label: "Submission ID",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  formatedHeader.push({
    name: "product",
    label: "Product",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  let supplierFilter = [];

  if (headerData.supplierName) {
    for (let i = 0; i < headerData.supplierName.length; i++) {
      supplierFilter.push({
        value: headerData.supplierName[i].name,
        label: headerData.supplierName[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "supplierName",
    label: "Supplier Name",
    hasFilter: true,
    filterItems: supplierFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  

  // let labFilter = [];

  // if (headerData.labName) {
  //   for (let i = 0; i < headerData.labName.length; i++) {
  //     labFilter.push({
  //       value: headerData.labName[i].name,
  //       label: headerData.labName[i].name,
  //     });
  //   }
  // }

  // formatedHeader.push({
  //   name: "labName",
  //   label: "Lab Name",
  //   hasFilter: true,
  //   filterItems: labFilter,
  //   selectedFilter: [],
  //   hasSorting: false,
  //   sortingOptions: [],
  //   sorting: 0,
  // });

  let factoryFilter = [];

  if (headerData.factoryName) {
    for (let i = 0; i < headerData.factoryName.length; i++) {
      factoryFilter.push({
        value: headerData.factoryName[i].name,
        label: headerData.factoryName[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "factoryName",
    label: "Factory",
    hasFilter: true,
    filterItems: factoryFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  let taskFilter = [];

  if (headerData.taskType) {
    for (let i = 0; i < headerData.taskType.length; i++) {
      taskFilter.push({
        value: headerData.taskType[i].name,
        label: headerData.taskType[i].name,
      });
    }
  }

  formatedHeader.push({
    name: "taskType",
    label: "Task",
    hasFilter: true,
    filterItems: taskFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  let taskStatusFilter = [];

  if (headerData.taskStatus) {
    for (let i = 0; i < headerData.taskStatus.length; i++) {
      taskStatusFilter.push({
        value: headerData.taskStatus[i].name,
        label: t(`taskStatus.${headerData.taskStatus[i].name}`),
      });
    }
  }

  formatedHeader.push({
    name: "taskStatus",
    label: "Task Status",
    hasFilter: true,
    filterItems: taskStatusFilter,
    selectedFilter: [],
    hasSorting: false,
    sortingOptions: [],
    sorting: 0,
  });

  formatedHeader.push({
    name: "requestedDate",
    label: "Requested Date",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  formatedHeader.push({
    name: "ecd",
    label: "ECD",
    hasFilter: false,
    filterItems: [],
    selectedFilter: [],
    hasSorting: true,
    sortingOptions: sortingOptions,
    sorting: 0,
  });

  return formatedHeader;
}

export const formatHeader = (headerData = [], t) => {
  let formatedHeader = [];
  let filterItems = [];
  const sortingOptions = ['none', 'ascending', 'descending'];

  for (let i = 0; i < headerData.length; i++) {
    filterItems = [];

    if (headerData[i].filterItems) {
      if (headerData[i].name === "taskType") {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: t(`task.${headerData[i].filterItems[j].name}`),
            });
          }
        }
      } else if (headerData[i].name === "taskStatus") {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: t(`taskStatus.${headerData[i].filterItems[j].name}`),
            });
          }
        }
      } else {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: headerData[i].filterItems[j].name,
            });
          }
        }
      }
    }

    if (headerData[i].name === "submission") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Submission",
        hasFilter: false,
        filterItems: [],
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "submissionId") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Submission",
        hasFilter: false,
        filterItems: [],
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "product") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Product",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "selectedLab") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Selected Lab",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "taskType") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task Type",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "taskStatus") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task Status",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "ecd") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "ECD",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "requestedDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Requested Date",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "supplierName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Supplier Name",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "factoryName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Factory",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "labName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Lab Location",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    }
  }

  return formatedHeader;
};

export const formatSupplierHeader = (headerData = [], t) => {
  let formatedHeader = [];
  let filterItems = [];
  const sortingOptions = ['none', 'ascending', 'descending'];

  for (let i = 0; i < headerData.length; i++) {
    filterItems = [];

    if (headerData[i].filterItems) {
      if (headerData[i].name === "taskType") {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: t(`task.${headerData[i].filterItems[j].name}`),
            });
          }
        }
      } else if (headerData[i].name === "taskStatus") {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: t(`taskStatus.${headerData[i].filterItems[j].name}`),
            });
          }
        }
      } else {
        for (let j = 0; j < headerData[i].filterItems.length; j++) {
          if (headerData[i].filterItems[j] && headerData[i].filterItems[j].name) {
            filterItems.push({
              value: headerData[i].filterItems[j].name,
              label: headerData[i].filterItems[j].name,
            });
          }
        }
      }
    }

    if (headerData[i].name === "submission") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Submission",
        hasFilter: false,
        filterItems: [],
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "submissionId") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Submission",
        hasFilter: false,
        filterItems: [],
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "product") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Product",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "selectedLab") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Selected Lab",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "taskType") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task Type",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "taskStatus") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Task Status",
        hasFilter: true,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: [],
        sorting: 0,
      });
    } else if (headerData[i].name === "ecd") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "ECD",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "requestedDate") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Requested Date",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: true,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "supplierName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Supplier Name",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "factoryName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Factory",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    } else if (headerData[i].name === "labName") {
      formatedHeader.push({
        name: headerData[i].name,
        label: "Selected Lab",
        hasFilter: false,
        filterItems: filterItems,
        selectedFilter: [],
        hasSorting: false,
        sortingOptions: sortingOptions,
        sorting: 0,
      });
    }
  }

  return formatedHeader;
};

export const formatRow = (headerData = [], rowData = [], t) => {
  let formatedRows = [];
  let submissionData = [];
  let itemData = [];
  let taskData = [];

  for (let i = 0; i < rowData.length; i++) {
    submissionData = [];
    itemData = [];

    for (let j = 0; j < headerData.length; j++) {
      if (headerData[j].name === "submissionId") {
        submissionData.push({
          name: headerData[j].name,
          value: rowData[i].submissionNumber,
        });
      } else if (headerData[j].name === "labName") {
        submissionData.push({
          name: headerData[j].name,
          value: rowData[i].labName,
        });
      } else if (headerData[j].name === "ecd") {
        submissionData.push({
          name: headerData[j].name,
          // value: rowData[i].ecd ? moment(rowData[i].ecd).format("DD/MMM/YYYY") : "N/A",
          value: "",
        });
      } else {
        submissionData.push({
          name: headerData[j].name,
          value: "",
        });
      }
    }

    for (let j = 0; j < rowData[i].tasks.length; j++) {
      taskData = [];

      for (let k = 0; k < headerData.length; k++) {
        if (headerData[k].name === "submissionId") {
          taskData.push({
            name: headerData[k].name,
            value: rowData[i].tasks[j].taskNumber,
            info: "",
            buyer: rowData[i].tasks[j].buyerCompanyName,
          });
        } else if (headerData[k].name === "productName") {
          taskData.push({
            name: headerData[k].name,
            value: rowData[i].tasks[j].productName,
            info: rowData[i].tasks[j].wicNumber,
            buyer: "",
          });
        } else if (headerData[k].name === "labName") {
          taskData.push({
            name: headerData[k].name,
            value: "",
            info: "",
            buyer: "",
          });
        } else if (headerData[k].name === "taskType") {
          taskData.push({
            name: headerData[k].name,
            value: t(`task.${rowData[i].tasks[j].taskType}`),
            info: "",
            buyer: "",
          });
        } else if (headerData[k].name === "taskStatus") {
          let indicatorColor = "";

          if (rowData[i].tasks[j].taskStatus === "not_open_for_quote") {
            indicatorColor = "grey";
          } else if (rowData[i].tasks[j].taskStatus === "waiting_for_lab_quote"
            || rowData[i].tasks[j].taskStatus === "quote_available"
            || rowData[i].tasks[j].taskStatus === "quote_confirmed"
            || rowData[i].tasks[j].taskStatus === "quote_confirmed_and_waiting_for_sample") {
              indicatorColor = "blue";
          } else if (rowData[i].tasks[j].taskStatus === "sample_received"
            || rowData[i].tasks[j].taskStatus === "in_progress"
            || rowData[i].tasks[j].taskStatus === "in_progress_revise_update"
            || rowData[i].tasks[j].taskStatus === "pending"
            || rowData[i].tasks[j].taskStatus === "retest_in_progress"
            || rowData[i].tasks[j].taskStatus === "in_progress_result_report_updated"
            || rowData[i].tasks[j].taskStatus === "arranging_sample"
            || rowData[i].tasks[j].taskStatus === "under_review"
            || rowData[i].tasks[j].taskStatus === "booking_reserved"
            || rowData[i].tasks[j].taskStatus === "waiting_for_wba_approval") {
              indicatorColor = "yellow";
          } else if (rowData[i].tasks[j].taskStatus === "completed"
            || rowData[i].tasks[j].taskStatus === "completed_accept"
            || rowData[i].tasks[j].taskStatus === "completed_concession") {
              indicatorColor = "green";
          } else if (rowData[i].tasks[j].taskStatus === "completed_reject") {
            indicatorColor = "red";
          }

          taskData.push({
            name: headerData[k].name,
            value: t(`taskStatus.${rowData[i].tasks[j].taskStatus}`),
            info: "",
            buyer: "",
            hasIndicator: true,
            indicator: indicatorColor,
          });
        } else if (headerData[k].name === "ecd") {
          taskData.push({
            name: headerData[k].name,
            value: rowData[i].tasks[j].ecd ? moment(rowData[i].tasks[j].ecd).format("DD/MMM/YYYY") : "N/A",
            info: "",
            buyer: "",
          });
        }
      }

      itemData.push({
        id: rowData[i].tasks[j].id,
        data: taskData
      });
    }

    formatedRows.push({
      id: rowData[i].id,
      data: submissionData,
      itemData: itemData
    });
  }

  return formatedRows;
};

export const formatPayerListDropdown = (data = []) => {
  let listContactPersonAll = [],
      listAddressAll = [],
      listEmailALl = [],
      listPhoneAll = [];

    let idx = 0;
    for(let i = 0; i < data.length; i++) {
      let contactPerson = data[i].contactPerson;
  
      for(let j = 0; j < contactPerson.length; j++) {
        listContactPersonAll.push({ 'id': idx, 'label': contactPerson[j].payerContactPerson })
        listAddressAll = listAddressAll.concat(contactPerson[j].payerCompanyAddress);
        listEmailALl = listEmailALl.concat(contactPerson[j].payerEmail);
        listPhoneAll = listPhoneAll.concat(contactPerson[j].payerPhoneNumber);
        idx = idx + 1;
      }
    }

  listAddressAll = [...new Set(listAddressAll)].map((item, index) => { return {id: index, label: item} });
  listEmailALl = [...new Set(listEmailALl)].map((item, index) => { return {id: index, label: item} });
  listPhoneAll = [...new Set(listPhoneAll)].map((item, index) => { return {id: index, label: item} });

  return {
    listContactPersonAll,
    listAddressAll,
    listEmailALl,
    listPhoneAll
  }

}
