import React, { useEffect, useState } from "react";
import clsx from "clsx";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import MuiTable from "@material-ui/core/Table";
import MuiTableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";
import TablePagination from "./TablePagination";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import classTable from "./style.module.scss";

const useStyles = makeStyles(() => ({
  filter: {
    color: "#000000",
    width: 23,
    height: 23,
  },
  selectedFilter: {
    color: "#005EFF",
  },
  collpaseIcon: {
    transform: "rotate(-90deg)",
  },
  noDataReload: {
    width: "100%",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 50,

    "& svg": {
      width: 50,
      height: 50,
      fill: "#DADADA",
    },
  },
  sorryText: {
    fontWeight: 500,
    fontSize: 14,
    marginTop: 20,
  },
  applyBtn: {
    marginTop: 30,
  },
}));

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, noPaddingTop, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      color="primary"
      classes={{ root: clsx({ [classTable.noPaddingTop]: noPaddingTop }) }}
      ref={resolvedRef}
      {...rest}
    />
  );
});

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, renderFilter, selectedCheckBox = false },
}) {
  const classes = useStyles();
  const count = preFilteredRows.length;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box display="flex" alignItems="center" className="wrapperFilter">
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list" size="small" onClick={handleClick}>
          <FilterListIcon
            className={classnames(classes.filter, { [classes.selectedFilter]: selectedCheckBox })}
          />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          {renderFilter ? (
            renderFilter()
          ) : (
            <input
              value={filterValue || ""}
              onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder={`Search ${count} records...`}
            />
          )}
        </Box>
      </Popover>
    </Box>
  );
}

const Table = (props) => {
  const {
    columns,
    data,
    classes,
    skipPageReset = true,
    hasCheckbox = false,
    hasExpanded = false,
    hasEmtyColumn = false,
    hasManageColumn = false,
    hasPagingTop = false,
    sizeOfPage = 10,
    goToItemDetail = false,
    renderRowSubComponent = () => {},
    cbSetDataPagingTable = () => {},
    rowsPerPageOptions,
    hasFooter = false,
    dataFooter = [],
    noPagingBottom = false,
    indexColHover = -1,
    onMouseOver = () => {},
    onMouseOut = () => {},
    hasResetData = false,
    hasResetDataLength = null,
    handleResetData = () => {},
  } = props;

  const [dataPagingTable, setDataPagingTable] = useState({});

  const classesStyle = useStyles();

  useEffect(() => {
    cbSetDataPagingTable(dataPagingTable);
  }, [dataPagingTable]);

  const cbSetPageDataTable = (data) => {
    // console.log('cbSetPageDataTable : ', data);
    setDataPagingTable(data);
  };

  const columnsMemo = React.useMemo(() => {
    return columns.map(
      ({ id, label: title, value, canSort = true, canFilter = false, render, ...rest }) => {
        let colObj = {
          id,
          Header: title,
          accessor: value,
          disableSortBy: !canSort,
          disableFilters: !canFilter,
          ...rest,
        };
        if (render) {
          colObj = { ...colObj, Cell: render };
        }
        if (canFilter) {
          colObj = {
            ...colObj,
            Filter: DefaultColumnFilter,
          };
        }

        return colObj;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);
  const dataMemo = React.useMemo(() => {
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const IndeterminateCheckbox2 = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  });

  const ViewGroupColumn = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div>
        <ViewColumnIcon classes={{ root: classTable.viewColumnIcon }} onClick={handleClick} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box className={classTable.groupViewColumn}>
            <div>
              <IndeterminateCheckbox2 {...getToggleHideAllColumnsProps()} /> Toggle All
            </div>
            {allColumns.map((column, key) => (
              <div key={key}>
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                  {typeof column.Header === "string" ? column.Header : column.id}
                </label>
              </div>
            ))}
          </Box>
        </Popover>
      </div>
    );
  };

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      autoResetPage: !skipPageReset,
      initialState: { pageSize: sizeOfPage },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (hasCheckbox) {
        hooks.allColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <IndeterminateCheckbox noPaddingTop {...row.getToggleRowSelectedProps()} />
            ),
            width: 10,
            align: "center",
          },
          ...columns,
        ]);
      }
      if (hasExpanded) {
        hooks.allColumns.push((columns) => [
          {
            id: "expanded",
            Header: () => null, // No header
            Cell: ({ row }) => (
              <div {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon className={classesStyle.collpaseIcon} />
                )}
              </div>
            ),
            width: 10,
            align: "center",
          },
          ...columns,
        ]);
      }
      if (hasEmtyColumn) {
        hooks.allColumns.push((columns) => [
          {
            id: "empty",
            Header: () => null, // No header
            Cell: ({ row }) => <span></span>,
            width: 24,
            align: "center",
          },
          ...columns,
        ]);
      }
    },
  );

  // useEffect(() => {
  //   console.log('allColumns : ', allColumns);
  // }, [allColumns]);

  return (
    <>
      {hasManageColumn && <ViewGroupColumn />}

      {hasPagingTop && (
        <Box className={classTable.topPaging}>
          <TablePagination
            cbSetPageData={cbSetPageDataTable}
            data={{
              rowsTotal: dataMemo.length,
              columnsTotal: columnsMemo.length,
              pageIndex,
              pageSize,
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            func={{ gotoPage, setPageSize }}
          />
        </Box>
      )}

      <MuiTableContainer component={Box} className={classes.root}>
        <MuiTable {...getTableProps()} className={classes.table}>
          <TableHead data={{ headerGroups }} />
          {dataMemo.length > 0 && (
            <TableBody
              data={{ rows: page }}
              func={{ prepareRow }}
              goToItemDetail={goToItemDetail}
              indexColHover={indexColHover}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
          {hasFooter && <TableFooter data={dataFooter} />}
        </MuiTable>
      </MuiTableContainer>
      {dataMemo.length > 0 && !noPagingBottom && (
        <TablePagination
          cbSetPageData={cbSetPageDataTable}
          data={{
            rowsTotal: dataMemo.length,
            columnsTotal: columnsMemo.length,
            pageIndex,
            pageSize,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          func={{ gotoPage, setPageSize }}
        />
      )}

      {dataMemo.length === 0 && !hasResetData && (
        <Box classes={{ root: classTable.noData }}>No Data To Display</Box>
      )}
      {dataMemo.length === 0 && hasResetData && hasResetDataLength.length > 0 && (
        <Box className={classesStyle.noDataReload}>
          <ErrorOutlineIcon />
          <Box className={classesStyle.sorryText}>Sorry. There are no result.</Box>
          <Button
            variant="contained"
            color="primary"
            className={classesStyle.applyBtn}
            onClick={handleResetData}
          >
            Clear Keyword
          </Button>
        </Box>
      )}
    </>
  );
};

export default Table;
