import api from "./api";

class fileAPI {
  static downloadFile(data) {
    return api.download(`/files/download/${data}`, {});
  }

  // file: FormData (IMPORTANT: only accept single file)
  static uploadFile(file) {
    return api.upload("/files/upload", file);
  }

  // files: [ { fileId: number, type: "string" } ]
  static uploadFileWithType({ type, typeId, data }) {
    return api.post(`/files/upload/${type}/${typeId}`, data.data);
  }

  // files: [ { fileId: number, type: "string" } ]
  static deleteFileWithType({ type, typeId, data }) {
    return api.post(`/files/delete/${type}/${typeId}`, data.data);
  }

  static downloadTrfFile(data) {
    return api.download(`/trf/${data.taskId}`, {});
  }

  static downloadTUVTrfFile(data) {
    return api.download(`/trf_tuv/${data.taskId}/${data.fileName}`, {});
  }

  static downloadULTrfFile(data) {
    return api.download(`/trf_ul/${data.taskId}/${data.fileName}`, {});
  }

  static downloadTrfBulk(data) {
    return api.downloadWithData(`/trf/bulk`, data);
  }
}

export default fileAPI;
