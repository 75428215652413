import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Dialog } from "@material-ui/core";

import Header from "./Header";
import UploadAndResult from "./UploadReportAndResult";

import { atnGetPBList } from "actions/pbActions";
// import { classnames } from "@material-ui/data-grid";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
    height: "100vh",
  },
  dialog: {
    "& > .MuiBox-root": {
      height: "100vh",
    },
  },
}));

const PbUploadReportModal = ({
  open,
  onClose,
  onSubmit,
  className,
  isLoading = false,
  ...props
}) => {
  const classes = useStyles();

  const masterWicId = useSelector((s) => s.submissionDetail?.currentTaskDetail?.masterWicId);

  const [pbOptions, setPbOptions] = useState([]);

  useEffect(() => {
    masterWicId && open && getPbList();
  }, [masterWicId, open]);

  const getPbList = async () => {
    await atnGetPBList({
      rowsPerPage: 999999999,
      filters: [{ name: "masterWicId", value: [masterWicId] }],
    }).then(({ data }) => {
      setPbOptions(data.list);
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      // className={classnames(classes.dialog, className)}
      {...props}
    >
      <Box className={classes.root}>
        <Header headerTitle="Update Report and Result" handleClose={onClose} />

        <UploadAndResult pb={pbOptions} onSubmit={onSubmit} disabled={isLoading} />
      </Box>
    </Dialog>
  );
};

export default memo(PbUploadReportModal);
