import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";
import Button from "components/input/Button";

export default function CompleteButton({ className, disabled = false, onClick }) {
  const classes = useStyles();

  return (
    <Button
      className={classnames(classes.button, className)}
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
    >
      Make as completed
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    marginRight: "16px",
    color: "#2e7d32",
    borderColor: "#2e7d32",
  },
}));
