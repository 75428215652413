import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar as Image, Box, Paper, Typography } from "@material-ui/core";

import { atnGetLabAdminSubmissionDetailsProducts } from "actions/submissionActions";
import NoImage from "assets/no_image.png";
import ImagePreview from "components/display/ImagePreview";
import Link from "components/display/Link";
import Table from "components/table/Table";
import Attachments from "pages/common/Attachments";
import { textOrUnknown } from "utils/string";

import WICDetails from "./WICDetails";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  image: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
}));

const Products = ({ userType, data }) => {
  const classes = useStyles();

  const [productsData, setProductsData] = React.useState([]);

  const getProducts = (id) => {
    atnGetLabAdminSubmissionDetailsProducts({ data: { id }, type: userType }).then((result) => {
      setProductsData(result.data);
    });
  };

  React.useEffect(() => {
    if (!data.id) return;

    getProducts(data.id);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [productImagesState, setProductImagesState] = React.useState({});

  // React.useEffect(() => {
  //   if (productsData.length) {
  //     productsData.forEach((p) => {
  //       if (p.productImages && p.productImages.length > 0) {
  //         p.productImages.forEach(({ id, fileName, location }) => {
  //           atnDownloadFile(location).then(async (data) => {
  //             const url = getFileSourceUrl(data);
  //             setProductImagesState((prev) => {
  //               if (!prev[p.wicNumber]) prev[p.wicNumber] = [];
  //               return prev[p.wicNumber].concat({ id, fileName, location: url });
  //             });
  //           });
  //         });
  //       }
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productsData]);

  const productsTableColumn = [
    {
      columnId: "images",
      width: "30%",
      render: ({ productImages }) => {
        return productImages.length ? (
          <ImagePreview data={productImages} className={{ image: classes.image }} />
        ) : (
          <Image variant="square" alt="item-image" src={NoImage} className={classes.image} />
        );
      },
    },
    {
      columnId: "wicNo",
      label: "WIC No.",
      render: ({ wicNumber, id }) => {
        let number, url;
        number = wicNumber;
        url = `/supplier/items/${id}`;

        return url ? <Link path={url} text={number} /> : textOrUnknown(number);
      },
    },
    {
      columnId: "productName",
      label: "Product Name",
      render: ({ productName }) => {
        let name;
        name = productName;
        return textOrUnknown(name);
      },
    },
    {
      columnId: "brandType",
      label: "Brand Type",
      render: (data) => {
        return data.brandType;
      },
    },
    {
      columnId: "program",
      label: "Program",
      render: (data) => {
        return data.programName;
      },
    },
  ];

  const productsTableData = productsData.map((item) => {
    return {
      ...item,
      additionalRow: item
        ? {
            classes: { cell: classes.tableCellDescription },
            content: (
              <>
                <Box py={2}>
                  <Typography className={classes.title}>WIC Details</Typography>
                  <WICDetails data={item.subWics} />
                </Box>
                <Box py={2}>
                  <Typography className={classes.title}>WIC Attachments</Typography>
                  <Attachments data={item.attachments} itemId={item.id} editable={false} />
                </Box>
              </>
            ),
          }
        : null,
    };
  });

  return (
    <Paper className={classes.container}>
      <Table data={productsTableData} columnData={productsTableColumn} noPagination />
    </Paper>
  );
};

export default Products;
