import React, { useCallback } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";

import { atnUploadFile } from "actions/fileActions";

const useStyles = makeStyles(() => ({
  dropzone: {
    width: 590,
    backgroundColor: "#eeeeee",
    textAlign: "center",
    border: "2px dashed #cccccc",
  },
  uploadText: {
    paddingTop: 70,
    color: "#777777",
  },
  uploadIconContainer: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  uploadIcon: {
    color: "#777777",
    width: 40,
    height: "auto",
  },
  previewSection: {
    padding: "10px 0px",
  },
  previewSectionTitle: {
    color: "#777777",
  },
  previewImagesContainer: {
    paddingTop: 5,
    width: "100%",
  },
  imageContainer: {
    position: "relative",
    width: "fit-content",
    display: "inline-block",
  },
  previewImage: {
    position: "relative",
    height: 100,
    width: "auto",
    objectFit: "contain",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  deleteBtnContainer: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    right: 20,
    backgroundColor: "#FFFFFF",
    width: 24,
    height: 24,
    borderRadius: "50%",
  },
}));

const UploadWithPreview = ({ images = [], handleAddImages, handleRemoveImage, cy }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // const formData = new FormData();
      // let newImages = [];

      // for (let i = 0; i < acceptedFiles.length; i++) {
      //   formData.append("file", acceptedFiles[i]);
      //   newImages.push({
      //     name: acceptedFiles[i].name,
      //     preview: window.URL.createObjectURL(acceptedFiles[i])
      //   });
      // }

      // atnUploadFile(formData)
      // .then(result => {
      //   if (result.data) {
      //     newImages[0].id = result.data.id;
      //   }

      //   handleAddImages(newImages);
      // });

      const uploadImage = (file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          atnUploadFile(formData).then(({ data, message }) => {
            if (data) {
              resolve(data);
            } else {
              reject();
            }
          });
        });
      };

      let newImages = [];

      let uploadImageFunctionArray = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadImageFunctionArray.push(uploadImage(acceptedFiles[i]));
        newImages.push({
          name: acceptedFiles[i].name,
          preview: window.URL.createObjectURL(acceptedFiles[i]),
        });
      }

      Promise.all(uploadImageFunctionArray).then((result) => {
        for (let i = 0; i < result.length; i++) {
          newImages[i].id = result[i].id;
        }

        handleAddImages(newImages);
      });
    },
    [handleAddImages],
  );

  return (
    <>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className={classes.dropzone} data-cy="dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} data-cy={cy} />
              <Box className={classes.uploadText}>Drag and drop an image file here or click</Box>
              <Box className={classes.uploadIconContainer}>
                <CloudUploadIcon className={classes.uploadIcon} />
              </Box>
            </div>
          </section>
        )}
      </Dropzone>

      {images.length > 0 && (
        <Box className={classes.previewSection}>
          <Box className={classes.previewSectionTitle}>Preview:</Box>
          <Box
            className={classes.previewImagesContainer}
            data-cy={"ci-wic-preview"}
            data-cy-count={images.length}
          >
            {images.map((img, index) => (
              <Box key={index} className={classes.imageContainer}>
                <img
                  src={img.preview}
                  alt=""
                  className={classes.previewImage}
                  data-cy={`ci-wic-preview-${index + 1}`}
                />
                <Box className={classes.deleteBtnContainer}>
                  <CloseIcon
                    onClick={() => handleRemoveImage(index)}
                    data-cy={`ci-wic-preview-remove-${index + 1}`}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default connect(null, { atnUploadImage: atnUploadFile })(UploadWithPreview);
