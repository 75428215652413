import React, { PureComponent } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = () => ({
  error: {
    color: "#D42600",
  },
  formControl: {
    "& .MuiInputLabel-outlined": {
      fontSize: 12,
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  },
  select: {
    width: 200,
    height: 40,
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
      fontSize: 12,
    },
    "& fieldset": {
      top: 0,
      "& legend": {
        display: "none",
      },
    },
  },
  disabled: {
    // cursor: "not-allowed",
    // pointerEvents: "none",
    backgroundColor: "rgba(169, 169, 169, 0.1)",
    "& > *": {
      color: "rgb(169, 169, 169)",
    },
  },
  fullWidth: {
    width: "100%",
    maxWidth: "100%",
  },
});

class MultipleSelect extends PureComponent {
  render() {
    const {
      classes,
      label,
      name,
      value,
      options,
      handleChange,
      additionalClass,
      formControlClass,
      cy,
      helperText,
      error = false,
      fullWidth = false,
      ...otherProps
    } = this.props;

    const formattedValue = value.map((item) => item?.value) || [];

    return (
      <FormControl
        variant="outlined"
        className={classnames(classes.formControl, formControlClass, {
          [classes.fullWidth]: fullWidth,
        })}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          variant="outlined"
          className={classnames(classes.select, additionalClass, {
            [classes.fullWidth]: fullWidth,
          })}
          name={name}
          value={formattedValue}
          renderValue={() => value.map((item) => item.label).join(", ")}
          onChange={handleChange}
          label={label}
          data-cy={cy}
          data-cy-count={options.length}
          {...otherProps}
        >
          {options.map((item) => (
            <MenuItem
              key={item?.value}
              value={item?.value}
              data-cy={`${cy}-${item.value}`}
              className={classnames({ [classes.disabled]: item.disabled })}
              disabled={item.disabled}
            >
              <Checkbox checked={formattedValue.indexOf(item?.value) > -1} />
              <ListItemText primary={item?.label} />
            </MenuItem>
          ))}
        </Select>

        {helperText && (
          <FormHelperText className={classnames({ [classes.error]: error })}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles)(MultipleSelect);
