import React from "react";
import {
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  Tooltip,
} from "@material-ui/core";
import TableCell from "./TableCell";

const TableHead = (props) => {
  const {
    data: { columnData, order, orderBy },
    func: { onRequestSort, onSelectAllClick },
  } = props;

  const createSortHandler = (columnId) => (event) => {
    onRequestSort(event, columnId);
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        {columnData.map((colData) => {
          const { columnId, numeric, label, noSort = false } = colData;
          return (
            <TableCell
              key={columnId}
              type="head"
              sortDirection={!noSort && orderBy === columnId ? order : false}
              onHeaderClick={onSelectAllClick}
              {...colData}
            >
              {!noSort ? (
                <Tooltip
                  title={label}
                  placement={numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <MuiTableSortLabel
                    active={orderBy === columnId}
                    direction={order}
                    // IconComponent={order === 'asc' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    onClick={createSortHandler(columnId)}
                  >
                    <div>{label}</div>
                  </MuiTableSortLabel>
                </Tooltip>
              ) : (
                <div>{label}</div>
              )}
            </TableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TableHead;
