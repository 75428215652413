export const loading = (type) => {
  return {
    type,
  };
};

export const failure = (type, error = "") => {
  return {
    type,
    error,
  };
};

export const success = (type, payload = null) => {
  return {
    type,
    payload,
  };
};
