import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 40,
    left: 0,
    width: "100%",
    height: 76,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  header: {
    position: "relative",
    width: "100%",
    height: 76,
    fontSize: 14,
    paddingTop: 29,
    textAlign: "center",
  },
  title: {
    color: "#000000",
    paddingRight: 10,
  },
  stepText: {
    color: "#777777",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    top: 15,
  },
  backBtn: {
    position: "absolute",
    left: 30,
    top: 15,
  },
  progressBarContainer: {
    height: 7,
    backgroundColor: "#E5ECF6",
  },
  progressBar: {
    height: 7,
    backgroundColor: "#003DA5",
  },
  step1: {
    width: "25%",
  },
  step2: {
    width: "50%",
  },
  step3: {
    width: "75%",
  },
  step4: {
    width: "100%",
  },
  supplierStep1: {
    width: "50%",
  },
  supplierStep2: {
    width: "100%",
  },
}));

const Header = ({ step = 0, assignTo, handlePrevPage, handleClose }) => {
  const classes = useStyles();

  const stepData =
    assignTo === "supplier"
      ? [
          {
            headerTitle: "Step 1: Task Detail",
            class: classes.supplierStep1,
          },
          {
            headerTitle: "Step 2: Update Report and Result",
            class: classes.supplierStep2,
          },
        ]
      : [
          {
            headerTitle: "Step 1: Share Cost",
            class: classes.step1,
          },
          {
            headerTitle: "Step 2: Task Detail",
            class: classes.step2,
          },
          {
            headerTitle: "Step 3: Select Lab",
            class: classes.step3,
          },
          {
            headerTitle: "Step 4: Payer Detail for Invoice",
            class: classes.step4,
          },
        ];

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {step > 0 && (
          <IconButton className={classes.backBtn} onClick={() => handlePrevPage()}>
            <ArrowBackIcon className={classes.arrow} />
          </IconButton>
        )}
        <Typography className={classes.title}>Edit Submission</Typography>
        <Typography className={classes.title}>|</Typography>
        <Typography className={classes.stepText}>{stepData[step].headerTitle}</Typography>
        <IconButton className={classes.closeBtn} onClick={() => handleClose()}>
          <CloseIcon className={classes.arrow} />
        </IconButton>
      </Box>

      <Box className={classes.progressBarContainer}>
        <Box className={classnames(classes.progressBar, stepData[step].class)} />
      </Box>
    </Box>
  );
};

export default Header;
