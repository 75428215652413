import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CsvLink from "./CSVLink";
import ReactTable from "components/table/ReactTable";
import classTable from "./style.module.scss";
import Modal from "components/modal/Modal";
import categoryList from "../mock/dataFilter/categoryList";
import Select from "components/input/Select";
import moment from "moment";
import Dropzone from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";

import SearchTableButton from "components/input/SearchTableButton";
import GifLoading from "components/display/GifLoading";
import { atnDownloadFile, atnUploadFile } from "actions/fileActions";
import { atnGet1506FileUploadHistory, atnImport1506ExcelFile } from "actions/analyticsActions";
import {
  atnGetCategoryEverydayPrograms,
  atnGetCategorySeasonalPrograms,
} from "actions/itemActions";

import { downloadFile } from "utils/files";

const useStyles = makeStyles((theme) => ({
  wrapperBasic: {
    position: "relative",
  },

  boxTable: {
    boxShadow: "0 2px 4px rgb(35 43 58 / 25%)",
    background: "white",
    marginTop: 20,

    "& .MuiTableContainer-root": {
      // overflowX: 'initial',
    },

    "& .MuiTablePagination-toolbar": {
      float: "left",
      paddingLeft: 10,
    },

    "& .wrapper-tooltip": {
      position: "absolute",
      background: "white",
      padding: "10px 10px 15px",
      boxShadow: "0px 2px 4px rgba(35, 43, 58, 0.25)",
      border: "1px solid #C4C4C4",
      zIndex: 99999,
      height: "max-content",
      left: 100,
      bottom: 10,

      "&.bottom": {
        top: 30,
        bottom: "initial",
      },

      "&::before, &::after": {
        content: "",
        position: "absolute",
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderColor: "transparent",
        borderBottom: 0,
      },
      /* Stroke */
      "&::before": {
        left: "80%",
        top: "100%",
        /* If 1px darken stroke slightly */
        borderTopColor: "white",
        borderWidth: 12,
      },
      /* Fill */
      "&::after": {
        left: "80%",
        top: "100%",
        borderTopColor: "red",
        borderWidth: 12,
      },
    },

    "& .tooltip-title": {
      color: "#000000",
      fontWeight: 500,
      fontSize: 14,
    },

    "& .tui-chart-tooltip-normal": {
      color: "#777777",
      fontSize: 12,
      marginTop: 3,
      maxWidth: 298,
    },

    "& .tui-chart-tooltip-body": {
      color: "#777777",
      fontWeight: 500,
      fontSize: 12,
      marginTop: 10,
      maxWidth: 298,
    },

    "& .tui-chart-tooltip-body-title": {
      color: "#AAAAAA",
      fontWeight: 500,
      fontSize: 12,
      marginTop: 15,
    },
  },

  typography: {
    display: "block",
    width: "250px",
    padding: theme.spacing(2),
    cursor: "pointer",

    "&:hover": {
      background: "#EBEDEF",
    },
  },

  allWicText: {
    fontWeight: "500",
    fontSize: 10,
    marginRight: 40,
  },

  allShowing: {
    fontWeight: "normal",
    fontSize: 10,
    marginRight: 30,
  },

  actionExport: {
    position: "relative",
  },

  normalCell: {},

  warningCell: {
    color: "#D32F2F",
    fontWeight: "bold",
  },

  showDetail: {
    cursor: "pointer",

    "&:hover": {
      color: "#287577",
    },
  },

  hintDetail: {
    color: "#777777",
  },

  wrapperTab: {
    width: "100%",
    boxShadow: "none",
    alignItems: "center",
    display: "flex",
    background: "none",

    "& .MuiTabs-indicator": {
      background: "transparent",
    },
  },

  tabHeader: {
    minHeight: "initial",
  },

  boldCell: {
    fontWeight: 900,
  },

  tab: {
    fontSize: 10,
    color: "#777777",
    padding: "6px 13px",
    textDecoration: "none",
    textTransform: "capitalize",
    fontWeight: "normal",
    minWidth: "initial",
    marginRight: 13,
    borderRadius: 2,
    minHeight: 28,
    background: "#EBEDEF",

    "&.MuiTab-textColorPrimary.Mui-selected": {
      color: "#2154A2",
      background: "#AEC5E9",
      boxShadow: "0px 2px 4px rgb(35 43 58 / 25%)",
    },

    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },

    "& svg": {
      marginRight: 12,
      marginBottom: "0 !important",
    },
  },
  avgOver: {
    fontWeight: 900,
    color: "#D42600",
    background: "#FFDDD6",

    "&:hover": {
      background: "#eeeeee",
    },
  },
  avgBelow: {
    fontWeight: 900,
    color: "#1BB135",
    background: "#F4FEEF",

    "&:hover": {
      background: "#eeeeee",
    },
  },
  avgBetween: {
    fontWeight: 900,
    color: "#003DA5",
    background: "#E5ECF6",

    "&:hover": {
      background: "#eeeeee",
    },
  },
  selectUpload: {
    display: "block",
    marginTop: 10,

    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
  },
  titleSelect: {
    display: "block",
    marginTop: 20,
  },
  groupClearBtn: {
    float: "right",
    marginTop: 30,
  },
  groupClearBtnUp: {
    marginTop: 30,
    position: "absolute",
    bottom: -30,
    right: 40,
  },
  textClear: {
    color: "#777777",
    fontSize: 12,
  },
  select: {
    width: 280,
    height: 40,

    "& .MuiOutlinedInput-input": {
      fontSize: 14,
    },
  },
  groupUpHistory: {
    paddingRight: 40,
    borderRight: "1px solid #AAAAAA",
    position: "relative",
    paddingBottom: 50,
    marginBottom: 40,
  },
  titleHistory: {
    color: "#232B3A",
    fontSize: 16,
    fontWeight: 500,
  },
  subTilHistory: {
    color: "#777777",
    fontSize: 15,
    marginTop: 20,
  },
  subTilHistoryShow: {
    color: "#777777",
    fontSize: 12,
    marginTop: 15,
  },
  applyBtn: {
    marginLeft: 15,
  },
  discardBtn: {
    border: "solid 2px #D42600",
    color: "#D42600",
    padding: "4px 8px",
  },
  discardPop: {
    "& hr": {
      background: "transparent",
    },
  },
  discardPopContent: {
    paddingTop: 0,
  },
  fileListContainer: {
    width: "fit-content",
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: "1px solid #777777",
  },
  fileItemContainer: {
    paddingBottom: 5,
  },
  fileName: {
    paddingTop: 5,
    paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  deleteFileBtn: {
    cursor: "pointer",
    paddingTop: 5,
  },
  deleteFileIcon: {
    color: "#777777",
    width: 14,
    height: 14,
  },
  showError: {
    marginTop: 18,
    color: "#D42600",
  },
  fileNameUpload: {
    fontWeight: 500,
    fontSize: 12,
  },
  fileTime: {
    color: "#CCCCCC",
    fontSize: 10,
    marginTop: 6,
  },
  download: {
    color: "#005EFF",
    fontWeight: 500,
    fontSize: 14,
    textDecoration: "underline",
    cursor: "pointer",
  },
  uploadFileBtn: {
    "& button": {
      color: "#3f51b5",
      border: "1px solid rgba(63, 81, 181, 0.5)",
      background: "transparent",
      padding: "5px 15px",

      "&:hover": {
        border: "1px solid #3f51b5",
        backgroundColor: "rgba(63, 81, 181, 0.04)",
      },

      "& span": {
        fontSize: 14,
      },
    },
  },

  wrapSelectTable: {
    borderLeft: "1px solid #C4C8D0",
    borderRight: "1px solid #C4C8D0",
  },
}));

const Table1506 = ({ tableDataWic = [], tableDataSupplier = [], tableDataFactory = [] }) => {
  const classes = useStyles();

  const sortOnly = { canSort: true, canFilter: false };
  const noSortFilter = { canSort: false, canFilter: false };

  const isValidNumber = (number) => {
    if (number === null || number === "") return false;
    return true;
  };

  const numberFormated = (number) => {
    return (Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100).toLocaleString();
  };

  const dataColumn = [
    {
      id: "wicNumber",
      // value: 'wicNumber',
      value: ({ wicNumber, productName }) => {
        return { wicNumber, productName };
      },
      label: "WIC Name",
      ...noSortFilter,
      width: 170,
      render: ({
        cell: {
          value: { wicNumber, productName },
        },
      }) => {
        return (
          <Box className={classnames(classes.showDetail, "hasTooltip")}>
            <Box className="wicNumber">{wicNumber}</Box>
            <Box className={classes.hintDetail}>{productName}</Box>
          </Box>
        );
      },
    },
    {
      id: "supplierName",
      value: "supplierName",
      label: "Supplier Name",
      ...noSortFilter,
      width: 200,
      render: ({ cell: { value: supplierName } }) => {
        return <Box className={classes.showDetail}>{supplierName}</Box>;
      },
    },
    {
      id: "factoryName",
      value: "factoryName",
      label: "Factory",
      ...noSortFilter,
      width: 200,
      render: ({ cell: { value: factoryName } }) => {
        return <Box className={classes.showDetail}>{factoryName}</Box>;
      },
    },
    {
      id: "noOfSuppliers",
      value: "noOfSuppliers",
      label: "No. of Supplier",
      className: classTable.boldHead,
      ...noSortFilter,
      width: 100,
      render: ({ cell: { value: noOfSuppliers } }) => {
        return (
          <Box className={classnames(classes.showDetail, "hasTooltip")}>
            {!isValidNumber(noOfSuppliers) ? `-` : numberFormated(noOfSuppliers)}
          </Box>
        );
      },
    },
    {
      id: "noOfFactories",
      value: "noOfFactories",
      label: "No. of Factory",
      className: classTable.boldHead,
      ...noSortFilter,
      width: 100,
      render: ({ cell: { value: noOfFactories } }) => {
        return (
          <Box className={classnames(classes.showDetail, "hasTooltip")}>
            {!isValidNumber(noOfFactories) ? `-` : numberFormated(noOfFactories)}
          </Box>
        );
      },
    },
    {
      id: "noOfWics",
      value: "noOfWics",
      label: "No. of WIC",
      className: classTable.boldHead,
      ...noSortFilter,
      width: 100,
      render: ({ cell: { value: noOfWics } }) => {
        return (
          <Box className={classnames(classes.showDetail, "hasTooltip")}>
            {!isValidNumber(noOfWics) ? `-` : numberFormated(noOfWics)}
          </Box>
        );
      },
    },
    {
      id: "noOfPrograms",
      value: "noOfPrograms",
      label: "No. of Program",
      className: classTable.boldHead,
      ...noSortFilter,
      width: 100,
      render: ({ cell: { value: noOfPrograms } }) => {
        return (
          <Box className={classnames(classes.showDetail, "hasTooltip")}>
            {!isValidNumber(noOfPrograms) ? `-` : numberFormated(noOfPrograms)}
          </Box>
        );
      },
    },
    {
      id: "rateInUnit",
      value: "rateInUnit",
      label: "Average 1506% in unit",
      className: classTable.boldHead,
      ...sortOnly,
      render: ({ cell: { value: rateInUnit } }) => {
        return !isValidNumber(rateInUnit) ? (
          <Box>-</Box>
        ) : (
          <Box
            className={
              parseFloat(rateInUnit) > 2
                ? classes.avgOver
                : parseFloat(rateInUnit) <= 1
                ? classes.avgBelow
                : classes.avgBetween
            }
          >
            {parseFloat(rateInUnit) + "%"}
          </Box>
        );
      },
    },
    {
      id: "rateInValue",
      value: "rateInValue",
      label: "Average 1506% in value",
      className: classTable.boldHead,
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: rateInValue } }) => {
        return !isValidNumber(rateInValue) ? (
          <Box>-</Box>
        ) : (
          <Box
            className={
              parseFloat(rateInValue) > 2
                ? classes.avgOver
                : parseFloat(rateInValue) <= 1
                ? classes.avgBelow
                : classes.avgBetween
            }
          >
            {parseFloat(rateInValue) + "%"}
          </Box>
        );
      },
    },
    {
      id: "sellThroughRate",
      value: "sellThroughRate",
      label: "Average Sale Through%",
      className: classTable.boldHead,
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: sellThroughRate } }) => {
        return (
          <Box className={classes.boldCell}>
            {!isValidNumber(sellThroughRate) ? `-` : sellThroughRate + "%"}
          </Box>
        );
      },
    },
    {
      id: "customerReturn",
      value: "customerReturn",
      label: "CUSTOMER RETURN",
      ...sortOnly,
      render: ({ cell: { value: customerReturn } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(customerReturn) ? `-` : numberFormated(customerReturn)}
          </Box>
        );
      },
    },
    {
      id: "damaged",
      value: "damaged",
      label: "DAMAGED",
      ...sortOnly,
      render: ({ cell: { value: damaged } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(damaged) ? `-` : numberFormated(damaged)}
          </Box>
        );
      },
    },
    {
      id: "damagedInTransit",
      value: "damagedInTransit",
      label: "DAMAGED IN TRANSIT",
      ...sortOnly,
      render: ({ cell: { value: damagedInTransit } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(damagedInTransit) ? `-` : numberFormated(damagedInTransit)}
          </Box>
        );
      },
    },
    {
      id: "damagedPackaging",
      value: "damagedPackaging",
      label: "DAMAGED PACKAGING",
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: damagedPackaging } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(damagedPackaging) ? `-` : numberFormated(damagedPackaging)}
          </Box>
        );
      },
    },
    {
      id: "dcPoorPackaging",
      value: "dcPoorPackaging",
      label: "DC POOR PACKAGING",
      ...sortOnly,
      render: ({ cell: { value: dcPoorPackaging } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(dcPoorPackaging) ? `-` : numberFormated(dcPoorPackaging)}
          </Box>
        );
      },
    },
    {
      id: "dcRazorCut",
      value: "dcRazorCut",
      label: "DC RAZOR CUT",
      ...sortOnly,
      width: 110,
      render: ({ cell: { value: dcRazorCut } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(dcRazorCut) ? "-" : numberFormated(dcRazorCut)}
          </Box>
        );
      },
    },
    {
      id: "outdated",
      value: "outdated",
      label: "OUTDATED",
      ...sortOnly,
      width: 110,
      render: ({ cell: { value: outdated } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(outdated) ? `-` : numberFormated(outdated)}
          </Box>
        );
      },
    },
    {
      id: "authorizedReturn",
      value: "authorizedReturn",
      label: "AUTHORIZED RETURN",
      ...sortOnly,
      render: ({ cell: { value: authorizedReturn } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(authorizedReturn) ? `-` : numberFormated(authorizedReturn)}
          </Box>
        );
      },
    },
    {
      id: "damagedDonatable",
      value: "damagedDonatable",
      label: "DAMAGED-DONATABLE",
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: damagedDonatable } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(damagedDonatable) ? `-` : numberFormated(damagedDonatable)}
          </Box>
        );
      },
    },
    {
      id: "broken",
      value: "broken",
      label: "BROKEN",
      ...sortOnly,
      width: 110,
      render: ({ cell: { value: broken } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(broken)
              ? `-`
              : (Math.round((parseFloat(broken) + Number.EPSILON) * 100) / 100).toLocaleString()}
          </Box>
        );
      },
    },
    {
      id: "soonToExpireDonate",
      value: "soonToExpireDonate",
      label: "SOON TO EXPIRE-DONATE",
      ...sortOnly,
      width: 180,
      render: ({ cell: { value: soonToExpireDonate } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(soonToExpireDonate) ? `-` : numberFormated(soonToExpireDonate)}
          </Box>
        );
      },
    },
    {
      id: "soonToExpire",
      value: "soonToExpire",
      label: "SOON TO EXPIRE",
      ...sortOnly,
      render: ({ cell: { value: soonToExpire } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(soonToExpire) ? `-` : numberFormated(soonToExpire)}
          </Box>
        );
      },
    },
    {
      id: "totalClaim",
      value: "totalClaim",
      label: "Total Claim",
      className: classTable.boldHead,
      ...sortOnly,
      render: ({ cell: { value: totalClaim } }) => {
        return (
          <Box className={classes.normalCell}>
            {!isValidNumber(totalClaim) ? "-" : numberFormated(totalClaim)}
          </Box>
        );
      },
    },
    {
      id: "totalSellUnit",
      value: "totalSellUnit",
      label: "Total Sell unit",
      className: classTable.boldHead,
      ...sortOnly,
      render: ({ cell: { value: totalSellUnit } }) => {
        return (
          <Box className={classes.normalCell}>
            {typeof parseFloat(totalSellUnit) !== "number" ? "-" : numberFormated(totalSellUnit)}
          </Box>
        );
      },
    },
  ];

  const [tableColumn, setTableColumn] = useState(
    dataColumn.filter(
      (item) =>
        item.id !== "supplierName" &&
        item.id !== "factoryName" &&
        item.id !== "noOfSuppliers" &&
        item.id !== "noOfFactories" &&
        item.id !== "noOfWics" &&
        item.id !== "noOfPrograms",
    ),
  );

  const [pageSize, setPageSize] = useState(10);
  const [newPage, setNewPage] = useState(0);

  const callBackPagingData = (data) => {
    setPageSize(data.pageSize);
    setNewPage(data.newPage);
  };

  // ========= Export data =========
  const dataHeader = [
    { label: "WIC", key: "wicNumber" },
    { label: "Supplier", key: "supplierName" },
    { label: "Factory", key: "factoryName" },

    { label: "No. of Factory", key: "noOfFactories" },
    { label: "No. of Supplier", key: "noOfSuppliers" },
    { label: "No. of WIC", key: "noOfWics" },
    { label: "No. of Program", key: "noOfPrograms" },

    { label: "Average 1506% in unit", key: "rateInUnitNew" },
    { label: "Average 1506% in value", key: "rateInValueNew" },
    { label: "Average Sale Through%", key: "sellThroughRateNew" },
    { label: "CUSTOMER RETURN", key: "customerReturnNew" },
    { label: "DAMAGED IN TRANSIT", key: "damagedInTransitNew" },
    { label: "DAMAGED PACKAGING", key: "damagedPackagingNew" },
    { label: "DC POOR PACKAGING", key: "dcPoorPackagingNew" },
    { label: "DC RAZOR CUT", key: "dcRazorCutNew" },
    { label: "OUTDATED", key: "outdatedNew" },
    { label: "AUTHORIZED RETURN", key: "authorizedReturnNew" },
    { label: "DAMAGED-DONATABLE", key: "damagedDonatableNew" },
    { label: "BROKEN", key: "brokenNew" },
    { label: "SOON TO EXPIRE-DONATE", key: "soonToExpireDonateNew" },
    { label: "SOON TO EXPIRE", key: "soonToExpireNew" },
    { label: "DAMAGED", key: "damagedNew" },
    { label: "Total Claim", key: "totalClaimNew" },
    { label: "Total Sell unit", key: "totalSellUnit" },
  ];
  const [headerExport, setHeaderExport] = useState(
    dataHeader.filter(
      (item) =>
        item.key !== "supplierName" &&
        item.key !== "factoryName" &&
        item.key !== "noOfSuppliers" &&
        item.key !== "noOfFactories" &&
        item.key !== "noOfWics" &&
        item.key !== "noOfPrograms",
    ),
  );
  const [dataExport, setDataExport] = useState(tableDataWic);
  // ========= End Export data =========

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idExport = open ? "simple-popover" : undefined;

  const [tab, setTab] = useState(0);
  const [nameTable, setNameTable] = useState("WIC");

  const [customDataTable, setCustomDataTable] = useState(tableDataWic);

  const handleTabChange = (event, value) => {
    setSearchQueryData("");

    switch (value) {
      case 0:
        setCustomDataTable(tableDataWic);
        setNameTable("WIC");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "supplierName" &&
              item.id !== "factoryName" &&
              item.id !== "noOfSuppliers" &&
              item.id !== "noOfFactories" &&
              item.id !== "noOfWics" &&
              item.id !== "noOfPrograms",
          ),
        );

        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "supplierName" &&
              item.key !== "factoryName" &&
              item.key !== "noOfSuppliers" &&
              item.key !== "noOfFactories" &&
              item.key !== "noOfWics" &&
              item.key !== "noOfPrograms",
          ),
        );
        setDataExport(tableDataWic);
        break;
      case 1:
        setCustomDataTable(tableDataSupplier);
        setNameTable("Supplier");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "wicNumber" && item.id !== "factoryName" && item.id !== "noOfSuppliers",
          ),
        );
        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "wicNumber" &&
              item.key !== "factoryName" &&
              item.key !== "noOfSuppliers",
          ),
        );
        setDataExport(tableDataSupplier);
        break;
      case 2:
        setCustomDataTable(tableDataFactory);
        setNameTable("Factory");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "wicNumber" && item.id !== "supplierName" && item.id !== "noOfFactories",
          ),
        );
        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "wicNumber" &&
              item.key !== "supplierName" &&
              item.key !== "noOfFactories",
          ),
        );
        setDataExport(tableDataFactory);
        break;
      default:
        break;
    }
    setTab(value);
  };

  useEffect(() => {
    handleTabChange(0, tab);
  }, [tableDataWic]);

  // ======================= HANDLE FOR POPUP =======================
  const [openImportExcelPop, setImportExcelPop] = useState(false);
  const handlePopImportExcel = () => {
    setImportExcelPop(!openImportExcelPop);
  };

  const [openDiscardImPop, setDiscardImPop] = useState(false);
  const handleDiscardChange = () => {
    setDiscardImPop(true);
  };
  const handlePopDiscardIm = () => {
    setDiscardImPop(false);
    setImportExcelPop(false);
    setImCategory("");
    setImProgram("");
    setImProgramYear("");
    setImportFiles(null);
  };
  const handleKeepEditImport = () => {
    setImportExcelPop(true);
    setDiscardImPop(false);
  };

  const [openHistoryPop, setHistoryPop] = useState(false);
  const handlePopHistory = () => {
    setHistoryPop(!openHistoryPop);
    setOriginHistoryMsg(true);
  };
  const [openDiscardUpPop, setDiscardUpPop] = useState(false);
  const handleDiscardChangeUp = () => {
    setDiscardUpPop(true);
  };
  const handlePopDiscardUpload = () => {
    setDiscardUpPop(false);
    setHistoryPop(false);
    setUpCategory("");
    setUpProgram("");
    setUpProgramYear("");
    setListFileUploaded([]);
  };
  const handleKeepEditUpload = () => {
    setDiscardUpPop(false);
  };

  const [openUpdateSuccess, setUpdateSuccessPop] = useState(false);
  const openUpdateSuccessPop = () => {
    setUpdateSuccessPop(true);
  };
  const closeUpdateSuccessPop = () => {
    setUpdateSuccessPop(false);
  };

  const [programYears, setProgramYears] = useState([]);
  const [programList, setProgramList] = useState([
    { label: "Select Program", value: "" },
    { label: "Xmas", value: "Xmas" },
  ]);
  const [programListSeasonal, setProgramListSeasonal] = useState([
    { label: "Select Program", value: "" },
    { label: "Xmas", value: "Xmas" },
  ]);
  const [programListEveryday, setProgramListEveryday] = useState([
    { label: "Select Program", value: "" },
    { label: "Xmas", value: "Xmas" },
  ]);

  useEffect(() => {
    // ======== SET PROGRAM LIST ========
    atnGetCategorySeasonalPrograms({}).then(({ data, message }) => {
      if (!data) return;

      let programs = [{ label: "Select Program", value: "" }];
      for (let i = 0; i < data.length; i++) {
        programs.push({
          label: data[i].programName,
          value: data[i].programName,
        });
      }
      setProgramList(programs);
      setProgramListSeasonal(programs);
    });

    atnGetCategoryEverydayPrograms({}).then(({ data, message }) => {
      if (!data) return;
      let programs = [{ label: "Select Program", value: "" }];
      for (let i = 0; i < data.length; i++) {
        programs.push({
          label: data[i].programName,
          value: data[i].programName,
        });
      }
      setProgramListEveryday(programs);
    });

    // ======== SET PROGRAM YEAR ========
    const year = moment().year();
    const startYear = 2019;
    const programYearData = [{ label: "Select Year", value: "" }];
    for (let i = startYear; i < year + 3; i++) {
      programYearData.push({ label: i, value: i });
    }

    setProgramYears(programYearData);
  }, []);

  // ========== Import State File =================
  const [imCategory, setImCategory] = useState("");
  const handleChangeImCategory = (e) => {
    let value = e.target.value;
    setImCategory(value);
    setProgramList(value === "seasonal" ? programListSeasonal : programListEveryday);
  };

  const [imProgram, setImProgram] = useState("");
  const handleChangeImProgram = (e) => {
    setImProgram(e.target.value);
  };
  const [imProgramYear, setImProgramYear] = useState("");
  const handleChangeImProgramYear = (e) => {
    setImProgramYear(e.target.value);
  };

  // =========== Upload History State ===================
  const [upCategory, setUpCategory] = useState("");
  const handleChangeUpCategory = (e) => {
    let value = e.target.value;
    setUpCategory(value);
    setProgramList(value === "seasonal" ? programListSeasonal : programListEveryday);
  };

  const [upProgram, setUpProgram] = useState("");
  const handleChangeUpProgram = (e) => {
    setUpProgram(e.target.value);
  };
  const [upProgramYear, setUpProgramYear] = useState("");
  const handleChangeUpProgramYear = (e) => {
    setUpProgramYear(e.target.value);
  };

  // ============ Handle Add / Remove Files ============
  const [loadingImportFile, setLoadingImportFile] = useState(false);
  const [importFiles, setImportFiles] = useState(null);
  const [idFileUpload, setIdFileUpload] = useState(null);

  const handleRemoveFilesImport = (index) => {
    setImportFiles(null);
  };
  const onDrop = useCallback((acceptedFiles) => {
    setImportFiles(acceptedFiles[0]);

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    atnUploadFile(formData).then(({ data, message }) => {
      if (data) {
        setIdFileUpload(data.id);
      }
    });
  }, []);

  const confirmUploadExcelFile = () => {
    setLoadingImportFile(true);

    const formData = new FormData();
    formData.append("file", importFiles);
    formData.append("programName", imProgram);
    formData.append("programYear", imProgramYear);
    formData.append("fileId", idFileUpload);
    formData.append("category", imCategory);
    formData.append("overwrite", "no");

    atnImport1506ExcelFile(formData).then(async (data) => {
      setLoadingImportFile(false);

      if (data.data) {
        openUpdateSuccessPop();
        handlePopImportExcel();

        setDiscardImPop(false);
        setImportExcelPop(false);
        setImCategory("");
        setImProgram("");
        setImProgramYear("");
        setImportFiles(null);
        handleClose();
      } else {
        alert("Please select correct file type and formart file");
      }
    });
  };

  const [listFileUploaded, setListFileUploaded] = useState([]);
  const [originHistoryMsg, setOriginHistoryMsg] = useState(true);

  const handleViewUploadHistory = () => {
    setLoadingImportFile(true);
    let obj = { category: upCategory, programName: upProgram, programYear: upProgramYear };
    atnGet1506FileUploadHistory(obj).then(({ data, message }) => {
      setOriginHistoryMsg(false);
      setListFileUploaded(data);
      setLoadingImportFile(false);
    });
  };

  const downloadHistoryFile = (location, fileName) => {
    atnDownloadFile(location).then(async (data) => {
      downloadFile(data, fileName);
    });
  };

  // ======================= END HANDLE FOR POPUP =======================

  // ======================= HANDLE MOUSE OVER TABLE 1506 =========================
  const mouseOverTable1506 = (e) => {
    let hasToolTip = e.currentTarget.firstElementChild.classList.contains("hasTooltip");
    if (!hasToolTip) return false;

    switch (nameTable) {
      case "WIC":
        showToolTipWIC(e);
        break;
      case "Supplier":
        showToolTipSupplier(e);
        break;
      case "Factory":
        showToolTipFactory(e);
        break;
      default:
        break;
    }
  };

  const mouseOutTable1506 = (e) => {
    document.getElementById("wrapper-tooltip-1506") &&
      document.getElementById("wrapper-tooltip-1506").remove();
  };

  const handleProgram = (programs) => {
    let array = [];
    for (let name in programs) {
      let child = ``;
      for (let i = 0; i < programs[name].length; i++) {
        child += programs[name][i] + (i + 1 === programs[name].length ? `` : `, `);
      }
      array.push({ name: name, year: child });
    }

    let item = ``;
    for (let j = 0; j < array.length; j++) {
      item += `<div>${array[j].name} : ${array[j].year}</div>`;
    }
    return item;
  };

  const handleSupplier = (suppliers) => {
    let item = ``;
    for (let i = 0; i < suppliers.length; i++) {
      item += `<div>${suppliers[i]}</div>`;
    }
    return item;
  };

  const handleFactory = (factories) => {
    let item = ``;
    for (let i = 0; i < factories.length; i++) {
      item += `<div>${factories[i]}</div>`;
    }
    return item;
  };

  const handleWIC = (wics) => {
    let item = ``;
    for (let i = 0; i < wics.length; i++) {
      item += wics[i] + (i + 1 === wics.length ? `` : `, `);
    }
    return item;
  };

  const showToolTipWIC = (e) => {
    let wicNumber = e.currentTarget.getElementsByClassName("wicNumber")[0].innerText;
    let rowIndex = e.currentTarget.parentElement.rowIndex;
    let itemSelected = tableDataWic.filter((e) => e.wicNumber[0] === wicNumber);

    if (!itemSelected.length) return;

    e.currentTarget.insertAdjacentHTML(
      "beforeend",
      `<div id="wrapper-tooltip-1506" class="${
        rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
      }">
          <div class="tui-chart-default-tooltip">
            <div class="tooltip-title">WIC : ${wicNumber}</div>
            <div class="tui-chart-tooltip-normal">${itemSelected[0].productName}</div>
            <div class="tui-chart-tooltip-body">${handleProgram(itemSelected[0].programs)}</div>
            <div class="tui-chart-tooltip-body">${handleSupplier(
              itemSelected[0].supplierName,
            )}</div>
            <div class="tui-chart-tooltip-body">${handleFactory(itemSelected[0].factoryName)}</div>
          </div>
        </div>`,
    );
  };

  const showToolTipSupplier = (e) => {
    let supplierName = e.currentTarget.parentElement.firstElementChild.innerText;
    let rowIndex = e.currentTarget.parentElement.rowIndex;
    let itemSelected = tableDataSupplier.filter(
      (item) => item.supplierName[0].split(" ").join("") === supplierName.split(" ").join(""),
    );
    let index = e.currentTarget.cellIndex;

    if (!itemSelected.length) return;

    switch (index) {
      case 1: // No. of Factory
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${supplierName}</div>
              <div class="tui-chart-tooltip-body-title">Involved Factory : </div>
              <div class="tui-chart-tooltip-body">${handleFactory(
                itemSelected[0].factoryName,
              )}</div>
            </div>
          </div>`,
        );
        break;
      case 2: // No. of WIC
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${supplierName}</div>
              <div class="tui-chart-tooltip-body-title">Involved WIC :</div>
              <div class="tui-chart-tooltip-body">${handleWIC(itemSelected[0].wicNumber)}</div>
            </div>
          </div>`,
        );
        break;
      case 3: // No. of Program
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${supplierName}</div>
              <div class="tui-chart-tooltip-body-title">Involved Program :</div>
              <div class="tui-chart-tooltip-body">${handleProgram(itemSelected[0].programs)}</div>
            </div>
          </div>`,
        );
        break;
      default:
        break;
    }
  };

  const showToolTipFactory = (e) => {
    let factoryName = e.currentTarget.parentElement.firstElementChild.innerText;
    let rowIndex = e.currentTarget.parentElement.rowIndex;
    let itemSelected = tableDataFactory.filter(
      (e) => e.factoryName[0].split(" ").join("") === factoryName.split(" ").join(""),
    );
    let index = e.currentTarget.cellIndex;

    if (!itemSelected.length) return;

    switch (index) {
      case 1: // No. of Supplier
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${factoryName}</div>
              <div class="tui-chart-tooltip-body-title">Involved Supplier : </div>
              <div class="tui-chart-tooltip-body">${handleSupplier(
                itemSelected[0].supplierName,
              )}</div>
            </div>
          </div>`,
        );
        break;
      case 2: // No. of WIC
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${factoryName}</div>
              <div class="tui-chart-tooltip-body-title">Involved WIC :</div>
              <div class="tui-chart-tooltip-body">${handleWIC(itemSelected[0].wicNumber)}</div>
            </div>
          </div>`,
        );
        break;
      case 3: // No. of Program
        e.currentTarget.insertAdjacentHTML(
          "beforeend",
          `<div id="wrapper-tooltip-1506" class="${
            rowIndex > 1 ? `wrapper-tooltip` : `wrapper-tooltip bottom`
          }">
            <div class="tui-chart-default-tooltip">
              <div class="tooltip-title">${factoryName}</div>
              <div class="tui-chart-tooltip-body-title">Involved Program :</div>
              <div class="tui-chart-tooltip-body">${handleProgram(itemSelected[0].programs)}</div>
            </div>
          </div>`,
        );
        break;
      default:
        break;
    }
  };

  // ======================= END HANDLE MOUSE OVER TABLE 1506 =========================

  const [searchQueryData, setSearchQueryData] = useState("");
  const cbHandleSearch = (data) => {
    setSearchQueryData(data);
    handleFilteredList(data);
  };

  const handleFilteredList = (searchQueryData) => {
    setLoadingImportFile(true);
    let listKeySearch = searchQueryData
      .split(new RegExp("[,]", "g"))
      .map((item, index) => item.trim());
    let result = [];

    switch (nameTable) {
      case "WIC":
        result = tableDataWic.filter((item) => {
          return listKeySearch.length === 1 && listKeySearch[0] === ""
            ? item
            : listKeySearch.filter((itemSearch) =>
                item.wicNumber[0].toLowerCase().includes(itemSearch.toLowerCase()),
              ).length > 0 ||
                listKeySearch.filter((itemSearch) =>
                  item.productName.toLowerCase().includes(itemSearch.toLowerCase()),
                ).length > 0;
        });
        break;
      case "Supplier":
        result = tableDataSupplier.filter((item) => {
          return listKeySearch.length === 1 && listKeySearch[0] === ""
            ? item
            : listKeySearch.filter((itemSearch) =>
                item.supplierName[0].toLowerCase().includes(itemSearch.toLowerCase()),
              ).length > 0;
        });
        break;
      case "Factory":
        result = tableDataFactory.filter((item) => {
          return listKeySearch.length === 1 && listKeySearch[0] === ""
            ? item
            : listKeySearch.filter((itemSearch) =>
                item.factoryName[0].toLowerCase().includes(itemSearch.toLowerCase()),
              ).length > 0;
        });
        break;
      default:
        break;
    }

    setCustomDataTable(result);

    let timeOutSearch = null;
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(() => {
      setLoadingImportFile(false);
    }, 300);
  };

  const clearKeyword = () => {
    let timeOut = null;
    clearTimeout(timeOut);
    setLoadingImportFile(true);
    timeOut = setTimeout(() => {
      setLoadingImportFile(false);
    }, 500);

    setSearchQueryData("");

    switch (tab) {
      case 0:
        setCustomDataTable(tableDataWic);
        setNameTable("WIC");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "supplierName" &&
              item.id !== "factoryName" &&
              item.id !== "noOfSuppliers" &&
              item.id !== "noOfFactories" &&
              item.id !== "noOfWics" &&
              item.id !== "noOfPrograms",
          ),
        );

        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "supplierName" &&
              item.key !== "factoryName" &&
              item.key !== "noOfSuppliers" &&
              item.key !== "noOfFactories" &&
              item.key !== "noOfWics" &&
              item.key !== "noOfPrograms",
          ),
        );
        setDataExport(tableDataWic);
        break;
      case 1:
        setCustomDataTable(tableDataSupplier);
        setNameTable("Supplier");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "wicNumber" && item.id !== "factoryName" && item.id !== "noOfSuppliers",
          ),
        );
        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "wicNumber" &&
              item.key !== "factoryName" &&
              item.key !== "noOfSuppliers",
          ),
        );
        setDataExport(tableDataSupplier);
        break;
      case 2:
        setCustomDataTable(tableDataFactory);
        setNameTable("Factory");
        setTableColumn(
          dataColumn.filter(
            (item) =>
              item.id !== "wicNumber" && item.id !== "supplierName" && item.id !== "noOfFactories",
          ),
        );
        setHeaderExport(
          dataHeader.filter(
            (item) =>
              item.key !== "wicNumber" &&
              item.key !== "supplierName" &&
              item.key !== "noOfFactories",
          ),
        );
        setDataExport(tableDataFactory);
        break;
      default:
        break;
    }
  };

  return (
    <Box className={classes.wrapperBasic} padding="20px 0">
      {loadingImportFile && <GifLoading />}

      <Box display="flex" alignItems="center" paddingBottom="20px">
        <Box marginRight="20px">
          <SearchTableButton
            cbHandleSearch={cbHandleSearch}
            value={searchQueryData}
            handleFilteredList={handleFilteredList}
          />
        </Box>

        <Box className={classes.wrapSelectTable} padding="0 10px 0 20px">
          <Paper className={classes.wrapperTab}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabHeader}
            >
              <Tab label="WIC" className={classes.tab} />
              <Tab label="Supplier" className={classes.tab} />
              <Tab label="Factory" className={classes.tab} />
            </Tabs>
          </Paper>
        </Box>

        <Box display="block" className={classes.actionExport} marginLeft="20px">
          <Button
            aria-describedby={idExport}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Actions <ArrowDropDownIcon />
          </Button>
          <Popover
            id={idExport}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography className={classes.typography}>
              <CsvLink
                header={headerExport}
                data={dataExport}
                fileName={`table_1506_${nameTable}.csv`}
                text="Export to Excel"
              />
            </Typography>

            <Typography className={classes.typography} onClick={handlePopImportExcel}>
              Import 1506 Excel
            </Typography>
            <Typography className={classes.typography} onClick={handlePopHistory}>
              View File Upload History
            </Typography>
          </Popover>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" padding="0">
        <Box display="block" className={classes.allWicText}>
          Viewing All {nameTable} Item
        </Box>
        <Box display="block" className={classes.allShowing}>{`Showing ${
          newPage * pageSize + 1 || 0
        } - ${
          (newPage * pageSize + pageSize || 0) > customDataTable.length
            ? customDataTable.length || 0
            : newPage * pageSize + pageSize || 0
        } of ${customDataTable.length}`}</Box>
      </Box>

      <Box className={classes.boxTable}>
        <ReactTable
          cbSetDataPagingTable={callBackPagingData}
          columns={tableColumn}
          data={customDataTable}
          hasPagingTop={true}
          sizeOfPage={20}
          hasResetData={true}
          hasResetDataLength={searchQueryData}
          handleResetData={clearKeyword}
          rowsPerPageOptions={[20, 50, 100, { label: "All", value: customDataTable.length }]}
          classes={{
            root: nameTable === "WIC" ? classTable.table1506WIC : classTable.table1506,
            viewColumnIcon: classTable.viewColumnIcon,
          }}
          onMouseOver={mouseOverTable1506}
          onMouseOut={mouseOutTable1506}
        />
      </Box>

      {/* =============== POPUP - IMPORT 1506% =============== */}
      <Modal
        title="Import 1506% Excel File"
        open={openImportExcelPop}
        handleClose={handleDiscardChange}
        size="normal"
      >
        <Box display="block" className={classes.textClear}>
          Import new file for table.
        </Box>
        <Box className={classes.groupSelect}>
          <Typography className={classes.titleSelect}>1. Category</Typography>
          <Box className={classes.selectUpload}>
            <Select
              label="Select Category"
              name="category"
              value={imCategory}
              options={categoryList.data.list}
              handleChange={handleChangeImCategory}
              additionalClass={classes.select}
            />
          </Box>

          <Typography className={classes.titleSelect}>2. Program</Typography>
          <Box className={classes.selectUpload}>
            <Select
              label="Select Program"
              name="program"
              value={imProgram}
              options={programList}
              handleChange={handleChangeImProgram}
              additionalClass={classes.select}
            />
          </Box>

          <Typography className={classes.titleSelect}>3. Program Year</Typography>
          <Box className={classes.selectUpload}>
            <Select
              label="Select Program Year"
              name="program_year"
              value={imProgramYear}
              options={programYears}
              handleChange={handleChangeImProgramYear}
              additionalClass={classes.select}
            />
          </Box>

          <Typography className={classes.titleSelect}>4. Upload File</Typography>
          <Box className={classes.selectUpload}>
            <Box className={classes.uploadFileBtn}>
              {!importFiles && (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className={classes.uploadBtnContainer}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} multiple={false} />
                        <Button variant="contained" color="primary" size="small">
                          Upload Excel File
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
              {importFiles && (
                <Box className={classes.fileListContainer}>
                  <Box display="flex" className={classes.fileItemContainer}>
                    <Box className={classes.fileName}>{importFiles.name}</Box>
                    <Box className={classes.deleteFileBtn}>
                      <CloseIcon
                        className={classes.deleteFileIcon}
                        onClick={handleRemoveFilesImport}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button color="primary" onClick={handlePopImportExcel}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            disabled={!imCategory || !imProgram || !imProgramYear || !importFiles}
            onClick={confirmUploadExcelFile}
          >
            CONFIRM
          </Button>
        </Box>
      </Modal>
      {/* =============== END POPUP - IMPORT 1506% =============== */}

      {/* =============== POPUP - DISCARD CHANGE IMPORT =============== */}
      <Modal
        title="Discard Changes"
        open={openDiscardImPop}
        size="small"
        hideCloseIcon={true}
        className={{ classRoot: classes.discardPop, classContent: classes.discardPopContent }}
      >
        <Box display="flex">
          <Typography className={classes.titleSelectNoMargin}>
            Are you sure you want to discard your changes?
          </Typography>
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button color="primary" className={classes.discardBtn} onClick={handlePopDiscardIm}>
            Discard Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={handleKeepEditImport}
          >
            Keep Editing
          </Button>
        </Box>
      </Modal>
      {/* =============== END POPUP - DISCARD CHANGE IMPORT =============== */}

      {/* =============== POPUP - FILE UPLOAD HISTORY =============== */}
      <Modal
        title="File Upload History "
        open={openHistoryPop}
        handleClose={handleDiscardChangeUp}
        size="normal"
      >
        <Box display="flex">
          <Box className={classes.groupUpHistory}>
            <Typography className={classes.titleSelectNoMargin}>1. Category</Typography>
            <Box className={classes.selectUpload}>
              <Select
                label="Select Category"
                name="category"
                value={upCategory}
                options={categoryList.data.list}
                handleChange={handleChangeUpCategory}
                additionalClass={classes.select}
              />
            </Box>

            <Typography className={classes.titleSelect}>2. Program</Typography>
            <Box className={classes.selectUpload}>
              <Select
                label="Select Program"
                name="program"
                value={upProgram}
                options={programList}
                handleChange={handleChangeUpProgram}
                additionalClass={classes.select}
              />
            </Box>

            <Typography className={classes.titleSelect}>3. Program Year</Typography>
            <Box className={classes.selectUpload}>
              <Select
                label="Select Program Year"
                name="program_year"
                value={upProgramYear}
                options={programYears}
                handleChange={handleChangeUpProgramYear}
                additionalClass={classes.select}
              />
            </Box>

            <Box display="block" className={classes.groupClearBtnUp}>
              <Button color="primary" onClick={handlePopHistory}>
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.applyBtn}
                disabled={!upCategory || !upProgram || !upProgramYear}
                onClick={handleViewUploadHistory}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>

          <Box paddingLeft="20px" width="100%">
            <Box className={classes.titleHistory}>Document History</Box>
            {!listFileUploaded.length && originHistoryMsg && (
              <Box className={classes.subTilHistory}>
                Document result will show here when you apply the choosing item in left side.
              </Box>
            )}

            {!originHistoryMsg && !listFileUploaded.length && (
              <Box className={classes.showError}>
                Sorry , there are no upload document or history.
              </Box>
            )}

            {listFileUploaded && listFileUploaded.length > 0 && (
              <>
                <Box className={classes.subTilHistoryShow}>
                  Dashboard and list Data is based on the lastest uploaded file.
                </Box>
                <Box className={classes.listFile}>
                  {listFileUploaded.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="15px"
                      >
                        <Box display="inline-block">
                          <Box className={classes.fileNameUpload}>{item.fileName}</Box>
                          <Box className={classes.fileTime}>
                            Uploaded date - {moment(item.createdTim).format("YYYY/MM/DD")}
                          </Box>
                        </Box>
                        <Box
                          className={classes.download}
                          onClick={() => downloadHistoryFile(item.location, item.fileName)}
                        >
                          Download
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
      {/* =============== END POPUP - FILE UPLOAD HISTORY =============== */}

      {/* =============== POPUP - DISCARD CHANGE UPLOAD =============== */}
      <Modal
        title="Do you want to leave?"
        open={openDiscardUpPop}
        size="small"
        hideCloseIcon={true}
        className={{ classRoot: classes.discardPop, classContent: classes.discardPopContent }}
      >
        <Box display="flex">
          <Typography className={classes.titleSelectNoMargin}>
            Are you sure you want to discard your choosing?
          </Typography>
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button color="primary" className={classes.discardBtn} onClick={handlePopDiscardUpload}>
            Discard & Leave
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={handleKeepEditUpload}
          >
            Keep Viewing
          </Button>
        </Box>
      </Modal>
      {/* =============== END POPUP - DISCARD CHANGE UPLOAD =============== */}

      {/* =============== POPUP - Confirm msg - Updated Success =============== */}
      <Modal title="Updated Success" open={openUpdateSuccess} size="small" hideCloseIcon={true}>
        <Box display="flex">
          <Typography className={classes.titleSelectNoMargin}>
            Please Reload the page again.
          </Typography>
        </Box>
        <Box display="block" className={classes.groupClearBtn}>
          <Button
            variant="contained"
            color="primary"
            className={classes.applyBtn}
            onClick={closeUpdateSuccessPop}
          >
            OK
          </Button>
        </Box>
      </Modal>
      {/* =============== END POPUP - Confirm msg - Updated Success =============== */}
    </Box>
  );
};

export default Table1506;
