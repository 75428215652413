import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";
import Button from "components/input/Button";
import Divider from "components/display/Divider";
import Table from "components/table/Table";
import Radio from "components/input/Radio";

const useStyles = makeStyles((theme) => ({
  gridContainer: {},
  gridItemRight: {
    paddingLeft: theme.spacing(4),
  },
  container: {
    // width: '100%',
    // margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  bottomButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    backgroundColor: "#005EFF",
    borderColor: "#005EFF",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#232B3A",
  },
  blackText: {
    color: "#000000",
  },
  textLabel: {
    paddingBottom: theme.spacing(1),
  },
  iconButton: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  tableCell: {
    border: "none",
    padding: `${theme.spacing(1)}px`,
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  childrenFullWidth: {
    "& > div:first-child": {
      width: "100%",
    },
  },
  textInputField: {
    width: 150,
  },
  selectField: {
    width: "100%",
  },
}));

const InvoiceUpdate = ({ data }) => {
  const classes = useStyles();

  const { nextAction } = data;

  const handleNext = () => {};

  const List = ({ title, items = [] }) => {
    return (
      <Paper className={classes.container}>
        <Typography className={classnames(classes.title, classes.blackText)}>{title}</Typography>
        <Divider className={classes.divider} />
        {items.map((item) => (
          <Grid key={item.id} container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classnames(classes.text, classes.blackText)}>
                {item.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography className={classnames(classes.text, classes.blackText)}>
                {item.detail}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    );
  };

  const ProductDetails = () => {
    const GridRow = ({ label, value }) => (
      <>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Typography className={classnames(classes.text, classes.blackText)}>{label}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Typography className={classnames(classes.text, classes.blackText)}>{value}</Typography>
        </Grid>
      </>
    );
    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Product Details
        </Typography>
        <Divider className={classes.divider} />
        <Grid container alignItems="center">
          <GridRow label="WIC Number" value="Information Detail" />
          <GridRow label="Product Name" value="Information Detail" />
          <GridRow label="Brand" value="Information Detail" />
          <GridRow label="Sample Photo(s)" value="Information Detail" />
        </Grid>
      </Box>
    );
  };

  const InspectionDetails = () => {
    const InspectionDate = ({ from, to }) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <TextInput
            placeholder="DD/MMM/YYYY"
            name="from"
            value={from}
            onChange={() => {}}
            additionalClass={classes.textInputField}
          />
        </Box>
        <Typography className={classnames(classes.text, classes.blackText)}>To</Typography>
        <Box>
          <TextInput
            placeholder="DD/MMM/YYYY"
            name="to"
            value={to}
            onChange={() => {}}
            additionalClass={classes.textInputField}
          />
        </Box>
      </Box>
    );

    const InspectionLevel = ({ value }) => (
      <Box className={classes.childrenFullWidth}>
        <Select
          name="inspectionLevel"
          value={value.value}
          options={[{ ...value }]}
          // handleChange={}
          additionalClass={classes.selectField}
        />
      </Box>
    );

    const AQLLevel = ({ critical, major, minor }) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className={classes.item}>
          <Typography className={classnames(classes.text, classes.grayText)}>Critical</Typography>
          <TextInput
            placeholder="0"
            name="critical"
            value={critical}
            onChange={() => {}}
            additionalClass={classes.textInputField}
          />
        </Box>
        <Box className={classes.item}>
          <Typography className={classnames(classes.text, classes.grayText)}>Major</Typography>
          <TextInput
            placeholder="0"
            name="major"
            value={major}
            onChange={() => {}}
            additionalClass={classes.textInputField}
          />
        </Box>
        <Box className={classes.item}>
          <Typography className={classnames(classes.text, classes.grayText)}>Minor</Typography>
          <TextInput
            placeholder="0"
            name="minor"
            value={minor}
            onChange={() => {}}
            additionalClass={classes.textInputField}
          />
        </Box>
      </Box>
    );

    const TotalQuantity = ({ value }) => (
      <Box className={classes.childrenFullWidth}>
        <TextInput placeholder="Quantity" name="quantity" value={value} onChange={() => {}} />
      </Box>
    );

    const tableColumn = [
      {
        columnId: "title",
        width: "30%",

        render: ({ title }) => {
          return title;
        },
      },
      {
        columnId: "value",
        render: ({ id, value }) => {
          if (id === 1) return <InspectionDate from={value.from} to={value.to} />;
          else if (id === 2) return <InspectionLevel value={value} />;
          else if (id === 3)
            return <AQLLevel critical={value.critical} major={value.major} minor={value.minor} />;
          else if (id === 4) return <TotalQuantity value={value} />;
        },
      },
    ];

    const tableData = [
      { id: 1, title: "Inspection Date", value: { from: "", to: "" } },
      {
        id: 2,
        title: "Inspection Level",
        value: { label: "General Inspection Levels II", value: 1 },
      },
      { id: 3, title: "AQL Level", value: { critical: 0 } },
      { id: 4, title: "Total Quantity", value: 1 },
    ];

    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Inspection Details
        </Typography>
        <Divider className={classes.divider} />
        <Table data={tableData} columnData={tableColumn} noHeader noPagination />
      </Box>
    );
  };

  const DesignInspectionPlan = () => {
    const InputBlock = ({ name, value }) => (
      <Box className={classes.childrenFullWidth}>
        <TextInput placeholder="" name={name} value={value} onChange={() => {}} />
      </Box>
    );

    const RadioBlock = ({ value }) => (
      <Box pl={1}>
        <Radio
          direction="row"
          name="requestPrePayment"
          options={[
            {
              value: "no",
              label: "No",
            },
            {
              value: "yes",
              label: "Yes",
            },
          ]}
          // defaultValue={}
          // onChange={}
        />
      </Box>
    );

    const tableColumn = [
      {
        columnId: "title",
        width: "30%",

        render: ({ title }) => {
          return title;
        },
      },
      {
        columnId: "value",
        render: ({ id, name, value }) => {
          if (id === 11) return <RadioBlock value={value} />;
          return <InputBlock name={name} value={value} />;
        },
      },
    ];

    const tableData = [
      { id: 1, title: "Number of Mandays", name: "numberMandays", value: 0 },
      { id: 2, title: "Manday Unit Cost", name: "unitCost", value: 0 },
      { id: 3, title: "Travel Expenses", name: "travelExpenses", value: 0 },
      { id: 5, title: "Hotel Expenses", name: "hotelExpenses", value: 1 },
      { id: 6, title: "Other Expenses", name: "otherExpenses", value: 1 },
      { id: 7, title: "Late Booking Fee", name: "lateBookingFee", value: 1 },
      { id: 8, title: "Cancellation Fee", name: "cancellationFee", value: 1 },
      { id: 9, title: "Total Price (USD)", name: "totalPriceUSD", value: 1 },
      { id: 10, title: "Remarks", name: "remarks", value: "Remarks" },
      { id: 11, title: "Request Pre-Payment", value: 1 },
    ];

    return (
      <Box>
        <Typography className={classnames(classes.title, classes.blackText)}>
          Design Inspection Plan
        </Typography>
        <Divider className={classes.divider} />
        <Table data={tableData} columnData={tableColumn} noHeader noPagination />
      </Box>
    );
  };

  return (
    <Grid container justify="space-between" className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <List
          title="Project Details"
          items={[
            { id: 1, title: "Info title", detail: "Information Detail" },
            { id: 2, title: "Info title", detail: "Information Detail" },
            { id: 3, title: "Info title", detail: "Information Detail" },
            { id: 4, title: "Info title", detail: "Information Detail" },
          ]}
        />
        <Box pt={4}>
          <List
            title="Task Details"
            items={[
              { id: 1, title: "Info title", detail: "Information Detail" },
              { id: 2, title: "Info title", detail: "Information Detail" },
              { id: 3, title: "Info title", detail: "Information Detail" },
            ]}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} className={classes.gridItemRight}>
        <Paper className={classes.container}>
          <Box py={4}>
            <ProductDetails />
            <Box py={4}>
              <InspectionDetails />
            </Box>
            <DesignInspectionPlan />
          </Box>
        </Paper>
        <Button className={classes.bottomButton} onClick={handleNext}>
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default InvoiceUpdate;
