import React from "react";
import { v4 as uuidv4 } from "uuid";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";

import classTable from "./style.module.scss";

const TableBody = ({
  data,
  func,
  goToItemDetail,
  renderRowSubComponent,
  indexColHover,
  onMouseOver,
  onMouseOut,
}) => {
  const { rows } = data;
  const { prepareRow } = func;

  const handleGoTo = (row, cell) => {
    if (goToItemDetail && cell.column.id !== "selection") {
      window.open(`/buyer/items/${row.original.masterWicId}`);
    }
  };

  return (
    <MuiTableBody>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <React.Fragment key={uuidv4()}>
            <MuiTableRow key={uuidv4()} {...row.getRowProps()} className={classTable.tableRow}>
              {row.cells.map((cell, index) => (
                <MuiTableCell
                  key={uuidv4()}
                  variant="body"
                  className={cell.classNameCell}
                  onClick={() => handleGoTo(row, cell)}
                  align={cell.align || "left"}
                  width={cell.width || "auto"}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  style={{ verticalAlign: "middle", borderBottom: row.isExpanded ? "none" : "" }}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </MuiTableCell>
              ))}
            </MuiTableRow>
            {row.isExpanded ? (
              <tr key={uuidv4()}>
                <td
                  key={uuidv4()}
                  colSpan={row.cells.length}
                  style={{ padding: 0, borderTop: "none" }}
                >
                  {renderRowSubComponent({ row })}
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      })}
    </MuiTableBody>
  );
};

export default TableBody;
