import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import Select from "components/input/Select";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
    paddingBottom: theme.spacing(2),
  },
  item: {
    display: "flex",
    flexDirection: "column",
    // paddingRight: theme.spacing(8),
    minWidth: "33%",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  cITableContainer: {
    margin: -10,
  },
  cITableCell: {
    border: "none",
  },
  cITableCellBorderRight: {
    borderRight: "1px solid #777777",
  },
  gridContainer: {
    paddingTop: 10,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    borderRight: "none",
    "&:last-child": {
      borderRight: "1px solid #777777",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputSubTitle: {
    fontSize: 10,
    color: "#777777",
  },
  viewText: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputFieldPadding: {
    marginRight: 20,
  },
}));

const FactoryInformation = ({
  data,
  countryList = [],
  stateList = [],
  cityList = [],
  hideState,
  manufactureStateList = [],
  manufactureCityList = [],
  hideManufactureState,
  handleFactoryDataChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>Factory Information</Typography>
      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={2} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Factory Name</Box>
          </Grid>

          <Grid item xs={10} className={classes.firstGrid}>
            <Box className={classes.viewText}>{data.factoryName}</Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2} className={classes.grid}>
            <Box className={classes.inputTitle}>Factory Address</Box>
          </Grid>

          <Grid item xs={10} className={classes.grid}>
            <Box className={classes.viewText}>{data.factoryAddress}</Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2} className={classes.grid}>
            <Box className={classes.inputTitle}>Contact Information</Box>
          </Grid>

          <Grid item xs={3} className={classes.grid}>
            <Box className={classes.inputSubTitle}>Contact Person Name</Box>
            <Box className={classes.viewText}>{data.factoryContactPerson}</Box>
          </Grid>

          <Grid item xs={3} className={classes.grid}>
            <Box className={classes.inputSubTitle}>Contact Person Email</Box>
            <Box className={classes.viewText}>{data.factoryContactEmail}</Box>
          </Grid>

          <Grid item xs={4} className={classes.grid}>
            <Box className={classes.inputSubTitle}>Contact Person Phone Number</Box>
            <Box className={classes.viewText}>{data.factoryContactNumber}</Box>
          </Grid>
          {/* <Grid item xs={1} className={classes.grid} /> */}
        </Grid>

        <Grid container>
          <Grid item xs={2} className={classes.grid}>
            <Box className={classes.inputTitle}>Place of Manufacture</Box>
          </Grid>

          <Grid item xs={10} className={classes.grid}>
            <Select
              label="Country"
              name="placeOfManufactureCountryId"
              value={data.placeOfManufactureCountryId || ""}
              options={countryList}
              handleChange={handleFactoryDataChange}
              additionalClass={classnames(classes.inputField, classes.inputFieldPadding)}
            />

            {!hideManufactureState && (
              <Select
                label="State"
                name="placeOfManufactureStateId"
                value={data.placeOfManufactureStateId || ""}
                options={manufactureStateList}
                handleChange={handleFactoryDataChange}
                additionalClass={classnames(classes.inputField, classes.inputFieldPadding)}
                disabled={!data.placeOfManufactureCountryId}
              />
            )}

            <Select
              label="City"
              name="placeOfManufactureCityId"
              value={data.placeOfManufactureCityId || ""}
              options={manufactureCityList}
              handleChange={handleFactoryDataChange}
              additionalClass={classes.inputField}
              disabled={!data.placeOfManufactureStateId}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2} className={classes.grid}>
            <Box className={classes.inputTitle}>Country of Origin</Box>
          </Grid>

          <Grid item xs={10} className={classes.grid}>
            <Select
              label="Country"
              name="countryOfOriginCountryId"
              value={data.countryOfOriginCountryId || ""}
              options={countryList}
              handleChange={handleFactoryDataChange}
              additionalClass={classnames(classes.inputField, classes.inputFieldPadding)}
            />

            {!hideState && (
              <Select
                label="State"
                name="countryOfOriginStateId"
                value={data.countryOfOriginStateId || ""}
                options={stateList}
                handleChange={handleFactoryDataChange}
                additionalClass={classnames(classes.inputField, classes.inputFieldPadding)}
                disabled={!data.countryOfOriginCountryId}
              />
            )}

            <Select
              label="City"
              name="countryOfOriginCityId"
              value={data.countryOfOriginCityId || ""}
              options={cityList}
              handleChange={handleFactoryDataChange}
              additionalClass={classes.inputField}
              disabled={!data.countryOfOriginStateId}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FactoryInformation;
