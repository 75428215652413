import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Header from "./Header";
import TestingInvoice from "./TestingInvoice";
import InspectionInvoice from "./InspectionInvoice";

import ConfirmModal from "components/modal/ConfirmModal";
import { formatFloatingNumber } from "utils/string";

import { atnCreateNewInvoice, atnGetSubmissionDetails } from "actions/submissionActions";
import useIsUL from "../../../hooks/useIsUL";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const NewInvoice = ({ match, history }) => {
  const submissionId = match.params.submissionId;
  const classes = useStyles();

  const isUl = useIsUL();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);
  const [submissionType, setSubmissionType] = useState("");
  const [labStatus, setLabStatus] = useState("");
  const [inspectionTestItem, setInspectionTestItem] = useState([]);
  const [detailData, setDetailData] = useState({
    id: "",
    currency: "USD",
    subTotal: 0,
    totalPrice: 0,
    packagePrice: "",
    vat: "",
    remarks: "",
    packageRemark: "",
    tat: "",
    sampleSize: "",
    isPackagePrice: false,
    reasonOption: "",
    reasonRemarks: "",
  });

  const [testItems, setTestItems] = useState([
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
    {
      itemName: "",
      quantity: "",
      unitPrice: "",
      subTotal: 0,
    },
  ]);

  const [inspectionTaskDetail, setInspectionTaskDetail] = useState({
    id: "",
    currency: "USD",
    aqlCritical: "",
    aqlMajor: "",
    aqlMinor: "",
    inspectionDateFrom: "",
    inspectionDateTo: "",
    inspectionLevel: "II",
    totalQuantity: "",
    isPrepayment: false,
    isPackagePrice: false,
    remarks: "",
    vat: 0,

    noOfMandays: 0,
    mandayUnitCost: 0,
    travelExpenses: 0,
    hotelExpenses: 0,
    otherExpenses: 0,
    lateBookingFee: 0,
    cancellationFee: 0,
    totalPrice: 0,
  });

  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const { totalPrice } = detailData;

  const isPriceZero = totalPrice === 0;
  const isPriceNagetiveAmount = totalPrice < 0;

  useEffect(() => {
    atnGetSubmissionDetails({ data: { id: submissionId }, type: 3 }).then(({ data, message }) => {
      if (data && data.taskType) {
        let type = "";

        if (
          data.taskType === "QT" ||
          data.taskType === "PST" ||
          data.taskType === "ST" ||
          data.taskType === "STD" ||
          data.taskType === "PAC"
        ) {
          type = "testing";
        } else if (
          data.taskType === "SPU" ||
          data.taskType === "SPUD" ||
          data.taskType === "DPI" ||
          data.taskType === "FRI" ||
          data.taskType === "FRID"
        ) {
          type = "inspection";
        }

        setSubmissionType(type);
      }
    });
  }, []);

  const handleOpenRemarkModal = () => {
    setOpenRemarkModal(true);
  };

  const handleRemarkModalClose = () => {
    setOpenRemarkModal(false);
  };

  const goToSubmitModal = () => {
    // handleRemarkModalClose();
    handleOpenConfirmDialog();
  };

  const onShowWarningModal = () => {
    setOpenWarningModal(true);
  };

  const onHideWarningModal = () => {
    setOpenWarningModal(false);
  };

  const handleCheckTotalPrice = () => {
    if (isPriceZero) {
      return handleOpenRemarkModal();
    } else if (isPriceNagetiveAmount) {
      return onShowWarningModal();
    } else {
      return goToSubmitModal();
    }
  };

  const handleDetailDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "isPackagePrice") {
      if (value) {
        let newTotalPrice = detailData.packagePrice || 0;

        if (detailData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      } else {
        let newTotalPrice = detailData.subTotal;

        if (detailData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
        }

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      }
    } else if (name === "packagePrice") {
      let newTotalPrice = value || 0;

      if (detailData.vat) {
        newTotalPrice = (newTotalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
      }

      setDetailData((prevState) => ({
        ...prevState,
        [name]: value,
        totalPrice: newTotalPrice,
      }));
    } else if (name === "vat") {
      if (detailData.isPackagePrice) {
        let newTotalPrice = detailData.packagePrice || 0;

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      } else {
        let newTotalPrice = detailData.subTotal || 0;

        setDetailData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      }
    } else {
      setDetailData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInspectionTaskDetailChange = (e) => {
    const { name, value } = e.target;
    setInspectionTaskDetail((prevState) => {
      let tempTotal = 0;
      let tempSubTotal = 0;
      if (
        name === "noOfMandays" ||
        name === "mandayUnitCost" ||
        name === "travelExpenses" ||
        name === "hotelExpenses" ||
        name === "otherExpenses" ||
        name === "lateBookingFee" ||
        name === "vat" ||
        name === "cancellationFee"
      ) {
        prevState[name] = parseFloat(value);
        tempTotal += (prevState.noOfMandays || 0) * (prevState.mandayUnitCost || 0);
        tempTotal += prevState.travelExpenses || 0;
        tempTotal += prevState.hotelExpenses || 0;
        tempTotal += prevState.otherExpenses || 0;
        tempTotal += prevState.lateBookingFee || 0;
        tempTotal += prevState.cancellationFee || 0;

        tempSubTotal = tempTotal;
        if (prevState.vat > 0) {
          tempTotal = tempTotal * (1 + parseFloat(prevState.vat) / 100);
        }

        return {
          ...prevState,
          [name]: parseFloat(value),
          totalPrice: tempTotal.toFixed(2),
          subTotal: tempSubTotal.toFixed(2),
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "isPackagePrice") {
      handleDetailDataChange({
        target: {
          name: name,
          value: checked,
        },
      });
    } else {
      setDetailData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleInspectionPrepaymentChange = (e) => {
    const { name, checked } = e.target;

    setInspectionTaskDetail((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleTestItemDataChange = (e, index) => {
    const { name, value } = e.target;

    setTestItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;

          if (name === "quantity") {
            if (value && item.unitPrice) {
              item.subTotal = parseInt(value) * parseFloat(item.unitPrice);
            } else {
              item.subTotal = 0;
            }
          }

          if (name === "unitPrice") {
            if (value && item.quantity) {
              item.subTotal = parseInt(item.quantity) * parseFloat(value);
            } else {
              item.subTotal = 0;
            }
          }
        }

        return item;
      });
    });

    setTimeout(() => {
      let subTotal = 0;

      for (let i = 0; i < testItems.length; i++) {
        if (testItems[i].quantity && testItems[i].unitPrice) {
          subTotal = formatFloatingNumber(
            subTotal + parseInt(testItems[i].quantity) * parseFloat(testItems[i].unitPrice),
          );
        }
      }

      let totalPrice = subTotal;

      if (detailData.isPackagePrice) {
        totalPrice = detailData.packagePrice || 0;
      }

      if (detailData.vat) {
        totalPrice = (totalPrice * (1 + parseFloat(detailData.vat) / 100)).toFixed(2);
      }

      setDetailData((prevState) => ({
        ...prevState,
        subTotal: subTotal,
        totalPrice: totalPrice,
      }));
    }, 200);
  };

  const handleAddNewTestItem = () => {
    setTestItems((prevState) => {
      return prevState.concat({
        itemName: "",
        quantity: "",
        unitPrice: "",
        subTotal: 0,
      });
    });
  };

  const handleRemoveTestItem = (index) => {
    let subTotal = 0;

    for (let i = 0; i < testItems.length; i++) {
      if (i !== index && testItems[i].quantity && testItems[i].unitPrice) {
        subTotal = formatFloatingNumber(
          subTotal + (testItems[i].quantity || 0) * (testItems[i].unitPrice || 0),
        );
      }
    }

    let totalPrice = subTotal;

    if (detailData.isPackagePrice) {
      totalPrice = detailData.packagePrice || 0;
    }

    if (detailData.vat) {
      totalPrice = (totalPrice * (1 + parseFloat(detailData.vat || 0) / 100)).toFixed(2);
    }

    setDetailData((prevState) => ({
      ...prevState,
      subTotal: subTotal,
      totalPrice: totalPrice,
    }));

    setTestItems((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const handleTestingSubmit = () => {
    let tempTestItems = [];
    let hasEmptyData = false;

    for (let i = 0; i < testItems.length; i++) {
      if (
        testItems[i].quantity !== null &&
        testItems[i].quantity !== "" &&
        testItems[i].unitPrice !== null &&
        testItems[i].unitPrice !== ""
      ) {
        if (testItems[i].id) {
          tempTestItems.push({
            id: testItems[i].id,
            itemName: testItems[i].itemName,
            quantity: parseInt(testItems[i].quantity),
            unitPrice: parseFloat(testItems[i].unitPrice),
          });
        } else {
          tempTestItems.push({
            itemName: testItems[i].itemName,
            quantity: parseInt(testItems[i].quantity),
            unitPrice: parseFloat(testItems[i].unitPrice),
          });
        }
      }
    }

    if (hasEmptyData) {
      setOpenConfirmDialog(false);
      return alert("missing data");
    }

    setIsDisabledConfirmButton(true);

    let tempInvoiceData = detailData;
    tempInvoiceData.vat = detailData.vat || 0;
    tempInvoiceData.testItems = tempTestItems;

    if (tempInvoiceData.isPackagePrice && !tempInvoiceData.packagePrice) {
      tempInvoiceData.packagePrice = 0;
    }

    let submitData = {
      submissionId: submissionId,
      data: tempInvoiceData,
    };

    atnCreateNewInvoice(submitData).then(({ data, message }) => {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);

      if (data) {
        history.push(`/lab/submissions/${submissionId}`);
      }
    });
  };

  const handleInspectionSubmit = () => {
    setIsDisabledConfirmButton(true);

    let tempData = Object.keys(inspectionTaskDetail)
      .filter(
        (key) =>
          key === "id" ||
          key === "currency" ||
          key === "isPrepayment" ||
          key === "expectedCompletionDate" ||
          key === "cancellationFee" ||
          key === "totalPrice" ||
          key === "expressServicePercentage" ||
          key === "vat" ||
          key === "remarks" ||
          key === "tat" ||
          key === "sampleSize" ||
          key === "isPackagePrice" ||
          key === "packagePrice",
      )
      .reduce((obj, key) => {
        obj[key] = inspectionTaskDetail[key];
        return obj;
      }, {});

    let tempInspectionTaskDetail = Object.keys(inspectionTaskDetail)
      .filter(
        (key) =>
          key === "aqlCritical" ||
          key === "aqlMajor" ||
          key === "aqlMinor" ||
          key === "expectedInspectionDate" ||
          key === "inspectionDateFrom" ||
          key === "inspectionDateTo" ||
          key === "inspectionLevel" ||
          key === "totalQuantity",
      )
      .reduce((obj, key) => {
        obj[key] = inspectionTaskDetail[key];
        return obj;
      }, {});

    tempInspectionTaskDetail.id = inspectionTaskDetail.inspectionTaskDetailId;
    tempData.expectedCompletionDate = tempData.expectedCompletionDate
      ? moment(tempData.expectedCompletionDate).format("YYYY-MM-DD")
      : null;
    tempInspectionTaskDetail.expectedInspectionDate =
      tempInspectionTaskDetail.expectedInspectionDate
        ? moment(tempInspectionTaskDetail.expectedInspectionDate).format("YYYY-MM-DD")
        : null;
    tempInspectionTaskDetail.inspectionDateFrom = tempInspectionTaskDetail.inspectionDateFrom
      ? moment(tempInspectionTaskDetail.inspectionDateFrom).format("YYYY-MM-DD")
      : null;
    tempInspectionTaskDetail.inspectionDateTo = tempInspectionTaskDetail.inspectionDateTo
      ? moment(tempInspectionTaskDetail.inspectionDateTo).format("YYYY-MM-DD")
      : null;

    let mandayId = "";
    let travelExpensesId = "";
    let hotelExpensesId = "";
    let otherExpensesId = "";
    let lateBookingFeeId = "";
    let cancellationFeeId = "";

    for (let i = 0; i < inspectionTestItem.length; i++) {
      if (inspectionTestItem[i].itemName === "Manday") {
        mandayId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Travel Expenses") {
        travelExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Hotel Expenses") {
        hotelExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Other Expenses") {
        otherExpensesId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Late Booking Fee") {
        lateBookingFeeId = inspectionTestItem[i].id;
      } else if (inspectionTestItem[i].itemName === "Cancellation Fee") {
        cancellationFeeId = inspectionTestItem[i].id;
      }
    }

    const newTempTestItems = [
      {
        id: mandayId,
        itemName: "Manday",
        quantity: inspectionTaskDetail.noOfMandays,
        unitPrice: inspectionTaskDetail.mandayUnitCost,
      },
      {
        id: travelExpensesId,
        itemName: "Travel Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.travelExpenses,
      },
      {
        id: hotelExpensesId,
        itemName: "Hotel Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.hotelExpenses,
      },
      {
        id: otherExpensesId,
        itemName: "Other Expenses",
        quantity: 1,
        unitPrice: inspectionTaskDetail.otherExpenses,
      },
      {
        id: lateBookingFeeId,
        itemName: "Late Booking Fee",
        quantity: 1,
        unitPrice: inspectionTaskDetail.lateBookingFee,
      },
      {
        id: cancellationFeeId,
        itemName: "Cancellation Fee",
        quantity: 1,
        unitPrice: inspectionTaskDetail.cancellationFee,
      },
    ];

    tempData.testItems = newTempTestItems;

    let submitData = {
      submissionId: submissionId,
      data: tempData,
    };

    atnCreateNewInvoice(submitData).then(({ data, message }) => {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);

      if (data) {
        history.push(`/lab/submissions/${submissionId}`);
      }
    });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Header handleClose={handleClose} />

      <Box className={classes.content}>
        <Box className={classes.title}>Please Fill Out the Invoice Details</Box>

        {submissionType === "testing" && (
          <TestingInvoice
            detailData={detailData}
            testItems={testItems}
            handleDetailDataChange={handleDetailDataChange}
            handleTestItemDataChange={handleTestItemDataChange}
            handleAddNewTestItem={handleAddNewTestItem}
            handleRemoveTestItem={handleRemoveTestItem}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}

        {submissionType === "inspection" && (
          <InspectionInvoice
            inspectionTaskDetail={inspectionTaskDetail}
            handleInspectionTaskDetailChange={handleInspectionTaskDetailChange}
            handleInspectionPrepaymentChange={handleInspectionPrepaymentChange}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={goToSubmitModal}
        >
          SUBMIT
        </Button>
      </Box>

      {/* <ReasonRemarkModal
        data={detailData}
        open={openRemarkModal}
        onSubmit={goToSubmitModal}
        onClose={handleRemarkModalClose}
        onReasonChange={handleDetailDataChange}
      />

      <WarningModal
        open={openWarningModal}
        onSubmit={onHideWarningModal}
        onClose={onHideWarningModal}
      /> */}

      <ConfirmModal
        isDisabledSubmit={isDisabledConfirmButton}
        onSubmit={submissionType === "testing" ? handleTestingSubmit : handleInspectionSubmit}
        onClose={handleConfirmDialogClose}
        open={openConfirmDialog}
        contentText={
          isUl
            ? 'Ensure that all tasks conducted in China are set to use currency "RMB"'
            : "Do you confirm to submit?"
        }
      />
    </Box>
  );
};

export default NewInvoice;
