import React from "react";

import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#005EFF",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Link = ({ text, path, action }) => {
  const classes = useStyles();

  let linkProps = {
    href: path,
    target: "_blank",
    rel: "noopener noreferrer",
  };

  if (action) {
    linkProps = {
      onClick: action,
    };
  }

  return (
    <MuiLink className={classes.link} underline="always" {...linkProps}>
      {textOrUnknown(text)}
    </MuiLink>
  );
};

export default Link;
