import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import Button from "components/input/Button";
import Divider from "components/display/Divider";

import TextInput from "components/input/TextInput";
import TaskDetailHeader from "./TaskDetailHeader";
import TaskDetailContent from "./TaskDetailContent";

import {
  atnCompleteSampleSealing,
  atnDeleteSampleSealingResult,
  atnUpdateSampleSealingResult,
} from "actions/submissionActions";
import Select from "../../../components/input/Select";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  header: {
    // display: 'flex',
    // flexDirection: 'column',
    // color: '#777777',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  divider: {
    margin: `${theme.spacing(3)}px -${theme.spacing(4)}px`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  subTitle: {
    fontSize: 12,
  },
  title2: {
    fontSize: 12,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    color: "#777777",
  },
  right: {
    textAlign: "right",
  },
  reportsTable: {
    border: "1px solid #777777",
    padding: theme.spacing(4),
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellNoBorderLeftRight: {
    borderLeft: "none",
    borderRight: "none",
  },
  tableCellNoBorderTop: {
    borderTop: "none",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  tableCellNoPaddingRight: {
    paddingRight: 0,
  },
  leftButton: {
    paddingRight: 20,
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
  },
  dialogTitleSection: {
    borderBottom: "1px solid #777777",
  },
  dialogTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  dialogSubTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  gridContainer: {
    paddingTop: 30,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputField: {
    width: "100%",
  },
}));

const currencyOptions = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "RMB",
    label: "RMB",
  },
];

const TaskDetail = ({ hasPayerInfo = false, userType, submissionId, assignTo, data = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isUpdateResult, setIsUpdateResult] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [isSampleSealingResultEditing, setIsSampleSealingResultEditing] = useState(false);
  const [sampleSealingResultData, setSampleSealingResultData] = useState([]);
  const [editSampleSealingResultData, setEditSampleSealingResultData] = useState([]);
  const [deletedSampleSealingResultData, setDeletedSampleSealingResultData] = useState([]);
  const [openCostDialog, setOpenCostDialog] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    unitPrice: 165,
    quantity: "",
    totalPrice: 0,
    subTotal: 0,
    vat: 0,
    remarks: "",
  });

  useEffect(() => {
    setSampleSealingResultData(data.sampleSealingResults);
    setEditSampleSealingResultData(data.sampleSealingResults);
  }, [data.sampleSealingResults]);

  useEffect(() => {
    let totalPrice = invoiceData.unitPrice * parseFloat(invoiceData.quantity || 0);
    let subTotal = invoiceData.unitPrice * parseFloat(invoiceData.quantity || 0);

    if (invoiceData.vat > 0) {
      totalPrice = totalPrice * (1 + invoiceData.vat / 100);
      totalPrice = totalPrice.toFixed(2);
    }

    setInvoiceData((prevState) => ({
      ...prevState,
      totalPrice: totalPrice,
      subTotal: subTotal,
    }));
  }, [invoiceData.unitPrice, invoiceData.quantity, invoiceData.vat]);

  const handleInvoiceDataChange = (e) => {
    const { name, value } = e.target;

    setInvoiceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateResultClick = () => {
    setIsSampleSealingResultEditing(true);
    setDeletedSampleSealingResultData([]);
    setEditSampleSealingResultData(JSON.parse(JSON.stringify(sampleSealingResultData)));
    setIsUpdateResult(true);
  };

  const handleCancelUpdateResult = () => {
    setIsSampleSealingResultEditing(false);
    setIsUpdateResult(false);
    setDeletedSampleSealingResultData([]);
    setEditSampleSealingResultData(JSON.parse(JSON.stringify(sampleSealingResultData)));
  };

  const handleResultDataChange = (e, index) => {
    const { name, value } = e.target;

    setEditSampleSealingResultData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      });
    });
  };

  const handleAddResultData = () => {
    setEditSampleSealingResultData((prevState) => {
      return prevState.concat({
        name: "",
        remark: "",
        result: "",
      });
    });
  };

  const handleRemoveResultData = (index) => {
    if (editSampleSealingResultData[index].id) {
      setDeletedSampleSealingResultData((prevState) => {
        return prevState.concat({
          id: editSampleSealingResultData[index].id,
        });
      });
    }

    setEditSampleSealingResultData((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const handleConfirmUpdateResult = () => {
    atnUpdateSampleSealingResult({
      type: 3,
      submissionId: submissionId,
      taskId: data.id,
      data: editSampleSealingResultData,
    }).then(({ data: resultData, message }) => {
      if (resultData) {
        if (deletedSampleSealingResultData.length > 0) {
          atnDeleteSampleSealingResult({
            type: 3,
            submissionId: submissionId,
            taskId: data.id,
            data: deletedSampleSealingResultData,
          }).then(() => {
            setSampleSealingResultData(JSON.parse(JSON.stringify(editSampleSealingResultData)));
            setIsUpdateResult(false);
            window.location.reload();
          });
        } else {
          setSampleSealingResultData(JSON.parse(JSON.stringify(editSampleSealingResultData)));
          setIsUpdateResult(false);
          window.location.reload();
        }
      }
    });
  };

  const handleCostDialogOpen = () => {
    setOpenCostDialog(true);
  };

  const handleCostDialogClose = () => {
    setOpenCostDialog(false);
  };

  const handleComplete = () => {
    let invoiceTestItems = [
      {
        itemName: "Sample Sealing",
        quantity: parseFloat(invoiceData.quantity),
        unitPrice: invoiceData.unitPrice,
      },
    ];

    atnCompleteSampleSealing({
      type: 3,
      submissionId: submissionId,
      taskId: data.id,
      data: {
        submissionId: submissionId,
        invoice: {
          vat: invoiceData.vat,
          currency: currency,
          totalPrice: invoiceData.totalPrice,
          remarks: invoiceData.remarks,
        },
        invoiceTestItems: invoiceTestItems,
      },
    }).then(({ data, message }) => {
      window.location.reload();
    });
  };

  return (
    <>
      <Paper className={classes.container}>
        {data ? (
          <>
            <TaskDetailHeader
              isResumeReport={data?.isResumeReport}
              triggeredBy={data.triggeredBy}
              wicNumber={data.wicNumber}
              wicNoUrl={data.wicNoUrl}
              labStatus={data.labStatus}
              taskType={data.taskType}
              isNb={data.isNb}
              programName={data.programName}
              programYear={data.programYear}
              isTenderingProgram={data?.isTenderingProgram}
              tenderingProgram={data?.tenderingProgram}
              category={data.category}
              status={data.status}
              completedStatus={data.completedStatus}
              remark={data.remark}
              inspectionTaskDetails={data.inspectionTaskDetails}
              expectedCompletionDate={data.expectedCompletionDate}
              confirmedInspectionDate={data.confirmedInspectionDate}
            />

            <Divider className={classes.divider} />

            <TaskDetailContent
              hasPayerInfo={hasPayerInfo}
              isResumeReport={data?.isResumeReport}
              triggeredBy={data?.triggeredBy}
              isProgram777={data?.isProgram777}
              submissionId={submissionId}
              userType={userType}
              assignTo={assignTo}
              createdAt={data.createdAt}
              category={data.category}
              taskType={data.taskType}
              taskNumber={data.taskNumber}
              sampleReceivedDate={data.sampleReceivedDate}
              masterWicId={data.masterWicId}
              pendingReason={data.pendingReason}
              taskFiles={data.taskFiles}
              capStatus={data.capStatus}
              lab={data.lab || ""}
              taskId={data.id || ""}
              icl={data.icl}
              lastUpdated={data.lastUpdated}
              labStatus={data.labStatus}
              reports={data.reports}
              pacCert={data.pacCert}
              firstShipmentTotalQuantity={data.firstShipmentTotalQuantity}
              fullPoTotalQuantity={data.fullPoTotalQuantity}
              po={data.po}
              pb={data.pb}
              e2open={data.e2open}
              spuForEveryDayTaskType={data.spuForEveryDayTaskType}
              selectedLabs={data.selectedLabs}
              expectedCompletionDate={data.expectedCompletionDate}
              inspectionTaskDetails={data.inspectionTaskDetails}
              status={data.status}
              completedStatus={data.completedStatus}
              isUpdateResult={isUpdateResult}
              sampleSealingResultData={sampleSealingResultData}
              editSampleSealingResultData={editSampleSealingResultData}
              handleResultDataChange={handleResultDataChange}
              handleAddResultData={handleAddResultData}
              handleRemoveResultData={handleRemoveResultData}
              handleCostDialogOpen={handleCostDialogOpen}
              isSampleSealingResultEditing={isSampleSealingResultEditing}
              handleCancelUpdateResult={handleCancelUpdateResult}
              handleConfirmUpdateResult={handleConfirmUpdateResult}
              handleUpdateResultClick={handleUpdateResultClick}
            />
          </>
        ) : (
          <Box>No data</Box>
        )}
      </Paper>

      <Dialog className={classes.dialog} open={openCostDialog} onClose={handleCostDialogClose}>
        <MuiDialogTitle className={classes.dialogTitleSection}>
          <Typography className={classes.dialogTitle}>
            Advise Final Cost and Confirm Completion
          </Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.dialogSubTitle}>
            Please fill in the final cost for invoice and confirm
            <Box sx={{ width: "200px" }}>
              <Select
                label="Currency (Select One)"
                name="currency"
                value={currency}
                options={currencyOptions}
                handleChange={(e) => {
                  e.preventDefault();
                  setCurrency(e.target.value);
                }}
                additionalClass={classes.selectField}
              />
            </Box>
          </Box>

          <Box className={classes.gridContainer}>
            <Grid container>
              <Grid item xs={3} className={classes.firstGrid}>
                <Box className={classes.inputTitle}>Sample Sealing</Box>
              </Grid>

              <Grid item xs={9} className={classes.firstGrid}>
                {currency}
                <TextInput
                  type="number"
                  placeholder="Unit price"
                  name="unitPrice"
                  value={invoiceData.unitPrice}
                  additionalClass={classes.inputField}
                  onChange={handleInvoiceDataChange}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3} className={classes.grid}>
                <Box className={classes.inputTitle}>Quantity</Box>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                <TextInput
                  type="number"
                  placeholder="Quantity"
                  name="quantity"
                  value={invoiceData.quantity}
                  additionalClass={classes.inputField}
                  onChange={handleInvoiceDataChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} className={classes.grid}>
                <Box className={classes.inputTitle}>Sub Price</Box>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                {currency} {invoiceData.subTotal}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} className={classes.grid}>
                <Box className={classes.inputTitle}>VAT %</Box>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                <TextInput
                  type="number"
                  placeholder="VAT"
                  name="vat"
                  value={invoiceData.vat}
                  additionalClass={classes.inputField}
                  onChange={handleInvoiceDataChange}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3} className={classes.grid}>
                <Box className={classes.inputTitle}>Total Price</Box>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                {currency} {invoiceData.totalPrice}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3} className={classes.grid}>
                <Box className={classes.inputTitle}>Remarks</Box>
              </Grid>

              <Grid item xs={9} className={classes.grid}>
                <TextInput
                  placeholder="Remarks"
                  name="remarks"
                  value={invoiceData.remarks}
                  additionalClass={classes.inputField}
                  onChange={handleInvoiceDataChange}
                />
              </Grid>
            </Grid>
          </Box>
        </MuiDialogContent>
        <DialogActions>
          <Button onClick={handleCostDialogClose} color="primary" variant="outlined">
            Close
          </Button>
          <Button onClick={handleComplete} color="primary" disabled={!invoiceData.quantity}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskDetail;
