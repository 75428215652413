import React from "react";
import _get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import {
  atnGetLabAdminSubmissionDetailsTaskDetail,
  atnGetLabAdminSubmissionDetailsTaskList,
} from "actions/submissionActions";

import TaskList from "./TaskList";
import TaskDetail from "./TaskDetail";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  itemRight: {
    paddingLeft: theme.spacing(4),
  },
}));

const Task = ({ userType, data }) => {
  const classes = useStyles();

  // const [activeItem, setActiveItem] = React.useState(0);

  const [taskListData, setTaskListData] = React.useState([]);
  const [taskDetailData, setTaskDetailData] = React.useState({});

  // const taskListData = data.map((item) => item.overview);
  // const taskDetailData = data.filter((_, index) => index === activeItem)[0].details;

  React.useEffect(() => {
    if (!data.id) return;
    getTaskList(data.id);

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTaskList = (id) => {
    atnGetLabAdminSubmissionDetailsTaskList({ data: { id }, type: userType }).then((result) => {
      setTaskListData(result.data);

      if (result.data.length) {
        const firstItemId = _get(result, "data.0.id");
        getTaskDetail(firstItemId);
      }
    });
  };

  const getTaskDetail = (id) => {
    if (id) {
      atnGetLabAdminSubmissionDetailsTaskDetail({
        data: { id: data.id, taskId: id },
        type: userType,
      }).then((result) => {
        const detail = result.data;
        setTaskDetailData(detail);
      });
    }
  };

  const handleChangeTask = (id) => {
    getTaskDetail(id);
  };

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TaskList
          userType={userType}
          data={{ list: taskListData }}
          func={{ onChange: handleChangeTask }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10} className={classes.itemRight}>
        <TaskDetail userType={userType} submissionId={data.id} data={taskDetailData} />
      </Grid>
    </Grid>
  );
};

export default Task;
