import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";

import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import Button from "components/input/Button";
import Table from "components/table/Table";
import FileUpload from "components/input/FileUpload";
import Textarea from "components/input/Textarea";
import Modal from "components/modal/Modal";

import ReportDetails from "pages/common/ReportDetails";
import GuaranteeLetters from "pages/common/GuaranteeLetters";
import PbInfoTable from "pages/common/PbInfoTable";
import E2OpenTable from "pages/common/E2OpenTable";

import { textOrUnknown } from "utils/string";
import { downloadFile, getTRFDocumentName } from "utils/files";
import Result from "../components/Result";
import SampleSealingResultTable from "./SampleSealingResultTable";
import auth from "auth/authenticator";

import {
  TASK_SAMPLE_SEALING_TYPE,
  TASK_STATUS,
  TASK_STATUS_ENUM,
  TASK_TYPE_ENUM,
  TRF_DOCUMENT,
  WBA,
} from "shared/constants";

import {
  atnCompleteSampleSealing,
  atnDeleteSampleSealingResult,
  atnUpdateSampleSealingResult,
} from "actions/submissionActions";
import {
  atnDownloadTrfFile,
  atnDownloadTUVTrfFile,
  atnDownloadULTrfFile,
} from "actions/fileActions";

import { atnUpdateCompletedTask } from "actions/taskActions";

import { isShowProgram777 } from "services/pbService";
import useUserRole from "hooks/useUserRole";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  blackText: {
    color: "#000000",
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
  leftButton: {
    paddingRight: 20,
  },
  middleButton: {
    marginLeft: 20,
    marginRight: 20,
  },
  dialogContentText: {
    fontSize: 10,
  },
  dialogTextareaLabel: {
    fontSize: 12,
  },
  dialogTextareaValue: {
    width: "100%",
    resize: "none",
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
  trf: {
    cursor: "pointer",
    color: "#005EFF",
  },
  gridContainer: {
    borderCollapse: "collapse",
  },
  gridItem: {
    padding: 10,
    border: "1px solid #777777",
    // borderBottom: "none",
    fontSize: 10,
  },
  gridItemRight: {
    borderLeft: "none",
  },
  gridItemBottom: {
    borderBottom: "1px solid #777777",
  },
  borderBottomNone: {
    borderBottom: "none",
  },
  borderRightNone: {
    borderRight: "none",
  },
}));

const ReportDetail = ({
  data,
  pb = [],
  e2open = [],
  isProgram777 = false,
  triggeredBy = "",
  taskType,
  status,
  completedStatus,
  itemStatus,
  taskId,
  submissionId,
  assignTo,
  masterWicId,
  onUpdate,
}) => {
  const classes = useStyles();

  const [isUpdateResult, setIsUpdateResult] = useState(false);

  const [sampleSealingResultData, setSampleSealingResultData] = useState([]);

  const [isSampleSealingResultEditing, setIsSampleSealingResultEditing] = useState(false);

  const [editSampleSealingResultData, setEditSampleSealingResultData] = useState([]);

  const [deletedSampleSealingResultData, setDeletedSampleSealingResultData] = useState([]);

  const [openConfirmTaskCompleteDialog, setOpenConfirmTaskCompleteDialog] = useState(false);

  const [openConfirmCompletedTaskDialog, setOpenConfirmCompletedTaskDialog] = useState({
    [TASK_STATUS.completed_accept.value]: false,
    [TASK_STATUS.completed_reject.value]: false,
    [TASK_STATUS.completed_concession.value]: false,
  });

  const [guaranteeLetters, setGuaranteeLetters] = useState([]);

  const { isBuyerRole, isBuyerSourcingRole } = useUserRole(auth.loginRole());

  useEffect(() => {
    if (data?.taskFiles) {
      let tempGuaranteeLetters = [];

      for (let i = 0; i < data?.taskFiles?.length; i++) {
        if (data.taskFiles[i]?.type === "guarantee_letter") {
          tempGuaranteeLetters.push(data.taskFiles[i]);
        }
      }

      setGuaranteeLetters(tempGuaranteeLetters);
    }
  }, [data.taskFiles]);

  useEffect(() => {
    setSampleSealingResultData(data.sampleSealingResults);
    setEditSampleSealingResultData(data.sampleSealingResults);
  }, [data.sampleSealingResults]);

  const downloadTrf = async (fetcher, taskId, fileName) => {
    try {
      const data = await fetcher({ taskId, fileName });

      await downloadFile(data, fileName);
    } catch (err) {
      console.log("downloadTrf", { err });
    }
  };

  const handleDownloadTrfFile = async (taskId, fileName) => {
    if (fileName === TRF_DOCUMENT.ADMIN_BOOKING_FORM) {
      await downloadTrf(atnDownloadTUVTrfFile, taskId, fileName);
    } else if (fileName === TRF_DOCUMENT.GENERAL_INPECTION_REQUEST_FORM) {
      await downloadTrf(atnDownloadULTrfFile, taskId, fileName);
    } else {
      await downloadTrf(atnDownloadTrfFile, taskId, fileName);
    }
  };

  const handleUpdateResultClick = () => {
    setIsSampleSealingResultEditing(true);
    setDeletedSampleSealingResultData([]);
    setEditSampleSealingResultData(JSON.parse(JSON.stringify(sampleSealingResultData)));
    setIsUpdateResult(true);
  };

  const handleCancelUpdateResult = () => {
    setIsSampleSealingResultEditing(false);
    setIsUpdateResult(false);
    setDeletedSampleSealingResultData([]);
    setEditSampleSealingResultData(JSON.parse(JSON.stringify(sampleSealingResultData)));
  };

  const handleResultDataChange = (e, index) => {
    const { name, value } = e.target;

    setEditSampleSealingResultData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      });
    });
  };

  const handleAddResultData = () => {
    setEditSampleSealingResultData((prevState) => {
      return prevState.concat({
        name: "",
        remark: "",
        result: "",
      });
    });
  };

  const handleRemoveResultData = (index) => {
    if (editSampleSealingResultData[index].id) {
      setDeletedSampleSealingResultData((prevState) => {
        return prevState.concat({
          id: editSampleSealingResultData[index].id,
        });
      });
    }

    setEditSampleSealingResultData((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const handleConfirmUpdateResult = () => {
    atnUpdateSampleSealingResult({
      type: 1,
      masterWicId: masterWicId,
      taskId: taskId,
      data: editSampleSealingResultData,
    }).then(({ data: resultData, message }) => {
      if (resultData) {
        if (deletedSampleSealingResultData.length > 0) {
          atnDeleteSampleSealingResult({
            type: 1,
            masterWicId: masterWicId,
            taskId: taskId,
            data: deletedSampleSealingResultData,
          }).then(() => {
            setSampleSealingResultData(JSON.parse(JSON.stringify(editSampleSealingResultData)));
            setIsUpdateResult(false);
            window.location.reload();
          });
        } else {
          setSampleSealingResultData(JSON.parse(JSON.stringify(editSampleSealingResultData)));
          setIsUpdateResult(false);
          window.location.reload();
        }
      }
    });
  };

  const handleConfirmTaskCompleteDialogOpen = (value) => {
    setOpenConfirmTaskCompleteDialog(value);
  };

  const handleComplete = () => {
    atnCompleteSampleSealing({
      type: 1,
      masterWicId: masterWicId,
      taskId: taskId,
    }).then(({ data, message, code }) => {
      window.location.reload();
    });
  };

  const {
    taskFiles = [],
    reports: reportsTableData = [],
    result,
    lab,
    lastUpdated,
    pacCert = [],
  } = data;

  const documentTableColumn = [
    {
      columnId: "title",
      width: "30%",
      render: ({ title }) => {
        return title;
      },
    },
    {
      columnId: "value",
      render: ({ title, value }) => {
        if (title === "TRF Document") {
          return value.trfDocument && value.taskId ? (
            <Box
              className={classes.trf}
              onClick={() => handleDownloadTrfFile(value.taskId, value.trfDocument)}
            >
              {value.trfDocument}
            </Box>
          ) : (
            textOrUnknown(value.trfDocument)
          );
        } else {
          return value && value.length > 0 ? (
            <FileUpload data={value} editable={false} multiple />
          ) : (
            textOrUnknown()
          );
        }
      },
    },
  ];

  //get supportingDocuments file for Task Documents
  let supportingDocuments = [];

  for (let i = 0; i < taskFiles.length; i++) {
    if (taskFiles[i].type === "supporting_document") {
      supportingDocuments.push(taskFiles[i]);
    }
  }

  //get trfDocument file by getTRFDocumentName for Task Documents
  const documentTableData = [
    {
      id: 1,
      title: "TRF Document",
      value: { trfDocument: getTRFDocumentName(lab, taskType), taskId },
    },
    {
      id: 2,
      title: "Supporting Documents",
      value: supportingDocuments,
    },
  ];

  const handleCompletedTaskDialogOpen = (key, value) => {
    setOpenConfirmCompletedTaskDialog({
      ...openConfirmCompletedTaskDialog,
      [key]: value,
    });
  };

  const handleUpdateCompletedTask = (status, reason) => {
    let params = { completedStatus: status };
    if (reason) {
      params = { ...params, completedReason: reason };
    }
    atnUpdateCompletedTask({ id: taskId, params }).then((res) => {
      if (onUpdate) onUpdate();
      handleCompletedTaskDialogOpen(status, false);
    });
  };

  const AcceptModal = () => {
    const [reason, setReason] = useState("");
    return (
      <Modal
        title="Accept Report"
        open={openConfirmCompletedTaskDialog[TASK_STATUS.completed_accept.value]}
        footer={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              onClick={() =>
                handleCompletedTaskDialogOpen(TASK_STATUS.completed_accept.value, false)
              }
              color="primary"
              variant="outlined"
              className={classes.btnLeft}
            >
              Back
            </Button>
            <Button
              onClick={() => handleUpdateCompletedTask(TASK_STATUS.completed_accept.value, reason)}
              color="primary"
            >
              Accept
            </Button>
          </Box>
        }
        hideCloseIcon
      >
        <Typography className={classes.dialogContentText}>
          Compliance status will change from [No] to [Yes] once task report is accepted.
        </Typography>
        <Box pt={2} className={classes.dialogTextareaLabel}>
          Reason (Optional)
        </Box>
        <Textarea
          rowsMin={10}
          rowsMax={10}
          placeholder={"Optional"}
          name="concessionalReason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className={classes.dialogTextareaValue}
        />
      </Modal>
    );
  };
  const RejectModal = () => {
    const [reason, setReason] = useState("");
    return (
      <Modal
        title="Request Retest"
        open={openConfirmCompletedTaskDialog[TASK_STATUS.completed_reject.value]}
        footer={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              onClick={() =>
                handleCompletedTaskDialogOpen(TASK_STATUS.completed_reject.value, false)
              }
              color="primary"
              variant="outlined"
              className={classes.btnLeft}
            >
              Back
            </Button>
            <Button
              onClick={() => handleUpdateCompletedTask(TASK_STATUS.completed_reject.value, reason)}
              color="secondary"
            >
              Request
            </Button>
          </Box>
        }
        hideCloseIcon
      >
        <Typography className={classes.dialogContentText}>
          New task will be created once retest is requested.
        </Typography>
        <Box pt={2} className={classes.dialogTextareaLabel}>
          Reject Reason (Optional)
        </Box>
        <Textarea
          rowsMin={10}
          rowsMax={10}
          placeholder={"Optional"}
          name="rejectReason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className={classes.dialogTextareaValue}
        />
      </Modal>
    );
  };
  const ConcessionalAcceptModal = () => {
    const [reason, setReason] = useState("");
    return (
      <Modal
        title="Concessional acceptConcessional accept"
        open={openConfirmCompletedTaskDialog[TASK_STATUS.completed_concession.value]}
        footer={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              onClick={() =>
                handleCompletedTaskDialogOpen(TASK_STATUS.completed_concession.value, false)
              }
              color="primary"
              variant="outlined"
              className={classes.btnLeft}
            >
              Back
            </Button>
            <Button
              onClick={() =>
                handleUpdateCompletedTask(TASK_STATUS.completed_concession.value, reason)
              }
              color="primary"
            >
              Confirm
            </Button>
          </Box>
        }
        hideCloseIcon
      >
        <Typography className={classes.dialogContentText}>
          Please provide a concession reason.
        </Typography>
        <Box pt={2} className={classes.dialogTextareaLabel}>
          Concession Reason (Optional)
        </Box>
        <Textarea
          rowsMin={10}
          rowsMax={10}
          placeholder={"Optional"}
          name="concessionalReason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className={classes.dialogTextareaValue}
        />
      </Modal>
    );
  };

  const hasDataDocumentTable =
    !!documentTableData[0].value.trfDocument || !!documentTableData[1].value.length;

  // Compliance Status: not Sample Sealing (SS) + is buyer + completed task
  const hasComplianceStatus =
    taskType !== TASK_TYPE_ENUM.SS &&
    isBuyerRole &&
    [TASK_STATUS_ENUM.COMPLETED, TASK_STATUS_ENUM.FAIL].includes(status) &&
    !completedStatus;

  const isEverydayTaskFRI = [TASK_TYPE_ENUM.FRID].includes(taskType);

  const showProgram777 = isShowProgram777(triggeredBy);

  const isBuyerValidTaskStatusAssignToWba =
    isBuyerRole &&
    ![TASK_STATUS_ENUM.COMPLETED, TASK_STATUS_ENUM.CLOSED, TASK_STATUS_ENUM.CANCELLED].includes(
      status,
    ) &&
    itemStatus !== TASK_STATUS_ENUM.NOT_ACKNOWLEDGE &&
    assignTo === WBA;

  return (
    <>
      {showProgram777 && isEverydayTaskFRI && (
        <>
          <PbInfoTable data={pb} />
          <E2OpenTable data={e2open} />
        </>
      )}

      {!isBuyerSourcingRole &&
        [TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID, TASK_TYPE_ENUM.DPI].includes(taskType) && (
          <Box py={2}>
            <Box>
              <Typography className={classnames(classes.title, classes.blackText)}>
                Guarantee letter / CAP
              </Typography>
              <Box>
                <GuaranteeLetters data={guaranteeLetters} taskId={taskId} editable={true} />
              </Box>
            </Box>
          </Box>
        )}

      <Box py={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <Typography className={classnames(classes.title, classes.blackText)}>
            Task Documents
          </Typography>

          {hasComplianceStatus && (
            <Box>
              <Button
                variant="contained"
                onClick={() =>
                  handleCompletedTaskDialogOpen(TASK_STATUS.completed_accept.value, true)
                }
              >
                Accept
              </Button>
              <Button
                variant="contained"
                className={classes.middleButton}
                onClick={() =>
                  handleCompletedTaskDialogOpen(TASK_STATUS.completed_reject.value, true)
                }
              >
                Reject
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  handleCompletedTaskDialogOpen(TASK_STATUS.completed_concession.value, true)
                }
              >
                Concessional accept
              </Button>
            </Box>
          )}
        </Box>

        {hasDataDocumentTable ? (
          <Table data={documentTableData} columnData={documentTableColumn} noHeader noPagination />
        ) : (
          <Box>No data</Box>
        )}
      </Box>
      <Box py={2}>
        <Box pb={2}>
          <Typography className={classnames(classes.title, classes.blackText)}>Reports</Typography>
        </Box>

        {[TASK_TYPE_ENUM.SS].includes(taskType) ? (
          <>
            <Box display="flex">
              <Box display="flex" flexGrow={1} className={classes.title2}>
                Result
              </Box>
              <Box>
                {isBuyerValidTaskStatusAssignToWba && (
                  <>
                    {isUpdateResult ? (
                      <Box display="flex">
                        <Box className={classes.leftButton}>
                          <Button variant="outlined" onClick={() => handleCancelUpdateResult()}>
                            CANCEL
                          </Button>
                        </Box>
                        <Box>
                          <Button variant="contained" onClick={() => handleConfirmUpdateResult()}>
                            SAVE
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Button variant="contained" onClick={() => handleUpdateResultClick()}>
                        UPDATE RESULT
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>

            <Box mt={2} className={classes.reportsTable}>
              <SampleSealingResultTable
                isUpdateResult={isUpdateResult}
                sampleSealingResultData={sampleSealingResultData}
                editSampleSealingResultData={editSampleSealingResultData}
                handleResultDataChange={handleResultDataChange}
                handleAddResultData={handleAddResultData}
                handleRemoveResultData={handleRemoveResultData}
              />
              <Box pt={4} display="flex" justifyContent="space-between">
                <Typography className={classes.text}>
                  Last Updated: <br />
                  {moment(lastUpdated).format("DD/MMM/YYYY HH:mm:ss")}
                </Typography>

                {isBuyerValidTaskStatusAssignToWba &&
                  sampleSealingResultData &&
                  sampleSealingResultData.length > 0 && (
                    <Button
                      variant="contained"
                      onClick={() => handleConfirmTaskCompleteDialogOpen(true)}
                      disabled={
                        !sampleSealingResultData[sampleSealingResultData.length - 1].result ||
                        sampleSealingResultData[sampleSealingResultData.length - 1].result ===
                          "reject" ||
                        isSampleSealingResultEditing
                      }
                    >
                      MARK TASK AS COMPLETE
                    </Button>
                  )}
              </Box>
            </Box>
          </>
        ) : reportsTableData && reportsTableData.length ? (
          <ReportDetails
            data={{ reports: reportsTableData, certifications: pacCert }}
            status={status}
            submissionId={submissionId}
            taskType={taskType}
            isProgram777={isProgram777}
            triggeredBy={triggeredBy}
          />
        ) : (
          <Box>No data</Box>
        )}
      </Box>

      {result && (
        <Box py={2}>
          <Result data={result} />
        </Box>
      )}
      {/* {lab && (
        <Box py={2}>
          <Lab data={lab} taskType={taskType} />
        </Box>
      )} */}
      {lastUpdated && TASK_SAMPLE_SEALING_TYPE.includes(taskType) && (
        <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.text}>
            Last Updated: <br />
            {lastUpdated}
          </Typography>
          <Button>Mark task as complete</Button>
        </Box>
      )}

      <Dialog
        open={openConfirmTaskCompleteDialog}
        onClose={() => handleConfirmTaskCompleteDialogOpen(false)}
      >
        <DialogTitle>Confirm to submit?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you confirm to mark as complete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmTaskCompleteDialogOpen(false)}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
          <Button onClick={handleComplete} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <AcceptModal />
      <RejectModal />
      <ConcessionalAcceptModal />
    </>
  );
};

export default ReportDetail;
