import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class MainWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default MainWrapper;
