import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import DatePicker from "components/input/DatePicker";
import UploadSingleFile from "components/input/UploadSingleFile";
import OutlinedButton from "components/input/OutlinedButton";
import Textarea from "components/input/Textarea";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitleSection: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 6,
  },
  rowContainer: {
    paddingTop: 10,
  },
  addButtonContainer: {
    paddingTop: 15,
    width: "100%",
    textAlign: "center",
  },
  deleteBtn: {
    padding: 5,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
}));

const UploadPACCert = ({
  submissionId,
  updateStatusData = {},
  handleUpdateStatusDataChange,
  handleSubmit,
}) => {
  const classes = useStyles();

  const [openCertDialog, setOpenCertDialog] = useState(false);
  const [originalCertData, setOriginalCertData] = useState(updateStatusData.pacCertification);
  // originalCertData from API
  const [certData, setCertData] = useState({
    fileId: "",
    fileName: "",
    pacValidityDate: "",
    remark: "",
  });

  useEffect(() => {
    setOriginalCertData(updateStatusData.pacCertification);
  }, [
    updateStatusData.pacCertification,
    updateStatusData.pacCertification.fileId,
    updateStatusData.pacCertification.pacValidityDate,
    updateStatusData.pacCertification.remark,
  ]);

  const handleCertDialogOpen = () => {
    setOpenCertDialog(true);
  };

  const handleCertDialogClose = () => {
    setOpenCertDialog(false);
    setCertData(originalCertData);
  };

  const handleUploadCert = (newFile) => {
    setCertData((prevState) => ({
      ...prevState,
      fileId: newFile.id,
      fileName: newFile.name,
    }));
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;

    setCertData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitCert = () => {
    if (!certData.fileId || !certData.pacValidityDate) {
      return alert("missing data");
    }

    let data = certData;
    data.pacValidityDate = moment(data.pacValidityDate).format("YYYY-MM-DD");

    handleUpdateStatusDataChange({
      target: {
        name: "pacCertification",
        value: data,
      },
    });

    setOpenCertDialog(false);
  };

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.pageTitle}>Upload PAC Certification</Box>

        <Paper className={classes.container}>
          <Box className={classes.title}>PAC Certification</Box>
          <Box className={classes.subtitleSection}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                Report
              </Grid>
              <Grid item xs={7}>
                PAC Validity Date
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {updateStatusData.pacCertification.fileName && (
            <Grid container spacing={3} className={classes.rowContainer}>
              <Grid item xs={5}>
                {updateStatusData.pacCertification.fileName}
              </Grid>
              <Grid item xs={7}>
                {moment(updateStatusData.pacCertification.pacValidityDate).format("DD/MMM/YYYY")}
              </Grid>
            </Grid>
          )}
        </Paper>

        <Box className={classes.addButtonContainer}>
          <OutlinedButton
            label="UPLOAD NEW CERTIFICATION"
            handleClick={handleCertDialogOpen}
            color="primary"
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={handleSubmit}
          disabled={!originalCertData.fileId || !originalCertData.pacValidityDate}
        >
          SUBMIT
        </Button>
      </Box>

      <Dialog open={openCertDialog} onClose={handleCertDialogClose} className={classes.dialog}>
        <MuiDialogTitle>
          <Typography className={classes.dialogTitle}>Upload New Certification</Typography>
          <Divider />
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.inputTitle}>1. Upload Certification File</Box>

          <Box>
            <UploadSingleFile
              buttonLabel="UPLOAD CERTIFICATION"
              handleAddFile={handleUploadCert}
              className={classes.uploadButton}
            />
          </Box>

          {certData.fileId && (
            <Box>
              <Box className={classes.fileName}>{certData.fileName}</Box>
              <Divider className={classes.divider} />
              <Box className={classes.inputTitle}>2. PAC Validity Date</Box>
              <Box className={classes.subtitle}>PAC Validity Date</Box>
              <DatePicker
                name="pacValidityDate"
                value={certData.pacValidityDate}
                handleChange={handleDataChange}
              />

              <Divider className={classes.divider} />
              <Box className={classes.inputTitle}>3. Remark</Box>
              <Box className={classes.subtitle}>Remark</Box>

              <Textarea
                rowsMin={5}
                name="remark"
                value={certData.remark}
                onChange={handleDataChange}
                className={classes.remark}
              />
            </Box>
          )}
        </MuiDialogContent>
        <DialogActions>
          <Button onClick={handleCertDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitCert}
            color="primary"
            variant="contained"
            disabled={!certData.fileId || !certData.pacValidityDate}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadPACCert;
