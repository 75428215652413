import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SubmissionActionTable from "./SubmissionActionTable";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 10,
    width: "fit-content",
    paddingRight: 30,
  },
}));

const Submissions = ({ submissionData = {}, handleActionClick }) => {
  const classes = useStyles();

  const handleOpenSubmission = ({ id }) => {
    window.open(`/supplier/submissions/${id}`);
  };

  const handleOpenSubmissionByItem = ({ submissionId }) => {
    window.open(`/supplier/submissions/${submissionId}`);
  };

  return (
    <Box display="flex" className={classes.tableContainer}>
      <SubmissionActionTable
        type={submissionData.type}
        tableTitle={submissionData.tableTitle}
        totalNumber={submissionData.totalNumber}
        rowData={submissionData.rowData}
        handleRowClick={handleOpenSubmission}
        handleSubRowClick={handleOpenSubmissionByItem}
        handleActionClick={handleActionClick}
      />
    </Box>
  );
};

export default Submissions;
