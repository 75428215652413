//checking of reason remark for $0 cost

export const isNegativePrice = (
  isInspectionType,
  totalPrice,
  subTotal,
  prepaymentPrice,
) => {
  return isInspectionType ? parseFloat(totalPrice) < 0 : subTotal - prepaymentPrice < 0
};

//show reason modal
export const isPriceZeroAndNoPrepayment = (totalPrice, hasPrepayment) =>
  parseFloat(totalPrice) === 0 && !hasPrepayment;

//show warning modal
export const hasPrepaymentAndNegativePrice = (
  hasPrepayment,
  isNegativePriceCb,
) => (hasPrepayment ? isNegativePriceCb : false);

//go to nextpage
export const isPriceValidAndHasPrepayment = (
  isInspectionType,
  hasPrepayment,
  prepaymentPrice,
  totalPrice,
  subTotal,
) => {
  return hasPrepayment
    ? isInspectionType
      ? parseFloat(totalPrice) >= 0
      : subTotal - prepaymentPrice >= 0
    : false;
};

//go to nextpage
export const isPriceNotZeroAndNoPrepayment = (hasPrepayment, totalPrice) =>
  !hasPrepayment && parseFloat(totalPrice) > 0;
