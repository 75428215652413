import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import TestInProgress from "./TestInProgress";
import UpdateResultAndReports from "./UpdateResultAndReports";
import Pending from "./Pending";
import Complete from "./Complete";

import CompleteWithDialog from "../components/CompleteWithDialog";
import UpdateStatusHeader from "../components/UpdateStatusHeader";
import UpdateStatusSelectUpdateType from "../components/UpdateStatusSelectUpdateType";

import ConfirmModal from "components/modal/ConfirmModal";
import { formatFloatingNumber } from "utils/string";

import {
  atnGetCompleteData,
  atnGetSubmissionDetailsTaskList,
  atnUpdateCompleteStatus,
  atnUpdateTaskStatus,
} from "actions/submissionActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectFieldContainer: {
    paddingTop: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  selectField: {
    width: "100%",
  },
  selectTaskContainer: {
    paddingTop: 50,
  },
  tableContainer: {
    position: "relative",
    paddingTop: 20,
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
}));

const UpdateTestingStatus = ({ history, submissionId, submissionType }) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [openSampleReceivedDialog, setOpenSampleReceivedDialog] = useState(false);
  const [isDisabledConformButton, setIsDisabledConformButton] = useState(false);
  const [isNb, setIsNb] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Step 1: Select Update Type");
  const [updateStatusData, setUpdateStatusData] = useState({
    updateType: "",
    selectedTasks: [],
    expectedCompletionDate: "", // booking_reserved
    inhouseLabNumber: "", // booking_reserved
    pendingReason: "", // pending
    taskFiles: [], // pending
    reports: [], // upload report and result
    validityPeriod: null, // upload report and result
  });

  const [originalTestItems, setOriginalTestItems] = useState([]);

  const [showCompleteDialog, setShowCompleteDialog] = useState(false);

  const [completeData, setCompleteData] = useState({
    id: "",
    currency: "",
    deliveryNote: [],
    isPackagePrice: false,
    isPrepayment: false,
    packageRemark: "",
    remarks: "",
    sampleSize: "",
    tat: "",
    subTotal: 0,
    prepaymentPrice: 0,
    packagePrice: "",
    totalPrice: 0,
    vat: "",
    reasonOption: "",
    reasonRemarks: "",
  });

  const [testItems, setTestItems] = useState([]);

  const [prepaymentData, setPrepaymentData] = useState({
    hasPrepayment: false,
    prepaymentPrice: 0,
  });

  const isTotalPriceZero = parseInt(completeData.totalPrice) === 0;

  const isUpdateTypeCompleted = updateStatusData.updateType === "completed";

  const handleResetReasonModal = () => {
    setCompleteData((state) => ({
      ...state,
      reasonOption: "",
      reasonRemarks: "",
    }));
  };

  const handleCompleteDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "isPackagePrice") {
      if (value) {
        let newTotalPrice = completeData.packagePrice || 0;

        // if (completeData.prepaymentPrice) {
        //   newTotalPrice -= (completeData.prepaymentPrice || 0);
        // }

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        if (completeData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(completeData.vat) / 100)).toFixed(2);
        }

        setCompleteData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      } else {
        let newTotalPrice = completeData.subTotal;

        // if (completeData.prepaymentPrice) {
        //   newTotalPrice -= (completeData.prepaymentPrice || 0);
        // }

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        if (completeData.vat) {
          newTotalPrice = (newTotalPrice * (1 + parseFloat(completeData.vat) / 100)).toFixed(2);
        }

        setCompleteData((prevState) => ({
          ...prevState,
          [name]: value,
          packagePrice: "",
          packageRemark: "",
          totalPrice: newTotalPrice,
        }));
      }
    } else if (name === "packagePrice") {
      let newTotalPrice = value || 0;

      // if (completeData.prepaymentPrice) {
      //   newTotalPrice -= (completeData.prepaymentPrice || 0);
      // }

      if (prepaymentData.hasPrepayment) {
        newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
      }

      if (completeData.vat) {
        newTotalPrice = (newTotalPrice * (1 + parseFloat(completeData.vat) / 100)).toFixed(2);
      }

      setCompleteData((prevState) => ({
        ...prevState,
        [name]: value,
        totalPrice: newTotalPrice,
      }));
    } else if (name === "vat") {
      if (completeData.isPackagePrice) {
        let newTotalPrice = completeData.packagePrice || 0;

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        setCompleteData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      } else {
        let newTotalPrice = completeData.subTotal || 0;

        if (prepaymentData.hasPrepayment) {
          newTotalPrice = newTotalPrice - prepaymentData.prepaymentPrice;
        }

        setCompleteData((prevState) => ({
          ...prevState,
          [name]: value,
          totalPrice: value
            ? (newTotalPrice * (1 + parseFloat(value) / 100)).toFixed(2)
            : newTotalPrice,
        }));
      }
    } else {
      setCompleteData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleTestItemDataChange = (e, index) => {
    const { name, value } = e.target;

    setTestItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;

          if (name === "quantity") {
            if (value && item.unitPrice) {
              item.subTotal = parseInt(value) * parseFloat(item.unitPrice);
            } else {
              item.subTotal = 0;
            }
          }

          if (name === "unitPrice") {
            if (value && item.quantity) {
              item.subTotal = parseInt(item.quantity) * parseFloat(value);
            } else {
              item.subTotal = 0;
            }
          }
        }

        return item;
      });
    });

    setTimeout(() => {
      let subTotal = 0;

      for (let i = 0; i < testItems.length; i++) {
        if (testItems[i].quantity && testItems[i].unitPrice) {
          subTotal = formatFloatingNumber(
            subTotal + parseInt(testItems[i].quantity) * parseFloat(testItems[i].unitPrice),
          );
        }
      }

      // let totalPrice = subTotal - (completeData.prepaymentPrice || 0);
      let totalPrice = subTotal;

      if (completeData.isPackagePrice) {
        // totalPrice = (completeData.packagePrice || 0) - (completeData.prepaymentPrice || 0);
        totalPrice = completeData.packagePrice || 0;
      }

      if (prepaymentData.hasPrepayment) {
        totalPrice = totalPrice - prepaymentData.prepaymentPrice;
      }

      if (completeData.vat) {
        totalPrice = (totalPrice * (1 + parseFloat(completeData.vat) / 100)).toFixed(2);
      }

      setCompleteData((prevState) => ({
        ...prevState,
        subTotal: subTotal,
        totalPrice: totalPrice,
      }));
    }, 200);
  };

  const handleAddNewTestItem = () => {
    setTestItems((prevState) => {
      return prevState.concat({
        itemName: "",
        quantity: "",
        unitPrice: "",
        subTotal: 0,
      });
    });
  };

  const handleRemoveTestItem = (index) => {
    let subTotal = 0;

    for (let i = 0; i < testItems.length; i++) {
      if (i !== index && testItems[i].quantity && testItems[i].unitPrice) {
        subTotal = formatFloatingNumber(
          subTotal + (testItems[i].quantity || 0) * (testItems[i].unitPrice || 0),
        );
      }
    }

    let totalPrice = subTotal;

    if (completeData.isPackagePrice) {
      totalPrice = completeData.packagePrice || 0;
    }

    if (prepaymentData.hasPrepayment) {
      totalPrice = totalPrice - prepaymentData.prepaymentPrice;
    }

    if (completeData.vat) {
      totalPrice = (totalPrice * (1 + (completeData.vat || 0) / 100)).toFixed(2);
    }

    setCompleteData((prevState) => ({
      ...prevState,
      subTotal: subTotal,
      totalPrice: totalPrice,
    }));

    setTestItems((prevState) => {
      return prevState.filter((item, i) => i !== index);
    });
  };

  const initData = (updateType) => {
    setUpdateStatusData({
      updateType: updateType,
      selectedTasks: [],
      expectedCompletionDate: "",
      inhouseLabNumber: "",
      pendingReason: "",
      taskFiles: [],
      reports: [],
      validityPeriod: null, // upload report and result
    });
  };

  const handleUpdateStatusDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "updateType") {
      initData(value);
    } else {
      setUpdateStatusData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRemoveReport = (index) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewReport = (report) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.concat(report),
    }));
  };

  const handleRemoveTaskFile = (index) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      taskFiles: prevState.taskFiles.filter((item, i) => i !== index),
    }));
  };

  const handleAddNewTaskFiles = (newFiles) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      taskFiles: prevState.taskFiles.concat(newFiles),
    }));
  };

  const handleConfirmEditReport = (index, newReportData) => {
    setUpdateStatusData((prevState) => ({
      ...prevState,
      reports: prevState.reports.map((item, i) => {
        if (i === index) {
          return newReportData;
        }

        return item;
      }),
    }));
  };

  const handleSampleReceivedDialogClose = () => {
    setOpenSampleReceivedDialog(false);
  };

  const handleCompleteDialogClose = () => {
    setShowCompleteDialog(false);
  };

  const handlePrevPage = () => {
    if (step > 0) {
      if (step === 1) {
        if (updateStatusData.updateType === "booking_reserved") {
          setHeaderTitle("Step 2: Update Information");
        } else if (updateStatusData.updateType === "in_progress_result_report_updated") {
          setHeaderTitle("Step 2: Update Report and Result");
        }
      }

      setStep(step - 1);
    }
  };

  const handleNextPage = (selectedTasks = []) => {
    if (step === 0) {
      if (updateStatusData.updateType === "in_progress") {
        setHeaderTitle("Step 2: Update Information");
        setStep(step + 1);
      } else if (updateStatusData.updateType === "in_progress_result_report_updated") {
        setHeaderTitle("Step 2: Update Report and Result");
        setStep(step + 1);
      } else if (updateStatusData.updateType === "sample_received") {
        setOpenSampleReceivedDialog(true);
      } else if (updateStatusData.updateType === "pending") {
        setHeaderTitle("Step 2: Pending");
        setStep(step + 1);
      } else if (isUpdateTypeCompleted) {
        atnGetSubmissionDetailsTaskList({
          data: { id: submissionId },
          type: 3,
        }).then((result) => {
          if (result && result.data) {
            let showInvoice = true;
            let selectedTaskIds = [];

            for (let i = 0; i < selectedTasks.length; i++) {
              selectedTaskIds.push(selectedTasks[i].id);
            }

            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i].isNb) {
                setIsNb(result.data[i].isNb);
              }

              if (
                selectedTaskIds.indexOf(result.data[i].id) === -1 &&
                result.data[i].isCancelled === "false" &&
                result.data[i].taskStatus !== "completed"
              ) {
                showInvoice = false;
                break;
              }
            }

            if (showInvoice) {
              atnGetCompleteData({
                submissionId: submissionId,
              }).then(({ data, message }) => {
                if (data && data.length > 0) {
                  let lastData = data[data.length - 1];
                  let tempTestItems = [];

                  let hasPrepayment = false;
                  let prepaymentPrice = 0;

                  for (let i = 0; i < data.length; i++) {
                    if (data[i].isPrepayment) {
                      hasPrepayment = true;
                      prepaymentPrice = data[i].totalPrice;
                      break;
                    }
                  }

                  setPrepaymentData({
                    hasPrepayment: hasPrepayment,
                    prepaymentPrice: prepaymentPrice,
                  });

                  let subTotal = 0;
                  let itemSubTotal = 0;

                  for (let i = 0; i < lastData.testPlans.length; i++) {
                    itemSubTotal =
                      lastData.testPlans[i].quantity && lastData.testPlans[i].unitPrice
                        ? (
                            parseInt(lastData.testPlans[i].quantity) *
                            parseFloat(lastData.testPlans[i].unitPrice)
                          ).toFixed(2)
                        : 0;
                    subTotal += parseFloat(itemSubTotal);

                    tempTestItems.push({
                      id: lastData.testPlans[i].id,
                      itemName: lastData.testPlans[i].itemName,
                      quantity: lastData.testPlans[i].quantity,
                      unitPrice: lastData.testPlans[i].unitPrice,
                      subTotal: itemSubTotal,
                      hasReport: lastData.testPlans[i].hasReport,
                    });
                  }

                  setTestItems(tempTestItems);
                  setOriginalTestItems(lastData.testPlans);

                  setCompleteData({
                    id: lastData.id,
                    currency: lastData.currency,
                    deliveryNote: lastData.deliveryNote || [],
                    isPackagePrice: lastData.isPackagePrice,
                    isPrepayment: lastData.isPrepayment,
                    packageRemark: lastData.packageRemark,
                    remarks: lastData.remarks,
                    sampleSize: lastData.sampleSize,
                    tat: lastData.tat,
                    subTotal: subTotal,
                    prepaymentPrice: lastData.prepaymentPrice,
                    packagePrice: lastData.packagePrice,
                    totalPrice: lastData.totalPrice,
                    vat: lastData.vat,

                    reasonOption: lastData.reasonOption,
                    reasonRemarks: lastData.reasonRemarks,
                  });

                  setHeaderTitle("Step 2: Invoice Update");
                  setStep(step + 1);
                }
              });
            } else {
              setShowCompleteDialog(true);
            }
          }
        });
      }
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    setIsDisabledConformButton(true);

    if (isUpdateTypeCompleted) {
      if (showCompleteDialog) {
        let submitData = {
          submissionId: submissionId,
          tasks: updateStatusData.selectedTasks,
        };

        atnUpdateCompleteStatus(submitData).then(({ data, message }) => {
          if (data) {
            setIsDisabledConformButton(false);
            history.push(`/lab/submissions/${submissionId}`);
          }
        });
      } else {
        let newTestItemIds = [];

        for (let i = 0; i < testItems.length; i++) {
          if (testItems[i].id) {
            newTestItemIds.push(testItems[i].id);
          }
        }

        let deletedTestItems = [];

        for (let i = 0; i < originalTestItems.length; i++) {
          if (newTestItemIds.indexOf(originalTestItems[i].id) === -1) {
            deletedTestItems.push({
              id: originalTestItems[i].id,
            });
          }
        }

        let packagePrice = completeData.packagePrice;

        if (completeData.isPackagePrice && !completeData.packagePrice) {
          packagePrice = 0;
        }

        const invoice = {
          id: completeData.id,
          currency: completeData.currency,
          totalPrice: completeData.totalPrice,
          vat: completeData.vat || 0,
          remarks: completeData.remarks,
          packageRemark: completeData.packageRemark,
          tat: completeData.tat,
          sampleSize: completeData.sampleSize,
          isPackagePrice: completeData.isPackagePrice,
          packagePrice: packagePrice,
        };

        let submitData = {
          submissionId: submissionId,
          invoice:
            isUpdateTypeCompleted && isTotalPriceZero
              ? {
                  ...invoice,
                  reasonOption: completeData.reasonOption,
                  reasonRemarks: completeData.reasonRemarks,
                }
              : invoice,
          invoiceTestItems: testItems,
          deletedTestItems: deletedTestItems,
          tasks: updateStatusData.selectedTasks,
        };

        atnUpdateCompleteStatus(submitData).then(({ data, message }) => {
          if (data) {
            setIsDisabledConformButton(false);
            history.push(`/lab/submissions/${submissionId}`);
          }
        });
      }
    } else {
      let submitData = [];

      if (updateStatusData.updateType === "in_progress") {
        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
            expectedCompletionDate: moment(updateStatusData.expectedCompletionDate).format(
              "YYYY-MM-DD",
            ),
            inhouseLabNumber: updateStatusData.inhouseLabNumber,
          });
        }
      } else if (updateStatusData.updateType === "in_progress_result_report_updated") {
        let selectedTestItems = [];
        let tempReports = updateStatusData.reports;

        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          for (let j = 0; j < tempReports.length; j++) {
            selectedTestItems = [];

            for (let k = 0; k < tempReports[j].testItems.length; k++) {
              if (tempReports[j].testItems[k].isSelected) {
                selectedTestItems.push(tempReports[j].testItems[k]);
              }
            }

            tempReports[j].testItems = selectedTestItems;
          }

          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
            validityPeriod: updateStatusData.validityPeriod, // upload report and result
            reports: tempReports,
          });
        }
      } else if (updateStatusData.updateType === "sample_received") {
        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
          });
        }
      } else if (updateStatusData.updateType === "pending") {
        let taskFiles = [];

        for (let i = 0; i < updateStatusData.taskFiles.length; i++) {
          taskFiles.push({
            fileId: updateStatusData.taskFiles[i].id,
            type: "supporting_document",
          });
        }

        for (let i = 0; i < updateStatusData.selectedTasks.length; i++) {
          submitData.push({
            taskId: updateStatusData.selectedTasks[i].id,
            taskStatus: updateStatusData.updateType,
            pendingReason: updateStatusData.pendingReason,
            taskFiles: taskFiles,
          });
        }
      }

      atnUpdateTaskStatus(submitData).then(({ data, message }) => {
        if (data) {
          setIsDisabledConformButton(false);
          history.push(`/lab/submissions/${submissionId}`);
        }
      });
    }
  };

  return (
    <Box className={classes.root}>
      <UpdateStatusHeader
        step={step}
        headerTitle={headerTitle}
        handlePrevPage={handlePrevPage}
        handleClose={handleClose}
      />

      {step === 0 && (
        <UpdateStatusSelectUpdateType
          updateStatusData={updateStatusData}
          submissionId={submissionId}
          submissionType={submissionType}
          handleNextPage={handleNextPage}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
        />
      )}

      {step === 1 && updateStatusData.updateType === "in_progress" && (
        <TestInProgress
          updateStatusData={updateStatusData}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
          handleSubmit={handleSubmit}
          isDisabledConformButton={isDisabledConformButton}
        />
      )}

      {step === 1 && updateStatusData.updateType === "in_progress_result_report_updated" && (
        <UpdateResultAndReports
          submissionId={submissionId}
          updateStatusData={updateStatusData}
          handleAddNewReport={handleAddNewReport}
          handleConfirmEditReport={handleConfirmEditReport}
          handleRemoveReport={handleRemoveReport}
          handleSubmit={handleSubmit}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
          isDisabledConformButton={isDisabledConformButton}
        />
      )}

      {step === 1 && updateStatusData.updateType === "pending" && (
        <Pending
          updateStatusData={updateStatusData}
          handleUpdateStatusDataChange={handleUpdateStatusDataChange}
          handleAddNewTaskFiles={handleAddNewTaskFiles}
          handleRemoveTaskFile={handleRemoveTaskFile}
          handleSubmit={handleSubmit}
          isDisabledConformButton={isDisabledConformButton}
        />
      )}

      {step === 1 && isUpdateTypeCompleted && (
        <CompleteWithDialog
          title="Update and Confirm the Invoice Detail"
          completeData={completeData}
          testItems={testItems}
          isNb={isNb}
          prepaymentData={prepaymentData}
          onSubmit={handleSubmit}
          handleCompleteDataChange={handleCompleteDataChange}
          handleResetReasonModal={handleResetReasonModal}
          component={Complete}
          handleTestItemDataChange={handleTestItemDataChange}
          handleAddNewTestItem={handleAddNewTestItem}
          handleRemoveTestItem={handleRemoveTestItem}
        />
      )}

      <ConfirmModal
        isDisabledSubmit={isDisabledConformButton}
        open={openSampleReceivedDialog}
        onClose={handleSampleReceivedDialogClose}
        onSubmit={handleSubmit}
        contentText="Do you confirm to submit?"
      />

      <ConfirmModal
        isDisabledSubmit={isDisabledConformButton}
        open={showCompleteDialog}
        onClose={handleCompleteDialogClose}
        onSubmit={handleSubmit}
        hasContentList
      />
    </Box>
  );
};

export default UpdateTestingStatus;
