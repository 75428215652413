import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";

import DatePicker from "components/input/DatePicker";
import TextInput from "components/input/TextInput";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const TestInProgress = ({
  updateStatusData = {},
  handleUpdateStatusDataChange,
  handleSubmit,
  isDisabledConformButton,
}) => {
  const classes = useStyles();

  const handleSubmitClick = () => {
    // check empty
    handleSubmit();
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Update Expected Completion Date</Box>

      <Paper className={classes.container}>
        <Box className={classes.title}>1. Update Expected Completion Date</Box>
        <Box className={classes.subtitle}>Expected Completion Date (ECD)</Box>
        <DatePicker
          name="expectedCompletionDate"
          value={updateStatusData.expectedCompletionDate}
          handleChange={handleUpdateStatusDataChange}
          additionalClass={classes.inputField}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          2. Inhouse Lab Number (Optional)
        </Box>
        <Box className={classes.subtitle}>Lab Number</Box>
        <TextInput
          placeholder="Lab Number"
          name="inhouseLabNumber"
          value={updateStatusData.inhouseLabNumber}
          additionalClass={classes.inputField}
          onChange={handleUpdateStatusDataChange}
        />
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={isDisabledConformButton}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default TestInProgress;
