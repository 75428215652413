import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const InspectionDetails = ({ data = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    inspectionDateFrom,
    inspectionDateTo,
    inspectionLevel,
    aqlCritical,
    aqlMajor,
    aqlMinor,
    totalQuantity,
    expectedInspectionDate,
    expectedSamplePickUpDate,
  } = data;

  const Item = ({ label, value, noPaddingBottom = false }) => (
    <Box className={classes.item} pb={noPaddingBottom ? 0 : 3}>
      <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>
      <Typography className={classnames(classes.text, classes.blackText)}>
        {textOrUnknown(value)}
      </Typography>
    </Box>
  );

  return (
    <Box py={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Inspection Date From" value={getDateFormat(inspectionDateFrom)} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Item label="Inspection Date to" value={getDateFormat(inspectionDateTo)} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Inspection Level" value={t(`inspectionLevel.${inspectionLevel}`)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="AQL Level Critical" value={aqlCritical} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="AQL Level Major" value={aqlMajor} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="AQL Level Minor" value={aqlMinor} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Item label="Total Quantity" value={totalQuantity} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Expected Inspection Date" value={expectedInspectionDate} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Item label="Expected Sample Pickup Date" value={expectedSamplePickUpDate} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InspectionDetails;
