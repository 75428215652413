import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Textarea from "components/input/Textarea";
import UploadFiles from "components/input/UploadFiles";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  pendingReason: {
    width: "100%",
    fontFamily: "inherit",
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const Pending = ({
  updateStatusData = {},
  handleUpdateStatusDataChange,
  handleAddNewTaskFiles,
  handleRemoveTaskFile,
  handleSubmit,
  isDisabledConformButton,
}) => {
  const classes = useStyles();

  const handleSubmitClick = () => {
    if (!updateStatusData.pendingReason || updateStatusData.pendingReason === "") {
      return alert("Pending reason cannot be empty!");
    }
    // check empty
    handleSubmit();
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.pageTitle}>Pending Reason</Box>

      <Paper className={classes.container}>
        <Box className={classes.title}>What is the Pending Reason *</Box>
        <Textarea
          rowsMin={5}
          name="pendingReason"
          value={updateStatusData.pendingReason}
          onChange={handleUpdateStatusDataChange}
          className={classes.pendingReason}
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>Supporting Documents</Box>

        <Box>
          <UploadFiles
            buttonLabel="UPLOAD SUPPORTING DOC"
            files={updateStatusData.taskFiles}
            handleAddFiles={handleAddNewTaskFiles}
            handleRemoveFile={handleRemoveTaskFile}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={handleSubmitClick}
        disabled={isDisabledConformButton}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default Pending;
