import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Switch, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import Card from "components/display/Card";
import Chip from "components/display/Chip";
import Link from "components/display/Link";
import Table from "components/table/Table";
import Tabs from "components/navigation/Tabs";
import Status from "components/display/Status";
import FileUpload from "components/input/FileUpload";
import Modal from "components/modal/Modal";
import GifLoading from "components/display/GifLoading";
import PayerDetail, { defaultBillingAddress } from "pages/supplier/Workflow/components/PayerDetail";
import auth from "auth/authenticator";

import Program777Chip from "components/display/Program777Chip";

import Quote from "./tabs/Quote";
import ReportDetail from "./tabs/ReportDetail";
import Invoice from "./tabs/Invoice";

import { textOrUnknown } from "utils/string";
import { getDateFormat } from "utils/date";

import {
  DD_MMM_YYYY,
  INVOICE_TYPE,
  LAB,
  SUPPLIER,
  TASK_STATUS,
  TASK_STATUS_ENUM,
  TASK_TYPE_ENUM,
} from "shared/constants";

import { atnDeleteTask } from "actions/itemActions";

import { isShowProgram777 } from "services/pbService";

import { atnGetTaskPayer, atnUpdateNb, atnUpdateTaskPayer } from "actions/taskActions";
import useUserType from "hooks/useUserType";
import NBChip from "../../../../../components/display/NBChip";
import SubmissionRemark from "../../../SubmissionRemark";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    color: "#777777",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  titlePayer: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
    display: "block",
    borderBottom: "1px solid #003DA5",
  },
  subTitle: {
    fontSize: 12,
  },
  wrapperPayerForm: {
    background: "white",

    "& > div": {
      boxShadow: "none",
    },
  },
  titleInner: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  titleInnerPayer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px 30px 15px",

    "& span": {
      color: "red",
      marginLeft: 10,
    },
  },
  right: {
    textAlign: "right",
  },
  tableContainer: {
    marginBottom: theme.spacing(4),
  },
  retestLabel: {
    marginLeft: 10,
    padding: "5px 15px",
    backgroundColor: "#B0D400",
    color: "#FFFFFF",
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
  },
  assignToLabBox: {
    width: "fit-content",
    padding: "5px 15px",
    backgroundColor: "#1BB135",
    color: "#FFFFFF",
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
    margin: "10px 0",
  },
  assignToSupplierBox: {
    width: "fit-content",
    padding: "5px 15px",
    backgroundColor: "#196EFF",
    color: "#FFFFFF",
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
    margin: "10px 0",
  },
  groupDelete: {
    float: "right",
    marginTop: 18,
    marginBottom: 18,

    "& button": {
      backgroundColor: "transparent",
      color: "#D42600",
      boxShadow: "none",
      textTransform: "none",
    },

    "&:hover": {
      "& button": {
        color: "white",
      },
    },
  },
  textClear: {
    "& span": {
      color: "#005EFF",
    },
  },
  groupClearBtn: {
    float: "right",
    marginTop: 50,
  },
  applyBtn: {
    marginLeft: 15,
  },
  wrapperSuccess: {
    position: "fixed",
    background: "#003DA5",
    color: "white",
    zIndex: 999,
    width: 760,
    bottom: 20,
    padding: "16px 30px",

    "& a": {
      border: "1px solid white",
      marginLeft: 15,

      "& span": {
        color: "white",
      },
    },
  },
}));

const payerErrorMessage = "Cannot update payer, please try again.";

const DetailBox = ({
  data,
  taskId,
  brand,
  itemStatus,
  taskType,
  category,
  allowSvrr,
  listTasks,
  masterWicId,
  onUpdate,
  hasPayerInfo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    submissionNumber: submissionNo,
    submissionNoUrl,
    assignTo,
    isProgram777,
    triggeredBy,
    status,
  } = data;

  const [showSuccess, setShowSuccess] = useState(false);
  const [tempIsNb, setTempIsNb] = useState(data.isNb || false);

  const [showFormPayer, setShowFormPayer] = useState(false);
  const [payerInfo, setPayerInfo] = useState(null);

  const { isSupplier } = useUserType(auth.userType());

  const showProgram777 = isShowProgram777(triggeredBy);

  const isAssignToSupplier = assignTo === SUPPLIER;

  useEffect(() => {
    handleGetTaskPayer(taskId);
  }, []);

  useEffect(() => {
    handleGetTaskPayer(taskId);
  }, [hasPayerInfo]);

  const handleGetTaskPayer = (taskId) => {
    if (
      taskType === TASK_TYPE_ENUM.SS &&
      assignTo === LAB &&
      ![TASK_STATUS_ENUM.CANCELLED, TASK_STATUS_ENUM.COMPLETED].includes(status)
    ) {
      atnGetTaskPayer({ taskId: taskId }).then((res) => {
        if (res.code === 404 || !res?.data) {
          if (isSupplier) setShowFormPayer(true);
          return;
        }

        setPayerInfo(res?.data);
        setShowFormPayer(false);
      });
    }
  };

  const handleUpdateNB = () => {
    atnUpdateNb({ taskId: taskId, masterWicId }).then((res) => {
      if (res.code === 404 || !res?.data) {
        return;
      }

      setTempIsNb(res.data.isNb);
    });
  };

  const SubmissionNoLink = () =>
    submissionNoUrl ? (
      <Link path={submissionNoUrl} text={submissionNo} />
    ) : (
      textOrUnknown(submissionNo)
    );

  const Header = () => {
    const {
      taskType,
      taskNumber: taskNo,
      lab,
      isRetest,
      status,
      completedStatus,
      expectedCompletionDate,
      remark,
      confirmedInspectionDate,
      assignTo,
    } = data;

    return (
      <Box className={classes.header}>
        <Box className={classes.left}>
          <Typography className={classes.title}>
            <div className={classes.titleInner}>
              {showProgram777 && <Program777Chip />}
              {t(`task.${taskType}`)} {tempIsNb && <NBChip />}
            </div>

            {remark && `(${remark})`}
          </Typography>
          <Typography className={classes.subTitle}>
            {assignTo === LAB && <Box className={classes.assignToLabBox}>To Lab</Box>}
            {isAssignToSupplier && (
              <Box className={classes.assignToSupplierBox}>Self Report Upload</Box>
            )}
            {!assignTo && "Assign To: TBC"}
          </Typography>
          <Typography className={classes.subTitle}>
            Submission No: <SubmissionNoLink /> • Task No: {textOrUnknown(taskNo)}
          </Typography>
          <Typography className={classes.subTitle}>
            {textOrUnknown(lab)} •{" "}
            <Status
              value={completedStatus || status}
              className={{ text: classes.subTitle }}
              withText
              withIcon={false}
            />
          </Typography>
        </Box>
        <Box className={classes.right}>
          <Box display="flex">
            <Box>
              <Typography className={classes.subTitle}>
                {[TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID, TASK_TYPE_ENUM.DPI].includes(taskType) &&
                  `Expected Inspection Date: ${getDateFormat(
                    confirmedInspectionDate,
                    DD_MMM_YYYY,
                    "TBC",
                  )}`}

                {[TASK_TYPE_ENUM.SPU, TASK_TYPE_ENUM.SPUD].includes(taskType) &&
                  `Expected Sample Pick Up Date: ${getDateFormat(
                    confirmedInspectionDate,
                    DD_MMM_YYYY,
                    "TBC",
                  )}`}

                {[
                  TASK_TYPE_ENUM.QT,
                  TASK_TYPE_ENUM.PST,
                  TASK_TYPE_ENUM.ST,
                  TASK_TYPE_ENUM.STD,
                  TASK_TYPE_ENUM.PAC,
                ].includes(taskType) &&
                  `Expected Completion Date: ${getDateFormat(
                    expectedCompletionDate,
                    DD_MMM_YYYY,
                    "TBC",
                  )}`}
              </Typography>
            </Box>
            {isRetest && <Box className={classes.retestLabel}>RETEST</Box>}
          </Box>
        </Box>

        {/* ------------ SUCCESS DELETE MESSAGE ------------ */}
        {showSuccess && (
          <Box className={classes.wrapperSuccess}>
            <Box display="flex">
              <Box>
                You have deltete one “{t(`task.${taskType}`)} , Task No : {textOrUnknown(taskNo)} ”
                successfuly. If you want to open new task, click action button to add new task.
              </Box>
              <Button
                variant="outlined"
                color="primary"
                href="#outlined-buttons"
                onClick={() => setShowSuccess(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        )}
        {/* ------------ END SUCCESS DELETE MESSAGE ------------ */}
      </Box>
    );
  };

  const Content = () => {
    const {
      subWICNo = [],
      shareCostWith = [],
      detailTabs = [],
      inspectionTaskDetails,
      firstShipmentTotalQuantity,
      fullPoTotalQuantity,
      po = [],
      pb = [],
      e2open = [],
      selectedLabs = [],
      pendingReason,
      sampleReceivedDate,
      completedStatus,
      completedReason,
      taskFiles = [],
      id,
      taskType,
      taskNumber,
      status,
    } = data;

    const tableColumn = [
      {
        columnId: "title",
        render: (data) => {
          return data.title;
        },
      },
      {
        columnId: "value",
        render: (data) => {
          return data.value;
        },
      },
    ];

    const tableData = useMemo(() => {
      const tempData = [
        {
          id: uuid(),
          title: "SubWIC No.",
          value: subWICNo.length
            ? subWICNo.map((item) => <Chip key={item.id} label={item.wicNumber} />)
            : "N/A",
        },
        {
          id: uuid(),
          title: "Share Cost with",
          value: shareCostWith.length ? (
            <>
              {shareCostWith.map((item, index) => (
                <span key={item.id}>{`${item.wicNumber}${
                  index < shareCostWith.length - 1 ? ", " : ""
                }`}</span>
              ))}{" "}
              <span>
                (Submission ID: <SubmissionNoLink />)
              </span>
            </>
          ) : (
            "N/A"
          ),
        },
      ];

      if (
        (taskType === "ST" || taskType === "STD") &&
        (brand === "National Brand" || brand === "Vendor Brand" || brand === "License Brand")
      ) {
        if (status !== "not_open_for_quote" || !isSupplier) {
          tempData.push({
            id: uuid(),
            title: "Supplier Voluntary Report Review",
            value: tempIsNb ? "Yes" : "No",
          });
        } else {
          if (isSupplier && allowSvrr) {
            tempData.push({
              id: uuid(),
              title: "Supplier Voluntary Report Review",
              value: (
                <>
                  <Switch
                    // disabled={status !== 'not_open_for_quote'}
                    onChange={() => handleUpdateNB()}
                    checked={tempIsNb}
                    name="checkedB"
                    color="primary"
                  />
                </>
              ),
            });
          }
        }
      }

      if (pendingReason) {
        tempData.push({
          id: uuid(),
          title: "Pending Reason",
          value: pendingReason,
        });
      }

      if (sampleReceivedDate) {
        tempData.push({
          id: uuid(),
          title: "Sample Received Date",
          value: sampleReceivedDate,
        });
      }

      return tempData;
    }, [taskType, tempIsNb]);

    if (completedReason) {
      let title = "Accept Reason";
      if (completedStatus === TASK_STATUS.completed_concession.value) {
        title = "Concession Reason";
      } else if (completedStatus === TASK_STATUS.completed_reject.value) {
        title = "Rejected Reason";
      }

      tableData.push({
        id: uuid(),
        title,
        value: completedReason,
      });
    }

    if (taskFiles.length > 0) {
      let supportingDocuments = [];

      for (let i = 0; i < taskFiles.length; i++) {
        if (taskFiles[i].type === "supporting_document") {
          supportingDocuments.push(taskFiles[i]);
        }
      }

      tableData.push({
        id: uuid(),
        title: "Supporting Documents",
        value: supportingDocuments.length > 0 && (
          <FileUpload data={supportingDocuments} editable={false} multiple />
        ),
      });
    }

    const detailTabsData = detailTabs.map((tab) => {
      const { title, content } = tab;
      let component = null;
      if (title === "Quote")
        component = (
          <Quote
            data={content}
            category={category}
            taskId={data.id}
            taskStatus={data.status}
            taskType={taskType}
            inspectionTaskDetails={inspectionTaskDetails}
            firstShipmentTotalQuantity={firstShipmentTotalQuantity}
            fullPoTotalQuantity={fullPoTotalQuantity}
            po={po}
            pb={pb}
            e2open={e2open}
            selectedLabs={selectedLabs}
            assignTo={assignTo}
            isProgram777={isProgram777}
            triggeredBy={triggeredBy}
          />
        );
      if (title === "Report & Detail")
        component = (
          <ReportDetail
            data={content}
            pb={pb}
            e2open={e2open}
            status={data.status}
            completedStatus={data.completedStatus}
            itemStatus={itemStatus}
            taskType={taskType}
            taskId={data.id}
            taskRequirementId={data.taskRequirementId}
            submissionId={data.submissionId}
            assignTo={assignTo}
            masterWicId={masterWicId}
            onUpdate={onUpdate}
            isProgram777={isProgram777}
            triggeredBy={triggeredBy}
          />
        );
      if (title === "Invoice")
        component = <Invoice data={content} taskType={taskType} payerInfo={payerInfo} />;
      if (title === "Remark") component = <SubmissionRemark data={data} />;
      return { ...tab, component };
    });

    const [hasDeleteCancel, setHasDeleteCancel] = useState(false);

    const countTaskNotCancel = () => {
      let count = 0;

      for (let i = 0; i < listTasks.length; i++) {
        if (listTasks[i].status !== "cancelled") {
          count = count + 1;
        }
      }

      return count;
    };

    const hasDeleteCancelTask = () => {
      let count = countTaskNotCancel();

      if (count > 1 && (status === "waiting_for_lab_quote" || status === "not_open_for_quote")) {
        setHasDeleteCancel(true);
      } else {
        setHasDeleteCancel(false);
      }
    };

    useEffect(() => {
      hasDeleteCancelTask();
    }, []);

    const [showDeletePop, setShowDeletePop] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteTask = () => {
      setLoading(true);
      atnDeleteTask({
        taskID: id,
      }).then(({ data, message }) => {
        setLoading(false);
        setShowDeletePop(false);
        setShowSuccess(true);
        if (onUpdate) onUpdate();
      });
    };

    return (
      <>
        {loading && <GifLoading />}

        <Table
          classes={{ containerStyle: classes.tableContainer }}
          data={tableData}
          columnData={tableColumn}
          noHeader
          noPagination
        />

        {!!detailTabsData.length ? (
          <Tabs data={detailTabsData} taskType={data.taskType} assignTo={data.assignTo} />
        ) : (
          "N/A"
        )}

        {/* Temporary comment this code */}
        {hasDeleteCancel && (
          <Box className={classes.groupDelete}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => setShowDeletePop(true)}
            >
              Delete this task
            </Button>
          </Box>
        )}

        {/* ------------ MODAL CLEAR FILTER ------------ */}
        <Modal title="Delete Task" open={showDeletePop} handleClose={() => setShowDeletePop(false)}>
          <Box display="block" className={classes.textClear}>
            Are you sure you want to delete{" "}
            <span>
              {t(`task.${taskType}`)} , Task No : {textOrUnknown(taskNumber)}
            </span>{" "}
            ?
          </Box>
          <Box display="block" className={classes.groupClearBtn}>
            <Button color="primary" onClick={() => setShowDeletePop(false)}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.applyBtn}
              onClick={() => deleteTask()}
            >
              CONFIRM
            </Button>
          </Box>
        </Modal>
        {/* ------------ END MODAL CLEAR FILTER ------------ */}
      </>
    );
  };

  // for payer info
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const [payerData, setPayerData] = useState({
    billingAddress: defaultBillingAddress,
  });
  const [payerError, setPayerError] = useState("");

  useEffect(() => {
    if (!data.id) {
      return;
    }
  }, []);

  //* payer info for SS task with lab flow
  const handlePayerInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "billingAddress") {
      setIsNewBillingAddress(true);
    }

    setPayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setPayerError("");
    setIsDisabledConfirmButton(true);

    let tempBillingAddress = payerData?.billingAddress;

    if (isNewBillingAddress) {
      delete payerData?.billingAddress.id;
    }

    if (payerData?.billingAddress.invoiceType === INVOICE_TYPE.ICW_COMPANY_INVOICE) {
      delete tempBillingAddress.attachments;
      delete tempBillingAddress.taxInformationFiles;
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType === INVOICE_TYPE.PLAIN_VAT_INVOICE) {
      delete tempBillingAddress.taxRegistrationCode;
      delete tempBillingAddress.enterpriseName;
      delete tempBillingAddress.address;
      delete tempBillingAddress.bankName;
      delete tempBillingAddress.contactNumber;
      delete tempBillingAddress.bankAddress;
    }

    if (payerData?.billingAddress.invoiceType !== INVOICE_TYPE.ICW_COMPANY_INVOICE) {
      let taxInformationFiles = [];

      for (let i = 0; i < payerData?.billingAddress?.attachments?.length; i++) {
        taxInformationFiles.push({
          id: payerData?.billingAddress?.attachments[i]?.id,
          type: "tax_information",
        });
      }

      tempBillingAddress.taxInformationFiles = taxInformationFiles;

      if (!payerInfo) {
        delete tempBillingAddress.attachments;
      }
    }

    atnUpdateTaskPayer({ taskId, data: payerData?.billingAddress })
      .then((res) => {
        if (!res?.data) return setPayerError(payerErrorMessage);

        handleGetTaskPayer(taskId);
      })
      .finally(() => {
        setIsDisabledConfirmButton(false);
      });
  };

  return (
    <>
      {showFormPayer && !hasPayerInfo ? (
        <Box className={classes.wrapperPayerForm}>
          <Typography className={classes.titlePayer}>
            <div className={classes.titleInnerPayer}>
              {t(`task.${taskType}`)} - <span>*Please Enter Payer Detail for Invoice</span>
            </div>
          </Typography>

          <PayerDetail
            actionsButtonEnabled
            cancelButtonDisabled
            isDisabledConfirmButton={isDisabledConfirmButton}
            errors={[payerError]}
            data={payerData}
            handleDataChange={handlePayerInfoChange}
            handleSubmit={handleSubmit}
          />
        </Box>
      ) : (
        <>
          <Card collapseComponent={<Header />} expandComponent={<Content />} expanded={true} />
        </>
      )}
    </>
  );
};

export default DetailBox;
