export const keysLS = {
  // key-value pairs
  labUpdateType: 'labUpdateType',
};

export const setLSItem = (key, value) => window.localStorage.setItem(key, value);

export const getLSItem = (key) => window.localStorage.getItem(key);

export const removeLSItem = (key) => window.localStorage.removeItem(key);

export const clearLS = () => window.localStorage.clear();

export const getLSKey = (index) => window.localStorage.key(index);
