import React, { useEffect, useState } from "react";
import { Table as MuiTable, TableContainer } from "@material-ui/core";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TablePagination from "./TablePagination";

const getRowsPerPageOptions = (length) => {
  let result = [];
  const defaultOptions = [10, 25, 50, 100];

  if (length > 0 && length < 100) {
    result = defaultOptions.filter((rows) => rows <= length);
  }

  if (length >= 100) result = defaultOptions;

  return result;
};

const sortAsc = (a, b, orderBy) => b[orderBy] - a[orderBy];
const sortDesc = (a, b, orderBy) => a[orderBy] - b[orderBy];

const getSorting = (order, orderBy) => {
  return (a, b) => (order === "desc" ? sortDesc(a, b, orderBy) : sortAsc(a, b, orderBy));
};

const Table = (props) => {
  const {
    classes = {},
    columnData,
    data,
    noEmptyRows,
    noHeader = false,
    noPagination = false,
    orderColumn,
    paginationProps,
    rowsPerPage: rowsPerPageProps,
    onRowClick,
  } = props;

  const [displayData, setDisplayData] = useState([]);

  const [state, setState] = useState({
    isFirstLoad: true,
    order: "asc",
    orderBy: orderColumn,
    page: 0,
    rowsPerPage: noPagination ? data.length : rowsPerPageProps || 10,
  });

  const { order, orderBy, rowsPerPage, page } = state;

  useEffect(() => {
    const sortedData = noPagination
      ? data.sort(getSorting(order, orderBy))
      : data
          .sort(getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    setDisplayData(sortedData);
  }, [order, orderBy, noPagination, data]);

  /*
  Set empty rows = 0 if and only if (iff):
  1. forced no pagination => noPagination = true
  or
  2. forced no empty rows => noEmptyRows = true
  */
  const emptyRows =
    noPagination || noEmptyRows
      ? 0
      : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const orderByVal = property;
    let orderVal = "desc";

    if (orderBy === property && order === "desc") {
      orderVal = "asc";
    }

    setState({ ...state, order: orderVal, orderBy: orderByVal });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setState({
        ...state,
        selected: data.map((n) => n.id),
      });
      return;
    }
    setState({ ...state, selected: [] });
  };

  const handleClick = (columnId) => () => {
    if (onRowClick) {
      onRowClick(columnId);
    }
  };

  const handleChangePage = (pageVal) => {
    setState({ ...state, page: Number(pageVal) });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: Number(event.target.value), page: 0 });
  };

  const { containerStyle = "" } = classes;

  return (
    <TableContainer className={containerStyle}>
      <MuiTable aria-labelledby="tableTitle">
        {!noHeader && (
          <TableHead
            data={{
              columnData,
              order,
              orderBy,
              orderColumn,
              rowCount: data.length,
            }}
            func={{
              onRequestSort: handleRequestSort,
              onSelectAllClick: handleSelectAllClick,
            }}
          />
        )}
        <TableBody
          data={{
            columnData,
            displayData,
            emptyRows,
            tableData: data,
          }}
          func={{ handleClick }}
        />
        {!noPagination && (
          <TablePagination
            data={{
              columnData,
              page,
              rowsPerPage,
              rowsPerPageOptions: getRowsPerPageOptions(data.length),
              tableData: data,
            }}
            func={{
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
            }}
            {...paginationProps}
          />
        )}
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
