import React, { memo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 600,
        width: 800,
        minHeight: 200,
      },
    },
  },

  title: {
    color: "#D42600",
    fontSize: 14,
    fontWeight: 500,
  },
  content: {
    fontSize: 16,
  },
  pbNumber: {
    color: "#D42600",
  },
  reportSpacing: {
    marginTop: "24px",
  },
  contentSpacing: {
    marginTop: "4px",
  },
}));

const PbNotExistedModal = ({
  pbNumbers = [],
  reportPbNumbers = [],
  open,
  onClose,
  content = "Please remind Supplier to update the PB on this submission.",
  reportContent = "Please update lab report's PB on this submission.",
}) => {
  const classes = useStyles();

  const PbNumbers = ({ numbers }) => <span className={classes.pbNumber}>{numbers.join(", ")}</span>;

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle className={classes.title}>PB did not existed</DialogTitle>
      <DialogContent className={classes.content}>
        {pbNumbers.length > 0 && (
          <>
            "<PbNumbers numbers={pbNumbers} />" did not existed.{" "}
            <Box className={classes.contentSpacing}>{content}</Box>
          </>
        )}

        {reportPbNumbers.length > 0 && (
          <Box
            className={classnames({
              [classes.reportSpacing]: pbNumbers.length > 0,
            })}
          >
            "<PbNumbers numbers={reportPbNumbers} />" did not existed.{" "}
            <Box className={classes.contentSpacing}>{reportContent}</Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PbNotExistedModal);
