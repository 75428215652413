import React, { useEffect } from "react";
import _findIndex from "lodash/findIndex";
import classnames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs as MuiTabs, Toolbar } from "@material-ui/core";
import { getStringQueryValue } from "utils/router";
import history from "helpers/history";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index, tab) {
  return {
    "data-cy-title": tab.title,
    "data-cy": `tab-${tab.value}`,
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  appBar: {
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90.06deg, #2F82FF 0%, #2154A2 100%)",
    boxShadow: "inset 0px -1px 0px #AAAAAA",
  },
  toolbar: {
    minHeight: "unset",
  },
  toolbarNoPadding: {
    padding: 0,
  },
  tabsIndicator: {
    backgroundColor: "#005EFF",
    height: 3,
  },
  tabColor: {
    fontSize: 10,
    color: "#777777",
    textTransform: "capitalize",
  },
  tabSelected: {
    color: "#005EFF",
  },
  tabPanel: {
    paddingBottom: "24px",
  },
}));

const Tabs = ({ defaultTab = "", data, gridDisplay = false, taskType, assignTo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    // load default tab by tab params URL
    const tab = getStringQueryValue(history, "tab");
    const index = _findIndex(data, { value: tab });

    if (index >= 0 && index <= data?.length) {
      setValue(index);
    }
  }, [data]);

  useEffect(() => {
    if (!defaultTab || !data) return;

    const index = _findIndex(data, { value: defaultTab });

    setValue(index);
  }, [defaultTab, data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const myTabsStyle = { indicator: classes.tabsIndicator };
  const myTabStyle = { textColorInherit: classes.tabColor, selected: classes.tabSelected };

  let tempData = [];

  if (taskType === "SS") {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title !== "Quote") {
        tempData.push(data[i]);
      }
    }
  } else {
    tempData = data;
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar
          className={classnames(classes.toolbar, { [classes.toolbarNoPadding]: !gridDisplay })}
        >
          <MuiTabs classes={myTabsStyle} value={value} onChange={handleChange}>
            {tempData.map((tab, index) => (
              <Tab key={index} classes={myTabStyle} label={tab.title} {...a11yProps(index, tab)} />
            ))}
          </MuiTabs>
        </Toolbar>
      </AppBar>
      {tempData.map((tab, index) => (
        <TabPanel
          className={classes.tabPanel}
          key={index}
          value={value}
          index={index}
          dir={theme.direction}
        >
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
