import * as types from "state/submissionDetail/types";

const currentTaskDetailState = {
  reports: [],
  pendingReason: null,
  isProgram777: null,
  remark: null,
  wicNumber: "",
  lab: "",
  inspectionTaskDetails: {
    aqlMinor: "",
    inspectionDateFrom: null,
    totalQuantity: null,
    aqlCritical: "",
    inspectionLevel: "",
    id: null,
    expectedInspectionDate: "",
    inspectionDateTo: null,
    aqlMajor: "",
    expectedSamplePickUpDate: null,
  },
  sampleSealingResults: [],
  createdAt: "",
  capStatus: null,
  lastUpdated: "",
  taskFiles: [],
  taskType: "",
  icl: "",
  programName: "",
  labStatus: "",
  masterWicId: null,
  id: null,
  spuForEveryDayTaskType: null,
  isCancelled: null,
  firstShipmentTotalQuantity: null,
  selectedLabs: [],
  completedStatus: null,
  confirmedInspectionDate: null,
  pb: [],
  po: [],
  isRetest: "",
  fullPoTotalQuantity: null,
  e2open: [],
  expectedCompletionDate: null,
  taskNumber: "",
  category: "",
  assignTo: "",
  triggeredBy: "",
  status: "",
};

const initState = {
  showInvoiceTab: false, //for ss task with lab flow in supplier role
  openErrorModal: false,
  //lab update report with pb
  openLabReportUpdateModal: false,
  currentTaskStatus: "",
  currentTaskDetail: currentTaskDetailState,
  //supplier pb revision
  openPbRevisionModal: false,
  submission: null,
  oldTasks: null,
  tasks: null,
  error: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case types.NAVIGATE_SUBMISSION_INVOICE_TAB:
      return {
        ...state,
        showInvoiceTab: action.show,
      };

    case types.TOGGLE_PB_ERROR_MODAL:
      return {
        ...state,
        openErrorModal: action.open,
      };

    case types.TOGGLE_SUBMISSION_PB_REVISION_MODAL:
      if (action.payload.open) {
        return {
          ...state,
          openPbRevisionModal: action.payload.open,
          oldTasks: [...action.payload.tasks],
          tasks: [...action.payload.tasks],
        };
      } else {
        return {
          ...state,
          openPbRevisionModal: action.payload.open,
          tasks: [...state.oldTasks],
        };
      }

    case types.GET_SUBMISSION_TASKS_DATA:
      return {
        ...state,
        oldTasks: action.payload.tasks,
        tasks: action.payload.tasks,
        submission: action.payload.submission,
        pbNotExistedNumbers: action.payload.pbNotExistedNumbers,
      };

    case types.CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES:
      return {
        ...state,
        oldTasks: [...action.payload.tasks],
        tasks: [...action.payload.tasks],
      };

    case types.UPDATE_SUBMISSION_PB_WIC_DETAIL:
    case types.EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL:
    case types.EXPAND_SUBMISSION_PB_WIC_DETAIL:
      return {
        ...state,
        tasks: [...action.payload.tasks],
      };

    case types.TOGGLE_SUBMISSION_LAB_REPORT_MODAL:
      return {
        ...state,
        openLabReportUpdateModal: action.payload.open,
      };

    case types.GET_SUBMISSION_CURRENT_TASK_DETAIL:
      return {
        ...state,
        currentTaskDetail: action.payload.taskDetail,
      };

    case types.UPDATE_SUBMISSION_CURRENT_TASK_REPORT:
      return {
        ...state,
        currentTaskDetail: {
          ...state.currentTaskDetail,
          reports: action.payload.taskReports,
        },
      };

    case types.GET_SUBMISSION_TASK_STATUS:
      return {
        ...state,
        currentTaskStatus: action.payload.currentTaskStatus,
      };

    case types.TOGGLE_SUBMISSION_PB_REVISION_MODAL_FAILURE:
    case types.GET_SUBMISSION_TASKS_DATA_FAILURE:
    case types.CONVERT_SUBMISSION_PB_WIC_DETAIL_FILES_FAILURE:
    case types.UPDATE_SUBMISSION_PB_WIC_DETAIL_FAILURE:
    case types.EXPAND_SUBMISSION_PB_WIC_DETAIL_FAILURE:
    case types.EXPAND_ALL_SUBMISSION_PB_WIC_DETAIL_FAILURE:
    case types.TOGGLE_SUBMISSION_LAB_REPORT_MODAL_FAILURE:
    case types.GET_SUBMISSION_CURRENT_TASK_DETAIL_FAILURE:
    case types.UPDATE_SUBMISSION_CURRENT_TASK_REPORT_FAILURE:
    case types.GET_SUBMISSION_TASK_STATUS_FAILURE:
    case types.ERROR_SUBMISSION:
    case types.NAVIGATE_SUBMISSION_INVOICE_TAB_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
