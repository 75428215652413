import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "components/modal/Modal";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function NewPayerConfirmModal({
  title = "Alert of New Payer Information",
  text = "Please make sure you want to change now , new information or changing will not be saved.",
  hideCloseIcon = false,
  open = false,
  onClose,
  onCancel,
  onSubmit,
  props,
}) {
  const classes = useStyles();

  return (
    <Modal
      {...props}
      hideCloseIcon={hideCloseIcon}
      title={title}
      open={open}
      handleClose={onClose}
      className={{ classRoot: classes.discardPop, classContent: classes.popPayerInform }}
    >
      <Box display="flex">
        <Box className={classes.textContent}>
          <Box display="block">{text}</Box>
        </Box>
      </Box>

      <Box display="block" className={classes.fourBox} marginTop="40px">
        <Button color="primary" onClick={onCancel}>
          CANCEL
        </Button>

        <Button variant="contained" color="primary" className={classes.applyBtn} onClick={onSubmit}>
          CONFIRM
        </Button>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  discardPop: {
    "& hr": {
      background: "#003DA5",
    },
  },
  popPayerInform: {
    color: "#D42600",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "16px",
  },
  textContent: {
    color: "black",
    fontSize: 14,
    fontWeight: "initial",
  },
  applyBtn: {
    marginLeft: 25,
  },
  fourBox: {
    float: "right",
  },
}));
