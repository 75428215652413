import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import UploadSingleFile from "components/input/UploadSingleFile";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  info: {
    paddingTop: 5,
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  },
  uploadContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const UploadCAP = ({ openTaskData = {}, handleAddCAPFile, handleNextPage }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Upload CAP</Box>

        <Box className={classes.info}>
          Please upload CAP in order to perform retest for the failed task.
        </Box>

        <Box className={classes.uploadContainer}>
          <UploadSingleFile file={openTaskData.capFile} handleAddFile={handleAddCAPFile} />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={!openTaskData.capFile || !openTaskData.capFile.id}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default UploadCAP;
