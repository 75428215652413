import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _find from "lodash/find";
import _isEqual from "lodash/isEqual";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  atnGetBrands,
  atnGetBuyerCompanyTeamsAndPrograms,
  atnGetCountries,
} from "actions/commonActions";

import { atnDownloadFile } from "actions/fileActions";

import {
  atnEditItem,
  atnEditTasks,
  atnGetMasterWicDetails,
  atnGetSuppliersAndFactories,
} from "actions/itemActions";

import {
  EVERYDAY,
  ITEM_CATEGORY,
  ITEM_SAMPLE_DATA,
  NA,
  PROGRAM_TYPES,
  SEASONAL,
  SUPPLIER,
} from "shared/constants";
import { getFileSourceUrl } from "utils/files";

import Header from "../components/Header";
import ItemDetails from "../components/ItemDetails";
import SupplierFactoryDetails from "../components/SupplierFactoryDetails";
import MasterWICDetails from "../components/MasterWICDetails";
import SubWICDetails from "../components/SubWICDetails";
import TaskAssignment from "./TaskAssignment";
import ValidityDetails from "../components/ValidityDetails";

import { getBuyerCompanyTeams, getProgramYears } from "services/buyerItemService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  title: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const EditItem = ({ match, history }) => {
  const itemId = match.params.id;

  const { t } = useTranslation();
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(false);

  const defaultTaskList = ITEM_SAMPLE_DATA.tasks.map((task) => ({
    taskId: null,
    ...task,
  }));

  const [originalData, setOriginalData] = useState({
    ...ITEM_SAMPLE_DATA,
    tasks: defaultTaskList,
  });
  const [originalTaskTypes, setOriginalTaskTypes] = useState([]);
  const [editItemData, setEditItemData] = useState({
    id: itemId,
    ...ITEM_SAMPLE_DATA,
    tasks: defaultTaskList,
  });

  const [buyerCompanyTeams, setBuyerCompanyTeams] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programYears, setProgramYears] = useState([]);
  const [exportMarkets, setExportMarkets] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [factories, setFactories] = useState([]);

  const [isEverydayProgram, setIsEverydayProgram] = useState(false);

  const stepsData = [
    { step: 0, header: "Item Details", title: "Item Details" },
    {
      step: 1,
      header: "Supplier & Factory Details",
      title: "Supplier & Factory Details",
    },
    { step: 2, header: "Master WIC Details", title: "Master WIC Details" },
    { step: 3, header: "Sub WIC Details", title: "Sub WIC Details" },
    { step: 4, header: "Task Assignment", title: "Select Task for Item" },
    {
      step: 5,
      header: "Validity Date & Period",
      title: "Compliance Validity Details",
    },
  ];

  useEffect(() => {
    getMasterWicDetails();
    getBrands();
    getExportMarkets();
    fetchProgramYears();
  }, []);

  const getBrands = () => {
    atnGetBrands().then(({ data }) => {
      const brandData = data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      setBrands(_orderBy(brandData, "label", "asc"));
    });
  };

  const getBuyerCompanyTeamsAndPrograms = (buyerTeamId) => {
    atnGetBuyerCompanyTeamsAndPrograms().then(({ data }) => {
      const buyerCompanyTeams = getBuyerCompanyTeams(data);

      if (buyerTeamId) {
        for (let i = 0; i < buyerCompanyTeams.length; i++) {
          if (buyerCompanyTeams[i].value === buyerTeamId) {
            setPrograms(buyerCompanyTeams[i].programs);

            break;
          }
        }
      }

      setBuyerCompanyTeams(_orderBy(buyerCompanyTeams, "label", "asc"));
    });
  };

  const fetchProgramYears = () => {
    setProgramYears(getProgramYears());
  };

  const getExportMarkets = () => {
    atnGetCountries().then(({ data }) => {
      const exportMarketData = data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      setExportMarkets(_orderBy(exportMarketData, "label", "asc"));
    });
  };

  const getSuppliersAndFactories = (supplierId) => {
    atnGetSuppliersAndFactories().then(({ data }) => {
      let supplierData = [];
      let factoryData = [];
      let selectedSupplier = {};

      for (let i = 0; i < data.length; i++) {
        factoryData = [];

        for (let j = 0; j < data[i].supplierFactories.length; j++) {
          factoryData.push({
            supplierFactoryId: data[i].supplierFactories[j].id,
            id: data[i].supplierFactories[j].factory.id,
            name: data[i].supplierFactories[j].factory.name,
            address: data[i].supplierFactories[j].factory.address1
              ? data[i].supplierFactories[j].factory.address1
              : data[i].supplierFactories[j].factory.address2
              ? data[i].supplierFactories[j].factory.address2
              : NA,
            code: data[i].supplierFactories[j].factory.factoryCode,
          });
        }

        supplierData.push({
          id: data[i].id,
          supplierCompanyName: data[i].name,
          contactPerson: data[i].contactPerson,
          supplierEmail: data[i].contactEmail,
          supplierID: data[i].supplierCode,
          factories: factoryData,
        });

        if (data[i].id === supplierId) {
          selectedSupplier = {
            id: data[i].id,
            supplierCompanyName: data[i].name,
            contactPerson: data[i].contactPerson,
            supplierEmail: data[i].contactEmail,
            supplierID: data[i].supplierCode,
            factories: factoryData,
          };

          setFactories(factoryData);
        }
      }

      setEditItemData((prevState) => ({
        ...prevState,
        supplier: selectedSupplier,
      }));
      setOriginalData((prevState) => ({
        ...prevState,
        supplier: selectedSupplier,
      }));

      setSuppliers(supplierData);
    });
  };

  const getMasterWicDetails = () => {
    atnGetMasterWicDetails({
      itemId: itemId,
    }).then(({ data, message }) => {
      if (data) {
        setIsEverydayProgram(data.masterWic.category === EVERYDAY);

        const downloadImage = (location) => {
          return new Promise((resolve, reject) => {
            atnDownloadFile(location).then(async (res) => {
              if (res) {
                resolve(getFileSourceUrl(res));
              } else {
                reject();
              }
            });
          });
        };

        let images = data.masterWicImages;
        let downloadImageFunctionArray = [];

        for (let i = 0; i < images.length; i++) {
          downloadImageFunctionArray.push(downloadImage(images[i].location));
        }

        Promise.all(downloadImageFunctionArray).then((result) => {
          for (let i = 0; i < result.length; i++) {
            images[i].preview = result[i];
          }
        });

        //subwics data massage
        let subWics = data.subWics;

        for (let i = 0; i < subWics.length; i++) {
          subWics[i].tempId = subWics[i].id;
          subWics[i].productCategory = subWics[i].childOrNot ? "childrenProduct" : "generalUse";
          subWics[i].walkthroughComments = subWics[i].walkThroughComments;
          subWics[i].isSelected = false;
        }

        //task data massage
        let tasks = data.tasks;

        let tempTasks = editItemData.tasks.filter(
          (task) =>
            task.category ===
            (_isEqual(data.buyerTeam, PROGRAM_TYPES.walgreensEveryday.value) ? EVERYDAY : SEASONAL),
        );

        let tempTaskTypes = [];

        for (let i = 0; i < tasks.length; i++) {
          let taskSubWicData = subWics;

          if (tempTaskTypes.indexOf(tasks[i].taskType) === -1) {
            tempTaskTypes.push(tasks[i].taskType);
          }

          for (let j = 0; j < tasks[i].subWics.length; j++) {
            for (let k = 0; k < taskSubWicData.length; k++) {
              const isMatchSubWicId = taskSubWicData[k].id === tasks[i].subWics[j].id;

              if (isMatchSubWicId) {
                taskSubWicData[k].isSelected = true;
                break;
              }
            }
          }

          tasks[i].subWics = taskSubWicData;
        }

        setOriginalTaskTypes(tempTaskTypes);

        let hasTask = false;

        for (let i = 0; i < tempTasks.length; i++) {
          // const curTempTask = tempTasks[i];
          tempTasks[i].isSelected = false; // reset all task as not selected when using edit mode
          hasTask = false;

          for (let j = 0; j < tasks.length; j++) {
            const curTask = tasks[j];

            if (curTask.taskType === tempTasks[i].taskType) {
              tempTasks[i].taskRequirementId = curTask.taskRequirementId;
              hasTask = true;

              let isSelected = false;

              if (tempTasks[i].category === "everyday") {
                isSelected = true;
              } else {
                isSelected = !!curTask.taskId;
              }

              let tempSubWICs = JSON.parse(JSON.stringify(curTask.subWics));

              tempTasks[i].isSelected = isSelected;
              tempTasks[i].subWics = tempSubWICs;
              tempTasks[i].taskId = curTask.taskId;
              tempTasks[i].handledBy = curTask.assignTo;
              tempTasks[i].wbaDeadline = curTask.wbaDeadline;
              tempTasks[i].remarks = curTask.remark;
              break;
            } else {
              tempTasks[i].taskRequirementId = null;
              if (!tempTasks[i].wbaDeadline) {
                tempTasks[i].wbaDeadline = data.shipByDates[0].shipByDate
                  ? moment(data.shipByDates[0].shipByDate).subtract(tempTasks[i].days, "days")
                  : "";
              }
            }
          }

          if (!hasTask) {
            tempTasks.subWics = subWics;
          }
        }

        data.tasks = tempTasks;

        setEditItemData({ masterWicImages: images, subWics: subWics, ...data });
        let tempData = JSON.parse(JSON.stringify(data));
        setOriginalData({
          masterWicImages: images,
          subWics: subWics,
          ...tempData,
        });

        getBuyerCompanyTeamsAndPrograms(data.buyerTeam);
        getSuppliersAndFactories(data.supplier.id);
      }
    });
  };

  const handlePrevPage = () => {
    if (!step) return;

    setStep(step - 1);
  };

  const handleConfirmSubWIC = () => {
    let tasks = editItemData.tasks;
    let tempSubWICs = [];
    let unselectedSubWICs = [];

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].subWics.length === 0) {
        tasks[i].subWics = JSON.parse(JSON.stringify(editItemData.subWics));
      } else {
        tempSubWICs = JSON.parse(JSON.stringify(editItemData.subWics));
        unselectedSubWICs = [];

        for (let j = 0; j < tasks[i].subWics.length; j++) {
          if (!tasks[i].subWics[j].isSelected) {
            unselectedSubWICs.push(tasks[i].subWics[j].tempId);
          }
        }

        for (let j = 0; j < tempSubWICs.length; j++) {
          if (unselectedSubWICs.indexOf(tempSubWICs[j].tempId) >= 0) {
            tempSubWICs[j].isSelected = false;
          }
        }

        tasks[i].subWics = tempSubWICs;
      }
    }

    setEditItemData((prevState) => ({
      ...prevState,
      tasks: tasks,
    }));

    setStep(step + 1);
  };

  const handleNextPage = () => {
    if (step < (isEverydayProgram ? 5 : 4)) {
      setStep(step + 1);
    }
  };

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleSubmit = async () => {
    setIsDisabledConfirmButton(true);

    let subWicData = editItemData.subWics.map((subWic) => ({
      // const tempSubWic = {
      productName: subWic.productName,
      wicNumber: subWic.wicNumber,
      ageGrade: subWic.ageGrade,
      isFoodContact: subWic.isFoodContact,
      childOrNot: subWic.productCategory === "childrenProduct",
      walkThroughComments: subWic.walkthroughComments,
      assortments: subWic.assortments,
      // };

      // if (subWic.id) {
      //   tempSubWic.id = subWic.id;
      // }

      // return tempSubWic;
    }));

    const exportMarketData = editItemData.exportMarkets.map((m) => ({
      id: m.value,
    }));

    let supplierFactoryData = {};

    if (editItemData.supplierFactory.isNewAdd) {
      supplierFactoryData = {
        supplierCompany: {
          id: editItemData.supplier.id,
        },
        factory: {
          factoryCode: editItemData.supplierFactory.code,
          name: editItemData.supplierFactory.name,
          address1: editItemData.supplierFactory.address,
        },
      };
    } else {
      supplierFactoryData = {
        id: editItemData.supplierFactory.supplierFactoryId,
      };
    }

    const masterWicFiles = editItemData.masterWicImages.map((image) => ({
      id: image.id,
      type: "product_image",
    }));

    let hasTasks = false;

    for (let i = 0; i < editItemData.tasks.length; i++) {
      if (editItemData.tasks[i].isSelected) {
        hasTasks = true;
        break;
      }
    }

    const taskList = [];

    for (const task of editItemData.tasks) {
      // Everyday
      let taskData = {
        taskType: task.taskType,
        deadLine: task.wbaDeadline ? moment(task.wbaDeadline).format("YYYY-MM-DD") : "",
        remark: task.remarks,
        id: isEverydayProgram ? task.taskRequirementId || null : task.taskId || null,
      };

      // Seasonal
      const seasonalData = {
        ...taskData,
        assignTo: task.handledBy || "lab",
        po: editItemData.shipByDates,
        shipByDate: editItemData.shipByDates[0].shipByDate,
        subWics: task.subWics
          .filter((subwic) => subwic.isSelected)
          .map((sorted) => ({
            wicNumber: sorted.wicNumber,
            productName: sorted.productName,
          })),
      };

      if (!isEverydayProgram) {
        taskData = { ...taskData, ...seasonalData };
      }

      if (task.isSelected) {
        taskList.push(taskData);
      }
      // else if (originalTaskTypes.indexOf(task.taskType) >= 0) {
      //   taskList.push({ ...taskData, isCancelled: true });
      // }
    }

    let itemData = {
      id: itemId,
      productName: editItemData.masterWic.productName,
      wicNumber: editItemData.masterWic.wicNumber,
      programYear: editItemData.programYear,
      category: isEverydayProgram ? ITEM_CATEGORY.everyday.value : ITEM_CATEGORY.seasonal.value,
      pacValidityDate: editItemData.masterWic.pacValidityDate,
      validityType: editItemData.masterWic.validityType,
      validityDate: editItemData.masterWic.validityDate,
      validityPeriod: editItemData.masterWic.validityPeriod,
      selfInspectionValidityDate: editItemData.masterWic.selfInspectionValidityDate,
      // po: editItemData.shipByDates,
      masterWicFiles: masterWicFiles,
      subWics: subWicData,
      buyerCompanyTeamProgram: {
        id: editItemData.program,
      },
      brand: {
        id: editItemData.brand,
      },
      exportMarkets: exportMarketData,
      supplierFactory: supplierFactoryData,
      icl: editItemData.icl,
      shipByDate: isEverydayProgram ? null : editItemData.shipByDates[0].shipByDate,
      hasTasks,
      tasks: taskList,
    };

    const changes = [];

    if (originalData.buyerTeam !== editItemData.buyerTeam) {
      let oldBuyerTeam = "";
      let newBuyerTeam = "";

      for (const team of buyerCompanyTeams) {
        if (team.value === originalData.buyerTeam) {
          oldBuyerTeam = team.label;
          if (newBuyerTeam) {
            break;
          }
        }

        if (team.value === editItemData.buyerTeam) {
          newBuyerTeam = team.label;
          if (oldBuyerTeam) {
            break;
          }
        }
      }

      changes.push({
        field: "Buyer Team",
        action: "Update",
        before: oldBuyerTeam,
        after: newBuyerTeam,
      });
    }

    if (originalData.program !== editItemData.program) {
      let oldProgram = "";
      let newProgram = "";

      for (const program of programs) {
        if (program.value === originalData.program) {
          oldProgram = program.label;
          if (newProgram) {
            break;
          }
        }

        if (program.value === editItemData.program) {
          newProgram = program.label;
          if (oldProgram) {
            break;
          }
        }
      }

      changes.push({
        field: "Program",
        action: "Update",
        before: oldProgram,
        after: newProgram,
      });
    }

    if (originalData.programYear.toString() !== editItemData.programYear.toString()) {
      changes.push({
        field: "Program Year",
        action: "Update",
        before: originalData.programYear,
        after: editItemData.programYear,
      });
    }

    if (originalData.shipByDates[0].shipByDate !== editItemData.shipByDates[0].shipByDate) {
      changes.push({
        field: "Ship By Date",
        action: "Update",
        before: originalData.shipByDates[0].shipByDate,
        after: editItemData.shipByDates[0].shipByDate,
      });
    }

    const oldExportMarks = originalData.exportMarkets.map((m) => m.label);
    const newExportMarks = editItemData.exportMarkets.map((m) => m.label);

    if (oldExportMarks.sort().toString() !== newExportMarks.sort().toString()) {
      changes.push({
        field: "Export Marks",
        action: "Update",
        before: oldExportMarks.sort().toString(),
        after: newExportMarks.sort().toString(),
      });
    }

    if (originalData.brand !== editItemData.brand) {
      let oldBrand = "";
      let newBrand = "";

      for (let i = 0; i < brands.length; i++) {
        if (brands[i].value === originalData.brand) {
          oldBrand = brands[i].label;
          if (newBrand) {
            break;
          }
        }

        if (brands[i].value === editItemData.brand) {
          newBrand = brands[i].label;
          if (oldBrand) {
            break;
          }
        }
      }

      changes.push({
        field: "Brand",
        action: "Update",
        before: oldBrand,
        after: newBrand,
      });
    }

    if (originalData.supplier.id !== editItemData.supplier.id) {
      changes.push({
        field: "Supplier",
        action: "Update",
        before: originalData.supplier.supplierCompanyName,
        after: editItemData.supplier.supplierCompanyName,
      });
    }

    if (originalData.supplierFactory.id !== editItemData.supplierFactory.id) {
      changes.push({
        field: "Factory",
        action: "Update",
        before: originalData.supplierFactory.name || "",
        after: editItemData.supplierFactory.name || "",
      });
    }

    if (originalData.masterWic.wicNumber !== editItemData.masterWic.wicNumber) {
      changes.push({
        field: "Master WIC Number",
        action: "Update",
        before: originalData.masterWic.wicNumber || "",
        after: editItemData.masterWic.wicNumber || "",
      });
    }

    if (originalData.masterWic.productName !== editItemData.masterWic.productName) {
      changes.push({
        field: "Master WIC Name & Description",
        action: "Update",
        before: originalData.masterWic.productName || "",
        after: editItemData.masterWic.productName || "",
      });
    }

    const oldMasterWicImages = originalData.masterWicImages.map((image) => image.fileName);
    let hasNewImage = false;
    const newMasterWicImages = editItemData.masterWicImages.map((image) => {
      if (!image.location) {
        hasNewImage = true;
        return image.name;
      }

      return image.fileName;
    });

    if (
      hasNewImage ||
      originalData.masterWicImages.length !== editItemData.masterWicImages.length
    ) {
      changes.push({
        field: "Master WIC Images",
        action: "Update",
        before: oldMasterWicImages.sort().toString(),
        after: newMasterWicImages.sort().toString(),
      });
    }

    const subWicIds = [];
    let subWicBefore = "";
    let subWicAfter = "";
    let oldAssortments = [];
    let newAssortments = [];
    let hasNewAssortment = false;

    for (const subWic of editItemData.subWics) {
      if (subWic.id) {
        subWicIds.push(subWic.id);

        for (const originalSubWic of originalData.subWics) {
          if (originalSubWic.id === subWic.id) {
            subWicBefore = "";
            subWicAfter = "";

            if (originalSubWic.wicNumber !== subWic.wicNumber) {
              subWicBefore = "WIC Number: " + originalSubWic.wicNumber + "\n";
              subWicAfter = "WIC Number: " + subWic.wicNumber + "\n";
            }

            if (originalSubWic.productName !== subWic.productName) {
              subWicBefore += "Item Name: " + originalSubWic.productName + "\n";
              subWicAfter += "Item Name: " + subWic.productName + "\n";
            }

            oldAssortments = [];
            newAssortments = [];
            hasNewAssortment = false;

            for (let k = 0; k < originalSubWic.assortments.length; k++) {
              oldAssortments.push(originalSubWic.assortments[k].name);
            }

            for (let k = 0; k < subWic.assortments.length; k++) {
              if (!subWic.assortments[k].id) {
                hasNewAssortment = true;
              }
              newAssortments.push(subWic.assortments[k].name);
            }

            if (
              hasNewAssortment ||
              oldAssortments.sort().toString() !== newAssortments.sort().toString()
            ) {
              subWicBefore += "Assortments: " + oldAssortments.sort().toString() + "\n";
              subWicAfter += "Assortments: " + newAssortments.sort().toString() + "\n";
            }

            if (originalSubWic.ageGrade !== subWic.ageGrade) {
              subWicBefore += "Age Grade: " + originalSubWic.ageGrade + "\n";
              subWicAfter += "Age Grade: " + subWic.ageGrade + "\n";
            }

            if (originalSubWic.productCategory !== subWic.productCategory) {
              subWicBefore +=
                "Product Category: " +
                t(`productCategory.${originalSubWic.productCategory}`) +
                "\n";
              subWicAfter +=
                "Product Category: " + t(`productCategory.${subWic.productCategory}`) + "\n";
            }

            if (originalSubWic.isFoodContact !== subWic.isFoodContact) {
              subWicBefore +=
                "Food Contact: " + (originalSubWic.isFoodContact ? "Yes" : "No") + "\n";
              subWicAfter += "Food Contact: " + (subWic.isFoodContact ? "Yes" : "No") + "\n";
            }

            if (originalSubWic.walkthroughComments !== subWic.walkthroughComments) {
              subWicBefore += "Walkthrough Comments: " + originalSubWic.walkthroughComments + "\n";
              subWicAfter += "Walkthrough Comments: " + subWic.walkthroughComments + "\n";
            }

            if (subWicBefore !== "" || subWicAfter !== "") {
              changes.push({
                field: "Sub WIC",
                action: "Update",
                before: subWicBefore,
                after: subWicAfter,
              });
            }

            break;
          }
        }
      } else {
        changes.push({
          field: "Sub WIC",
          action: "Add",
          before: "",
          after: subWic.wicNumber,
        });
      }
    }

    for (const subWic of originalData.subWics) {
      if (subWicIds.indexOf(subWic.id) === -1) {
        changes.push({
          field: "Sub WIC",
          action: "Delete",
          before: "",
          after: "Delete " + subWic.wicNumber,
        });
      }
    }

    let oldTaskData = "";
    let newTaskData = "";

    for (const task of originalData.tasks) {
      if (task.isSelected) {
        oldTaskData += "Task Type: " + t(`task.${task.taskType}`) + "\n";
        oldTaskData += "Assign to: " + t(`user.${task.handledBy}`) + "\n";
        oldTaskData += "Remark: " + task.remarks;
      }
    }

    for (const task of editItemData.tasks) {
      if (task.isSelected) {
        newTaskData += "Task Type: " + t(`task.${task.taskType}`) + "\n";
        newTaskData += "Assign to: " + t(`user.${task.handledBy}`) + "\n";
        newTaskData += "Remark: " + task.remarks;
      }
    }

    if (oldTaskData !== newTaskData) {
      changes.push({
        field: "Task",
        action: "Update",
        before: oldTaskData,
        after: newTaskData,
      });
    }

    if (changes.length > 0) {
      itemData.changes = changes;
    }

    const res = await atnEditItem(itemData);

    if (!res || (res.code !== 200 && res.code !== 201)) {
      setIsDisabledConfirmButton(false);
      setOpenConfirmDialog(false);
      return;
    }

    setIsDisabledConfirmButton(false);
    setOpenConfirmDialog(false);
    history.push(`/buyer/items/${itemId}`);
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleEditItemDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "buyerTeam") {
      const getExportMarketDefault = () => {
        const isWalgreens = value === 1;
        const isBoots = value === 2;
        if (isWalgreens) {
          return _find(exportMarkets, { label: "United States" });
        } else if (isBoots) {
          return _find(exportMarkets, { label: "United Kingdom" });
        }

        return _find(exportMarkets, { label: "United States" });
      };

      setEditItemData((prevState) => ({
        ...prevState,
        [name]: value,
        program: "",
        programYear: "",
        exportMarkets: [getExportMarketDefault()],
        tasks: prevState.tasks.filter(
          (task) =>
            task.category ===
            (_isEqual(value, PROGRAM_TYPES.walgreensEveryday.value)
              ? ITEM_CATEGORY.everyday.value
              : ITEM_CATEGORY.seasonal.value),
        ),
      }));

      for (let i = 0; i < buyerCompanyTeams.length; i++) {
        if (buyerCompanyTeams[i].value === value) {
          setPrograms(buyerCompanyTeams[i].programs);
          break;
        }
      }
    } else if (name === "program") {
      setEditItemData((prevState) => ({
        ...prevState,
        [name]: value,
        programYear: _get(programYears, "0.value", ""),
      }));
    } else if (name === SUPPLIER) {
      for (let i = 0; i < suppliers.length; i++) {
        if (suppliers[i].id === value.id) {
          setFactories(value.factories);
          break;
        }
      }

      setEditItemData((prevState) => ({
        ...prevState,
        [name]: value,
        supplierFactory: {
          isNewAdd: false,
          id: "",
          name: "",
          address: "",
          code: "",
          contactPersonName: "",
          contactNumber: "",
          contactEmail: "",
        },
      }));
    } else if (
      [
        "pacValidityDate",
        "validityType",
        "validityDate",
        "validityPeriod",
        "selfInspectionValidityDate",
      ].includes(name)
    ) {
      setEditItemData((prevState) => ({
        ...prevState,
        masterWic: {
          ...prevState.masterWic,
          [name]: value,
        },
      }));
    } else {
      setEditItemData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddSubWIC = (subWIC) => {
    setEditItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.concat(subWIC),
    }));
  };

  const handleRemoveSubWIC = (index) => {
    setEditItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.filter((item, i) => i !== index),
    }));
  };

  const handleSubWICDataChange = (index, subWIC) => {
    setEditItemData((prevState) => ({
      ...prevState,
      subWics: prevState.subWics.map((item, i) => {
        if (i === index) {
          return subWIC;
        }

        return item;
      }),
    }));
  };

  const handleTaskSelect = (taskType) => {
    setEditItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item) => {
        if (item.taskType === taskType) {
          if (item.isSelected) {
            item.subWics = prevState.subWics;
            item.remarks = "";
          }
          item.isSelected = !item.isSelected;
        }

        return item;
      }),
    }));
  };

  const handleTaskDataChange = (e, index) => {
    const { name, value } = e.target;

    setEditItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const handleDeadlineChange = (e, index) => {
    setEditItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item, i) => {
        if (i === index) {
          item.wbaDeadline = e ? e._d : "";
        }

        return item;
      }),
    }));
  };

  const handleTaskSubWICChange = (taskType, subWICIndex) => {
    setEditItemData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((item) => {
        if (item.taskType === taskType) {
          item.subWics = item.subWics.map((subWIC, i) => {
            if (i === subWICIndex) {
              subWIC.isSelected = !subWIC.isSelected;
            }

            return subWIC;
          });
        }

        return item;
      }),
    }));
  };

  const handleShipByDateChange = (e, index) => {
    const value = e.target.value;

    let tasks = editItemData.tasks;

    for (let i = 0; i < tasks.length; i++) {
      tasks[i].wbaDeadline = value ? moment(value).subtract(tasks[i].days, "days")._d : "";
    }

    setEditItemData((prevState) => ({
      ...prevState,
      tasks: tasks,
      shipByDates: editItemData.shipByDates.map((item, i) => {
        if (i === index) {
          item.shipByDate = value;
        }

        return item;
      }),
    }));
  };

  const addNewShipByDate = () => {
    setEditItemData((prevState) => ({
      ...prevState,
      shipByDates: editItemData.shipByDates.concat({
        shipByDate: "",
      }),
    }));
  };

  const handleExportMarketsChange = (e) => {
    const { value } = e.target;

    const exportMarketsData = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < exportMarkets.length; j++) {
        if (value[i] === exportMarkets[j].value) {
          exportMarketsData.push(exportMarkets[j]);
        }
      }
    }

    setEditItemData((prevState) => ({
      ...prevState,
      exportMarkets: exportMarketsData,
    }));
  };

  return (
    <Box className={classes.root}>
      <Header
        title="Edit Item"
        data={{
          step,
          stepTotal: isEverydayProgram ? stepsData.length : stepsData.length - 1,
          headerText: _get(_find(stepsData, { step }), "header"),
        }}
        func={{ handlePrevPage, handleClose }}
      />

      <Box className={classes.content}>
        <Box className={classes.title}>{_get(_find(stepsData, { step }), "title")}</Box>

        {step === 0 && (
          <ItemDetails
            data={{
              itemData: editItemData,
              buyerCompanyTeams,
              programs,
              programYears,
              exportMarkets,
              brands,
              isEverydayProgram,
            }}
            itemId={itemId}
            func={{
              handleCreateItemDataChange: handleEditItemDataChange,
              handleShipByDateChange,
              addNewShipByDate,
              handleExportMarketsChange,
              handleNextPage,
            }}
          />
        )}

        {step === 1 && (
          <SupplierFactoryDetails
            data={{ itemData: editItemData, suppliers, factories }}
            func={{ onItemDataChange: handleEditItemDataChange, handleNextPage }}
          />
        )}

        {step === 2 && (
          <MasterWICDetails
            data={{ itemData: editItemData }}
            func={{ onItemDataChange: handleEditItemDataChange, handleNextPage }}
          />
        )}

        {step === 3 && (
          <SubWICDetails
            data={{ itemData: editItemData, isEverydayProgram }}
            func={{
              handleAddSubWIC,
              handleRemoveSubWIC,
              handleSubWICDataChange,
              handleNextPage: handleConfirmSubWIC,
            }}
          />
        )}

        {step === 4 && (
          <TaskAssignment
            data={{
              editItemData,
              openConfirmDialog,
              isEverydayProgram,
              taskList: editItemData.tasks,
            }}
            func={{
              handleTaskSelect,
              handleTaskDataChange,
              handleDeadlineChange,
              handleTaskSubWICChange,
              handleEditItemDataChange,
              handleNextPage,
              handleSubmit: handleConfirmDialogOpen,
            }}
          />
        )}

        {isEverydayProgram && step === 5 && (
          <ValidityDetails
            data={{ itemData: editItemData, openConfirmDialog }}
            func={{
              onItemDataChange: handleEditItemDataChange,
              handleSubmit: handleConfirmDialogOpen,
            }}
          />
        )}
      </Box>

      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose} disableBackdropClick>
        <DialogTitle>Confirm to submit?</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you confirm to submit?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary" variant="outlined">
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isDisabledConfirmButton}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(null, {
  atnGetBrands,
  atnGetBuyerCompanyTeamsAndPrograms,
  atnGetCountries,
  atnEditItem,
  atnEditTasks,
  atnGetSuppliersAndFactories,
})(EditItem);
