import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 250,
    maxWidth: 250,
    maxHeight: 350,
    overflow: "auto",
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 2,
    border: "1px solid #003DA5",
    boxShadow: "0px 2px 10px rgba(35, 43, 58, 0.25)",
  },
  itemContainer: {
    height: 40,
    cursor: "pointer",
  },
  searchField: {
    height: 32,
    width: "100%",
    marginBottom: 5,
    "& .MuiInputBase-root": {
      borderRadius: 0,
      background: "transparent",

      "& input": {
        fontSize: 12,
        padding: "10px 8px 8px 8px",
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: 12,
      transform: "translate(12px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(10px, -4px) scale(0.75) !important",
    },
  },
  emptyMessage: {
    height: 40,
    padding: 10,
    fontSize: 10,
    color: "#777777",
  },
  checkBox: {
    paddingTop: 7,

    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#2A77F6",
    },
  },
}));

const SearchableMultiSelect = ({
  filterName,
  filterItems = [],
  selectedFilter = {},
  handleFilterItemSelect,
}) => {
  const classes = useStyles();

  const [filteredOptions, setFilteredOptions] = useState(filterItems);

  const handleSearchItems = (e) => {
    const { value } = e.target;

    let filteredItems = [];

    for (let i = 0; i < filterItems.length; i++) {
      if (filterItems[i].label.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        filteredItems.push(filterItems[i]);
      }
    }

    setFilteredOptions(filteredItems);
  };

  return (
    <Paper className={classes.container}>
      <Box>
        <TextField
          label="Search"
          variant="filled"
          className={classes.searchField}
          onChange={handleSearchItems}
        />
      </Box>
      {filteredOptions.map((item) => (
        <Box
          key={item.value}
          className={classes.itemContainer}
          onClick={() => handleFilterItemSelect(filterName, item.value)}
        >
          <Checkbox
            className={classes.checkBox}
            checked={selectedFilter[filterName].indexOf(item.value) >= 0}
          />{" "}
          {item.label}
        </Box>
      ))}

      {filteredOptions.length === 0 && <Box className={classes.emptyMessage}>no result</Box>}
    </Paper>
  );
};

export default SearchableMultiSelect;
