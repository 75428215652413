import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Box from "@material-ui/core/Box";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Search from "components/input/Search";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    whiteSpace: "nowrap",
  },
  table: {
    position: "relative",
    zIndex: 995,
    marginTop: 10,
  },
  tableHeaderCell: {
    position: "relative",
    padding: "8px 10px",
    color: "#777777",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  tableCell: {
    height: 40,
    padding: "0 10px",
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #777777",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  checkBoxIcon: {
    color: "#B0D400",
  },
  checkBoxOutlineBlankIcon: {
    color: "#979797",
  },
  dialogHeader: {
    borderBottom: "1px solid #003DA5",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogMessage: {
    fontSize: 12,
  },
  dialogSearchContainer: {
    paddingTop: 10,
    width: 250,
  },
}));

const SelectPOTable = ({
  tasks = [],
  selectedPOIds = [],
  handlePOSelect,

  searchKeyword,
  handleSearchKeywordChange,

  handleCloseSelectPODialog,
  handleConfirmPOSelect,
}) => {
  const classes = useStyles();

  return (
    <>
      <MuiDialogTitle className={classes.dialogHeader}>
        <Typography className={classes.dialogTitle}>Select PO for Submission</Typography>
      </MuiDialogTitle>
      <MuiDialogContent>
        <Box className={classes.dialogMessage}>List shown below are PO of involved WIC Item.</Box>
        <Box className={classes.dialogSearchContainer}>
          <Search
            name="searchKeyword"
            value={searchKeyword}
            handleChange={handleSearchKeywordChange}
          />
        </Box>

        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} />
                <TableCell className={classes.tableHeaderCell}>PO Number</TableCell>
                <TableCell className={classes.tableHeaderCell}>PO Quantity</TableCell>
                <TableCell className={classes.tableHeaderCell}>Ship By Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, index) => (
                <TableRow
                  key={index}
                  className={classes.tableRow}
                  onClick={() => handlePOSelect(task)}
                >
                  <TableCell scope="row" align="center" className={classes.tableCell}>
                    {selectedPOIds.indexOf(task.id) >= 0 ? (
                      <CheckBoxIcon className={classes.checkBoxIcon} />
                    ) : (
                      <CheckBoxOutlineBlankIcon className={classes.checkBoxOutlineBlankIcon} />
                    )}
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>{task.poNumber}</Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>{task.poQuantity}</Box>
                  </TableCell>

                  <TableCell scope="row" align="left" className={classes.tableCell}>
                    <Box className={classes.tableText}>
                      {task.shipByDate ? moment(task.shipByDate).format("DD/MMM/YYYY") : "N/A"}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button onClick={handleCloseSelectPODialog} color="primary">
          CANCEL
        </Button>

        <Button onClick={handleConfirmPOSelect} color="primary" variant="contained">
          CONTINUE
        </Button>
      </MuiDialogActions>
    </>
  );
};

export default SelectPOTable;
