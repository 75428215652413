import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiChip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F6F6F6",
    borderRadius: 3,
    marginRight: theme.spacing(1),
    marginBottom: 3,
  },
  label: {
    fontSize: 10,
    color: "#777777",
  },
}));

const Chip = ({ label }) => {
  const classes = useStyles();

  const myStyle = { root: classes.root, label: classes.label };

  return <MuiChip label={label} classes={myStyle} />;
};

export default Chip;
