import React, { useEffect } from "react";
import _findIndex from "lodash/findIndex";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import auth from "auth/authenticator";
import Divider from "components/display/Divider";
import TaskStatus from "components/task/TaskStatus";
import { ITEM_CATEGORY, TASK_DATE_TYPE, USER_TYPE, VALIDITY_PERIODS } from "shared/constants";
import DateItem from "pages/common/DateItem";
import SelectItem from "pages/common/SelectItem";
import { atnUpdateTaskRequirement } from "actions/taskActions";
import TenderingChip from "../../../../components/display/TenderingChip";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    // transition: 'all .5s ease-in-out',

    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      // transform: 'scale(1.05)',
    },
  },
  itemActivated: {
    borderLeft: "4px solid #196EFF",
  },
  itemTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemStatus: {
    minWidth: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
    marginRight: "10px",
  },
  subText: {
    fontSize: 10,
    color: "#777777",
  },
  divider: {
    margin: `${theme.spacing(1)}px -${theme.spacing(1)}px`,
  },
}));

const TaskList = ({ data, func, indexTaskSelected, payerInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { list = [], userType, taskType, statusProject, category = "everyday" } = data;
  const { onChange, onUpdate } = func;

  const [activeItem, setActiveItem] = React.useState(0);

  React.useEffect(() => {
    if (!list.length) return;

    setActiveItem(_findIndex(list, { taskType }) || 0);
  }, [taskType]);

  useEffect(() => {
    if (indexTaskSelected > 0 && !payerInfo) {
      let index = indexTaskSelected;
      handleChange(list[index].taskRequirementId, list[index].taskStatus, index);
    }
  }, [indexTaskSelected]);

  const handleChange = (taskRequirementId, taskStatus, index) => {
    onChange({ taskRequirementId, taskStatus });
    setActiveItem(index);
  };

  const isBuyerSourcing = auth.loginRole() === "buyer_sourcing";
  const isSupplier = auth.loginRole() === "supplier";

  const Item = ({
    taskType,
    taskStatus: status,
    lab,
    wbaDeadline,
    plannedDate,
    isCancelled,
    assignTo,
    taskRequirementId,
    index,
    pacValidityDate,
    selfInspectionValidityDate,
    validityType = "QAA",
    validityDate,
    validityPeriod = 6,
    tenderingProgram,
  }) => {
    const isEverydayProgram = category.toLowerCase() === ITEM_CATEGORY.everyday.value;

    const handleSubmitValidityPeriod = (newValue) => {
      const params = { taskRequirementId, data: { validityPeriod: newValue } };
      atnUpdateTaskRequirement({ data: params, type: userType }).then((res) => {
        if (onUpdate) onUpdate();
      });
    };

    const isBuyerOnly = !isBuyerSourcing && userType === USER_TYPE.buyer;

    return (
      <Paper
        data-cy-task-type={taskType}
        data-cy-active={activeItem === index}
        data-cy-index={index}
        className={classnames(classes.itemWrapper, {
          [classes.itemActivated]: activeItem === index,
        })}
        onClick={() => handleChange(taskRequirementId, status, index)}
      >
        <Box className={classes.itemTitle}>
          <Box>
            <Typography className={classes.mainText}>{t(`task.${taskType}`)}</Typography>
            {tenderingProgram && tenderingProgram.assignedLab !== "" && (
              <TenderingChip detail={tenderingProgram} />
            )}
          </Box>

          <Box className={classes.itemStatus}>
            <TaskStatus
              value={status}
              className={{ root: classes.itemStatus, text: classes.subText }}
              withText
              withIcon
            />
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.itemDescription}>
          <Typography className={classes.subText}>{`LAB: ${lab || "N/A"}`}</Typography>

          {isEverydayProgram && (
            <>
              {taskType === "PAC" && (
                <DateItem
                  isEditable={isBuyerOnly}
                  value={pacValidityDate}
                  type={TASK_DATE_TYPE.pacValidityDate}
                  taskRequirementId={taskRequirementId}
                  userType={userType}
                  onUpdate={onUpdate}
                />
              )}

              {taskType === "FRID" && (
                <DateItem
                  isEditable={isBuyerOnly}
                  value={selfInspectionValidityDate}
                  type={TASK_DATE_TYPE.selfInspectionValidityDate}
                  taskRequirementId={taskRequirementId}
                  userType={userType}
                  onUpdate={onUpdate}
                />
              )}

              {taskType === "STD" && validityType === "GCC" && (
                <DateItem
                  isEditable={isBuyerOnly}
                  value={validityDate}
                  type={TASK_DATE_TYPE.gccValidityDate}
                  taskRequirementId={taskRequirementId}
                  userType={userType}
                  onUpdate={onUpdate}
                />
              )}

              {taskType === "STD" && validityType === "QAA" && (
                <DateItem
                  isEditable={isBuyerOnly}
                  value={validityDate}
                  type={TASK_DATE_TYPE.qaaValidityDate}
                  taskRequirementId={taskRequirementId}
                  userType={userType}
                  onUpdate={onUpdate}
                />
              )}

              {taskType === "STD" && (
                <SelectItem
                  label={"Validity Period"}
                  value={validityPeriod}
                  name={"validityPeriod"}
                  options={VALIDITY_PERIODS}
                  isEditable={isBuyerOnly}
                  onSubmit={handleSubmitValidityPeriod}
                />
              )}
            </>
          )}

          <DateItem
            value={wbaDeadline}
            isEditable={isBuyerOnly}
            type={TASK_DATE_TYPE.deadLine}
            taskRequirementId={taskRequirementId}
            userType={userType}
            onUpdate={onUpdate}
          />

          <DateItem
            value={plannedDate}
            isEditable={
              userType === USER_TYPE.supplier && !isCancelled && statusProject !== "not_acknowledge"
            }
            type={TASK_DATE_TYPE.plannedDate}
            taskRequirementId={taskRequirementId}
            userType={userType}
            onUpdate={onUpdate}
          />
        </Box>

        <Typography className={classes.subText}>{`Assign To: ${
          assignTo ? t(`user.${assignTo}`) : "TBC"
        }`}</Typography>
      </Paper>
    );
  };

  return (
    <Box>
      {list && list.length
        ? list.map(
            (item, index) =>
              (!isSupplier ||
                (isSupplier && item.taskStatus && item.taskStatus !== "empty_task")) && (
                <Item key={uuid()} {...item} index={index} />
              ),
          )
        : "No data"}
    </Box>
  );
};

export default TaskList;
