import React from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import UploadSingleFile from "components/input/UploadSingleFile";
import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";
import Textarea from "components/input/Textarea";

import { REPORT_RESULT_OPTIONS } from "shared/constants";

export default function EditReportModal({
  open = false,
  loading = false,
  report = {
    fileId: null,
    id: null,
    result: null,
    fileName: null,
    issueDate: null,
    reportTestItem: [],
    remark: null,
  },
  errors = {
    issueDate: false,
    testItemCount: false,
    testItemResult: false,
  },
  onClose,
  onUploadReport,
  onSelectTestItem,
  onChangeTestItem,
  onChange,
  onSubmit,
}) {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <MuiDialogTitle>
        <Typography className={classes.dialogTitle}>Upload Report</Typography>

        <Divider />
      </MuiDialogTitle>
      <MuiDialogContent>
        <Box className={classes.inputTitle}>1. Upload Report File</Box>

        <Box>
          <UploadSingleFile
            buttonLabel="UPLOAD SUPPORTING DOC"
            handleAddFile={onUploadReport}
            className={classes.uploadButton}
          />
        </Box>

        {report.id && (
          <Box>
            <Box className={classes.fileName}>{report?.fileName}</Box>

            <Divider className={classes.divider} />

            <Box className={classes.inputTitle}>2. Issue Date</Box>
            <Box className={classes.subtitle}>Issue Date</Box>

            <DatePicker
              name="issueDate"
              value={report.issueDate}
              handleChange={onChange}
              error={errors.issueDate}
              helperText={errors.issueDate ? "Please select a date" : null}
            />

            <Divider className={classes.divider} />

            <Box className={classes.inputTitle}>3. Test Results</Box>

            {report?.reportTestItem?.map((item, index) => (
              <Box key={item.itemName} display="flex" className={classes.testItemContainer}>
                <Box>
                  <Checkbox
                    checked={item.isSelected}
                    color="primary"
                    onChange={() => onSelectTestItem(index)}
                  />
                </Box>

                <Box className={classes.testItemName}>{item.itemName}</Box>

                <Box>
                  <Select
                    label="Select Result"
                    name="result"
                    value={item.result}
                    options={REPORT_RESULT_OPTIONS}
                    handleChange={(e) => onChangeTestItem(e, index)}
                    additionalClass={classes.selectField}
                  />
                </Box>
              </Box>
            ))}

            {errors.testItemResult && (
              <Box className={classnames(classes.text, classes.error)}>
                Please select Test Item result
              </Box>
            )}

            {errors.testItemCount && (
              <Box className={classnames(classes.text, classes.error)}>Test Item not found</Box>
            )}

            <Divider className={classes.divider} />

            <Box className={classes.inputTitle}>4. Remark</Box>
            <Box className={classes.subtitle}>Remark</Box>

            <Textarea
              rowsMin={5}
              name="remark"
              value={report.remark}
              onChange={onChange}
              className={classes.remark}
            />
          </Box>
        )}
      </MuiDialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" disabled={loading}>
          Cancel
        </Button>

        <Button onClick={onSubmit} color="primary" variant="contained" disabled={loading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  actionBtn: {
    cursor: "pointer",
    color: "#999999",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
        minHeight: 500,
      },
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
  },
  fileName: {
    paddingTop: 10,
    // paddingRight: 20,
    textDecoration: "underline",
    color: "#005EFF",
    fontSize: 12,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: "2px",
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  selectField: {
    width: 200,
  },
  testItemContainer: {
    paddingTop: 10,
  },
  testItemName: {
    width: 300,
    paddingTop: 10,
    overflowWrap: "breakWord",
  },
  remark: {
    width: "100%",
    fontFamily: "inherit",
  },
  reportNameContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  inputFormControl: {
    width: "100%",
  },
  pbSelectField: {
    maxWidth: 300,
    width: "100%",
  },
  error: {
    color: "#D42600",
  },
  errorSpacing: {
    marginTop: "4px",
  },
  datePickerMaxWidth: {
    maxWidth: "300px",
  },
}));
