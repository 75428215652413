import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";

import { atnGetUserList, atnGetUserToken } from "actions/adminActions";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  // contentContainer: {
  //   backgroundColor: "#F7F9FF",
  //   padding: 30,
  //   overflowX: "auto",
  // },
  tableContainer: {
    height: "80vh",
    width: "100%",
  },
}));

const SupplierList = () => {
  const classes = useStyles();

  const [headerData, setHeaderData] = useState([
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
  ]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    atnGetUserList({
      type: "suppliers",
    }).then(({ data, message }) => {
      if (data) {
        let dataSorted = data.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
        setRowData(dataSorted);
      }
    });
  }, []);

  const handleRowClick = (param) => {
    atnGetUserToken({
      userId: param.row.userId,
    }).then(({ data, message }) => {
      if (data) {
        window.open(`/hackAccount/${data.role}/${data.token}/${data.useremail}`);
      }
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.tableContainer}>
          <DataGrid
            pageSize={50}
            rowsPerPageOptions={[50, 100, 200, 400, 600, 1000]}
            pagination
            rows={rowData}
            columns={headerData}
            onRowClick={(param) => handleRowClick(param)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierList;
