import React, { memo } from "react";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { textOrUnknown } from "utils/string";

import Program777Chip from "components/display/Program777Chip";
import ResumeChip from "components/display/ResumeChip";

import Link from "components/display/Link";

import TaskHeaderStatus from "components/submission/TaskHeaderStatus";

import TenderingChip from "components/display/TenderingChip";

import { isShowProgram777 } from "services/pbService";

import { RESUME_TYPE, TASK_TYPE_ENUM } from "shared/constants";
import { getCategory } from "services/categoryService";
import NBChip from "../../../components/display/NBChip";

const TaskDetailHeader = ({
  wicNumber,
  wicNoUrl,
  labStatus,
  taskType,
  programName,
  programYear,
  isTenderingProgram,
  tenderingProgram,
  category,
  status,
  isNb,
  completedStatus,
  triggeredBy = "",
  isResumeReport = false,
  remark,
  inspectionTaskDetails,
  expectedCompletionDate,
  confirmedInspectionDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const showProgram777 = isShowProgram777(triggeredBy);

  const { isEveryday } = getCategory(category);

  const WICNoLink = () =>
    wicNoUrl ? (
      <Link path={wicNoUrl}>
        <Box display="flex">
          {showProgram777 && <Program777Chip />}
          {wicNumber}
        </Box>
      </Link>
    ) : (
      <Box display="flex">
        {showProgram777 && <Program777Chip />}
        {textOrUnknown(wicNumber)}
      </Box>
    );

  const ConfirmedDate = ({ title = "", date }) => (
    <>
      <Box>{title}</Box>
      <Box>{date ? moment(date).format("DD/MMM/YYYY") : "TBC"}</Box>
    </>
  );

  return (
    <Box className={classes.header}>
      <Box className={classes.left}>
        <Box display="flex">
          <Typography className={classes.title}>
            <WICNoLink /> • {t(`task.${taskType}`)} {remark && remark !== "" && `(${remark})`}
          </Typography>
          {isNb && <NBChip />}
        </Box>
        <Typography className={classnames(classes.subTitle, classes.program)}>
          <Box className={classes.subtitleSpacing}>Program: </Box>{" "}
          <Box display="flex" className={classes.alignCetner}>
            {isTenderingProgram && (
              <>
                {" "}
                <TenderingChip detail={tenderingProgram} />
              </>
            )}
            {programName} {isEveryday ? "" : programYear}{" "}
          </Box>
        </Typography>

        <Box className={classes.flexRow}>
          <TaskHeaderStatus
            className={classes.statusSpacing}
            labStatus={labStatus}
            status={status}
          />

          {/* label resume */}
          {isResumeReport && <ResumeChip label={RESUME_TYPE.REPORT} />}
        </Box>
      </Box>
      <Box className={classes.right}>
        {[TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID].includes(taskType) && (
          <ConfirmedDate title="Confirmed Inspection Date" date={confirmedInspectionDate} />
        )}
        {[TASK_TYPE_ENUM.SPU, TASK_TYPE_ENUM.SPUD].includes(taskType) && (
          <ConfirmedDate title="Confirmed Sample Pick Up Date" date={confirmedInspectionDate} />
        )}
        {[
          TASK_TYPE_ENUM.QT,
          TASK_TYPE_ENUM.PST,
          TASK_TYPE_ENUM.ST,
          TASK_TYPE_ENUM.STD,
          TASK_TYPE_ENUM.PAC,
        ].includes(taskType) && (
          <ConfirmedDate title="Confirmed Completion Date" date={expectedCompletionDate} />
        )}
      </Box>
    </Box>
  );
};

export default memo(TaskDetailHeader);

const useStyles = makeStyles(() => ({
  subtitleSpacing: {
    marginRight: "2px",
  },
  alignCetner: {
    alignItems: "center",
  },
  program: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  header: {
    // display: 'flex',
    // flexDirection: 'column',
    // color: '#777777',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  subTitle: {
    fontSize: 12,
  },
  title2: {
    fontSize: 12,
    fontWeight: 500,
  },
  text: {
    fontSize: 10,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    color: "#777777",
  },
  right: {
    textAlign: "right",
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellNoBorderLeftRight: {
    borderLeft: "none",
    borderRight: "none",
  },
  tableCellNoBorderTop: {
    borderTop: "none",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  tableCellNoPaddingRight: {
    paddingRight: 0,
  },
  leftButton: {
    paddingRight: 20,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  statusSpacing: {
    marginRight: "8px",
  },
}));
