/* eslint-disable react-hooks/rules-of-hooks */
import { getCategory } from "services/categoryService";
import {
  LAB,
  SUPPLIER,
  TASK_STATUS_ENUM,
  TASK_TYPE,
  TASK_TYPE_ENUM,
  TASK_TYPE_EVERYDAY,
} from "shared/constants";
import useUserType from "hooks/useUserType";

export const toTaskTypeList = (taskList = []) => taskList?.map((item) => item?.taskType);

export const getOpenTaskTypeList = (userType, category, existingTaskTypeList = [], tasks = []) => {
  const { isSupplier, isBuyer } = useUserType(userType);

  const { isEveryday, isSeasonal } = getCategory(category);

  const defaultTaskOptions = isEveryday ? TASK_TYPE_EVERYDAY : TASK_TYPE;

  // supplier can only add existing task & plus SPUD in everyday, buyer can add any task with below
  const hasSPUDInExistingTaskTypes = existingTaskTypeList?.some(
    (type) => type === TASK_TYPE_ENUM.SPUD,
  );

  const supplierDefaultTaskTypes =
    isEveryday && !hasSPUDInExistingTaskTypes
      ? [TASK_TYPE_ENUM.SPUD, ...existingTaskTypeList]
      : existingTaskTypeList;

  const filteredTaskTypeList = isSupplier
    ? defaultTaskOptions?.filter((item) => supplierDefaultTaskTypes.includes(item?.value))
    : defaultTaskOptions;

  // return available task to create
  return filteredTaskTypeList?.reduce((acc, cur) => {
    // check if is empty task
    const existingEmptyTaskTypes = tasks
      ?.filter((task) => !task?.taskStatus || task?.taskStatus === TASK_STATUS_ENUM.EMPTY_TASK)
      ?.map((task) => task?.taskType);

    // check if has SS in existing tasks
    const hasExistingSS = tasks?.some((task) => task?.taskType === TASK_TYPE_ENUM.SS);

    // check current looping task type
    const isTaskTypeSS = TASK_TYPE_ENUM.SS === cur.value;
    const isTaskTypeST = TASK_TYPE_ENUM.ST === cur.value;
    const isTaskTypeSPUD = TASK_TYPE_ENUM.SPUD === cur.value;

    // buyer checking:
    // 1. everyday -> can add any task
    // 2. seasonal -> can add any task & SS (lab & wba flow) can add if not exist
    const buyerChecking =
      isBuyer &&
      (isEveryday ||
        (isSeasonal && hasExistingSS && !isTaskTypeSS) ||
        (isSeasonal && !hasExistingSS));

    // supplier checking:
    // 1. everyday -> can add any task already in filteredTaskTypeList (opt out empty task, except SPUD in everyday)
    // 2. seasonal -> can add any task except SS & ST
    const supplierChecking =
      isSupplier &&
      ((isEveryday && isTaskTypeSPUD) ||
        (isEveryday && !existingEmptyTaskTypes.includes(cur?.value)) ||
        (isSeasonal && !isTaskTypeST && !isTaskTypeSS));

    if (supplierChecking || buyerChecking) {
      acc.push(cur);
    }

    return acc;
  }, []);
};

export const getTaskAssignFlow = (userType, category, initialTaskTypes = [], selectedTaskType) => {
  const { isBuyer, isSupplier } = useUserType(userType);

  const { isEveryday, isSeasonal } = getCategory(category);

  const state = {
    canLabFlow: false,
    canSupplierFlow: false,
    canWbaFlow: false,
  };

  // if not DPI / FRI / SS, return default state
  if (
    ![TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI, TASK_TYPE_ENUM.FRID, TASK_TYPE_ENUM.SS].includes(
      selectedTaskType,
    )
  )
    return state;

  // buyer checking:
  // 1. everyday -> FRI can add both supplier / lab flow
  // 2. seasonal ->
  //   2a. SS can add both wba / lab flow
  //   2b. DPI / FRI can only select initial assign flow (if has task, else supplier / lab flow)

  // supplier checking:
  // 1. everyday -> FRI can add both supplier / lab flow
  // 2. seasonal -> DPI / FRI can only select initial assign flow

  // buyer 1 & supplier 1
  if ((isBuyer || isSupplier) && isEveryday && TASK_TYPE_ENUM.FRID === selectedTaskType) {
    state.canSupplierFlow = true;
    state.canLabFlow = true;
  }

  // buyer 2a
  if (isBuyer && TASK_TYPE_ENUM.SS === selectedTaskType) {
    state.canWbaFlow = true;
    state.canLabFlow = true;
  }

  // buyer 2b & supplier 2
  if (
    (isBuyer || isSupplier) &&
    isSeasonal &&
    [TASK_TYPE_ENUM.DPI, TASK_TYPE_ENUM.FRI].includes(selectedTaskType)
  ) {
    // check if has old task
    const hasInitialTaskType =
      initialTaskTypes &&
      Object.keys(initialTaskTypes)?.length &&
      !!initialTaskTypes[selectedTaskType]?.length;

    // set old task flow
    const isInitialToLab = !!initialTaskTypes[selectedTaskType]?.includes(LAB);
    const isInitialToSupplier = !!initialTaskTypes[selectedTaskType]?.includes(SUPPLIER);

    // if has not old task, allow to assign lab / supplier flow
    state.canLabFlow = hasInitialTaskType ? isInitialToLab : true;
    state.canSupplierFlow = hasInitialTaskType ? isInitialToSupplier : true;
  }

  return state;
};
