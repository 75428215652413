import React from "react";
import { Box } from "@material-ui/core";

import ImagePreview from "components/display/ImagePreview";

import useUserRole from "hooks/useUserRole";
import Auth from "auth/authenticator";

const ProductImages = ({ data = [], itemId }) => {
  const { isBuyerSourcingRole } = useUserRole(Auth.loginRole());

  return (
    <Box py={2}>
      <ImagePreview
        data={data}
        config={{ type: "masterWicFiles", typeId: itemId, fileType: "product_image" }}
        editable={!isBuyerSourcingRole}
        multiple
        downloadable
      />
    </Box>
  );
};

export default ProductImages;
