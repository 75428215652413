import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./router";
import ScrollToTop from "./router/ScrollToTop";
import theme from "./theme";
import hackAdminUser from "helpers/hackAdmin";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loaded: {
    display: "none",
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center",
  },
  loading: {
    paddingTop: 300,
  },
}));

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    // get admin token & role for dev
    if (process.env.NODE_ENV !== "development") return;

    hackAdminUser();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <div className={classes.root}>
            {/* {!loaded &&
              <div className={loading ? classes.loadingContainer : classes.loaded}>
                <div className={classes.loading}>Loading...</div>
              </div>
            } */}
            <Router />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
// export default hot(module)(App);
