/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from "@material-ui/core";
import { getOptionsFormatted } from "../util";
import classes from "./NativeSelect.module.scss";

const NativeSelect = (props) => {
  const { variant, label, options, selectProps } = props;
  const {
    name,
    classes: classesProps,
    value: defaultValue,
    onChange,
    touched,
    errorText,
    formProps,
    ...others
  } = selectProps;

  const slTouched = formProps ? get(formProps, `touched.${name}`, "") : touched;
  const slErrorText = formProps ? get(formProps, `errors.${name}`, "") : errorText;
  const slValue = formProps ? get(formProps, `values.${name}`, "") : defaultValue;

  const optionsFormatted = getOptionsFormatted(options);
  const defaultValueOption = optionsFormatted[0] && optionsFormatted[0].value;

  const [value, setValue] = useState(slValue || defaultValueOption);

  useEffect(() => {
    if (formProps) formProps.setFieldValue(name, value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) onChange(event);
  };

  return (
    <FormControl variant={variant} classes={{ root: classes.formControl }}>
      {label && (
        <FormLabel component="legend" className={classes.label}>
          {label}
        </FormLabel>
      )}
      <Select
        autoWidth
        displayEmpty
        classes={classesProps}
        value={value}
        onChange={(event) => handleChange(event)}
        {...others}
      >
        {optionsFormatted.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {slErrorText && (
        <FormHelperText
          className={slTouched && slErrorText ? classes.error : classes.errorTextHide}
        >
          {slErrorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

NativeSelect.defaultProps = {
  variant: "outlined",
  label: "",
  options: [],
};

NativeSelect.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  selectProps: PropTypes.object.isRequired,
};

export default NativeSelect;
