import React, { useState } from "react";

import { Box, Typography } from "@material-ui/core";
import { textOrUnknown } from "utils/string";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import TextInput from "components/input/TextInput";
import { Check as SubmitIcon, Close as CancelIcon, Edit as EditIcon } from "@material-ui/icons";

import { atnUpdateSubmissionDetailsSupplierFactory } from "actions/submissionActions";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 10,
  },
  blackText: {
    color: "#000000",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: "#000000",
    width: "0.7em",
    height: "0.7em",
    transition: "all .2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  inputField: {
    width: 250,
    height: 26,
  },
}));

const EditItem = ({
  value,
  hasEdit,
  submissionId,
  typeEdit,
  userType,
  cbSetData = function () {},
}) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [valueInput, setValueInput] = useState(value);
  const [valueShow, setValueShow] = useState(value);

  const onEditItem = () => {
    setEditing(true);
  };

  const onCancelItem = () => {
    setValueInput(value);
    setEditing(false);
  };

  const handleMasterWICChange = (e) => {
    setValueInput(e.target.value);
  };

  const onSumitItem = () => {
    const params = { submissionId, data: { [typeEdit.type]: { [typeEdit.name]: valueInput } } };

    atnUpdateSubmissionDetailsSupplierFactory({ data: params, type: userType }).then((res) => {
      setValueShow(valueInput);
      cbSetData();
      setEditing(false);
    });
  };

  return (
    <>
      {editing ? (
        <>
          <Box display="flex" alignItems="flex-start">
            <TextInput
              name="wicNumber"
              value={valueInput}
              additionalClass={classes.inputField}
              onChange={handleMasterWICChange}
            />
            <Box display="flex" mt={0.5}>
              <SubmitIcon className={classes.iconButton} onClick={onSumitItem} />
              <CancelIcon className={classes.iconButton} onClick={onCancelItem} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classnames(classes.text, classes.blackText)}>
            {textOrUnknown(valueShow)}
          </Typography>
          {hasEdit && <EditIcon className={classes.iconButton} onClick={onEditItem} />}
        </>
      )}
    </>
  );
};

export default EditItem;
