import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { atnUpdateTaskRequirement } from "actions/taskActions";
import DateTimePicker from "components/input/DateTimePicker";
import { getDateFormat } from "utils/date";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 10,
    color: "#777777",
  },
  dateTime: {
    "& input": {
      fontSize: "0.8rem",
    },
  },
}));

const DateItem = ({
  value = "",
  isEditable = false,
  type,
  taskRequirementId,
  userType,
  onUpdate,
}) => {
  const classes = useStyles();

  const text = type.label;

  const [valueState, setValueState] = React.useState(value);

  const handleSubmit = (newValue) => {
    const params = { taskRequirementId, data: { [type.value]: newValue } };
    atnUpdateTaskRequirement({ data: params, type: userType }).then((res) => {
      if (onUpdate) onUpdate();
    });
  };

  return isEditable ? (
    <div onClick={(e) => e.stopPropagation()}>
      <DateTimePicker
        label={text}
        type="date"
        textClassName={classes.text}
        onSubmit={handleSubmit}
        pickerProps={{
          className: classes.dateTime,
          initialValue: value,
          value: valueState,
          setValue: setValueState,
        }}
      />
    </div>
  ) : (
    <Typography className={classes.text}>
      {text}: {textOrUnknown(getDateFormat(valueState))}
    </Typography>
  );
};

export default DateItem;
