import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  // contentContainer: {
  //   backgroundColor: "#F7F9FF",
  //   padding: 30,
  //   overflowX: "auto",
  // },
  tableContainer: {
    height: "80vh",
    width: "100%",
  },
}));

const AdminHome = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue("firstName") || ""} ${params.getValue("lastName") || ""}`,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];

  const handleRowClick = (param) => {};

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.tableContainer}>
          <DataGrid
            pageSize={50}
            rowsPerPageOptions={[50, 100, 200]}
            pagination
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowClick(param)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminHome;
