import React, { PureComponent } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const styles = () => ({
  textarea: {
    // width: "100%",
    // border: "1px solid #AAAAAA",
    // borderRadius: 4,
    // height: 36,
    // "& .MuiInputBase-input": {
    //   fontSize: 12,
    //   padding: "10px 8px"
    // },
    fontFamily: "inherit",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: "8px 14px",
    fontSize: 12,
  },
});

class Textarea extends PureComponent {
  render() {
    const {
      classes,
      rowsMin,
      rowsMax,
      placeholder,
      name,
      value,
      handleChange,
      additionalClass,
      cy,
      ...otherProps
    } = this.props;

    return (
      <TextareaAutosize
        rowsMin={rowsMin}
        rowsMax={rowsMax}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        className={classnames(classes.textarea, additionalClass)}
        data-cy={cy}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(Textarea);
