import React, { memo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

function NormalSelect({
  label,
  name,
  value,
  options,
  handleChange,
  additionalClass,
  formControlClass,
  cy = "",
  placeholder = "",
  helperText = "",
  disabled = false,
  error = false,
  fullWidth = false,
  ...otherProps
}) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classnames(classes.formControl, formControlClass, {
        [classes.fullWidth]: fullWidth,
      })}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        variant="outlined"
        className={classnames(classes.select, additionalClass, {
          [classes.fullWidth]: fullWidth,
        })}
        name={name}
        value={value}
        onChange={handleChange}
        label={label}
        data-cy={cy}
        data-cy-count={options.length}
        {...otherProps}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            data-cy={`${cy}-${item.value}`}
            data-cy-parent={cy}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {helperText && (
        <FormHelperText className={classnames({ [classes.error]: error })}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default memo(NormalSelect);

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    "& .MuiInputLabel-outlined": {
      fontSize: 12,
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
  },
  select: {
    maxWidth: 200,
    width: "100%",
    height: 40,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: 12,
    },
    "& fieldset": {
      top: 0,
      "& legend": {
        display: "none",
      },
    },
  },
  fullWidth: {
    width: "100%",
    maxWidth: "100%",
  },
  error: {
    color: "#D42600",
  },
}));
