import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 40,
    left: 0,
    width: "100%",
    height: 76,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  header: {
    position: "relative",
    width: "100%",
    height: 76,
    fontSize: 14,
    paddingTop: 29,
    textAlign: "center",
  },
  title: {
    color: "#000000",
    paddingRight: 10,
  },
  stepText: {
    color: "#777777",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    top: 15,
  },
  backBtn: {
    position: "absolute",
    left: 30,
    top: 15,
  },
  progressBarContainer: {
    height: 7,
    backgroundColor: "#E5ECF6",
  },
  progressBar: {
    height: 7,
    backgroundColor: "#003DA5",
  },
}));

const Header = ({
  title = "Create Item",
  data: { step: srcStep = 0, stepTotal = 0, headerText = "", cy = "ci-header" },
  func: { handlePrevPage, handleClose },
}) => {
  const classes = useStyles();
  const step = srcStep + 1;

  const getProcessPercentage = () => {
    if (stepTotal === 0) return 100;
    return Math.ceil(100 / stepTotal) * step;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {step > 0 && (
          <IconButton
            className={classes.backBtn}
            onClick={() => handlePrevPage()}
            data-cy={`${cy}-prev`}
          >
            <ArrowBackIcon className={classes.arrow} />
          </IconButton>
        )}
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.title}>|</Typography>
        <Typography className={classes.stepText}>{`Step ${step}: ${headerText}`}</Typography>
        <IconButton
          className={classes.closeBtn}
          onClick={() => handleClose()}
          data-cy={`${cy}-close`}
        >
          <CloseIcon className={classes.arrow} />
        </IconButton>
      </Box>

      <Box className={classes.progressBarContainer}>
        <Box
          className={classnames(classes.progressBar)}
          style={{ width: `${getProcessPercentage()}%` }}
        />
      </Box>
    </Box>
  );
};

export default Header;
