import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const ExpandButton = ({ expanded = false, size = "medium", onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classnames(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      aria-expanded={expanded}
      aria-label="show more"
      size={size}
      onClick={onClick}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

export default ExpandButton;
