import React, { useState } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";

import TextInput from "components/input/TextInput";
import Textarea from "components/input/Textarea";
import Select from "components/input/Select";
import OutlinedButton from "components/input/OutlinedButton";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 400,
  },
  shortInputFieldMargin: {
    marginTop: 10,
  },
  shortInputField: {
    width: 150,
  },
  inputFieldPadding: {
    paddingTop: 10,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  blockPadding: {
    paddingRight: 20,
  },
  tableTitleContainer: {
    borderBottom: "1px solid #cccccc",
    paddingTop: 30,
    paddingBottom: 10,
  },
  tableTitle: {
    fontSize: 12,
  },
  tableContentContainer: {
    borderBottom: "1px solid #cccccc",
    paddingTop: 10,
    paddingBottom: 10,
  },
  productName: {
    minWidth: 182,
    width: 182,
    marginRight: 10,
  },
  inputMargin: {
    marginTop: 5,
  },
  assortment: {
    minWidth: 165,
    width: 165,
    marginRight: 10,
  },
  ageGrade: {
    minWidth: 86,
    width: 86,
    marginRight: 10,
  },
  productCategory: {
    minWidth: 220,
    width: 220,
    marginRight: 10,
  },
  walkthrough: {
    minWidth: 182,
    width: 182,
    marginRight: 10,
  },
  foodContact: {
    minWidth: 140,
    width: 140,
    marginRight: 10,
  },
  action: {
    minWidth: 60,
    // width: 60,
  },
  addAssignment: {
    paddingTop: 10,
  },
  removeAssignmentBtn: {
    padding: 6,
  },
  addButtonContainer: {
    paddingTop: 20,
    textAlign: "center",
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiDialog-paperWidthSm": {
        minWidth: 700,
      },
    },
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  dialogCloseBtn: {
    position: "absolute",
    right: 10,
  },
  actionBtnMargin: {
    marginRight: 30,
  },
  remark: {
    fontFamily: "inherit",
  },
}));

const SubWICDetails = ({
  data: { itemData = {}, isEverydayProgram = false },
  func: { handleAddSubWIC, handleRemoveSubWIC, handleSubWICDataChange, handleNextPage },
}) => {
  const classes = useStyles();

  const productCategory = [
    {
      value: "childrenProduct",
      label: "Toy & Children Product",
    },
    {
      value: "generalUse",
      label: "General Use",
    },
  ];

  const [dialogSubWIC, setDialogSubWIC] = useState({
    tempId: "",
    wicNumber: "",
    productName: "",
    assortments: [],
    ageGrade: "",
    productCategory: "",
    walkthroughComments: "",
    isFoodContact: false,
    isSelected: true,
  });

  const [openSubWICDialog, setOpenSubWICDialog] = useState(false);
  const [dialogSubWICIndex, setDialogSubWICIndex] = useState("");

  const handleAddNewSubWIC = () => {
    setDialogSubWICIndex("");
    setDialogSubWIC({
      tempId: uuidv4(),
      wicNumber: "",
      productName: "",
      assortments: [],
      ageGrade: "",
      productCategory: "",
      walkthroughComments: "",
      isFoodContact: false,
      isSelected: true,
    });
    setOpenSubWICDialog(true);
  };

  const handleAddAssignment = () => {
    setDialogSubWIC((prevState) => ({
      ...prevState,
      assortments: prevState.assortments.concat({
        name: "",
      }),
    }));
  };

  const handleAssignmentChange = (e, index) => {
    const { value } = e.target;

    setDialogSubWIC((prevState) => ({
      ...prevState,
      assortments: prevState.assortments.map((item, i) => {
        if (i === index) {
          item.name = value;
        }

        return item;
      }),
    }));
  };

  const handleRemoveAssignment = (index) => {
    setDialogSubWIC((prevState) => ({
      ...prevState,
      assortments: prevState.assortments.filter((item, i) => {
        return i !== index;
      }),
    }));
  };

  const handleSubWICDialogClose = () => {
    setOpenSubWICDialog(false);
    setDialogSubWICIndex("");
  };

  const handleEditSubWIC = (index) => {
    setDialogSubWICIndex(index);
    setDialogSubWIC(itemData.subWics[index]);
    setOpenSubWICDialog(true);
  };

  const handleNewSubWICSubmit = () => {
    handleAddSubWIC(dialogSubWIC);
    setOpenSubWICDialog(false);
  };

  const handleEditSubWICSubmit = () => {
    handleSubWICDataChange(dialogSubWICIndex, dialogSubWIC);
    setOpenSubWICDialog(false);
  };

  const handleDialogSubWICDataChange = (e) => {
    const { name, value } = e.target;

    setDialogSubWIC((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFoodContactChange = () => {
    setDialogSubWIC((prevState) => ({
      ...prevState,
      isFoodContact: !prevState.isFoodContact,
    }));
  };

  const disabledSaveCondition = () => {
    let condition = !dialogSubWIC.wicNumber || !dialogSubWIC.productName;

    if (!isEverydayProgram) {
      condition = condition || !dialogSubWIC.ageGrade || !dialogSubWIC.productCategory;
    }

    return condition;
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Sub WIC Details</Box>

        {itemData.subWics.length > 0 && (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.tableTitleContainer}
            >
              <Box display="flex">
                <Box className={classnames(classes.tableTitle, classes.productName)}>Item Name</Box>
                <Box className={classnames(classes.tableTitle, classes.assortment)}>Assortment</Box>
                {!isEverydayProgram && (
                  <>
                    <Box className={classnames(classes.tableTitle, classes.ageGrade)}>
                      Age Grade
                    </Box>
                    <Box className={classnames(classes.tableTitle, classes.productCategory)}>
                      Toy & Children Product & General Use
                    </Box>
                    <Box className={classnames(classes.tableTitle, classes.walkthrough)}>
                      Walkthrough Comments
                    </Box>
                    <Box className={classnames(classes.tableTitle, classes.foodContact)}>
                      Food Contact
                    </Box>
                  </>
                )}
              </Box>
              <Box className={classnames(classes.tableTitle, classes.action)} />
            </Box>
          </Box>
        )}

        {itemData.subWics.map((item, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            className={classes.tableContentContainer}
          >
            <Box display="flex">
              <Box className={classnames(classes.productName)}>
                <Box>{item.wicNumber}</Box>
                <Box className={classes.inputMargin}>{item.productName}</Box>
              </Box>

              <Box className={classnames(classes.assortment)}>
                {item.assortments.map((assortment, i) => (
                  <Box key={i}>{assortment.name}</Box>
                ))}
              </Box>

              {!isEverydayProgram && (
                <>
                  <Box className={classnames(classes.ageGrade)}>
                    <Box>{item.ageGrade}</Box>
                  </Box>
                  <Box className={classnames(classes.productCategory)}>
                    {productCategory.map((category) => {
                      if (category.value === item.productCategory) {
                        return category.label;
                      }

                      return "";
                    })}
                  </Box>
                  <Box className={classnames(classes.walkthrough)}>
                    <Box>{item.walkthroughComments}</Box>
                  </Box>
                  <Box className={classnames(classes.foodContact)}>
                    <Checkbox color="primary" checked={item.isFoodContact} disabled />
                  </Box>
                </>
              )}
            </Box>

            <Box display="flex" className={classnames(classes.action)}>
              <Box className={classes.actionBtnMargin}>
                <IconButton
                  onClick={() => handleEditSubWIC(index)}
                  data-cy={`ci-subwic-edit-${index}`}
                >
                  <CreateIcon />
                </IconButton>
              </Box>

              <Box>
                <IconButton onClick={() => handleRemoveSubWIC(index)} data-cy="ci-subwic-delete">
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}

        <Box className={classes.addButtonContainer}>
          <OutlinedButton
            label="+ ADD SUB WIC"
            handleClick={handleAddNewSubWIC}
            color="primary"
            data-cy={"ci-subwic-add"}
          />
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={itemData.subWics.length === 0}
        data-cy={"ci-continue"}
      >
        CONTINUE
      </Button>

      <Dialog
        className={classes.dialog}
        onClose={handleSubWICDialogClose}
        open={openSubWICDialog}
        disableBackdropClick
        PaperProps={{
          "data-cy": "ci-dialog",
        }}
      >
        <MuiDialogTitle>
          <Typography className={classes.dialogTitle}>
            {dialogSubWICIndex === "" ? "Add New Sub WIC" : "Edit Sub WIC"}
          </Typography>
          <IconButton className={classes.dialogCloseBtn} onClick={handleSubWICDialogClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.title}>1. WIC Number</Box>
          <Box className={classes.subtitle}>WIC Number</Box>
          <TextInput
            placeholder="WIC Number"
            name="wicNumber"
            value={dialogSubWIC.wicNumber}
            additionalClass={classes.inputField}
            onChange={handleDialogSubWICDataChange}
            cy={"ci-subwic-number"}
          />

          <Box className={classes.subtitle}>Item Name</Box>
          <Box>
            <TextInput
              placeholder="Item Name"
              name="productName"
              value={dialogSubWIC.productName}
              additionalClass={classes.inputField}
              onChange={handleDialogSubWICDataChange}
              cy={"ci-subwic-item"}
            />
          </Box>

          <Box className={classnames(classes.title, classes.fieldPadding)}>2. Assortment</Box>

          {dialogSubWIC.assortments.map((item, index) => (
            <Box key={index} className={classes.inputFieldPadding} display="flex">
              <Box className={classes.blockPadding}>
                <TextInput
                  placeholder={`#${index + 1}`}
                  name="name"
                  value={item.name}
                  additionalClass={classes.inputField}
                  onChange={(e) => handleAssignmentChange(e, index)}
                  cy={`ci-subwic-assort-${index + 1}`}
                />
              </Box>
              <Box>
                <IconButton
                  className={classes.removeAssignmentBtn}
                  onClick={() => handleRemoveAssignment(index)}
                  data-cy={`ci-subwic-assort-remove-${index + 1}`}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}

          <Box className={classes.addAssignment}>
            <OutlinedButton
              label="ADD MORE"
              handleClick={handleAddAssignment}
              color="primary"
              data-cy={"ci-subwic-assort-add"}
            />
          </Box>

          {!isEverydayProgram && (
            <>
              <Box display="flex" className={classes.fieldPadding}>
                <Box className={classes.blockPadding}>
                  <Box className={classes.title}>3. Age Grade</Box>
                  <Box className={classes.shortInputFieldMargin}>
                    <TextInput
                      placeholder="Age Grade"
                      name="ageGrade"
                      value={dialogSubWIC.ageGrade}
                      additionalClass={classes.shortInputField}
                      onChange={handleDialogSubWICDataChange}
                      cy={"ci-subwic-age"}
                    />
                  </Box>
                </Box>

                <Box className={classes.blockPadding}>
                  <Box className={classes.title}>4. Product Category</Box>
                  <Box className={classes.shortInputFieldMargin}>
                    <Select
                      label="Select One"
                      name="productCategory"
                      value={dialogSubWIC.productCategory}
                      options={productCategory}
                      handleChange={handleDialogSubWICDataChange}
                      additionalClass={classes.shortInputField}
                      cy={"ci-subwic-product"}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box className={classes.title}>5. Food Contact</Box>
                  <Box>
                    <Checkbox
                      color="primary"
                      checked={dialogSubWIC.isFoodContact}
                      onChange={handleFoodContactChange}
                      data-cy={"ci-subwic-food"}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className={classnames(classes.title, classes.fieldPadding)}>
                6. Walkthrough Comments
              </Box>
              <Box className={classes.shortInputFieldMargin}>
                <Textarea
                  rowsMin={5}
                  placeholder="Comments"
                  name="walkthroughComments"
                  value={dialogSubWIC.walkthroughComments}
                  additionalClass={classnames(classes.inputField, classes.remark)}
                  onChange={handleDialogSubWICDataChange}
                  cy={"ci-subwic-comment"}
                />
              </Box>
            </>
          )}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={handleSubWICDialogClose}
            color="primary"
            variant="outlined"
            data-cy={"ci-subwic-cancel"}
          >
            Cancel
          </Button>
          <Button
            onClick={dialogSubWICIndex === "" ? handleNewSubWICSubmit : handleEditSubWICSubmit}
            color="primary"
            variant="contained"
            disabled={disabledSaveCondition()}
            data-cy={"ci-subwic-save"}
          >
            Save
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default SubWICDetails;
