import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import TextInput from "components/input/TextInput";
import Select from "../../../../components/input/Select";

import { currencyOptions } from "../data/index.json";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F9FF",
    position: "relative",
  },
  content: {
    marginTop: 123,
    paddingLeft: 120,
    paddingRight: 120,
  },
  pageTitle: {
    width: "100%",
    textAlign: "center",
    padding: 30,
    fontSize: 24,
    fontWeight: 500,
  },
  container: {
    padding: 30,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  gridContainer: {
    paddingTop: 30,
  },
  firstGrid: {
    minHeight: 40,
    border: "1px solid #777777",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  grid: {
    minHeight: 40,
    border: "1px solid #777777",
    borderTop: "none",
    "&:first-child": {
      borderRight: "none",
    },
    padding: 10,
  },
  inputTitle: {
    fontSize: 10,
    fontWeight: 400,
  },
  inputField: {
    width: "100%",
  },
  datePicker: {
    width: "100%",
  },
  to: {
    paddingTop: 10,
    textAlign: "center",
  },
  selectFieldContainer: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  fieldPadding: {
    paddingTop: 30,
  },
  submitBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
}));

const Complete = ({ completeData, prepaymentData, handleCompleteDataChange, isNb = false }) => {
  const classes = useStyles();

  const handleInspectionPrepaymentChange = (e) => {
    const { name, checked } = e.target;

    handleCompleteDataChange({
      target: {
        name: name,
        value: checked,
      },
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="10px 0"
        sx={{ width: "100%" }}
      >
        <Box className={classnames(classes.sectionTitle)}>Design Inspection Plan</Box>
        <Box sx={{ width: "200px", float: "right" }}>
          <Select
            label="Currency (Select One)"
            name="currency"
            value={completeData.currency}
            options={currencyOptions}
            handleChange={handleCompleteDataChange}
            additionalClass={classes.selectField}
            disabled={!!completeData.prepaymentPrice || isNb}
          />
        </Box>
      </Box>
      <Box className={classes.gridContainer}>
        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Number of Mandays</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <TextInput
              type="number"
              placeholder="Number of Mandays"
              name="noOfMandays"
              value={completeData.noOfMandays}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Manday Unit Cost</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Manday Unit Cost"
              name="mandayUnitCost"
              value={completeData.mandayUnitCost}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Travel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Travel Expenses"
              name="travelExpenses"
              value={completeData.travelExpenses}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Hotel Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Hotel Expenses"
              name="hotelExpenses"
              value={completeData.hotelExpenses}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Other Expenses</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Other Expenses"
              name="otherExpenses"
              value={completeData.otherExpenses}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Late Booking Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Late Booking Fee"
              name="lateBookingFee"
              value={completeData.lateBookingFee}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Cancellation Fee</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="Cancellation Fee"
              name="cancellationFee"
              value={completeData.cancellationFee}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
              startAdornment={
                <InputAdornment position="start">{completeData.currency}</InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Sub-total</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {completeData.currency} {completeData.subTotal}
          </Grid>
        </Grid>

        {prepaymentData.hasPrepayment && (
          <Grid container>
            <Grid item xs={5} className={classes.grid}>
              <Box className={classes.inputTitle}>Prepayment Price</Box>
            </Grid>

            <Grid item xs={7} className={classes.grid}>
              - {completeData.currency} {prepaymentData.prepaymentPrice}
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>VAT %</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            <TextInput
              type="number"
              placeholder="VAT %"
              name="vat"
              value={completeData.vat}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5} className={classes.grid}>
            <Box className={classes.inputTitle}>Total Price</Box>
          </Grid>

          <Grid item xs={7} className={classes.grid}>
            {completeData.currency} {completeData.totalPrice}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.gridContainer}>
        {/* <Grid container>
              <Grid item xs={5} className={classes.firstGrid}>
                <Box className={classes.inputTitle}>
                  Request Pre-Payment
                </Box>
              </Grid>

              <Grid item xs={7} className={classes.firstGrid}>
                <Checkbox
                  name="isPrepayment"
                  checked={completeData.isPrepayment}
                  onChange={handleInspectionPrepaymentChange}
                  color="primary"
                /> Yes
              </Grid>
            </Grid> */}

        <Grid container>
          <Grid item xs={5} className={classes.firstGrid}>
            <Box className={classes.inputTitle}>Remarks</Box>
          </Grid>

          <Grid item xs={7} className={classes.firstGrid}>
            <TextInput
              placeholder="Remarks"
              name="remarks"
              value={completeData.remarks}
              additionalClass={classes.inputField}
              onChange={handleCompleteDataChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Complete;
