import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import { RESUME_TYPE } from "shared/constants";

import capitalize from "utils/capitalize";

export default function ResumeChip({ className, label = "report", variant = "rounded" }) {
  const classes = useStyles();

  const getLabel = () => {
    switch (RESUME_TYPE[label.toUpperCase()]) {
      case RESUME_TYPE.REPORT:
        return capitalize(RESUME_TYPE.REPORT);

      case RESUME_TYPE.INVOICE:
        return capitalize(RESUME_TYPE.INVOICE);

      default:
        return "";
    }
  };

  return (
    <Box className={classnames(classes.labelBox, className)} display="flex">
      <Avatar variant={variant} className={classes.label}>
        {`Resume ${getLabel()}`}
      </Avatar>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  labelBox: {},
  label: {
    backgroundColor: "#FFD12E",
    color: "#433501",
    width: "auto",
    height: 20,
    fontSize: 10,
    fontWeight: "bold",
    border: "1px solid #D5A910",
    padding: 4,
  },
}));
