import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import TextInput from "components/input/TextInput";

import { isPbNotExisted } from "services/pbService";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 25,
  },
  table: {
    marginTop: 15,
  },
  tableHeaderCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableCell: {
    border: "1px solid #777777",
    fontSize: 12,
    height: 40,
    minWidth: 40,
    padding: "0px 10px",
    fontWeight: "normal",
  },
  tableText: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  info: {
    fontSize: 10,
    color: "#777777",
  },
  buyer: {
    fontSize: 10,
    color: "#003DA5",
  },
  closeIcon: {
    cursor: "pointer",
  },
  wrapperInput: {
    marginTop: 10,
    marginBottom: 10,
  },
  smallInputField: {
    width: 162,
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    backgroundColor: "rgba(169, 169, 169, 0.1)",
    "& > *": {
      color: "rgb(169, 169, 169)",
    },
  },
  error: {
    color: "#D42600",
  },
}));

const SelectedPBTable = ({ selected = [], onRemove, onClickConfirm }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>
              PB Number [Production batch line ID]
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>PB Quantity</TableCell>
            {/* <TableCell className={classes.tableHeaderCell}>
            Actual Quantity in Pieces
          </TableCell> */}
            <TableCell className={classes.tableHeaderCell}>Case Pack</TableCell>
            <TableCell className={classes.tableCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {selected.map((task, index) => {
            const pbIdentity = `${task.pbNumber} [ ${task.pbLineId || "N/A"} ]`;

            return (
              <TableRow
                key={task.id}
                className={classnames({
                  // [classes.disabled]: isPbNotExisted(task),
                })}
              >
                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.tableText}>
                    {pbIdentity}
                    {isPbNotExisted(task) && (
                      <>
                        {" "}
                        <span className={classes.error}>(Cancelled)</span>
                      </>
                    )}
                  </Box>
                </TableCell>

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.tableText}>{task.pbQuantity}</Box>
                </TableCell>

                {/* <TableCell scope="row" align="left" className={classes.tableCell}>
              <Box className={classes.tableText}>{task.poQuantity}</Box>
            </TableCell> */}

                <TableCell scope="row" align="left" className={classes.tableCell}>
                  <Box className={classes.wrapperInput}>
                    <TextInput
                      placeholder="Remarks"
                      name="casePack"
                      value={task.casePack || ""}
                      additionalClass={classes.smallInputField}
                      onChange={(e) => onClickConfirm(e, index)}
                      disabled={isPbNotExisted(task)}
                    />
                  </Box>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {/* {index !== 0 && */}
                  <CloseIcon className={classes.closeIcon} onClick={() => onRemove(task.id)} />
                  {/* } */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SelectedPBTable;
