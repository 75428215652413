import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import Table from "components/table/Table";
import { TASK_INSPECTION_TYPE, TASK_TESTING_TYPE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  tableCellTop: {
    borderTop: "none",
  },
  tableCellRight: {
    textAlign: "right",
  },
  tableCellFirst: {
    borderLeft: "none",
  },
  tableCellLast: {
    borderRight: "none",
  },
  tableCellNoPaddingLeft: {
    paddingLeft: 0,
  },
  tableCellNoPaddingRight: {
    paddingRight: 0,
  },
  tableTotal: {
    "& table tbody tr": {
      "&:nth-child(3)": {
        "& td": {
          fontWeight: "bold",
        },
      },
    },
  },
}));

const TestPlanTable = ({ data: tableData = {}, taskType, index, prepaymentData }) => {
  const classes = useStyles();

  const {
    testPlans = [],
    currency,
    subTotal,
    vat,
    totalPrice,
    isPackagePrice,
    packagePrice,
  } = tableData;

  const tableColumn = [
    {
      columnId: "item",
      label: TASK_TESTING_TYPE.includes(taskType) ? "Test Item" : "Item",
      width: "30%",
      classes: { cell: classnames(classes.tableCellFirst, classes.tableCellNoPaddingLeft) },
      render: ({ itemName, item }) => {
        return itemName || item;
      },
    },
    {
      columnId: "quantity",
      label: TASK_INSPECTION_TYPE.includes(taskType) ? "Mandays" : "Quantity",
      width: "20%",
      classes: { cell: classes.tableCellRight },
      render: ({ quantity }) => {
        return quantity;
      },
    },
    {
      columnId: "cost",
      label: "Cost",
      width: "20%",
      classes: { cell: classes.tableCellRight },
      render: ({ uniPrice, unitPrice }) => {
        return uniPrice || unitPrice;
      },
    },
    {
      columnId: "amount",
      label: "Sub-Total",
      width: "30%",
      classes: {
        cell: classnames(
          classes.tableCellLast,
          classes.tableCellNoPaddingRight,
          classes.tableCellRight,
        ),
      },
      render: ({ subTotal }) => {
        return `${subTotal} ${currency}`;
      },
    },
  ];

  const resultsColumn = [
    {
      columnId: "item",
      width: "30%",
      classes: {
        cell: classnames(
          classes.tableCellTop,
          classes.tableCellFirst,
          classes.tableCellNoPaddingLeft,
        ),
      },
      render: ({ title }) => {
        return title;
      },
    },
    {
      columnId: "quantity",
      width: "20%",
      classes: { cell: classnames(classes.tableCellTop, classes.tableCellRight) },
      render: null,
    },
    {
      columnId: "cost",
      width: "20%",
      classes: { cell: classnames(classes.tableCellTop, classes.tableCellRight) },
      render: null,
    },
    {
      columnId: "amount",
      width: "30%",
      classes: {
        cell: classnames(
          classes.tableCellTop,
          classes.tableCellLast,
          classes.tableCellNoPaddingRight,
          classes.tableCellRight,
        ),
      },
      render: ({ value }) => {
        return value;
      },
    },
  ];

  const resultsData = [
    {
      id: 1,
      title: "Sub-Total",
      value: `${isPackagePrice ? packagePrice : subTotal} ${currency}`,
    },
    {
      id: 2,
      title: "VAT (if applicable)",
      value: `${vat} %`,
    },
  ];

  if (index === 1 && prepaymentData && prepaymentData.hasPrepayment) {
    resultsData.push(
      {
        id: 3,
        title: "Prepayment Price",
        value: `- ${prepaymentData.prepaymentPrice} ${currency}`,
      },
      {
        id: 4,
        title: "Total Price (incl. VAT)",
        value: `${totalPrice} ${currency}`,
      },
    );
  } else {
    resultsData.push({
      id: 3,
      title: "Total Price (incl. VAT)",
      value: `${totalPrice} ${currency}`,
    });
  }

  return (
    <Box py={2}>
      <Table data={testPlans} columnData={tableColumn} noPagination />
      <Box className={classes.tableTotal}>
        <Table data={resultsData} columnData={resultsColumn} noHeader noPagination />
      </Box>
    </Box>
  );
};

export default TestPlanTable;
