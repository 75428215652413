import api from './api';

class commonApi {
  static getBrands(data) {
    return api.get('/common_data/brands', data);
  }

  static getBuyerCompanyTeamsAndPrograms(data) {
    return api.get('/buyer/buyer_company_teams', data);
  }

  static getCountries(data) {
    return api.get('/common_data/countries', data);
  }

  static getStates(data) {
    return api.get(`/common_data/area/states/${data.countryId}`, {});
  }

  static getCities(data) {
    return api.get(`/common_data/area/cities/${data.stateId}`, {});
  }

  static getPrograms(data) {
    return api.get("/common_data/programs", {});
  }
}

export default commonApi;
