import { EXCEPTION_TASK_STATUS_MAPPING, TENDERING_BRAND_TYPES } from "shared/constants/tendering";
import { TASK_TYPE_ENUM } from "shared/constants";

import { getCategory } from "services/categoryService";

export const canTenderingLabExemption = (category, labBrandType, isTendering = false) => {
  //* control to display lab exemption popup in supplier item detail

  const { isSeasonal } = getCategory(category);

  const isExceptionBrandType = TENDERING_BRAND_TYPES.includes(labBrandType);

  // show lab exception option in menu
  return isTendering && isExceptionBrandType && isSeasonal ? true : false;
};

export const canShareCostWithTendering = (selectedTasks = []) => {
  //* check share cost with tendering labs for supplier open task & edit task

  // check selected tasks(exclude default task) if has tendering program
  const [_, ...relatedTasks] = selectedTasks;

  // check if every task not tendering
  const hasNoTenderingTask = selectedTasks?.every((task) => !task?.isTenderingProgram);

  // count for checking matched labs
  const tenderingTaskCount = selectedTasks?.filter((task) => task?.isTenderingProgram)?.length || 0;

  // check if has 1 related task && some have tendering
  const hasOnlyRelatedTaskWithTendering =
    relatedTasks?.length === 1 && relatedTasks?.some((task) => task?.isTenderingProgram);

  // check if is has 1 task / more than one tasks
  const isDefaultTaskOnly = selectedTasks?.length === 1;

  const hasOneTaskOrOneTendering = isDefaultTaskOnly || tenderingTaskCount === 1;

  // check if multiple related tasks && some have tendering
  const hasTenderingWithSelectedTasks =
    (relatedTasks?.length > 1 && selectedTasks?.some((task) => task?.isTenderingProgram)) || false;

  // get matched tendering labs
  const matchedDesignatedLabs = () => {
    const labsCount = selectedTasks?.reduce((acc, cur, index) => {
      if (cur?.designatedLabs) {
        for (const dLab of cur?.designatedLabs) {
          if (dLab.id in acc) {
            acc[dLab.id]++;
          } else {
            acc[dLab.id] = 1;
          }
        }
      }

      return acc;
    }, {});

    const asArray = Object.entries(labsCount);

    // if only has open task / 1 tednering task -> pass all, else pass lab count larger than 1
    const filteredNotMatchedLabs = asArray.filter(([key, value]) =>
      hasOneTaskOrOneTendering ? value : value > 1,
    );

    // transform object to array with object key
    const tofilteredObject = Object.fromEntries(filteredNotMatchedLabs);
    const toArrayMatchedLabs = Object.keys(tofilteredObject) || [];

    const matchedLabIds = [];

    for (let index = 0; index < toArrayMatchedLabs.length; index++) {
      // has same lab for each selected task OR hasOnlyRelatedTaskWithTendering
      if (
        hasOnlyRelatedTaskWithTendering ||
        labsCount[toArrayMatchedLabs[index]] >= tenderingTaskCount
      ) {
        matchedLabIds.push(toArrayMatchedLabs[index]);
      }
    }

    // console.log({ labsCount, toArrayMatchedLabs, matchedLabIds, tenderingTaskCount });

    // return bool, check length of unique array list with lab id
    return [...new Set(matchedLabIds)];
  };

  const canTenderingShareCost =
    hasOneTaskOrOneTendering || hasNoTenderingTask
      ? true
      : (hasOnlyRelatedTaskWithTendering || hasTenderingWithSelectedTasks) &&
        !!matchedDesignatedLabs().length
      ? true
      : false;

  const stringifyTenderingLabs = !!matchedDesignatedLabs()?.length
    ? matchedDesignatedLabs()?.toString()
    : "";

  // console.log(
  //   {
  //     canTenderingShareCost,
  //     isDefaultTaskOnly,
  //     hasOnlyRelatedTaskWithTendering,
  //     hasTenderingWithSelectedTasks,
  //     tenderingTaskCount,
  //   },
  //   matchedDesignatedLabs(),
  // );

  /*
  canTenderingShareCost: @return true if:
    1. only default task
    2. has tendering program in selected tasks && has at least 1 matched designated Lab
  matchedDesignatedLabs: @return number[] (lab id)
  stringifyTenderingLabs: @return array toString
  */
  return {
    canTenderingShareCost,
    matchedDesignatedLabs: matchedDesignatedLabs(),
    stringifyTenderingLabs,
  };
};

export const getLabExemptionTasks = (tasks = []) => {
  // opt out SS task & only EXCEPTION_TASK_STATUS_MAPPING

  const labExemptionTasks =
    tasks?.filter(
      (itm) =>
        itm?.taskType !== TASK_TYPE_ENUM.SS &&
        EXCEPTION_TASK_STATUS_MAPPING.includes(itm?.taskStatus) &&
        itm.tenderingProgram &&
        itm.tenderingProgram.assignedLab &&
        itm.tenderingProgram.assignedLab !== "",
    ) || [];

  return { labExemptionTasks };
};
