import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormControlLabel, Radio as MuiRadio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  radioGroupRow: {
    flexDirection: "row",
  },
  radioControlLabelRoot: {
    marginRight: theme.spacing(4),
  },
  radioControlLabelText: {
    fontSize: 10,
    paddingLeft: theme.spacing(1),
  },
  radio: {
    padding: 0,
    color: "#000000",
    opacity: 0.54,
  },
  radioChecked: {
    color: "#B0D400",
    opacity: 1,
  },
}));

const Radio = (props) => {
  const classes = useStyles();

  const {
    direction = "column",
    name,
    options = [],
    disabled,
    defaultValue,
    onChange,
    ...other
  } = props;

  const radioStyle = { root: classes.radio, checked: classes.radioChecked };

  const radioControlLabelStyle = {
    root: classes.radioControlLabelRoot,
    label: classes.radioControlLabelText,
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        className={classnames({ [classes.radioGroupRow]: direction === "row" })}
        aria-label={`radio-${name}`}
        name={name}
        value={defaultValue}
        onChange={handleChange}
        {...other}
      >
        {options.map((o, i) => {
          return (
            <FormControlLabel
              key={i}
              value={o.value}
              classes={radioControlLabelStyle}
              control={<MuiRadio classes={radioStyle} color="default" />}
              label={o.label}
              disabled={o.disabled || disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default Radio;
