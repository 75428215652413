import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Select from "components/input/Select";
import TextInput from "components/input/TextInput";

const useStyles = makeStyles(() => ({
  container: {
    padding: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputField: {
    width: 600,
  },
  inputPadding: {
    paddingTop: 10,
  },
  shortInputField: {
    width: 400,
  },
  blockPadding: {
    paddingRight: 10,
  },
  fieldPadding: {
    paddingTop: 50,
  },
  addButtonContainer: {
    paddingTop: 10,
  },
  continueBtn: {
    marginTop: 50,
    marginBottom: 30,
    width: "100%",
  },
  hide: {
    display: "none",
  },
}));

const FactoryDetails = ({
  acknowledgeItemData = {},
  handleFactoryDataChange,
  handleAcknowledgeItemDataChange,
  countryList = [],
  stateList = [],
  cityList = [],
  hideState,
  handleNextPage,
}) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.container}>
        <Box className={classes.title}>Factory Information from WBA</Box>
        <Box className={classes.subtitle}>Factory Name</Box>
        <TextInput
          placeholder="Factory Name"
          name="name"
          value={acknowledgeItemData.factory.name || ""}
          additionalClass={classes.inputField}
          disabled
        />

        <Box className={classes.subtitle}>Factory Address</Box>
        <TextInput
          placeholder="Factory address"
          name="address1"
          value={acknowledgeItemData.factory.address1 || ""}
          additionalClass={classes.inputField}
          disabled
        />

        <Box className={classes.subtitle}>Factory ID</Box>
        <TextInput
          placeholder="Factory ID"
          name="factoryCode"
          value={acknowledgeItemData.factory.factoryCode || ""}
          additionalClass={classes.inputField}
          disabled
        />

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          1. Enter Factory Address (in Local Language)
        </Box>

        <Box className={classes.subtitle}>Factory Address</Box>
        <TextInput
          placeholder="Address"
          name="address2"
          value={acknowledgeItemData.factory.address2 || ""}
          additionalClass={classes.inputField}
          onChange={handleFactoryDataChange}
        />

        <Box className={classes.subtitle}>Area</Box>

        <Box>
          <Select
            label="Select Country"
            name="country"
            value={
              acknowledgeItemData.factory.city ? acknowledgeItemData.factory.city.countryId : ""
            }
            options={countryList}
            handleChange={handleFactoryDataChange}
            additionalClass={classes.inputField}
            data-cy="select-country"
          />
        </Box>

        {!hideState && (
          <Box className={classes.inputPadding}>
            <Select
              label="Select State"
              name="state"
              value={
                acknowledgeItemData.factory.city ? acknowledgeItemData.factory.city.stateId : ""
              }
              options={stateList}
              handleChange={handleFactoryDataChange}
              additionalClass={classes.inputField}
              disabled={
                !acknowledgeItemData.factory.city || !acknowledgeItemData.factory.city.countryId
              }
            />
          </Box>
        )}

        <Box className={classes.inputPadding}>
          <Select
            data-cy="select-city"
            label="Select City"
            name="city"
            value={acknowledgeItemData.factory.city ? acknowledgeItemData.factory.city.id : ""}
            options={cityList}
            handleChange={handleFactoryDataChange}
            additionalClass={classes.inputField}
            disabled={
              !acknowledgeItemData.factory.city || !acknowledgeItemData.factory.city.stateId
            }
          />
        </Box>

        <Box className={classnames(classes.title, classes.fieldPadding)}>
          2. Enter Contact Person Information
        </Box>

        <Box className={classes.subtitle}>Contact Person Name</Box>
        <TextInput
          placeholder="Name"
          name="contactPerson"
          value={acknowledgeItemData.factory.contactPerson || ""}
          additionalClass={classes.shortInputField}
          onChange={handleFactoryDataChange}
        />

        <Box display="flex">
          <Box className={classes.blockPadding}>
            <Box className={classes.subtitle}>Contact Person Email</Box>
            <TextInput
              placeholder="Email"
              name="contactEmail"
              value={acknowledgeItemData.factory.contactEmail || ""}
              additionalClass={classes.shortInputField}
              onChange={handleFactoryDataChange}
            />
          </Box>

          <Box>
            <Box className={classes.subtitle}>Contact Person Phone Number</Box>
            <TextInput
              placeholder="Phone Number"
              name="contactNumber"
              value={acknowledgeItemData.factory.contactNumber || ""}
              additionalClass={classes.shortInputField}
              onChange={handleFactoryDataChange}
            />
          </Box>
        </Box>

        {/* <Box className={classnames(classes.title, classes.fieldPadding)}>
          3. Upload AIS/Stibo - Factory information
        </Box>

        <UploadFiles
          files={acknowledgeItemData.aisFactoryInformation}
          handleAddFiles={handleAddAisFactoryinformationFiles}
          handleRemoveFile={handleRemoveAisFactoryinformationFile}
        /> */}
      </Paper>

      <Button
        data-cy="continue-factory"
        variant="contained"
        color="primary"
        className={classes.continueBtn}
        onClick={handleNextPage}
        disabled={
          !acknowledgeItemData.factory.address2 ||
          !acknowledgeItemData.factory.city ||
          !acknowledgeItemData.factory.city.id ||
          !acknowledgeItemData.factory.contactPerson ||
          !acknowledgeItemData.factory.contactEmail ||
          !acknowledgeItemData.factory.contactNumber
        }
      >
        CONTINUE
      </Button>
    </>
  );
};

export default FactoryDetails;
