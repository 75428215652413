import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell as MuiTableCell } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tableCell: {
    border: "1px solid #777777",
    fontSize: 10,
    color: "#000000",
    padding: 10,
    lineHeight: "1rem",
    verticalAlign: "middle !important",
  },
}));

const TableCell = ({
  children,
  classes: myClasses = { header: "", cell: "" },
  data,
  padding,
  type,
  sortDirection,
  width,
  onClick,
  onHeaderClick,
}) => {
  const classes = useStyles();

  function handleClick(event) {
    if (type === "body" && onClick) onClick(data);
    if (onHeaderClick) onHeaderClick(event);
  }

  const myCellStyle = {
    width: width || "auto",
    padding,
    verticalAlign: type === "body" && "initial",
    wordWrap: "break-word",
  };

  return (
    <MuiTableCell
      className={classnames(classes.tableCell, myClasses.cell, {
        [myClasses.head]: type === "head",
      })}
      variant={type} // enum: "head" || "body" || "footer"
      style={myCellStyle}
      sortDirection={sortDirection}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
