import submissionApi from '../api/submissionApi';

export const atnGetSubmissionListHeader = (reqData = {}) => {
  return submissionApi.getSubmissionListHeader(reqData);
};

export const atnGetSubmissionListData = (reqData = {}) => {
  return submissionApi.getSubmissionListData(reqData);
};

export const atnGetSubmissionDetails = (reqData = {}) => {
  return submissionApi.getSubmissionDetails(reqData);
};

export const atnGetSubmissionDetailsQuotations = (reqData) => {
  return submissionApi.getSubmissionDetailsQuotations(reqData);
};

export const atnGetSubmissionDetailsTaskList = (reqData) => {
  return submissionApi.getSubmissionDetailsTaskList(reqData);
};

export const atnGetSubmissionDetailsTaskDetail = (reqData) => {
  return submissionApi.getSubmissionDetailsTaskDetail(reqData);
};

export const atnGetSubmissionDetailsProducts = (reqData) => {
  return submissionApi.getSubmissionDetailsProducts(reqData);
};

export const atnGetSubmissionDetailsSupplierFactory = (reqData) => {
  return submissionApi.getSubmissionDetailsSupplierFactory(reqData);
};

export const atnUpdateSubmissionDetailsSupplierFactory = (reqData = {}) => {
  return submissionApi.updateSubmissionDetailsSupplierFactory(reqData);
};

export const atnGetSubmissionDetailsInvoice = (reqData) => {
  return submissionApi.getSubmissionDetailsInvoice(reqData);
};

export const atnGetSubmissionResumeReportTasks = (reqData) => {
  return submissionApi.getSubmissionResumeReportTasks(reqData);
}

export const atnPutSubmissionResumeReport = (reqData) => {
  return submissionApi.putSubmissionResumeReport(reqData);
};

export const atnPutSubmissionCompleteResumeReport = (reqData) => {
  return submissionApi.putSubmissionCompleteResumeReport(reqData);
};

// ******************** lab admin start ********************

export const atnGetLabAdminSubmissionListHeader = (reqData = {}) => {
  return submissionApi.getLabAdminSubmissionListHeader(reqData);
};

export const atnGetLabAdminSubmissionListData = (reqData = {}) => {
  return submissionApi.getLabAdminSubmissionListData(reqData);
};

export const atnGetLabAdminSubmissionDetails = (reqData = {}) => {
  return submissionApi.getLabAdminSubmissionDetails(reqData);
};

export const atnGetLabAdminSubmissionDetailsQuotations = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsQuotations(reqData);
};

export const atnGetLabAdminSubmissionDetailsTaskList = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsTaskList(reqData);
};

export const atnGetLabAdminSubmissionDetailsTaskDetail = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsTaskDetail(reqData);
};

export const atnGetLabAdminSubmissionDetailsProducts = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsProducts(reqData);
};

export const atnGetLabAdminSubmissionDetailsSupplierFactory = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsSupplierFactory(reqData);
};

export const atnGetLabAdminSubmissionDetailsInvoice = (reqData) => {
  return submissionApi.getLabAdminSubmissionDetailsInvoice(reqData);
};

export const atnGetLabAdminLabBranches = (reqData) => {
  return submissionApi.getLabAdminLabBranches(reqData);
};

export const atnAssignLabBranchToSubmissions = (reqData) => {
  return submissionApi.assignLabBranchToSubmissions(reqData);
};

// ******************** lab admin end ********************

export const atnSubmitQuotation = (reqData = {}) => {
  return submissionApi.submitQuotation(reqData);
};

export const atnConfirmQuotation = (reqData = {}) => {
  return submissionApi.confirmQuotation(reqData);
};

export const atnGetRelatedTasks = (reqData = {}) => {
  return submissionApi.getRelatedTasks(reqData);
};

export const atnUpdateTaskStatus = (reqData = {}) => {
  return submissionApi.updateTaskStatus(reqData);
};

export const atnGetTestItems = (reqData = {}) => {
  return submissionApi.getTestItems(reqData);
};

export const atnGetCompleteData = (reqData = {}) => {
  return submissionApi.getCompleteData(reqData);
};

export const atnUpdateCompleteStatus = (reqData = {}) => {
  return submissionApi.updateCompleteStatus(reqData);
};

export const atnGetTasksForCancellation = (reqData = {}) => {
  return submissionApi.getTasksForCancellation(reqData);
};

export const atnCancelTasks = (reqData = {}) => {
  return submissionApi.cancelTasks(reqData);
};

export const atnGetSubmissionTasks = (reqData = {}) => {
  return submissionApi.getSubmissionTasks(reqData);
};

export const atnEditSubmission = (reqData = {}) => {
  return submissionApi.editSubmission(reqData);
};

export const atnUpdateSampleSealingResult = (reqData = {}) => {
  return submissionApi.updateSampleSealingResult(reqData);
};

export const atnDeleteSampleSealingResult = (reqData = {}) => {
  return submissionApi.deleteSampleSealingResult(reqData);
};

export const atnCompleteSampleSealing = (reqData = {}) => {
  return submissionApi.completeSampleSealing(reqData);
};

export const atnGetSubmissionTrfDetails = (reqData = {}) => {
  return submissionApi.getSubmissionTrfDetails(reqData);
};

export const atnCreateTrfForm = (reqData = {}) => {
  return submissionApi.createTrfForm(reqData);
};

export const atnUpdateQuotationCost = (reqData = {}) => {
  return submissionApi.updateQuotationCost(reqData);
};

export const atnUpdateInvoiceCost = (reqData = {}) => {
  return submissionApi.updateInvoiceCost(reqData);
};

export const atnResumeInvoice = (reqData = {}) => {
  return submissionApi.resumeInvoice(reqData);
};

export const atnUpdateReport = (reqData = {}) => {
  return submissionApi.updateReport(reqData);
};

export const atnCreateReviseReport = (reqData = {}) => {
  return submissionApi.createReviseReport(reqData);
};

export const atnDeleteReport = (reqData = {}) => {
  return submissionApi.deleteReport(reqData);
};

export const atnGetMasterWicTrfFormData = (reqData = {}) => {
  return submissionApi.getMasterWicTrfFormData(reqData);
};

export const atnDownloadAllFiles = (reqData = {}) => {
  return submissionApi.downloadAllFiles(reqData);
};

export const atnCreatePACCert = (reqData = {}) => {
  return submissionApi.createPACCert(reqData);
};

export const atnCreateNewInvoice = (reqData = {}) => {
  return submissionApi.createNewInvoice(reqData);
};

export const atnUpdateCAPStatus = (reqData = {}) => {
  return submissionApi.updateCAPStatus(reqData);
};

export const atnUploadCAPReport = (reqData = {}) => {
  return submissionApi.uploadCAPReport(reqData);
};

export const atnNotifyCannotComplete = (reqData = {}) => {
  return submissionApi.notifyCannotComplete(reqData);
};

export const atnGetSubmissionPayer = (reqData = {}) => {
  return submissionApi.getSubmissionsPayer(reqData);
};

export const atnUpdatePayerSubmission = (reqData = {}) => {
  return submissionApi.updatePayerSubmission(reqData);
};