import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";

import { USER_TYPE } from "shared/constants";

import Button from "components/input/Button";

const useStyles = makeStyles((theme) => ({
  emptyWrapper: {
    borderRadius: 0,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  emptyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(10)}px 0`,

    "& button": {
      marginTop: theme.spacing(6),
    },
  },
  titleText: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
  defaultText: {
    fontSize: 12,
    color: "#000000",
  },
}));

const EmptyDetailBox = ({ history, itemId, userType }) => {
  const classes = useStyles();

  const SupplierContent = () => (
    <>
      <Typography className={classes.defaultText}>
        Get start by acknowledging this project and to advise the planned completion date of each
        task to WBA.
      </Typography>
      <Button onClick={() => history.push(`/supplier/acknowledgeItem/${itemId}`)}>
        I acknowledge this project
      </Button>
    </>
  );

  const BuyerContent = () => (
    <>
      <Typography className={classes.titleText}>No Task Available Yet</Typography>
      <Typography className={classes.defaultText}>
        This page is used for record only. Validity Dates & Periods can be edited in the tab at the
        left.
      </Typography>
    </>
  );

  return (
    <Paper className={classes.emptyWrapper}>
      <Box className={classes.emptyBox} data-cy="empty-box">
        {userType === USER_TYPE.supplier ? <SupplierContent /> : <BuyerContent />}
      </Box>
    </Paper>
  );
};

export default withRouter(EmptyDetailBox);
