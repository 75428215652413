import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import Attachments from "pages/common/Attachments";
import ProductGuaranteeLetters from "pages/common/ProductGuaranteeLetters";
import ItemType from "pages/common/ItemType";

import Divider from "components/display/Divider";

import EditableInput from "components/input/EditableInput";
import ConfirmModal from "components/modal/ConfirmModal";
import NoticeBar from "components/display/NoticeBar";

import ProductImages from "./ProductImages";
import WICDetails from "./WICDetails";

import Auth from "auth/authenticator";

import { atnGetItemDetailsProducts, atnUpdateIcl, atnUpdateProgram777 } from "actions/itemActions";

import { getCategory } from "services/categoryService";
import locale from "shared/locale.json";
import useUserType from "hooks/useUserType";
import useUserRole from "hooks/useUserRole";

const useStyles = makeStyles((theme) => ({
  contentText: {
    fontSize: 14,
  },
  container: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000000",
  },
  text: {
    fontSize: 10,
  },
  gridContainer: {
    padding: "16px 0",
  },
  gridItem: {
    fontSize: 10,
    border: "1px solid #777777",
    padding: 10,
    "&:first-child": {
      borderRight: "none",
    },
  },
}));

const Products = ({ userType, data, masterWicId, handleUpdateLabel }) => {
  const classes = useStyles();

  const { isBuyer } = useUserType(userType);
  const { isBuyerSourcingRole } = useUserRole(Auth.loginRole());

  const [images, setImages] = useState([]);
  const [details, setDetails] = useState([]);
  const [icl, setIcl] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [taskFiles, setTaskFiles] = useState([]);

  const [checkedProgram777, setCheckedProgram777] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [openNotice, setOpenNotice] = useState(false);
  const [isLabelSubmitError, setLabelSubmitError] = useState(false);

  const isProgram777 = data?.isProgram777;

  const { isEveryday } = getCategory(data?.category);

  const getProducts = (id) => {
    atnGetItemDetailsProducts({ data: { id }, type: userType }).then(({ data }) => {
      if (!data) return;

      const { productImages, subWics, attachments, taskFiles, icl } = data;

      if (productImages) setImages(productImages);
      if (subWics) setDetails(subWics);
      if (icl) setIcl(icl);
      if (attachments) setAttachments(attachments);
      if (taskFiles) setTaskFiles(taskFiles);
    });
  };

  useEffect(() => {
    if (!data.id) return;

    getProducts(data.id);
    setCheckedProgram777(isProgram777);
  }, []);

  const handleSubmitEditIcl = (newValue) => {
    const tempIcl = icl;
    setIcl(newValue);

    atnUpdateIcl({
      id: masterWicId,
      icl: newValue,
    }).then(({ data, message }) => {
      if (!data) {
        setIcl(tempIcl);
      }
    });
  };

  const onShowNoticeBar = () => {
    setOpenNotice(true);
  };

  const onHideNoticeBar = () => {
    setOpenNotice(false);
  };

  const onToggleLabel = (evt) => {
    const { checked } = evt.target;
    setCheckedProgram777(checked);

    onOpenConfirmModal();
  };

  const onOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const onCloseConfirmModalAndResetToggle = () => {
    setOpenConfirmModal(false);
    setCheckedProgram777(!checkedProgram777);
  };

  const onConfirmSubmit = async () => {
    const reqData = {
      id: data.id,
      isProgram777: checkedProgram777,
    };

    try {
      const res = await atnUpdateProgram777(reqData);

      if (!res) {
        setLabelSubmitError(true);
        onShowNoticeBar();
        onCloseConfirmModal();
        setCheckedProgram777(isProgram777);
        return;
      }

      setLabelSubmitError(false);
      onShowNoticeBar();
      onCloseConfirmModal();
      handleUpdateLabel(true);
    } catch (err) {
      setLabelSubmitError(true);
      onShowNoticeBar();
      onCloseConfirmModal();
      setCheckedProgram777(isProgram777);
    }

    setLabelSubmitError(false);
    onShowNoticeBar();
    onCloseConfirmModal();
    handleUpdateLabel(true);
  };

  const confirmLabelText = `Please confirm to ${
    checkedProgram777 ? "add" : "cancel"
  } label 777 for this item.`;

  return (
    <>
      <Paper className={classes.container}>
        <Box py={2}>
          <Typography className={classes.title}>Product Images</Typography>
          <Divider />
          <ProductImages data={images} itemId={data.id} />
        </Box>

        {isBuyer && isEveryday && (
          <>
            <Box py={2}>
              <Typography className={classes.title}>Item Type</Typography>

              <Divider />

              <ItemType checked={checkedProgram777} onChange={onToggleLabel} />
            </Box>

            <ConfirmModal
              title="777 Item Type"
              contentText={confirmLabelText}
              contentTextClass={classes.contentText}
              submitVariant="contained"
              submitText="CONFIRM"
              closeText="CANCEL"
              open={openConfirmModal}
              onClose={onCloseConfirmModalAndResetToggle}
              onSubmit={onConfirmSubmit}
            />
          </>
        )}

        <Box py={2}>
          <Typography className={classes.title}>WIC Details</Typography>
          <WICDetails data={details} isEverydayProgram={isEveryday} />
        </Box>
        {isEveryday && (
          <Box py={2}>
            <Typography className={classes.title}>ICL Number</Typography>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridItem}>
                ICL Number
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem}>
                {isBuyer ? (
                  <EditableInput
                    name="icl"
                    value={icl}
                    textClassName={classes.text}
                    handleSubmitEdit={handleSubmitEditIcl}
                  />
                ) : (
                  icl
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        <Box py={2}>
          <Typography className={classes.title}>Guarantee letter / CAP</Typography>
          <ProductGuaranteeLetters data={taskFiles} />
        </Box>
        <Box py={2}>
          <Typography className={classes.title}>WIC Attachments</Typography>
          <Attachments data={attachments} itemId={data.id} editable={!isBuyerSourcingRole} />
        </Box>
      </Paper>

      <NoticeBar
        message={
          isLabelSubmitError
            ? locale.errors.server
            : "Edit 777 label Success. You can see the 777 Label on Product Name Column."
        }
        error={isLabelSubmitError}
        open={openNotice}
        onClose={onHideNoticeBar}
      />
    </>
  );
};

export default Products;
