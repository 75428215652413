import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 40,
    left: 0,
    width: "100%",
    height: 76,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
  },
  header: {
    position: "relative",
    width: "100%",
    height: 76,
    fontSize: 14,
    paddingTop: 29,
    textAlign: "center",
  },
  title: {
    color: "#000000",
    paddingRight: 10,
  },
  stepText: {
    color: "#777777",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    top: 15,
  },
  backBtn: {
    position: "absolute",
    left: 30,
    top: 15,
  },
  progressBarContainer: {
    height: 7,
    backgroundColor: "#E5ECF6",
  },
  progressBar: {
    height: 7,
    backgroundColor: "#003DA5",
  },
  step1: {
    width: "33%",
  },
  step2: {
    width: "66%",
  },
  step3: {
    width: "100%",
  },
  step4: {
    width: "100%",
  },
}));

const Header = ({ step = 0, taskType, handlePrevPage, handleClose }) => {
  const classes = useStyles();

  const stepData = [
    {
      headerTitle: `Step 1: Upload Required Document`,
      class: classes.step1,
    },
    {
      headerTitle: `Step ${taskType === "PAC" ? 1 : 2}: Select Lab`,
      class: classes.step2,
    },
    {
      headerTitle: `Step ${taskType === "PAC" ? 2 : 3}: Fill In TRF Information`,
      class: classes.step3,
    },
    {
      headerTitle: `Step ${taskType === "PAC" ? 3 : 4}: Payer Detail for Invoice`,
      class: classes.step4,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {step > 0 && (
          <IconButton className={classes.backBtn} onClick={() => handlePrevPage()}>
            <ArrowBackIcon className={classes.arrow} />
          </IconButton>
        )}
        <Typography className={classes.title}>Open {taskType} Retest Task</Typography>
        <Typography className={classes.title}>|</Typography>
        <Typography className={classes.stepText}>{stepData[step].headerTitle}</Typography>
        <IconButton className={classes.closeBtn} onClick={() => handleClose()}>
          <CloseIcon className={classes.arrow} />
        </IconButton>
      </Box>

      <Box className={classes.progressBarContainer}>
        <Box className={classnames(classes.progressBar, stepData[step].class)} />
      </Box>
    </Box>
  );
};

export default Header;
