import poApi from '../api/poApi';

export function atnGetDailyPOList(data) {
  return poApi.getDailyPOList(data);
}

export function atnGetDailyPOSeriesList(data) {
  return poApi.getDailyPOSeriesList(data);
}

export function atnUploadPOChecker(data) {
  return poApi.uploadPOChecker(data);
}

export function atnGetDailyPOListSupplier(data) {
  return poApi.getDailyPOListSupplier(data);
}

export function atnGetDailyPOSeriesListSupplier(data) {
  return poApi.getDailyPOSeriesListSupplier(data);
}
