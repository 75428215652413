import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingLeft: theme.spacing(1),
    maxWidth: "fit-content",
  },
  checkboxControlLabelText: {
    fontSize: 10,
    paddingLeft: theme.spacing(1),
  },
  noLabel: {
    margin: 0,
  },
  checkbox: {
    padding: 0,
    color: "#000000",
    opacity: 0.54,
  },
  checkboxChecked: {
    color: "#B0D400",
    opacity: 1,
  },
}));

const Checkbox = ({ list = [], direction = "column", noLabel = false }) => {
  const classes = useStyles();

  const checkboxStyle = { root: classes.checkbox, checked: classes.checkboxChecked };

  const checkboxControlLabelStyle = {
    label: classes.checkboxControlLabelText,
    root: classnames({ [classes.noLabel]: noLabel }),
  };

  const handleChange = (event, onChange) => {
    if (onChange) onChange(event.target.checked, event.target.name);
  };

  return (
    <FormControl className={classes.formControl}>
      <FormGroup row={direction === "row"}>
        {list.map(({ name, value, disabled, label, onChange, ...rest }, index) => {
          return (
            <FormControlLabel
              key={index}
              classes={checkboxControlLabelStyle}
              control={
                <MuiCheckbox
                  classes={checkboxStyle}
                  color="default"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  name={name}
                  checked={!!value}
                />
              }
              value={value}
              disabled={disabled}
              label={label}
              onChange={(e) => handleChange(e, onChange)}
              {...rest}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default Checkbox;
