import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { textOrUnknown } from "utils/string";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 10,
  },
  grayText: {
    color: "#777777",
  },
  blackText: {
    color: "#000000",
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const OtherDetails = ({ data, taskType }) => {
  const classes = useStyles();

  const { sampleSize, tat, isPackagePrice, packageRemark, isPrepayment, deliveryNotes, remarks } =
    data;

  const Item = ({ label, value, noPaddingBottom = false }) => (
    <Box className={classes.item} pb={noPaddingBottom ? 0 : 3}>
      <Typography className={classnames(classes.text, classes.grayText)}>{label}</Typography>
      <Typography className={classnames(classes.text, classes.blackText)}>
        {textOrUnknown(value)}
      </Typography>
    </Box>
  );

  return (
    <Box py={2}>
      <Grid container>
        {(taskType === "QT" || taskType === "PST" || taskType === "ST") && (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Item label="Sample size" value={sampleSize} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Item label="Estimated Turnaround Time (TAT)" value={tat} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Item label="This is a package price" value={isPackagePrice ? "Yes" : "No"} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Item label="Package Remarks" value={packageRemark} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Item label="Delivery notes" value={deliveryNotes} />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Request Pre-payment" value={isPrepayment ? "Yes" : "No"} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item label="Remarks" value={remarks} noPaddingBottom />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtherDetails;
